import React, {useCallback, useEffect, useState} from 'react'
import {
  darkErrorMessage,
  darkMessage,
  fetchCustomersWorkOrderUrl,
  updatePurchaseOrderForField,
} from '../../modules/auth/core/_requests'
import {useParams} from 'react-router-dom'

import {Modal} from 'react-bootstrap'
import {FieldArray, Form, Formik} from 'formik'
import {Autocomplete, CircularProgress, TextField} from '@mui/material'

type PropType = {
  closeModal: () => void
  getList: (index: any) => void
  editRecord: any
}

type FieldType = {
  index: number
  customers: string[]
  workOrders: []
  remove: any
  setFilterCustomer: any
  setFieldValue: any
  defaultValues: any
}

const Fields: React.FC<FieldType> = ({
  index,
  customers,
  workOrders,
  remove,
  setFilterCustomer,
  setFieldValue,
  defaultValues,
}) => {
  const [openWorkOrders, setOpenWorkOrders] = useState(false)
  const [openCustomers, setOpenCustomers] = useState(false)
  const [defaultWorkOrder, setDefaultWorkOrder] = useState<any>({})
  const [defaultCustomer, setDefualtCustomer] = useState<any>({})
  const autoWorkOrderLoading = openWorkOrders && workOrders.length === 0
  const autoCustomersLoading = openCustomers && customers.length === 0

  useEffect(() => {
    const previous_customer = defaultValues[index]?.customer?.company
    const previous_workorder = defaultValues[index]?.work_order?.final_title
    setDefaultWorkOrder({
      [index]: previous_workorder,
    })
    setDefualtCustomer({
      [index]: previous_customer,
    })
  }, [])

  const handleWorkOrders = (wo: any) => {
    setDefaultWorkOrder((prevWo: any) => ({
      ...prevWo,
      [index]: wo,
    }))
  }

  const handleCustomers = (cu: any) => {
    setDefualtCustomer((prevCu: any) => ({
      ...prevCu,
      [index]: cu,
    }))
  }

  return (
    <div className='row p-6 ' style={{border: '1px solid darkgrey'}} key={index}>
      <div className='d-flex justify-content-around '>
        <Autocomplete
          sx={{width: 445}}
          // open={openCustomers}
          onOpen={() => {
            setOpenCustomers(true)
          }}
          onClose={() => {
            setOpenCustomers(false)
          }}
          onChange={(e, idx: any) => {
            setFilterCustomer(index, idx?.label)
            handleCustomers(idx?.label)
          }}
          value={defaultCustomer[index] || ''}
          options={customers}
          loading={autoCustomersLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search by Customer Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoCustomersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id={`assigned_item.${index}.assigned_work_order`}
          sx={{width: 445}}
          onOpen={() => {
            setOpenWorkOrders(true)
          }}
          onClose={() => {
            setOpenWorkOrders(false)
          }}
          onChange={(e, idx: any) => {
            setFieldValue(`assigned_item.${index}.assigned_work_order`, idx?.value)
            handleWorkOrders(idx?.label)
          }}
          value={defaultWorkOrder[index] || ''}
          options={workOrders[index] || workOrders}
          loading={autoWorkOrderLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose Quote, Project and Field Service'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoWorkOrderLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <button
          className='btn btn-secondary'
          onClick={() => remove(index)}
          type='button'
          style={{float: 'right'}}
        >
          X
        </button>
      </div>
    </div>
  )
}

const PurchaseOrderFor: React.FC<PropType> = ({closeModal, getList, editRecord}: any) => {
  const [workOrders, setWorkOrders] = useState<any>([])
  const [customers, setCustomers] = useState([])
  const [filterCustomer, setFilterCustomer] = useState({
    sequence: -1,
    data: '',
  })
  const {id}: any = useParams()

  const getWorkOrderCustomer = useCallback(() => {
    fetchCustomersWorkOrderUrl(filterCustomer.data)
      .then((res) => {
        const allCustomers: any = []
        const allWorkorder: any = []
        const requiredData = res?.data?.work_orders

        const customerData = res?.data?.customer
        customerData.map((eachCustomer: any) => {
          return allCustomers.push({
            value: eachCustomer?.id,
            label: eachCustomer?.company,
          })
        })
        requiredData.map((eachWorkOrder: any) => {
          return allWorkorder.push({
            value: eachWorkOrder?.id,
            label: eachWorkOrder?.final_title,
          })
        })
        setCustomers(allCustomers)
        filterCustomer.sequence > -1 &&
          setWorkOrders((prevData: any) => ({
            ...prevData,
            [filterCustomer.sequence]: allWorkorder,
          }))
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [filterCustomer.data, filterCustomer.sequence])

  useEffect(() => {
    getWorkOrderCustomer()
  }, [filterCustomer, getWorkOrderCustomer])

  const assigned_initialValues = {
    assigned_item: editRecord.map((each_record: any) => {
      return {assigned_work_order: each_record?.work_order?.id}
    }),
  }

  return (
    <>
      <Formik
        initialValues={assigned_initialValues}
        onSubmit={async (values, {resetForm}) => {
          const Items = values?.assigned_item
          const json_pack = JSON.stringify(Items)
          id
            ? updatePurchaseOrderForField(id, json_pack)
                .then((res) => {
                  closeModal()
                  getList()
                  const message = res?.data?.message
                  darkMessage(message)
                })
                .catch((e) => {
                  if (e?.response?.status === 409) {
                    console.error(e?.response?.data)
                    darkErrorMessage(e?.response?.data?.error_list[0])
                  }
                  darkErrorMessage('Error')
                  console.error(e)
                })
            : getList(Items)
          closeModal()
        }}
      >
        {({values, errors, getFieldProps, setFieldValue, isValid}) => (
          <Form>
            <Modal.Body style={{height: '500px', overflow: 'scroll'}}>
              <FieldArray name='assigned_item'>
                {({insert, remove, push}) => (
                  <div>
                    {values.assigned_item.length > 0 &&
                      values.assigned_item.map((assigned_item: any, index: number) => (
                        <Fields
                          index={index}
                          setFilterCustomer={(idx: number, cb_data: string) =>
                            setFilterCustomer({
                              sequence: idx,
                              data: cb_data,
                            })
                          }
                          setFieldValue={setFieldValue}
                          remove={remove}
                          defaultValues={editRecord}
                          customers={customers}
                          workOrders={workOrders}
                        />
                      ))}
                    <div className='row mb-6 mt-5'>
                      <div className='col-md-12'>
                        <button
                          className='btn btn-primary me-lg-5'
                          style={{float: 'right'}}
                          type={'button'}
                          onClick={() => push({assigned_work_order: ''})}
                        >
                          Assign More
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex flex-row-reverse mx-2 m-3'>
                <span className='mx-2'>
                  <button className='btn btn-primary' type={'submit'} disabled={!isValid}>
                    {'Confirm'}
                  </button>
                </span>
                <span className='mx-1'>
                  <button onClick={() => closeModal()} type={'button'} className='btn btn-dark'>
                    Discard
                  </button>
                </span>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  )
}
export {PurchaseOrderFor}
