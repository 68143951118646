/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useContext, useEffect, useState} from 'react'
import {
  currnetCompanyName,
  darkMessage,
  getCompanyClockOutTime,
  setCompanyClockOutTime,
} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker'
import DateFnsUtils from '@date-io/date-fns'
import {TextField} from '@mui/material'
import moment from 'moment'
import {MomentTimeFormat} from '../../context/TimeSheetConstant'

function ClockOut() {
  const headerContext = useContext<any>(HeaderContext)
  const [loading, setLoading] = useState(false)
  const [getDate, setDate] = useState(new Date())
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'TimeZone',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getList = useCallback(async () => {
    const customTime = moment(getDate).format(MomentTimeFormat)
    setLoading(true)
    await setCompanyClockOutTime(customTime)
      .then((res) => {
        setDate(res?.data?.data?.company_clock_out)
        navigate(`/${prefixCompany}/dashboard`)
        darkMessage('Data Updated Successfully')
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [getDate])

  const getData = useCallback(async () => {
    setLoading(true)
    await getCompanyClockOutTime()
      .then((res) => {
        setDate(res?.data?.data?.company_clock_out || new Date())
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      <div className='card'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h2 className='fw-bolder m-0'>Customize Clock Out Time</h2>
          </div>
        </div>
        <div className='row mb-6 p-10'>
          <label className='col-lg-3 col-form-label required fw-bold fs-6'>
            Please Select Automatic Clock Out Time
          </label>
          <div className='col-md-9'>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DesktopTimePicker
                className={`form-control form-control-lg form-control-solid c-clock-in `}
                value={moment(getDate).toDate()}
                onChange={(date: any) => setDate(date)}
                renderInput={(params: any) => <TextField {...params} label='Set Clock Out Time' />}
              />
            </LocalizationProvider>
          </div>
          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-primary btn-sm my-10'
              disabled={loading}
              onClick={getList}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClockOut
