import {useEffect, useState, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {TimeSheetTextConstant} from '../../context/TimeSheetConstant'
import {
  darkErrorMessage,
  darkMessage,
  updateQuoteLabel,
  dulpicateQuotes,
} from '../../modules/auth/core/_requests'

export function QuoteModal(props: any) {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [nextVal, setNextVal] = useState(false)
  const [objVal, setObjVal] = useState('')
  const [input, setInput] = useState<number | string>(0)
  const [inObject, setInObject] = useState<object | [] | any>({})

  const {type, id} = useParams()
  let quoteid = props.id
  var TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : TimeSheetTextConstant.WORKORDER
  TIMESHEET_TEXT = TIMESHEET_TEXT.charAt(0).toUpperCase() + TIMESHEET_TEXT.slice(1)
  useEffect(() => {
    setChecked(props?.checked)
  }, [props?.checked])

  const labelManage = () => {
    setNextVal(true)
  }

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      setLoading(true)
      let arr: Array<object | string>[] = []
      Object.keys(inObject).map((data) => {
        return arr.push(inObject[data])
      })
      checked ? (
        <>
          {dulpicateQuotes(arr, id)
            .then((res) => {
              setLoading(false)
              props.onHide()
              props.getList()
            })
            .catch((e) => {
              setLoading(false)
              props.onHide()
            })}
        </>
      ) : (
        updateQuoteLabel(id, input)
          .then((res) => {
            props.callbackFnc()
            setLoading(false)
            props.onModalClose()
            darkMessage('Quote Updates Successfully')
          })
          .catch((e) => {
            props.callbackFnc()
            setLoading(false)
            props.onModalClose()
            darkErrorMessage('Error')
            console.error(e)
          })
      )
    },
    [input, id, props, checked, inObject]
  )

  const closeModal = () => {
    setInput(0)
    props.onHide()
  }

  return (
    <>
      <div className='card'>
        <div
          className='card-body d-flex justify-content-between'
          style={{borderBottom: '1px solid #EEE'}}
        >
          <h2 className='fw-bolder m-2'>Welcome to #{quoteid}</h2>
          {/* <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{'aria-label': 'controlled'}}
          /> */}
        </div>
        {checked ? (
          <div className='d-flex flex-column' style={{padding: '30px'}}>
            <h2>{`Create Duplicates of ${TIMESHEET_TEXT}s`}</h2>
            <div>
              <label className='form-label' style={{padding: ' 20px 0px', fontSize: '15px'}}>
                How many duplicate {TIMESHEET_TEXT} you want to create ?
              </label>
              <input
                className='form-control form-control-lg '
                type='number'
                required
                min={'1'}
                style={{marginBottom: '20px'}}
                onChange={(e) => setInput(parseInt(e?.target?.value))}
              />
              {!input && nextVal === true ? (
                <span className='text-danger'>Please select any value...</span>
              ) : null}
              {input && nextVal === true
                ? [...Array(input)].map((item, key) => {
                    return (
                      <>
                        <div>
                          <label className='form-label'>
                            label {key + 1}
                            {item}
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg mb-5'
                            onChange={(e) => {
                              setInObject({...inObject, [`label${key}`]: e.target.value})
                              setObjVal(e.target.value)
                            }}
                          />
                        </div>
                      </>
                    )
                  })
                : null}

              <button
                style={{float: 'right', marginLeft: '10px'}}
                type='submit'
                className='btn btn-primary'
                disabled={loading}
                onClick={(e) => (input && nextVal === true ? handleSubmit(e) : labelManage())}
              >
                {!loading && input && nextVal === true ? 'Submit' : 'Next'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                className='btn btn-secondary mr-5'
                style={{float: 'right'}}
                onClick={() => closeModal()}
              >
                Discards
              </button>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column' style={{padding: '30px'}}>
            <h2>{`Update ${TIMESHEET_TEXT}# Label`}</h2>
            <div>
              <label className='form-label' style={{padding: ' 20px 0px', fontSize: '15px'}}>
                Enter New {TIMESHEET_TEXT} label
              </label>
              <input
                className='form-control form-control-lg '
                type='text'
                required
                onChange={(e) => setInput(e?.target?.value)}
                value={input}
                style={{marginBottom: '20px'}}
              />
              <button
                style={{float: 'right'}}
                type='submit'
                className='btn btn-primary '
                disabled={loading}
                onClick={handleSubmit}
              >
                {!loading && `Update ${TIMESHEET_TEXT}`}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                className='btn btn-secondary mr-5'
                style={{float: 'right'}}
                onClick={props.onHide}
              >
                Discards
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
