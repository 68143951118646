/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Purchase_Order_Intial as initialValues} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {FieldArray, Form, Formik, Field, ErrorMessage} from 'formik'
import {
  createPurchaseOrder,
  createReturnOrder,
  currnetCompanyName,
  darkMessage,
  inventoryData,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers'
import {InventoryManagement} from './InventoryManagement'
import {PurchaseOrderModel} from '../../modules/model/purchaseOrder'
let today = new Date()
const profileDetailsSchema = Yup.object().shape({
  date_ordered: Yup.date().required('Date is required').min(today, 'Please Enter a valid date'),
  tracking_number: Yup.number(),
  order_link: Yup.string(),
})

const RMACreate: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [options, setOptions] = useState<any>([])
  const [dropdown, setDropdown] = useState<any>([])
  const [modal, setModalShow] = React.useState<any>(false)
  const [vendor, setVendor] = useState<any>()
  const [shipping, setShipping] = useState<any>()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const headerContext = useContext<any>(HeaderContext)
  var subtotal: any = 0

  async function getList() {
    await inventoryData(1, '')
      .then((res) => {
        let shippingTypes = res?.data?.shipping_types
        let vendors = res?.data?.vendors
        let shipping: any = []
        let vendor: any = []
        vendors.forEach((e: any) => {
          vendor.push({label: e.name, id: e.id})
        })
        shippingTypes.forEach((e: any) => {
          shipping.push({label: e.name, id: e.id})
        })
        setDropdown(vendor)
        setOptions(shipping)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Return Order',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/return-order`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    getList()
    headerContext.dispatch(stateHeader)
  }, [])

  const onModalClose = useCallback(() => {
    setModalShow({show: false, label: ''})
    getList()
  }, [])

  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        label={modal?.label}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryManagement
          onModalClose={onModalClose}
          label={modal?.label}
          show={modal?.show}
          customerData={modal?.data}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <Formik
        initialValues={initialValues}
        validationSchema={profileDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          let items_info_data: any = []
          setLoading(true)
          values?.item_counter.map((item, index) => {
            items_info_data.push({
              item_name: item?.item_name,
              item_sku: item?.item_sku,
              quantity: item?.item_count,
              unit_cost: item?.unit_cost,
            })
          })
          let rma_order: PurchaseOrderModel = {
            vendor_id: vendor,
            shipping_method_id: shipping,
            tracking_number: values.tracking_number,
            order_link: values.order_link,
            date_ordered: values.date_ordered,
            total_cost: parseInt(subtotal) + parseInt(values.shipping_cost),
            items_info: JSON.stringify(items_info_data),
          }
          createReturnOrder(rma_order)
            .then(({data}) => {
              setSuccessBody(data as any)
              setLoading(false)
              resetForm()
              darkMessage('Return order created successfully')
              navigate(`/${prefixCompany}/inventory/return-order`)
            })
            .catch((e) => {
              if (e.response.status === 409) {
                setErrorBody(e.response.data)
                setLoading(false)
              }
            })
        }}
        render={({values, touched, errors, isValid}: any) => {
          subtotal = 0
          values?.item_counter.map((item: any, index: any) => {
            subtotal = subtotal + parseFloat(item?.item_count) * parseFloat(item?.unit_cost)
          })
          return (
            <Form>
              {errorBody && <ErrorAlert error_response={errorBody} />}
              {successBody && <SuccessAlert success_response={successBody} />}
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-8 '>
                    <div className='card mb-5 mb-xl-10'>
                      <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                      >
                        <div className='card-title m-0 ps-4 pe-4'>
                          <h3 className='fw-bolder '>Parts to Return</h3>
                          <span title='Items to be included in current RMA'>?</span>
                        </div>
                      </div>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-9'>
                          <div>
                            <FieldArray
                              name='item_counter'
                              render={(arrayHelpers) => (
                                <>
                                  <div>
                                    {values.item_counter && values.item_counter.length > 0
                                      ? values.item_counter.map((data: any, index: any) => (
                                          <div
                                            key={index}
                                            className='return-card-wrapper'
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              marginTop: 10,
                                              gap: '10px',
                                            }}
                                          >
                                            <Field
                                              style={{width: '100%'}}
                                              as={TextField}
                                              name={`item_counter.${index}.item_name`}
                                              label='Name'
                                              variant='outlined'
                                            />
                                            <Field
                                              style={{width: '100%'}}
                                              as={TextField}
                                              name={`item_counter.${index}.item_sku`}
                                              label='SKU'
                                              variant='outlined'
                                            />
                                            <Field
                                              style={{width: '100%'}}
                                              as={TextField}
                                              type='number'
                                              name={`item_counter.${index}.item_count`}
                                              label='Quantity'
                                              requried
                                              variant='outlined'
                                              className={`form-control ${
                                                touched.item_count && errors.item_count
                                                  ? 'is-invalid'
                                                  : ''
                                              }`}
                                            />
                                            <Field
                                              style={{width: '100%'}}
                                              as={TextField}
                                              type='number'
                                              requried
                                              name={`item_counter.${index}.unit_cost`}
                                              label='Unit Cost($)'
                                              variant='outlined'
                                            />
                                            <Field
                                              style={{width: '100%'}}
                                              as={TextField}
                                              name={`item_counter.${index}.subtotal`}
                                              label='Subtotal'
                                              variant='outlined'
                                              value={
                                                parseFloat(
                                                  values?.item_counter[index]?.item_count
                                                ) *
                                                  parseFloat(
                                                    values?.item_counter[index]?.unit_cost
                                                  ) || 0
                                              }
                                            />
                                            <button
                                              style={{width: '80%'}}
                                              className='btn btn-sm btn-secondary btn-xs cursor-pointer'
                                              type='button'
                                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                  <div className='add-items-btn'>
                                    <button
                                      style={{
                                        padding: '10px ',
                                        // width: '15%',
                                        margin: '10px 0',
                                        display: 'block',
                                      }}
                                      type='button'
                                      className='btn  btn-primary  cursor-pointer'
                                      onClick={() => arrayHelpers.insert(1, '')} // insert an empty string at a position
                                    >
                                      Add New Item
                                    </button>
                                    <ErrorMessage name='item_count' />
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='card mb-5 mb-xl-10'>
                      <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                      >
                        <div className='card-title m-0 ps-4 pe-4'>
                          <h3 className='fw-bolder m-0'>RMA Order</h3>
                        </div>
                      </div>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-9'>
                          <div style={{display: 'grid', gridTemplateColumns: 'auto'}}>
                            <table>
                              <tbody>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15 required'
                                  >
                                    Date Ordered
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <Field
                                      type='date'
                                      name='date_ordered'
                                      style={{padding: '7px', width: '100%'}}
                                      className={`mt-2 form-control ${
                                        touched.date_ordered && errors.date_ordered
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                    />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <ErrorMessage name='date_ordered' />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: 'auto'}}
                                    className='pb-4 font-size-15 required'
                                  >
                                    Vendor
                                  </td>
                                  <td style={{width: 'auto'}} className='font-size-15 text-right'>
                                    <button
                                      onClick={() =>
                                        setModalShow({
                                          show: 1,
                                          label: 'vendor',
                                        })
                                      }
                                      className='btn btn-sm btn-primary btn-xs cursor-pointer'
                                      style={{cursor: 'pointer'}}
                                    >
                                      Create New Vendor
                                    </button>
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <Autocomplete
                                      disablePortal
                                      id='vendor_id'
                                      style={{padding: '7px', width: '100%'}}
                                      options={dropdown}
                                      onChange={(e, value: any) => {
                                        if (value?.id) {
                                          setVendor(value?.id)
                                        }
                                      }}
                                      fullWidth={true}
                                      renderInput={(params: any) => (
                                        <TextField {...params} label='Please select Vendor' />
                                      )}
                                    />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <ErrorMessage name='vendor_ordernumber' />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td style={{width: '30%'}} className='pb-4 font-size-15 required'>
                                    Shipping Mehtod
                                  </td>
                                  <td style={{width: '70%'}} className=' font-size-15 text-right'>
                                    <button
                                      onClick={() =>
                                        setModalShow({
                                          show: 1,
                                          label: 'shipping',
                                        })
                                      }
                                      className='btn btn-sm btn-primary btn-xs cursor-pointer'
                                      style={{cursor: 'pointer'}}
                                    >
                                      Create New Shipping Method
                                    </button>
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <Autocomplete
                                      disablePortal
                                      id='combo-box-demo'
                                      style={{padding: '7px', width: '100%'}}
                                      options={options}
                                      onChange={(e, value: any) => {
                                        if (value?.id) {
                                          setShipping(value?.id)
                                        }
                                      }}
                                      fullWidth={true}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          label='Please select Shipping Method'
                                        />
                                      )}
                                    />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    Tracking Number
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <Field
                                      type='number'
                                      name='tracking_number'
                                      style={{width: '100%'}}
                                      className={`mt-2 form-control ${
                                        touched.tracking_number && errors.tracking_number
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                    />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <ErrorMessage name='tracking_number' />
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    Total Refund Value
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td
                                    style={{width: '100%'}}
                                    colSpan={2}
                                    className='pb-4 font-size-15'
                                  >
                                    <>{parseInt(subtotal) || 0}</>
                                  </td>
                                </tr>
                                <tr className=''></tr>
                              </tbody>
                            </table>

                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={loading || !isValid}
                            >
                              {!loading && 'Submit PO'}
                              {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      />
    </>
  )
}
export {RMACreate}
