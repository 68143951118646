import React, {useCallback, useContext, useEffect, useState, useRef, useMemo} from 'react'
import MaterialReactTable from 'material-react-table'
import Modal from 'react-bootstrap/Modal'
import {
  Purchase_Order_Intial as initialValues,
  VendorInterface,
} from '../../modules/accounts/components/settings/SettingsModel'
import {
  createFullVendor,
  createShippingType,
  fetchDummyAssignedItems,
  getInventoryData,
  inventorySearchListForPO,
  need_to_order_inventory_url,
  purchaseOrderItemData,
  darkErrorMessage,
  updatePurchaseOrder,
} from '../../modules/auth/core/_requests'
import TextField from '@mui/material/TextField'
import {Form, Formik, Field} from 'formik'
import * as Yup from 'yup'
import {currnetCompanyName, darkMessage} from '../../modules/auth/core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {InventoryManagement} from './InventoryManagement'
import {PurchaseOrderModel} from '../../modules/model/purchaseOrder'
import {LinearProgress} from '@mui/material'
import CreatableReactSelect from 'react-select/creatable'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {NumberFormatter, onKeyDown} from './GlobalComponents'
import SimpleDataTable from './SimpleDataTable'
import DataTable from 'react-data-table-component'
import {InventoryForm} from './InventoryForm'

const EditPurchaseOrder: React.FC = () => {
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [newLoading, setNewLoading] = useState(false)
  const [anotherloading, setAnotherLoading] = useState(false)
  const {currentUser} = useAuth()
  const [options, setOptions] = useState<any>([])
  const [dropdown, setDropdown] = useState<any>([])
  const [modal, setModalShow] = React.useState<any>(false)
  const [image, setImage] = useState('/media/avatars/blank.png')
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [needToOrder, setNeedToOrder] = useState([])
  const [previousPOData, setPreviousPOData] = useState<any>([])
  const [previousPOData_lines, setPreviousPOData_lines] = useState<any>([])
  const [SelectedVendor, setSelecetedVendor] = useState('')
  const [SelectedShippingMethod, setSelectedShippingMethod] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [searchItems, setSearchItems] = useState<any>([])
  const [fieldArrayData, setFieldArrayData] = useState([])
  const [getQuantity, setQuantity] = useState<number[]>([])
  const [initial_line_items, setinitial_line_items] = useState<any>([])
  const [getUnitCost, setUnitCost] = useState<number[]>([])
  const [subtotals, setSubtotals] = useState<number[]>([])
  const [item_counter, setItem_Counter] = useState<string[]>([])
  const [show_shipping_details, setshow_shipping_details] = useState(true)
  const [show_gt_detail, setshow_gt_detail] = useState(true)
  const [create_inventory_modal, set_create_inventory_modal] = useState(false)
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newQuantity = parseFloat(e.target.value)
    setQuantity((prevQuantity) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }

  const handleUnitCostChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newUnitCost = parseFloat(e.target.value)
    setUnitCost((prevUnitCost) => {
      const updatedUnitCost = [...prevUnitCost]
      updatedUnitCost[index] = newUnitCost
      return updatedUnitCost
    })
  }

  useEffect(() => {
    const newSubtotals = getQuantity.map((q, index) => calculateSubtotal(q, getUnitCost[index]))
    setSubtotals(newSubtotals)
  }, [getQuantity, getUnitCost])

  const refData = useRef<any>(null)
  var subtotal: any = 0
  var overallQuantity: any = 0

  const calculateSubtotal = (quantity: any, unitCost: any) => {
    return parseFloat(quantity) * parseFloat(unitCost)
  }

  const getList = useCallback(async () => {
    setLoading(true)
    let shipping: any = []
    let vendor: any = []
    await getInventoryData()
      .then((res) => {
        let shippingTypes = res?.data?.shipping_types
        let vendors = res?.data?.vendors
        vendors.forEach((e: any) => {
          vendor.push({label: e.name, id: e.id, image: e?.image})
        })
        shippingTypes.forEach((e: any) => {
          shipping.push({label: e.name, id: e.id})
        })
        setDropdown(vendor)
        setOptions(shipping)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setLoading(false)
        }
      })
  }, [])

  const getNeedToOrder = useCallback(() => {
    setNewLoading(true)
    need_to_order_inventory_url()
      .then((res) => {
        const needOrder: any = []
        res?.data?.items.map((v: any) => (v?.available_stock < 0 ? needOrder.push(v) : null))
        setNeedToOrder(needOrder)
        setNewLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setNewLoading(false)
      })
  }, [])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Purchase Order',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/purchase-order`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getSearchData = useCallback(async (searchTerm: any) => {
    setLoading(true)
    await inventorySearchListForPO(searchTerm)
      .then((res) => {
        const searchRes = res?.data?.items
        setSearchItems(searchRes || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const MaterialSearchItemsCol: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => (
          <>
            <a href={row?.image_field || null} target='blank'>
              <img
                alt='customer logo'
                src={`${row?.image_field || '/media/custom/empty_inventory.svg'}`}
                onError={(evt: any) => {
                  evt.target.src = blank
                }}
                width='70px'
                height={'70px'}
                style={{objectFit: 'contain'}}
              />
            </a>
          </>
        ),
        accessorKey: 'inventory_image',
      },
      {
        accessorFn: (row: any) => row?.brand?.name,
        accessorKey: 'mfg',
      },
      {
        accessorFn: (row: any) => row.sku,
        accessorKey: 'mfg part #',
      },
      {
        accessorFn: (row: any) => row.name,
        accessorKey: 'item name',
      },
    ],
    []
  )

  // Search
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length >= 3) {
      getSearchData(searchTerm)
    } else if (searchTerm.length === 0) {
      setSearchItems([])
    }
    return () => {}
  }, [getSearchData, searchTerm, debouncedSearchTerm])

  useEffect(() => {
    getNeedToOrder()
    getList()
  }, [getList, getNeedToOrder])

  const onModalClose = useCallback(() => {
    setModalShow({show: false, label: ''})
    getList()
  }, [getList])

  const handleClick = useCallback(
    (index: any) => {
      const updatedArray: any | string[] = [...fieldArrayData, index]
      setItem_Counter((prevItem_counter) => [...prevItem_counter, index?.id])
      setFieldArrayData(updatedArray)
    },
    [fieldArrayData]
  )

  const MaterialColumns: any = [
    {
      accessorFn: (row: any) => row.sku || 'N/A',
      header: 'MFG PART #', //uses the default width from defaultColumn prop
      accessorKey: 'need_mfg_part',
      size: 100,
    },
    {
      accessorFn: (row: any) => row.name || 'N/A',
      header: 'ITEM NAME', //uses the default width from defaultColumn prop
      accessorKey: 'need_item_name',
    },
    {
      accessorFn: (row: any) => row?.brand?.name || 'N/A',
      header: 'MFG', //uses the default width from defaultColumn prop
      accessorKey: 'need_mfg',
      size: 50,
    },
    {
      accessorFn: (row: any) => row?.vendor?.name || 'N/A',
      header: 'PREFERRED VENDOR', //uses the default width from defaultColumn prop
      accessorKey: 'need_preferred_vendor',
      size: 50,
    },
    {
      accessorFn: (row: any) => row.reserved_quantity || 0,
      header: 'QTY RESERVED', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_reserved',
      size: 50,
    },
    {
      accessorFn: (row: any) => row.old_stock || 0,
      header: 'QTY AVAILABLE', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_available',
      size: 50,
    },
    {
      accessorFn: (row: any) => Math.abs(row.available_stock) || 0,
      header: 'QTY TO ORDER', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_to_order',
      size: 50,
    },
    {
      accessorFn: (row: any) =>
        (row.final_title &&
          row.final_title.map((each_title: any) => {
            return (
              <>
                {each_title} <br />
              </>
            )
          })) ||
        'N/A',
      header: 'NEEDED FOR', //uses the default width from defaultColumn prop
      accessorKey: 'need_needed_for',
      size: 50,
    },
    {
      accessorFn: (row: any) => (
        <button
          type='button'
          className='btn btn-secondary px-2 py-1'
          onClick={() => handleClick(row)}
        >
          +
        </button>
      ),
      header: 'ADD TO PO', //uses the default width from defaultColumn prop
      accessorKey: 'need_add_to_po',
      size: 50,
    },
  ]
  const handleRemove = (_data: any, index: number) => {
    setFieldArrayData((prevData) => prevData.filter((_, i) => i !== index))
  }

  useEffect(() => {
    const vendor_name = previousPOData?.vendor?.name
    const shipping_method = previousPOData?.shipping_method?.name
    setSelecetedVendor(vendor_name)
    refData?.current?.setFieldValue('vendor_id', previousPOData?.vendor?.id)
    refData?.current?.setFieldValue('shipping_method_id', previousPOData?.shipping_method?.id)

    setSelectedShippingMethod(shipping_method)
    setImage(previousPOData?.vendor_logo)
    refData?.current?.setFieldValue('vendor_ordernumber', previousPOData?.vendor_ordernumber)
    refData?.current?.setFieldValue('date_ordered', previousPOData?.date_ordered || Date())
    refData?.current?.setFieldValue('tracking_number', previousPOData?.tracking_number)
    refData?.current?.setFieldValue('shipping_cost', previousPOData?.shipping_cost)
    refData?.current?.setFieldValue('tax', previousPOData?.tax)
  }, [previousPOData])

  const columns: any = useMemo(
    () => [
      {
        name: 'ITEM LOGO',
        selector: (row: any) => (
          <a href={row?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${row?.image_field ? row?.image_field : '/media/custom/empty_inventory.svg'}`}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain'}}
            />
          </a>
        ),
      },
      {
        name: 'MFG',
        selector: (row: any) => row?.brand?.name,
      },
      {
        name: 'MFG. PART #',
        selector: (row: any) => row?.sku,
      },
      {
        name: 'ITEM NAME',
        selector: (row: any) => row?.name,
      },
      {
        name: 'AVAILABLE STOCK',
        selector: (row: any) => row?.available_stock,
      },
      {
        name: 'NEED TO ORDER',
        selector: (row: any) => 0,
      },
      {
        name: 'ORDER QTY',
        selector: (row: any, index: number) => (
          <div>
            <input
              required
              type='number'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                handleQuantityChange(e, index)
                refData.current.setFieldValue(`item_counter.${index}.item_count`, e?.target?.value)
              }}
              defaultValue={initial_line_items[index]?.quantity}
            />
          </div>
        ),
      },
      {
        name: 'ITEM COST',
        selector: (row: any, index: any) => (
          <div>
            <input
              required
              type='text'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                handleUnitCostChange(e, index)
                refData.current.setFieldValue(`item_counter.${index}.unit_cost`, e?.target?.value)
              }}
              defaultValue={initial_line_items[index]?.item_cost}
            />
          </div>
        ),
      },
      {
        name: 'EXPECTED DELIVERY DATE',
        width: '180px',
        selector: (row: any, index: any) => (
          <div>
            <input
              type='date'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                refData.current.setFieldValue(
                  `item_counter.${index}.expected_delivery`,
                  e?.target?.value
                )
              }}
              defaultValue={initial_line_items[index]?.expected_delivery || ''}
            />
          </div>
        ),
      },
      {
        name: 'REFERENCE',
        selector: (row: any, index: any) => (
          <div>
            <input
              type='text'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                refData.current.setFieldValue(`item_counter.${index}.ref`, e?.target?.value)
              }}
              defaultValue={initial_line_items[index]?.reference || ''}
            />
          </div>
        ),
      },
      {
        name: 'SUBTOTAL',
        selector: (row: any, index: any) => <div>{NumberFormatter(subtotals[index])}</div>,
      },
      {
        name: 'Actions',
        selector: (row: any, index: any) => (
          <button className='btn btn-light' onClick={() => handleRemove(row, index)}>
            X
          </button>
        ),
      },
    ],
    [initial_line_items, subtotals]
  )

  const handleNewVendor = (
    selectedOption: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const optionString = selectedOption
    setLoading(true)
    let vendor: VendorInterface = {
      vendor_name: optionString,
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip: '',
      website: '',
      terms: '',
      notes: '',
      image: '',
      po_count: 0,
    }
    createFullVendor(vendor)
      .then(({data}) => {
        setFieldValue('vendor_id', optionString)
        setSelecetedVendor(optionString)
        setLoading(false)
        getList()
        darkMessage('Vendor created successfully')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          console.error(e.response.data)
        }
        console.log(e)
        setLoading(false)
      })
  }
  const handleVendorCreate = (e: any, setFieldValue: any) => {
    handleNewVendor(e, setFieldValue)
  }

  const handleVendorChange = useCallback(
    (selectedOption: any, setFieldValue: (arg0: string, arg1: string) => void) => {
      setImage(selectedOption?.image || blank)
      setFieldValue('vendor_id', selectedOption.id)
      setSelecetedVendor(selectedOption?.label)
    },
    []
  )

  const handleNewShipping = (
    selectedOption: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const optionString = selectedOption
    setLoading(true)
    createShippingType(optionString)
      .then(({data}) => {
        setFieldValue('shipping_method_id', optionString)
        setSelectedShippingMethod(optionString)
        setLoading(false)
        getList()
        darkMessage('Shipping Method created successfully')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          console.error(e.response.data)
        }
        console.log(e)
        setLoading(false)
      })
  }

  const handleShippingCreate = (e: any, setFieldValue: any) => {
    handleNewShipping(e, setFieldValue)
  }

  const handleShippingChange = useCallback(
    (selectedOption: any, setFieldValue: (arg0: string, arg1: string) => void) => {
      setSelectedShippingMethod(selectedOption?.label)
      setFieldValue('shipping_method_id', selectedOption?.id)
    },
    []
  )

  const fetchRecords = useCallback((index: any) => {
    const json_Data = JSON.stringify(index)
    const api_result = fetchDummyAssignedItems(json_Data)
      .then((res) => {
        return res?.data?.result
      })
      .catch((e) => {
        console.error(e)
      })
    return api_result
  }, [])

  const schema = Yup.object().shape({
    item_counter: Yup.array().of(
      Yup.object().shape({
        // unit_cost: Yup.number().min(0.0, 'Unit cost must be a positive number'),
        // item_count: Yup.number().min(1, 'Quantity must be at least 1'),
      })
    ),
    vendor_id: Yup.string().required('Vendor is required'),
    date_ordered: Yup.date().required('Order Date is required'),
  })

  const handleFieldArrayItems = useCallback(
    (index_data: any) => {
      const dataArrayCopy: any = [...fieldArrayData]
      dataArrayCopy.push(index_data)
      setItem_Counter((prevItem_counter: any) => [...prevItem_counter, index_data?.id])
      setFieldArrayData(dataArrayCopy)
      setSearchTerm('')
    },
    [fieldArrayData]
  )

  useEffect(() => {
    setinitial_line_items(previousPOData_lines || [])
    const mappedArray = previousPOData_lines?.map((each_item: any) => ({
      id: each_item?.item_sku?.id,
      sku: each_item?.item_sku?.sku,
      name: each_item?.item_sku?.name,
      brand: {
        id: each_item?.item_sku?.brand?.id,
        user: each_item?.item_sku?.brand?.user,
        name: each_item?.item_sku?.brand?.name,
      },
      available_stock: each_item?.item_sku?.available_stock,
      quantity_in_store: each_item?.item_sku?.quantity_in_store,
      image_field: each_item?.item_sku?.image_field,
      preferred_vendor: each_item?.item_sku?.preferred_vendor,
      reserved_quantity: each_item?.item_sku?.reserved_stock,
    }))
    previousPOData_lines?.forEach((each_item: any, index: number) => {
      refData.current.setFieldValue(`item_counter.${index}.line_item_id`, each_item?.id)
      refData.current.setFieldValue(`item_counter.${index}.item_count`, each_item?.quantity)
      refData.current.setFieldValue(`item_counter.${index}.unit_cost`, each_item?.item_cost)
      refData.current.setFieldValue(
        `item_counter.${index}.expected_delivery`,
        each_item?.expected_delivery
      )
      refData.current.setFieldValue(`item_counter.${index}.ref`, each_item?.reference)
      setItem_Counter((prevItem_counter: any) => [...prevItem_counter, each_item?.item_sku?.id])

      setQuantity((prevQuantity) => {
        const updatedQuantity = [...prevQuantity]
        updatedQuantity[index] = parseFloat(each_item?.quantity)
        return updatedQuantity
      })

      setUnitCost((prevUnitCost) => {
        const updatedUnitCost = [...prevUnitCost]
        updatedUnitCost[index] = parseFloat(each_item?.item_cost)
        return updatedUnitCost
      })
    })
    setFieldArrayData(mappedArray)
  }, [previousPOData_lines])

  const getPreviousData = useCallback(async () => {
    await purchaseOrderItemData(id)
      .then((res) => {
        let purchaseOrderData = res?.data?.purchase_order[0]
        let purchaseOrderItems = res?.data?.purchase_order_items || []
        setPreviousPOData(purchaseOrderData || [])
        setPreviousPOData_lines(purchaseOrderItems)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          console.log(e.response.data)
        }
      })
  }, [id])

  useEffect(() => {
    getPreviousData()
  }, [getPreviousData])
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        label={modal?.label}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryManagement
          onModalClose={onModalClose}
          label={modal?.label}
          show={modal?.show}
          customerData={modal?.data}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={create_inventory_modal}
        onHide={() => set_create_inventory_modal(false)}
        onModalClose={() => set_create_inventory_modal(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryForm
          checkIsInventoryModal={true}
          closeInventoryModal={() => {
            set_create_inventory_modal(false)
          }}
        />
      </Modal>
      <Formik
        innerRef={refData}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, {resetForm}) => {
          const transformedArr = values?.item_counter.map((obj: any, index) => ({
            id: item_counter[index],
            ...obj,
          }))
          let items_info_data: any = []
          overallQuantity = 0
          setLoading(true)
          values?.item_counter.map((item: any, index: any) => {
            return (overallQuantity += parseFloat(item?.item_count))
          })
          transformedArr.map((item, index) => {
            return items_info_data.push({
              item_id: item?.id,
              line_item_id: item?.line_item_id,
              ref: item?.ref || '',
              quantity: item?.item_count || 0,
              unit_cost: item?.unit_cost || 0,
              expected_delivery: item?.expected_delivery || 0,
            })
          })
          let purchase_order: PurchaseOrderModel = {
            po_id: id,
            items_info: JSON.stringify(items_info_data),
            vendor_id: values.vendor_id,
            date_ordered: values.date_ordered,
            shipping_method_id: values.shipping_method_id,
            expected_delivery: values.expected_delivery,
            tracking_number: values.tracking_number,
            order_subtotal: subtotal,
            shipping_cost: values.shipping_cost,
            tax: values.tax,
            total_cost:
              (parseFloat(subtotal) || 0) +
              (parseFloat(values?.shipping_cost) || 0) +
              (parseFloat(values?.tax) || 0),
            order_status: 'IN_TRANSIT',
            overall_quantity: overallQuantity,
            received_total: '',
            order_link: '',
            parent_ref: '',
            vendor_ordernumber: values?.vendor_ordernumber,
          }
          updatePurchaseOrder(purchase_order)
            .then(({data}) => {
              setLoading(false)
              darkMessage('Purchase order updated successfully')
              resetForm()
              navigate(`/${prefixCompany}/inventory/purchase-order/${id}/view`)
            })
            .catch((e: any) => {
              if (e.response.status === 409) {
                const message = e?.response?.data?.error_list[0]
                darkErrorMessage(message)
                setLoading(false)
              }
              setLoading(false)
            })
        }}
        render={({values, touched, errors, isValid, setFieldValue, getFieldProps}: any) => {
          subtotal = 0
          values?.item_counter.map((item: any, index: any) => {
            return (subtotal =
              subtotal + parseFloat(item?.item_count) * parseFloat(item?.unit_cost))
          })
          const formattedSubtotal = subtotal
          return (
            <Form onKeyDown={onKeyDown}>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-9'>
                    {newLoading && <LinearProgress />}
                    <div className='mb-5 mb-xl-10'>
                      <MaterialReactTable
                        columns={MaterialColumns}
                        data={needToOrder}
                        enableSorting={false}
                        enableHiding={false}
                        enableFilters={false}
                        muiTableHeadCellProps={{
                          sx: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                          },
                        }}
                        muiTableBodyCellProps={{
                          style: {fontSize: '13px'},
                        }}
                        enableStickyHeader
                        enableStickyFooter
                        manualFiltering
                        enableGlobalFilter={true}
                        enableColumnActions={false}
                        renderTopToolbarCustomActions={({table}) => (
                          <div className='card-title m-0 d-flex d-inline align-items-center'>
                            <h4 className='fw-bolder m-5 w-115px'>Need To Order</h4>
                          </div>
                        )}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enablePagination={false}
                        enableColumnFilters={false}
                        enableBottomToolbar={false}
                      />
                    </div>
                    <div className='card mb-5 mb-xl-10'>
                      <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                      >
                        <div className='d-flex p-lg-5 align-items-lg-center m-0 items-order-wrapper'>
                          <h3 className='fw-bolder m-3'>Items to Order</h3>
                          <div className='d-flex align-items-center position-relative my-1'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen021.svg'
                              className='svg-icon-4 position-absolute ms-6'
                            />
                            <input
                              style={{fontSize: '14px', padding: '7px 10px'}}
                              type='text'
                              data-kt-user-table-filter='search'
                              className='form-control form-control-solid w-300px ps-14'
                              placeholder='Search Existing Inventory Items'
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </div>
                          <div className='d-flex align-items-center position-relative mx-2 my-1'>
                            <button
                              className='btn btn-secondary'
                              type='button'
                              onClick={() => set_create_inventory_modal(true)}
                            >
                              + Create New Inventory Item
                            </button>
                          </div>
                        </div>
                      </div>
                      {searchItems && searchItems.length > 0 && (
                        <div
                          className='pop_up_table'
                          style={{
                            position: 'absolute',
                            zIndex: '1',
                            marginTop: '60px',
                            marginLeft: '165px',
                            border: '1px solid black',
                            maxHeight: '400px',
                            overflow: 'scroll',
                          }}
                        >
                          <SimpleDataTable
                            MaterialColumns={MaterialSearchItemsCol}
                            MaterialColumnsApiData={searchItems}
                            stopRedirection={true}
                            handleClick={(cb_data: any) => {
                              handleFieldArrayItems(cb_data)
                            }}
                          />
                        </div>
                      )}
                      <div id='kt_account_profile_details' className='collapse show '>
                        <div className='border-top bottom_table'>
                          <DataTable
                            pointerOnHover
                            highlightOnHover
                            columns={columns}
                            data={fieldArrayData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='row '>
                                  <div className='col-md-4 pe-0 d-flex justify-content-center'>
                                    <a href={image} target='blank'>
                                      <img
                                        alt='vendor here'
                                        src={image}
                                        onError={(evt: any) => {
                                          evt.target.src = blank
                                        }}
                                        style={{
                                          objectFit: 'contain',
                                          borderRadius: '10px',
                                          maxWidth: '80px',
                                          width: '100%',
                                          height: '90px',
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <div className='col-md-8 mb-3'>
                                    <div className='row gap-2'>
                                      <div className='col-md-12'>
                                        <CreatableReactSelect
                                          isSearchable
                                          className='w-100 '
                                          isLoading={anotherloading}
                                          options={dropdown}
                                          onChange={(e: any) => {
                                            handleVendorChange(e, setFieldValue)
                                          }}
                                          onCreateOption={(e) => {
                                            handleVendorCreate(e, setFieldValue)
                                          }}
                                          value={{
                                            value: SelectedVendor,
                                            label: SelectedVendor,
                                          }}
                                          placeholder='Vendor'
                                        />
                                        {errors.vendor_id && touched.vendor_id && (
                                          <div className='text-danger'>{errors.vendor_id}</div>
                                        )}
                                      </div>
                                      <div className='col-md-12'>
                                        <Field
                                          as={TextField}
                                          variant='outlined'
                                          label='Vendor Order #'
                                          name='vendor_ordernumber'
                                          style={{marginTop: '7px', width: '100%', zIndex: '0'}}
                                          className={`form-control ${
                                            touched.vendor_ordernumber && errors.vendor_ordernumber
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='row '>
                                  <div className='col-md-4 pe-0 d-flex align-items-center justify-content-center'>
                                    <label
                                      className='d-block fw-bold'
                                      htmlFor='date_ordered'
                                      style={{
                                        fontSize: '16px',
                                      }}
                                    >
                                      Order Date
                                    </label>
                                  </div>
                                  <div className='col-md-8'>
                                    <Field
                                      placeholder='Order Date'
                                      type='date'
                                      name='date_ordered'
                                      className={`mt-2 form-control  ${
                                        touched.date_ordered && errors.date_ordered
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      defaultValue={initialValues.date_ordered}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='row gap-4'>
                              <div className='d-flex justify-content-between border-bottom pb-5'>
                                <h3 className='mb-0'>Shipping Details</h3>
                                <span
                                  onClick={() => {
                                    setshow_shipping_details(
                                      (prev_shipping_details) => !prev_shipping_details
                                    )
                                  }}
                                  className='text-end'
                                >
                                  {show_shipping_details ? (
                                    <KTSVG path={'/media/custom/up.svg'} />
                                  ) : (
                                    <KTSVG path={'/media/custom/down.svg'} />
                                  )}
                                </span>
                              </div>
                              {show_shipping_details && (
                                <div>
                                  <div className='col-md-12 position-relative'>
                                    <CreatableReactSelect
                                      isSearchable
                                      className='w-100'
                                      isLoading={anotherloading}
                                      options={options}
                                      onChange={(e: any) => {
                                        handleShippingChange(e, setFieldValue)
                                      }}
                                      onCreateOption={(e) => {
                                        handleShippingCreate(e, setFieldValue)
                                      }}
                                      value={{
                                        value: SelectedShippingMethod,
                                        label: SelectedShippingMethod,
                                      }}
                                      placeholder='Shipping Method'
                                    />
                                  </div>
                                  <div className='col-md-12 mt-lg-2'>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      label='Tracking Number'
                                      style={{marginTop: '7px', width: '100%', zIndex: '0'}}
                                      className='form-control'
                                      name='tracking_number'
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='row gap-3'>
                              <div className='d-flex justify-content-between border-bottom pb-5'>
                                <h3 className='mb-1'>Totals</h3>
                                <span
                                  onClick={() => {
                                    setshow_gt_detail((prev_gt_detail) => !prev_gt_detail)
                                  }}
                                  className='text-end'
                                >
                                  {show_gt_detail ? (
                                    <KTSVG path={'/media/custom/up.svg'} />
                                  ) : (
                                    <KTSVG path={'/media/custom/down.svg'} />
                                  )}
                                </span>
                              </div>
                              {show_gt_detail && (
                                <div className='row'>
                                  <div className='col-md-5 p-lg-3 d-flex justify-content-end align-items-center'>
                                    <label
                                      className='d-block fw-bold text-muted'
                                      htmlFor='order_subtotal'
                                    >
                                      Order Subtotal
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 d-flex justify-content-center align-items-center'>
                                    <span className='text-muted fw-bold'>
                                      {NumberFormatter(formattedSubtotal)}
                                    </span>
                                  </div>
                                  <div className='col-md-5 p-lg-3 d-flex justify-content-end align-items-center'>
                                    <label
                                      className='d-block fw-bold text-muted'
                                      htmlFor='shipping_cost'
                                    >
                                      Shipping Cost
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 d-flex justify-content-center align-items-center'>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      type='number'
                                      className='custom-class_for_po'
                                      min={0}
                                      name='shipping_cost'
                                    />
                                  </div>
                                  <div className='col-md-5 p-lg-3  d-flex justify-content-end align-items-center'>
                                    <label className='d-block fw-bold text-muted'>Tax</label>
                                  </div>
                                  <div className='col-md-7 p-lg-3  d-flex justify-content-center align-items-center'>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      type='number'
                                      name='tax'
                                      min={0}
                                      className='custom-class_for_po'
                                    />
                                  </div>
                                  <div className='col-md-5 p-lg-3 border-top border-dark d-flex justify-content-end align-items-center mt-3'>
                                    <label
                                      className='d-block fw-bold text-dark'
                                      htmlFor='total_cost'
                                    >
                                      Grand Total
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 border-top border-dark d-flex justify-content-center align-items-center mt-3'>
                                    <span className='text-dark fw-bold'>
                                      {`$${
                                        (
                                          (Number(formattedSubtotal) || 0) +
                                          (Number(values?.shipping_cost) || 0) +
                                          (Number(values?.tax) || 0)
                                        )
                                          .toFixed(2)
                                          .toLocaleString() || ''
                                      }`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type='submit'
                        style={{width: '85%', margin: '0 auto'}}
                        className='btn btn-primary mt-5 d-flex justify-content-center'
                        disabled={loading || !isValid}
                      >
                        {!loading && 'Submit PO'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      />
    </>
  )
}
export {EditPurchaseOrder}
