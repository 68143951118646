/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {ListsWidget2} from '../../../../_metronic/partials/widgets'
import {useParams} from 'react-router-dom'
import {
  ALL_fetchWorkOrderTag,
  createWorkOrderTag,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  employeeList,
  getHoursUsedUrl,
  quoteAssignedToUrl,
  quoteFetchAssignedToUrl,
  FETCH_tagsForWorkOrder,
  viewQuote,
  UPDATE_tagsForWorkOrder,
} from '../../../modules/auth/core/_requests'
import {QuotesModel} from '../../../modules/model/quotes'
import {HeaderAction, HeaderContext} from '../../../context/HeaderContext'
import {useAuth} from '../../../modules/auth'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Doughnut} from 'react-chartjs-2'
import {AutocompleteChangeReason, createFilterOptions} from '@mui/material/Autocomplete'
import {Autocomplete, Checkbox, CircularProgress, TextField} from '@mui/material'
import {ProfileContext} from '../../../../_metronic/layout/MasterLayout'
import {Button, Modal} from 'react-bootstrap'
import {AddNote} from '../AddNote'
import {Projects} from './Projects'
import {TimeSheetTextConstant} from '../../../context/TimeSheetConstant'
import {ListsWidget12} from '../../../../_metronic/partials/widgets/lists/ListsWidget12'
import EmpInterface from '../../../modules/emp/EmpInterface'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {Documents} from './Documents'
import {HAS_QuoteAddNotes, HAS_WorkOrderAddNotes} from '../PermissionFunction'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function Overview() {
  var permissions = null
  const profileContext: any = useContext(ProfileContext)
  const [emplist, setEmplist] = useState<EmpInterface[]>([])
  const [assignedTo, setAssignedTo] = useState([])
  const [result, setResult] = useState<any>([])
  const [info, setInfo] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [tagsLoading, setTagsLoading] = useState(false)
  const [tagsList, setTagsList] = useState<any[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const filter = createFilterOptions<any>()
  const [hoursData, setHoursData] = useState<any[]>([])
  const [department_title, setDepartmentTitle] = useState<any[]>([])
  const [total_hours, setTotalHours] = useState('0')
  const [uploadFileModal, setUploadFileModal] = useState(false)
  const {type, id} = useParams<any>()
  const [modal, setModalShow] = useState<any>(false)
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [recentfile, setRecentfile] = useState([])
  const TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : TimeSheetTextConstant.WORKORDER
  const headerContext = useContext<any>(HeaderContext)
  const capitalizeFirst = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const fetchTags = useCallback(() => {
    setTagsLoading(true)
    FETCH_tagsForWorkOrder(id || '')
      .then(({data}) => {
        const response = data?.result[0]?.tags
        setSelectedTags(response || [])
        setTagsLoading(false)
      })
      .catch((e) => {
        setTagsLoading(false)
        console.error(e)
      })
  }, [id])

  const handleUpdateTag = useCallback(
    (selectedOption: any) => {
      const STRINGIFY_DATA = JSON.stringify(selectedOption)
      UPDATE_tagsForWorkOrder(id || '', STRINGIFY_DATA)
        .then(({data}) => {
          fetchTags()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [id]
  )
  const handleSelectChange = useCallback(
    (e: any, selectedOption: any, reason: AutocompleteChangeReason) => {
      console.log(e, selectedOption, reason)
      if (reason === 'createOption') {
        const newTag = selectedOption.pop()
        const isExisting = tagsList.some((option) => newTag === option.name)
        console.log(isExisting)
        if (!isExisting) {
          // the selected option is a new item
          handleCreateTag(newTag)
          return
        }
      } else if (reason === 'selectOption') {
        console.log(selectedOption)
        handleUpdateTag(selectedOption)
      } else if (reason === 'removeOption') {
        handleUpdateTag(selectedOption)
      }
    },
    [id]
  )

  const donut_data = {
    labels: department_title,
    datasets: [
      {
        data: hoursData,
        backgroundColor: [
          '#20A2E6',
          '#05AC57',
          '#ebe9e4',
          '#0B6C71',
          '#4E7332',
          '#B5556A',
          '#2E690B',
          '#EA0E06',
          '#D502E7',
          '#A7A1DA',
        ],
      },
    ],
  }

  const donut_options: any = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        maxWidth: 200,
      },
    },
  }

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: capitalizeFirst(`${type} Details`),
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/${type}`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = useCallback(async () => {
    setLoading(true)
    await viewQuote(id, '', 'clocked,item')
      .then((res) => {
        setResult(res.data.clocked_in?.data || [])
        let data = res.data.activities_grouping as any[]
        setInfo(data || [])
        setRecentfile(res.data.files_info || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  function secondsToHours(seconds: number) {
    const hours = seconds / 3600 // There are 3600 seconds in an hour
    return hours.toFixed(2)
  }

  const fetchHoursByDepartment = useCallback(() => {
    let formatted_hours: any[] = []
    let formatted_departments: any[] = []
    setLoading(true)
    getHoursUsedUrl(id)
      .then((res) => {
        setLoading(false)
        const hours_data = res?.data?.data
        const total_hours = res?.data?.sum

        Object.keys(hours_data).map((item: any) => {
          return formatted_hours.push(secondsToHours(hours_data[item]))
        })
        Object.keys(hours_data).map((item: any) => {
          return formatted_departments.push(item)
        })
        setDepartmentTitle(formatted_departments)
        setHoursData(formatted_hours)
        setTotalHours(secondsToHours(total_hours))
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  const getEmp = useCallback(async () => {
    setLoading(true)
    await employeeList(1)
      .then((res) => {
        const employes = res.data.employees as EmpInterface[]
        setEmplist(employes)
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  const fetchAssignedTo = useCallback(() => {
    setLoading(true)
    quoteFetchAssignedToUrl(id)
      .then((res) => {
        setLoading(false)
        setAssignedTo(res?.data?.result[0]?.assigned_to)
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  const callbackFnc = useCallback(() => {
    getEmp()
    getList()
    fetchAssignedTo()
    fetchHoursByDepartment()
    fetchTags()
  }, [getList, getEmp, fetchAssignedTo, fetchHoursByDepartment, fetchTags])

  const getTagsList = useCallback(() => {
    const TAGS_LIST_ARRAY: string[] | any = []
    setTagsLoading(true)
    ALL_fetchWorkOrderTag()
      .then(({data}) => {
        let LIST = data?.result
        LIST.forEach((e: any) => {
          TAGS_LIST_ARRAY.push({name: e.name, id: e.id})
        })
        setTagsList(TAGS_LIST_ARRAY)
        setTagsLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setTagsLoading(false)
      })
  }, [])

  useEffect(() => {
    callbackFnc()
    getTagsList()
  }, [callbackFnc, getTagsList])

  const onModalClose = useCallback(() => {
    setModalShow(false)
  }, [])

  const handleCreateTag = (selectedOption: any) => {
    setTagsLoading(true)
    const optionString = selectedOption
    createWorkOrderTag(optionString)
      .then(({data}) => {
        setTagsLoading(false)
        getTagsList()
        darkMessage('New Tag Created Successfully')
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setTagsLoading(false)
      })
  }
  useEffect(() => {
    getList()
    profileContext?.state?.setStatus(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext?.state?.clockIn, profileContext?.state?.status])

  const updatePrepared = async (e: any, value: any) => {
    setLoading(true)
    await quoteAssignedToUrl(id, JSON.stringify(value))
      .then((result) => {
        setAssignedTo(value)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        console.error(e)
      })
  }

  function setAutoCompleteValue(arg0: any) {
    throw new Error('Function not implemented.')
  }

  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-5 col-lg-6 col-md-6'>
        <div className=''>
          {/* {TIMESHEET_TEXT !== TimeSheetTextConstant.QUOTE && ( */}
          <div
            className='card-headerw-100 border-0 fw-bold cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <Projects />
          </div>
          {/* )} */}
          <div className='card-title m-0'>
            <div
              className='d-flex bg-white mb-n4'
              style={{
                justifyContent: 'space-between',
                borderRadius: ' 10px 10px 0 0px',
              }}
            >
              <h3 className='fw-bolder p-7'>Notes</h3>

              {type === 'quote'
                ? (permissions = HAS_QuoteAddNotes(currentUser))
                : (permissions = HAS_WorkOrderAddNotes(currentUser))}
              {permissions && (
                <button
                  onClick={() => setModalShow(1)}
                  style={{
                    height: '44px',
                    margin: '10px',
                    width: '130px',
                  }}
                  type='button'
                  className='btn btn-light'
                  // disabled={loading}
                >
                  Add Note
                </button>
              )}
            </div>
            <div
              style={
                TIMESHEET_TEXT !== TimeSheetTextConstant.QUOTE
                  ? {maxHeight: '330px', overflowX: 'scroll', background: '#fff'}
                  : {maxHeight: '520px', overflowX: 'scroll', background: '#fff'}
              }
            >
              <>
                {Object.keys(info).map((idx) => {
                  let item = info[idx]
                  return (
                    <ListsWidget12
                      className='rounded-0 pb-2'
                      list={item || []}
                      title={idx}
                      getList={getList}
                      getCustomerList={''}
                    />
                  )
                })}
              </>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-3 col-lg-6 col-md-6'>
        <div className='d-flex flex-column gap-6'>
          <div className='card p-7'>
            <div
              className='d-flex align-items-center mb-4'
              style={{fontSize: '25px', fontWeight: '600'}}
            >
              {total_hours}
              <span className='ms-3' style={{fontSize: '20px', color: '#ddd'}}>
                Total Hours Used
              </span>
            </div>
            {parseInt(total_hours) > 0 && (
              <div className=''>
                <Doughnut data={donut_data} options={donut_options} className='donut_canvas' />
              </div>
            )}
          </div>
          <div className='card p-7 c-uplaod-files'>
            <h2>
              Recent Files
              <button
                type='button'
                className='btn btn-secondary p-2 mx-3'
                style={{
                  height: '30px',
                  width: '30px',
                }}
                onClick={() => {
                  setUploadFileModal(true)
                }}
              >
                +
              </button>
            </h2>
            <div className='mt-lg-4'>
              {recentfile.slice(0, 3).map((r: any) => {
                return (
                  <>
                    <a
                      href={r.path}
                      target='_blank'
                      style={{color: 'rgb(0, 158, 247)'}}
                      rel='noreferrer'
                    >
                      {r.file_name}
                    </a>
                    <br />
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <AddNote onModalClose={onModalClose} getData={getList} onHide={() => setModalShow(false)} />
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={uploadFileModal}
        onHide={() => setUploadFileModal(false)}
        onModalClose={() => {
          setUploadFileModal(false)
        }}
        size='xl'
        className='fade'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h2>Upload Files</h2>
          </Modal.Title>
          <Button
            type='button'
            onClick={() => {
              setUploadFileModal(false)
            }}
            variant='dark'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Documents cb_func={callbackFnc} />
        </Modal.Body>
      </Modal>
      <div className='col-xl-4 col-lg-6 col-md-6'>
        <div style={{maxHeight: '220px', overflow: 'scroll'}}>
          <ListsWidget2 schema={result} className='mb-5 mb-xxl-8 p-sm-5 p-5' />
        </div>
        <div className='d-flex flex-column gap-4'>
          <div className='card p-5'>
            <h2>Assigned To Project</h2>
            <div className='d-flex flex-column gap-4 mt-lg-6'>
              <Autocomplete
                multiple
                id='checkboxes-tags-demo'
                disableCloseOnSelect
                options={emplist}
                value={assignedTo}
                getOptionLabel={(option: any) => option?.first_name + ' ' + option?.last_name}
                renderOption={(props, option: any, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.first_name + ' ' + option?.last_name}
                  </li>
                )}
                onChange={(e, value) => {
                  return updatePrepared(e, value)
                }}
                // sx={{width: 300}}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Assigned To'}
                    placeholder='Employees List'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='card p-5'>
            <h2>Tags</h2>
            <div className='d-flex flex-column gap-4 mt-lg-6'>
              <Autocomplete
                multiple
                freeSolo
                id='free-solo-with-text-demo'
                // sx={{width: 300}}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                getOptionLabel={(option: any) => option?.name}
                filterOptions={(options, params) => {
                  const {inputValue} = params
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.name)
                  if (inputValue !== '' && !isExisting) {
                    options.push({name: inputValue, id: -1}) // add new option with -1 as a temporary id
                  }
                  return options
                }}
                options={tagsList}
                value={selectedTags}
                loading={tagsLoading}
                onChange={(e, newval, reason) => {
                  handleSelectChange(e, newval, reason)
                }}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Create & Choose  Tags'
                    placeholder='Tags'
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
