/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers'
import {
  createBrand,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  deleteInventoryBrand,
  getInventoryManufacturers,
  updateInventoryBrand,
} from '../../modules/auth/core/_requests'
import {Brand} from '../../modules/model/Inventory'
import {Button, Modal} from 'react-bootstrap'
import {MaterialDataTable} from './MatrialDataTable'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {InventoryManagement} from './InventoryManagement'
import {useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  Inventory_Management,
  Inventory_Management_Initials as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useLocalStorage} from './GlobalComponents'

function ManufacturerCategories() {
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [value, setValue] = useState('')
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalRows, seTotalRows] = useState(0)
  const [tableStorage, setTableStorage] = useLocalStorage('editManufactColsVisibility', '')
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const getList = useCallback((page: number = 0, str = '', pagePerEntries: number = 100) => {
    setLoading(true)
    getInventoryManufacturers(page, str, pagePerEntries)
      .then((res) => {
        let categories = res?.data?.brands as Brand[]
        setData(categories)
        seTotalRows(res.data.totalRows)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getList(pagination.pageIndex, '', pagination.pageSize)
  }, [pagination])

  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  const PaginationCallback = useCallback((getPagination: any) => {
    getList(getPagination?.pageIndex, '', getPagination?.pageSize)
  }, [])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: `Category Manager`,
        subtitle: ` `,
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    getList()
  }, [])

  useEffect(() => {
    const search: any = searchParams.get('str')
    if (search === null) {
      getList()
    } else {
      getList(0, search)
    }
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])
  const MaterialColumns: any = useMemo(
    () => [
      {
        header: 'Manufacturer Name',
        accessorFn: (row: Brand) => row?.name,
        //uses the default width from defaultColumn prop
      },
      {
        header: 'Manufacturer Status',
        accessorFn: (row: Brand) => 'ACTIVE',
      },
      {
        header: 'Created By',
        accessorFn: (row: Brand) => row?.user?.first_name + ' ' + row?.user?.last_name,
      },
      {
        accessorFn: (row: Brand) => (
          <>
            <button
              className='btn btn-light mx-2'
              onClick={() => {
                updateCategory(row)
              }}
            >
              Update Manufacturer
            </button>
            <button
              className='btn btn-light mx-2'
              onClick={() => {
                deleteCategory(row)
              }}
            >
              Delete Manufacturer
            </button>
          </>
        ),
        header: 'Actions',
        size: 200, //decrease the width of this column
      },
    ],
    []
  )

  const Inventory_validation = Yup.object().shape({
    inventory_info: Yup.string().required(`Manufacturer is required`),
  })

  const formik = useFormik<Inventory_Management>({
    initialValues,
    validationSchema: Inventory_validation,
    onSubmit: (values) => {
      setLoading(true)
      createBrand(values.inventory_info)
        .then(({data}) => {
          setLoading(false)
          getList()
          formik.resetForm()
          handleClose()
          darkMessage('Manufacturer added successfully')
        })
        .catch((e) => {
          if (e.response.status === 409) {
            darkErrorMessage('Manufacturer already exists')
            setLoading(false)
            handleClose()
          }
        })
    },
  })
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const updateCategory = useCallback((idx: any) => {
    setShowModal({show: true, data: idx, label: 'update'})
  }, [])

  const onHandleUpdate = useCallback(
    (idx: any) => {
      updateInventoryBrand(idx.id, value)
        .then((res) => {
          setValue('')
          getList()
          darkMessage('Manufacturer updated Successfully')
          onModalClose()
        })
        .catch((e) => {
          if (e?.response.status === 409) {
            console.error(e?.response.data)
            darkErrorMessage(e?.response.data.error_list[0])
          } else {
            console.error(e)
          }
          onModalClose()
        })
    },
    [value]
  )

  const onHandleDelete = useCallback((idx: any) => {
    deleteInventoryBrand(idx.id)
      .then((res) => {
        getList()
        darkMessage('Manufacturer deleted Successfully')
        onModalClose()
      })
      .catch((e) => {
        if (e?.response.status === 409) {
          console.error(e?.response.data)
          darkErrorMessage(e?.response.data.error_list[0])
          onModalClose()
        } else {
          console.error(e)
          onModalClose()
        }
        onModalClose()
      })
  }, [])

  const deleteCategory = useCallback((idx: any) => {
    setShowModal({show: true, data: idx, label: 'delete'})
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal({show: false, data: ''})
  }, [])
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={showModal?.show}
        onHide={() => {
          onModalClose()
        }}
        size='xl'
        centered
        // className='bg-opacity-1 bg-black'
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          {showModal?.label === 'update' ? (
            <div className='row'>
              <label className='col-lg-3 required m-auto'>Enter New Manufacturer Name</label>
              <div className='col-lg-9'>
                <input
                  type='text'
                  className='form-control form-control-lg '
                  value={value}
                  onChange={(event) => {
                    setValue(event.target.value)
                  }}
                />
              </div>
            </div>
          ) : (
            <h3>Did you really want to delete this Manufacturer</h3>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              onModalClose()
            }}
          >
            Discard
          </button>
          {showModal?.label === 'update' ? (
            <button
              className='btn btn-primary'
              onClick={() => {
                onHandleUpdate(showModal?.data)
              }}
            >
              Update Manufacturer
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={() => {
                onHandleDelete(showModal?.data)
              }}
            >
              Delete Manufacturer
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <div className='card-body'>
        <MaterialDataTable
          MaterialColumns={MaterialColumns}
          MaterialColumnsApiData={data}
          totalRows={totalRows}
          loadingBar={loading}
          tableHeight={700}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          columnVisibility={tableStorage}
          cbColumnVisibility={(callbackData) => {
            cbFunction(callbackData)
          }}
          navigationLink={''}
          stopRedirection={false}
          optionalTableHeader={
            <div className='card-title m-0 d-flex d-inline align-items-center'>
              <h4 className='fw-bolder m-5 w-115px'>
                <KTSVG path='/media/icons/duotune/general/gen067.svg' className='svg-icon-1 me-3' />
                Manufacturer List
              </h4>
              <div className='mx-lg-3'>
                <UsersListSearchComponent />{' '}
              </div>
              <Button className='btn btn-primary h-35px w-35px fw-bold' onClick={handleShow}>
                <div style={{marginTop: '-7px', marginLeft: '-7px', fontSize: '20px'}}>+</div>
              </Button>
              <Modal
                style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
                show={show}
                centered
                className='fade'
                size='xl'
                onHide={handleClose}
              >
                <Modal.Body>
                  <div className='card'>
                    <div className='card-body'>
                      <h2 className='fw-bolder m-2'>Enter Manufacturer Name</h2>
                      <form onSubmit={formik.handleSubmit} noValidate className='form m-10'>
                        <div className='row g-6 g-xl-9'>
                          <input
                            className='form-control form-control-lg '
                            type='text'
                            id=''
                            {...formik.getFieldProps('inventory_info')}
                          />
                          {formik.touched.inventory_info && formik.errors.inventory_info && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.inventory_info}</div>
                            </div>
                          )}
                          <button
                            type='submit'
                            className='btn btn-primary '
                            style={{width: '230px'}}
                            disabled={loading || !formik.isValid}
                          >
                            {!loading && `Create Manufacturer`}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          }
        />
      </div>
    </>
  )
}

export default ManufacturerCategories
