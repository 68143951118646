/* eslint-disable jsx-a11y/anchor-is-valid */
// import {object} from 'prop-types'
import moment from 'moment'
import React, {useState, useEffect, useContext, useLayoutEffect} from 'react'
// import {Button} from 'react-bootstrap'
// import ProgressBar from 'react-bootstrap/ProgressBar'
import {allCurrentlyClockedIn} from '../../../app/modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {HAS_WhoClockedInView} from './PermissionFunction'

const TimeZoneModal = () => {
  const [data, setData] = useState<any>([])
  const headerContext = useContext<any>(HeaderContext)
  const {currentUser} = useAuth()

  // const [loading, setLoading] = useState(false)/
  // const now = 60
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'TimeZone',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTimeData = () => {
    allCurrentlyClockedIn()
      .then((res) => {
        setData(res.data?.data)
      })
      .catch((e) => {
        console.log('e', e.response)
      })
  }
  const timeConverter = (e: number) => {
    const time = e / 3600
    return time.toFixed(2)
  }
  const getDate = (date: any) => {
    let formattedDate = moment(date).format('LT')
    return formattedDate
  }
  useLayoutEffect(() => {
    getTimeData()
  }, [])
  return HAS_WhoClockedInView(currentUser) ? (
    <>
      {data ? (
        <>
          <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '20px'}}>
            {/* <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Timezone Details</h3>
        </div>
      </div> */}
            {data?.map((item: any) => {
              return (
                <>
                  <div className='card'>
                    <div className='card-body pt-5 pb-0'>
                      <div className='d-flex  col-md-12'>
                        <div className='d-flex me-7 col-md-3 mb-4 justify-content-center align-items-center position-relative'>
                          <img
                            alt='customer logo'
                            src={
                              item?.user?.profile_pic
                                ? item?.user?.profile_pic
                                : '/media/avatars/blank.png'
                            }
                            className='rounded-circle '
                            style={{width: '150px', height: '150px', objectFit: 'contain'}}
                          />
                          <div
                            className='position-absolute translate-middle bottom-0  mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'
                            style={{marginLeft: '132px'}}
                          ></div>
                        </div>
                        <div className='col-md-9'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <h1>
                                    {item?.user?.first_name.charAt(0).toUpperCase() +
                                      item?.user?.first_name.slice(1) +
                                      ' ' +
                                      item?.user?.last_name.charAt(0).toUpperCase() +
                                      item?.user?.last_name.slice(1)}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'>Label</div>
                                </div>
                                <div className='fw-bold fs-6 text-gray-400'>
                                  {item?.final_title}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'>Clocked In From</div>
                                </div>
                                <div className='fw-bold fs-6 text-gray-400'>
                                  {getDate(item?.start_time)}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'> Total Working Hours</div>
                                </div>
                                <div className='fw-bold fs-6 text-gray-400'>
                                  {timeConverter(item?.time_diff)}
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-md-6'>
                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'>Clocked In From</div>
                                </div>
                                <div className='fw-bold fs-6 text-gray-400'>
                                  {getDate(item?.start_time)}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
            <div className='col-md-12 mb-5  justify-content-center'>
              {/* <ProgressBar  style={{width:"90%",color:"white",height:"155%"}} variant="info" label={`${now}%`}  now={now} /> */}
            </div>
          </div>
        </>
      ) : (
        <h2 className='text-center'>There is No Data to Display</h2>
      )}
    </>
  ) : (
    <></>
  )
}

export {TimeZoneModal}
