import {UserModel} from './../../modules/auth/core/_models'
import {hasAccess, Permission} from './Permission'
import {TimeSheetTextConstant} from '../../../app/context/TimeSheetConstant'
//###################### Quote Menu  ############################
// export const HAS_QuoteMenu = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.Menu.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_QuoteView = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.View.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_DashboardQuoteAdd = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.Add.value,
//     currentUser
//   )
// }
// //###################### Reminder Menu  ############################
// export const HAS_ReminderMenu = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.Menu.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_ReminderView = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.View.value,
//     currentUser
//   )export const HAS_QuoteMenu = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.Menu.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_QuoteView = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.View.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_DashboardQuoteAdd = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardQuote.value,
//     Permission.DashboardQuote.Add.value,
//     currentUser
//   )
// }
// //###################### Reminder Menu  ############################
// export const HAS_ReminderMenu = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.Menu.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_ReminderView = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.View.value,
//     currentUser
//   )
// }
// //###############################################################################################################
// export const HAS_DashboardRemainderAdd = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.Add.value,
//     currentUser
//   )
// }
// }
// //###############################################################################################################
// export const HAS_DashboardRemainderAdd = (currentUser: UserModel | undefined) => {
//   return hasAccess(
//     Permission.DashboardRemainder.value,
//     Permission.DashboardRemainder.Add.value,
//     currentUser
//   )
// }

//######################  Customer Table Menu  ############################
export const HAS_DashboardMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Dashboard.value, Permission.Dashboard.Menu.value, currentUser)
}
//###############################################################################################################

export const HAS_CustomerTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.Menu.value, currentUser)
}
//###############################################################################################################

export const HAS_ServiceViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Services.value, Permission.Services.ViewTable.value, currentUser)
}
//###############################################################################################################

export const HAS_ServiceCreateTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Services.value, Permission.Services.CreateService.value, currentUser)
}
//###############################################################################################################

export const HAS_ServiceUpdateService = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Services.value, Permission.Services.UpdateService.value, currentUser)
}
//###############################################################################################################

export const HAS_ServiceDeleteService = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Services.value, Permission.Services.DeleteService.value, currentUser)
}
export const HAS_CustomerTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.ViewTable.value, currentUser)
}
//###############################################################################################################
export const HAS_CustomerCreate = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.CreateCustomer.value, currentUser)
}
//###############################################################################################################
export const HAS_CustomerView = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.ViewCustomer.value, currentUser)
}
//###############################################################################################################
export const HAS_CustomerUpdate = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.UpdateCustomer.value, currentUser)
}
//###############################################################################################################
export const HAS_CustomerDelete = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Customer.value, Permission.Customer.DeleteCustomer.value, currentUser)
}
//###################### Quotes Table Menu  ############################
export const HAS_QuoteTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_QuoteTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.ViewTable.value, currentUser)
}
//###############################################################################################################
export const HAS_QuoteCreate = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.CreateQuote.value, currentUser)
}
//###############################################################################################################
export const HAS_QuotePageView = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.ViewQuote.value, currentUser)
}
//###############################################################################################################
export const HAS_QuoteUploadFile = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.UploadFiles.value, currentUser)
}
//###############################################################################################################
export const HAS_QuoteAddNotes = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.AddNotes.value, currentUser)
}
//###############################################################################################################
export const HAS_QuoteUpdateStatus = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Quotes.value, Permission.Quotes.UpdateStatus.value, currentUser)
}
//######################  Project Table Menu  ############################
export const HAS_WorkOrderTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WorkOrder.value, Permission.WorkOrder.Menu.value, currentUser)
}

export const HAS_ServiceTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.FieldService.value, Permission.FieldService.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_WorkOrderTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WorkOrder.value, Permission.WorkOrder.ViewTable.value, currentUser)
}
//###############################################################################################################
export const HAS_ViewWorkOrder = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.WorkOrder.value,
    Permission.WorkOrder.ViewWorkOrder.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_CreateProject = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.WorkOrder.value,
    Permission.WorkOrder.CreateProject.value,
    currentUser
  )
}

//###############################################################################################################
export const HAS_WorkOrderUploadFiles = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WorkOrder.value, Permission.WorkOrder.UploadFiles.value, currentUser)
}
//###############################################################################################################
export const HAS_WorkOrderAddNotes = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WorkOrder.value, Permission.WorkOrder.AddNotes.value, currentUser)
}
//###############################################################################################################
export const HAS_WorkOrderUpdateStatus = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WorkOrder.value, Permission.WorkOrder.UpdateStatus.value, currentUser)
}
//###################### Inventory Table Menu  ############################
export const HAS_InventoryViewTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.ViewTable.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryViewItem = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.ViewItem.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryCreateItem = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.CreateItem.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryUpdateItem = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.UpdateItem.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryDeleteItem = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.DeleteItem.value, currentUser)
}
//###############################################################################################################
export const HAS_InventoryEditCategory = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Inventory.value, Permission.Inventory.EditCategory.value, currentUser)
}
//######################  Purchase Order Menu  ############################
export const HAS_PurchaseOrderViewTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.PurchaseOrder.value, Permission.PurchaseOrder.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_PurchaseOrderViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.PurchaseOrder.value,
    Permission.PurchaseOrder.ViewTable.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_PurchaseOrderCreatePO = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.PurchaseOrder.value,
    Permission.PurchaseOrder.CreatePO.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_PurchaseOrderViewPO = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.PurchaseOrder.value,
    Permission.PurchaseOrder.ViewPO.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_PurchaseOrderUpdatePO = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.PurchaseOrder.value,
    Permission.PurchaseOrder.UpdatePO.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_PurchaseOrderDeletePO = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.PurchaseOrder.value,
    Permission.PurchaseOrder.DeletePO.value,
    currentUser
  )
}

//######################  RMA Order Menu  ############################
export const HAS_RMAOrderViewTableMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.RMAOrder.value, Permission.RMAOrder.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_RMAOrderViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.RMAOrder.value, Permission.RMAOrder.ViewTable.value, currentUser)
}
//###############################################################################################################
export const HAS_RMAOrderCreateRMA = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.RMAOrder.value, Permission.RMAOrder.CreateRMA.value, currentUser)
}
//###############################################################################################################
export const HAS_RMAOrderViewRMA = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.RMAOrder.value, Permission.RMAOrder.ViewRMA.value, currentUser)
}
//###############################################################################################################
export const HAS_RMAOrderUpdateRMA = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.RMAOrder.value, Permission.RMAOrder.UpdateRMA.value, currentUser)
}

//######################  Employee Menu  ############################
export const HAS_EmployeesViewEMPMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Employees.value, Permission.Employees.Menu.value, currentUser)
}
//######################  Employee Update  ############################
export const HAS_EmployeesUpdateEmployeeMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Employees.value,
    Permission.Employees.UpdateEmployee.value,
    currentUser
  )
}

//######################  Employee Delete  ############################
export const HAS_EmployeesDeleteEmployeeMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Employees.value,
    Permission.Employees.DeleteEmployee.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_EmployeesViewEMP = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Employees.value, Permission.Employees.ViewEMP.value, currentUser)
}
//###############################################################################################################
export const HAS_EmployeesInviteEmployees = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Employees.value,
    Permission.Employees.InviteEmployees.value,
    currentUser
  )
}

//###################### User Management Menu  ############################
export const HAS_UserManagementViewDepartmentsMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.UserManagement.value,
    Permission.UserManagement.Menu.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_UserManagementViewDepartments = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.UserManagement.value,
    Permission.UserManagement.ViewDepartments.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_UserManagementUpdatePermissions = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.UserManagement.value,
    Permission.UserManagement.UpdatePermissions.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_UserManagementEditDepartments = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.UserManagement.value,
    Permission.UserManagement.EditDepartments.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_UserManagementDeleteDepartments = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.UserManagement.value,
    Permission.UserManagement.DeleteDepartments.value,
    currentUser
  )
}
//###################### Invited Employee Menu  ############################
export const HAS_InvitedEmployeeMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.InvitedEmployee.value,
    Permission.InvitedEmployee.Menu.value,
    currentUser
  )
}
//###################### Invited Employee View Table  ############################
export const HAS_InvitedEmployeeViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.InvitedEmployee.value,
    Permission.InvitedEmployee.ViewTable.value,
    currentUser
  )
}
//###################### Invited Employee Create Invited Employee  ############################
export const HAS_InvitedEmployeeCreateInvitedEmployee = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.InvitedEmployee.value,
    Permission.InvitedEmployee.CreateInvitedEmployee.value,
    currentUser
  )
}
//###################### Invited Employee Resend Invitation  ############################
export const HAS_InvitedEmployeeResendInvitation = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.InvitedEmployee.value,
    Permission.InvitedEmployee.ResendInvitation.value,
    currentUser
  )
}
//###################### Invited Employee Delete Invitation  ############################
export const HAS_InvitedEmployeeDeleteInvitation = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.InvitedEmployee.value,
    Permission.InvitedEmployee.DeleteInvitation.value,
    currentUser
  )
}
//###################### Who's Clocked In Menu  ############################
export const HAS_WhoClockedIn = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.WhoClockedIn.value, Permission.WhoClockedIn.Menu.value, currentUser)
}
//###################### Who's Clocked In View User  ############################
export const HAS_WhoClockedInView = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.WhoClockedIn.value,
    Permission.WhoClockedIn.ViewWhoClockedIn.value,
    currentUser
  )
}
//###################### TimeSheet Menu  ############################
export const HAS_TimesheetViewTimesheetMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Timesheet.value, Permission.Timesheet.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_TimesheetViewTimesheet = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Timesheet.value,
    Permission.Timesheet.ViewTimesheet.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_EmpTimesheetViewTimesheetMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.EmpTimesheet.value, Permission.EmpTimesheet.Menu.value, currentUser)
}
//###########################Settings Menu ###########################

export const HAS_SettingMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.Menu.value, currentUser)
}
//###############################################################################################################
export const HAS_DashboardMyCompany = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.MyCompany.value, currentUser)
}
//###############################################################################################################
export const HAS_DashboardQuoteAdd = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.DashboardQuote.value, currentUser)
}
//###############################################################################################################
export const HAS_AddTasks = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.AddTasks.value, currentUser)
}
//###############################################################################################################
export const HAS_DashboardRemainderAdd = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Settings.value,
    Permission.Settings.DashboardRemainder.value,
    currentUser
  )
}
//###############################################################################################################
export const HAS_Files = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.Files.value, currentUser)
}
//###############################################################################################################
export const HAS_MasqueradeView = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.Masquerade.value, currentUser)
}
//###############################################################################################################
export const HAS_ClosingTime = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.ClosingTime.value, currentUser)
}
//###############################################################################################################
export const HAS_StatusOption = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.StatusOption.value, currentUser)
}
//###############################################################################################################
export const HAS_Timezone = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.Timezone.value, currentUser)
}
export const HAS_Timezone_Panel = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Settings.value, Permission.Settings.Timezone.value, currentUser)
}

export const HAS_FieldTable = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.FieldService.value,
    Permission.FieldService.ViewTable.value,
    currentUser
  )
}
// export const HAS_FieldTableMenu = (currentUser: UserModel | undefined) => {
//   return hasAccess(Permission.FieldService.value, Permission.FieldService.Menu.value, currentUser)
// }
export const HAS_FieldPageView = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.FieldService.value,
    Permission.FieldService.ViewFieldService.value,
    currentUser
  )
}
export const HAS_CreateFieldService = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.FieldService.value,
    Permission.FieldService.CreateFieldService.value,
    currentUser
  )
}
export const HAS_FieldServiceUploadFiles = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.FieldService.value,
    Permission.FieldService.UploadFiles.value,
    currentUser
  )
}
export const HAS_FieldServiceUpdateStatus = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.FieldService.value,
    Permission.FieldService.UpdateFieldStatus.value,
    currentUser
  )
}

export const HAS_VendorMenu = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.Menu.value, currentUser)
}

export const HAS_VendorViewTable = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.ViewTable.value, currentUser)
}

export const HAS_VendorViewVendor = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.ViewVendor.value, currentUser)
}

export const HAS_VendorCreateVendor = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.CreateVendor.value, currentUser)
}

export const HAS_VendorDeleteVendor = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.DeleteVendor.value, currentUser)
}

export const HAS_VendorUpdateVendor = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.UpdateVendor.value, currentUser)
}

export const HAS_VendorPOTab = (currentUser: UserModel | undefined) => {
  return hasAccess(Permission.Vendor.value, Permission.Vendor.POTab.value, currentUser)
}

export const HAS_VendorLoginCredentialsTab = (currentUser: UserModel | undefined) => {
  return hasAccess(
    Permission.Vendor.value,
    Permission.Vendor.LoginCredentialsTab.value,
    currentUser
  )
}

// ###################### PERMISSION FOR PENCIL SIGN IN QUOTE ACTIVITY ################################
export const HAS_Activity_Edit = (currentUser: UserModel | undefined, data: any) => {
  const permission = currentUser?.id === data?.user?.user || data?.user ? true : false
  return permission
}

export const HAS_PO_Notes_Edit = (currentUser: UserModel | undefined, data: any) => {
  const permission = currentUser?.id === data?.id ? true : false
  return permission
}

// ###################### PERMISSION FOR PENCIL SIGN IN QUOTE ACTIVITY ################################
export const HAS_THREE_TAB_PERMISSION = (param: any) => {
  let permission: boolean = true
  if (param === TimeSheetTextConstant?.WORKORDER) {
    permission = false
  }
  if (param === TimeSheetTextConstant?.FIELDSERVICE) {
    permission = false
  }
  return permission
}

export const HAS_CONTRA_THREE_TAB_PERMISSION = (param: any) => {
  let permission: boolean = false
  if (param !== TimeSheetTextConstant?.QUOTE) {
    permission = true
  }

  return permission
}

export const HAS_VENDOR_NOTES_EDIT = (currentUser: UserModel | undefined, data: any) => {
  const permission = currentUser?.id === data?.id ? true : false
  return permission
}
