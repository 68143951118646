import {useCallback, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  fetchWorkOrderPurchaseOrders,
  currnetCompanyName,
} from '../../../modules/auth/core/_requests'
import {Button, LinearProgress} from '@mui/material'
import moment from 'moment'
import MaterialReactTable from 'material-react-table'
import DataTable from 'react-data-table-component'
import {CommaNumberFormatter, NumberFormatter} from '../GlobalComponents'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

export function ProjectPO() {
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleAction = (id: any) => {
    navigate(`/${prefixCompany}/inventory/purchase-order/${id}/view`)
  }

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.purchase?.id,
        size: 70,
        header: 'P.O.#', //uses the default width from defaultColumn prop
      },
      {
        accessorFn: (row: any) => row?.purchase?.vendor?.name,
        header: 'Vendor',
      },
      {
        accessorFn: (row: any) => row?.purchase?.item_count,
        header: 'Items Ordered',
      },
      {
        accessorFn: (row: any) => row?.purchase?.received_total,
        header: 'Items Received',
      },
      {
        accessorFn: (row: any) => moment(row?.purchase.date_ordered).format('ll'),
        header: 'Ordered Date',
      },
      {
        accessorFn: (row: any) => `$ ` + parseFloat(row?.purchase.total_cost).toFixed(2),
        header: 'PO Total',
      },
      {
        header: 'Action',
        accessorFn: (row: any) => (
          <Button
            variant='contained'
            className='btn btn-light'
            onClick={() => handleAction(row?.purchase?.id)}
          >
            View PO
          </Button>
        ),
      },
    ],
    []
  )

  const subColumns: any = useMemo(
    () => [
      {
        name: 'ITEM LOGO',
        width: '100px',
        selector: (row: any) => (
          <a href={row?.item?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${
                row?.item?.image_field
                  ? row?.item?.image_field
                  : '/media/custom/empty_inventory.svg'
              }`}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain'}}
            />
          </a>
        ),
      },
      {
        name: 'MFG',
        // selector: (row: any) => row?.item?.brand?.name || row?.item?.brand,
        selector: (row: any) => (
          <div
            className='w-auto'
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
            title={row?.item?.brand?.name}
          >
            {row?.item?.brand?.name}
          </div>
        ),
      },
      {
        name: 'MFG. PART #',

        selector: (row: any) => (
          <div
            className='w-auto'
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
            title={row?.item_sku}
          >
            {row?.item_sku}
          </div>
        ),
      },
      {
        name: 'ITEM NAME',
        selector: (row: any) => (
          <div
            className='w-auto'
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
            title={row?.item_name}
          >
            {row?.item_name}
          </div>
        ),
      },

      {
        name: 'UNIT COST',
        selector: (row: any) => NumberFormatter(row?.item_cost),
      },
      {
        name: 'ORDERED',
        selector: (row: any) => CommaNumberFormatter(row?.quantity),
      },
      {
        name: 'RECEIVED',
        selector: (row: any) => CommaNumberFormatter(row?.received),
      },
      {
        name: 'Ref. #',
        selector: (row: any) => row?.reference,
      },
    ],
    []
  )

  const getList = useCallback(async () => {
    setLoading(true)
    await fetchWorkOrderPurchaseOrders(id)
      .then((res) => {
        setData(res?.data?.notes || [])
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }, [id])

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <>
      {loading && <LinearProgress />}
      <MaterialReactTable
        columns={MaterialColumns}
        enableSorting={false}
        enableStickyHeader
        enableStickyFooter
        enableGlobalFilter={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableBottomToolbar={false}
        data={data}
        enableExpanding
        manualFiltering
        enableDensityToggle={false}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
          },
        }}
        muiTableBodyCellProps={{
          style: {fontSize: '15px', cursor: 'pointer'},
        }}
        enableFullScreenToggle={false}
        muiTableContainerProps={{sx: {maxHeight: `700px`}}}
        renderDetailPanel={({row}: any) => (
          <div style={{width: '100%'}}>
            <div style={{width: '100%', display: 'flex'}}>
              <div style={{flex: 1}}>
                <DataTable
                  pointerOnHover
                  highlightOnHover
                  columns={subColumns}
                  data={row?.original?.purchase?.purchase_order_items}
                  style={{width: '100%'}}
                />
              </div>
            </div>
          </div>
        )}
      />
    </>
  )
}
