/* eslint-disable react-hooks/exhaustive-deps */
// / eslint-disable jsx-a11y/anchor-is-valid /
import {useState} from 'react'
import {FC} from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import Button from 'react-bootstrap/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import SortableList, {SortableItem} from 'react-easy-sort'
import {arrayMoveImmutable} from 'array-move'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {currnetCompanyName, customerList} from '../../modules/auth/core/_requests'


interface PropsFormType {
  name: string
  type: string
  required: string | null | boolean
  option: [] | null
  input: string | number
  inpval: boolean | null
}
const CustomFormGen: FC = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [formArray, setFormArray] = useState<
    {name: ''; type: string; option: null; required: ''; input: ''; inpval: false}[]
  >([])
  const [formData, setFormData] = useState<PropsFormType>({
    name: '',
    type: '',
    option: null,
    required: '',
    input: '',
    inpval: false,
  })
  const [option, setOption] = useState({})
  const [error, setError] = useState<string | null>(null)
  const [count, setCount] = useState(0)
  const [appendForm, setAppendForm] = useState({})
  const [appendArray, setAppendArray] = useState([])
  const [input, setInput] = useState<number | any>(0)

  // custom status navigate function
  const cusformStatus = () =>{
    navigate(`/${prefixCompany}/custom-status`)
  }

  // Generate dynamic form-: store the label && type and required field in state
  const handleForm = () => {
    const {name, type} = formData
    if (!name) {
      return setError('name')
    } else if (!type) {
      return setError('type')
    } else {
      setError(null)
      setInput(input + 1)
      if (Object.values(option) != '') {
        console.log('ifoption', option, formData)
        let arr: Array<object | string>[] = []
        Object.keys(option).map((data) => {
          arr.push(option[data])
        })
        formData.option = arr
        formData.input = `input${input}`
        setFormArray((current: any) => [...current, formData])
        console.log('formData', formData)
      } else {
        formData.input = `input${input}`
        console.log('else formData', formData)
        setFormArray((current: any) => [...current, formData])
      }
      setFormData({name: '', type: '', option: null, required: false, input: '', inpval: false})
      setOption({})
      setCount(0)
    }

    setTimeout(() => {
      console.log('formArray', formArray)
    })
  }

  // delete select box option with this function
  const onFieldDelete = (key: number) => {
    let nArr = formArray
    nArr.splice(key, 1)
    setFormArray([])
    setTimeout(() => {
      setFormArray(nArr)
    }, 100)
  }

  //custom select option Array
  const select_value = [
    {value: 'text', label: 'Text'},
    {value: 'select', label: 'Select'},
  ]

  //uncheckedHandler function for unchecked checkbox
  const unCheckedHandler = (key: any, name: any) => {
    console.log('enter in function', key, name)
    let Arr = formArray
    formArray.map((e, i) => {
      formArray[key].required = ''
    })
    setFormArray([])
    setTimeout(() => {
      setError(null)
      setFormArray(Arr)
    }, 100)
  }

  // new append form data store in state :- appendArray
  const newFormHandler = () => {
    let erroCheck = false
    Object.values(formArray).map((data, key) => {
      if (data.required && data.inpval === false) {
        erroCheck = true
        setError(data.input)
      }
    })
    if (erroCheck) {
      return error
    } else {
      setAppendArray((current) => [...current, appendForm])
      setAppendForm({})
      setInput(0)
      setFormData({name: '', type: '', option: null, required: false, input: '', inpval: false})
      setFormArray([])
    }
  }

  // array move function
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setFormArray((formArray) => arrayMoveImmutable(formArray, oldIndex, newIndex))
  }

  return (
    <>
      <div className='card'>
        <div className='card-body border-top p-9'>
          <h2 className='fw-bolder m-2' >Add Custom Form</h2>
          <div className='row mb-4'>
            <div className='mt-5'>
              <Box
                component='form'
                sx={{'& > :not(style)': {m: 1, width: '50ch'}}}
                noValidate
                autoComplete='off'
              >
                {/* input name */}
                <div>
                  <TextField
                    error={error === 'name' && !formData.name ? true : false}
                    id={
                      error === 'name' && !formData.name
                        ? 'outlined-error-helper-text'
                        : 'outlined-name'
                    }
                    label={error === 'name' && !formData.name ? 'Error' : 'Name'}
                    value={formData.name ? formData.name : ''}
                    style={{width: '100%'}}
                    helperText={error === 'name' && !formData.name ? 'name is required' : ''}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                  />
                </div>
                {/* input type */}
                <div>
                  <TextField
                    error={error === 'type' && !formData.type ? true : false}
                    id={
                      error === 'type' && !formData.type
                        ? 'outlined-error-helper-text'
                        : 'outlined-select-currency'
                    }
                    helperText={error === 'type' && !formData.type ? 'type is required' : ''}
                    select
                    style={{width: '100%'}}
                    label='Choose Type'
                    value={formData.type ? formData.type : ''}
                    onChange={(e) => setFormData({...formData, type: e.target.value})}
                  >
                    {select_value.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* Multiple select box option start */}
                {formData.type === 'select' ? (
                  <>
                    <div>
                      <TextField
                        id='outlined-name'
                        label={'Option value'}
                        name='option1'
                        style={{width: '80%'}}
                        onChange={(e) => setOption({...option, [e.target.name]: e.target.value})}
                      />
                    </div>
                    {count
                      ? [...Array(count)].map((e, i) => {
                          return (
                            <div className='d-flex flex-row'>
                              <TextField
                                id='outlined-name'
                                label={'Option value'}
                                style={{width: '80%'}}
                                onChange={(e) =>
                                  setOption({...option, [`options${i}`]: e.target.value})
                                }
                              />
                            </div>
                          )
                        })
                      : null}
                    <div>
                      <Button
                        style={{
                          height: '40px',
                          marginLeft: '1%',
                          width: '12%',
                          justifyContent: 'center',
                        }}
                        onClick={() => setCount(count + 1)}
                      >
                        +
                      </Button>
                    </div>
                  </>
                ) : null}
                {/* Multiple select box option end */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({...formData, required: true})
                          } else {
                            setFormData({...formData, required: false})
                          }
                        }}
                      />
                    }
                    label='Required'
                  />
                </FormGroup>
                <Button className='mt-5 w-25' onClick={handleForm}>
                  Generate Form
                </Button>
              </Box>
            </div>
            {/* Dynamic form start */}
            <SortableList onSortEnd={onSortEnd} className='list' draggedItemClassName='dragged'>
              {formArray && formArray.length > 0
                ? formArray.map((val, key) => {
                    return (
                      <SortableItem key={val}>
                        <Box
                          component='form'
                          sx={{'& > :not(style)': {m: 1, width: '50ch'}}}
                          noValidate
                          autoComplete='off'
                        >
                          <div>
                            <div className='d-flex flex-row'>
                              <label className=' col-form-label fw-bold fs-6'>{val.name}</label>
                              {formArray[key] && formArray[key].required !== '' ? (
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={val.required ? true : false}
                                        color='error'
                                        onChange={() => unCheckedHandler(key, val.name)}
                                      />
                                    }
                                    label=''
                                  />
                                </FormGroup>
                              ) : null}
                            </div>
                            {val && val.type === 'select' ? (
                              <TextField
                                error={error === `${val.input}` ? true : false}
                                id={
                                  error === `${val.input}`
                                    ? 'outlined-error-helper-text'
                                    : 'outlined-select-currency'
                                }
                                select
                                style={{width: '70%'}}
                                label={error === `${val.input}` ? 'Error' : val.name}
                                required={val.required ? true : false}
                                onChange={(event) => {
                                  setAppendForm({
                                    ...appendForm,
                                    [`${val.input}`]: event.target.value,
                                  })
                                  if (event.target.value) {
                                    setError(null)
                                  }
                                  if (event.target.value && val.required == true) {
                                    val.inpval = true
                                  } else {
                                    val.inpval = false
                                  }
                                }}
                              >
                                {/* //dynamicForm(key,event,val.name) */}
                                {val.option
                                  ? val.option.map((data: any) => {
                                      return (
                                        <MenuItem key={data} value={data}>
                                          {data}
                                        </MenuItem>
                                      )
                                    })
                                  : null}
                              </TextField>
                            ) : (
                              <TextField
                                error={error === `${val.input}` ? true : false}
                                id={
                                  error === `${val.input}`
                                    ? 'outlined-error-helper-text'
                                    : 'outlined-name'
                                }
                                label={error === `${val.input}` ? 'Error' : val.name}
                                multiline={val.type === 'textarea' ? true : false}
                                style={{width: '70%'}}
                                required={val.required ? true : false}
                                onChange={(event) => {
                                  setAppendForm({
                                    ...appendForm,
                                    [`${val.input}`]: event.target.value,
                                  })
                                  if (event.target.value) {
                                    setError(null)
                                  }
                                  if (event.target.value && val.required == true) {
                                    val.inpval = true
                                  } else {
                                    val.inpval = false
                                  }
                                }}
                              />
                            )}
                            <Button
                              style={{height: '50px', marginLeft: '2px'}}
                              onClick={() => onFieldDelete(key)}
                            >
                              Delete
                            </Button>
                          </div>
                        </Box>
                      </SortableItem>
                    )
                  })
                : null}
            </SortableList>

            {formArray && formArray.length > 0 ? (
              <div>
                <Button className='mt-5 w-25' onClick={newFormHandler}>
                  Submit
                </Button>
              </div>
            ) : null}
            {/* Dynamic form end */}
          </div>
        </div>
      </div>
    </>
  )
}
export default CustomFormGen
