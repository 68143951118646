/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

const JsonFormatter = (string: string | null) => {
  try {
    if (string === null) {
      return null
    }
    var dummyString = string.replace('None', '""')
    var b = dummyString.replace(/'/g, '"')
    return b
  } catch {
    return null
  }
}

const ListsWidget13 = ({itemData}: any) => {
  const ITEM_ARRAY = itemData.title.split('==>')
  const OLDER_STATUS = JSON.parse(JsonFormatter(ITEM_ARRAY[0]) || 'null')
  const NEWER_STATUS = JSON.parse(JsonFormatter(ITEM_ARRAY[1]) || 'null')
  return (
    <>
      <span
        className='badge badge-light-primary cursor-pointer'
        style={{
          backgroundColor: OLDER_STATUS?.bgcolor ?? '#000000',
          color: OLDER_STATUS?.color ?? '#FFFFFF',
          fontSize: '12px',
          padding: '10px',
        }}
      >
        {OLDER_STATUS?.title}
      </span>
      <KTSVG path={'/media/custom/extra_yo.svg'} className='svg-icon-2' />
      <span
        className='badge badge-light-primary cursor-pointer'
        style={{
          backgroundColor: NEWER_STATUS?.bgcolor ?? '#000000',
          color: NEWER_STATUS?.color ?? '#FFFFFF',
          fontSize: '12px',
          padding: '10px',
        }}
      >
        {NEWER_STATUS?.title}
      </span>
    </>
  )
}

export {ListsWidget13}
