import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  inventoryItemData,
  inventoryListForPO,
  subquote_item_delete,
  updateItem,
  inventorySearchListForPO,
  GetItemToggle,
} from '../../modules/auth/core/_requests'
import {Menu, MenuItem, TextField, Select, Tooltip} from '@mui/material'
import {Field, Form, Formik} from 'formik'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {useNavigate, useParams} from 'react-router-dom'
import DeleteModal from './DeleteModal'
import {Modal} from 'react-bootstrap'

import SimpleDataTable from './SimpleDataTable'
import {useDebounce} from '../../../_metronic/helpers'
import DataTable from 'react-data-table-component'
import {InventoryForm} from './InventoryForm'
import blank from '../../../_metronic/layout/components/header/blank.png'

const SubquotesItemsView: React.FC = ({
  finalCost,
  setFinalCost,
  handleCostChange,
  handleQuantityChange,
  getMarkUp,
  handleMarkupChange,
  itemRowData,
  setItemRowData,
  markUp,
  setMarkUp,
  setUpdModalOpen,
  customerView,
  fieldArrayData,
  setFieldArrayData,
  setDisable,
  setItemTotal,
  rowId,
  getItem,
  item,
  setItem,
  viewItem,
  setViewItem,
  getQuantityItems,
  getCostItems,
  setQuantityItems,
  selectedOnRow,
  handleOnRow,
  toggleItem,
  setToggleItem,
  GetItemServices,
  itemService,
  data,
  setData,
  setItemRowId,
  setGetMarkUp,
  defaultMarkupUnit,
  defaultMarkup,
  setDefaultMarkup,
  setDefaultMarkUnit,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {id}: any = useParams<any>()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [create_inventory_modal, set_create_inventory_modal] = useState(false)
  const [options, setOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [toggleManufacturer, setToggleManufacturer] = useState(true)
  const [toggleMfg, setToggleMfg] = useState(true)
  const [toggleQuantity, setToggleQuantity] = useState(true)
  const [togglePrice, setTogglePrice] = useState(true)
  const [toggleTotal, setToggleTotal] = useState(true)
  const opens = Boolean(anchorEl)
  const [initialPrice, setInitialPrice] = useState(0)
  const [initialQuantity, setInitialQuantity] = useState(0)
  const [newItems, setNewItems] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchDiv, setSearchDiv] = useState(false)
  const [searchDiv2, setSearchDiv2] = useState(false)
  const [searchItems, setSearchItems] = useState<any>([])
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const popupRef = useRef()
  const [markupValue, setMarkupValue] = useState(0)
  const [markupUnit, setMarkupUnit] = useState('$')
  const handleOverallItemMarkup = (event: any) => {
    const newMarkupValue = parseFloat(event.target.value)
    setMarkupValue(newMarkupValue)
    mergedData.map((row, index) => handleMarkupChange(event, index, row))
  }
  const handleOverallItemMarkupUnit = (event: any) => {
    const newMarkupValueUnit = event.target.value
    setMarkupUnit(newMarkupValueUnit)
    mergedData.map((row, index) => handleOnRow(event, index, row))
  }

  const handleToggleItems = useCallback(
    async (toggleItem: any) => {
      await GetItemToggle(rowId, id, JSON.stringify(toggleItem))
        .then((res: any) => {
          console.log('toggle')
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    [rowId, id]
  )

  useEffect(() => {
    localStorage.setItem('toggleItem', JSON.stringify(toggleItem))
    handleToggleItems(toggleItem)
  }, [handleToggleItems, toggleItem])

  const handleItemToogle = (item_type: any) => {
    setToggleItem((prevState: any) => ({
      ...prevState,
      [item_type]: !prevState[item_type],
    }))
  }

  const handleOutsideClick = useCallback((event: {target: any}) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSearchDiv(false)
      setSearchDiv2(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'View Inventory Item',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getItemsList = useCallback(async () => {
    setLoading(true)

    await inventoryListForPO()
      .then((res) => {
        const inventoryItems = res?.data?.items
        setOptions(inventoryItems)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const getList = useCallback(
    async (page: number = 0, str = '') => {
      await inventoryItemData(page)
        .then((res) => {
          const itemData = res?.data?.items[0]
          setItem(itemData || [])
        })
        .catch((e) => {
          console.error(e)
        })
    },
    [id]
  )

  const handleClick = (row_Data: any, event: React.MouseEvent<HTMLButtonElement>) => {
    setItemRowData(row_Data)
    setAnchorEl(event.currentTarget)
  }
  const getSearchData = useCallback(
    async (searchTerms: any) => {
      setLoading(true)
      await inventorySearchListForPO(searchTerms)
        .then((res: {data: {items: any}}) => {
          const searchRes = res?.data?.items
          setSearchItems(searchRes || [])
          setLoading(false)
          searchRes.length === 0 ? setSearchDiv(true) : setSearchDiv(false)
          searchRes.length > 0 ? setSearchDiv2(true) : setSearchDiv2(false)
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
            setSearchItems([])
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [searchTerm]
  )
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length >= 3) {
      getSearchData(searchTerm)
    } else if (searchTerm.length === 0) {
      setSearchItems([])
      setSearchDiv(false)
    }
    return () => {}
  }, [getSearchData, searchTerm, debouncedSearchTerm])

  const mappedDataState1 = viewItem.map(
    (
      row: {
        id: any
        item_name: any
        item_mfg: {name: any}
        item_mfg_part: any
        image_field: any
        quantity: number
        item_cost: number
        finalCost: number
        markup: number
        priceUnit: any
      },
      index: number
    ) => {
      const prices =
        row?.finalCost + (row?.priceUnit === '%' ? row.finalCost * (row.markup / 100) : row.markup)
      return {
        id: row.id,
        item_name: row?.item_name,
        item_mfg: row?.item_mfg.name,
        item_mfg_part: row?.item_mfg_part,
        quantity: row?.quantity,
        item_cost: row?.finalCost,
        markup: row?.markup,
        priceUnit: row?.priceUnit,
        Total: prices * row?.quantity,
        image_field: row?.image_field,
      }
    }
  )

  const mappedDataState2 = fieldArrayData.map(
    (
      row: {name: any; sku: any; brand: {name: any}; item_cost: number},
      index: number,
      markup: number,
      priceUnit: number,
      mappedDataState1: any
    ) => {
      const indexs = viewItem.length

      const quantityForRow =
        getQuantityItems[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]
      const costForRow =
        getCostItems[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]

      const setGetFinal =
        costForRow === '' || costForRow === undefined ? row?.item_cost : costForRow
      const markupForRow =
        getMarkUp[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]
      const priceunit =
        selectedOnRow[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]

      const prices =
        setGetFinal +
        (priceunit === '%'
          ? setGetFinal *
            (markupForRow === '' ||
            markupForRow === undefined ||
            markupForRow === null ||
            markupForRow === isNaN
              ? 0
              : markupForRow / 100)
          : markupForRow === '' ||
            markupForRow === undefined ||
            markupForRow === null ||
            markupForRow === isNaN
          ? 0
          : markupForRow)

      return {
        index: index,
        item_name: row?.name,
        item_mfg: row?.brand?.name,
        item_mfg_part: row?.sku,
        quantity: quantityForRow,
        item_cost: setGetFinal === isNaN ? 0 : setGetFinal,
        markup: markupForRow === isNaN ? 0 : markupForRow,
        priceUnit: priceunit,
        Total:
          prices === undefined || prices === '' || prices === isNaN
            ? setGetFinal
            : prices * (quantityForRow ? quantityForRow : 0),
      }
    }
  )

  const mergedData = [...mappedDataState1, ...mappedDataState2]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const st = () => {
    const combinedData = mappedDataState1.concat(mappedDataState2)

    const total = combinedData.reduce((acc: any, obj: {Total: any}) => acc + obj.Total, 0)

    setItemTotal(total)
  }
  useEffect(() => {
    st()
  }, [st])
  useEffect(() => {
    const storedToggleManufacturer: string | null =
      localStorage.getItem('toggleManufacturer') ?? null
    if (storedToggleManufacturer !== null) {
      setToggleManufacturer(JSON.parse(storedToggleManufacturer))
    }
  }, [])
  useEffect(() => {
    const storedToggleMfg: string | null = localStorage.getItem('toggleMfg') ?? null
    if (storedToggleMfg !== null) {
      setToggleMfg(JSON.parse(storedToggleMfg))
    }
  }, [])
  useEffect(() => {
    const storedToggleQuantity: string | null = localStorage.getItem('toggleQuantity') ?? null
    if (storedToggleQuantity !== null) {
      setToggleQuantity(JSON.parse(storedToggleQuantity))
    }
  }, [])
  useEffect(() => {
    const storedTogglePrice: string | null = localStorage.getItem('togglePrice') ?? null
    if (storedTogglePrice !== null) {
      setTogglePrice(JSON.parse(storedTogglePrice))
    }
  }, [])
  useEffect(() => {
    const storedToggleTotal: string | null = localStorage.getItem('toggleTotal') ?? null
    if (storedToggleTotal !== null) {
      setToggleTotal(JSON.parse(storedToggleTotal))
    }
  }, [])
  // useEffect(() => {
  //   setMarkupValue(markupValue)
  // }, [markupValue])

  useEffect(() => {
    localStorage.setItem('toggleManufacturer', JSON.stringify(toggleManufacturer))
    localStorage.setItem('toggleMfg', JSON.stringify(toggleMfg))
    localStorage.setItem('toggleQuantity', JSON.stringify(toggleQuantity))
    localStorage.setItem('togglePrice', JSON.stringify(togglePrice))
    localStorage.setItem('toggleTotal', JSON.stringify(toggleTotal))
  }, [toggleManufacturer, toggleMfg, toggleQuantity, togglePrice, toggleTotal])

  const fadeStyleManufacturer: React.CSSProperties = {
    opacity: toggleItem['item_mfg'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const fadeStyleMfg: React.CSSProperties = {
    opacity: toggleItem['item_mfg_part'] ? 1 : 0.3,
    // opacity: toggleItem['item_mfg'] || ['itemMfg' === null] ? 1 : 0.3,

    transition: 'opacity 0.5s',
  }
  const fadeStyleQuantity: React.CSSProperties = {
    opacity: toggleItem['quantity'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const fadeStylePrice: React.CSSProperties = {
    opacity: toggleItem['price'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const fadeStyleTotal: React.CSSProperties = {
    opacity: toggleItem['total'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const existingStyle = {
    display: 'flex',
    alignItems: 'center',
  }
  const itemManufacturerStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemTotalStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemMfgStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemQunatityStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemPriceStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const combinedStyleManufacturer = {
    ...existingStyle,
    ...fadeStyleManufacturer,
  }
  const combinedStyleMfg = {
    ...existingStyle,
    ...fadeStyleMfg,
  }
  const combinedStyleQunatity = {
    ...existingStyle,
    ...fadeStyleQuantity,
  }
  const combinedStylePrice = {
    ...existingStyle,
    ...fadeStylePrice,
  }
  const combinedStyleTotal = {
    ...existingStyle,
    ...fadeStyleTotal,
  }
  const combinedManufacturerStyle: React.CSSProperties = {
    ...itemManufacturerStyle,
    ...fadeStyleManufacturer,
  }
  const combinedMfgStyle: React.CSSProperties = {
    ...itemMfgStyle,
    ...fadeStyleMfg,
  }
  const combinedQuantityStyle: React.CSSProperties = {
    ...itemQunatityStyle,
    ...fadeStyleQuantity,
  }
  const combinedPriceStyle: React.CSSProperties = {
    ...itemPriceStyle,
    ...fadeStylePrice,
  }
  const combinedPriceTotal: React.CSSProperties = {
    ...itemTotalStyle,
    ...fadeStyleTotal,
  }

  useEffect(() => {
    getItem()
    getList()
  }, [getList])

  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
    localStorage.setItem('isNewItemAssigned', 'true')
  }
  const handleItemClick = (e: any) => {
    alert('heelo')
  }
  const columns = []

  if (customerView === false) {
    columns.push({
      selector: (row: any) => (
        <Tooltip
          title={
            <img
              alt='customer logo'
              src={`${row?.image_field || '/media/custom/empty_inventory.svg'}`}
              onError={(evt: any) => {
                evt.target.src = blank
              }}
              width='200px'
              height='140px'
              style={{objectFit: 'contain'}}
              data-tip
            />
          }
          placement='left'
          arrow
        >
          <div style={{whiteSpace: 'normal'}}>{row.item_name}</div>
        </Tooltip>
      ),
      name: 'Item',
    })
  } else {
    columns.push({
      selector: (row: any) => <div style={{whiteSpace: 'normal'}}>{row?.item_name}</div>,
      name: 'Item',
      width: '200px',
    })
  }

  if (customerView === false) {
    columns.push({
      width: '145px',

      selector: (row: any) => <div style={combinedManufacturerStyle}>{row?.item_mfg}</div>,
      name: (
        <div style={combinedStyleManufacturer}>
          Manufacturer
          <div onClick={() => handleItemToogle('item_mfg')}>
            {toggleItem['item_mfg'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleItem['item_mfg'] === true) {
    columns.push({
      width: '145px',
      selector: (row: any) => <div style={combinedManufacturerStyle}>{row?.item_mfg}</div>,
      name: <div style={combinedStyleManufacturer}>Manufacturer</div>,
    })
  } else {
    columns.push({
      width: '145px',

      selector: (row: any) => '',
      name: '',
    })
  }
  if (customerView === false) {
    columns.push({
      width: '130px',
      name: (
        <div style={combinedStyleMfg}>
          Mfg.Part#
          <div onClick={() => handleItemToogle('item_mfg_part')}>
            {toggleItem['item_mfg_part'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),

      selector: (row: any) => <div style={combinedMfgStyle}>{row?.item_mfg_part}</div>,
      size: 200,
    })
  } else if (toggleItem['item_mfg_part'] === true) {
    columns.push({
      width: '130px',
      name: <div style={combinedStyleMfg}>Mfg.Part#</div>,

      selector: (row: any) => <div style={combinedMfgStyle}>{row?.item_mfg_part}</div>,
      size: 200,
    })
  } else {
    columns.push({
      width: '130px',
      name: <div style={combinedStyleMfg}></div>,

      selector: (row: any) => <div style={combinedMfgStyle}></div>,
      size: 200,
    })
  }

  if (customerView === false) {
    columns.push({
      selector: (row: any, index: Number) => (
        <>
          <div style={combinedQuantityStyle}>
            <input
              required
              type='number'
              className='form-control'
              defaultValue={row?.quantity}
              style={{padding: 4, borderBottom: '1px solid lightgrey', width: '60px'}}
              onChange={(e) => {
                handleQuantityChange(e, index, row)
              }}
            />
          </div>
        </>
      ),
      name: (
        <div style={combinedStyleQunatity}>
          Qty
          <div onClick={() => handleItemToogle('quantity')}>
            {toggleItem['quantity'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleItem['quantity'] === true) {
    columns.push({
      selector: (row: any, index: Number) => (
        <>
          <div style={{marginLeft: '60px'}}>{row?.quantity}</div>
        </>
      ),
      name: <div style={{marginLeft: '55px'}}>Qty</div>,
    })
  } else {
    columns.push({
      selector: (row: any, index: Number) => <div></div>,
      name: '',
    })
  }

  if (customerView === false) {
    columns.push({
      selector: (row: any, index: number) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span style={{marginRight: '5px'}}>$</span>
          <input
            required
            type='number'
            className='form-control'
            value={parseFloat(row?.item_cost).toFixed(2)} // Display two decimal places
            style={{padding: 4, borderBottom: '1px solid lightgrey', width: '60px'}}
            onChange={(e) => {
              handleCostChange(e, index, row)
            }}
          />
        </div>
      ),
      name: 'Cost',
    })

    columns.push({
      selector: (row: any, index: number) => (
        <>
          {/* {customerView === false && ( */}
          <div style={{display: 'flex', textAlign: 'start'}}>
            <input
              required
              // className='form-control'
              type='number'
              value={row?.markup || defaultMarkup}
              style={{padding: 4, width: '40px'}}
              onChange={(e) => handleMarkupChange(e, index, row)}
            />

            <Select
              className='priceUnit-p-btn'
              labelId='demo-simple-select-label'
              id='demo-simple-select-label'
              style={{height: '32px', width: '37px', borderRadius: '0px'}}
              value={
                row?.priceUnit === null || row?.priceUnit === undefined || row?.priceUnit === ''
                  ? '$'
                  : row?.priceUnit
              }
              onChange={(e) => {
                handleOnRow(e, index, row)
              }}
            >
              <MenuItem
                value='$'
                style={{
                  height: '25px',
                  width: '50px',
                  padding: '5px',
                }}
              >
                $
                <span style={{marginLeft: '6px'}}>
                  {row?.priceUnit === '$' && (
                    <i
                      className='bi-check2'
                      style={{color: 'rgb(25,118,210)', marginLeft: '5px'}}
                    ></i>
                  )}
                </span>
              </MenuItem>
              <MenuItem
                value='%'
                style={{
                  height: '25px',
                  width: '50px',
                  padding: '5px',
                }}
              >
                %
                <span style={{marginLeft: '6px'}}>
                  {row?.priceUnit === '%' && (
                    <i className='bi-check2' style={{color: 'rgb(25,118,210)'}}></i>
                  )}
                </span>
              </MenuItem>
            </Select>
          </div>
        </>
      ),
      name: 'Markup',
    })
  }

  if (customerView === false) {
    columns.push({
      selector: (row: any) => (
        <div style={combinedPriceStyle}>
          {(() => {
            const price = parseFloat(row?.item_cost) || 0
            const totalCost =
              price +
              (row?.priceUnit === '%'
                ? (price * parseFloat(row?.markup || defaultMarkup)) / 100
                : parseFloat(row?.markup || defaultMarkup))

            const formattedTotalCost = isNaN(totalCost) ? '0.00' : totalCost.toFixed(2)

            return `$${formattedTotalCost}`
          })()}
        </div>
      ),
      name: (
        <div style={combinedStylePrice}>
          Price
          <div onClick={() => handleItemToogle('price')}>
            {toggleItem['price'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleItem['price'] === true) {
    columns.push({
      selector: (row: any) => (
        <div style={combinedPriceStyle}>
          {(() => {
            const price = parseFloat(row?.item_cost) || 0 // Convert to float or default to 0
            const totalCost =
              price +
              (row?.priceUnit === '%'
                ? (price * parseFloat(row?.markup)) / 100
                : parseFloat(row?.markup))

            const formattedTotalCost = totalCost.toFixed(2) // Always display two decimal places

            return `$${formattedTotalCost}`
          })()}
        </div>
      ),
      name: <div style={combinedStylePrice}>Price</div>,
    })
  } else {
    columns.push({
      selector: (row: any) => <div style={combinedPriceStyle}></div>,
      name: '',
      width: '230px',
    })
  }
  if (customerView === false) {
    columns.push({
      selector: (row: any, index: Number) => (
        <div style={combinedPriceTotal}>
          ${row?.Total !== undefined ? row?.Total.toFixed(2) : '0.00'}
        </div>
      ),

      name: (
        <div style={combinedStyleTotal}>
          Total
          <div onClick={() => handleItemToogle('total')}>
            {toggleItem['total'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleItem['total'] === true) {
    columns.push({
      selector: (row: any, index: Number) => (
        <div style={combinedPriceTotal}>
          $
          {row?.Total !== undefined
            ? parseFloat(row?.Total).toFixed(2) // Display two decimal places
            : '0.00'}
        </div>
      ),

      name: <div style={combinedStyleTotal}>Total</div>,
    })
  } else {
    columns.push({
      selector: (row: any, index: Number) => <div style={combinedPriceTotal}></div>,

      name: <div style={combinedStyleTotal}></div>,
    })
  }
  if (customerView === false) {
    columns.push({
      selector: (row: any, id: any) => (
        <>
          {customerView === false && (
            <button
              className='btn-white btn'
              id='basic-button'
              aria-controls={opens ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={opens ? 'true' : undefined}
              onClick={(e: any) => {
                handleClick(row, e)
              }}
            >
              <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
            </button>
          )}
        </>
      ),
      name: customerView === true ? '' : 'Actions',
    })
  }

  const MaterialSearchItemsCol: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => (
          <>
            <a href={row?.image_field || null} target='blank'>
              <img
                alt='customer logo'
                src={`${row?.image_field || '/media/custom/empty_inventory.svg'}`}
                onError={(evt: any) => {
                  evt.target.src = blank
                }}
                width='70px'
                height={'70px'}
                style={{objectFit: 'contain'}}
              />
            </a>
          </>
        ),
        accessorKey: 'inventory_image',
      },
      {
        accessorFn: (row: any) => row.name,
        accessorKey: 'item name',
      },
      {
        accessorFn: (row: any) => row?.brand?.name,
        accessorKey: 'mfg',
      },
      {
        accessorFn: (row: any) => row?.sku,
        accessorKey: 'mfg part #',
      },
    ],
    []
  )
  const handleFieldArrayItems = useCallback(
    (index_data: any) => {
      const dataArrayCopy: any = [...fieldArrayData]
      dataArrayCopy.push(index_data)
      setFieldArrayData(dataArrayCopy)
      setSearchTerm('')
    },
    [fieldArrayData]
  )

  useEffect(() => {
    if (isModalOpen === false) {
      setInitialPrice(0)
      setInitialQuantity(0)
    }
    if (isModalOpen === true) {
      getItemsList()
      setNewItems([])
    }
  }, [initialPrice, initialQuantity, isModalOpen])

  function addObject(newObject: any) {
    const index = newObject.index
    const existingObjectIndex = newItems.findIndex((obj: any) => obj.index === index)
    if (existingObjectIndex !== -1) {
      const updatedArray = [...newItems]
      updatedArray[existingObjectIndex] = newObject
      setNewItems(updatedArray)
    } else {
      setNewItems((prevArray: any) => [...prevArray, newObject])
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function removeItem() {
    setDeleteModal(true)
    handleClose()
  }

  const deleteQuote = (id: any, index: number) => {
    if (id === undefined) {
      const updatedFieldArrayData = [...fieldArrayData]
      updatedFieldArrayData.splice(index, 1)
      setFieldArrayData(updatedFieldArrayData)
    } else {
      subquote_item_delete(id)
        .then((res) => {
          let result = res?.data?.error
          darkMessage('SubQuote Item deleted Successfully')
          setDeleteModal(false)
          getItem()
        })
        .catch((e: {response: {status: number; data: string}}) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            setDeleteModal(false)
          }
        })
      getItem()
    }
  }
  useEffect(() => {
    setDefaultMarkUnit(defaultMarkupUnit)
  }, [defaultMarkupUnit, setDefaultMarkUnit])

  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])
  // console.log(defaultMarkup)
  // console.log(defaultMarkupUnit)
  return (
    <>
      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-alpine'>
          <div style={{width: '100%'}} className='d-flex align-items-center position-relative my-1'>
            <span
              style={{marginLeft: '10px', fontSize: '18px', color: 'rgb(64,64,64)'}}
              className='fw-bolder my-5 w-115px'
            >
              Items
            </span>

            {customerView === false ? (
              <>
                <span style={{marginLeft: '13px', marginBottom: '16px'}}>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-4 position-absolute ms-5'
                  />
                </span>
                <input
                  style={{fontSize: '14px', padding: '7px 10px'}}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-200px ps-14 search-wrap'
                  placeholder='Search to Add Item'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </>
            ) : (
              ''
            )}
            <div style={{marginLeft: '190px'}}>
              {customerView === false ? (
                <>
                  <span
                    style={{marginLeft: '10px', fontSize: '18px', color: 'rgb(64,64,64)'}}
                    className='fw-bolder my-5 w-115px'
                  >
                    Overall Item Markup
                  </span>
                  <input
                    required
                    type='number'
                    style={{padding: 7, width: '50px', marginLeft: '10px'}}
                    defaultValue={defaultMarkup}
                    onChange={handleOverallItemMarkup}
                  />{' '}
                  <Select
                    className='priceUnit-p-btn'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select-label'
                    style={{height: '32px', width: '37px', borderRadius: '0px'}}
                    defaultValue={defaultMarkupUnit}
                    onChange={handleOverallItemMarkupUnit}
                  >
                    <MenuItem
                      value='$'
                      style={{
                        height: '25px',
                        width: '50px',
                        padding: '5px',
                      }}
                    >
                      $
                      {/* <span style={{marginLeft: '6px'}}>
                        {markupUnit === '$' && (
                          <i
                            className='bi-check2'
                            style={{color: 'rgb(25,118,210)', marginLeft: '5px'}}
                          ></i>
                        )}
                      </span> */}
                    </MenuItem>
                    <MenuItem
                      value='%'
                      style={{
                        height: '25px',
                        width: '50px',
                        padding: '5px',
                      }}
                    >
                      %
                      {/* <span style={{marginLeft: '6px'}}>
                        {markupUnit === '%' && (
                          <i className='bi-check2' style={{color: 'rgb(25,118,210)'}}></i>
                        )}
                      </span> */}
                    </MenuItem>
                  </Select>
                </>
              ) : (
                ''
              )}
            </div>
          </div>

          <Modal
            show={create_inventory_modal}
            onHide={() => set_create_inventory_modal(false)}
            onModalClose={() => set_create_inventory_modal(false)}
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            style={{backgroundColor: '#170b0bcf'}}
            centered
          >
            <InventoryForm
              set_create_inventory_modal={set_create_inventory_modal}
              checkIsInventoryModal={true}
              closeInventoryModal={() => {
                set_create_inventory_modal(false)
              }}
            />
          </Modal>
          {searchItems && searchItems.length > 0 && searchDiv2 === true && (
            <div
              ref={popupRef}
              className='pop_up_table'
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                zIndex: '3',
                marginTop: '13px',
                marginLeft: '55px',
                border: '2px solid ',
                maxHeight: '400px',
                overflow: 'scroll',
              }}
            >
              <SimpleDataTable
                MaterialColumns={MaterialSearchItemsCol}
                MaterialColumnsApiData={searchItems}
                stopRedirection={true}
                handleClick={(cb_data: any) => {
                  handleFieldArrayItems(cb_data)
                }}
              />
            </div>
          )}
          {searchDiv === true && (
            <div
              ref={popupRef}
              className='pop_up_table'
              style={{
                position: 'absolute',
                zIndex: '1',
                marginTop: '13px',
                marginLeft: '55px',
                border: '2px solid ',
                maxHeight: '400px',
                overflow: 'scroll',
                background: 'white',
              }}
            >
              <div style={{width: '539px', backgroundColor: 'rgb(255,255,255)', height: '50px'}}>
                <button
                  style={{
                    marginTop: '9px',
                    marginLeft: '150px',
                    backgroundColor: 'rgb(199,229,255)',
                    color: 'rgb(107,176,255)',
                    height: '35px',
                    width: '250px',
                    border: 'none',
                    fontSize: '15px',
                    borderRadius: '7px 7px 7px 7px',
                  }}
                  type='button'
                  onClick={() => {
                    set_create_inventory_modal(true)
                  }}
                >
                  + Create New Inventory Item
                </button>
              </div>
            </div>
          )}
          {searchDiv === false && ''}
        </div>
      </div>

      <div className='t-border-top vendors-desc-table'>
        {mergedData.length === 0 ? (
          <p
            style={{
              marginLeft: '450px',
              color: 'rgb(39,50,63)',
              fontSize: '12.5px',
              marginTop: '20px',
              letterSpacing: '0.2px',
            }}
          >
            No Items assigned to Quote
          </p>
        ) : (
          <>
            <DataTable
              pointerOnHover
              highlightOnHover
              columns={columns}
              data={mergedData}
              customStyles={{
                rows: {
                  style: {
                    marginTop: '8px',
                    marginRight: '5px',
                  },
                },
              }}
            />
          </>
        )}
        <Menu
          id='basic-menu-1'
          anchorEl={anchorEl}
          open={opens}
          onClose={() => handleClose()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              removeItem()
            }}
          >
            Remove Item
          </MenuItem>
        </Menu>
        <DeleteModal
          modalShow={openDeleteModal}
          title={'Confirmation Message'}
          body={`Are you sure you want to delete this item #${itemRowData?.item_name} ?`}
          handleClose={() => {
            setDeleteModal(false)
            setItemRowData(null)
          }}
          confirmFunction={() => {
            deleteQuote(itemRowData?.id, itemRowData?.index)
            setDeleteModal(false)
          }}
        />

        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', backdropFilter: 'blur(2px)'}}
          className='fade'
          show={isUpdateModalOpen}
          onHide={() => setIsUpdateModalOpen(false)}
          centered
        >
          <Modal.Body style={{border: '1px-solid'}}>
            <h1>Update Item #{itemRowData?.item_name} Quantity</h1>
          </Modal.Body>
          <Formik
            initialValues={itemRowData}
            onSubmit={async (values, {resetForm}) => {
              const assigned_items_data = {
                id: values?.id,
                quantity: values?.quantity,
                // markup: markupValue,
              }
              await updateItem(assigned_items_data).then(({data}) => {
                darkMessage(data?.message)
                setUpdModalOpen(false)
                setIsUpdateModalOpen(false)
                getItem()
                getList()
              })
              getItem().catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
                if (e?.response?.status === 409) {
                  const message = e?.response?.data?.error_list[0]
                  darkErrorMessage(message)
                } else {
                  console.error(e)
                }
              })
            }}
          >
            {({values, errors, getFieldProps, setFieldValue, isValid}) => (
              <Form>
                <Modal.Body style={{height: '200px', border: '1px-solid'}}>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <Field
                        name='quantity'
                        render={({field}: any) => (
                          <TextField {...field} id='Quantity' label='Quantity' variant='outlined' />
                        )}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-row-reverse mx-2 '>
                    <span className='mx-2'>
                      <button
                        onClick={() => {
                          setItem((prev: any) => {
                            return [...prev, ...newItems]
                          })
                          setIsModalOpen(false)
                        }}
                        className='btn btn-primary'
                        disabled={loading || !isValid}
                      >
                        {!loading && 'Submit'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </span>
                    <span className='mx-1'>
                      <button
                        type='button'
                        onClick={() => {
                          setIsUpdateModalOpen(false)
                          setNewItems([])
                        }}
                        className='btn btn-dark'
                      >
                        Discard
                      </button>
                    </span>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  )
}
export {SubquotesItemsView}
