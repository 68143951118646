import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {EmployeesWrapper} from '../pages/dashboard/EmployeesWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {DashboardPanelWrapper} from '../pages/dashboard/CompanyWrapper'
import {Customers} from '../pages/dashboard/Customers'
import Vendors from '../pages/dashboard/Vendors'

import {Invite} from '../pages/dashboard/Invite'
import {Quote} from '../pages/dashboard/Quote'
import {useAuth} from '../modules/auth'
import {CustomerForm} from '../pages/dashboard/CustomerForm'
import {CustomerView} from '../pages/dashboard/CustomerView'
import {CustomerCustomView} from '../pages/dashboard/CustomerCustomView'
import VendorsView from '../pages/dashboard/VendorsView'
import {QuoteView} from '../pages/dashboard/QuoteView'
import NewFieldForm from '../pages/dashboard/NewFieldForm'
import {Quotes} from '../pages/dashboard/Quotes'
import {ServiceField} from '../pages/dashboard/ServiceField'
import FieldServiceForm from '../pages/dashboard/FieldServiceForm'

import {EditCustomer} from '../pages/dashboard/EditCustomer'
import {PurchaseOrders} from '../pages/dashboard/PurchaseOrders'
import {EditPurchaseOrder} from '../pages/dashboard/EditPurchaseOrder'
import {RMACreate} from '../pages/dashboard/RMACreate'
import {RMAOrders} from '../pages/dashboard/RMAorders'
import {InventoryManagement} from '../pages/dashboard/InventoryManagement'
import {Inventory} from '../pages/dashboard/Inventory'
import ManufacturerCategories from '../pages/dashboard/ManufacturerCategories'
import {InventoryForm} from '../pages/dashboard/InventoryForm'
import {InventoryView} from '../pages/dashboard/InventoryView'
import {EditInventory} from '../pages/dashboard/EditInventory'
import {PurchaseOrderForm} from '../pages/dashboard/PurchaseOrderForm'
import {InventoryCategories} from '../pages/dashboard/InventoryCategories'
import {EditNotes} from '../pages/dashboard/EditNotes'
import {ProfileHeader} from '../modules/profile/ProfileHeader'
import {SubquotesHeader} from '../pages/dashboard/SubquotesHeader'
import {ProfileDetails} from '../modules/accounts/components/settings/cards/ProfileDetails'
import {CompanyHeader} from '../modules/profile/CompanyHeader'
import WorkOrder from '../pages/dashboard/WorkOrder'
import FieldService from '../pages/dashboard/FieldService'
import MainDash from '../pages/dashboard/MainDash'
import {Timesheet} from '../pages/dashboard/Timesheet'
import {AccountSetting} from '../pages/dashboard/AccountSetting'
import React from 'react'
import {TimeCardPage} from '../pages/dashboard/TimeCardPage'
import {EmployeeTimeCardPage} from '../pages/dashboard/EmployeeTimeCardPage'
import {UserManagementPage} from '../pages/dashboard/UserManagementPage'
import {ManageUserPermission} from '../pages/dashboard/ManageUserPermission'
import AddQuotes from '../pages/dashboard/AddQuotes'
import AddTasks from '../pages/dashboard/AddTasks'
import TasksToEmp from '../pages/dashboard/TasksToEmp'
import AddReminder from '../pages/dashboard/AddReminder'
import {currnetCompanyName} from '../modules/auth/core/_requests'
import {PurchaseOrderView} from '../pages/dashboard/PurchaseOrderView'
import CustomFormGen from '../pages/dashboard/CustomFormGen'
import CustomStatusTable from '../pages/dashboard/CustomStatus'
import {Excel} from '../pages/dashboard/Excel'
import {Masquerade} from '../pages/dashboard/Masquerade'
import {Timezone} from '../pages/dashboard/Timezone'
import Lifetime from '../pages/dashboard/Lifetime'

import {TimeZoneModal} from '../pages/dashboard/TimeZoneModal'
import Invitation from '../pages/dashboard/Invitation'
import CompanyFiles from '../pages/dashboard/CompanyFiles'
import ClockOut from '../pages/dashboard/ClockOut'
import {VendorForm} from '../pages/dashboard/VendorForm'
import {EditVendor} from '../pages/dashboard/EditVendor'
import ShippingMethods from '../pages/dashboard/ShippingMethods'
import {HiddenPage} from '../pages/dashboard/HiddenPage'
import Tags from '../pages/dashboard/Tags'
import Services from '../pages/dashboard/Services'
const PrivateRoutes = (fieldArrayData: any, setFieldArrayData: any) => {
  const ProfilePage = lazy(() => import('../pages/dashboard/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={`/${prefixCompany}/dashboard`} />} />
        <Route path='auth/*' element={<Navigate to={`/:companyname/dashboard`} />} />
        {/* Pages */}
        {/* <Route path={`/${prefixCompany}/dashboard`} element={<MainDash />} /> */}
        <Route path={`/${prefixCompany}/dashboard`} element={<DashboardWrapper />} />
        <Route path='/dashboard' element={<DashboardWrapper />} />
        <Route path={`/${prefixCompany}/panel`} element={<DashboardPanelWrapper />} />
        <Route path={`/${prefixCompany}/employee/view`} element={<EmployeesWrapper />} />
        <Route path={`/${prefixCompany}/customers`} element={<Customers />} />
        <Route path={`/${prefixCompany}/inventory/vendors`} element={<Vendors />} />

        <Route path={`/${prefixCompany}/timesheet`} element={<Timesheet />} />

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route path={`/${prefixCompany}/employees/invitations`} element={<Invite />} />
        <Route path={`/${prefixCompany}/customers/create-customer`} element={<CustomerForm />} />
        <Route path={`/${prefixCompany}/inventory/vendors/create`} element={<VendorForm />} />
        {/* <Route path={`/${prefixCompany}/customers/view/:id`} element={<CustomerView />} /> */}
        <Route path={`/${prefixCompany}/customers/edit/:id`} element={<EditCustomer />} />
        <Route path={`/${prefixCompany}/inventory/vendors/view/:id`} element={<VendorsView />} />
        <Route path={`/${prefixCompany}/inventory/vendors/edit/:id`} element={<EditVendor />} />

        <Route path={`/${prefixCompany}/customers/view/:id`} element={<CustomerCustomView />} />
        {/* <Route path={`/${prefixCompany}/customer/edit/:id`} element={<CustomerForm />} /> */}
        <Route path={`/${prefixCompany}/quotes-view/:id`} element={<QuoteView />} />
        <Route path={`/${prefixCompany}/quote`} element={<Quotes />} />
        <Route path={`/${prefixCompany}/fieldservice`} element={<ServiceField />} />
        {/* <Route path={`/${prefixCompany}/fieldservice/create`} element={<FieldServiceForm />} /> */}

        <Route path={`/${prefixCompany}/excel`} element={<Excel />} />
        <Route path={`/${prefixCompany}/fieldservice/createnew`} element={<NewFieldForm />} />

        <Route path={`/${prefixCompany}/custom-dynamic-form`} element={<CustomFormGen />} />
        {/* <Route path={`/${prefixCompany}/settings/quotes/create`} element={<Quote />} /> */}
        <Route path={`/${prefixCompany}/settings/add-reminder`} element={<AddReminder />} />
        <Route path={`/${prefixCompany}/settings/add-quotes`} element={<AddQuotes />} />
        <Route path={`/${prefixCompany}/settings/tasks/create`} element={<AddTasks />} />
        <Route path={`/${prefixCompany}/settings/tasks`} element={<TasksToEmp />} />
        {/* <Route path={`/${prefixCompany}/settings/custom-status`} element={<CustomStatusTable />} /> */}
        <Route path={`/${prefixCompany}/settings/masquerade`} element={<Masquerade />} />
        <Route path={`/${prefixCompany}/console/`} element={<HiddenPage />} />
        <Route path={`/${prefixCompany}/settings/timezone`} element={<Timezone />} />
        <Route path={`/${prefixCompany}/customers/settings`} element={<Lifetime />} />
        <Route path={`/${prefixCompany}/settings/clock_out_time`} element={<ClockOut />} />
        <Route path={`/${prefixCompany}/settings/files`} element={<CompanyFiles />} />

        <Route path={`/${prefixCompany}/employee/whoclockedIn`} element={<TimeZoneModal />} />

        <Route path={`/${prefixCompany}/inventory/store/:id/view`} element={<InventoryView />} />

        <Route path={`/${prefixCompany}/inventory/store/:id/edit`} element={<EditInventory />} />
        <Route path={`/${prefixCompany}/inventory/purchase-order`} element={<PurchaseOrders />} />
        <Route
          path={`/${prefixCompany}/inventory/purchase-order/edit/:id`}
          element={<EditPurchaseOrder />}
        />
        <Route
          path={`/${prefixCompany}/inventory/purchase-order/create`}
          element={<PurchaseOrderForm />}
        />
        <Route
          path={`/${prefixCompany}/inventory/purchase-order/:id/view`}
          element={
            <PurchaseOrderView
              jio={0}
              jid={undefined}
              pdata={false}
              updateTable={undefined}
              recretM={function (ev: boolean): {} {
                throw new Error('Function not implemented.')
              }}
            />
          }
        />
        <Route path={`/${prefixCompany}/inventory/return-order`} element={<RMAOrders />} />
        <Route path={`/${prefixCompany}/inventory/store`} element={<Inventory />} />
        <Route path={`/${prefixCompany}/inventory/store/create`} element={<InventoryForm />} />
        <Route
          path={`/${prefixCompany}/inventory/store/categories`}
          element={<InventoryCategories />}
        />
        <Route
          path={`/${prefixCompany}/inventory/store/manufacturer`}
          element={<ManufacturerCategories />}
        />
        <Route
          path={`/${prefixCompany}/inventory/store/shipping_methods`}
          element={<ShippingMethods />}
        />
        <Route path={`/${prefixCompany}/:type/tags`} element={<Tags />} />
        <Route
          path={`/${prefixCompany}/inventory/create/:method`}
          element={<InventoryManagement />}
        />
        <Route path={`/${prefixCompany}/inventory/return-order/create`} element={<RMACreate />} />
        <Route path={`/${prefixCompany}/notes/edit`} element={<EditNotes />} />
        {/* <Route path={`/${prefixCompany}/:type/create`} element={<Quote />} /> */}

        <Route path={`/${prefixCompany}/profile/`} element={<ProfileHeader />} />
        <Route path={`/${prefixCompany}/subquotes/`} element={<SubquotesHeader />} />
        <Route path={`/${prefixCompany}/settings/company/`} element={<CompanyHeader />} />
        <Route path={`/${prefixCompany}/time_employee/`} element={<TimeCardPage />} />
        <Route path={`/${prefixCompany}/employee/timesheet/`} element={<EmployeeTimeCardPage />} />
        <Route path={`/${prefixCompany}/employee/invitation/`} element={<Invitation />} />

        <Route path={`/${prefixCompany}/account/settings`} element={<AccountSetting />} />
        <Route path={`/${prefixCompany}/details`} element={<ProfileDetails />} />

        {/* Services Routes Here */}
        <Route path={`/${prefixCompany}/services`} element={<Services />} />
        {/* Services End Routes Here */}

        <Route path={`/${prefixCompany}/employee/roles`} element={<UserManagementPage />} />
        <Route
          path={`/${prefixCompany}/employee/roles/:role/permission`}
          element={<ManageUserPermission />}
        />

        <Route path={`/${prefixCompany}/media/:date/:image_name`} />
        <Route path={`/${prefixCompany}/project`} element={<WorkOrder />} />

        {/* <Route path={`/${prefixCompany}/fieldservice`} element={<FieldService />} /> */}

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path={`/${prefixCompany}/:type/:id/*`}
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
