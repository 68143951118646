/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect, useState} from 'react'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {
  currnetCompanyName,
  viewDepartment,
  addDepartment,
  darkMessage,
  darkErrorMessage,
} from '../../modules/auth/core/_requests'
import RoleCard from './components/RoleCard'
import {HAS_UserManagementViewDepartments} from './PermissionFunction'
import {RolesList} from './Roles'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useSearchParams} from 'react-router-dom'
import {LinearProgress} from '@mui/material'

const UserManagementPage = (props: any) => {
  const headerContext = useContext<any>(HeaderContext)
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [show, setShow] = useState<any>(false) //this state is used for open mdodal
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [department, setDepartment] = useState<any>('') //this state used for set value of department
  const [arrayVal, setArrayVal] = useState<any>([]) //this state is used for set array value from get permisiion records API
  const [error, setError] = useState<any>(false) //this state is used for showing error

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Role Management',
        subtitle: ` `,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  // close modal function
  const handleClose = () => setShow(false)

  // Submit handler addPermission function in Modal
  const addPermission = () => {
    setLoading(true)
    addDepartment(department)
      .then((res) => {
        getList()
        setDepartment('')
        setShow(false)
        setLoading(false)
        darkMessage('Department Added Successfully')
      })
      .catch((e) => {
        if (e?.response.status === 409) {
          darkErrorMessage(e?.response.data?.error_list[0])
        } else {
          console.log(e)
          darkErrorMessage('Error')
        }
        setDepartment('')
        setError(true)
        setLoading(false)
        setShow(false)
      })
  }
  const getList = useCallback(async () => {
    setLoading(true)
    await viewDepartment()
      .then((res) => {
        let departmentList = res.data.response
        setData(departmentList)
        setLoading(false)
      })
      .catch((e) => {
        setData([])
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [])
  return (
    HAS_UserManagementViewDepartments(currentUser) &&
    (loading ? (
      <LinearProgress />
    ) : (
      <div>
        <div className='row'>
          <div className='card mb-5 mb-xl-1'>
            <div
              className='card-title m-0 d-flex align-items-center'
              style={{justifyContent: 'space-between'}}
            >
              <h1 className='m-5'>Manage Departments</h1>
              <button className='btn btn-primary float-end m-5' onClick={() => setShow(true)}>
                Add Department
              </button>
            </div>
          </div>
          {data.map((role: any) => (
            <div className='col-xl-4 col-md-6 col-sm-12 mb-4 mt-4'>
              <RoleCard role={role} getList={getList} />
            </div>
          ))}
          {/* <Permissions child_value={Permission.Customer.ViewTable.value} parent_value={Permission.Customer.value}/> */}
        </div>

        {/********************** Modal start ****************************/}
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          show={show}
          size='lg'
          centered
          onHide={handleClose}
          className='fade'
        >
          <Modal.Header>
            <Modal.Title>
              <h1 className='p-2 lh-base'>Add Department</h1>
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Do you want to Clock In?</Modal.Body> */}
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Department Name
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter Department'
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value)
                    setError(false)
                  }}
                />
                {error ? <span className='text-danger'>Required fields......</span> : null}
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button onClick={handleClose} className='btn btn-dark'>
              Cancel
            </button>
            <button onClick={addPermission} className='btn btn-primary'>
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        {/********************** Modal end ****************************/}
      </div>
    ))
  )
}

export {UserManagementPage}
