/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect} from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useState} from 'react'
import {useAuth} from '../../modules/auth'
import EmpSingle from '../../modules/emp/EmpSingle'
import EmpInterface from '../../modules/emp/EmpInterface'
import {currnetCompanyName, employeeList, getDepartments} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {HAS_EmployeesInviteEmployees, HAS_EmployeesViewEMP} from './PermissionFunction'
import {FormControl, InputLabel, LinearProgress, MenuItem, Select} from '@mui/material'
const EmployeesWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [empList, setEmpList] = useState<EmpInterface[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedClient, setSelectedClient] = useState('all')
  const [loading, setLoading] = useState(false)
  const [deparments, setDepartments] = useState<any>([])
  const navigate = useNavigate()

  const ListDepartmentType = {
    all: 'all',
    list: 'list',
    employee: 'employee',
    inventory: 'inventory',
  }

  async function getList(page: number = 1, str = '', employee_type = ListDepartmentType.all) {
    setLoading(true)
    await employeeList(page, str, employee_type)
      .then((res) => {
        let employes = res.data.employees as EmpInterface[]
        setEmpList(employes)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const departmentList = useCallback(() => {
    setLoading(true)
    getDepartments()
      .then((res: any) => {
        setLoading(false)
        let list = res?.data?.response
        setDepartments(list)
      })
      .catch((e: any) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getList()
    departmentList()
  }, [])
  useEffect(() => {
    const search = searchParams.get('str') as string
    getList(1, search)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Employee',
        subtitle: '7 Total',
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/employees/invitations`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  function handleActive(event: any) {
    setSelectedClient(event.target.value)
    getList(1, '', event?.target?.value)
  }
  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EMPLOYEE'})} </PageTitle>
      <div>
        <div>
          <div className='row'>
            <div className='col-md-3 my-5'>
              {HAS_EmployeesInviteEmployees(currentUser) && (
                <button
                  className='btn btn-primary'
                  onClick={() => navigate(`/${prefixCompany}/employees/invitations`)}
                >
                  Send Invitation to Employee
                </button>
              )}
            </div>
            <div className='col-md-7 my-5'></div>
            <div className='col-md-2 my-5'>
              <FormControl fullWidth>
                <InputLabel id='all-departments'>Search By Department</InputLabel>
                <Select
                  labelId='all-departments'
                  id='demo-simple-select'
                  value={selectedClient}
                  label='Search For Department'
                  onChange={handleActive}
                >
                  <MenuItem value={'all'}>{'ALL'}</MenuItem>
                  {deparments.map((item: any, index: number) => (
                    <MenuItem value={item.type} key={index}>
                      {item.type === 'Owner'
                        ? 'Admin Department'
                        : item.type === 'Team Member'
                        ? 'Engineering Department'
                        : item.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      {HAS_EmployeesViewEMP(currentUser) && (
        <div
          className='view-emp-wrapper'
          style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '20px'}}
        >
          {empList.map((emp: EmpInterface) => {
            return (
              <EmpSingle
                changed={() => {
                  getList()
                }}
                key={emp.user}
                emp={emp}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export {EmployeesWrapper}
