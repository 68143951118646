import produce from 'immer';
import { createContext } from 'react';

export const headerInitalState = {
    custom: false,
    title: "",
    subtitle: "",
    hasBtn: true,
    action: {
        btn_text: "",
        customOnClick: () => { },
        navigation_url: "",
    },
    additionalAction: {
        additional_btn_text: "",
        customOnClick: () => { },
        additional_navigation_url: "",
    },
    searchBar:{
        search :false,
        search_text:'',
    }
}

export const HeaderAction = {
    Update: "update"
}

export const dispatchHeader = (state: any, action: any) => {

    if (action.type == HeaderAction.Update) {
        return produce(state, (draft: any) => {
            if (typeof action?.payload?.custom == "boolean") draft.custom = action.payload.custom
            if (action?.payload?.title) draft.title = action.payload.title
            if (action?.payload?.subtitle) draft.subtitle = action.payload.subtitle
            if (action?.payload?.btn_text) draft.action.btn_text = action?.payload?.btn_text
            if (action?.payload?.navigation_url) draft.action.navigation_url = action?.payload?.navigation_url
            if (action?.payload?.additional_btn_text) draft.additionalAction.additional_btn_text = action?.payload?.additional_btn_text
            if (action?.payload?.additional_navigation_url) draft.additionalAction.additional_navigation_url = action?.payload?.additional_navigation_url
            if (action?.payload?.search) draft.searchBar.search = action?.payload?.search
            if (action?.payload?.search_text) draft.searchBar.search_text = action?.payload?.search_text
        })
    }
    return state
}

export const HeaderContext = createContext({})
