/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {getRole} from '../../pages/dashboard/Roles'
import {getLocalUser, useAuth} from '../auth'
import EmpInterface from './EmpInterface'
import blank from './../../../_metronic/layout/components/header/blank.png'
import {
  HAS_EmployeesUpdateEmployeeMenu,
  HAS_EmployeesUpdateRoles,
} from '../../pages/dashboard/PermissionFunction'
import moment from 'moment'
interface PropsType {
  emp: EmpInterface
  changed: () => void
}
const EmpSingle = ({emp, changed}: PropsType) => {
  const user = useAuth()
  const {currentUser} = useAuth()
  const [menuSwitch, setMenuSwitch] = useState(false)
  const dropdownRef = useRef(null)
  const handleButtonClick = () => {
    setMenuSwitch((prevState) => !prevState)
  }
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuSwitch(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-lg-nowrap mb-3 align-items-center align-items-lg-start'>
            <div className='me-7 mb-4 d-flex align-items-center view-emp-img'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  style={{objectFit: 'contain'}}
                  src={emp.profile_pic}
                  alt={emp.first_name}
                  onError={(evt: any) => {
                    evt.target.src = blank
                  }}
                />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start mb-2'>
                <div className='d-flex flex-column flex-wrap'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {emp.first_name} {emp.last_name}
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 align-items-baseline'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {emp.user_type}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr086.svg'
                        className='svg-icon-4 me-1'
                      />
                      {emp.emp_title ? emp.emp_title : <></>}
                    </a>
                  </div>
                </div>
                <div className='d-flex my-4'>
                  <div className='me-0'>
                    {/* {userRole} */}
                    <>
                      <button
                        ref={dropdownRef}
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={handleButtonClick} // Use the custom click handler
                      >
                        <i className='bi bi-three-dots fs-3'></i>
                      </button>
                      <div className='dropdown_emp_section'>
                        {/* Dropdown content */}
                        {menuSwitch ? (
                          <Dropdown1
                            menuSwitch={menuSwitch}
                            onChange={(role: any) => {
                              if (emp.id === user?.currentUser?.id) {
                                const user_temp = user?.currentUser
                                user_temp.user_type = role
                                user.setCurrentUser(user_temp)
                              }
                              changed()
                            }}
                            defaultValue={getRole(emp.user_type)?.value}
                            user_id={emp.user}
                            emp={emp}
                            changed={changed}
                          />
                        ) : (
                          <>
                            <Dropdown1
                              menuSwitch={menuSwitch}
                              onChange={(role: any) => {
                                if (emp.id === user?.currentUser?.id) {
                                  const user_temp = user?.currentUser
                                  user_temp.user_type = role
                                  user.setCurrentUser(user_temp)
                                }
                                changed()
                              }}
                              defaultValue={getRole(emp.user_type)?.value}
                              user_id={emp.user}
                              emp={emp}
                              changed={changed}
                            />
                          </>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className='flex-stack'>
                <div className='flex-grow-1 pe-8'>
                  <div className=''>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'> Work Email Address</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>
                        <KTSVG
                          path='/media/icons/duotune/communication/com002.svg'
                          className='svg-icon-4 me-1'
                        />
                        {emp.email}
                      </div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'> Work Phone Number</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>
                        {' '}
                        <KTSVG
                          path='/media/icons/duotune/communication/com005.svg'
                          className='svg-icon-4 me-1'
                        />
                        {emp.phone ? emp.phone : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row pe-8'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='custom-c  border border-gray-300 border-dashed rounded min-w-230px py-3 px-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='f-size fw-bolder'>Hometown</div>
                      </div>
                      <div className='fw-bold d-flex text-gray-400 w-100 flex-wrap'>
                        {' '}
                        <KTSVG
                          path='/media/icons/duotune/maps/map008.svg'
                          className='svg-icon-4 me-1'
                        />
                        {emp.hometown ? emp?.hometown : <>N/A</>}
                      </div>
                    </div>
                  </div>
                  {/* fs-2 */}
                  <div className='col-lg-6 col-md-12 ps-lg-0 ps-md-0'>
                    <div className='custom-c border border-gray-300 border-dashed rounded min-w-230px py-3 px-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='f-size fw-bolder'>Birthday</div>
                      </div>
                      <div className='fw-bold d-flex text-gray-400 w-100 '>
                        {' '}
                        <KTSVG path='/media/custom/cake.svg' className='svg-icon-4 me-1' />
                        {emp.birthday ? moment(emp.birthday).format('Do MMMM ') : <>N/A</>}
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-12 ps-lg-0 ps-md-0'>
                    <div className='custom-c border border-gray-300 border-dashed rounded min-w-230px py-3 px-4 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='f-size fw-bolder'>Anniversary</div>
                      </div>
                      <div className='fw-bold d-flex text-gray-400 w-100 '>
                        {' '}
                        <KTSVG path='/media/custom/cake.svg' className='svg-icon-4 me-1' />
                        {emp.anniversary ? moment(emp.anniversary).format('Do MMMM ') : <>N/A</>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmpSingle
