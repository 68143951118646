/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import {useCallback, useEffect, useMemo, useState, useContext} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {
  LinearProgress,
  TextField,
  Autocomplete,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import {AutocompleteChangeReason, createFilterOptions} from '@mui/material/Autocomplete'
import {
  updateDescription,
  darkErrorMessage,
  itemService,
  updateItem,
  subquoteDelete,
  GetSubQuotes,
  viewQuote,
  darkMessage,
  GetItemServices,
  GetDisclaimer,
  GetEditedDisclaimer,
  updateSubQuote,
  updateService,
  GetItemToggle,
  UPDATE_tagsForWorkOrder,
  FETCH_tagsForWorkOrder,
  createWorkOrderTag,
  updateSubQuoteProject,
  ALL_fetchWorkOrderTag,
  currnetCompanyName,
  sub_quotes_generate_pdf,
  API_URL,
  updateSubQuoteFs,
} from '../../modules/auth/core/_requests'
import {Modal} from 'react-bootstrap'
import Menu from '@mui/material/Menu'
import {QuotesModel} from '../../modules/model/quotes'
import MenuItem from '@mui/material/MenuItem'
import SimpleDataTable from './SimpleDataTable'
import {SubquotesHeader} from './SubquotesHeader'
import {SubquotesItemsView} from './SubquotesItems'
import {SubquotesServicesView} from './SubquotesServices'
import DeleteModal from './DeleteModal'
import {SubQuote} from './SubQuote'
import SubQuoteFooter from './SubquotesFooter'
import SubQuoteTotal from './TotalValue'
import SubQuoteDisclaimer from './SubQuoteDisclaimer'
import {SubQuoteDesc} from './SubQuoteDes'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {AddNote} from './AddNote'
import {useAuth} from '../../modules/auth'
import GeneratePDF from './GeneratePDF'

const BOMData: React.FC = ({globalData, modalOpen, setModalOpen}: any) => {
  const {id} = useParams()
  const [data, setData] = useState([])
  const [rowId, setRowId] = useState()
  const [rowPk, setRowPk] = useState()
  const [customerView, setCustomerView] = useState(false)
  const [onClickView, setOnClickView] = useState(false)
  const [getQuantityServices, setQuantityServices] = useState<number[]>([])
  const [tagsList, setTagsList] = useState<any[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [getServiceName, setGetServiceName] = useState<any>([])
  const [getServiceDescription, setGetServiceDescription] = useState<any>([])
  const [getPriceServices, setGetPriceServices] = useState<number[]>([])
  const [popupModal, setPopupModal] = useState<any>(false)
  const [fsPopupModal, setFsPopupModal] = useState<any>(false)

  const [projectModal, setProjectModal] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setUpdModalOpen] = useState(false)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [actionClick, setActionClick] = useState(false)
  const [descModal, setDescModal] = useState(false)
  const [disable, setDisable] = useState(false)
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [rowData, setRowData] = useState<any>({})
  const [closeModal, setCloseModal] = useState<any>({})
  const [itemRowData, setItemRowData] = useState<any>({})
  const [serviceRowData, setServiceRowData] = useState<any>({})
  const [itemRowId, setItemRowId] = useState()
  const open = Boolean(anchorEl)
  const [newSub, setNewSub] = useState([])
  const [item, setItem] = useState<any>([])
  const [fieldArrayData, setFieldArrayData] = useState<any>([])
  const [viewItem, setViewItem] = useState<any>([])
  const [getQuantityItems, setQuantityItems] = useState<any>([])
  const [getCostItems, setCostItems] = useState<any>([])
  const [finalCost, setFinalCost] = useState<any>([])
  const [itemTotal, setItemTotal] = useState(0)
  const [tax, setTax] = useState()
  const [discount, setDiscount] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [disUnit, setDisUnit] = useState('$')
  const [taxValue, setTaxValue] = useState(0)
  const [defaultTax, setDefaultTax] = useState('')
  const [defaultMarkupUnit, setDefaultMarkupUnit] = useState('')
  const [defaultMarkup, setDefaultMarkup] = useState('')
  const [newModal, setNewModal] = useState(false)
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null)
  const [service, setService] = useState<any>([])
  const [fieldServiceData, setFieldServiceData] = useState<any>([])
  const [viewService, setViewService] = useState<any>([])
  const [serviceTotal, setServiceTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(itemTotal + serviceTotal)
  const [grandTotal, setGrandTotal] = useState(0)
  const [disclaimer, setDisclaimer] = useState('')
  const [expiry, setExpiry] = useState('')
  const [getDisc, setGetDisc] = useState('')
  const [markUp, setMarkUp] = useState()
  const [getMarkUp, setGetMarkUp] = useState<any>([])
  const [priceUnits, setPriceUnits] = useState('$')
  const [checkedValue, setCheckedValue] = useState('')

  const [selectedOnRow, setSelectedOnRow] = useState<any>([])

  const storedToggleItem = localStorage.getItem('toggleItem')

  const initialToggleItem = storedToggleItem
    ? JSON.parse(storedToggleItem)
    : {
        item_mfg: true,
        item_mfg_part: true,
        price: true,
        total: true,
        quantity: true,
      }
  const [toggleItem, setToggleItem] = useState(initialToggleItem)

  const [toggleService, setToggleService] = useState({
    price: true,
    total: true,
    quantity: true,
  })
  const [projectForm, setProjectForm] = useState({
    name: '',
    description: '',
  })
  const [projectName, setProjectName] = useState()
  const [projectDescription, setProjectDescription] = useState()

  const [formErrors, setFormErrors] = useState({})

  const [formData, setFormData] = useState({
    label: '',
    description: '',
  })
  const [serviceDescription, setServiceDescription] = useState<any>([])
  const [prev_name, setPrev_name] = useState<any>()
  const [prev_desc, setPrev_desc] = useState<any>()
  const [prev_files, setPrevFiles] = useState<any>([])
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  let quo_id: any
  // let prev_name:any
  // let prev_desc:any
  const getList = useCallback(async () => {
    setLoading(true)
    await viewQuote(id).then((res) => {
      console.log(res, res?.data?.files_info)
      quo_id = res?.data?.customer_details as QuotesModel
      setPrev_name(res?.data?.customer_details?.fields?.project_field)
      setPrev_desc(res?.data?.customer_details?.fields?.description)
      setPrevFiles(res?.data?.files_info)
    })
    await GetSubQuotes(quo_id.id || '')
      .then(({data}) => {
        // const filteredData = data?.data.filter((item) => item.showQuote === 'True')
        // setData(filteredData)
        console.log(data)
        setData(data?.data)
        setLoading(false)
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [id, globalData])
  const handleInputChange = (event: {target: {name: any; value: any}}) => {
    const {name, value} = event.target
    console.log(event.target.name, event.target.value)
    setProjectForm({...projectForm, [name]: value})
    setFormErrors({...formErrors, [name]: ''})
  }
  // const handleProjectName =(e)=>{
  //   setProjectName(e.target.value)
  // }
  useEffect(() => {
    getList()
  }, [getList])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const deleteQuote = async (id: any, quo_id: any, subQuoteLabel: any) => {
    setLoading(true)
    try {
      const res = await subquoteDelete(id, quo_id, subQuoteLabel)
      let result = res?.data?.error
      darkMessage('Quote deleted Successfully')
      getList()
      const del = await GetSubQuotes(id || '')
      setLoading(false)
    } catch (e: any) {
      if (e.response.status === 409) {
        darkErrorMessage(e.response.data)
        setDeleteModal(false)
        setLoading(false)
      }
    }
  }
  const getTotal = useCallback(async () => {
    await setSubTotal(itemTotal + serviceTotal - discountValue)
  }, [itemTotal, serviceTotal, discountValue])
  const getTax = useCallback(async () => {
    const quote_id = id
    const taxPercentage = tax !== 0 ? tax / 100 : defaultTax / 100
    const taxValue = subTotal * taxPercentage
    await setTaxValue(taxValue)
    if (defaultTax === '0') {
      await setGrandTotal(subTotal)
    } else {
      console.log(subTotal + taxValue)
      await setGrandTotal(subTotal + taxValue)
    }
    const updateGrandTotal = subTotal + taxValue
    itemService(
      JSON.stringify(fieldArrayData),
      JSON.stringify(fieldServiceData),
      tax,
      quote_id,
      updateGrandTotal, // Pass the updated grandTotal value
      discVal,
      rowData?.id,
      rowData?.description,
      disUnit,
      discount
    )
    // }, [])
  }, [defaultTax, tax, subTotal])

  const getDiscount = useCallback(async () => {
    const total = itemTotal + serviceTotal
    let discountValues

    if (disUnit === '%') {
      discountValues = (total * discount) / 100
      setDiscountValue(discountValues)
    } else {
      discountValues = discount
      setDiscountValue(discountValues)
    }
  }, [discount, discountValue, disUnit, itemTotal, serviceTotal])
  useEffect(() => {
    getDiscount()
    getTotal()
    getTax()
  }, [getTotal, getTax, getDiscount])
  const getDisclaimer = useCallback(async () => {
    setLoading(true)
    await GetDisclaimer()
      .then(({data}) => {
        console.log('dataaaaa', data)
        setLoading(false)
        setDisclaimer(data?.quote_disclaimer?.quote_disclaimer || '')
        setDefaultTax(data?.quote_disclaimer?.default_tax || '')
        setDefaultMarkupUnit(data?.quote_disclaimer?.default_markup_value_unit || '')
        setDefaultMarkup(data?.quote_disclaimer?.default_markup_value || '')
        setExpiry(data?.quote_disclaimer?.expire_days || '')
        setLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status == 409) {
          let message: any = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
          setLoading(false)
        } else {
          console.log(e)
        }
      })
  }, [])
  function handleDisclaimerChange(event: any) {
    setGetDisc(event.target.value)
  }
  const handleMarkupChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, row: any) => {
    const itemUpdatedata = {
      id: row.id,
      markup: parseFloat(e.target.value),
    }
    console.log(e.target.value)
    setItemRowId(row.id)
    updateItem(itemUpdatedata).then(({data}) => {
      getItem()
      getList()
    })
    const newMarkup = parseFloat(e.target.value === '' ? '0' : e.target.value)

    setGetMarkUp((prevQuantity: any) => {
      const updatedQuantity = Array.isArray(prevQuantity) ? [...prevQuantity] : []
      updatedQuantity[index] = newMarkup
      return updatedQuantity
    })
  }
  const handleOnRow = (e: React.ChangeEvent<HTMLInputElement>, index: number, row: any) => {
    const itemUpdatedata = {
      id: row.id,
      priceUnit: e.target.value,
    }
    updateItem(itemUpdatedata).then(({data}) => {
      getItem()
      getList()
    })
    setSelectedOnRow((prevQuantity: any) => {
      const updatedQuantity = Array.isArray(prevQuantity) ? [...prevQuantity] : []
      updatedQuantity[index] = e.target.value
      return updatedQuantity
    })
  }
  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, row: any) => {
    const itemUpdatedata = {
      id: row.id,
      itemCost: parseFloat(e.target.value),
    }
    const quote_id = id
    console.log(e.target.value)

    const newCost = parseFloat(e.target.value === '' ? '0' : e.target.value)
    updateItem(itemUpdatedata).then(({data}) => {
      console.log({grandTotal})
      getItem()
      getList()
      getTotal()
    })

    setCostItems((prevQuantity: any) => {
      const updatedQuantity = Array.isArray(prevQuantity) ? [...prevQuantity] : []
      updatedQuantity[index] = newCost
      return updatedQuantity
    })
  }
  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const itemUpdatedata = {
      id: row.id,
      quantity: parseFloat(e.target.value),
    }
    const newQuantity = parseFloat(e.target.value)
    updateItem(itemUpdatedata).then(({data}) => {
      getItem()
      getList()
    })
    setQuantityItems((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }
  const handleServiceQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const serviceDescriptionUpdateData = {
      id: row.id,
      quantity: parseFloat(e.target.value),
    }
    updateService(serviceDescriptionUpdateData).then(({data}) => {
      getService()
      getList()
    })
    const newQuantity = parseFloat(e.target.value)
    setQuantityServices((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }
  const handleServiceNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const serviceDescriptionUpdateData = {
      id: row.id,
      serviceName: e.target.value,
    }
    updateService(serviceDescriptionUpdateData).then(({data}) => {
      getService()

      getList()
    })
    const newQuantity = e.target.value
    const serviceRate = row?.service_rate
    setGetServiceName((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }
  const handleServiceDescChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const serviceDescriptionUpdateData = {
      id: row.id,
      service_description: e.target.value,
    }
    updateService(serviceDescriptionUpdateData).then(({data}) => {
      getService()

      getList()
    })
    const newQuantity = e.target.value
    const serviceRate = row?.service_rate
    setGetServiceDescription((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }

  const handleServicePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const serviceDescriptionUpdateData = {
      id: row.id,
      price: parseFloat(e.target.value),
    }
    updateService(serviceDescriptionUpdateData).then(({data}) => {
      getService()

      getList()
    })
    const newQuantity = parseFloat(e.target.value === '' ? '0' : e.target.value)
    setGetPriceServices((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }
  const handleServiceDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const serviceDescriptionUpdateData = {
      id: row.id,
      service_description: e.target.value,
    }
    const newServiceDescription = e.target.value
    updateService(serviceDescriptionUpdateData).then(({data}) => {
      getService()
      getList()
    })
    setServiceDescription((previousServiceDescriptionData: any) => {
      const updateServiceDescriptionData = [...previousServiceDescriptionData]
      updateServiceDescriptionData[index] = newServiceDescription
      return updateServiceDescriptionData
    })
  }
  function handletaxChange(event: any) {
    setTax(event.target.value)
  }
  function handleDiscountChange(event: any) {
    setDiscount(event.target.value)
  }
  function handleDisUnitChange(event: any) {
    setDisUnit(event.target.value)
  }
  const handleClick = (row_Data: any, event: React.MouseEvent<HTMLButtonElement>) => {
    setRowData(row_Data)
    setRowId(row_Data.label)
    setRowPk(row_Data.id)
    setAnchorEl(event.currentTarget)
  }

  const handleOut = () => {
    setAnchorEl(null)
  }

  const handleCheckbox = (e: any, value: string) => {
    if (value === 'Accepted') {
      setCheckedValue(e.target.checked ? 'Accepted' : 'New')
    } else if (value === 'New') {
      setCheckedValue(e.target.checked ? 'New' : 'Accepted')
    }
  }

  const handleModal = useCallback(
    (index: any) => {
      console.log(checkedValue)
      console.log(projectForm, projectName, projectDescription)
      updateSubQuote(
        rowData?.quote,
        'Project Created',
        rowData?.label,
        rowData?.description,
        prev_name,
        prev_desc,
        checkedValue,
        prev_files,
        'status',
        null
      ).then(() => {
        darkMessage(`Quote ${rowData?.label}  Converted to Project Successfully`)
      })
      getList()
      setPopupModal(false)
      setProjectModal(true)
    },
    [rowData?.label, rowData?.quote, checkedValue, globalData]
  )
  const handleModalClose = useCallback((index: any) => {
    setPopupModal(false)
  }, [])
  const handleFSModal = useCallback(
    (index: any) => {
      console.log(checkedValue)
      console.log(projectForm, projectName, projectDescription)
      updateSubQuoteFs(
        rowData?.quote,
        'Field Service Created',
        rowData?.label,
        rowData?.description,
        prev_name,
        prev_desc,
        checkedValue,
        prev_files,
        'status',
        null
      ).then(() => {
        darkMessage(`Quote ${rowData?.label}  Converted to Field Service Successfully`)
      })
      getList()
      setPopupModal(false)
      setProjectModal(true)
    },
    [rowData?.label, rowData?.quote, checkedValue, globalData]
  )
  const handleProjectData = useCallback(() => {
    console.log(rowData?.label, projectName, projectDescription)
    let pn = projectName === undefined ? prev_name : projectName
    let pd = projectDescription === undefined ? prev_desc : projectDescription
    updateSubQuoteProject(rowData?.label, pn, pd)
      .then(() => {
        darkMessage(`Quote ${rowData?.label} Updated Project Successfully`)
        navigate(`/${prefixCompany}/quote`)
      })
      .catch((error) => {
        console.error('Error updating project:', error)
      })

    getList()
  }, [rowData, projectName, projectDescription, updateSubQuoteProject, darkMessage, getList])
  const getEditedDisclaimer = useCallback(
    async (rowData: any) => {
      setLoading(true)
      await GetEditedDisclaimer(rowData.id)
        .then((res) => {
          if (res.data.edited_disclaimer !== '' && res.data.edited_disclaimer !== null) {
            setGetDisc(res.data.edited_disclaimer)
          }
          setLoading(false)
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            let message: any = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
            setLoading(false)
          } else {
            console.log(e)
          }
        })
    },
    [rowData.id]
  )
  function removeHtmlTags(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html')
    return doc.body.textContent || ''
  }
  const getDefaultTax = useCallback(
    async (rowData: any) => {
      setLoading(true)
      await GetEditedDisclaimer(rowData.id)
        .then((res) => {
          if (res.data.tax !== '' && res.data.tax !== null) {
            setTax(res.data.tax)
          }
          setDiscount(res.data.disount)
          setDisUnit(res.data.disUnit)
          setLoading(false)
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            let message: any = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
            setLoading(false)
          } else {
            console.log(e)
          }
        })
    },
    [rowData.id, discount, disUnit]
  )
  // console.log(rowData)
  const discVal = getDisc === '' ? disclaimer : getDisc
  const getItem = async () => {
    // setLoading(true)

    await GetItemServices(rowData?.label).then(({data}) => {
      setViewItem(data.items)
      if (data?.item_toggle_Filter) {
        setToggleItem(data?.item_toggle_Filter)
      }
      data.items.forEach((item: any) => {
        if (item.markup !== '' && item.markup !== null) {
          setGetMarkUp(item.markup)
        }
        if (item.priceUnit !== '' && item.priceUnit !== null) {
          setSelectedOnRow(item.priceUnit)
        }
      })
    })
    setLoading(false)
  }

  const getService = async () => {
    setLoading(true)
    await GetItemServices(rowData?.label).then(({data}) => {
      setViewService(data.services)
      if (data?.service_toggle_Filter) {
        setToggleService(data?.service_toggle_Filter)
      }
    })
    setLoading(false)
  }
  const handleBackendPdf = useCallback(
    async (rowDatas: any) => {
      // alert('ss')
      console.log(rowData, rowDatas)
      setPdfOpen(true)
      console.log(rowData, quo_id.id, rowDatas)
      await sub_quotes_generate_pdf(quo_id.id, rowDatas)
        .then((res: any) => {
          const fileUrl = API_URL + res?.data?.file_path
          if (fileUrl) {
            window.open(fileUrl, '_blank')
          }
        })
        .catch((e: any) => {
          darkErrorMessage('ERROR! PDF does not generated')
        })
      setPdfOpen(false)
    },
    [setPdfOpen]
  )
  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          row?.showQuote === 'True' ? (
            <div
              style={{
                backgroundColor: 'rgb(228,228,228)',
                borderRadius: '4px',
                display: 'flex',
                padding: '3px',
                width: '45px',
                textAlign: 'center',
                marginInlineStart: '35px',
              }}
            >
              <span style={{backgroundColor: 'rgb(228,228,228)'}}>
                {' '}
                {row?.quoteStatus === 'null' ? 'Draft' : row?.quoteStatus}
              </span>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: 'rgb(164,255,176)',
                borderRadius: '4px',
                display: 'flex',
                padding: '3px',
                textAlign: 'center',
                marginInlineStart: '10px',
              }}
            >
              <span style={{backgroundColor: 'rgb(164,255,176)'}}>
                {row?.quoteStatus === 'null' ? 'Draft' : row?.quoteStatus}
              </span>
            </div>
          ),
        header: (
          <div style={{margin: '0 20px'}}>
            {' '}
            {/* Adjust the margin values as needed */}
            Status
          </div>
        ),
        size: 130,
        accessorKey: 'quote_status',
      },
      {
        accessorFn: (row: any) => `Q-${row?.label}`,
        header: 'Quote#',
        size: 70,
        accessorKey: 'bom_label',
      },
      {
        accessorFn: (row: any) => row?.description,
        header: 'Quote Description',
        accessorKey: 'description',
      },
      {
        accessorFn: (row: any, i: any) => row?.itemss,
        header: 'Items on Quote',
        size: 70,
        accessorKey: 'bom_items_on_quote',
      },
      {
        accessorFn: (row: any) => row?.servicess,
        header: 'Services on Quote',
        size: 70,
        accessorKey: 'bom_services_on_quote',
      },
      {
        accessorFn: (row: any) => `$${row?.grandTotal ? row?.grandTotal.toFixed(2) : '0.00'}`,
        header: 'Quote Total',
        accessorKey: 'bom_services_quote_total',
      },
      {
        accessorFn: (row: any) => moment(row?.last_modified, 'DD-MM-YYYY').format('ll'),
        header: 'Last Modified',
        accessorKey: 'bom_modified_date_on_quote',
      },
      {
        accessorFn: (row: any, id: any) => (
          <div>
            <button
              className='btn-white btn'
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onMouseOver={(e) => {
                setActionClick(true)
                handleClick(row, e)
              }}
            >
              <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
            </button>
          </div>
        ),
        header: 'Actions',
        accessorKey: 'bom_actions',
      },
    ],
    []
  )

  function updateQuantity(rowData: any) {
    setUpdModalOpen(true)
    getDefaultTax(rowData)
    getDisclaimer()
    getEditedDisclaimer(rowData)
    handleClose()
  }

  function removeItem() {
    setDeleteModal(true)
    handleClose()
  }

  const handleOpenMenu = (event: any) => {
    setAnchorEl1(event.currentTarget)
  }

  function showDisclaimerModal() {
    setNewModal(true)
    setAnchorEl1(null)
  }
  const handleButtonClick = () => {
    const quote_id = id
    const fieldDataItems = []
    updateDescription(rowData.id, formData.description).then(({data}) => {
      darkMessage(data.message)
      getList()
    })
    const indexs = viewItem.length
    for (let i = 0; i < fieldArrayData.length; i++) {
      const modalItemData = {
        subquote_id: rowData?.label,
        item_id: fieldArrayData[i].id,
        finalCost:
          getCostItems.length === 0
            ? fieldArrayData[i].item_cost
            : getCostItems[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],

        quantity:
          getQuantityItems[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
        markup: getMarkUp[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
        priceUnit:
          selectedOnRow[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
      }
      fieldDataItems.push(modalItemData)
    }
    const fieldDataServices = []
    const sindex = viewService.length
    for (let i = 0; i < fieldServiceData.length; i++) {
      const modalServiceData = {
        subquote_id: rowData?.label,
        service_name:
          getServiceName.length === 0
            ? fieldServiceData[i].service_name
            : getServiceName[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
        serviceDescription:
          getServiceDescription.length === 0
            ? fieldServiceData[i].service_description
            : getServiceDescription[
                i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i
              ],
        servicequantity:
          getQuantityServices[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
        servicePrice:
          getPriceServices.length === 0
            ? fieldServiceData[i].service_rate
            : getPriceServices[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
      }
      fieldDataServices.push(modalServiceData)
    }
    const taxSet = tax !== '' ? tax : defaultTax

    // fieldDataItems.length > 0 ||
    // (fieldDataServices.length > 0 &&
    itemService(
      JSON.stringify(fieldDataItems),
      JSON.stringify(fieldDataServices),
      taxSet,
      quote_id,
      grandTotal,
      discVal,
      rowData?.id,
      rowData?.description,
      disUnit,
      discount
    ).then(({data}) => {
      darkMessage(data.message)
      setUpdModalOpen(false)
      getItem()
      getService()
      setViewItem([])
      setItem([])
      setService([])
      setFieldArrayData([])
      setGetServiceName([])
      setGetServiceDescription([])
      setGetPriceServices([])
      setFieldServiceData([])
      setCostItems([])
      getList()
      setDisable(false)
    })
    // )
  }
  const fetchTags = useCallback(() => {
    // setTagsLoading(true)
    FETCH_tagsForWorkOrder(id || '')
      .then(({data}) => {
        const response = data?.result[0]?.tags
        setSelectedTags(response || [])
        // setTagsLoading(false)
      })
      .catch((e) => {
        // setTagsLoading(false)
        console.error(e)
      })
  }, [id])
  const getTagsList = useCallback(() => {
    const TAGS_LIST_ARRAY: string[] | any = []
    // setTagsLoading(true)
    ALL_fetchWorkOrderTag()
      .then(({data}) => {
        let LIST = data?.result
        LIST.forEach((e: any) => {
          TAGS_LIST_ARRAY.push({name: e.name, id: e.id})
        })
        setTagsList(TAGS_LIST_ARRAY)
        // setTagsLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        // setTagsLoading(false)
      })
  }, [])
  const handleCreateTag = (selectedOption: any) => {
    // setTagsLoading(true)
    const optionString = selectedOption
    createWorkOrderTag(optionString)
      .then(({data}) => {
        // setTagsLoading(false)
        getTagsList()
        darkMessage('New Tag Created Successfully')
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        // setTagsLoading(false)
      })
  }
  const handleUpdateTag = useCallback(
    (selectedOption: any) => {
      console.log(rowData, rowId, rowPk)
      const STRINGIFY_DATA = JSON.stringify(selectedOption)
      UPDATE_tagsForWorkOrder(rowData?.label, STRINGIFY_DATA)
        .then(({data}) => {
          fetchTags()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [rowPk]
  )
  const handleSelectChange = useCallback(
    (e: any, selectedOption: any, reason: AutocompleteChangeReason) => {
      console.log(e, selectedOption, reason, rowData)
      if (reason === 'createOption') {
        const newTag = selectedOption.pop()
        const isExisting = tagsList.some((option) => newTag === option.name)
        console.log(isExisting)
        if (!isExisting) {
          // the selected option is a new item
          handleCreateTag(newTag)
          return
        }
      } else if (reason === 'selectOption') {
        console.log(selectedOption)
        handleUpdateTag(selectedOption)
      } else if (reason === 'removeOption') {
        handleUpdateTag(selectedOption)
      }
    },
    [id]
  )

  // useEffect(() => {
  //   getTotal()
  //   getTax()
  // }, [getTotal, getTax, getDiscount])
  return (
    <>
      {loading && <LinearProgress />}
      <SimpleDataTable
        MaterialColumns={MaterialColumns}
        MaterialColumnsApiData={data?.length < 0 ? [] : data}
        stopRedirection={true}
        handleClick={(cb_data: any) => {
          setRowData(cb_data)
          setRowId(cb_data.label)
          setRowPk(cb_data.id)
          getList()
          setCustomerView(true)
          setUpdModalOpen(true)
          setOnClickView(true)
          updateQuantity(cb_data)
          GetDisclaimer()
        }}
      />
      {actionClick === true && (
        <Menu
          id='basic-menu-1'
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            handleClose()
          }}
          onMouseLeave={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div onMouseLeave={handleClose}>
            {rowData?.showQuote === 'True' && (
              <>
                <MenuItem
                  onClick={() => {
                    setPopupModal(true)
                  }}
                >
                  Convert Q-{rowData?.label} to Project
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFsPopupModal(true)
                  }}
                >
                  Convert Q-{rowData?.label} to Field Service
                </MenuItem>
              </>
            )}

            <MenuItem
              onClick={() => {
                updateQuantity(rowData)
              }}
            >
              Edit Q-{rowData?.label}
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeItem()
              }}
            >
              Delete Q-{rowData?.label}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleBackendPdf(rowData?.id)
                handleClose()
              }}
            >
              Generate PDF
            </MenuItem>
          </div>
        </Menu>
      )}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={popupModal}
        onHide={() => setPopupModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        {/* <Modal.Body>
          <h3>Did You really want to convert this {rowData?.label} Quote to Project?</h3>
          <h3>Do you want to change the status of Quote {rowData?.label} as Accepted?</h3>

        </Modal.Body> */}
        <Modal.Body>
          <h3>Do you want to change the status of Quote {quo_id?.label} as Accepted?</h3>
          {/* <label>
            <input
              type='checkbox'
              id='statusCheckbox'
              onChange={(e) => handleCheckbox(e)}
              checked={checkedValue === 'Accepted'}
            />
            Yes
          </label> */}
          <FormControl component='fieldset'>
            <FormGroup aria-label='position' row>
              <FormControlLabel
                value='yes'
                checked={checkedValue === 'Accepted'}
                onChange={(e) => handleCheckbox(e, 'Accepted')}
                control={<Checkbox />}
                label='Yes'
                id='statusCheckbox'
              />
              <FormControlLabel
                value='no'
                control={
                  <Checkbox
                    checked={checkedValue === 'New'}
                    onChange={(e) => handleCheckbox(e, 'New')}
                  />
                }
                label='No'
                id='statusCheckboxNo'
              />
            </FormGroup>
          </FormControl>
        </Modal.Body>

        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setPopupModal(false)
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              if (checkedValue === 'Accepted') {
                handleModal(rowData)
                setPopupModal(false)
              } else {
                handleModalClose(closeModal)
              }
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={fsPopupModal}
        onHide={() => setFsPopupModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        {/* <Modal.Body>
          <h3>Did You really want to convert this {rowData?.label} Quote to Project?</h3>
          <h3>Do you want to change the status of Quote {rowData?.label} as Accepted?</h3>

        </Modal.Body> */}
        <Modal.Body>
          <h3>Do you want to change the status of Field Service {quo_id?.label} as Accepted?</h3>
          <label>
            <input
              type='checkbox'
              id='statusCheckbox'
              onChange={(e) => handleCheckbox(e, 'Accepted')}
              checked={checkedValue === 'Accepted'}
            />
            Yes
          </label>
        </Modal.Body>

        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setFsPopupModal(false)
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleFSModal(rowData)
              setFsPopupModal(false)
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={projectModal}
        onHide={() => setProjectModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Project Details</h1>
        </Modal.Header>
        <Modal.Body>
          {/* <h3>Did You really want to convert this {rowData?.label} Quote to Project?</h3> */}
          {/* <form
            onSubmit={() => {
              handleModal(rowData,)
              setPopupModal(false)
            }}
          > */}
          <TextField
            name='Project Name'
            label='Project Name'
            placeholder='Enter Project Name'
            // value={projectForm.name}
            defaultValue={projectName === undefined ? prev_name : projectName}
            onChange={(e: any) => setProjectName(e.target.value)}
            multiline
            maxRows={1}
            fullWidth
          />
          <br />
          <br />
          <TextField
            name='Project description'
            label='Project Description'
            placeholder='Enter Project description'
            // value={projectForm.description}
            // onChange={handleInputChange}
            defaultValue={removeHtmlTags(prev_desc)}
            onChange={(e: any) => setProjectDescription(e.target.value)}
            multiline
            maxRows={1}
            fullWidth
          />
          <br />
          {/* <div className='card p-5'>
            <h2>Tags</h2>
            <div className='d-flex flex-column gap-4 mt-lg-6'>
              <Autocomplete
                multiple
                freeSolo
                id='free-solo-with-text-demo'
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                getOptionLabel={(option: any) => option?.name}
                filterOptions={(options, params) => {
                  const {inputValue} = params
                  const isExisting = options.some((option) => inputValue === option.name)
                  if (inputValue !== '' && !isExisting) {
                    options.push({name: inputValue, id: -1})
                  }
                  return options
                }}
                options={tagsList}
                value={selectedTags}
                onChange={(e, newval, reason) => {
                  handleSelectChange(e, newval, reason)
                }}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Create & Choose Tags'
                    placeholder='Tags'
                  />
                )}
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setProjectModal(false)
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleProjectData()
              setProjectModal(false)
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal
        modalShow={openDeleteModal}
        title={'Confirmation Message'}
        body={`Are you sure to delete this quote #${rowData?.label} ? `}
        handleClose={() => {
          setDeleteModal(false)
          setRowData('')
        }}
        confirmFunction={() => {
          deleteQuote(rowData?.id, id, rowData?.label)
          setDeleteModal(false)
        }}
      />
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modalOpen}
        onHide={() => {
          setModalOpen(false)
        }}
        size='ml'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Quote {newSub}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubQuote
            setModalOpen={setModalOpen}
            getList={getList}
            updateQuantity={updateQuantity}
            rowData={rowData}
            setRowData={setRowData}
            newSub={newSub}
            setNewSub={setNewSub}
            // defaultMarkup={defaultMarkup}
            // defaultMarkupUnit={defaultMarkupUnit}
          />
        </Modal.Body>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={descModal}
        onHide={() => setDescModal(false)}
        size='ml'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Quote Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubQuoteDesc
            getList={getList}
            descModal={descModal}
            setDescModal={setDescModal}
            rowData={rowData}
          />
        </Modal.Body>
      </Modal>
      <GeneratePDF pdfOpen={pdfOpen} pdfText='Generating PDF......' />

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={isModalOpen}
        onHide={() => {
          setUpdModalOpen(false)
          handleButtonClick()
          setOnClickView(false)
          setCustomerView(false)
        }}
        size='xl'
      >
        <Modal.Body id='generate_pdf_sub_quotes'>
          <SubquotesHeader
            getDisc={getDisc}
            defaultTax={defaultTax}
            // defaultMarkup={defaultMarkup}
            // defaultMarkupUnit={defaultMarkupUnit}
            pdfOpen={pdfOpen}
            setPdfOpen={setPdfOpen}
            onClickView={onClickView}
            setOnClickView={setOnClickView}
            customerView={customerView}
            setCustomerView={setCustomerView}
            formData={formData}
            setFormData={setFormData}
            taxValue={taxValue}
            itemTotal={itemTotal}
            setItemTotal={setItemTotal}
            serviceTotal={serviceTotal}
            setServiceTotal={setServiceTotal}
            rowData={rowData}
            viewItem={viewItem}
            viewService={viewService}
            disclaimer={disclaimer}
            setDisclaimer={setDisclaimer}
          />
          <SubquotesItemsView
            handleDisUnitChange={handleDisUnitChange}
            defaultMarkup={defaultMarkup}
            defaultMarkupUnit={defaultMarkupUnit}
            setDefaultMarkup={setDefaultMarkup}
            setDefaultMarkUnit={setDefaultMarkupUnit}
            data={data}
            setData={setData}
            toggleItem={toggleItem}
            itemService={itemService}
            GetItemServices={GetItemServices}
            setToggleItem={setToggleItem}
            handleDiscountChange={handleDiscountChange}
            selectedOnRow={selectedOnRow}
            handleCostChange={handleCostChange}
            handleQuantityChange={handleQuantityChange}
            handleOnRow={handleOnRow}
            itemRowData={itemRowData}
            setItemRowData={setItemRowData}
            markUp={markUp}
            getMarkUp={getMarkUp}
            handleMarkupChange={handleMarkupChange}
            itemRowId={itemRowId}
            setItemRowId={setItemRowId}
            setGetMarkUp={setGetMarkUp}
            setMarkUp={setMarkUp}
            setUpdModalOpen={setUpdModalOpen}
            customerView={customerView}
            finalCost={finalCost}
            setFinalCost={setFinalCost}
            getCostItems={getCostItems}
            getQuantityItems={getQuantityItems}
            setQuantityItems={setQuantityItems}
            fieldArrayData={fieldArrayData}
            setFieldArrayData={setFieldArrayData}
            setDisable={setDisable}
            setItemTotal={setItemTotal}
            rowId={rowId}
            getItem={getItem}
            item={item}
            setItem={setItem}
            rowData={rowData}
            setRowData={setRowData}
            viewItem={viewItem}
            setViewItem={setViewItem}
          />
          <br />
          <br />
          <SubquotesServicesView
            setUpdModalOpen={setUpdModalOpen}
            handleServiceDescriptionChange={handleServiceDescriptionChange}
            toggleService={toggleService}
            setToggleService={setToggleService}
            customerView={customerView}
            fieldServiceData={fieldServiceData}
            serviceDescription={serviceDescription}
            setServiceDescription={setServiceDescription}
            setFieldServiceData={setFieldServiceData}
            setDisable={setDisable}
            setServiceTotal={setServiceTotal}
            rowId={rowId}
            getService={getService}
            service={service}
            setService={setService}
            rowData={rowData}
            setRowData={setRowData}
            serviceRowData={serviceRowData}
            setServiceRowData={setServiceRowData}
            viewService={viewService}
            setViewService={setViewService}
            getPriceServices={getPriceServices}
            setGetPriceServices={setGetPriceServices}
            handleServicePriceChange={handleServicePriceChange}
            getQuantityServices={getQuantityServices}
            setQuantityServices={setQuantityServices}
            getServiceName={getServiceName}
            getServiceDescription={getServiceDescription}
            handleServiceNameChange={handleServiceNameChange}
            handleServiceDescChange={handleServiceDescChange}
            handleServiceQuantityChange={handleServiceQuantityChange}
          />
          <br />
          <SubQuoteTotal
            handleDisUnitChange={handleDisUnitChange}
            handleDiscountChange={handleDiscountChange}
            discount={discount}
            discountValue={discountValue}
            disUnit={disUnit}
            handletaxChange={handletaxChange}
            customerView={customerView}
            tax={tax}
            defaultTax={defaultTax}
            taxValue={taxValue}
            grandTotal={grandTotal}
            subTotal={subTotal}
            itemTotal={itemTotal}
            serviceTotal={serviceTotal}
            item={item}
            service={service}
            fieldArrayData={fieldArrayData}
            setFieldArrayData={setFieldArrayData}
            fieldServiceData={fieldServiceData}
            setFieldServiceData={setFieldServiceData}
            setSubTotal={setSubTotal}
          />
          <h3>
            Terms & Conditions
            <span
              style={{marginLeft: '10px', cursor: 'pointer'}}
              onClick={handleOpenMenu}
              id='basic-menu-2'
            >
              {customerView === false ? <MoreHorizIcon /> : ''}
            </span>
          </h3>
          <Menu
            id='basic-menu-2'
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={() => setAnchorEl1(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={showDisclaimerModal}>Edit Default Quote Disclaimer</MenuItem>
          </Menu>
          <Modal
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
            show={newModal}
            onHide={() => setNewModal(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <AddNote
              subQuoteDisclaimer={true}
              disclaimer={true}
              onModalClose={() => setNewModal(false)}
              onHide={() => setNewModal(false)}
            />
          </Modal>
          <br />
          <SubQuoteDisclaimer
            customerView={customerView}
            getDisc={getDisc}
            disclaimer={disclaimer}
            handleDisclaimerChange={handleDisclaimerChange}
          />
        </Modal.Body>

        <Modal.Footer>
          <SubQuoteFooter
            disUnit={disUnit}
            discount={discount}
            setDiscountValue={setDiscountValue}
            getServiceName={getServiceName}
            getServiceDescription={getServiceDescription}
            getCostItems={getCostItems}
            setCostItems={setCostItems}
            finalCost={finalCost}
            setFinalCost={setFinalCost}
            itemRowData={itemRowData}
            markUp={markUp}
            selectedOnRow={selectedOnRow}
            getMarkUp={getMarkUp}
            setViewItem={setViewItem}
            viewItem={viewItem}
            viewService={viewService}
            defaultTax={defaultTax}
            // defaultMarkup={defaultMarkup}
            // defaultMarkupUnit={defaultMarkupUnit}
            customerView={customerView}
            formData={formData}
            setDisable={setDisable}
            getList={getList}
            disable={disable}
            tax={tax}
            setTax={setTax}
            taxValue={taxValue}
            grandTotal={grandTotal}
            subTotal={subTotal}
            setSubTotal={setSubTotal}
            itemTotal={itemTotal}
            serviceTotal={serviceTotal}
            item={item}
            service={service}
            fieldArrayData={fieldArrayData}
            setFieldArrayData={setFieldArrayData}
            fieldServiceData={fieldServiceData}
            setFieldServiceData={setFieldServiceData}
            getItem={getItem}
            getService={getService}
            rowData={rowData}
            setUpdModalOpen={setUpdModalOpen}
            setItem={setItem}
            setService={setService}
            editedSubQuoteDisclaimer={discVal}
            getQuantityItems={getQuantityItems}
            setQuantityItems={setQuantityItems}
            getQuantityServices={getQuantityServices}
            setQuantityServices={setQuantityServices}
            getPriceServices={getPriceServices}
            setGetPriceServices={setGetPriceServices}
            setGetServiceDescription={setGetServiceDescription}
            setGetServiceName={setGetServiceName}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {BOMData}
