import {
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {
  currnetCompanyName,
  darkErrorMessage,
  fetchPurchaseHistoryInventoryUrl,
  fetchVendorListNamesOnly,
} from '../../modules/auth/core/_requests'
import {PurchaseOrderModel} from '../../modules/model/purchaseOrder'
import {
  CommaNumberFormatter,
  NumberFormatter,
  PURCHASE_ORDER_PARTIALLY_RECEIVED,
  PURCHASE_ORDER_RECEIVED,
} from './GlobalComponents'
import SimpleDataTable from './SimpleDataTable'

type PropType = {
  item_details: object | any
}
const SELECTED_ALL = 'all'

export default function PurchaseHistory({item_details}: PropType) {
  const {id} = useParams()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [PurchaseHistory, setPurchaseHistory] = useState([])
  const [Loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [option, setOption] = useState<any>([])
  const isAllSelected = option.length > 0 && selected.length === option.length

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {row?.purchase_order?.id}
          </div>
        ),
        size: 70,
        header: 'P.O.#', //uses the default width from defaultColumn prop
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {row?.purchase_order?.vendor?.name}
          </div>
        ),
        header: 'Vendor',
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {NumberFormatter(row.item_cost)}
          </div>
        ),
        header: 'Item Cost',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {CommaNumberFormatter(row?.quantity)}
          </div>
        ),
        header: 'QTY Ordered',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {CommaNumberFormatter(row?.received)}
          </div>
        ),
        header: 'QTY Received',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() =>
              navigate(`/${prefixCompany}/inventory/purchase-order/${row?.purchase_order?.id}/view`)
            }
          >
            {moment(row?.purchase_order?.date_ordered).format('ll')}
          </div>
        ),
        header: 'Order Date',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: any) => (
          <div
            style={{
              color: 'white',
              overflow: 'visible',
              padding: '5px 10px',
              fontSize: '13px',
              fontWeight: '600',
              borderRadius: '50px',
              display: 'table-row',
            }}
            className={
              row.purchase_order.order_status === PURCHASE_ORDER_PARTIALLY_RECEIVED
                ? 'bg-warning'
                : row.purchase_order.order_status === PURCHASE_ORDER_RECEIVED
                ? 'bg-success'
                : 'bg-primary'
            }
          >
            {row?.purchase_order?.order_status}{' '}
          </div>
        ),
        header: 'Status',
        size: 100, //decrease the width of this column
      },
    ],
    [navigate, prefixCompany]
  )

  const PurchaseHistoryFnc = useCallback(
    async (vendor_list: string | any = SELECTED_ALL) => {
      setLoading(true)
      await fetchPurchaseHistoryInventoryUrl(id, vendor_list)
        .then((res) => {
          const ITEMS = res?.data?.item
          setPurchaseHistory(ITEMS || [])
          setLoading(false)
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            darkErrorMessage(e?.response?.data?.error_list[0])
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [id]
  )

  const getVendorList = useCallback(async () => {
    setLoading(true)
    await fetchVendorListNamesOnly()
      .then((res) => {
        const VendorList = res?.data?.vendors
        setOption(VendorList || [])
        setLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    PurchaseHistoryFnc()
    getVendorList()
  }, [PurchaseHistoryFnc, getVendorList])

  const handleChange = useCallback(
    (event: any) => {
      const value = event.target.value
      if (value[value.length - 1] === SELECTED_ALL) {
        setSelected(
          selected.length === option.length
            ? []
            : option.map((item: {title: any}) => {
                return item.title
              })
        )
        PurchaseHistoryFnc(SELECTED_ALL)
        return
      } else {
        setSelected(value)
        PurchaseHistoryFnc(value)
      }
    },
    [PurchaseHistoryFnc, option, selected.length]
  )

  return (
    <>
      {Loading && <LinearProgress />}{' '}
      <div className='row mt-9 bg-white p-0'>
        <div className='col-12 overflow-scroll'>
          <div className='m-lg-2 mb-3 d-flex align-items-center justify-content-between'>
            <h2>
              <span title={'Manufacturer'} className='cursor-pointer'>
                {item_details?.brand?.name}
              </span>
              <span className='text-muted mx-3'>|</span>
              <span title={'Manufacturer Part#'} className='cursor-pointer'>
                {item_details?.sku}
              </span>
              <span className='text-muted mx-3'>|</span>
              <span title={'Item Name'} className='cursor-pointer'>
                {item_details?.name}
              </span>
            </h2>
            <FormControl className='w-200px h-35 m-2'>
              <InputLabel id='mutiple-select-label' style={{marginTop: '-6px'}}>
                Filter By Vendor
              </InputLabel>
              <Select
                labelId='mutiple-select-label'
                style={{height: '35px'}}
                multiple
                value={selected}
                label='Filter By Vendor'
                onChange={handleChange}
                renderValue={(selected) => selected.join(',')}
              >
                <MenuItem value={SELECTED_ALL}>
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={selected.length > 0 && selected.length < option.length}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Select All' />
                </MenuItem>
                {option.map((item: any) => (
                  <MenuItem value={item.name}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(item.name) > -1} />
                    </ListItemIcon>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <SimpleDataTable
            MaterialColumns={MaterialColumns}
            MaterialColumnsApiData={PurchaseHistory}
          />
        </div>
      </div>
    </>
  )
}
