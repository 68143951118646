import Roles from '../../../../pages/dashboard/Roles'
import {UserModel, UserModel_Intial} from '../../../auth'

export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  email: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'Keenthemes',
  email: 'Lnwebworks@mail.com',
  contactPhone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}

export interface IInvitation {
  fName: string
  lName: string
  pin: string
  email: string
  role: string
  phone: string
  title: string
  birthday: any
  hometown: string
  anniversary: any
}
export const IInvitationInitials: IInvitation = {
  fName: '',
  lName: '',
  pin: '',
  email: '',
  role: Roles.Engineering.value,
  phone: '',
  title: '',
  birthday: '',
  anniversary: '',
  hometown: '',
}
export interface Inventory_Items {
  brand_name: string
  vendor_name: string
  category_name: string
  item_description: string
  name: string
  mark_up: any
  maintain_stock: string
  reorder_level: string
  item_cost: string
  retail_price: string
  available_stock: string
  qty_in_store: string
  order_link: string
  category_id: string
  subcategory_id: string
  item_status: string
  brand_id: string
  vendor_id: string
  sku: string
  in_transit_stock: string
  reserved_stock: string
  uploadedImage: any
  manufaturar_part_no: string
  manufacturar: string
  inventory_location: string
  upload_attachment: any
}

export interface Purchase_Order {
  for_assignee: any
  tax: string
  vendor_id: string
  vendor_ordernumber: string
  shipping_method_id: string
  item_count: string
  unit_cost: string
  item_name: string
  item_sku: string
  shipping_cost: string
  total_cost: string
  expected_delivery: string
  tracking_number: string
  order_link: string
  order_status: string
  date_ordered: string
  item_manufacturer: string
  item_available_stock: string
  parent_ref: string
  attached_files: any
  order_subtotal: any
  vendor_order: string
  for: string
  shipping_method: string

  item_counter: [
    {
      item_name: string
      item_sku: string
      item_count: number
      unit_cost: number
      total_cost: string
      item_manufacturer: string
      item_available_stock: string
      ref: string
    }
  ]
}

export interface Create_Customer_Form {
  website: string
  fName: string
  lName: string
  company: string
  companyphone: string
  email: string
  phone: string
  address: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  billing_address_1: string
  billing_address_2: string
  billing_city: string
  billing_state: string
  billing_zip_code: string
  contactmethod: string
  discount: string
  refer: string
  notes: string
  cupro: string
  profile_pic: any
}

export interface Update_User_Form {
  fName: string
  lName: string
  profile_pic: string
  user_type: string
  phone_number: string
}

export const Update_User_Form_Initial: Update_User_Form = {
  fName: '',
  lName: '',
  profile_pic: '',
  user_type: '',
  phone_number: '',
}
export interface Update_Customer_Form {
  first_name: string
  last_name: string
  company: string
  company_phone: string
  email: string
  phone: string
  address: string
  city: string
  state: string
  zip_code: string
  contact_method: string
  discount_type: string
  referral_type: string
  notes: string
  address_line_2: string
  billing_address_1: string
  billing_address_2: string
  billing_city: string
  billing_state: string
  billing_zip_code: string
  website: string
  cupro: string
  profile_pic: any
}
export interface filesUpload {
  files: string
}

export interface Estimated_Notes {
  notes: string
}

export const Estimated_Notes_Initial: Estimated_Notes = {
  notes: '',
}

export interface Vendor_Notes {
  id?: string
  message: string
}

export const Vendor_Notes_Initial: Vendor_Notes = {
  message: '',
}
export interface Inventory_Management {
  inventory_info: string
}

export const Inventory_Management_Initials: Inventory_Management = {
  inventory_info: '',
}

export interface createQuoteForm {
  customer: string
  device_type: string
  device_model: string
  problem: string
  working_parts: string
  notes: string
  // type:string
  // work_order_number:string
  // status:string
  // response:string
  // user:string
  // customer_id:string
}
export const Create_Customer_Form_Initial = {
  fieldArrays: [
    {
      fName: '',
      lName: '',
      email: '',
      cupro: '',
      phone: '',
      companyphone: '',
      contactTitle: '',
      primary: '',
    },
  ],
  company: '',
  address: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  contactmethod: '',
  discount: '',
  refer: '',
  notes: '',
  billing_address_1: '',
  billing_address_2: '',
  billing_city: '',
  billing_state: '',
  billing_zip_code: '',
  website: '',
  profile_pic: '',
}
export const Create_Field_Service_Form_Initial: Create_Field_Service = {
  cstmrName: '',
  need: '',
  frame: '',
  additional_details: '',
  service_requested: '',
  emergency_call: '',
  time_frame: undefined,
}
export interface Create_Field_Service {
  time_frame: any
  additional_details: string
  service_requested: string
  emergency_call: string
  cstmrName: string
  need: string
  frame: string
}

export const Create_New_Field_Service_Form_Initial: Create_New_Field_Service = {
  need_soon: '',
  customer_name: '',
  time_frame: '',
  additional_details: '',
  service_requested: '',
  emergency_call: '',
}

export interface Create_New_Field_Service {
  additional_details: string
  service_requested: string
  emergency_call: string
  customer_name: string
  need_soon: string
  time_frame: any
}
export const Inventory_Items_Intial: Inventory_Items = {
  name: '',
  maintain_stock: '',
  reorder_level: '',
  item_cost: '',
  retail_price: '',
  available_stock: '',
  qty_in_store: '',
  order_link: '',
  category_id: '',
  subcategory_id: '',
  item_status: '',
  brand_id: '',
  vendor_id: '',
  sku: '',
  in_transit_stock: '',
  reserved_stock: '',
  manufacturar: '',
  manufaturar_part_no: '',
  item_description: '',
  mark_up: '',
  uploadedImage: '',
  brand_name: '',
  vendor_name: '',
  category_name: '',
  inventory_location: '',
  upload_attachment: '',
}

export const Purchase_Order_Intial: Purchase_Order = {
  vendor_id: '',
  vendor_ordernumber: '',
  shipping_method_id: '',
  item_count: '',
  shipping_cost: '',
  total_cost: '',
  attached_files: '',
  expected_delivery: '',
  tracking_number: '',
  order_link: '',
  order_status: '',
  date_ordered: new Date().toISOString().slice(0, 10),
  unit_cost: '',
  item_name: '',
  item_sku: '',
  item_available_stock: '',
  item_manufacturer: '',
  parent_ref: '',
  order_subtotal: '',
  vendor_order: '',
  for: '',
  shipping_method: '',
  item_counter: [
    {
      item_name: '',
      item_sku: '',
      item_count: 0,
      unit_cost: 0,
      total_cost: '',
      item_available_stock: '',
      item_manufacturer: '',
      ref: '',
    },
  ],
  tax: '',
  for_assignee: '',
}
export const Update_Customer_Form_Intial: Update_Customer_Form = {
  first_name: '',
  last_name: '',
  company: '',
  company_phone: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  contact_method: '',
  discount_type: '',
  referral_type: '',
  notes: '',
  address_line_2: '',
  billing_address_1: '',
  billing_address_2: '',
  billing_city: '',
  billing_state: '',
  billing_zip_code: '',
  website: '',
  cupro: '',
  profile_pic: '',
}
export const createQuoteFormIntial: createQuoteForm = {
  device_type: '',
  device_model: '',
  problem: '',
  working_parts: '',
  notes: '',
  customer: '',
}

export interface statusOptions_bgColor {
  Awaiting_Engineering_Estimate: any
  Pending_Initail_Finance_Review: any
  Ready_to_Send: any
  Send_to_Customer: any
  Customer_Approved_Pending_Finance: any
  Work_Order_Created: any
}

export const statusOptions_bgColorInitial: statusOptions_bgColor = {
  Awaiting_Engineering_Estimate: '#d6c3fa',
  Pending_Initail_Finance_Review: '#ffbfbf',
  Ready_to_Send: '#abafff',
  Send_to_Customer: '#f5efbf',
  Customer_Approved_Pending_Finance: '#ffbfbf',
  Work_Order_Created: '#cafaf0',
}

export interface statusOptions_textColor {
  Awaiting_Engineering_Estimate: any
  Pending_Initail_Finance_Review: any
  Ready_to_Send: any
  Send_to_Customer: any
  Customer_Approved_Pending_Finance: any
  Work_Order_Created: any
}

export const statusOptions_textColorInitial: statusOptions_textColor = {
  Awaiting_Engineering_Estimate: '#6610f2',
  Pending_Initail_Finance_Review: '#c70000',
  Ready_to_Send: '#00078c',
  Send_to_Customer: '#827300',
  Customer_Approved_Pending_Finance: '#c70000',
  Work_Order_Created: '#006953',
}

export interface statusWorkOptions_bgColor {
  In_Engineering: any
  Awaiting_parts: any
  Work_order: any
  Awaiting_Production: any
  In_Production: any
  Pending_Finance: any
  Ready_To_Ship: any
  Completed_This_Week: any
}

export const statusWorkOptions_bgColorInitial: statusWorkOptions_bgColor = {
  In_Engineering: '#e6c8eb',
  Awaiting_parts: '#ffeb90',
  Work_order: '#b2eb90',
  Awaiting_Production: '#fcb482',
  In_Production: '#74c5f1',
  Pending_Finance: '#ff9f9e',
  Ready_To_Ship: '#8de2d2',
  Completed_This_Week: '#079479',
}

export interface statusWorkOptions_textColor {
  In_Engineering: any
  Awaiting_parts: any
  Work_order: any
  Awaiting_Production: any
  In_Production: any
  Pending_Finance: any
  Ready_To_Ship: any
  Completed_This_Week: any
}

export const statusWorkOptions_textColorInitial: statusWorkOptions_textColor = {
  In_Engineering: '#b62be4',
  Awaiting_parts: '#998800',
  Work_order: '#246100',
  Awaiting_Production: '#d4661a',
  In_Production: '#00659b',
  Pending_Finance: '#a80e0c',
  Ready_To_Ship: '#079479',
  Completed_This_Week: '#8de2d2',
}

export const Task_To_Emp_Initial: Task_To_Emp = {
  task_title: '',
  task_description: '',
  task_end_date: '',
  task_selected_emp: '',
}

export interface Task_To_Emp {
  task_title: string
  task_description: string
  task_end_date: string
  task_selected_emp: string
}

export interface VendorInterface {
  po_count: number
  name?: any
  user?: UserModel
  vendor_name: string
  'vendor_account#': string
  account_id?: string
  contacts_field: [
    {
      fName: string
      lName: string
      email: string
      phone: string
      alternative_contact: string
      title: string
    }
  ]
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip: string
  website: string
  terms: string
  notes: string
  image: string
}

export const VendorInterface_Initial: VendorInterface = {
  vendor_name: '',
  contacts_field: [
    {
      fName: '',
      lName: '',
      email: '',
      phone: '',
      alternative_contact: '',
      title: '',
    },
  ],
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
  website: '',
  terms: '',
  notes: '',
  name: '',
  user: UserModel_Intial,
  image: '',
  po_count: 0,
  'vendor_account#': '',
  account_id: '',
}
export interface VendorCredentials {
  viewPasswordType: string
  passwordType: string
  id?: string
  vendor_id?: string
  vendor_account: string
  username: string
  password: string
  viewPasswordType:boolean
}

export const VendorCredentials_Initial: VendorCredentials = {
  viewPasswordType: '',
  vendor_account: '',
  username: '',
  password: '',
  passwordType: '',
  viewPasswordType: false,
}
