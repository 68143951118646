import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useSearchParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {
  createWorkOrderTag,
  darkErrorMessage,
  darkMessage,
  deleteWorkOrderTag,
  fetchWorkOrderTag,
  updateWorkOrderTag,
} from '../../modules/auth/core/_requests'
import CreateTag from './CreateTag'
import DeleteModal from './DeleteModal'
import {useLocalStorage} from './GlobalComponents'
import {MaterialDataTable} from './MatrialDataTable'

interface TagData {
  id: string
  name: string
  user_name: string
  user_image: string
}

const Tags = () => {
  const headerContext = useContext<any>(HeaderContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [tableStorage, setTableStorage] = useLocalStorage('tagsColsVisibility', '')
  const [loading, setLoading] = useState<boolean>(false)
  const [handleCreateModal, setHandleCreateModal] = useState<boolean>(false)
  const [handleUpdateModal, setHandleUpdateModal] = useState<{show: boolean; data: TagData | null}>(
    {
      show: false,
      data: null,
    }
  )
  const [handleDeleteModal, setHandleDeleteModal] = useState<{show: boolean; data: TagData | null}>(
    {
      show: false,
      data: null,
    }
  )
  const [data, setData] = useState<string[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })

  const getTagsList = useCallback((page: number = 0, str = '', pagePerEntries: number = 100) => {
    setLoading(true)
    fetchWorkOrderTag(page, str, pagePerEntries)
      .then(({data}) => {
        const LIST = data?.result
        const ROW_COUNT = data?.row_count
        setData(LIST)
        setRowCount(ROW_COUNT)
        setLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const search: any = searchParams.get('str')
    getTagsList(0, search)
    return () => {
      searchParams.set('str', '')
    }
  }, [getTagsList, searchParams])

  const PaginationCallback = useCallback(
    (getPagination: any) => {
      getTagsList(getPagination?.pageIndex, '', getPagination?.pageSize)
    },
    [getTagsList]
  )

  useEffect(() => {
    getTagsList(pagination.pageIndex, '', pagination.pageSize)
  }, [getTagsList, pagination])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: `Tags Manager`,
        subtitle: ` `,
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: -1,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    getTagsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteTag = useCallback(async () => {
    const TAG_ID: string = handleDeleteModal?.data?.id || ''
    setLoading(true)
    await deleteWorkOrderTag(TAG_ID)
      .then(({data}) => {
        darkMessage('Tag Deleted Successfully')
        setHandleDeleteModal({
          show: false,
          data: null,
        })
        setLoading(false)
        getTagsList()
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [getTagsList, handleDeleteModal])

  const handleUpdateTag = useCallback(
    async (input_data: string) => {
      const TAG_ID: string = handleUpdateModal?.data?.id || ''
      const UPDATE_TAG_NAME = input_data
      setLoading(true)
      await updateWorkOrderTag(TAG_ID, UPDATE_TAG_NAME)
        .then(({data}) => {
          darkMessage('Tag Updated Successfully')
          setHandleUpdateModal({
            show: false,
            data: null,
          })
          setLoading(false)
          getTagsList()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [getTagsList, handleUpdateModal]
  )

  const MaterialColumns: any = useMemo(
    () => [
      {
        header: 'Tag Name',
        accessorFn: (row: any) => row?.name,
        //uses the default width from defaultColumn prop
      },
      {
        header: 'Tag Status',
        accessorFn: (row: any) => 'ACTIVE',
        //uses the default width from defaultColumn prop
      },
      {
        header: 'Created By',
        accessorFn: (row: any) => (
          <a href={row?.user_image || null} target='blank' title={row?.user_name}>
            <img
              alt={row?.user_name}
              src={row?.user_image ? row?.user_image : '/media/avatars/blank.png'}
              onError={(evt: any) => {
                evt.target.src = '/media/avatars/blank.png'
              }}
              width='40px'
              height={'40px'}
              style={{objectFit: 'contain', borderRadius: '50%'}}
            />
          </a>
        ),
      },
      {
        accessorFn: (row: any) => (
          <>
            <button
              className='btn btn-light mx-2'
              onClick={() => {
                setHandleUpdateModal({
                  show: true,
                  data: row,
                })
              }}
            >
              Update Tag
            </button>
            <button
              className='btn btn-light mx-2'
              onClick={() => {
                setHandleDeleteModal({
                  show: true,
                  data: row,
                })
              }}
            >
              Delete Tag
            </button>
          </>
        ),
        header: 'Actions',
      },
    ],
    []
  )

  const handleCreateTag = useCallback(
    async (tag_data: string) => {
      setLoading(true)
      await createWorkOrderTag(tag_data)
        .then(({data}) => {
          darkMessage('New Tag Created Successfully')
          setLoading(false)
          getTagsList()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [getTagsList]
  )

  return (
    <>
      <div className='card-body'>
        <MaterialDataTable
          MaterialColumns={MaterialColumns}
          MaterialColumnsApiData={data}
          totalRows={rowCount}
          loadingBar={loading}
          tableHeight={700}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          columnVisibility={tableStorage}
          cbColumnVisibility={(callbackData) => {
            cbFunction(callbackData)
          }}
          navigationLink={''}
          stopRedirection={false}
          optionalTableHeader={
            <div className='card-title m-0 d-flex d-inline align-items-center'>
              <h4 className='fw-bolder m-5 w-115px'>
                <KTSVG path='/media/custom/tag.svg' className='svg-icon-1 me-3' />
                Tags List
              </h4>
              <div className='mx-lg-3'>
                <UsersListSearchComponent />{' '}
              </div>
              <Button
                className='btn btn-primary h-35px w-35px fw-bold'
                onClick={() => setHandleCreateModal(true)}
              >
                <div style={{marginTop: '-9px', marginLeft: '-7px', fontSize: '20px'}}>+</div>
              </Button>
            </div>
          }
        />
      </div>
      <CreateTag
        loading={loading}
        handleCreateModal={handleCreateModal}
        handleCloseCreateModal={() => setHandleCreateModal(false)}
        callbackFnx={(input_data: string) => handleCreateTag(input_data)}
      />
      <DeleteModal
        modalShow={handleDeleteModal?.show}
        title={'Confirmation Message'}
        body={'Are you sure you want to delete this tag?'}
        handleClose={() => {
          setHandleDeleteModal({
            show: false,
            data: null,
          })
        }}
        confirmFunction={() => {
          handleDeleteTag()
        }}
      />
      <CreateTag
        loading={loading}
        handleCreateModal={handleUpdateModal?.show}
        handleCloseCreateModal={() =>
          setHandleUpdateModal({
            show: false,
            data: null,
          })
        }
        callbackFnx={(input_data: string) => handleUpdateTag(input_data)}
        default_input_value={handleUpdateModal?.data?.name}
      />
    </>
  )
}

export default Tags
