/* eslint-disable jsx-a11y/anchor-is-valid */
import {LinearProgress} from '@mui/material'
import {FC, useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'

type Props = {
  list: any
  onConfirm: () => void
  onDiscard: () => void
  loading: boolean
}

const CardFilePreview: FC<Props> = ({list, onConfirm, onDiscard, loading}) => {
  const {currentUser} = useAuth()
  const [company, setCompany] = useState(currentUser?.company?.toLowerCase().replace(' ', '_'))
  return list != null ? (
    <div className=' h-100 mb-6'>
      {!loading ? (
        <>
          <div className='card card-body d-flex justify-content-center text-centser flex-column p-8'>
            {list.map((item: any, idx: number) => {
              return (
                <>
                  <a
                    href='#'
                    target='_blank'
                    className='text-gray-800 text-hover-primary d-flex flex-column'
                    download
                  >
                    <div className='symbol symbol-75px mb-6'>
                      {/* <img src={toAbsoluteUrl(icon)} alt='' /> */}
                    </div>
                    <div className='fs-5 fw-bolder mb-2'>
                      {idx + 1}) {item?.name}
                    </div>
                  </a>
                  <div className='fs-7 fw-bold text-gray-400 mt-auto'>
                    {(item?.size / 1024).toFixed(2)} KB
                  </div>
                </>
              )
            })}
            <div className='col-sm-12 my-5'>
              <button
                className='btn btn-primary btn-sm me-2'
                style={{width: '150px'}}
                onClick={() => onConfirm()}
              >
                Confirm to Upload
              </button>
              <button
                className='btn btn-dark btn-sm ms-2'
                style={{width: '150px'}}
                onClick={() => onDiscard()}
              >
                Discard
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <LinearProgress />{' '}
        </>
      )}
    </div>
  ) : (
    <></>
  )
}

CardFilePreview.defaultProps = {
  onConfirm: () => {},
  onDiscard: () => {},
  loading: false,
}
export {CardFilePreview}
