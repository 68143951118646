/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {useCallback, useEffect, useMemo, useState} from 'react'
import {TimeSheetConstant} from '../../../../app/context/TimeSheetConstant'
import {useAuth} from '../../../../app/modules/auth'
import {ShopTimeClockIN} from '../../../../app/modules/auth/core/_requests'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function ClockOutModal(props: any) {
  const TIMESHEET = TimeSheetConstant.JOB
  const [loading, setLoading] = useState(false)
  const [addNotes, setAddNotes] = useState('')
  const shoptime = () => {
    props?.handleSubmit()
    ShopTimeClockIN(TIMESHEET)
  }

  const modelData = useCallback(() => {
    localStorage.setItem('clockIn', addNotes)
  }, [addNotes])
  useEffect(() => {
    modelData()
  }, [addNotes])
  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      show={props?.show}
      size='lg'
      onHide={props?.onModalClose}
    >
      <Modal.Header>
        <Modal.Title>
          <div className='d-flex justify-content-around'>
            <h2 className='p-2 lh-base'>{props?.description}</h2>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='' style={{backgroundColor: 'rgb(255 255 255 / 60%)'}}>
        <div className='row mb-6'>
          <label className='col-lg-12 col-form-label  fw-bold fs-6 ml-6'>
            Enter your Note here
          </label>
          <div className='col-lg-12 fv-row'>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter your Note'
              rows={5}
              cols={36}
              onChange={(e: any) => {
                setAddNotes(e?.target?.value)
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='col-7' style={{position: 'relative', left: '-38px'}}>
          <Button variant='warning' onClick={shoptime}>
            Clock in Shop Time
          </Button>
        </div>
        <div className='col-4 d-flex justify-content-around'>
          <Button variant='secondary' onClick={props?.onModalClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={props?.handleSubmit}>
            {props?.value ? 'Clock Out' : 'Clock In'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
