/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect, useState, useMemo} from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import DataTable from 'react-data-table-component'
import {QuotesModel} from '../../modules/model/quotes'
import moment from 'moment'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {HAS_RMAOrderCreateRMA, HAS_RMAOrderViewTable} from './PermissionFunction'
import {currnetCompanyName, rmaOrderData} from '../../modules/auth/core/_requests'
import MaterialReactTable from 'material-react-table'
import {MaterialDataTable} from './MatrialDataTable'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../_metronic/helpers'
import {InventorySettings} from './InventorySettings'
const RMAOrders: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [totalRows, seTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const company = currnetCompanyName(currentUser?.company)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'RMA Orders',
        subtitle: `0 Total Records`,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/inventory/return-order/create`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])
  const navigate = useNavigate()

  const columns: any = useMemo(
    () => [
      {
        header: ' ',
        accessorFn: (row: QuotesModel) => (
          <Link to={`/${company}/quote/${row.id}/overview`} state={{data: row}}>
            #{row.id}
          </Link>
        ),
      },
      {
        header: 'RMA#',
        // accessorFn: (row: QuotesModel) => row.customer.company,
      },
      {
        header: 'Vendor Name',
        accessorFn: (row: QuotesModel) => row.project_name,
      },
      {
        header: 'Item Count',
        accessorFn: (row: QuotesModel) => row.project_location,
      },
      {
        header: 'Refunded Count',
        accessorFn: (row: QuotesModel) => row.panel_type,
      },
      {
        header: 'Total Refund Value',
        accessorFn: (row: QuotesModel) => row.panel_needed,
      },
      {
        header: 'Refunded Amount',
        accessorFn: (row: QuotesModel) => row.user?.first_name,
      },
      {
        header: 'Date',
        accessorFn: (row: QuotesModel) => moment(row.date_created).format('llll'),
      },
      {
        header: 'RMA Order Status',
        accessorFn: (row: QuotesModel) => <a href={`https://#`}>Convert to Project</a>,
      },
      {
        header: 'Actions',
        accessorFn: (row: QuotesModel) => <a href={`https://#`}>Convert to Project</a>,
      },
    ],
    []
  )

  const fields: any = useMemo(
    () => [
      {
        header: ' ',
        accessorFn: (row: QuotesModel) => (
          <Link to={`/${company}/quotes/${row.id}/overview`} state={{data: row}}>
            #{row.id}
          </Link>
        ),
      },
      {
        header: 'RMA#',
        accessorFn: (row: QuotesModel) => '',
      },
      {
        header: 'Vendor Name',
        accessorFn: (row: QuotesModel) => row.project_name,
      },
      {
        header: 'Item Count',
        accessorFn: (row: QuotesModel) => row.project_location,
      },
      {
        header: 'Refunded Count',
        accessorFn: (row: QuotesModel) => row.panel_type,
      },
      {
        header: 'Total Refund Value',
        accessorFn: (row: QuotesModel) => row.panel_needed,
      },
      {
        header: 'Refunded Amount',
        accessorFn: (row: QuotesModel) => row.user?.first_name,
      },
      {
        header: 'Date',
        accessorFn: (row: QuotesModel) => moment(row.date_created).format('llll'),
      },
      {
        header: 'RMA Order Status',
        accessorFn: (row: QuotesModel) => <a href={`https://#`}>Convert to Project</a>,
      },
      {
        header: 'Actions',
        accessorFn: (row: QuotesModel) => <a href={`https://#`}>Convert to Project</a>,
      },
    ],
    []
  )
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [data, setData] = useState<QuotesModel[]>([])

  const getList = useCallback(async (page: number = 1) => {
    await rmaOrderData(page)
      .then((res) => {
        let rma_order = res.data.return_orders
        setData(rma_order)
        seTotalRows(res.data.totalRows)
        setLoading(false)
        const stateHeader = {
          payload: {subtitle: `${res.data.totalRows} Total Records`},
          type: HeaderAction.Update,
        }
        headerContext.dispatch(stateHeader)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    function fetchList() {
      getList(1)
    }
    fetchList()
  }, [])

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(getPagination?.pageIndex)
  }, [])

  return HAS_RMAOrderViewTable(currentUser) ? (
    <>
      {/* <Toolbar1 btnText={"Create New Quote"} btnAction={() => {
        navigate(`/${prefixCompany}/quotes/create`)
      }} /> */}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      <div className='card mb-5 mb-xl-10 p-0'>
        <div
          className='card-header cursor-pointer'
          role='button'
          style={{borderBottom: '3px solid lightgrey'}}
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Pending RMA Orders</h3>
          </div>
        </div>
        {/* <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          progressComponent={loading}
          paginationTotalRows={totalRows}
          onChangePage={(page) => {
            getList(page)
          }}
          // selectableRows
        /> */}
        {/* <MaterialReactTable
          columns={columns}
          data={data}
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150,
          }}
          enableColumnResizing
          manualFiltering
          manualPagination
          enableGlobalFilter={false}
          manualSorting
          state={{pagination, showProgressBars: loading}}
          onPaginationChange={setPagination}
          enableStickyHeader
          enableStickyFooter
          columnResizeMode='onChange'
          // rowCount={totalRows}
          muiTablePaginationProps={{
            rowsPerPageOptions: [100, 200, 500],
          }}
          muiTableContainerProps={{sx: {maxHeight: '600px'}}}
        /> */}
        <MaterialDataTable
          MaterialColumns={fields}
          MaterialColumnsApiData={data}
          totalRows={totalRows}
          loadingBar={loading}
          tableHeight={600}
          navigationLink={'inventory/return-order'}
          additionalNavigationLink={'view'}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          optionalTableHeader={
            <div className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'>
              <h4
                className='fw-bolder m-5 w-115px'
                onClick={() => navigate(`/${prefixCompany}/inventory/return-order/create`)}
              >
                <KTSVG path='/media/icons/duotune/general/gen086.svg' className='svg-icon-4 me-3' />
                Pending RMA Order
              </h4>

              <div className='mx-lg-3'>
                <UsersListSearchComponent />{' '}
              </div>
              <div className='m-2'></div>
              <div className='mui-icons'>
                <InventorySettings />

                <div>
                  {HAS_RMAOrderCreateRMA(currentUser) ? (
                    <button
                      className='btn btn-primary  fw-bold'
                      style={{fontSize: '20px', padding: '3px 12px'}}
                      onClick={() => navigate(`/${prefixCompany}/inventory/return-order/create`)}
                    >
                      +
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header cursor-pointer'
          role='button'
          style={{borderBottom: '3px solid lightgrey'}}
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Completed RMA Orders </h3>
          </div>
        </div>
        {/* <DataTable
          columns={fields}
          data={data}
          pagination
          paginationServer
          progressComponent={loading}
          paginationTotalRows={totalRows}
          onChangePage={(page) => {
            getList(page)
          }}
          // selectableRows
        /> */}
        {/* <MaterialReactTable
          columns={fields}
          data={data}
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150,
          }}
          enableColumnResizing
          manualFiltering
          manualPagination
          enableGlobalFilter={false}
          manualSorting
          state={{pagination, showProgressBars: loading}}
          onPaginationChange={setPagination}
          enableStickyHeader
          enableStickyFooter
          columnResizeMode='onChange'
          // rowCount={totalRows}
          muiTablePaginationProps={{
            rowsPerPageOptions: [100, 200, 500],
          }}
          muiTableContainerProps={{sx: {maxHeight: '600px'}}}
        /> */}
        <MaterialDataTable
          MaterialColumns={fields}
          MaterialColumnsApiData={data}
          totalRows={totalRows}
          loadingBar={loading}
          tableHeight={600}
          navigationLink={'inventory/return-order'}
          additionalNavigationLink={'view'}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          optionalTableHeader={
            <div className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'>
              <h4
                className='fw-bolder m-5 w-115px'
                onClick={() => navigate(`/${prefixCompany}/inventory/return-order/create`)}
              >
                <KTSVG path='/media/icons/duotune/general/gen087.svg' className='svg-icon-4 me-3' />
                Completed RMA Order
              </h4>

              <div className='mx-lg-3'>
                <UsersListSearchComponent />{' '}
              </div>
              <div className='m-2'></div>
              <InventorySettings />
            </div>
          }
        />
      </div>
    </>
  ) : (
    <></>
  )
}

export {RMAOrders}
