// Menu item
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import blank from './blank.png'
import {Menu} from '@mui/material'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'

function MenuItem() {
  const isMobileView = window.innerWidth <= 991
  const {currentUser}: any = useAuth()
  const userCompany = currentUser?.company
  const userCompanyLogo = currentUser?.company_logo.includes('None')
    ? blank
    : currentUser?.company_logo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const open = Boolean(anchorEl)

  return (
    <div className={clsx('menu-item', {'me-lg-1': !isMobileView})}>
      {isMobileView && (
        <>
          <div className='menu-item me-lg-1'>
            <div className={clsx('menu-link py-0')}>
              <div
                className='m-auto pe-4 d-flex align-items-center gap-5 fw-bold'
                style={{color: '#4b525c'}}
              >
                <a href={userCompanyLogo} target='_blank' rel='noopener noreferrer'>
                  <img
                    src={userCompanyLogo}
                    alt={userCompany}
                    style={{objectFit: 'contain', height: '40px', width: '40px'}}
                  />
                </a>
                {userCompany.charAt(0).toUpperCase() + userCompany.slice(1)}
              </div>
            </div>
          </div>
          <div className='menu-item me-lg-1'>
            <div className={clsx('menu-link py-0')}>
              <div className='m-auto mx-1 cursor-pointer'>
                <button
                  style={{marginLeft: '27px'}}
                  className='btn btn-sm btn-icon btn-light btn-active-light-primary me-6'
                >
                  <KTSVG path='/media/icons/duotune/general/bell.svg' className='svg-icon-1 ' />
                </button>
                <span style={{fontSize: '14px'}} className='pe-3 fw-bold '>
                  Notification Bell
                </span>
              </div>
            </div>
          </div>
          <div className='menu-item me-lg-1'>
            <div className={clsx('menu-link py-0')}>
              <>
                <div className='m-auto mx-1 cursor-pointer'>
                  <button
                    style={{marginLeft: '27px'}}
                    className='btn btn-sm btn-icon btn-light btn-active-light-primary me-6'
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 ' />
                  </button>
                  <span style={{fontSize: '13px'}} className='pe-3 fw-bold '>
                    Search Bar
                  </span>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null)
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <>
                      <UsersListSearchComponent />{' '}
                    </>
                  </Menu>
                </div>
              </>
            </div>
          </div>
        </>
      )}
      {/* <Link
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className='menu-title'>{title}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link> */}
    </div>
  )
}

export {MenuItem}
