/* eslint-disable react-hooks/exhaustive-deps */
import {FormControl, MenuItem, Select} from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ProfileContext} from '../../../_metronic/layout/MasterLayout'
import {TimeSheetTextConstant} from '../../context/TimeSheetConstant'
import {viewQuote, filteredStatus} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'
// let statusOptions = [
//   {id: 1, value: 'Awaiting Engineering Estimate', bgcolor: '#d6c3fa', color: '#6610f2'},
//   {id: 2, value: 'Pending Initail Finance Review', bgcolor: '#ffbfbf', color: '#c70000'},
//   {id: 3, value: 'Ready to Send', bgcolor: '#abafff', color: '#00078c'},
//   {id: 4, value: 'Send to Customer', bgcolor: '#f5efbf', color: '#827300'},
//   {id: 5, value: 'Customer Approved, Pending Finance', bgcolor: '#ffbfbf', color: '#c70000'},
//   {id: 6, value: 'Project Created', bgcolor: '#cafaf0', color: '#006953'},
// ]

// let workStatusOptions = [
//   {id: 1, value: 'In Engineering', color: '#f5efbf', bgcolor: '#CA8ED4'},
//   {id: 2, value: 'Awaiting Parts', color: '#f5efbf', bgcolor: '#E9C834'},
//   {id: 3, value: 'Awaiting Production', color: '#f5efbf', bgcolor: '#DA7834'},
//   {id: 4, value: 'In Production', color: '#f5efbf', bgcolor: '#399ED5'},
//   {id: 5, value: 'Pending Finance', color: '#f5efbf', bgcolor: '#FF2D2C'},
//   {id: 6, value: 'Ready To Ship', color: '#f5efbf', bgcolor: '#3FBAA3'},
//   {id: 7, value: 'Completed This Week', color: '#f5efbf', bgcolor: '#65C6BD'},
// ]
type Props = {
  fetcher: (e: any) => {}
}

const StatusOpt: React.FC<Props> = ({fetcher}) => {
  const {type, id} = useParams<any>()
  const profileContext: any = useContext(ProfileContext)
  const [data, setData] = useState<any>([])
  // const [selectedOrder, setSelectedOrder] = useState<any>({
  //   id: 1,
  //   value: 'Awaiting Engineering Estimate',
  //   bgcolor: '#d6c3fa',
  //   color: '#6610f2',
  // })
  const [quotesData, setQuotesData] = useState<any>([])
  const [workData, setWorkData] = useState<any>([])
  const [fieldServiceData, setFieldServiceData] = useState<any>([])

  // const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>({
  //   id: 1,
  //   value: 'In Engineering',
  //   bgcolor: '#d6c3fa',
  //   color: '#6610f2',
  // })

  // const [selectedFieldService, setSelectedFieldService] = useState<any>({
  //   id: 1,
  //   value: 'Field service created',
  //   bgcolor: '#d6c3fa',
  //   color: '#6610f2',
  // })

  const TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : type === TimeSheetTextConstant.WORKORDER
      ? TimeSheetTextConstant.WORKORDER
      : TimeSheetTextConstant.FIELDSERVICE
  const updateStatus = (e: any) => {
    fetcher(e)
    profileContext?.state?.setStatus(true)
  }

  async function getList() {
    await viewQuote(id)
      .then((res) => {
        let fields = res.data.customer_details as QuotesModel
        setData(fields)
      })
      .catch(() => {})
  }

  const getQuotesData = () => {
    filteredStatus()
      .then((res) => {
        setQuotesData(res.data.quotes)
        setWorkData(res.data.workorder)
        setFieldServiceData(res.data.field_service)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  useEffect(() => {
    getQuotesData()
    getList()
  }, [])

  return (
    <>
      <div>
        <select
          name=''
          id=''
          className='btn btn-sm  me-3'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_offer_a_deal'
          style={{
            backgroundColor: data?.type?.bgcolor,
            color: data?.type?.color,
            fontSize: '13px',
            padding: 'auto 0px',
            width: '100%',
            border: '1px solid #9d9d9d',
            height: '51px',
          }}
          onChange={updateStatus}
        >
          <option value={data?.type?.title} selected disabled hidden>
            {data?.type?.title}
          </option>
          {TIMESHEET_TEXT === TimeSheetTextConstant.QUOTE ? (
            quotesData.map((item: any, index: number) => {
              const itemStyleQuote = {
                backgroundColor: item?.bgcolor,
                color: item.color,
              }
              return (
                <option style={itemStyleQuote} key={index}>
                  {item?.title}
                </option>
              )
            })
          ) : TIMESHEET_TEXT === TimeSheetTextConstant.WORKORDER ? (
            workData.map((item: any, index: number) => {
              const itemStyleProject = {
                backgroundColor: item?.bgcolor,
                color: item.color,
              }
              return <option style={itemStyleProject}>{item?.title}</option>
            })
          ) : TIMESHEET_TEXT === TimeSheetTextConstant.FIELDSERVICE ? (
            fieldServiceData.map((item: any) => {
              const itemStyleFS = {
                backgroundColor: item?.bgcolor,
                color: item.color,
              }
              return <option style={itemStyleFS}>{item?.title}</option>
            })
          ) : (
            <>No Data Found</>
          )}
        </select>
      </div>
    </>
  )
}

export {StatusOpt}
