/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {dashBoardCardApi} from '../../modules/auth/core/_requests'
import {currnetCompanyName} from '../../modules/auth/core/_requests'
import AddQuotes from './AddQuotes'

const DashboardPage: FC = () => {
  type ListData = {value: any; label: string; link: string}[]
  const {currentUser} = useAuth()
  const [listData, setListData] = useState<ListData>([])
  const [filterCustomer, setFilterCustomer] = useState('customer_today')
  const [filterQuote, setFilterQuote] = useState('quotes_today')
  const [filterProject, setFilterProject] = useState('project_today')
  const [filterFieldService, setFilterFieldService] = useState('fs_today')
  const [data, setData] = useState<any>({})
  const company = currnetCompanyName(currentUser?.company)
  const [modal, setModal] = useState(false)

  const fetchList = useCallback(async () => {
    try {
      const response = await dashBoardCardApi()
      const data = response.data
      setData(data)
      if (data) {
        const result = [
          {
            label: 'Store Inventory Value',
            value: data?.storeValue,
            link: `/${company}/inventory/store`,
          },
          {
            label: 'Number of Inventory Items',
            value: data?.inventoryItems,
            link: `/${company}/inventory/store`,
          },
          {
            label: 'In Transit Purchase Orders',
            value: data?.transitOrder,
            link: `/${company}/inventory/purchase-order`,
          },
          {
            label: 'In Transit RMA Orders',
            value: 0,
            link: '/',
          },
          {
            label: 'Number of Employees',
            value: data?.users,
            link: `/${company}/employee/view`,
          },
          {
            label: 'Number of Active Quotes',
            value: data?.activeQuotes,
            link: `/${company}/quote`,
          },
          {
            label: 'Number of Active Projects',
            value: data?.activeProject,
            link: `/${company}/project`,
          },
          {
            label: 'Number of Active Field Service',
            value: data?.fieldService,
            link: `/${company}/fieldservice`,
          },
          {
            label: 'Number of Customers',
            value: data?.customers,
            link: `/${company}/customers`,
          },
          {
            label: 'Number of Employees Clocked In',
            value: data?.clockInUser,
            link: `/${company}/employee/whoclockedIn`,
          },
          {
            label: 'Auto-Clockout Time',
            value: '8:00 PM',
            link: '/',
          },
          {
            label: 'Create No. of Customers Today',
            value: data?.customers_today,
            link: `/${company}/customers`,
          },
          {
            label: 'Create No. of Quotes Today',
            value: data?.quotesToday,
            link: `/${company}/quote`,
          },
          {
            label: 'Create No. of Projects Today',
            value: data?.wDataProjectToday,
            link: `/${company}/project`,
          },
          {
            label: 'Create No. of FS Today',
            value: data?.fsDataToday,
            link: `/${company}/fieldservice`,
          },
          {
            label: 'Create No. of Customers This Week',
            value: data?.customersThisWeek,
            link: `/${company}/customers`,
          },
          {
            label: 'Create No. of Quotes This Week',
            value: data?.quotesThisWeek,
            link: `/${company}/quote`,
          },
          {
            label: 'Create No. of Projects This Week',
            value: data?.wDataProjectThisWeek,
            link: `/${company}/project`,
          },
          {
            label: 'Create No. of FS This Week',
            value: data?.fsDataThisWeek,
            link: `/${company}/fieldservice`,
          },
          {
            label: 'Anniversaries of This Month',
            value: data?.anniversaries_this_month,
            link: `/${company}/employee/view`,
          },
          {
            label: 'Birthdays of This Month',
            value: data?.birthdays_this_month,
            link: `/${company}/employee/view`,
          },
        ]
        setListData(result)
      }
    } catch (error) {
      console.error(error)
    }
  }, [company])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  const handleCloseModal = useCallback(() => {
    setModal(false)
    fetchList()
  }, [fetchList])
  return (
    <>
      <div className='information-custom-cards'>
        <div className='container my-5'>
          <div
            className='row
          
          '
          >
            <div className='col-lg-4 col-md-6'>
              <div className='row gap-5 pb-sm-5'>
                <div className='col-md-12 customers-card'>
                  <div className='card'>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen063.svg'
                          className='svg-icon-2 card-svg-icon'
                        />
                        <a href={listData[8]?.link}>
                          <h3>Customers</h3>
                        </a>
                      </div>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <div className='text-center w-50'>
                          <span className='text-dark'>
                            New Customers -{' '}
                            <select
                              className='border-0 text-center w-auto bg-white'
                              name=''
                              id=''
                              onChange={(e) => {
                                setFilterCustomer(e.target.value)
                              }}
                            >
                              <option selected value='customer_today'>
                                Today
                              </option>
                              <option value='customer_this_week'>This Week</option>
                            </select>
                          </span>
                          <h3>
                            {filterCustomer === 'customer_this_week'
                              ? listData[15]?.value
                              : listData[11]?.value}
                          </h3>
                        </div>
                        <div className='border'></div>
                        <div className='text-center w-50'>
                          <span className='text-dark'>Total Customers</span>
                          <h3>{listData[8]?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=' col-md-12 qoutes-card'>
                  <div className='card'>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen070.svg'
                          className='svg-icon-2 me-3'
                        />
                        <a href={listData[5]?.link}>
                          <h3>Quotes</h3>
                        </a>
                      </div>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <div className='text-center w-50'>
                          <span className='text-dark'>
                            New Quotes -
                            <select
                              className='border-0 text-center w-auto bg-white'
                              name=''
                              id=''
                              onChange={(e) => {
                                setFilterQuote(e.target.value)
                              }}
                            >
                              <option selected value='quotes_today'>
                                Today
                              </option>
                              <option value='quotes_this_week'>This Week</option>
                            </select>
                          </span>
                          <h3>
                            {filterQuote === 'quotes_this_week'
                              ? listData[16]?.value
                              : listData[12]?.value}
                          </h3>
                        </div>
                        <div className='border'></div>
                        <div className='text-center w-50'>
                          <span className='text-dark'>Active Quotes</span>
                          <h3>{listData[5]?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 p-top-10'>
              <div className=' col-md-12 inventory-card'>
                <div className='card'>
                  <div className='card-body p-2'>
                    <div className='card-title text-align-center d-flex justify-content-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen067.svg'
                        className='svg-icon-2 card-svg-icon'
                      />
                      <h3>Inventory</h3>
                    </div>
                    <div className='card-body p-0 d-grid inventory-card-box'>
                      <a href={listData[0]?.link}>
                        <div className='text-center store-inventory'>
                          <span className='text-dark'>Store Inventory Value</span>
                          <h3>{listData[0]?.value}</h3>
                        </div>
                      </a>
                      <a href={listData[1]?.link}>
                        <div className='text-center inventory-item'>
                          <span className='text-dark'># of Inventory Items</span>
                          <h3>{listData[1]?.value}</h3>
                        </div>
                      </a>
                      <a href={listData[2]?.link}>
                        <div className='text-center transit-po'>
                          <span className='text-dark'>In Transit POs</span>
                          <h3>{listData[2]?.value}</h3>
                        </div>
                      </a>
                      <a href={listData[3]?.link}>
                        <div className='text-center transit-rma'>
                          <span className='text-dark'>IN Transit RMAs</span>
                          <h3>{listData[3]?.value}</h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 p-top-10'>
              <div className='row gap-5'>
                <div className='col-md-12 quote-of-month-card'>
                  <div className='card '>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <img
                          src={'/media/custom/inverted-commas.png'}
                          style={{width: '25px'}}
                          alt='inverted-comma'
                        />

                        <h3>Quote of the Month</h3>
                        <i
                          className='bi bi-pencil-square mx-2 cursor-pointer mt-1'
                          style={{color: 'black'}}
                          onClick={() => {
                            setModal(true)
                          }}
                        ></i>
                      </div>
                      <Modal
                        className={'fade'}
                        style={{backgroundColor:'rgba(0, 0, 0, 0.6)'}}
                        onModalClose={() => {
                          setModal(false)
                        }}
                        label={'somelable'}
                        show={modal}
                        onHide={() => setModal(false)}
                        size='lg'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                        
                      >
                        <AddQuotes handleCloseModal={handleCloseModal}  />
                      </Modal>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <div className='text-center '>
                          <span className='fst-italic fw-light'>
                            {data?.quote_of_the_month ||
                              'Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{' '}
                <div className='col-md-12 work-anniversary-month-card'>
                  <div className='card '>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <h3>Work Anniversaries This Month</h3>
                      </div>
                      <div className='d-flex justify-content-evenly'>
                        <table>
                          <tbody>
                            {listData[19]?.value.length > 0 ? (
                              listData[19]?.value.map((item: any, index: any) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={item?.image?item?.image:'/media/avatars/blank.png'}
                                      alt='User Image'
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '15px',
                                        marginRight: '25px',
                                      }}
                                      onError={(evt: any) => {
                                        evt.target.src = '/media/avatars/blank.png'
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        marginRight: '25px',
                                      }}
                                    >
                                      {item.user_name}
                                    </p>
                                  </td>
                                  <td>
                                    <span className='text-muted'>
                                      {/* {moment(item?.anniversary).format('MMMM, Do')} */}
                                      <span className='ms-1 text-italic'>
                                        {moment().diff(item?.anniversary, 'years')} Years
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <span className='fst-italic fw-light'>
                                No anniversary will be this month{' '}
                              </span>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 p-top-10'>
              <div className='row gap-5'>
                <div className=' col-md-12 project-card'>
                  <div className='card'>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <KTSVG path='/media/custom/Project_s.svg' className='svg-icon-2 me-3' />
                        <a href={listData[6]?.link}>
                          <h3>Projects</h3>
                        </a>
                      </div>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <div className='text-center w-50'>
                          <span className='text-dark'>
                            New Projects -{' '}
                            {
                              <select
                                className='border-0  text-center w-auto bg-white'
                                name=''
                                id=''
                                onChange={(e) => {
                                  setFilterProject(e.target.value)
                                }}
                              >
                                <option selected value='project_today'>
                                  Today
                                </option>
                                <option value='project_this_week'>This Week</option>
                              </select>
                            }
                          </span>
                          <h3>
                            {filterProject === 'project_this_week'
                              ? listData[17]?.value
                              : listData[13]?.value}
                          </h3>
                        </div>
                        <div className='border'></div>
                        <div className='text-center w-50'>
                          <span className='text-dark'>Active Projects</span>
                          <h3>{listData[6]?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 field-card'>
                  <div className='card '>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen077.svg'
                          className='svg-icon-2 card-svg-icon'
                        />
                        <a href={listData[7]?.link}>
                          <h3>Field Service</h3>
                        </a>
                      </div>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <div className='text-center w-50'>
                          <span className='text-dark'>
                            New FS -
                            <select
                              className='border-0 text-center w-auto bg-white'
                              name=''
                              id=''
                              onChange={(e) => {
                                setFilterFieldService(e.target.value)
                              }}
                            >
                              <option selected value='fs_today'>
                                Today
                              </option>
                              <option value='fs_this_week'>This Week</option>
                            </select>
                          </span>
                          <h3>
                            {filterFieldService === 'fs_this_week'
                              ? listData[18]?.value
                              : listData[14]?.value}
                          </h3>
                        </div>
                        <div className='border'></div>
                        <div className='text-center w-50'>
                          <span className='text-dark'>Active FS</span>
                          <h3>{listData[7]?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 p-top-10'>
              <div className='row'>
                <div className='col-md-12 employees-card'>
                  <div className='card '>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen065.svg'
                          className='svg-icon-2 card-svg-icon'
                        />
                        <h3>Employees</h3>
                      </div>
                      <div className='card-body p-0 d-flex d-flex justify-content-center gap-5'>
                        <a className='text-center w-50' href={listData[9]?.link}>
                          <div>
                            <span className='text-dark'>Employees Clocked In </span>
                            <h3>{listData[9]?.value}</h3>
                          </div>
                        </a>

                        <div className='border'></div>
                        <a className='text-center w-50' href={listData[4]?.link}>
                          <div>
                            <span className='text-dark'>Active Employees</span>
                            <h3>{listData[4]?.value}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 p-top-10'>
              <div className='row'>
                <div className='col-md-12 work-anniversary-month-card'>
                  <div className='card '>
                    <div className='card-body p-2'>
                      <div className='card-title text-align-center d-flex justify-content-center'>
                        <h3>Birthdays This Month</h3>
                      </div>
                      <div className='d-flex justify-content-evenly'>
                        <table>
                          <tbody>
                            {listData[20]?.value.length > 0 ? (
                              listData[20]?.value.map((item: any, index: any) => (
                                <tr>
                                  <td>
                                    <img
                                      src={item.image? item.image:'/media/avatars/blank.png'}
                                      alt='User Image'
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '15px',
                                        marginRight: '25px',
                                      }}
                                      onError={(evt: any) => {
                                        evt.target.src = '/media/avatars/blank.png'
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        marginRight: '25px',
                                      }}
                                    >
                                      {item.user_name}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <span className='text-muted'>
                                        {moment(item?.birthday).format('MMMM, Do')}
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <span className='fst-italic fw-light'>
                                No birthday will be this month{' '}
                              </span>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <td></td>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [headerContext])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
