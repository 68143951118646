/* eslint-disable jsx-a11y/anchor-is-valid */
interface PropsType {
  classname: any
  placeholder: any
  inputType: any
  customfield: any
  onChange?: any
}

interface AnotherPropsType {
  classname: any
  label: any
}

export const GlobalForm = ({
  classname,
  placeholder,
  inputType,
  customfield,
  onChange,
}: PropsType) => {
  return (
    <input
      className={classname}
      placeholder={placeholder}
      type={inputType}
      onChange={onChange}
      {...customfield}
    />
  )
}

export const GlobalLable = ({classname, label}: AnotherPropsType) => {
  return <label className={classname}>{label}</label>
}
