/* eslint-disable react-hooks/exhaustive-deps */
import {useContext,useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {QuoteStepper} from '../../../context/QuoteContext'
const profileDetailsSchema = Yup.object().shape({
  project_type: Yup.string().required('Above Selection is Required'),
})

const Step1 = (projectType: any) => {
  const qsContext: any = useContext(QuoteStepper)

  interface FormikValType {
    project_type: string
  }
  console.log((projectType = '' ? '' : projectType), projectType)
  const initialValues: FormikValType = {
    project_type: (projectType = '' ? '' : projectType),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values: any) => {
      console.log(values.project_type)
      values.project_type === 'Project Quote'
        ? (values.project_type = 0)
        : values.project_type === 'Project'
        ? (values.project_type = 1)
        : values.project_type === 'Field Service'
        ? (values.project_type = 2)
        : (values.project_type = 0)
      qsContext.dispatch({type: 'UPDATE_STEP_1', payload: values})
    },
  })
  // useEffect(() => {
    
  //   formik.submitForm();
  // }, [formik.values.project_type]);
  return (
    <>
      <div className='card'>
        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div>
              <h1 className='text-center p-5'>Create New ...</h1>
              <div
                className='row justify-content-evenly'
                style={{display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '30px'}}
              >
                <button
                  className='column d-grid justify-content-center align-items-center text-center p-lg-9  bg-secondary border-0'
                  style={{width: '200px', height: '200px', borderRadius: '10px'}}
                  onClick={() => {
                    formik.setFieldValue('project_type', 'Project Quote')
                  }}
                >
                  <h2>Quote</h2>
                  <h3> Q -</h3>
                </button>
                <button
                  className='column d-grid justify-content-center align-items-center text-center p-lg-9  bg-secondary border-0'
                  style={{width: '200px', height: '200px', borderRadius: '10px'}}
                  onClick={() => {
                    formik.setFieldValue('project_type', 'Project')
                  }}
                >
                  <h2>Project</h2>
                  <h3>P -</h3>
                </button>
                <button
                  className='column d-grid justify-content-center align-items-center text-center p-lg-9  bg-secondary border-0'
                  style={{width: '200px', height: '200px', borderRadius: '10px'}}
                  onClick={() => {
                    formik.setFieldValue('project_type', 'Field Service')
                  }}
                >
                  <h2>Field Service</h2>
                  <h3>FS -</h3>
                </button>
              </div>

              <div className='row pt-5'>
                <div className='col-md-6'>
                  {formik.touched.project_type && formik.errors.project_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.project_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export {Step1}
