/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {CircularProgress} from '@mui/material'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../auth'
import {companyDetails, currnetCompanyName, profileImg, updateCompany} from '../auth/core/_requests'
import {CompanyForm} from './CompanyForm'
import blank from '../../../_metronic/layout/components/header/blank.png'
const CompanyHeader: React.FC = () => {
  const headerContext = useContext<any>(HeaderContext)
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>()
  const [buffer, setBuffer] = useState(false)
  const [img, setImg] = React.useState<any>(null)
  const inputFile = useRef<any>()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Company',
        subtitle: ` `,
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = () => {
    companyDetails()
      .then((res) => {
        setData(res?.data?.company_data)
        setImg(res?.data?.company_data?.company_logo)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    getList()
  }, [])

  const Updatelogo = (props: any) => {
    let info = {
      url: props,
      id: data?.id,
    }
    updateCompany(info)
      .then(() => {
        setBuffer(true)
        getList()
        setTimeout(() => {
          setBuffer(false)
        }, 0)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <>
      <div>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4 company-logo-wrap'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  {buffer ? (
                    <div
                      style={{
                        height: '170px',
                        width: '170px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress style={{margin: 'auto'}} />
                    </div>
                  ) : (
                    <>
                      <img
                        src={img}
                        alt='Company Logo'
                        onError={() => {
                          setImg(blank)
                        }}
                        style={{
                          width: '190px',
                          height: '190px',
                          margin: 'auto',
                          cursor: 'pointer',
                          objectFit: 'cover',
                        }}
                      />
                    </>
                  )}
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div
                        className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        style={{cursor: 'pointer'}}
                      >
                        {data?.company_name.charAt(0).toUpperCase() + data?.company_name.slice(1)}
                      </div>
                      <a href='#'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen026.svg'
                          className='svg-icon-1 svg-icon-primary'
                        />
                      </a>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-4 me-1'
                        />
                        {data?.created_by?.first_name + ' ' + data?.created_by?.last_name}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        {data?.created_by?.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyForm />
    </>
  )
}

export {CompanyHeader}
