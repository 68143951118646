import {FC} from 'react'
import {useAuth} from '../../modules/auth'
import {WorkOrderTable} from './TableCompo/WorkOrderTable'
import {HAS_WorkOrderTable} from './PermissionFunction'

const WorkOrder: FC = () => {
  const {currentUser} = useAuth()

  return HAS_WorkOrderTable(currentUser) ? (
    <>
      <WorkOrderTable />
    </>
  ) : (
    <></>
  )
}

export default WorkOrder
