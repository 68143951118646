import React from 'react'
import { useState } from "react"

export interface ErrorType {
    error_list: Array<string>,
    error: boolean,
}

interface PropsType {
    error_response:ErrorType
}

const ErrorAlert = ({error_response}: PropsType)  => {

const [visible, setVisible] = useState<boolean>(true)
  setTimeout(() => {
    // setVisible(false)
  }, 5000)

  return (
    <>
        { (error_response.error_list.length > 0) && (
            <div className="container">
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {
                            error_response.error_list.map((item:string) => {
                               return <b key={item}>{item}</b>
                            })
                        }
                    </div>
                </div>
            </div>
        )}
    </>
  )
}

export default ErrorAlert