/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import MaterialReactTable from 'material-react-table'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  inventoryItemData,
  inventoryListForPO,
  addAssignedItems,
  fetch_all_services,
  subquote_service_delete,
  updateService,
  create_new_service,
  GetServiceToggle,
} from '../../modules/auth/core/_requests'
import {
  Autocomplete,
  CircularProgress,
  Menu,
  MenuItem,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {useNavigate, useParams} from 'react-router-dom'
import DeleteModal from './DeleteModal'
import {Button, Modal} from 'react-bootstrap'
import {FieldArray, Form, Field, Formik} from 'formik'
import SimpleDataTable from './SimpleDataTable'
import {useDebounce} from '../../../_metronic/helpers'
import {NumberFormatter} from './GlobalComponents'
import DataTable from 'react-data-table-component'
import EditService from './Services'
import Services from './Services'

interface CallBack_DataType {
  input_data: string
  service_des: string
  number_data: number
}

interface ServiceData {
  id: string
  service_name: string
  service_description: string
  service_rate: number
  user_name: string
  user_image: string
}
interface Row {
  service_name: string | number | readonly string[] | undefined
  id: number
  name: string
  age: number
  rate: number
}

//  Typescript type for Props (Parent to Child and Child to Parent)
type PropsType = {
  title: string
  loading: boolean
  handleCreateModal: boolean
  handleCloseCreateModal: () => void
  callbackFnx: (input_data: CallBack_DataType) => void
  default_input_value?: string
  default_service_value?: string
  default_number_value?: number
}

const SubquotesServicesView: React.FC = ({
  getServiceName,
  getServiceDescription,
  handleServiceNameChange,
  handleServiceDescChange,
  handleServiceQuantityChange,
  handleServicePriceChange,
  setUpdModalOpen,
  customerView,
  setRowData,
  serviceRowData,
  setServiceRowData,
  fieldServiceData,
  setFieldServiceData,
  setDisable,
  setServiceTotal,
  rowId,
  getService,
  service,
  setService,
  viewService,
  setViewService,
  getQuantityServices,
  setQuantityServices,
  getPriceServices,
  setGetPriceServices,
  handleServiceDescriptionChange,
  handleCloseCreateModal,
  callbackFnx,
  default_input_value = '',
  default_service_value = '',
  default_number_value = 0,
  rowData,
  toggleService,
  setToggleService,
}: // setRowData,
any) => {
  const [inputFieldData, setInputFieldData] = useState('')
  const [serviceDescriptionField, setServiceDescriptionField] = useState('')
  const [numberFieldData, setNumberFieldData] = useState(0.0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {id}: any = useParams<any>()
  const [compo, setCompo] = useState('overview')
  const [compo1, setCompo1] = useState('associated')
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [associatedItemsFilter, setAssociatedItemsFilter] = useState<any>([])
  const [substitutesItemsFilter, setSubstitutesItemsFilter] = useState<any>([])
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isUpdateServiceModalOpen, setIsUpdateServiceModalOpen] = useState(false)

  const [deleteModalVendor, setDeleteModalVendor] = useState<any>({
    show: false,
    data: null,
  })
  const [inventoryItems, setInventoryItems] = useState([])
  const opens = Boolean(anchorEl)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const initialValues = {
    assigned_item: [{inventory_id: '', assigned_items: ''}],
  }
  const [loading, setLoading] = useState(false)
  const [selectedValue2, setSelectedValue2] = useState([])
  const [createModal, setCreateModal] = useState(false)
  const [autoOpen, setAutoOpen] = useState(false)
  const [options, setOptions] = useState<any>([])
  const [initialPrice, setInitialPrice] = useState(0)
  const [initialQuantity, setInitialQuantity] = useState(0)
  const autoCompleteLoading = autoOpen && options.length === 0
  const [newItems, setNewItems] = useState<any>([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [storeQuantity, setStoreQuantity] = useState<any>({})
  const [storeNames, setStoreNames] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [searchItems, setSearchItems] = useState<any>([])
  const [item_counter, setItem_Counter] = useState<string[]>([])
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [getUnitCost, setUnitCost] = useState<number[]>([])
  const [subtotals, setSubtotals] = useState<number[]>([0])
  const refData = useRef<any>(null)
  const popupRef = useRef()
  const [searchDiv, setSearchDiv] = useState(false)
  const [searchDiv2, setSearchDiv2] = useState(false)
  const [handleCreateModal, setHandleCreateModal] = useState<boolean>(false)
  const [create_inventory_modal, set_create_inventory_modal] = useState(false)
  const [showCustomServiceFields, setShowCustomServiceFields] = useState(false)
  const [newSerName, setNewSerName] = useState('')
  const [newRowName, setNewRowName] = useState('')
  const [newRowAge, setNewRowAge] = useState(0)
  const [newRowRate, setNewRowRate] = useState(0)
  const [toggleQuantity, setToggleQuantity] = useState(true)
  const [togglePrice, setTogglePrice] = useState(true)
  const [toggleTotal, setToggleTotal] = useState(true)
  const handleToggleServices = useCallback(
    async (toggleService: any) => {
      await GetServiceToggle(rowId, id, JSON.stringify(toggleService))
        .then((res: any) => {
          console.log(res)
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    [rowId, id]
  )
  useEffect(() => {
    handleToggleServices(toggleService)
  }, [handleToggleServices, toggleService])

  const handleServiceToogle = (service_type: any) => {
    setToggleService((prevState: any) => ({
      ...prevState,
      [service_type]: !prevState[service_type],
    }))
  }

  const getServiceList = useCallback(async () => {
    setLoading(true)
    await fetch_all_services(0, '', 100)
      .then((res) => {
        const inventoryItems = res?.data?.fetch_services

        setOptions(inventoryItems)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [])

  function handlePriceChange(value: any) {
    setInitialPrice(value.target.value)
  }
  useEffect(() => {
    if (isModalOpen === false) {
      setInitialPrice(0)
      setInitialQuantity(0)
      setSelectedValue2([])
    }
    if (isModalOpen === true) {
      getServiceList()
      setNewItems([])
    }
  }, [initialPrice, initialQuantity, isModalOpen])

  useEffect(() => {
    if (!createModal) {
      setOptions([])
    }
  }, [service, createModal])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'View Inventory Item',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = useCallback(async () => {
    await inventoryItemData(id)
      .then((res) => {
        const itemData = res?.data?.items[0]
        const getAssociatedItems = res?.data?.items[0]?.associated_items
        const getSubstitutesItems = res?.data?.items[0]?.substitutes_items
        setAssociatedItemsFilter(getAssociatedItems || [])
        setSubstitutesItemsFilter(getSubstitutesItems || [])
        setService(itemData || [])
      })
      .catch((e) => {
        console.error(e)
      })
  }, [id])

  const allInventoryData = useCallback(async () => {
    await inventoryListForPO()
      .then((res) => {
        const inventoryItems = res?.data?.items
        setInventoryItems(inventoryItems)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  // const handleToggleQuantity = () => setToggleQuantity(!toggleQuantity)
  // const handleTogglePrice = () => setTogglePrice(!togglePrice)
  // const handleToggleTotal = () => setToggleTotal(!toggleTotal)
  const fadeStyleQuantity: React.CSSProperties = {
    opacity: toggleService['quantity'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const fadeStylePrice: React.CSSProperties = {
    opacity: toggleService['price'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const fadeStyleTotal: React.CSSProperties = {
    opacity: toggleService['total'] ? 1 : 0.3,
    transition: 'opacity 0.5s',
  }
  const existingStyle = {
    display: 'flex',
    alignItems: 'center',
  }
  const itemQunatityStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemPriceStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const itemTotalStyle: React.CSSProperties = {
    whiteSpace: 'normal',
  }
  const combinedStyleQunatity = {
    ...existingStyle,
    ...fadeStyleQuantity,
  }
  const combinedStylePrice = {
    ...existingStyle,
    ...fadeStylePrice,
  }
  const combinedStyleTotal = {
    ...existingStyle,
    ...fadeStyleTotal,
  }
  const combinedQuantityStyle: React.CSSProperties = {
    ...itemQunatityStyle,
    ...fadeStyleQuantity,
  }
  const combinedPriceStyle: React.CSSProperties = {
    ...itemPriceStyle,
    ...fadeStylePrice,
  }
  const combinedPriceTotal: React.CSSProperties = {
    ...itemTotalStyle,
    ...fadeStyleTotal,
  }
  useEffect(() => {
    const storedToggleQuantity: string | null =
      localStorage.getItem('toggleQuantityService') ?? null
    if (storedToggleQuantity !== null) {
      setToggleQuantity(JSON.parse(storedToggleQuantity))
    }
  }, [])
  useEffect(() => {
    const storedTogglePrice: string | null = localStorage.getItem('togglePriceService') ?? null
    if (storedTogglePrice !== null) {
      setTogglePrice(JSON.parse(storedTogglePrice))
    }
  }, [])
  useEffect(() => {
    const storedToggleTotal: string | null = localStorage.getItem('toggleTotalService') ?? null
    if (storedToggleTotal !== null) {
      setToggleTotal(JSON.parse(storedToggleTotal))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('toggleQuantityService', JSON.stringify(toggleQuantity))
    localStorage.setItem('togglePriceService', JSON.stringify(togglePrice))
    localStorage.setItem('toggleTotalService', JSON.stringify(toggleTotal))
  }, [toggleQuantity, togglePrice, toggleTotal])
  useEffect(() => {
    getService()
    getList()
    allInventoryData()
  }, [allInventoryData, getList])

  const getSearchData = useCallback(
    async (str = '', page: number = 0, pagePerEntries: number = 100) => {
      setLoading(true)
      await fetch_all_services(page, str, pagePerEntries)
        .then(({data}) => {
          const SERVICES_DATA = data?.fetch_services
          setSearchItems(SERVICES_DATA || [])
          setLoading(false)
          SERVICES_DATA.length === 0 ? setSearchDiv(true) : setSearchDiv(false)
          SERVICES_DATA.length > 0 ? setSearchDiv2(true) : setSearchDiv2(false)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length >= 3) {
      getSearchData(searchTerm)
    } else if (searchTerm.length === 0) {
      setSearchItems([])
      setSearchDiv(false)
    }
    return () => {}
  }, [getSearchData, searchTerm, debouncedSearchTerm])
  const handleClick = (row_Data: any, event: React.MouseEvent<HTMLButtonElement>) => {
    setServiceRowData(row_Data)
    setAnchorEl(event.currentTarget)
  }
  const handleOutsideClick = useCallback((event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSearchDiv(false)
      setSearchDiv2(false)
    }
  }, [])
  const MaterialSearchServicesCol: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.service_name,
        accessorKey: 'service name',
      },
      {
        accessorFn: (row: any) => row?.service_description,
        accessorKey: 'service description',
      },
      {
        accessorFn: (row: any) => '$' + row?.service_rate + '.00',
        accessorKey: 'service rate',
      },
      // {
      //   accessorFn: (row: any) => row?.user_name,
      //   accessorKey: 'service user_name',
      // },
    ],
    []
  )
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  const handleUnitCostChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newUnitCost = parseFloat(e.target.value)
    setUnitCost((prevUnitCost) => {
      const updatedUnitCost = [...prevUnitCost]
      updatedUnitCost[index] = newUnitCost
      return updatedUnitCost
    })
  }

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    const newQuantity = parseFloat(e.target.value)
    const serviceRate = row?.service_rate
    setQuantityServices((prevQuantity: any) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }
  const handleFieldArrayItems = useCallback(
    (index_data: any) => {
      const dataArrayCopy: any = [...fieldServiceData]
      dataArrayCopy.push(index_data)
      setItem_Counter((prevItem_counter: any) => [...prevItem_counter, index_data?.id])
      setFieldServiceData(dataArrayCopy)
      setSearchTerm('')
    },
    [fieldServiceData]
  )
  const [visibleAddButton, setVisibleAddButton] = useState(
    localStorage.getItem('visibleAddButton') === 'true'
  )

  const mappedDataState1 = viewService.map(
    (row: {
      id: any
      service: any
      service_name: any
      service_description: any
      service_cost: number
      quantity: number
      serviceDescription: any
    }) => ({
      id: row.id,
      service: row?.service,
      service_name: row?.service_name,
      service_description:
        row?.serviceDescription === '' || row?.serviceDescription === null
          ? row?.service_description
          : row?.serviceDescription,
      quantity: row?.quantity,
      service_cost: row?.service_cost,
      Total: row?.service_cost * row?.quantity,
    })
  )

  const mappedDataState2 = fieldServiceData.map(
    (
      row: {service_name: any; service_description: any; service_rate: any; service: any},
      index: number
    ) => {
      const indexs = viewService.length
      const lengths = fieldServiceData.length
      const quantityForRow =
        getQuantityServices[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]
      const serName =
        getServiceName[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]
      const serDes =
        getServiceDescription[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]
      const priceForRow =
        getPriceServices[
          index === 0 ? indexs : index === 1 ? indexs + 1 : index === 2 ? indexs + 2 : index
        ]

      const setServiceName =
        serName === undefined || serName === null || serName === '' ? row?.service_name : serName
      const setServiceDes =
        serDes === undefined || serDes === null || serDes === '' ? row?.service_description : serDes

      const setServicePrice =
        priceForRow === undefined || priceForRow === null || priceForRow === ''
          ? row?.service_rate
          : priceForRow
      return {
        index: index,
        service_name: setServiceName,
        service_description: setServiceDes,
        quantity: quantityForRow,
        service_cost: setServicePrice,
        Total: setServicePrice * (quantityForRow ? quantityForRow : 0),
      }
    }
  )

  const mergedData = [...mappedDataState1, ...mappedDataState2]

  const st = () => {
    const combinedData = mappedDataState1.concat(mappedDataState2)

    const total = combinedData.reduce((acc: any, obj: {Total: any}) => acc + obj.Total, 0)

    setServiceTotal(total)
  }
  useEffect(() => {
    st()
  }, [st])

  const addRow = () => {
    const newRow: Row = {
      id: viewService.length + 1,
    }

    setFieldServiceData([...fieldServiceData, newRow])
  }

  const serviceColumns: any[] = []
  if (customerView === false) {
    serviceColumns.push({
      selector: (row: any, index: number) => (
        <div style={{marginTop: '0', display: 'flex', flexDirection: 'column'}}>
          {!customerView ? (
            <div
              style={{
                whiteSpace: 'normal',
                fontSize: '12px',
                textAlign: 'justify',
                padding: '8px',
                borderRadius: '7px 7px 7px',
              }}
            >
              <TextField
                defaultValue={row?.service_name}
                style={{
                  marginTop: '3.5px',

                  height: '40px',
                  whiteSpace: 'normal',

                  width: '450px',
                  backgroundColor: 'rgb(245,248,250)',
                }}
                onChange={(e) => {
                  handleServiceNameChange(e, index, row)
                  refData.current.setFieldValue(
                    `item_counter.${index}.item_count`,
                    e?.target?.value
                  )
                }}
                multiline
                maxRows={5}
                fullWidth
              />
            </div>
          ) : (
            <> {row.service_name}</>
          )}
          <div
            style={{
              whiteSpace: 'normal',
              fontSize: '12px',
              textAlign: 'justify',
              padding: '8px',
              borderRadius: '7px 7px 7px',
            }}
          >
            <TextField
              defaultValue={row?.service_description}
              style={{
                width: '450px',
                backgroundColor: 'rgb(245,248,250)',
                marginTop: '3px',
              }}
              onChange={(e: any) => {
                handleServiceDescChange(e, index, row)
              }}
              multiline
              fullWidth
              maxRows={5}
            />
          </div>
        </div>
      ),

      name: 'Services',
      width: '500px',
    })
  } else {
    serviceColumns.push({
      selector: (
        row: {
          service_name:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
          service_description:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        },
        index: any
      ) => (
        <div>
          <div>{row.service_name}</div>
          <div
            style={{
              marginTop: '2px !important',
              whiteSpace: 'pre-line',
              fontSize: '12px',
              maxWidth: '1000px',

              color: 'gray',
            }}
          >
            {row.service_description}
          </div>
        </div>
      ),
      name: 'Services',
      width: '370px',
    })
  }
  if (customerView === true) {
    serviceColumns.push({
      selector: (row: any) => {},
      name: '',
    })
  }
  if (customerView === false) {
    serviceColumns.push({
      selector: (row: any, index: number) => (
        <div
          style={{
            display: 'flex',
            marginTop: '0',
          }}
        >
          <div
            style={{
              marginTop: '0 !important',
              whiteSpace: 'normal',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <div style={combinedQuantityStyle}>
              <input
                required
                type='number'
                className='form-control'
                defaultValue={row?.quantity}
                style={{
                  padding: 4,
                  borderBottom: '1px solid lightgrey',
                  width: '60px',
                }}
                onChange={(e) => {
                  handleServiceQuantityChange(e, index, row)
                  refData.current.setFieldValue(
                    `item_counter.${index}.item_count`,
                    e?.target?.value
                  )
                }}
              />
            </div>
          </div>
        </div>
      ),
      name: (
        <div style={combinedStyleQunatity}>
          Qty
          <div onClick={() => handleServiceToogle('quantity')}>
            {toggleService['quantity'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleService['quantity'] === true) {
    serviceColumns.push({
      selector: (row: any, index: number) => (
        <div
          style={{
            display: 'flex',
            marginTop: '0',
          }}
        >
          <div
            style={{
              marginTop: '0 !important',
              whiteSpace: 'normal',
              fontSize: '12px',
              color: 'black',
              marginLeft: '10px',
            }}
          >
            {row?.quantity}
          </div>
        </div>
      ),

      name: <div>Qty</div>,
    })
  } else {
    serviceColumns.push({
      selector: (row: any, index: number) => (
        <div style={{padding: '5px'}}>
          <div></div>
        </div>
      ),
      name: '',
    })
  }
  if (customerView === false) {
    serviceColumns.push({
      selector: (row: any, index: number) => {
        return (
          <div style={combinedPriceStyle}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginRight: '5px'}}>$</span>

              <input
                required
                type='number'
                className='form-control'
                value={parseFloat(row?.service_cost).toFixed(2)} // Display two decimal places
                style={{
                  padding: 4,
                  borderBottom: '1px solid lightgrey',
                  width: '60px',
                  whiteSpace: 'normal',
                }}
                onChange={(e) => {
                  handleServicePriceChange(e, index, row)
                  refData.current.setFieldValue(
                    `item_counter.${index}.item_count`,
                    e?.target?.value
                  )
                }}
              />
            </div>
          </div>
        )
      },
      name: (
        <div style={combinedStylePrice}>
          Price
          <div onClick={() => handleServiceToogle('price')}>
            {toggleService['price'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleService['price'] === true) {
    serviceColumns.push({
      selector: (row: any, index: number) => {
        return <div style={combinedPriceStyle}>${parseFloat(row?.service_cost).toFixed(2)}</div>
      },
      name: <div style={combinedStylePrice}>Price</div>,
    })
  } else {
    serviceColumns.push({
      selector: (row: any) => {},

      name: '',
      width: '230px',
    })
  }
  if (customerView === false) {
    serviceColumns.push({
      selector: (row: any, index: any) => (
        <div style={combinedPriceTotal}>
          ${row?.Total !== undefined ? parseFloat(row?.Total).toFixed(2) : '0.00'}
        </div>
      ),

      name: (
        <div style={combinedStyleTotal}>
          Total
          <div onClick={() => handleServiceToogle('total')}>
            {toggleService['total'] ? (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/show.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            ) : (
              <span
                role='img'
                aria-label='Hide'
                style={{marginRight: '5px', cursor: 'pointer', marginLeft: '4px'}}
              >
                <img
                  src='/media/icons/duotune/eye/hide.svg'
                  alt='Hide Icon'
                  style={{width: '20px', height: '20px'}}
                />
              </span>
            )}
          </div>
        </div>
      ),
    })
  } else if (toggleService['total'] === true) {
    serviceColumns.push({
      selector: (row: any, index: any) => (
        <div style={combinedPriceTotal}>
          $
          {row?.Total !== undefined
            ? parseFloat(row?.Total).toFixed(2) // Display two decimal places
            : '0.00'}
        </div>
      ),

      name: <div style={combinedStyleTotal}>Total</div>,
    })
  } else {
    serviceColumns.push({
      selector: (row: any) => {},
      name: '',
    })
  }

  if (customerView === false) {
    serviceColumns.push({
      selector: (row: any, id: any) => (
        <>
          {customerView === false && (
            <button
              className='btn-white btn'
              id='basic-button'
              aria-controls={opens ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={opens ? 'true' : undefined}
              onClick={(e: any) => {
                handleClick(row, e)
              }}
            >
              <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
            </button>
          )}
        </>
      ),
      name: customerView === true ? '' : 'Actions',
    })
  }

  const MaterialAddColumns: any = useMemo(
    () => [
      {
        selector: (row: any, index: number) => (
          <>
            <div style={{whiteSpace: 'normal', marginTop: '10px'}}>
              <input
                required
                type='text'
                className='form-control'
                defaultValue={''}
                style={{
                  padding: 4,
                  borderBottom: '1px solid lightgrey',
                  width: '150px',
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                }}
              />{' '}
            </div>
            <br />
            <textarea
              required
              className='form-control'
              defaultValue={''}
              style={{
                padding: 4,
                borderBottom: '1px solid lightgrey',
                width: '400px',
                whiteSpace: 'normal',
              }}
            />
            <br />
          </>
        ),
        name: 'Services',
        width: '500px',
      },

      {
        selector: (row: any, index: number) => (
          <div style={{whiteSpace: 'normal', marginTop: '10px'}}>
            <input
              required
              type='number'
              className='form-control'
              defaultValue={''}
              style={{padding: 4, borderBottom: '1px solid lightgrey', width: '50px'}}
            />
          </div>
        ),
        name: 'Quantity',
      },
      {
        selector: (row: any) => (
          <div style={{whiteSpace: 'normal', marginTop: '10px'}}>
            <input
              required
              type='number'
              className='form-control'
              defaultValue={''}
              style={{padding: 4, borderBottom: '1px solid lightgrey', width: '50px'}}
            />
          </div>
        ),
        name: 'Rate',
      },
      {
        selector: (row: any, index: any) => (
          <div style={{whiteSpace: 'normal', marginTop: '10px'}}>
            <input
              required
              type='number'
              className='form-control'
              defaultValue={''}
              style={{padding: 4, borderBottom: '1px solid lightgrey', width: '50px'}}
            />
          </div>
        ),
        name: 'Total',
      },
      {
        selector: (row: any, id: any, service: any, index: number) => (
          <>
            <button
              className='btn-white btn'
              id='basic-button'
              aria-controls={opens ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={opens ? 'true' : undefined}
              onClick={(e: any) => {
                handleClick(row, e)
              }}
            >
              <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
            </button>
          </>
        ),
        name: customerView === true ? '' : 'Actions',
      },
    ],
    [showCustomServiceFields]
  )

  const MaterialColumns: any = useMemo(
    () => [
      {
        selector: (row: any, index: number) => (
          <div style={{marginTop: '0', display: 'flex', flexDirection: 'column'}}>
            <div style={{whiteSpace: 'normal'}}>{row?.service_name}</div>
            <div
              style={{
                whiteSpace: 'normal',
                fontSize: '12px',
                textAlign: 'justify',
                padding: '8px',
                borderRadius: '7px 7px 7px',
              }}
            >
              {!customerView ? (
                <textarea
                  required
                  className='form-control'
                  defaultValue={row?.service_description}
                  style={{
                    padding: 4,
                    borderBottom: '1px solid lightgrey',
                    width: '400px',
                    whiteSpace: 'normal',
                  }}
                  onChange={(e: any) => {
                    handleServiceDescriptionChange(e, index, row)
                  }}
                />
              ) : (
                <> {row.service_description}</>
              )}
            </div>
          </div>
        ),
        name: 'Services',
        width: '500px',
      },

      {
        selector: (row: any, index: number) => (
          <div
            style={{
              alignItems: 'start',
              display: 'flex',
              marginTop: '0',
            }}
          >
            {!customerView ? (
              <div
                style={{
                  marginTop: '0 !important',
                  whiteSpace: 'normal',
                  fontSize: '12px',
                  color: 'gray',
                }}
              >
                <input
                  required
                  type='number'
                  className='form-control'
                  defaultValue={row?.quantity}
                  style={{padding: 4, borderBottom: '1px solid lightgrey', width: '50px'}}
                  onChange={(e) => {
                    handleServiceQuantityChange(e, index, row)
                    refData.current.setFieldValue(
                      `item_counter.${index}.item_count`,
                      e?.target?.value
                    )
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  whiteSpace: 'normal',
                  fontSize: '12px',
                  marginTop: '0',
                }}
              >
                {row.quantity}
              </div>
            )}
          </div>
        ),
        name: 'Quantity',
      },
      {
        selector: (row: any) => `$${Number(row?.service_cost)}`,
        name: 'Rate',
      },
      {
        selector: (row: any, index: any) => (
          <div>
            $
            {row?.Total
              ? row?.Total.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : 0}
          </div>
        ),
        name: 'Total',
      },
      {
        selector: (row: any, id: any, service: any) => (
          <>
            {customerView === false && (
              <button
                className='btn-white btn'
                id='basic-button'
                aria-controls={opens ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={opens ? 'true' : undefined}
                onClick={(e: any) => {
                  handleClick(row, e)
                }}
              >
                <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
              </button>
            )}
          </>
        ),
        name: customerView === true ? '' : 'Actions',
      },
    ],
    [opens, subtotals, customerView, getQuantityServices, mergedData]
  )

  const MARK_UP = service?.retail_price - service?.item_cost

  const handleChange1 = (index: any, newValue: any, selectedValue: any) => {
    const updatedSelectedValue: any = [...selectedValue]
    if (newValue) {
      const optionIndex = options.findIndex((option: any) => option === newValue)
      updatedSelectedValue[index] = optionIndex >= 0 ? options[optionIndex] : null
    } else {
      updatedSelectedValue[index] = null
    }
    setSelectedValue2(updatedSelectedValue)
  }

  function addObject(newObject: any) {
    const index = newObject.index
    const existingObjectIndex = newItems.findIndex((obj: any) => obj.index === index)
    if (existingObjectIndex !== -1) {
      const updatedArray = [...newItems]
      updatedArray[existingObjectIndex] = newObject
      setNewItems(updatedArray)
    } else {
      setNewItems((prevArray: any) => [...prevArray, newObject])
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  function updateQuantity() {
    setIsUpdateModalOpen(true)
    handleClose()
  }
  function updateServiceDescription() {
    setIsUpdateServiceModalOpen(true)
    handleClose()
  }

  function removeItem() {
    setDeleteModal(true)
    handleClose()
  }

  const deleteQuote = (id: any, index: number) => {
    if (id === undefined) {
      const updatedFieldArrayData = [...fieldServiceData]

      updatedFieldArrayData.splice(index, 1)
      setFieldServiceData(updatedFieldArrayData)
      darkMessage('Quote Service  deleted Successfully')
    } else {
      subquote_service_delete(id)
        .then((res) => {
          let result = res?.data?.error
          darkMessage('Quote Service  deleted Successfully')
          setDeleteModal(false)
          getService()
        })
        .catch((e: {response: {status: number; data: string}}) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            setDeleteModal(false)
          }
        })
      getService()
    }
  }
  const valueDEs = viewService.map((item: {service_description: any}) => item.service_description)

  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])

  const handleCreateService = useCallback(
    (cb_Data: CallBack_DataType) => {
      setLoading(true)
      const service_name = cb_Data?.input_data
      const service_description = cb_Data?.service_des
      const service_rate = cb_Data?.number_data
      create_new_service(service_name, service_description, service_rate)
        .then(({data}: any) => {
          const message = data?.message
          darkMessage(message)
          setLoading(false)
          getServiceList()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [getServiceList]
  )

  const handleSubmit = useCallback(
    (e: any) => {
      e?.preventDefault()
      const input_data = {
        input_data: inputFieldData,
        service_des: serviceDescriptionField,
        number_data: numberFieldData,
      }
      handleCreateService(input_data)
      setInputFieldData('')
      setServiceDescriptionField('')
      setNumberFieldData(0)
      set_create_inventory_modal(false)
    },
    [
      handleCreateService,
      set_create_inventory_modal,
      inputFieldData,
      serviceDescriptionField,
      numberFieldData,
    ]
  )

  return (
    <>
      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-alpine'>
          {/* <h4
            style={{marginLeft: '10px', color: 'rgb(64,64,64)'}}
            className='fw-bolder my-5 w-115px'
          >
            Services
          </h4> */}
          {/* <div className='god-father_1'>
            <div className='sub-god_1'> */}
          {/* <div>
            {' '}
            {customerView === true && (
              <div className='col-md-4'>{serviceDescription?.service_description}</div>
            )}
            {customerView === false && (
              <div className='col-md-4'>
                <TextField
                  style={{backgroundColor: 'rgb(224,235,240)'}}
                  value={serviceDescription?.serviceDescription}
                  onChange={(e) => setServiceDescription({serviceDescription: e.target.value})}
                  inputProps={{
                    style: {
                      height: '0.05px',
                    },
                  }}
                />
              </div>
            )}
          </div>
          <br /> */}
          <div style={{width: '100%'}} className='d-flex align-items-center position-relative my-1'>
            <span
              style={{marginLeft: '10px', fontSize: '18px', color: 'rgb(64,64,64)'}}
              className='fw-bolder my-5 w-115px'
            >
              Services
            </span>
            {customerView == false ? (
              <>
                <span style={{marginLeft: '13px', marginBottom: '16px'}}>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-4 position-absolute ms-5'
                  />
                </span>
                <input
                  style={{fontSize: '14px', padding: '7px 10px'}}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-200px ps-14 search-wrap'
                  placeholder='Search to Add Service'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <span>
                  {' '}
                  <button
                    style={{marginLeft: '15px'}}
                    type='button'
                    className='btn btn-secondary'
                    onClick={addRow}
                  >
                    Add Custom Service
                  </button>
                </span>
              </>
            ) : (
              ''
            )}
          </div>

          <Modal
            show={create_inventory_modal}
            onHide={() => set_create_inventory_modal(false)}
            onModalClose={() => set_create_inventory_modal(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            style={{backgroundColor: 'rgba(0, 0, 0, 0.83  )'}}
            centered
          >
            <Modal.Header>
              <h2>Add New Service</h2>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className='form-group'>
                  <div className='row my-2'>
                    <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                      <label htmlFor='enter_service_name' className='required'>
                        Service Name
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <input
                        type='text'
                        required
                        className='form-control'
                        id='enter_service_name'
                        onChange={(e) => setInputFieldData(e?.target?.value)}
                        value={inputFieldData}
                      />
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                      <label htmlFor='enter_service_description'>Service Description</label>
                    </div>
                    <div className='col-sm-9'>
                      <textarea
                        className='form-control'
                        rows={4}
                        id='enter_service_description'
                        onChange={(e) => setServiceDescriptionField(e?.target?.value)}
                        value={serviceDescriptionField}
                      />
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                      <label htmlFor='enter_service_rate' className='required'>
                        Service Rate
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <input
                        type='number'
                        required
                        className='form-control'
                        id='enter_service_rate'
                        onChange={(e) => setNumberFieldData(parseFloat(e?.target?.value))}
                        value={numberFieldData}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='dark'
                  type='button'
                  onClick={() => {
                    set_create_inventory_modal(false)
                    setInputFieldData('')
                  }}
                >
                  Discard
                </Button>
                <Button variant='primary' type='submit'>
                  {!loading && 'Submit'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
          {/* </div>
          </div> */}
          {searchItems && searchItems.length > 0 && searchDiv2 === true && (
            <div
              ref={popupRef}
              className='pop_up_table'
              style={{
                position: 'absolute',
                zIndex: '1',
                backgroundColor: 'white',
                marginTop: '13px',
                marginLeft: '55px',
                border: '2px solid ',
                maxHeight: '400px',
                overflow: 'scroll',
                width: '86%',
              }}
            >
              <SimpleDataTable
                MaterialColumns={MaterialSearchServicesCol}
                MaterialColumnsApiData={searchItems}
                stopRedirection={true}
                handleClick={(cb_data: any) => {
                  handleFieldArrayItems(cb_data)
                }}
              />
            </div>
          )}
          {searchDiv === true && (
            <div
              ref={popupRef}
              className='pop_up_table'
              style={{
                position: 'absolute',
                zIndex: '1',
                marginTop: '13px',
                marginLeft: '55px',
                border: '2px solid ',
                maxHeight: '400px',
                overflow: 'scroll',
                background: 'white',
              }}
            >
              <div style={{width: '539px', backgroundColor: 'rgb(255,255,255)', height: '50px'}}>
                <button
                  style={{
                    marginTop: '9px',
                    marginLeft: '150px',
                    backgroundColor: 'rgb(199,229,255)',
                    color: 'rgb(107,176,255)',
                    height: '35px',
                    width: '250px',
                    border: 'none',
                    fontSize: '15px',
                    borderRadius: '7px 7px 7px 7px',
                  }}
                  type='button'
                  onClick={() => set_create_inventory_modal(true)}
                >
                  + Add New Service
                </button>
              </div>
            </div>
          )}
          {searchDiv === false && ''}
        </div>
      </div>

      <div
        className='t-border-top vendors-desc-table'
        style={{
          whiteSpace: 'normal',
          fontSize: '12px',
          color: 'gray',
          alignItems: 'start !important',
          marginTop: '0 !important',
        }}
      >
        {mergedData.length === 0 ? (
          <p
            style={{
              marginLeft: '450px',
              color: 'rgb(39,50,63)',
              fontSize: '12.5px',
              marginTop: '20px',
              letterSpacing: '0.2px',
            }}
          >
            No Services assigned to Quote
          </p>
        ) : (
          <DataTable
            pointerOnHover
            highlightOnHover
            columns={serviceColumns}
            data={mergedData}
            striped
            customStyles={{
              rows: {
                style: {
                  marginTop: '5px',
                  alignItems: 'start',
                },
              },
            }}
          />
        )}

        <Menu
          id='basic-menu-1'
          anchorEl={anchorEl}
          open={opens}
          onClose={() => handleClose()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              removeItem()
            }}
          >
            Remove Item
          </MenuItem>
        </Menu>

        <DeleteModal
          modalShow={openDeleteModal}
          title={'Confirmation Message'}
          body={`Are you sure you want to delete this service #${serviceRowData?.service_name} ?`}
          handleClose={() => {
            setDeleteModal(false)
          }}
          confirmFunction={() => {
            deleteQuote(serviceRowData?.id, serviceRowData?.index)
            setDeleteModal(false)
          }}
        />

        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h1>Add </h1>
          </Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, {resetForm}) => {
              let modalData: any[] = []
              const Items = values?.assigned_item
              Items.map(({inventory_id, assigned_items}) => {
                return modalData.push({
                  project_id: id,
                  inventory_id: inventory_id,
                  assigned_items: assigned_items,
                })
              })

              setLoading(true)
              addAssignedItems(JSON.stringify(modalData))
                .then(({data}) => {
                  setLoading(false)
                  setSelectedValue2([])
                  resetForm()
                  setCreateModal(false)
                  darkMessage('New Items assigned successfully')
                })
                .catch((e) => {
                  if (e.response.status === 409) {
                    darkErrorMessage(e?.response?.data.error_list[0])
                    console.error(e?.response?.data)
                  }
                  console.error(e)
                  setLoading(false)
                })
            }}
          >
            {({values, errors, getFieldProps, setFieldValue, isValid}) => (
              <Form>
                <Modal.Body style={{height: '700px', overflow: 'scroll'}}>
                  <FieldArray name='assigned_item'>
                    {({insert, remove, push}) => (
                      <div>
                        {values.assigned_item.length > 0 &&
                          values.assigned_item.map((assigned_item: any, index) => (
                            <div
                              className='row p-6 '
                              style={{border: '1px solid darkgrey'}}
                              key={index}
                            >
                              <div className='row mb-6'>
                                {index > 0 && (
                                  <div className='col-lg-1 d-flex justify-content-center'>
                                    <button
                                      className='btn btn-secondary'
                                      onClick={() => remove(index)}
                                      type='button'
                                      style={{float: 'right'}}
                                    >
                                      X
                                    </button>
                                  </div>
                                )}
                                <div className='row mb-6 mt-5 align-items-end'>
                                  <div className='col-lg-4 fv-row'>
                                    <Autocomplete
                                      id={`assigned_item.${index}.inventory_id`}
                                      onOpen={() => {
                                        setAutoOpen(true)
                                      }}
                                      onClose={() => {
                                        setAutoOpen(false)
                                      }}
                                      clearIcon={
                                        <ClearIcon
                                          onClick={() => {
                                            setFieldValue(`assigned_item.${index}.service`, '')
                                          }}
                                        />
                                      }
                                      onChange={(event, newValue, idx) => {
                                        handleChange1(index, newValue, selectedValue2)

                                        setFieldValue(
                                          `assigned_item.${index}.service`,
                                          newValue.service_rate
                                        )

                                        addObject({index: index, newValue})

                                        setStoreNames((prev: any) => {
                                          return {...prev, [index]: newValue ? newValue.sku : ''}
                                        })

                                        setStoreQuantity((prev: any) => {
                                          return {...prev, [index]: ''}
                                        })
                                      }}
                                      getOptionLabel={(option: any) => option.service_name}
                                      options={options}
                                      loading={autoCompleteLoading}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Services'
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <React.Fragment>
                                                {autoCompleteLoading ? (
                                                  <CircularProgress color='inherit' size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>

                                  <div className='col-lg-4 fv-row'>
                                    <label>Price</label>
                                    <input
                                      value={assigned_item.service ? assigned_item.service : ''}
                                      type='number'
                                      name={`assigned_item.${index}.service`}
                                      placeholder='Price'
                                      onChange={(v) => handlePriceChange(v)}
                                      style={{
                                        borderColor: 'darkgrey',
                                        padding: '13px',
                                      }}
                                      className='form-control form-control-lg form-control-solid bg-white border-1 rounded'
                                    />
                                  </div>
                                  <div className='col-lg-4 fv-row'>
                                    <label>Quantity</label>
                                    <Field name='number'>
                                      {({field, form}: any) => (
                                        <div>
                                          <input
                                            disabled={
                                              newItems[index]
                                                ? newItems[index].newValue
                                                  ? false
                                                  : true
                                                : true
                                            }
                                            type='number'
                                            {...field}
                                            placeholder='Enter a number'
                                            style={{
                                              borderColor: 'darkgrey',
                                              padding: '13px',
                                            }}
                                            className='form-control form-control-lg form-control-solid bg-white border-1 rounded'
                                            onChange={(number: any, newValue: any) => {
                                              const newArray = [...newItems]
                                              newArray[index] = {
                                                ...newArray[index],
                                                quantity: number.target.value,
                                                index: index,
                                              }
                                              setNewItems(newArray)
                                              setStoreQuantity((prev: any) => {
                                                return {...prev, [index]: number.target.value}
                                              })
                                            }}
                                            value={storeQuantity[index]}
                                          />
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className='row mb-6 mt-5'>
                          <div className='col-md-12'>
                            <button
                              className='btn btn-primary me-lg-5'
                              style={{float: 'right'}}
                              type={'button'}
                              onClick={() => push({inventory_id: '', assigned_items: ''})}
                            >
                              Add Another Item
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Modal.Body>

                <Modal.Footer>
                  <div className='d-flex flex-row-reverse mx-2 '>
                    <span className='mx-2'>
                      <button
                        onClick={() => {
                          setService((prev: any) => {
                            return [...prev, ...newItems]
                          })
                          setDisable(true)

                          setIsModalOpen(false)
                        }}
                        className='btn btn-primary'
                        disabled={loading || !isValid}
                      >
                        {!loading && 'Assign Items'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </span>
                    <span className='mx-1'>
                      <button
                        onClick={() => {
                          setCreateModal(false)
                          setSelectedValue2([])
                        }}
                        className='btn btn-dark'
                      >
                        Discard
                      </button>
                    </span>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(2px)',
            border: '1px solid',
          }}
          className='bg-grey'
          show={isUpdateServiceModalOpen}
          onHide={() => setIsUpdateServiceModalOpen(false)}
          centered
        >
          <Modal.Body>
            <h1>
              <div>Update Service </div>
            </h1>
          </Modal.Body>
          <Formik
            initialValues={rowData}
            onSubmit={async (values, {resetForm}) => {
              const assigned_items_data = {
                id: values?.id,
                service_description: values?.service_description,
                serviceId: values?.service,
                quantity: values?.quantity,
              }
              await updateService(assigned_items_data)
                .then(({data}) => {
                  darkMessage(data?.message)
                  getService()
                  setIsUpdateModalOpen(false)
                  setUpdModalOpen(false)
                })
                .catch((e) => {
                  if (e?.response?.status === 409) {
                    const message = e?.response?.data?.error_list[0]
                    darkErrorMessage(message)
                  } else {
                    console.error(e)
                  }
                })
            }}
          >
            <Form>
              <Modal.Body style={{height: '400px'}}>
                <div>
                  <div className='row p-6 ' style={{border: '1px solid darkgrey'}}>
                    <div className='row mb-6'>
                      <div className='row mb-6 mt-5 align-items-end'>
                        <div className='col-lg-4 fv-row' style={{width: '611px'}}>
                          <Field
                            name='service_description'
                            render={({field}: any) => (
                              <TextField
                                {...field}
                                multiline
                                placeholder='Service Description'
                                rows={4}
                                style={{width: '410px'}}
                                id='Service Description'
                                label='Service Description'
                                variant='outlined'
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <div className='row mb-6 mt-5 align-items-end'>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            name='quantity'
                            render={({field}: any) => (
                              <TextField
                                {...field}
                                id='Quantity'
                                label='Quantity'
                                variant='outlined'
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'right'}}>
                  <button
                    type='button'
                    style={{marginRight: '5%'}}
                    className='btn btn-dark'
                    onClick={() => setIsUpdateServiceModalOpen(false)}
                  >
                    Discard
                  </button>
                  <button type='submit' style={{marginRight: '10%'}} className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </Modal.Body>
            </Form>
          </Formik>
        </Modal>
      </div>
    </>
  )
}
export {SubquotesServicesView}
