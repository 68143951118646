/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {LinearProgress} from '@mui/material'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {If} from 'react-if'
import {useParams} from 'react-router-dom'
import {viewQuote} from '../../../../app/modules/auth/core/_requests'
import blank from '../../../layout/components/header/blank.png'
import {ProfileContext} from '../../../layout/MasterLayout'
type Props = {
  className: string
  schema: any[]
}

const ListsWidget2: React.FC<Props> = ({className, schema}) => {
  const {id} = useParams<any>()
  const [listLoading, setListLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [result, setResult] = useState<any>([])
  const [title, setTitle] = useState<any>('')
  const profileContext: any = useContext(ProfileContext)
  const getList = useCallback(async () => {
    setListLoading(true)
    await viewQuote(id)
      .then((res) => {
        let data = res.data.activities_grouping as any[]
        setData(data || [])
        setResult(res.data.clocked_in?.data || [])
        setTitle(res?.data?.customer_details?.date_created)
        setListLoading(false)
      })
      .catch((e) => {
        setListLoading(false)
      })
  }, [id])

  useEffect(() => {
    getList()
    profileContext?.state?.setStatus(false)
  }, [profileContext?.state?.clockIn, profileContext?.state?.status, getList])
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3
          className='d-flex align-items-lg-center fw-bold text-dark'
          style={{marginTop: '-35px', justifyContent: 'center', display: 'flex'}}
        >
          Currently Clocked In ({result.length})
        </h3>
      </div>
      <div className='card-body pt-2 p-2' style={{marginTop: '-38px'}}>
        {!listLoading ? (
          <>
            <If condition={result?.length === 0}>{'No One is currently Clocked In'}</If>
            {result.length > 0 ? (
              <>
                {result.map((item: any) => {
                  return (
                    <>
                      <div className='d-flex align-items-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            alt={item?.user?.first_name}
                            src={item?.user?.profile ? item?.user?.profile : blank}
                            onError={(evt: any) => {
                              evt.target.src = blank
                            }}
                            // onError={() => {
                            //   profileContext?.state?.setProfilePic(
                            //     produce((draft: any) => {
                            //       draft.pic = blank
                            //     })
                            //   )
                            // }}
                          />
                        </div>
                        <div className='flex-grow-1'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item?.user?.first_name + ' ' + item?.user?.last_name}
                          </a>
                          <span className='text-muted d-block fw-semibold'>
                            {item?.user?.email}
                          </span>
                        </div>
                      </div>
                    </>
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <LinearProgress />
        )}
      </div>
    </div>
  )
}

export {ListsWidget2}
