/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import {
  addTimesheetData,
  darkErrorMessage,
  darkMessage,
  deleteTimesheetData,
  fetchAllWorkOrders,
  updateTimesheetData,
  userQuoteTimesheet,
} from '../../modules/auth/core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import {Modal} from 'react-bootstrap'
import {
  MomentTimeFormat,
  TEMP_TimeSheetConstant,
  TimeSheetConstant,
  TimeSheetTextConstant,
} from '../../context/TimeSheetConstant'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import DateFnsUtils from '@date-io/date-fns'
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker'
import {Autocomplete, CircularProgress, LinearProgress, TextField} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import React from 'react'

export const SubModal = (props: any) => {
  const [options, setOptions] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const [asDate, setAsDate] = useState<any>(
    moment(props?.customData?.start_time).toDate() || new Date()
  )
  const [timeValue, setTimeValue] = useState<any>(parseInt(TimeSheetConstant.JOB))
  const [quoteId, setQuoteId] = useState<any>(null)
  const [aeDate, setAeDate] = useState<any>(
    moment(props?.customData?.end_time).toDate() || new Date()
  )
  const [_asDate, setsDate] = useState<any>(
    props?.customData?.start_time
      ? moment(props?.customData?.start_time).toDate()
      : moment().toDate()
  )
  const [_aeDate, seteDate] = useState<any>(
    props?.customData?.end_time ? moment(props?.customData?.end_time).toDate() : moment().toDate()
  )

  const discardButton = () => {
    props?.onModalClose()
  }
  const getValue = useCallback((e: any) => {
    setLoading(true)
    const getType = parseInt(e?.target?.value)
    setTimeValue(getType)
    setLoading(false)
  }, [])

  const confirmButton = (idx: any) => {
    deleteTimesheetData(idx)
      .then((res: any) => {
        props?.getList()
        props?.onModalClose()
        darkMessage('Data Deleted Successfully')
      })
      .catch((e: any) => {
        props?.getList()
        darkErrorMessage(e?.error_list[0])
        console.error(e)
      })
  }

  const addTimestamp = useCallback(() => {
    let getQuoteId = null
    if (quoteId) {
      const regexForId = quoteId.match(/^[^-]*-(.*)/)
      getQuoteId = regexForId[1]
    }
    const data = {
      quote: getQuoteId,
      start_time: moment(asDate).format(MomentTimeFormat),
      end_time: moment(aeDate).format(MomentTimeFormat),
      timesheet_type: timeValue,
    }
    addTimesheetData(data, props?.getEmpId)
      .then((res: any) => {
        props?.getList()
        props?.onModalClose()
        setTimeValue(parseInt(TimeSheetConstant.JOB))
        setQuoteId(null)
        darkMessage('TimeStamp Added Successfully')
      })
      .catch((e: any) => {
        props?.getList()
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
      })
  }, [asDate, aeDate, timeValue, quoteId])

  const haEdit = () => {
    const customArray = [{id: props?.customData?.id, start_time: _asDate, end_time: _aeDate}]
    updateTimesheetData(JSON.stringify(customArray))
      .then((res: any) => {
        // props?.fetchTimeSheet()
        props?.getList()
        props?.onModalClose()
        darkMessage('Data Updated Successfully')
      })
      .catch((e: any) => {
        // props?.fetchTimeSheet()
        console.error(e)
        props?.getList()
        darkErrorMessage('Error')
      })
  }

  const getWorkorders = useCallback(() => {
    const INITIALS =
      timeValue === parseInt(TimeSheetConstant.QUOTE)
        ? TimeSheetConstant.QUOTE
        : timeValue === parseInt(TimeSheetConstant.WORKORDER)
        ? TimeSheetConstant.JOB
        : timeValue === parseInt(TimeSheetConstant.FIELDSERVICE)
        ? TimeSheetConstant.WORKORDER
        : null
    setLoading(true)
    fetchAllWorkOrders(INITIALS)
      .then((res) => {
        const ALL_WORKORDERS = res?.data?.final_title
        setOptions(ALL_WORKORDERS || [])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        console.error(e)
      })
  }, [timeValue])

  useEffect(() => {
    getWorkorders()
  }, [getWorkorders])

  return (
    <div className='card'>
      {props?.type === 'delete' && (
        <>
          <div className='card-body'>
            <h1 className='fw-bolder m-5'>Confirm Message</h1>
            <div className='row g-6 g-xl-9'>
              <div>
                <h3 className='lh-base m-5'>
                  Could you please confirm that you want to delete this ?
                  <br />
                  Once, it has been deleted, it cannot be recovered
                </h3>
              </div>
              <div className='text-end' style={{padding: '0px 20px'}}>
                <button onClick={discardButton} className='btn btn-dark float-right m-5'>
                  {`Discard `}
                </button>
                <button
                  onClick={() => {
                    confirmButton(props?.customData?.id)
                  }}
                  className='btn btn-primary float-right m-5'
                >
                  {`Confirm `}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {props.type === 'add' && (
        <>
          <div className='card-body'>
            <h1 className='fw-bolder m-5'>Add New Timestamp Here</h1>
            <div className='row g-6 g-xl-9'>
              <div className='col'>
                <label className='col-form-label required fw-bold fs-6'>Start Time</label>
                <DatePicker
                  wrapperClassName='datePicker'
                  className='form-control form-control-lg form-control-solid mw-100 z-index-master'
                  selected={asDate}
                  onChange={(date: any) => setAsDate(date)}
                  showTimeSelect
                  dateFormat='dd/MM/yyyy hh:mm a'
                  timeFormat='hh:mm a'
                />
              </div>
              <div className='col'>
                <label className='col-form-label required fw-bold fs-6'>End Time</label>
                <DatePicker
                  wrapperClassName='datePicker'
                  className='form-control form-control-lg form-control-solid mw-100 z-index-master'
                  selected={aeDate}
                  onChange={(date: any) => setAeDate(date)}
                  showTimeSelect
                  dateFormat='dd/MM/yyyy hh:mm a'
                  timeFormat='hh:mm a'
                />
              </div>
              <div className='col'>
                <label className='col-form-label required fw-bold fs-6'>Type</label>
                <select
                  name=''
                  id=''
                  onChange={getValue}
                  className='form-control form-control-lg form-control-solid mw-100 z-index-master'
                >
                  {
                    <>
                      <option selected hidden disabled>
                        Please Select Type (Default:Shop Time)
                      </option>
                      <option value={TimeSheetConstant.JOB}>{'Shop Time'}</option>
                      <option value={TimeSheetConstant.QUOTE}>
                        {TimeSheetTextConstant.QUOTE.charAt(0).toUpperCase() +
                          TimeSheetTextConstant.QUOTE.slice(1)}
                      </option>
                      <option value={TimeSheetConstant.WORKORDER}>
                        {TimeSheetTextConstant.WORKORDER.charAt(0).toUpperCase() +
                          TimeSheetTextConstant.WORKORDER.slice(1)}
                      </option>
                      <option value={TimeSheetConstant.FIELDSERVICE}>
                        {TimeSheetTextConstant.FIELDSERVICE.charAt(0).toUpperCase() +
                          TimeSheetTextConstant.FIELDSERVICE.slice(1)}
                      </option>
                    </>
                  }
                </select>
              </div>
              {timeValue !== parseInt(TimeSheetConstant.JOB) && (
                <>
                  <div className='col'>
                    <label className='col-form-label fw-bold fs-6'>Enter Id</label>
                    <Autocomplete
                      disablePortal
                      className=' form-control-lg p-0'
                      style={{padding: '0 !important'}}
                      id='asynchronous-demo'
                      options={options}
                      loading={loading}
                      onChange={(e: any) => {
                        setQuoteId(e?.target?.innerText)
                      }}
                      fullWidth={true}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label='Search For Id'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </>
              )}

              <div className='text-end' style={{padding: '0px 20px'}}>
                <button onClick={discardButton} className='btn btn-dark float-right m-5'>
                  {`Discard `}
                </button>
                <button
                  onClick={() => {
                    addTimestamp()
                  }}
                  className='btn btn-primary float-right m-5'
                >
                  {`Add Data`}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {props?.type === 'edit' && (
        <>
          <div className='card-body'>
            <h1 className='fw-bolder m-5'>Update Timestamp </h1>
            <div className='row g-6 g-xl-9'>
              <div className='col'>
                <label className='col-form-label required fw-bold fs-6'>Start Time</label>
                <DatePicker
                  wrapperClassName='datePicker'
                  className='form-control form-control-lg form-control-solid mw-100 z-index-master'
                  selected={_asDate}
                  onChange={(date: any) => setsDate(date)}
                  showTimeSelect
                  dateFormat='dd/MM/yyyy hh:mm a'
                  timeFormat='hh:mm a'
                />
              </div>
              <div className='col'>
                <label className='col-form-label required fw-bold fs-6'>End Time</label>
                <DatePicker
                  wrapperClassName='datePicker'
                  className='form-control form-control-lg form-control-solid mw-100 z-index-master'
                  selected={_aeDate}
                  onChange={(date: any) => seteDate(date)}
                  showTimeSelect
                  dateFormat='dd/MM/yyyy hh:mm a'
                  timeFormat='hh:mm a'
                />
              </div>

              <div className='text-end' style={{padding: '0px 20px'}}>
                <button onClick={discardButton} className='btn btn-dark float-right m-5'>
                  {`Discard `}
                </button>
                <button onClick={haEdit} className='btn btn-primary float-right m-5'>
                  {`Update Timesheet`}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export function WeeklyTimeCardModal(props: any) {
  const [loading, setLoading] = useState(false)
  const [modal, setModalShow] = useState<any>({})
  const [startDate, setStartDate] = useState<any>({})
  const [endDate, setEndDate] = useState<any>({})
  const [customArray, setCustomArray] = useState<any>([])
  const [total, setTotal] = useState<any>([])
  const [editTimestamp, setEditTimestamp] = useState(true)
  const getEmpId = props?.employee?.emp?.user

  const timeConverter = (e: number) => {
    if (e) {
      const time = e / 3600
      return time.toFixed(2)
    }
  }

  useEffect(() => {
    customArray.map((item: any) => {
      return item?.id === startDate?.data ? (
        (item.start_time = moment(startDate?.date).format(MomentTimeFormat))
      ) : (
        <></>
      )
    })
  }, [startDate])

  useEffect(() => {
    customArray.map((item: any) => {
      return item?.id === endDate?.data ? (
        (item.end_time = moment(endDate?.date).format(MomentTimeFormat))
      ) : (
        <></>
      )
    })
  }, [endDate])

  const deleteConfirmation = (idx: any) => {
    setModalShow({show: true, custom: idx, type: 'delete'})
  }
  const editTimeStamp = (idx: any) => {
    setModalShow({show: true, custom: idx, type: 'edit'})
  }

  const addTimestamp = (cData: any) => {
    setModalShow({show: true, custom: cData, type: 'add'})
  }

  const getDate = (date: any) => {
    let formattedDate = moment(date).toDate()
    return formattedDate
  }

  const columns: any = [
    {
      name: <span className='c-row-heads'>Job</span>,
      className: 'custom_s',
      style: {justifyContent: 'center'},
      selector: (row: any) => (
        <>{row?.quote ? row?.final_title + row?.quote?.label : row?.final_title}</>
      ),
    },
    {
      name: <span className='c-row-heads'>In</span>,
      style: {justifyContent: 'center'},
      selector: (row: any) => (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <DesktopTimePicker
            className={`form-control form-control-lg form-control-solid c-clock-in  ${
              !editTimestamp ? 'bg-white' : ''
            }`}
            value={getDate(row?.start_time)}
            onChange={(date: any) => setStartDate({date: date, data: row?.id})}
            renderInput={(params: any) => <TextField {...params} label='' />}
          />
        </LocalizationProvider>
      ),
    },
    {
      name: <span className='c-row-heads'>Out</span>,
      style: {justifyContent: 'center'},
      selector: (row: any) => (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <DesktopTimePicker
            className={`form-control form-control-lg form-control-solid c-clock-out ${
              !editTimestamp ? 'bg-white' : ''
            }`}
            value={getDate(row?.end_time)}
            onChange={(date: any) => setEndDate({date: date, data: row?.id})}
            renderInput={(params: any) => <TextField {...params} label='' />}
          />
        </LocalizationProvider>
      ),
    },
    {
      name: <span className='c-row-heads'>Hours</span>,
      style: {justifyContent: 'center'},
      selector: (row: any) => timeConverter(row?.timeDiff) || '',
    },
    // {
    //   name: 'Edit',
    //   selector: (row: any) => (
    //     <button
    //       style={{width: '100px'}}
    //       className='btn btn-secondary '
    //       onClick={() => {
    //         editTimeStamp(row)
    //       }}
    //       disabled={loading}
    //     >
    //       {!loading && `Edit`}
    //       {loading && (
    //         <span className='indicator-progress' style={{display: 'block'}}>
    //           Please wait...{' '}
    //           <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //         </span>
    //       )}
    //     </button>
    //   ),
    // },
    {
      name: '',
      width: '80px',
      selector: (row: any) => (
        <>
          <button
            type='submit'
            className='btn btn-white cursor-pointer'
            onClick={() => {
              deleteConfirmation(row)
            }}
          >
            <KTSVG path='/media/custom/trash.svg' className='svg-icon-4 me-1' />
          </button>
        </>
      ),
    },
  ]

  const getList = useCallback(() => {
    const start_Date = props?.data?.start_time.split('T')
    setLoading(true)
    userQuoteTimesheet(start_Date[0], getEmpId)
      .then((res) => {
        const timesheetData = res?.data?.data
        const totalTime = res?.data?.sum
        setTotal(totalTime)
        setLoading(false)

        setCustomArray(timesheetData || [])
      })
      .catch((e: any) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const onModalClose = () => {
    setModalShow(false)
    props?.onModalClose()
    props?.fetchTimeSheet()
  }
  const haEdit = () => {
    updateTimesheetData(JSON.stringify(customArray))
      .then((res: any) => {
        props?.fetchTimeSheet()
        props?.onModalClose()
        darkMessage('Data Updated Successfully')
      })
      .catch((e: any) => {
        console.error(e)
        props?.fetchTimeSheet()
        darkErrorMessage('Error')
      })
  }
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal?.show}
        className={'fade'}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <SubModal
          onModalClose={onModalClose}
          customData={modal?.custom}
          type={modal?.type}
          getList={getList}
          show={modal?.show}
          getEmpId={getEmpId}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <div className='card'>
        <div className='card-body' style={{overflow: 'scroll'}}>
          <div className='row m-3'>
            <div className='col'>
              <h2 className='fw-bolder m-2'>
                {props?.data?.start_time
                  ? moment(props?.data?.start_time).format('dddd, MMMM Do')
                  : moment().format('dddd, MMMM Do')}
              </h2>
            </div>
            <div className='col text-end'>
              <button
                type='submit'
                className='btn btn-light btn-active-primary my-1 me-2'
                onClick={() => {
                  addTimestamp(props?.data)
                }}
                disabled={loading}
              >
                {!loading && ` + Add Timestamp `}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='row g-6 g-xl-9 dummy-modal custom-timestamp'>
            {customArray ? (
              <>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <>
                    <DataTable
                      columns={columns}
                      data={customArray}
                      progressPending={loading}
                      pointerOnHover
                      highlightOnHover
                    />
                    <div className='row mt-3'>
                      <div className='col-md-10'>
                        <div className='text-end me-3'>
                          Total Hours: {timeConverter(total) || ''}
                        </div>
                      </div>
                      <div className='col-md-2'></div>
                    </div>
                    <div className='text-end'>
                      <button
                        className='btn btn-dark float-right mx-5'
                        onClick={() => {
                          props?.onModalClose()
                        }}
                      >{`Discard `}</button>
                      <button
                        className='btn btn-primary float-right'
                        onClick={haEdit}
                      >{`Save Changes`}</button>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
