import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  Inventory_Items,
  Inventory_Items_Intial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  currnetCompanyName,
  darkMessage,
  getInventoryData,
  inventoryData,
  inventoryItemData,
  updateInventoryItem,
} from '../../modules/auth/core/_requests'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {InventoryModel} from '../../modules/model/Inventory'
import {Autocomplete, Checkbox, CircularProgress, TextField} from '@mui/material'
import {InventoryManagement} from './InventoryManagement'
import {Modal} from 'react-bootstrap'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {onKeyDown} from './GlobalComponents'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const profileDetailsSchema = Yup.object().shape({
  sku: Yup.mixed().required('Manufacturer part number is required'),
  name: Yup.mixed().required('Item Name is required'),
  item_cost: Yup.number().min(0),
  retail_price: Yup.number().min(0),
  qty_in_store: Yup.number().min(0),
  reorder_level: Yup.number().min(0),
  order_link: Yup.string().url('Please enter a valid URL starting with http:// or https://'),
})

const EditInventory: React.FC = () => {
  const [options, setOptions] = useState<any>([])
  const inputFile = useRef<any>()
  const [dropdown, setDropdown] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState<any>([])
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [modal, setModalShow] = React.useState<any>(false)
  const {id} = useParams()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [vendor_list, setVendor_list] = useState<any>([])
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const location: any = useLocation()
  const headerContext = useContext<any>(HeaderContext)
  const [data, setData] = useState<any>({})
  const [manufacturer, setManufacturer] = useState<any>('')
  const [vendor, setVendor] = useState<any>('')
  const [category, setCategory] = useState<any>('')
  const [img, setImg] = React.useState<any>('/media/avatars/blank.png')

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Update Inventory Details',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store/${id}/view`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const formik = useFormik<Inventory_Items>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      let stock = ''
      if ((values.maintain_stock = 'true')) {
        stock = 'True'
      } else {
        stock = 'False'
      }
      setLoading(true)
      let inventoryItem: InventoryModel = {
        name: values.name,
        maintain_stock: stock,
        reorder_level: values.reorder_level || 0,
        item_cost: values.item_cost,
        retail_price: parseFloat(formik.values.item_cost) + parseFloat(formik.values.mark_up) || 0,
        available_stock: values.available_stock || 0,
        qty_in_store: values.qty_in_store || 0,
        order_link: values.order_link,
        category_id: values.category_id,
        item_status: values.item_status,
        brand_id: values.brand_id,
        vendor_id: JSON.stringify(values.vendor_id),
        sku: values.sku,
        in_transit_stock: values.in_transit_stock,
        reserved_stock: values.reserved_stock,
        item_description: values.item_description,
        uploadImage: values.uploadedImage,
        inventory_location: values.inventory_location,
        image_field: undefined,
      }
      updateInventoryItem(inventoryItem, id)
        .then(({data}) => {
          setLoading(false)
          setSuccessBody(data as any)
          formik.resetForm()
          darkMessage('Item updated successfully')
          navigate(`/${prefixCompany}/inventory/store/${id}/view`)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setErrorBody(e.response.data)
          }
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    let subscribe = true
    async function getList() {
      await inventoryItemData(id)
        .then((res) => {
          if (subscribe) {
            let item = res?.data?.items[0]
            setData(item || [])
            formik.setFieldValue('name', item.name)
            formik.setFieldValue('reorder_level', item.reorder_level)
            formik.setFieldValue('item_cost', item.item_cost)
            formik.setFieldValue('retail_price', item.retail_price)
            formik.setFieldValue(
              'mark_up',
              parseFloat(item.retail_price) - parseFloat(item.item_cost)
            )
            formik.setFieldValue('available_stock', item.available_stock)
            formik.setFieldValue('qty_in_store', item.quantity_in_store)
            formik.setFieldValue('order_link', item.order_link)
            formik.setFieldValue('category_id', item.category.id)
            formik.setFieldValue('category_name', item.category.name)
            formik.setFieldValue('subcategory_id', item.subcategory.id)
            formik.setFieldValue('item_description', item.item_description)
            formik.setFieldValue('subcategory_name', item.subcategory.name)
            formik.setFieldValue('item_status', item.item_status)
            formik.setFieldValue('brand_id', item.brand.id)
            formik.setFieldValue('brand_name', item.brand.name)
            formik.setFieldValue('vendor_id', item.vendor)
            formik.setFieldValue('vendor_name', item.vendor.name)
            formik.setFieldValue('sku', item.sku)
            formik.setFieldValue('in_transit_stock', item.in_transit_stock)
            formik.setFieldValue('reserved_stock', item.reserved_stock)
            formik.setFieldValue('inventory_location', item.inventory_location)
            setImg(item.image_field)
            const newVendorList = item.vendor.map((each_vendor: any) => {
              return {
                id: each_vendor?.id,
                label: each_vendor?.name,
              }
            })
            setVendor_list(newVendorList)
          }
        })
        .catch(() => {})
    }
    getList()
  }, [])

  async function getList() {
    await getInventoryData()
      .then((res) => {
        let brands = res?.data?.brands
        let vendors = res?.data?.vendors
        let categories = res?.data?.category
        let brand: any = []
        let vendor: any = []
        let category: any = []
        brands.forEach((e: any) => {
          brand.push({label: e.name, id: e.id})
        })
        vendors.forEach((e: any) => {
          vendor.push({label: e.name, id: e.id})
        })
        categories.forEach((e: any) => {
          category.push({label: e.name, id: e.id})
        })
        setDropdown(vendor)
        setOptions(brand)
        setBody(category)
      })
      .catch((e) => {
        if (e.response.status == 409) {
          setErrorBody(e.response.data)
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    getList()
  }, [])

  const onModalClose = useCallback(() => {
    setModalShow({show: false, label: ''})
    getList()
  }, [])

  useEffect(() => {
    let manufacturer_selected = `${data?.brand?.name} `
    let vendor_selected = `${data?.vendor?.name} `
    let category_selected = `${data?.category?.name} `
    setManufacturer(manufacturer_selected)
    setVendor(vendor_selected)
    setCategory(category_selected)
  }, [data])

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const changeImg = (e: any) => {
    setImg(URL.createObjectURL(e.target.files[0]))
    try {
      let filesToUpload: any = []
      if (e?.target?.files != null) {
        let lengthOfFiles = Object.keys(e.target.files).length
        Object.values(e.target.files).map((value) => {
          return filesToUpload.push(value)
        })
        formik.setFieldValue('uploadedImage', e.target.files)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        label={modal?.label}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryManagement
          onModalClose={onModalClose}
          label={modal?.label}
          show={modal?.show}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <form
        // onKeyDown={onKeyDown}
        onSubmit={formik.handleSubmit}
        noValidate
        encType='multipart/formData'
        className='form'
      >
        <div className='card mb-5 mb-xl-10 col-sm-12'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <div className='d-flex p-lg-5 align-items-lg-center m-0'>
              <h3 className='fw-bolder m-0'>Update Item</h3>
            </div>
          </div>
          {errorBody && <ErrorAlert error_response={errorBody} />}
          {successBody && <SuccessAlert success_response={successBody} />}
          <div id='kt_account_profile_details' className='collapse show'>
            <div className=' row card-body border-top p-9 col-sm-12'>
              <div className='col-sm-4'>
                <table className='col-sm-12'>
                  <tbody>
                    <tr className=''>
                      <td colSpan={2} className='pb-5 px-4 w-75 text-center'>
                        <input
                          type='file'
                          ref={inputFile}
                          style={{display: 'none'}}
                          onChange={changeImg}
                          accept='image/*'
                        />
                        <img
                          src={img ?? blank}
                          onClick={onButtonClick}
                          alt=''
                          style={{
                            width: '170px',
                            height: '170px',
                            margin: 'auto',
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='py-2  px-4 font-size-15'>Manufacturer</td>
                      <td style={{minWidth: '210px'}} className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'manufacturer',
                            })
                            e.preventDefault()
                          }}
                          className='btn btn-sm btn-primary btn-xs cursor-pointer'
                          style={{cursor: 'pointer'}}
                        >
                          + Create New Manufacturer
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} colSpan={2} className='pb-4 px-4 font-size-15'>
                        <Autocomplete
                          disablePortal
                          className=' form-control-lg '
                          id='combo-box-demo'
                          style={{padding: '7px', width: '100%'}}
                          options={options}
                          value={manufacturer}
                          onChange={(e, value: any) => {
                            if (value?.id) {
                              setManufacturer(value?.label)
                              formik.setFieldValue('brand_id', value?.id)
                            }
                          }}
                          fullWidth={true}
                          renderInput={(params: any) => (
                            <TextField {...params} label={'Please select Manufacturer'} />
                          )}
                        />
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='pb-4  px-4 font-size-15'>Vendor</td>
                      <td className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'vendor',
                            })
                            e.preventDefault()
                          }}
                          style={{cursor: 'pointer'}}
                          className='btn btn-sm btn-primary btn-xs cursor-pointer'
                        >
                          + Create New Vendor
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} colSpan={2} className='pb-4 px-4 font-size-15'>
                        <Autocomplete
                          multiple
                          id='checkboxes-tags-demo'
                          disableCloseOnSelect
                          value={vendor_list}
                          options={dropdown || []}
                          getOptionLabel={(option: any) => option?.label}
                          renderOption={(props, option: any, {selected}) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          onChange={(e, value: any) => {
                            formik.setFieldValue('vendor_id', value)
                            setVendor_list(value)
                          }}
                          fullWidth={true}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Please Select Vendor'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        {formik.touched.vendor_id && formik.errors.vendor_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.vendor_id}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='pb-4  px-4 font-size-15'>Inventory Category</td>
                      <td className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'category',
                            })
                            e.preventDefault()
                          }}
                          className='btn btn-sm btn-primary btn-xs cursor-pointer'
                          style={{cursor: 'pointer'}}
                        >
                          + Create New Category
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '50%'}} colSpan={2} className='pb-4 px-4 font-size-15'>
                        <Autocomplete
                          disablePortal
                          className=' form-control-lg '
                          id='combo-box-demo'
                          style={{padding: '7px', width: '100%'}}
                          options={body}
                          value={category}
                          onChange={(e, value: any) => {
                            if (value?.id) {
                              setCategory(value?.label)
                              formik.setFieldValue('category_id', value?.id)
                            }
                          }}
                          fullWidth={true}
                          renderInput={(params: any) => (
                            <TextField {...params} label={'Please select Category'} />
                          )}
                        />
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4  px-4 font-size-15 '>
                        Item Cost
                      </td>
                      <td style={{width: '100%'}} className='pb-4  px-4 font-size-15 '>
                        Markup
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text'>$</span>
                          </div>

                          <input
                            type='text'
                            className='form-control'
                            style={{padding: '7px', width: '50%'}}
                            {...formik.getFieldProps('item_cost')}
                          />
                        </div>
                      </td>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text'>$</span>
                          </div>
                          <input
                            type='text'
                            className='form-control'
                            style={{padding: '7px', width: '50%'}}
                            {...formik.getFieldProps('mark_up')}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        {formik.touched.item_cost && formik.errors.item_cost && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.item_cost}</div>
                          </div>
                        )}
                      </td>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-sm-8'>
                <table className='col-sm-12'>
                  <tbody>
                    <tr className=''>
                      <td
                        style={{width: '100%'}}
                        colSpan={4}
                        className='pb-4  px-4 font-size-15 required'
                      >
                        Manufacturer Part #
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} colSpan={4} className='pb-4 px-4 font-size-15'>
                        <input
                          type='text'
                          id=''
                          // readOnly
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('sku')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        {formik.touched.sku && formik.errors.sku && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.sku}</div>
                          </div>
                        )}
                      </td>
                    </tr>

                    <tr className=''>
                      <td
                        style={{width: '100%'}}
                        className='pb-4  px-4 font-size-15 required'
                        colSpan={4}
                      >
                        Item Name
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('name')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </td>
                    </tr>

                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4  px-4 font-size-15 ' colSpan={4}>
                        Item description
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        <textarea
                          id=''
                          rows={4}
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('item_description')}
                        />
                      </td>
                    </tr>

                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4  px-4 font-size-15 ' colSpan={4}>
                        Order Link (Must include http:// or https://)
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('order_link')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        {formik.touched.order_link && formik.errors.order_link && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.order_link}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4  px-4 font-size-15 ' colSpan={4}>
                        Inventory Location
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15' colSpan={4}>
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('inventory_location')}
                        />
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='pb-4 px-4 font-size-15'>Retail Price</td>
                      <td className='pb-4 px-4 font-size-15'>QTY in Store</td>
                      <td className='pb-4 px-4 font-size-15'>Maintain Stock</td>
                      <td className='pb-4 px-4 font-size-15'>Reorder Level</td>
                    </tr>
                    <tr className=''>
                      <td className='pb-4 px-4 font-size-15'>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text'>$</span>
                          </div>
                          <input
                            type='text'
                            className='form-control'
                            style={{padding: '7px', width: '50%'}}
                            value={
                              parseFloat(formik.values.item_cost) +
                                parseFloat(formik.values.mark_up) || 0
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className='pb-4 px-4 font-size-15'>
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('qty_in_store')}
                        />
                      </td>
                      <td className='pb-4 px-4 font-size-15'>
                        <select
                          id=''
                          style={{padding: '7px', width: '100px'}}
                          {...formik.getFieldProps('maintain_stock')}
                        >
                          {data?.maintain_stock === true ? (
                            <>
                              <option selected hidden disabled value={'yes'}>
                                Y
                              </option>
                            </>
                          ) : (
                            <>
                              <option selected hidden disabled value={'no'}>
                                N
                              </option>
                            </>
                          )}
                          <option value={'yes'}>Y</option>
                          <option value={'no'}>N</option>
                        </select>
                      </td>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.values.maintain_stock === 'yes' ? (
                          <input
                            type='text'
                            id=''
                            style={{padding: '7px', width: '100%'}}
                            {...formik.getFieldProps('reorder_level')}
                          />
                        ) : (
                          <input
                            type='text'
                            id=''
                            style={{padding: '7px', width: '100%'}}
                            disabled
                            {...formik.getFieldProps('reorder_level')}
                          />
                        )}
                      </td>
                    </tr>
                    <tr className=''>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.retail_price && formik.errors.retail_price && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.retail_price}</div>
                          </div>
                        )}
                      </td>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.qty_in_store && formik.errors.qty_in_store && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.qty_in_store}</div>
                          </div>
                        )}
                      </td>
                      <td className='pb-4 px-4 font-size-15'></td>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.reorder_level && formik.errors.reorder_level && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.reorder_level}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='row col-sm-10 my-2'></div>
              <div className='row col-sm-2 my-2'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || !formik.isValid}
                >
                  {!loading && 'Update Item'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export {EditInventory}
