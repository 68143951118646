import {useEffect, useRef, useState, useReducer} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {KTSVG} from '../../../_metronic/helpers'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateAccount, inits} from './CreateAccountWizardHelper'
import {useAuth} from '../../modules/auth'
import {initalQuoteState, QuoteStepper, reducerQuote} from '../../context/QuoteContext'
import {HAS_QuoteCreate} from './PermissionFunction'
import {Modal} from 'react-bootstrap'

interface PropsType {
  modal: boolean
  setModal: (cb_value: boolean) => void
  getWorkOrderData: () => void
}
const Quote = ({modal, setModal, getWorkOrderData, projectType}: PropsType) => {
  const [stateStepper, dispatchStepper] = useReducer<any>(reducerQuote, initalQuoteState)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [initValues] = useState<ICreateAccount>(inits)
  const {currentUser} = useAuth()
  const stepForm1 = useRef<any>(null)
  const stepForm2 = useRef<any>(null)
  const resetAction = {type: 'RESET'}

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      stepForm1.current.submitForm()
      setTimeout(() => {
        console.log(stepForm1.current)
        if (Object.keys(stepForm1.current.errors).length === 0) {
          stepper?.current?.goNext()
        }
      }, 500)
    } else if (stepper.current.getCurrentStepIndex() === 2) {
      stepForm2.current.submitForm()
      if (Object.keys(stepForm2.current.errors).length === 0) {
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  function handleCloseModal() {
    setModal(false)
    dispatchStepper(resetAction)
  }
  return (
    <div className='card'>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', marginTop: '0px'}}
        onModalClose={() => handleCloseModal()}
        show={modal}
        className=''
        onHide={() => handleCloseModal()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Body>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column'
            id='kt_create_account_stepper'
          >
            <QuoteStepper.Provider value={{state: stateStepper, dispatch: dispatchStepper}}>
              <div style={{display: stateStepper?.current == 1 ? 'block' : 'none'}}>
                <Step1 projectType={projectType} />
              </div>
              <div style={{display: stateStepper?.current == 2 ? 'block' : 'none'}}>
                <Step2
                  dispatchStepper={handleCloseModal}
                  getWorkOrderData={getWorkOrderData}
                  setModal={(callback_value: boolean) => setModal(callback_value)}
                />
              </div>
            </QuoteStepper.Provider>
            <Formik initialValues={initValues} onSubmit={submitStep}>
              {() => (
                <Form className='' id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'></div>
                  <div data-kt-stepper-element='content'></div>
                  <div className='d-flex flex-stack'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {Quote}
