import {Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Projects} from './components/Projects'
import {Campaigns} from './components/Campaigns'
import {Documents} from './components/Documents'
import {ProfileHeader} from './ProfileHeader'
import {Notes} from './components/Notes'
import {TimeSheet} from './components/TimeSheet'
import {BOMData} from './BOMData'
import {ItemsAssigned} from './components/ItemsAssigned'
import {ProjectPO} from './components/ProjectPO'
import {createContext, useCallback, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {HAS_QuotePageView} from './PermissionFunction'
export const ApiContext = createContext<string[] | any>([])

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {
  const [data, setData] = useState<string[]>([])
  const [title, setTitle] = useState('')
  const [empList, setEmpList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const {currentUser} = useAuth()
  const callbackFunction = useCallback((id_data: [], id_title: string, cbEmpList: []) => {
    setData(id_data)
    setTitle(id_title)
    setEmpList(cbEmpList)
  }, [])

  return (
    <div className='card_custom_container'>
      <ApiContext.Provider value={{data: data, title: title, emp_list: empList}}>
        <Routes>
          <Route
            element={
              <>
                <>
                  <ProfileHeader
                    cbFnc={(cbData: string[] | any, cbTitle: string, cbEmpList: string[] | any) =>
                      callbackFunction(cbData, cbTitle, cbEmpList)
                    }
                    setModalOpen={setModalOpen}
                  />
                  <Outlet />
                </>
              </>
            }
          >
            <Route
              path='overview'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
                  <Overview />
                </>
              }
            />
            <Route
              path='intake-form'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Intial Intake Form</PageTitle>
                  <Projects />
                </>
              }
            />
            <Route
              path='activity'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Activity Manager</PageTitle>
                  <Campaigns />
                </>
              }
            />
            <Route
              path='files'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Files Manager</PageTitle>
                  <Documents />
                </>
              }
            />
            <Route
              path='bom'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Bill of Material</PageTitle>
                  {/* <Connections /> */}
                  <BOMData globalData={data} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                </>
              }
            />
            <Route
              path='notes'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Estimated Notes</PageTitle>
                  <Notes />
                </>
              }
            />
            <Route
              path='timesheet'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Timesheet</PageTitle>
                  <TimeSheet />
                </>
              }
            />
            <Route
              path='items-assigned'
              element={
                <>
                  <ItemsAssigned />
                </>
              }
            />
            <Route
              path='bom-assigned'
              element={
                <>
                  <BOMData />
                </>
              }
            />
            <Route
              path='purchase-order'
              element={
                <>
                  <ProjectPO />
                </>
              }
            />
          </Route>
        </Routes>
      </ApiContext.Provider>
    </div>
  )
}

export default ProfilePage
