import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

type PropsType = {
  modalShow: boolean
  rowData: string[] | any
  inventoryData: string[] | any
  defaultData: string[] | any
  title: string
  handleClose: () => void
  confirmFunction: (apiData: any) => void
}

export default function AssociatedItems({
  modalShow,
  title,
  rowData,
  inventoryData,
  defaultData,
  handleClose,
  confirmFunction,
}: PropsType) {
  const [items, setItems] = useState<any>([])

  useEffect(() => {
    setItems(defaultData || [])
  }, [defaultData])

  const handleAddItem = (event: any, value: any) => {
    if (value !== null && !items.includes(value)) {
      setItems([...items, value])
    }
  }

  const handleRemoveItem = (index: number) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  const loading = inventoryData.length === 0
  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      onModalClose={() => {
        handleClose()
      }}
      show={modalShow}
      className='fade'
      onHide={() => handleClose()}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='xl'
    >
      <Modal.Header>
        <Modal.Title className='w-100'>
          <div className='row'>
            <div className='col-md-4 d-flex align-items-lg-center'>
              <h1>{title}</h1>
            </div>
            <div className='col-md-8'>
              <div className='row border-bottom-1 border-dark'>
                <div className='col-md-4'>
                  <div className='text-muted'>Manufacturer</div>
                  <div>{rowData?.brand?.name || 'N/A'}</div>
                </div>
                <div className='col-md-4'>
                  <div className='text-muted'>Manufacturer Part#</div>
                  <div>{rowData?.sku || 'N/A'}</div>
                </div>
                <div className='col-md-4'>
                  <div className='text-muted'>Item Name</div>
                  <div>{rowData?.name || 'N/A'}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* begin::Search */}
          <div className='card-title d-flex' style={{}}>
            <div className='d-flex align-items-center w-100 position-relative '>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-4 position-absolute ms-6'
              />
              <Autocomplete
                id='asynchronous-demo'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                }}
                getOptionLabel={(option: any) => option.sku}
                options={inventoryData}
                onChange={handleAddItem}
                loading={loading}
                className='form-control form-control-solid ps-14 w-100 p-0'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search Inventory to add Associated Item'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          {/* end::Search */}
          <div>
            <table className='p-lg-4'>
              <thead className='border-bottom'>
                <tr>
                  <th
                    style={{
                      width: '150px',
                    }}
                    className='p-lg-3 fs-5'
                  >
                    Item Logo
                  </th>
                  <th
                    style={{
                      width: '200px',
                    }}
                    className='p-lg-3 fs-5'
                  >
                    MFG
                  </th>
                  <th
                    style={{
                      width: '200px',
                    }}
                    className='p-lg-3 fs-5'
                  >
                    MFG Part #
                  </th>
                  <th
                    style={{
                      width: '550px',
                    }}
                    className='p-lg-3 fs-5'
                  >
                    Item Name
                  </th>
                  <th
                    style={{
                      width: '100px',
                    }}
                    className='text-center fs-5'
                  >
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  display: 'table-caption',
                  height: '300px',
                  overflowY: 'scroll',
                }}
              >
                {items.map((item: any, index: React.Key | any) => (
                  <tr key={index}>
                    <td
                      style={{
                        width: '150px',
                      }}
                      className='p-lg-3 fs-6'
                    >
                      <img
                        alt='item'
                        src={`${
                          item?.image_field
                            ? item?.image_field
                            : '/media/custom/empty_inventory.svg'
                        }`}
                        width='70px'
                        height={'70px'}
                        style={{objectFit: 'contain'}}
                      />
                    </td>
                    <td
                      style={{
                        width: '200px',
                      }}
                      className='p-lg-3 fs-6'
                    >
                      {item?.brand?.name || 'N/A'}
                    </td>
                    <td
                      style={{
                        width: '200px',
                      }}
                      className='p-lg-3 fs-6'
                    >
                      {item?.sku || 'N/A'}
                    </td>
                    <td
                      style={{
                        width: '550px',
                      }}
                      className='p-lg-3 fs-6'
                    >
                      {item?.name || 'N/A'}
                    </td>
                    <td
                      style={{
                        width: '100px',
                      }}
                      className='text-center fs-6 cursor-pointer'
                    >
                      <span onClick={() => handleRemoveItem(index)}>X</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-row-reverse mx-2'>
          <span className='mx-2'>
            <button
              className='btn btn-primary'
              onClick={() => {
                confirmFunction(items)
              }}
            >
              Confirm
            </button>
          </span>
          <span className='mx-1'>
            <button
              onClick={() => {
                handleClose()
              }}
              className='btn btn-dark'
            >
              Discard
            </button>
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
