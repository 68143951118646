/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  icon: string
  title: string
  description: string
  url: string
  outerClassname: string
  outerId: string
  innerClassname: any
}

const Card4: FC<Props> = ({
  icon,
  title,
  description,
  url,
  outerClassname,
  innerClassname,
  outerId,
}) => {
  return (
    <div className='card customCard' style={{marginTop: '25px'}}>
      <div className={outerClassname} id={outerId}>
        <a
          href={`${url}`}
          target='_blank'
          className='text-gray-800 text-hover-primary d-flex flex-column'
          download
        >
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl(icon)} alt='' />
          </div>
          <div className={innerClassname}>{title}</div>
        </a>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{description}</div>
      </div>
    </div>
  )
}

export {Card4}
