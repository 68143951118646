/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {Admin_department, RolePermission} from './Roles'
import role_list from '../../data/roles.json'
import * as Yup from 'yup'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  getPermissions,
  updatePermissions,
} from '../../modules/auth/core/_requests'
import {useFormik} from 'formik'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {Permission} from './Permission'

const ManageUserPermission = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const roleListing: RolePermission[] = role_list
  const headerContext = useContext<any>(HeaderContext)
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const {role} = useParams<any>()
  // const ROLE = getRole(role)
  const ROLE = role
  var json_Data = data
  const profileDetailsSchema = Yup.object().shape({})
  const initialValues = ''
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Manage Permission',
        subtitle: ` `,
        btn_text: 'Manage Role',
        navigation_url: `/${prefixCompany}/employee/roles`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = async (role: any) => {
    await getPermissions(role)
      .then((res) => {
        let PermissionData = res?.data?.permissions
        setData(PermissionData || [])
        // setData(roleListing)
      })
      .catch((e) => {
        console.error(e)
      })
  }
  useEffect(() => {
    getList(ROLE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateJsonPermissions = (e: any, anotherE: any, anotherV: any) => {
    let updatePermissions: any[] = []
    e?.permission.map((item: any) =>
      item?.value === anotherE
        ? ((item.access = anotherV), updatePermissions.push(item))
        : updatePermissions.push(item)
    )
    e['permission'] = updatePermissions
    return e
  }

  const getValues = (header: any, label_value: any, event: any) => {
    let label = label_value
    let value = event?.target?.checked
    let title = header
    json_Data = Object.values(data).map((item: any) =>
      item?.value === title ? updateJsonPermissions(item, label, value) : item
    )
    return json_Data
  }

  const permissionBlocker = () => {
    darkErrorMessage('Admin role must have checked this Permission.')
  }

  const formik = useFormik<any>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      let jsonString = JSON.stringify(json_Data)
      setLoading(true)
      updatePermissions(jsonString, ROLE)
        .then((res: any) => {
          let result = res?.data
          setLoading(false)
          setSuccessBody(result as any)
          darkMessage('Permission updated.')
          navigate(`/${prefixCompany}/employee/roles`)
          window.location.reload()
        })
        .catch((e: any) => {
          console.log(e)
          if (e.response.status === 409) {
            setErrorBody(e.response.data)
            setLoading(false)
          }

          darkErrorMessage('Oops! Something went wrong.')
        })
    },
  })
  return (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card card-flush h-md-100 pt-9'>
            {errorBody && <ErrorAlert error_response={errorBody} />}
            {successBody && <SuccessAlert success_response={successBody} />}
            <div className='card-footer flex-wrap pt-0'>
              <label className='form-label text-dark fs-6 required'>Role Name</label>
              <input
                placeholder='Role'
                type='text'
                disabled
                value={ROLE}
                autoComplete='off'
                className={clsx('form-control form-control-lg form-control-solid')}
              />
              <label className='form-label text-dark fs-6  mt-6'>Role Permissions</label>
              <br />
              <div className='table-responsive'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                  <table className='table align-middle table-row-dashed fs-6 gy-5'>
                    <tbody className='text-gray-600 fw-semibold'>
                      <tr>
                        <td className='text-gray-800'>
                          <div style={{width: '200px'}}>
                            <i
                              className='fas fa-exclamation-circle ms-1 fs-7'
                              data-bs-toggle='tooltip'
                              aria-label='Allows a full access to the system'
                              data-bs-original-title='Allows a full access to the system'
                              data-kt-initialized='1'
                            ></i>{' '}
                            {ROLE}
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      {Object.values(data).map((permission: any) => {
                        // console.log(permission)
                        return ROLE !== Admin_department?.label &&
                          permission?.value === Permission?.Settings?.Masquerade?.value ? (
                          <></>
                        ) : (
                          <tr>
                            <td className='text-gray-800'>{permission?.title}</td>
                            <td>
                              <div className='d-flex'>
                                {permission?.permission?.map((item: any) => {
                                  var ischecked = Boolean(item.access)
                                  return (
                                    <label
                                      style={{minWidth: '150px'}}
                                      className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'
                                    >
                                      {ROLE === Admin_department?.label &&
                                      permission?.value === Permission?.UserManagement?.value ? (
                                        <>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            checked
                                            id={item.value}
                                            onClick={permissionBlocker}
                                          />
                                          <span className='form-check-label'>{item.label}</span>
                                        </>
                                      ) : (
                                        <>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            defaultChecked={ischecked ? true : false}
                                            id={item.value}
                                            onChange={(e) => {
                                              getValues(permission?.value, item?.value, e)
                                            }}
                                          />
                                          <span className='form-check-label'>{item.label}</span>
                                        </>
                                      )}
                                    </label>
                                  )
                                })}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td style={{minWidth: '190px', display: 'inline-block'}}>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={loading || !formik.isValid}
                          >
                            {!loading && 'Save Changes'}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ManageUserPermission}
