import {LinearProgress} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {ListsWidget14} from '../../../_metronic/partials/widgets/lists/ListsWidget14'
import {useAuth} from '../../modules/auth'
import {
  createItemActivity_Item,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  fetchAssignedItems_Item,
  fetchItemActivity_Item,
} from '../../modules/auth/core/_requests'
import {InventoryNotes} from './InventoryNotes'
import SimpleDataTable from './SimpleDataTable'

const ItemsAssigned_Item = () => {
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [assigned_items, set_assigned_items] = useState([])
  const [item_activity, set_item_activity] = useState([])
  const [modal, setModalShow] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const getList = useCallback(async () => {
    setLoading(true)
    await fetchAssignedItems_Item(id)
      .then((res) => {
        const ITEMS = res?.data?.items_assigned
        set_assigned_items(ITEMS || [])
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [id])

  const getActivity = useCallback(async () => {
    setLoading(true)
    await fetchItemActivity_Item(id)
      .then((res) => {
        const ITEMS_ACTIVITY = res?.data?.item_activity
        set_item_activity(ITEMS_ACTIVITY || [])
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    getList()
    getActivity()
  }, [getList, getActivity])

  const handleSubmitActivity = useCallback(
    async (callback_data: any) => {
      await createItemActivity_Item(callback_data)
        .then((res: any) => {
          setModalShow(false)
          getActivity()
          darkMessage('Note Added Successfully')
        })
        .catch((e: any) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
        })
    },
    [getActivity]
  )
  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row.final_title,
        header: 'Quote/Project/Field Service', //uses the default width from defaultColumn prop
        accessorKey: 'items_assigned_work_order_id',
      },
      {
        accessorFn: (row: any) => row?.assigned_items,
        header: 'Items Assigned',
        accessorKey: 'items_assigned_assigned_count',
      },
      {
        header: 'Assigned By',
        accessorFn: (row: any) => (
          <img
            title={row?.user_name}
            alt='user here'
            src={`${row?.user_image || '/media/avatars/blank.png'}`}
            width='40px'
            height={'40px'}
            style={{objectFit: 'contain', borderRadius: '50%'}}
          />
        ),
        accessorKey: 'items_assigned_assigned_by',
      },
    ],
    []
  )
  const handleNavigation = (navigate_link: any) => {
    const NAVIGATION_ID = navigate_link?.project_id
    let NAVIGATION_LINK = ''
    if (navigate_link?.final_title.includes('P')) {
      NAVIGATION_LINK = `/${prefixCompany}/project/${NAVIGATION_ID}/overview`
    } else if (navigate_link?.final_title.includes('FS')) {
      NAVIGATION_LINK = `/${prefixCompany}/fieldservice/${NAVIGATION_ID}/overview`
    }
    navigate(NAVIGATION_LINK)
  }
  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      <div className='row'>
        <div className='col'>
          <div className='card-title m-0 mt-5'>
            <div className='d-flex bg-white '>
              <h3 className='fw-bolder p-7'> Item Assigned to Quotes/Project/Field Service</h3>
            </div>
            <SimpleDataTable
              MaterialColumns={MaterialColumns}
              MaterialColumnsApiData={assigned_items}
              tableHeight={450}
              stopRedirection={true}
              handleClick={(cb_data: any) => {
                handleNavigation(cb_data)
              }}
            />
          </div>
        </div>
        <div className='col'>
          <div className='card-title m-0 mt-5'>
            <div
              className='d-flex bg-white '
              style={{justifyContent: 'space-between', borderRadius: ' 10px 10px 0 0px'}}
            >
              <h3 className='fw-bolder p-7'>Notes</h3>
              <button
                onClick={() => setModalShow(true)}
                style={{
                  height: '44px',
                  margin: '10px',
                  width: '130px',
                }}
                type='button'
                className='btn btn-light'
                disabled={loading}
              >
                Add Note
              </button>
            </div>
            <div>
              <div
                className={`card rounded-0 pb-5 gap-2`}
                style={{
                  height: '470px',
                  overflow: 'scroll',
                }}
              >
                <div className='timeline-label d-flex flex-column gap-7'>
                  {item_activity.map((idx) => {
                    return (
                      <ListsWidget14
                        className='rounded-0 pb-5'
                        list={idx || []}
                        getList={getActivity}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryNotes cb_fnc={(cb_Data: any) => handleSubmitActivity(cb_Data)} />
      </Modal>
    </React.Fragment>
  )
}

export default ItemsAssigned_Item
