import {Autocomplete, TextField} from '@mui/material'
import {useFormik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Create_New_Field_Service_Form_Initial as initialValues} from '../../modules/accounts/components/settings/SettingsModel'
import {
  CreateNewFieldServiceForm,
  customerQuoteList,
  darkMessage,
} from '../../modules/auth/core/_requests'
import {CustomerModel} from '../../modules/model/customer'
const emergencycall = ['Yes', 'No']
const timeframe = ['Morning', 'Afternoon', 'Evening', 'Weekend']
function NewFieldForm() {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const profileDetailsSchema = Yup.object().shape({
    customer_name: Yup.string().required('Customer Name is required'),
    emergency_call: Yup.string().required('Emegency Call is required'),
    service_requested: Yup.string().required('Service Requested is required'),
    need_soon: Yup.string().required('How do you is required'),

    additional_details: Yup.string().required('Additional Details is required'),
  })
  const getList = useCallback(async () => {
    await customerQuoteList()
      .then((res: any) => {
        let employes = res.data.customers as CustomerModel[]
        let list: any = []
        employes.forEach((e) => {
          list.push({label: e.company, id: e.id})
        })
        setOptions(list)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setLoading(false)
        }
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      let fieldItems = {
        customer_name: values.customer_name,
        emergency_call: values.emergency_call,
        service_requested: values.service_requested,
        need_soon: values.need_soon,
        time_frame: values.time_frame,
        additional_details: values.additional_details,
      }
      CreateNewFieldServiceForm(fieldItems)
        .then((res) => {
          console.log(res?.data)
          setLoading(false)
          darkMessage('Field Service Created Successfully')
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            console.error(e.response.data)
            darkMessage(e.response.data?.error_list[0])
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })

  return (
    <>
      {/* {JSON.stringify(formik.errors)} */}
      <form onSubmit={formik.handleSubmit} noValidate encType='multipart/formData' className='form'>
        <div className='card mb-5 mb-xl-10 col-sm-12'>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>Customer</label>
                </div>
                <div className='col-sm-8'>
                  <Autocomplete
                    disablePortal
                    className='form-control form-control-lg form-control-solid'
                    id='combo-box-demo'
                    options={options}
                    onChange={(e, value: any) => {
                      if (value?.id) {
                        formik.setFieldValue('customer_name', value?.id)
                      }
                    }}
                    fullWidth={true}
                    renderInput={(params: any) => (
                      <TextField {...params} label='Please select Customer' />
                    )}
                  />
                  {formik.touched.customer_name && formik.errors.customer_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.customer_name}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>Emergency Call</label>
                </div>
                <div className='col-sm-8'>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e) => formik.setFieldValue('emergency_call', e?.target?.value)}
                  >
                    <option selected disabled hidden>
                      ---------------
                    </option>
                    {emergencycall.map((call) => {
                      return <option value={call}>{call}</option>
                    })}
                  </select>
                  {formik.touched.emergency_call && formik.errors.emergency_call && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.emergency_call}</div>
                    </div>
                  )}
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>Service Requested</label>
                </div>
                <div className='col-sm-8'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    placeholder='Enter Request'
                    {...formik.getFieldProps('service_requested')}
                  />
                  {formik.touched.service_requested && formik.errors.service_requested && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.service_requested}</div>
                    </div>
                  )}
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>
                    How soon does this need to be done?
                  </label>
                </div>
                <div className='col-sm-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='How soon does this need to be done?'
                    {...formik.getFieldProps('need_soon')}
                  />
                  {formik.touched.need_soon && formik.errors.need_soon && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.need_soon}</div>
                    </div>
                  )}
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>
                    What time frame does our tech need to arrive?
                  </label>
                </div>
                <div className='col-sm-8'>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e) => formik.setFieldValue('time_frame', e?.target?.value)}
                  >
                    <option selected disabled hidden>
                      ---------------
                    </option>
                    {timeframe.map((frame: any) => {
                      return <option value={frame}>{frame}</option>
                    })}
                  </select>
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <div className='col-sm-4'>
                  <label className='col-form-label required fw-bold fs-6'>Additional Details</label>
                </div>
                <div className='col-sm-8'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    placeholder='Additional Details'
                    {...formik.getFieldProps('additional_details')}
                  />
                  {formik.touched.additional_details && formik.errors.additional_details && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.additional_details}</div>
                    </div>
                  )}
                </div>
              </div>{' '}
              <div className='my-2 text-end'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || !formik.isValid}
                >
                  {!loading && 'Create Field Service'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export default NewFieldForm
