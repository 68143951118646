/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useFormik} from 'formik'
import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {
  currentlyClockIn,
  currnetCompanyName,
  getDepartments,
  timesheetManagerV2,
} from '../../modules/auth/core/_requests'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import moment from 'moment'
import {RowTimeSheet, Datum2, LastRow} from '../../modules/model/RowTimeSheet'
import {FormControl, InputLabel, LinearProgress, MenuItem, Select} from '@mui/material'
import Roles from './Roles'
import {useAuth} from '../../modules/auth'
import {HAS_TimesheetViewTimesheet} from './PermissionFunction'
import SearchComponent from './SearchComponent'
import {Link} from 'react-router-dom'
import {TimeSheetTextConstant} from '../../context/TimeSheetConstant'
import {WeeklyTimeCardModal} from './WeeklyTimeCardModal'
import {Modal} from 'react-bootstrap'
import {ListsWidget11} from '../../../_metronic/partials/widgets/lists/ListsWidget11'
import {ProfileContext} from '../../../_metronic/layout/MasterLayout'
import {StickyComponent} from '../../../_metronic/assets/ts/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

interface PropsType {
  data: Datum2[]
  total: number
  showHeading: boolean
  clicked: (idx: any) => void
}
interface PropsTotalItemType {
  rows: RowTimeSheet
}
interface PropsLastRow {
  row: LastRow[]
}
const timeConverter = (e: number) => {
  const time = e / 3600
  return time.toFixed(2)
}
const secondsToTime = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0')
  return h + ':' + m
}



const CellTimeSheet = ({data, total, showHeading, clicked,header,index}: PropsType) => {
  let blank_rows = Math.abs(data?.length - total)
  {console.log('data?.length')}
  {console.log(data?.length)}
  {console.log('total')}
  {console.log(total)}
  {console.log('blank_rows')}
  {console.log(blank_rows)}

  // if (blank_rows === 0) blank_rows = 2
  // console.log({blank_rows, len: data?.length, total})
  return (
    <>
    <div>
      <table rowspacing='0' cellspacing='0' style={{width:'100%',
      borderCollapse:'separate',borderSpacing:'0',borderBottom:'solid 3px #707d87'}} >
      <thead>

      {console.log(header)}
      {console.log(index)}

      {header.map((item: any,counter) => {
//         {console.log('map start')}
//         {console.log(item)}
//         {console.log('map end')}
             if(counter===index){
                  return (
                            <>
                            <tr className='day-header-tr' style={{backgroundColor:'#707D87',color:'#FFFFFF',}}>
                                    <td style={{fontWeight:'bold', fontSize:'14px', display:'table-cell',}} colSpan={2}>{moment(item?.date).format('ddd, MMM DD')}</td>
                                    <td style={{textAlign:'right',}} colSpan={1}>
              {timeConverter(
                data.reduce((accumulator, item) => accumulator + parseInt(item.total_time), 0)
              ) !== '00:00'
                ? timeConverter(
                    data.reduce((accumulator, item) => accumulator + parseInt(item.total_time), 0)
                  )
                : '00:00'}
              {' hrs'} </td>
                              </tr>
                              </>
                            )}
                          })}
      </thead>
{/*         {showHeading && ( */}
{/*           <thead style={{borderBottom: '1px solid #D6D9D6'}}> */}
{/*             <th>Job</th> */}
{/*             <th>In</th> */}
{/*             <th>Out</th> */}
{/*             <th>Hours</th> */}
{/*           </thead> */}
{/*         )} */}
        <tbody style={{paddingTop:'50px'}}>
          {data.map((item) => {
            return (
//               <tr className=' inner-table cursor-pointer' onClick={() => clicked(item)}>
//                 <td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px'}}>
//                   {/* {Linker(item)} */}
//                   {item.title}
//                 </td>
//                 <td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px'}}>
//                   {moment(item.start_time).format('LT')}
//                 </td>
//                 <td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px'}}>
//                   {moment(item.end_time).format('LT')}
//                 </td>
//                 <td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px'}}>
//                   {timeConverter(parseInt(item.total_time))}
//                 </td>
//               </tr>
<>

{/*Job Title & Total Time ROW */}
<tr className=' inner-table cursor-pointer outer-border-tr' onClick={() => clicked(item)} style={{backgroundColor:'#E8E8E8', color:'#5F6E78', fontWeight:'600',borderBottom:'0.6px solid #D3D3D3', borderTop:'0.6px solid #D3D3D3'}}>
<td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px'}} colSpan={2}>
{item.title}
</td>
<td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px', textAlign:'right'}} colSpan={1}>
{timeConverter(parseInt(item.total_time))}{' hrs'}
</td>
</tr>

{/*In & Out ROW */}
<tr className=' inner-table cursor-pointer outer-border-tr' onClick={() => clicked(item)} style={{backgroundColor:'#FFFFFF', color:'#757575'}}>

<td style={{overflow: 'hidden', verticalAlign: 'top',width:'33.33%'}} colSpan={1} >
{moment(item.start_time).format('LT')}
</td>

<td style={{overflow: 'hidden', verticalAlign: 'top',width:'33.33%',textAlign:'center'}} colSpan={1}>
{'\u2192'}
</td>

<td style={{overflow: 'hidden', verticalAlign: 'top',width:'33.33%', textAlign:'right'}} colSpan={1}>
{moment(item.end_time).format('LT')}
</td>

</tr>
</>
            )
          })}


{data.length === 0 && (
  Array(1)
    .fill(null)
    .map((item) => {
      return (
        <tr className=' inner-table cursor-pointer outer-border-tr' onClick={() => clicked(item)} style={{backgroundColor:'#FFFFFF', color:'#757575'}}>
          <td style={{overflow: 'hidden', verticalAlign: 'top', minWidth: '10px',textAlign:'center'}} colSpan={3}>
            {'No timestamps found,'}<br/>{'click to add one!'}
          </td>
        </tr>
      );
    })
)}

        </tbody>
      </table>
      </div>
    </>
  )
}
const TotalItem = ({rows}: PropsTotalItemType) => {
  let total = 0
  if (rows.data.length === 0) {
    total = 0
  } else {
    total = rows.data.reduce((acc, items) => {
      return acc + items.data.reduce((acc_sub, item) => acc_sub + parseInt(item.total_time), 0)
    }, 0)
  }
  return (

            <div>{'Total: '}
              <span style={{fontWeight:'bold'}}>{timeConverter(total)}{' hours'}</span>
              </div>

  )
}

function DisplayDaysSelected({ formik}) {
  const formatDate = (dateString) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      {/* Assuming formik.values.from_date is a valid date string */}
      {'('}
     {formatDate(formik.values.from_date)}
     {' - '}
     {formatDate(formik.values.to_date)}
     {')'}
    </>
  );
}

const LastRowItems = ({row}: PropsLastRow) => {
  return (
    <tr className=''>
      <td></td>
      {row.map((item) => {
        return (
          <td>
            <b>Total : </b> {timeConverter(parseInt(item?.total_time))} hrs{' '}
          </td>
        )
      })}
      <td>
        <b>Total : </b>
        {timeConverter(row.reduce((acc, item) => acc + parseInt(item?.total_time), 0))} hrs{' '}
      </td>
    </tr>
  )
}
interface type {
  timesheet_type: any
}
const WeeklyTimeCardV2: FC<type> = (props: type) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState<any>(null)
  const [submitPressed, setSubmitPressed] = useState(false)
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [lastRowData, setLastRowData] = useState([])
  const [header, setHeader] = useState([])
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const companyName: any = currnetCompanyName(currentUser?.company)
  const search_routes = `/${companyName}/employee/timesheet/`
  const [modal, setModalShow] = React.useState<any>({})
  const [selectedClient, setSelectedClient] = useState('all')
  const [value, setValue] = useState<any>([])
  const [clockData, setClockData] = useState<any>(null)
  const profileContext: any = useContext(ProfileContext)
  const [deparments, setDepartments] = useState<any>([])

  const departmentList = useCallback(() => {
    setLoading(true)
    getDepartments()
      .then((res: any) => {
        setLoading(false)
        let list = res?.data?.response
        setDepartments(list)
      })
      .catch((e: any) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  const Linker = (orderNumber: any, empData: any, index: any) => {
    let date: any = ''
    header.map((e, ind): any => {
      if (ind === index) {
        date = e?.date
      }
    })
    const datas = {
      customer: empData?.emp,
      end_time: moment(date).format(),
      quote_id: '',
      start_time: moment(date).format(),
      title: '',
      total_time: '',
    }
    orderNumber
      ? setModalShow({show: true, data: orderNumber, employee: empData})
      : setModalShow({show: true, data: datas, employee: empData})
    // setModalShow({show: true, data: orderNumber})
  }

  const initialValues = {
    from_date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    search: '',
  }

  //  Formik validations defined here
  const WeeklyTimeCardSchema = Yup.object().shape({
    from_date: Yup.string().required('From Date is required'),
    to_date: Yup.string().required('To Date is required'),
  })
  const department = useCallback((getData: any) => {
    setFilter(getData?.value)
  }, [])

  const getData = useCallback(() => {
    const clocked_in = value?.clocked_in
    const clocked_in_timesheet = value?.clocked_in_timesheet
    setTimeout(() => {
      if (Object.keys(clocked_in).length > 0) {
        setClockData(clocked_in)
      } else {
        if (Object.keys(clocked_in_timesheet).length > 0) {
          setClockData(clocked_in_timesheet)
        } else {
          setClockData(null)
        }
      }
    }, 0)
  }, [value, profileContext?.state?.clockIn])

  const onModalClose = useCallback(() => {
    setModalShow({show: false})
    let time_period = {
      from_date: formik.values.from_date,
      to_date: formik.values.to_date,
      permission: props?.timesheet_type,
      search: formik.values.search,
    }
    fetchTimeSheet(time_period)
  }, [])

  const fetchTimeSheet = useCallback((time_period: any) => {
    timesheetManagerV2(time_period)
      .then((res: any) => {
        const headerData = res?.data?.date_filter
        const emp_data = res?.data?.final_data
        const last_row = res?.data?.last_row
        setTimeout(() => {
          setHeader(headerData || [])
          setData(emp_data || [])
          setLastRowData(last_row || [])
        }, 0)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
  }, [])

  function handleActive(event: any) {
    setSelectedClient(event.target.value)
    if (event?.target?.value === 'all') {
      setFilter(null)
    } else {
      setFilter(event?.target?.value)
    }
  }
  const currentlyClockedIn = useCallback(() => {
    currentlyClockIn()
      .then((res: any) => {
        let data = res?.data
        setValue(data)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
  }, [])

  //  Formik defined here
  const formik = useFormik({
    initialValues,
    validationSchema: WeeklyTimeCardSchema,
    onSubmit: (values) => {
      setLoading(true)
      setSubmitPressed(true)
      let time_period = {
        from_date: values.from_date,
        to_date: values.to_date,
        permission: props?.timesheet_type,
        search: values.search,
      }
      fetchTimeSheet(time_period)
    },
  })

  useEffect(() => {
    currentlyClockedIn()
    let time_period = {
      from_date: formik.values.from_date,
      to_date: formik.values.to_date,
      permission: props?.timesheet_type,
      search: formik.values.search,
    }
    setLoading(true)
    fetchTimeSheet(time_period)
    setTimeout(() => {
      getData()
      departmentList()
    }, 0)
  }, [])

  useEffect(() => {
    let time_period = {
      from_date: formik.values.from_date,
      to_date: formik.values.to_date,
      permission: props?.timesheet_type,
      search: filter || '',
    }
    setLoading(true)
    setTimeout(() => {
      fetchTimeSheet(time_period)
    }, 0)
    getData()
  }, [filter, value])
  useEffect(() => {
    currentlyClockedIn()
    getData()
  }, [profileContext?.state?.clockIn])
  return (
    HAS_TimesheetViewTimesheet(currentUser) && (
      <>
        {errorBody && <ErrorAlert error_response={errorBody} />}
        <div className='container mt-10 card mt-4 mb-5'>
          <div className='row p-1 my-4'>
            <div className='col-12  justify-content-between'>
              <>
                <div className='d-flex justify-content-between'>
                  {search_routes === window.location.pathname ? (
                    <h2 className='px-10 py-3'>Company Timesheet</h2>
                  ) : (
                    <>
                      <h2 className='px-10 py-3'>
                        {currentUser?.first_name + ' ' + currentUser?.last_name}
                      </h2>
                      <div className='px-10 py-3 text-right'>
                        {clockData ? (
                          <>
                            <h3>Currently Clocked In</h3>
                            <b>{clockData?.final_title} </b>
                            {/* {timeConverter(clockData?.seconds)} hrs */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <hr />
                <div className='container'>
                  <div className='row'>
                    <form className='form-inline' onSubmit={formik.handleSubmit} noValidate>
                      <div
                        className='col-sm-12 company-timesheet-wrapper'
                        style={{justifyContent: 'space-between'}}
                      >
                        <>
                          {search_routes === window.location.pathname ? (
                            <>
                              <div className='col-md-2 p-2 wrap-fileds' style={{float: 'left'}}>
                                <label htmlFor=''>Search</label>
                                <input
                                  type='text'
                                  className={'form-control'}
                                  placeholder={'John Doe'}
                                  {...formik.getFieldProps('search')}
                                />
                                {formik.touched.search && formik.errors.search && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.search}</div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : null}
                        </>
                        <div className='col-md-2 p-2 wrap-fileds' style={{float: 'left'}}>
                          <label htmlFor='' className='required'>
                            From Date
                          </label>
                          <input
                            type='date'
                            className={'form-control'}
                            {...formik.getFieldProps('from_date')}
                          />
                          {formik.touched.from_date && formik.errors.from_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.from_date}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-md-2 p-2 wrap-fileds' style={{float: 'left'}}>
                          <label htmlFor='' className='required'>
                            To Date
                          </label>
                          <input
                            type='date'
                            className={'form-control'}
                            {...formik.getFieldProps('to_date')}
                          />
                          {formik.touched.to_date && formik.errors.to_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.to_date}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-md-2 p-2 wrap-fileds' style={{float: 'left'}}>
                          <label htmlFor='' style={{marginTop: '3.3rem'}}></label>
                          <button
                            type='submit'
                            className='btn btn-primary btn-sm'
                            disabled={loading || !formik.isValid}
                          >
                            {!loading && (
                              <>
                                <i className='fab fa-font-awesome'></i> APPLY FILTER
                              </>
                            )}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                {' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className='col-sm-1 p-2 wrap-fileds' style={{float: 'left'}}></div>
                        <div
                          className='col-md-3 p-2 wrap-fileds'
                          style={{float: 'right', marginTop: '10px'}}
                        >
                          <FormControl className='w-100'>
                            <InputLabel id='all-departments'>Search By Department</InputLabel>
                            <Select
                              labelId='all-departments'
                              id='demo-simple-select'
                              value={selectedClient}
                              label='Search For Department'
                              onChange={handleActive}
                            >
                              <MenuItem value={'all'}>{'ALL'}</MenuItem>
                              {deparments.map((item: any) => (
                                <MenuItem value={item.type}>
                                  {item.type === 'Owner'
                                    ? 'Admin Department'
                                    : item.type === 'Team Member'
                                    ? 'Engineering Department'
                                    : item.type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* Start New Table */}
                <div className='table-responsive' >
                  {!loading && data.length > 0 ? (
                  <>
                    <div> {data.map((item: RowTimeSheet, idx) => {
                          return (
                          <>
                                <div style={{display:'flex', 
                                justifyContent:'space-between', alignItems:'center',
                                margin:'16px',marginBottom:'8px', fontSize:'18px'}}>
                                <div className="employee-info-left">
                                  <div className='full-height employee-name' style={{fontWeight:'bold'}}>
                                    {' '}
                                    {item?.emp?.first_name} {item?.emp?.last_name}{' '}
                                  </div>
                                  
                                  <DisplayDaysSelected formik={formik}></DisplayDaysSelected>
                                </div>
                                {/* icon goes here */}
                                <div className='employee-info-right'>
                                  <TotalItem rows={item} />
                              
                                    <button className="btn btn-primary btn-sm btn-export" style={{marginLeft:'12px'}}>
                                      <FontAwesomeIcon icon={faFileExcel}/>
                                      {'   EXPORT'} 
                                    </button>
                          
                                </div>
                                </div>
                                <hr style={{border:'3px solid #707D87',opacity:'1'}}/>
{/*                                 {console.log(data)} */}
{/*                                 {console.log(item)} */}
{/*                                 {console.log(idx)} */}

                                {/* days go here */}
                                <div style={{width:'100%',
                                display:'grid', gridTemplateColumns:'repeat(auto-fit, minmax(180px, 1fr))',
                                gridGap:'10px', gridRowGap:'24px',paddingLeft:'8px',paddingRight:'8px'
                                }}>

{/*                                 {header.map((item: any) => { */}
{/*                             return ( */}
{/*                               <> */}
{/*                                   <div style={{backgroundColor: 'blue'}}> */}
{/*                                     <div style={{fontWeight:'bold', */}
{/*                                     fontSize:'16px'}}>{moment(item?.date).format('ddd, MMM DD')}</div> */}

{/*                                   </div> */}
{/*                               </> */}
{/*                             ) */}
{/*                           })} */}

                           {item.data.map((data_item, index) => {
                                return (
                                  <>

                                    {' '}
                                    <CellTimeSheet
                                      clicked={(cb_data: any) => {
                                        return Linker(cb_data, item, index)
                                      }}
                                      showHeading={idx === 0}
                                      total={item?.max}
                                      data={data_item.data}
                                      header={header}
                                      index={index}
                                    />{' '}
                                 </>
                                )
                              })}


                                </div>
                          </>
                          )
                        })}
                   </div>


                   </>
                  ) : (
                    <></>
                  )}

                  {/* {' '}
                  <p>
                    {' '}
                    {submitPressed ? (
                      <>No result found.</>
                    ) : (
                      <>Please select from date and to date to see the table of time card.</>
                    )}{' '}
                  </p>{' '}
                </div> */}

                  <div className='text-center mt-6'>
                    {!loading && data.length === 0 && <p>No result found</p>}
                  </div>
                  {loading && <LinearProgress />}
                </div>

                <Modal
                  style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
                  show={modal?.show}
                  onHide={() => setModalShow({show: false})}
                  size='lg'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <WeeklyTimeCardModal
                    onModalClose={onModalClose}
                    fetchTimeSheet={fetchTimeSheet}
                    show={modal?.show}
                    data={modal?.data}
                    employee={modal?.employee}
                    onHide={() => setModalShow(false)}
                  />
                </Modal>
                {/* <Modal
                  show={popUp?.show}
                  onHide={() => setPopUp({show: false})}
                  size='xl'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <ListsWidget11
                    onModalClose={onModalClose}
                    className='card-xxl-stretch mb-5 mb-xl-10'
                    data={value}
                  />
                </Modal> */}
                {/* End New Table */}
              </>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export {WeeklyTimeCardV2}
