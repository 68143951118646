import {UserModel} from './../../modules/auth/core/_models'

export const Permission = {
  Dashboard: {
    value: 'dashboard',
    Menu: {
      value: 'menu',
    },
  },
  Customer: {
    value: 'customer',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreateCustomer: {
      value: 'create-customer',
    },
    ViewCustomer: {
      value: 'single-customer',
    },
    UpdateCustomer: {
      value: 'edit-customer',
    },
    DeleteCustomer: {
      value: 'delete-customer',
    },
  },
  Quotes: {
    value: 'quotes',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreateQuote: {
      value: 'create-quote',
    },
    ViewQuote: {
      value: 'single-quote',
    },
    UploadFiles: {
      value: 'upload-files',
    },
    AddNotes: {
      value: 'add-notes',
    },
    UpdateStatus: {
      value: 'update-status',
    },
    GeneratePdf: {
      value: 'generate-pdf',
    },
  },
  WorkOrder: {
    value: 'workorders',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    ViewWorkOrder: {
      value: 'single-workorder',
    },
    CreateProject: {
      value: 'create-project',
    },
    UploadFiles: {
      value: 'upload-files',
    },
    AddNotes: {
      value: 'add-notes',
    },
    UpdateStatus: {
      value: 'update-status',
    },
  },
  Inventory: {
    value: 'inventory',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    ViewItem: {
      value: 'single-item',
    },
    CreateItem: {
      value: 'create-item',
    },
    UpdateItem: {
      value: 'update-item',
    },
    DeleteItem: {
      value: 'delete-item',
    },
    EditCategory: {
      value: 'edit-category',
    },
  },
  PurchaseOrder: {
    value: 'purchase-order',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreatePO: {
      value: 'create-po',
    },
    ViewPO: {
      value: 'single-po',
    },
    UpdatePO: {
      value: 'update-po',
    },
    DeletePO: {
      value: 'delete-po',
    },
  },
  RMAOrder: {
    value: 'rma-order',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreateRMA: {
      value: 'create-rma',
    },
    ViewRMA: {
      value: 'single-rma',
    },
    UpdateRMA: {
      value: 'update-rma',
    },
  },
  Employees: {
    value: 'employees',
    Menu: {
      value: 'menu',
    },
    ViewEMP: {
      value: 'view-employees',
    },
    InviteEmployees: {
      value: 'invite-employees',
    },
    UpdateEmployee: {
      value: 'update-employees',
    },
    DeleteEmployee: {
      value: 'delete-employees',
    },
  },
  UserManagement: {
    value: 'user-management',
    Menu: {
      value: 'menu',
    },
    ViewDepartments: {
      value: 'view-departments',
    },
    UpdatePermissions: {
      value: 'edit-permissions',
    },
    EditDepartments: {
      value: 'edit-departments',
    },
    DeleteDepartments: {
      value: 'delete-departments',
    },
  },
  Timesheet: {
    value: 'timesheet',
    Menu: {
      value: 'menu',
    },
    ViewTimesheet: {
      value: 'view-timesheet',
    },
  },
  EmpTimesheet: {
    value: 'emp-time-sheet',
    Menu: {
      value: 'menu',
    },
    ViewTimesheet: {
      value: 'view-emp-sheet',
    },
  },
  Settings: {
    value: 'settings',
    Menu: {
      value: 'menu',
    },
    MyCompany: {
      value: 'my-company',
    },
    DashboardQuote: {
      value: 'add-quote',
    },
    AddTasks: {
      value: 'add-tasks',
    },
    DashboardRemainder: {
      value: 'add-reminder',
    },
    Files: {
      value: 'files',
    },
    Masquerade: {
      value: 'masquerade',
    },
    ClosingTime: {
      value: 'closing-time',
    },
    StatusOption: {
      value: 'status-option',
    },
    Timezone: {
      value: 'timezone',
    },
  },
  InvitedEmployee: {
    value: 'invited-employee',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreateInvitedEmployee: {
      value: 'create-invited-employee',
    },
    ResendInvitation: {
      value: 'resend-invitation',
    },
    DeleteInvitation: {
      value: 'delete-invitation',
    },
  },
  WhoClockedIn: {
    value: 'who-clocked-in',
    Menu: {
      value: 'menu',
    },
    ViewWhoClockedIn: {
      value: 'view-who-clocked-in',
    },
  },
  Vendor: {
    value: 'vendor',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    ViewVendor: {
      value: 'view-vendor',
    },
    CreateVendor: {
      value: 'create-vendor',
    },
    DeleteVendor: {
      value: 'delete-vendor',
    },
    UpdateVendor: {
      value: 'update-vendor',
    },
    POTab: {
      value: 'po_tab',
    },
    LoginCredentialsTab: {
      value: 'login_credentials_tab',
    },
  },
  FieldService: {
    value: 'fieldservice',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    ViewFieldService: {
      value: 'single-field-service',
    },
    CreateFieldService: {
      value: 'create-field-service',
    },
    UpdateFieldService: {
      value: 'update-field-service',
    },
    UploadFiles: {
      value: 'upload-files',
    },
    AddNotes: {
      value: 'add-notes',
    },
    UpdateFieldStatus: {
      value: 'update-field-status',
    },
  },

  Services: {
    value: 'services',
    Menu: {
      value: 'menu',
    },
    ViewTable: {
      value: 'view-table',
    },
    CreateService: {
      value: 'create-service',
    },
    UpdateService: {
      value: 'update-service',
    },
    DeleteService: {
      value: 'delete-service',
    },
  },
}

const cache_data: any = []

export const hasAccess = (parent: string, child: string, user: UserModel | undefined) => {
  // Use Cache Key if already role permission fetch.

  try {
    let cache_data_key = cache_data[parent + '__' + child]
    if (cache_data_key !== undefined) {
      return cache_data_key
    }
    if (user !== undefined) {
      if (user.permissions != undefined) {
        let permission = user.permissions.find((item: any) => item?.value == parent)
        if (permission?.permission) {
          let child_permission = permission?.permission.find((item: any) => item?.value == child)
          if (child_permission) {
            cache_data[parent + '__' + child] = child_permission.access
            return child_permission.access
          }
        }
      }
    }
  } catch (e) {
    // do nothing.
  }
  return true
}
