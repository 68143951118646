export interface RoleObjectInterface {
    value: string,
    label: string
}
interface RolesInterface {
  Executive: RoleObjectInterface
  Admin: RoleObjectInterface
  Sales: RoleObjectInterface
  Engineering: RoleObjectInterface
  Production: RoleObjectInterface

}

export const Admin_department = {
    label:'Admin Department',
    value:'Owner'
}

const Roles: RolesInterface = {
  Executive: {
    label:'Executive Department',
    value:'Executive Department'
  },
  Admin: {
    label:'Admin Department',
    value:'Owner'
  },
  Sales: {
    label: 'Sales Department',
    value: 'Sales Department',
  },
  Engineering: {
    label: 'Engineering Department',
    value: 'Team Member'
  },
  Production: {
    label: 'Production Department',
    value: 'Production Department'
  },
}
export const RolesList = [
      Roles.Executive,
      Roles.Admin,
      Roles.Sales,
      Roles.Engineering,
      Roles.Production,
]

export const getRole = (role: any) => {
  return RolesList.find( item => item.value === role )
}

export const getRoleValue = (role: any) => {
  return RolesList.find( item => item.label === role )
}


export interface Permission {
    label: string;
    value: string;
    access: boolean;
}

export interface RolePermission {
    title: string;
    value: string;
    permission: Permission[];
}



export default Roles