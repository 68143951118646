/* eslint-disable jsx-a11y/anchor-is-valid */
import {TextField} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {Button} from 'react-bootstrap'

type Props = {
  className: string
  Heading: string
  getQuote: (cb_data: string) => void
}

const QuoteWidget: React.FC<Props> = ({className, Heading, getQuote}) => {
  const [quote, setQuote] = useState('')

  // Handle Submission of Quote (Send data from child to parent)
  const handleSubmit = useCallback(
    (e: {preventDefault: () => void}) => {
      e?.preventDefault()
      getQuote(quote)
      setQuote('')
    },
    [getQuote, quote]
  )

  return (
    <div className={`card ${className}`}>
      <div className='card-body pb-0'>
        <div className='pb-5 '>
          <h3>{Heading}</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            variant='outlined'
            label='Enter Quote'
            placeholder='If you can dream it, you can do it.'
            fullWidth
            onChange={(e) => setQuote(e?.target?.value)}
            value={quote}
          />
          <Button type='submit' className='mt-5 mb-5 text-center'>
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export {QuoteWidget}
