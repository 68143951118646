import {
  FormControl,
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  LinearProgress,
} from '@mui/material'
import React, {useCallback, useState, useEffect} from 'react'
import {ALL_fetchWorkOrderTag, darkErrorMessage} from '../../modules/auth/core/_requests'
import CloseIcon from '@mui/icons-material/Close';


type PropsType = {
  callback_function: (cb_data: string[] | string) => void
}
const FilterByTag = ({callback_function}: PropsType) => {
  const SELECTED_ALL = 'all'
  const [selected, setSelected] = useState<string[] | any>([])
  const [option, setOption] = useState([])
  const [loading, setLoading] = useState(false)
  const isAllSelected = option.length > 0 && selected.length === option.length

  const getTagsList = useCallback(() => {
    const TAGS_LIST_ARRAY: string[] | any = []
    setLoading(true)
    ALL_fetchWorkOrderTag()
      .then(({data}) => {
        let LIST = data?.result
        LIST.forEach((e: any) => {
          TAGS_LIST_ARRAY.push({name: e.name, id: e.id})
        })
        setOption(TAGS_LIST_ARRAY)
        setLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getTagsList()
  }, [getTagsList])

  const handleChange = useCallback(
    (event: any) => {
      const value = event.target.value
      if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
        setSelected([])
        callback_function(SELECTED_ALL)
        return
      } else {
        setSelected(value)
        callback_function(value)
      }
    },
    [callback_function, option, selected.length]
  )

  return (
    <FormControl className='check h-35 c-width100'>
      <InputLabel id='mutiple-select-label' style={{marginTop: '-6px'}}>
        Filter By Tags
      </InputLabel>
      <Select
        labelId='mutiple-select-label'
        style={{height: '35px', width: "100%"}}
        multiple
        value={selected}
        label='Filter By Tags'
        onChange={handleChange}
        renderValue={(selected) => selected.join(',')}
      >
        <MenuItem value={SELECTED_ALL}>
          <CloseIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={selected.length > 0 && selected.length < option.length}
            />
          </CloseIcon>
          <ListItemText primary='Clear All' />
        </MenuItem>
        {loading ? (
          <LinearProgress />
        ) : (
          option.map((item: any) => (
            <MenuItem value={item.name}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(item.name) > -1} />
              </ListItemIcon>
              {item.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}

export default FilterByTag