import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MaterialReactTable from 'material-react-table'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  deleteInventoryItem,
  inventoryAssignedItemsUrl,
  inventoryItemData,
  inventoryListForPO,
  inventorySubstitutesItemsUrl,
  preferred_vendor_inventory_item,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {
  HAS_InventoryDeleteItem,
  HAS_InventoryUpdateItem,
  HAS_InventoryViewItem,
} from './PermissionFunction'
import DeleteModal from './DeleteModal'
import {CommaNumberFormatter, NumberFormatter} from './GlobalComponents'
import moment from 'moment'
import PurchaseHistory from './PurchaseHistory'
import AssociatedItems from './AssociatedItems'
import ItemsAssigned_Item from './ItemsAssigned_Item'

const InventoryView: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {id}: any = useParams<any>()
  const [compo, setCompo] = useState('overview')
  const [compo1, setCompo1] = useState('associated')
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [item, setItem] = useState<any>([])
  const [associatedItemsFilter, setAssociatedItemsFilter] = useState<any>([])
  const [substitutesItemsFilter, setSubstitutesItemsFilter] = useState<any>([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalVendor, setDeleteModalVendor] = useState<any>({
    show: false,
    data: null,
  })
  const [defaultVendor, setDefaultVendor] = useState(0)
  const [associatedItems_Modal, setAssociatedItems_Modal] = useState(false)
  const [substitutes_Modal, setSubstitutes_Modal] = useState(false)
  const [inventoryItems, setInventoryItems] = useState([])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'View Inventory Item',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = useCallback(async () => {
    await inventoryItemData(id)
      .then((res) => {
        const itemData = res?.data?.items[0]
        const getAssociatedItems = res?.data?.items[0]?.associated_items
        const getSubstitutesItems = res?.data?.items[0]?.substitutes_items
        setAssociatedItemsFilter(getAssociatedItems || [])
        setSubstitutesItemsFilter(getSubstitutesItems || [])
        setItem(itemData || [])
      })
      .catch((e) => {
        console.error(e)
      })
  }, [id])

  const allInventoryData = useCallback(async () => {
    await inventoryListForPO()
      .then((res) => {
        const inventoryItems = res?.data?.items
        setInventoryItems(inventoryItems)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getList()
    allInventoryData()
  }, [allInventoryData, getList])

  const handleDeleteModal = useCallback(() => {
    setDeleteModal(false)
  }, [])

  const handleClosing = () => {
    setAnchorEl(null)
  }

  const DeleteInventoryItem = useCallback(
    (idx: any = id) => {
      deleteInventoryItem(idx)
        .then((res) => {
          darkMessage('Item Deleted Successfully')
          handleDeleteModal()
          navigate(`/${prefixCompany}/inventory/store`)
        })
        .catch((e) => {
          if (e?.response?.status) {
            darkErrorMessage(e?.response?.data)
          } else {
            console.error(e)
          }
        })
    },
    [handleDeleteModal, id, prefixCompany]
  )

  const handleOpening = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePreferredVendor = useCallback(async () => {
    const vendor_id = deleteModalVendor?.data?.id
    const item = {
      inventory_id: id,
      vendor_id: vendor_id,
    }
    await preferred_vendor_inventory_item(item)
      .then((response: any) => {
        const message = response?.data?.message
        darkMessage(message)
        getList()
        setDeleteModalVendor({
          show: false,
          data: null,
        })
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const error = e?.response?.data?.error_list[0]
          darkErrorMessage(error)
        } else {
          console.log(e)
        }
        setDeleteModalVendor({
          show: false,
          data: null,
        })
      })
  }, [deleteModalVendor?.data?.id, getList, id])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          item?.preferred_vendor === row?.id && (
            <img
              src={'/media/custom/star.png'}
              alt=''
              style={{width: '20px', height: '20px', margin: 'auto', marginRight: '10px'}}
            />
          ),
        header: '', //uses the default width from defaultColumn prop
        accessorKey: 'logo',
        size: 20,
      },
      {
        accessorFn: (row: any) => row.name,
        header: 'Vendor', //uses the default width from defaultColumn prop
        accessorKey: 'vendor_name',
        size: 150,
      },
      {
        accessorFn: (row: any) => (
          <a
            href={
              row?.website && row?.website.includes('http') ? row.website : `https://${row.website}`
            }
            target='blank'
          >
            <KTSVG path={'/media/icons/duotune/general/orderlink.svg'} />
          </a>
        ),
        header: 'Order Link',
        accessorKey: 'vendor_order_link',
        size: 50,
      },
      {
        header: 'Last Order From Vendor',
        accessorFn: (row: any) => (!row?.last_order ? 'N/A' : moment(row?.last_order).format('ll')),
        size: 120, //increase the width of this column
        accessorKey: 'vendor_last_order',
      },
      {
        accessorFn: (row: any) => NumberFormatter(row?.last_paid_price_po) || 0,
        header: 'Last Paid Price',
        accessorKey: 'vendor_last_price',
        size: 100, //increase the width of this column
      },
      {
        accessorFn: (row: any) => (
          <div className='flex-wrap fw-bold fs-6 mb-4 pe-2' style={{display: 'flex'}}>
            <b
              onClick={(e: any) => {
                handleOpening(e)
                setDefaultVendor(row)
              }}
              style={{
                letterSpacing: '3px',
                color: 'black',
                cursor: 'pointer',
              }}
            >
              ...
            </b>
          </div>
        ),
        header: 'Actions',
        accessorKey: 'vendor_actions',
        size: 50, //increase the width of this column
      },
    ],
    [item]
  )

  const MARK_UP = item?.retail_price - item?.item_cost

  const handle_AssociatedItemsModal = async (cb_data: any) => {
    const json_list = JSON.stringify(cb_data)
    await inventoryAssignedItemsUrl(id, json_list)
      .then((res) => {
        const message = res?.data?.message
        darkMessage(message)
        getList()
        setAssociatedItems_Modal(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data?.error_list[0])
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
      })
  }

  const handle_SubstitutesItemsModal = async (cb_data: any) => {
    const json_list = JSON.stringify(cb_data)
    await inventorySubstitutesItemsUrl(id, json_list)
      .then((res) => {
        const message = res?.data?.message
        darkMessage(message)
        getList()
        setSubstitutes_Modal(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data?.error_list[0])
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
      })
  }
  return HAS_InventoryViewItem(currentUser) ? (
    <>
      <form noValidate encType='multipart/formData' className='form'>
        <div className='card mb-5 mb-xl-10 col-sm-12'>
          <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClosing}>
            <MenuItem
              onClick={() => {
                setDeleteModalVendor({
                  show: true,
                  data: defaultVendor,
                })
                setAnchorEl(null)
              }}
            >
              Make This Vendor the Preferred Vendor
            </MenuItem>
          </Menu>

          <DeleteModal
            modalShow={deleteModalVendor?.show}
            title={'Confirmation Message'}
            body={'Did You really want to mark this Vendor as Preferred Vendor ?'}
            handleClose={() =>
              setDeleteModalVendor({
                show: false,
                data: null,
              })
            }
            confirmFunction={() => handlePreferredVendor()}
          />

          <DeleteModal
            modalShow={deleteModal}
            title={'Delete Item'}
            body={'Are you sure want to delete this Item ?'}
            handleClose={() => handleDeleteModal()}
            confirmFunction={() => DeleteInventoryItem(id)}
          />

          <div id='kt_account_profile_details' className='collapse show'>
            <div className='row p-0' style={{background: ' #F5F8FA'}}>
              <div className='col-xl-5 col-lg-8 col-md-8 col-sm-12 col-xsm-12'>
                <div className='border-top'>
                  <div className=''>
                    <div className='row align-items-start'>
                      <div className='col inventory-view-tabs'>
                        <h5
                          className={
                            compo && compo === 'overview' ? 'text-danger bg-white rounded-top' : ''
                          }
                          onClick={() => setCompo('overview')}
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            padding: '15px 0',
                            height: '57px',
                            margin: '0',
                            textAlign: 'center',
                          }}
                        >
                          Item Overview
                        </h5>
                      </div>
                      <div className='col inventory-view-tabs'>
                        <h5
                          className={
                            compo && compo === 'history' ? 'text-danger bg-white rounded-top' : ''
                          }
                          onClick={() => setCompo('history')}
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            padding: '15px 0',
                            margin: '0',
                            height: '57px',
                            textAlign: 'center',
                          }}
                        >
                          Purchase History
                        </h5>
                      </div>
                      <div className='col inventory-view-tabs'>
                        <h5
                          className={
                            compo && compo === 'activity' ? 'text-danger bg-white rounded-top' : ''
                          }
                          onClick={() => setCompo('activity')}
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            padding: '15px 0',
                            margin: '0',
                            height: '57px',
                            textAlign: 'center',
                          }}
                        >
                          Item Activity
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='card-body border-top p-5 col-sm-12'
              style={{minHeight: '600px', maxHeight: '600px', overflow: 'scroll'}}
            >
              <div>
                {compo && compo === 'overview' ? (
                  <>
                    <div className='d-flex align-items-lg-center m-0 justify-content-end'>
                      {HAS_InventoryUpdateItem(currentUser) && (
                        <div className='mx-3'>
                          <button
                            type='button'
                            onClick={() => navigate(`/${prefixCompany}/inventory/store/${id}/edit`)}
                            className='btn btn-primary'
                          >
                            {'Edit Item'}
                          </button>
                        </div>
                      )}
                      <div className='mx-3'>
                        {HAS_InventoryDeleteItem(currentUser) && (
                          <button
                            type='button'
                            onClick={() => {
                              setDeleteModal(true)
                            }}
                            className='btn btn-primary'
                          >
                            {'Delete Item'}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className='tab-content'>
                      <div className='tab-pane active' id='tabs-1' role='tabpanel'>
                        <div className='row pt-10 pb-10'>
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xsm-12 text-center'>
                            <a href={item?.image_field} target='blank'>
                              <img
                                src={item?.image_field || '/media/custom/empty_inventory.svg'}
                                alt=''
                                style={{
                                  maxWidth: '180px',
                                  width: '100%',
                                  height: 'auto',
                                  margin: 'auto',
                                  objectFit: 'contain',
                                }}
                              />
                            </a>
                          </div>
                          <div className='col-xl-7 col-lg-6 col-md-8 col-sm-12 col-xsm-12'>
                            <div className='row t-border-bottom'>
                              <div className='col-md-4'>
                                <span className='text-muted'>Manufacturer</span>
                                <h3>{item?.brand?.name || 'N/A'}</h3>
                              </div>
                              <div className='col-md-4'>
                                <span className='text-muted'>Manufacturer Part#</span>
                                <h3>{item?.sku || 'N/A'}</h3>
                              </div>
                              <div className='col-md-4'>
                                <span className='text-muted'>Item Name</span>
                                <h3>{item?.name || 'N/A'}</h3>
                              </div>
                            </div>
                            <div className='row pt-5 pb-5' style={{minHeight: '150px'}}>
                              <div className='col-md-12'>
                                <span className='text-muted'>Description</span>
                                <h4 className='fw-normal'>{item?.item_description}</h4>
                              </div>
                            </div>
                            <div className='t-border-top vendors-desc-table'>
                              <MaterialReactTable
                                columns={MaterialColumns}
                                data={item?.vendor || []}
                                enableSorting={false}
                                enableHiding={false}
                                enableFilters={false}
                                muiTableHeadCellProps={{
                                  sx: {
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                  },
                                }}
                                muiTableBodyCellProps={{
                                  style: {fontSize: '13px'},
                                }}
                                muiTablePaperProps={{
                                  elevation: 0, //change the mui box shadow
                                  //customize paper styles
                                  sx: {
                                    borderRadius: '0',
                                    borderWidth: '0',
                                  },
                                }}
                                manualFiltering
                                enableGlobalFilter={true}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enablePagination={false}
                                enableColumnFilters={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                              />
                            </div>
                          </div>
                          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xsm-12'>
                            <div className='d-flex justify-content-around d-border'>
                              <div className='py-4  px-4  pe-0  d-flex align-items-center'>
                                <img
                                  src={'/media/custom/app-s-Icon.png'}
                                  alt=''
                                  style={{width: '35px', height: '35px'}}
                                />
                                <div>
                                  <h6 className='font-size-15 m-0'>Inventory Category</h6>
                                  <div>{item?.category?.name}</div>
                                </div>
                              </div>
                              <div className='py-4  px-4 ps-0 d-flex'>
                                <img
                                  src={'/media/custom/location.png'}
                                  alt=''
                                  style={{width: '25px', height: '25px'}}
                                />
                                <div>
                                  <h6 className='font-size-15 m-0'>Inventory Location</h6>
                                  <div>{item?.inventory_location || 'N/A'}</div>
                                </div>
                              </div>
                            </div>

                            <div className='d-grid' style={{gridTemplateColumns: '1fr 1fr'}}>
                              <div className='d-border text-center'>
                                <div className='py-4  px-4 font-size-15 '>
                                  <span>Item Cost</span>
                                  <p className='fw-bold'>{NumberFormatter(item?.item_cost) || 0}</p>
                                </div>
                                <div className='py-4  px-4 font-size-15'>
                                  <span>Mark Up</span>
                                  <p className='fw-bold'>{NumberFormatter(MARK_UP) || 0}</p>
                                </div>
                                <div className='py-4  px-4 font-size-15 '>
                                  Retail Price
                                  <p className='fw-bold'>
                                    {NumberFormatter(item?.retail_price) || 0}
                                  </p>
                                </div>
                              </div>
                              <div className='d-border text-center'>
                                <div className='py-4  px-4 font-size-15 '>
                                  QTY in Store
                                  <p className='fw-bold'>
                                    {CommaNumberFormatter(item?.quantity_in_store) || 0}
                                  </p>
                                </div>
                                <div className='py-4  px-4 font-size-15 '>
                                  Available Stock
                                  <p className='fw-bold'>
                                    {CommaNumberFormatter(item?.available_stock) || 0}
                                  </p>
                                </div>
                                <div className='py-4  px-4 font-size-15 '>
                                  Quantity Reserved
                                  <p className='fw-bold'>
                                    {!isNaN(CommaNumberFormatter(item?.reserved_quantity))
                                      ? CommaNumberFormatter(item?.reserved_quantity)
                                      : 0}
                                  </p>
                                </div>
                                <div className='py-4  px-4 font-size-15'>
                                  Quantity On Order
                                  <p className='fw-bold'>
                                    {CommaNumberFormatter(item?.quantity_on_order) || 0}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-around d-border text-center'>
                              <div className='py-4  px-4 font-size-15'>
                                Maintain Stock?
                                <p className='fw-bold'>
                                  {JSON.stringify(item?.maintain_stock) === 'true' ? 'Yes' : 'No'}
                                </p>
                              </div>
                              <div className='py-4  px-4 font-size-15'>
                                Re-Order Level<p className='fw-bold'>{item?.reorder_level || 0}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='tab-pane' id='tabs-2' role='tabpanel'>
                        <p>Second Panel</p>
                      </div>
                      <div className='tab-pane' id='tabs-3' role='tabpanel'>
                        <p>Third Panel</p>
                      </div>
                    </div>
                  </>
                ) : compo && compo === 'history' ? (
                  <PurchaseHistory item_details={item} />
                ) : compo && compo === 'activity' ? (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <ItemsAssigned_Item />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <AssociatedItems
            modalShow={associatedItems_Modal}
            rowData={item || []}
            inventoryData={inventoryItems}
            defaultData={associatedItemsFilter}
            title={'Edit Associated Items'}
            handleClose={() => setAssociatedItems_Modal(false)}
            confirmFunction={(callback_data: any) => handle_AssociatedItemsModal(callback_data)}
          />
          <AssociatedItems
            modalShow={substitutes_Modal}
            rowData={item || []}
            inventoryData={inventoryItems}
            defaultData={substitutesItemsFilter}
            title={'Edit Substitutes Items'}
            handleClose={() => setSubstitutes_Modal(false)}
            confirmFunction={(callback_data: any) => handle_SubstitutesItemsModal(callback_data)}
          />
          {compo && compo === 'overview' && (
            <>
              <div className='card mt-10'>
                <ul className='nav nav-tabs d-flex justify-content-evenly' role='tablist'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active font-size-15  fw-bolder m-0 border-0'
                      style={{background: '#ccccc'}}
                      data-toggle='tab'
                      href='#tabs-1'
                      role='tab'
                    >
                      <h3
                        className={compo1 && compo1 === 'associated' ? 'text-danger' : ''}
                        onClick={() => setCompo1('associated')}
                      >
                        {compo1 && compo1 === 'associated' && (
                          <span className='me-3' onClick={() => setAssociatedItems_Modal(true)}>
                            <KTSVG path={'/media/custom/pencil-123.svg'} className='svg-icon-3' />
                          </span>
                        )}
                        Associated Items
                      </h3>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link fw-bolder m-0'
                      data-toggle='tab'
                      href='#tabs-2'
                      role='tab'
                    >
                      <h3
                        className={compo1 && compo1 === 'substitutes' ? 'text-danger' : ''}
                        onClick={() => setCompo1('substitutes')}
                      >
                        {compo1 && compo1 === 'substitutes' && (
                          <span className='me-3' onClick={() => setSubstitutes_Modal(true)}>
                            <KTSVG path={'/media/custom/pencil-123.svg'} className='svg-icon-3' />
                          </span>
                        )}
                        Substitutes
                      </h3>
                    </a>
                  </li>
                </ul>
              </div>
              {compo1 && compo1 === 'associated' ? (
                <div
                  className='card-body border-top  p-9 d-flex gap-10'
                  style={{overflowY: 'scroll'}}
                >
                  {associatedItemsFilter.length > 0 ? (
                    associatedItemsFilter.map((each_associatedItem: any) => {
                      return (
                        <div
                          className='d-flex p-5'
                          style={{
                            border: ' 1px solid #ccc',
                            height: '200px',
                            minWidth: '350px',
                            maxWidth: '400px',
                          }}
                        >
                          <div className='d-flex'>
                            <img
                              src={
                                each_associatedItem?.image_field ||
                                '/media/custom/empty_inventory.svg'
                              }
                              alt=''
                              style={{width: '100px', height: '100px', margin: 'auto'}}
                            />
                          </div>
                          <div className='p-lg-2 d-inline-flex justify-content-between flex-column'>
                            <div>
                              <h2 className='text-muted'>{each_associatedItem?.brand?.name}</h2>
                              <strong>{each_associatedItem?.sku}</strong>
                              <p className='m-0' style={{fontSize: '15px'}}>
                                {each_associatedItem?.name}
                              </p>
                            </div>
                            <div className=' border-0 cursor-pointer'>
                              <NavLink
                                to={`/${prefixCompany}/inventory/store/${each_associatedItem?.id}/view`}
                                className='m-auto '
                                style={{
                                  background: 'rgb(225 225 225)',
                                  padding: '7px',
                                  borderRadius: '30px',
                                  display: 'inline-flex',
                                  color: 'black',
                                }}
                              >
                                Quick View
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='w-100 m-auto text-center'>
                      <h3>No Associated Items Found</h3>
                    </div>
                  )}
                </div>
              ) : compo1 && compo1 === 'substitutes' ? (
                <div
                  className='card-body border-top  p-9 d-flex gap-10'
                  style={{overflowY: 'scroll'}}
                >
                  {substitutesItemsFilter.length > 0 ? (
                    substitutesItemsFilter.map((each_associatedItem: any) => {
                      return (
                        <div
                          className='d-flex p-5'
                          style={{
                            border: ' 1px solid #ccc',
                            height: '200px',
                            minWidth: '350px',
                            maxWidth: '400px',
                          }}
                        >
                          <div className='d-flex'>
                            <img
                              src={
                                each_associatedItem?.image_field ||
                                '/media/custom/empty_inventory.svg'
                              }
                              alt=''
                              style={{width: '100px', height: '100px', margin: 'auto'}}
                            />
                          </div>
                          <div className='p-lg-2 d-inline-flex justify-content-between flex-column'>
                            <div>
                              <h2 className='text-muted'>{each_associatedItem?.brand?.name}</h2>
                              <strong>{each_associatedItem?.sku}</strong>
                              <p className='m-0' style={{fontSize: '15px'}}>
                                {each_associatedItem?.name}
                              </p>
                            </div>
                            <div className=' border-0 cursor-pointer'>
                              <NavLink
                                to={`/${prefixCompany}/inventory/store/${each_associatedItem?.id}/view`}
                                className='m-auto '
                                style={{
                                  background: 'rgb(225 225 225)',
                                  padding: '7px',
                                  borderRadius: '30px',
                                  display: 'inline-flex',
                                  color: 'black',
                                }}
                              >
                                Quick View
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='w-100 m-auto text-center'>
                      <h3>No Substitutes Items Found</h3>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </form>
    </>
  ) : (
    <></>
  )
}
export {InventoryView}
