/* eslint-disable react-hooks/exhaustive-deps */
// / eslint-disable jsx-a11y/anchor-is-valid /
import {useCallback, useContext, useEffect, useState} from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {storeLocalUser, useAuth} from '../../modules/auth'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import {Calendar, dateFnsLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {FeedsWidget2} from '../../../_metronic/partials/widgets'
import {
  getRemainders,
  getUserByToken,
  login_user_by_email,
  masqueradeUrl,
  userMasquerade,
} from '../../modules/auth/core/_requests'
import {HAS_MasqueradeView, HAS_QuoteView, HAS_ReminderView} from './PermissionFunction'
import {Autocomplete, TextField} from '@mui/material'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const Masquerade: FC = () => {
  const [value, setValue] = useState([])
  const {currentUser} = useAuth()
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [list, setList] = useState<any>([])
  const headerContext = useContext<any>(HeaderContext)

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Masquerade',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = useCallback(() => {
    userMasquerade()
      .then((res) => {
        let result = res?.data?.user_data
        setValue(result)
      })
      .catch((e: any) => {
        if (e.response.status === 409) {
          console.error(e.response.data)
        } else {
          console.log(e)
        }
      })
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    let emailList: {label: any; id: any}[] = []
    if (value.length > 0) {
      value.forEach((e: any) => {
        emailList.push({label: e?.email, id: e?.id})
      })
    }
    setList(emailList)
  }, [value])

  const handleNavigate = (user: any) => {
    let user_mail = user?.label
    login_user_by_email(user_mail)
      .then(async (res) => {
        const auth = res?.data?.user
        await saveAuth(auth)
        const {data: user} = await getUserByToken(auth.access)
        await storeLocalUser({...user, ...auth})
        await setCurrentUser(user)
        window.location.reload()
        window.location.href = `/${user?.company?.toLowerCase().replaceAll(' ', '_')}/dashboard`
      })
      .catch((e: any) => {
        if (e?.response?.status) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }

  return (
    <>
      {HAS_MasqueradeView(currentUser) && (
        <div className='card mb-12 mb-xl-12'>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='row mb-12'>
                <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                  Search For Other Users Masquerade
                </label>
                <br />
                <div className='col-lg-12 fv-row'>
                  <Autocomplete
                    disablePortal
                    className=' form-control-lg '
                    id='combo-box-demo'
                    options={list}
                    onChange={(e, value: any) => {
                      if (value?.id) {
                        handleNavigate(value)
                      }
                    }}
                    fullWidth={true}
                    renderInput={(params: any) => (
                      <TextField {...params} label='Select for Other Users Masquerade' />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {Masquerade}
