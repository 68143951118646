import {LinearProgress} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useCallback, useState, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {
  currnetCompanyName,
  darkErrorMessage,
  fetchPOforVendors,
} from '../../modules/auth/core/_requests'
import {
  NumberFormatter,
  CommaNumberFormatter,
  PURCHASE_ORDER_PARTIALLY_RECEIVED,
  PURCHASE_ORDER_RECEIVED,
} from './GlobalComponents'
import SimpleDataTable from './SimpleDataTable'

const VendorPO = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [loading, setLoading] = useState(false)
  const [getList, setList] = useState([])
  const [row_count, setRowCount] = useState(0)
  const navigate = useNavigate()

  const getPOList = useCallback(async () => {
    setLoading(true)
    await fetchPOforVendors(id)
      .then((res) => {
        const purchase_order_data = res?.data?.po
        const purchase_order_length = res?.data?.po_count
        setList(purchase_order_data || [])
        setRowCount(purchase_order_length || 0)
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          const error = e?.response?.data?.error_list[0]
          darkErrorMessage(error)
        } else {
          console.log(e)
        }
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    getPOList()
  }, [getPOList])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => (
          <div
            onClick={() => navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)}
          >
            {row?.id}
          </div>
        ),
        size: 70,
        header: 'P.O.#', //uses the default width from defaultColumn prop
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() => navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)}
          >
            {row?.vendor?.name}
          </div>
        ),
        header: 'Vendor',
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() => navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)}
          >
            {CommaNumberFormatter(row.item_count)}
          </div>
        ),
        header: 'Item Ordered',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: any) => (
          <div
            onClick={() => navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)}
          >
            {moment(row?.date_ordered).format('ll')}
          </div>
        ),
        header: 'Order Date',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: any) => (
          <div
            onClick={() => navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)}
          >
            {NumberFormatter(row?.total_cost)}
          </div>
        ),
        header: 'PO Total',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: any) => (
          <div
            style={{
              color: 'white',
              overflow: 'visible',
              padding: '5px 10px',
              fontSize: '13px',
              fontWeight: '600',
              borderRadius: '50px',
              display: 'table-row',
            }}
            className={
              row.order_status === PURCHASE_ORDER_PARTIALLY_RECEIVED
                ? 'bg-warning'
                : row.order_status === PURCHASE_ORDER_RECEIVED
                ? 'bg-success'
                : 'bg-primary'
            }
          >
            {row.order_status}
          </div>
        ),
        header: 'Status',
        size: 100, //decrease the width of this column
      },
    ],
    [navigate, prefixCompany]
  )

  return (
    <>
      {loading && <LinearProgress />}
      <SimpleDataTable MaterialColumns={MaterialColumns} MaterialColumnsApiData={getList} />
    </>
  )
}

export default VendorPO
