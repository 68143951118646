import {useState, useEffect} from 'react'
import {KTSVG, useDebounce} from '../../../../../../../_metronic/helpers'
import {createSearchParams, useNavigate} from 'react-router-dom'

const UsersListSearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const navigate = useNavigate()

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 100)
  // Effect for API call
  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length > 0) {
  //       navigate({
  //         search: createSearchParams({
  //           str: searchTerm,
  //         }).toString(),
  //       })
  //     } else if (searchTerm.length === 0) {
  //       navigate({
  //         search: createSearchParams({
  //           str: '',
  //         }).toString(),
  //       })
  //     }
  //     return () => {}
  //   },
  //   [debouncedSearchTerm, navigate, searchTerm] // Only call effect if debounced search term changes
  //   // More details about useDebounce: https://usehooks.com/useDebounce/
  // )

  useEffect(() => {
    const hasSearchTerm = searchTerm !== undefined && searchTerm.length > 0
    if (debouncedSearchTerm !== undefined && hasSearchTerm) {
      setSearchTerm(searchTerm.trimStart())
      navigate({
        search: createSearchParams({str: searchTerm.trim()}).toString(),
      })
    } else if (!hasSearchTerm) {
      navigate({
        search: '',
      })
    }

    return () => {}
  }, [debouncedSearchTerm, navigate, searchTerm])

  return (
    <div className='d-flex'>
      {/* begin::Search */}
      <div style={{width:"100%"}} className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-4 position-absolute ms-6'
        />
        <input
          style={{fontSize: '14px', padding: '7px 10px'}}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid ps-14 c-width100'
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
