/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

import {useAuth} from '../../../../app/modules/auth'
import {
  HAS_CustomerTableMenu,
  HAS_DashboardQuoteAdd,
  HAS_DashboardRemainderAdd,
  HAS_EmployeesViewEMPMenu,
  HAS_EmpTimesheetViewTimesheetMenu,
  HAS_InventoryViewTableMenu,
  HAS_MasqueradeView,
  HAS_PurchaseOrderViewTableMenu,
  HAS_QuoteTableMenu,
  HAS_RMAOrderViewTableMenu,
  HAS_SettingMenu,
  HAS_StatusOption,
  HAS_Timezone,
  HAS_Timezone_Panel,
  HAS_UserManagementViewDepartmentsMenu,
  HAS_WorkOrderTableMenu,
  HAS_ServiceTableMenu,
  HAS_DashboardMyCompany,
  HAS_DashboardMenu,
  HAS_VendorMenu,
  HAS_InvitedEmployeeMenu,
  HAS_WhoClockedIn,
  HAS_AddTasks,
  HAS_Files,
  HAS_ClosingTime,
} from '../../../../app/pages/dashboard/PermissionFunction'
import {currnetCompanyName} from '../../../../app/modules/auth/core/_requests'
export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const company = currnetCompanyName(currentUser?.company)

  return (
    <>
      {HAS_DashboardMenu(currentUser) && (
        <AsideMenuItem
          to={`/${company}/dashboard`}
          icon={'/media/icons/duotune/art/art011.svg'}
          title='Dashboard'
          fontIcon=''
        />
      )}

      {HAS_CustomerTableMenu(currentUser) && (
        <AsideMenuItem
          to={`/${company}/customers`}
          icon='/media/icons/duotune/general/gen063.svg'
          title='Customers'
          fontIcon=''
        />
      )}
      {HAS_QuoteTableMenu(currentUser) ? (
        <AsideMenuItem
          to={`/${company}/quote`}
          icon='/media/icons/duotune/general/gen070.svg'
          title=' Quotes'
          fontIcon='bi-layers'
        />
      ) : null}
      {HAS_WorkOrderTableMenu(currentUser) ? (
        <AsideMenuItem
          to={`/${company}/project`}
          icon='/media/custom/Project_s.svg'
          title='Projects'
          fontIcon='bi-layers'
        />
      ) : null}
      {HAS_ServiceTableMenu(currentUser) && (
        <AsideMenuItem
          to={`/${company}/fieldservice`}
          icon='/media/icons/duotune/general/gen077.svg'
          title='Field Service'
          fontIcon='bi-layers'
        />
      )}
      {HAS_InventoryViewTableMenu(currentUser) ||
      HAS_PurchaseOrderViewTableMenu(currentUser) ||
      HAS_RMAOrderViewTableMenu(currentUser) ||
      HAS_VendorMenu(currentUser) ? (
        <AsideMenuItemWithSub
          to={`/${company}/inventory`}
          title='Inventory'
          icon='/media/icons/duotune/general/gen067.svg'
          fontIcon='bi-layers'
        >
          {HAS_InventoryViewTableMenu(currentUser) ? (
            <AsideMenuItem
              to={`/${company}/inventory/store`}
              title='Store Inventory'
              hasBullet={true}
            />
          ) : null}
          {HAS_PurchaseOrderViewTableMenu(currentUser) ? (
            <AsideMenuItem
              to={`/${company}/inventory/purchase-order`}
              title='Purchase Orders'
              hasBullet={true}
            />
          ) : null}
          {HAS_RMAOrderViewTableMenu(currentUser) ? (
            <AsideMenuItem
              to={`/${company}/inventory/return-order`}
              title='RMA Orders'
              hasBullet={true}
            />
          ) : null}
          {HAS_VendorMenu(currentUser) ? (
            <AsideMenuItem
              to={`/${company}/inventory/vendors`}
              hasBullet={true}
              // icon='/media/icons/duotune/general/gen089.svg'
              title='Vendors'
              // fontIcon=''
            />
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}

      {HAS_ServiceTableMenu(currentUser) ? (
        <AsideMenuItem
          to={`/${company}/services`}
          icon='/media/custom/services-icon.svg'
          title='Services'
          fontIcon='bi-layers'
        />
      ) : null}

      {HAS_EmployeesViewEMPMenu(currentUser) ||
      HAS_EmpTimesheetViewTimesheetMenu(currentUser) ||
      HAS_Timezone_Panel(currentUser) ||
      HAS_UserManagementViewDepartmentsMenu(currentUser) ? (
        <AsideMenuItemWithSub
          to={`/${company}/timecard`}
          title='Employees'
          icon='/media/icons/duotune/general/gen065.svg'
          fontIcon='bi-layers'
        >
          {HAS_EmployeesViewEMPMenu(currentUser) && (
            <AsideMenuItem
              to={`/${company}/employee/view`}
              // icon='/media/icons/duotune/general/gen065.svg'
              hasBullet={true}
              title='View Employees'
              fontIcon='bi-layers'
            />
          )}

          {HAS_EmpTimesheetViewTimesheetMenu(currentUser) && (
            <AsideMenuItem
              to={`/${company}/employee/timesheet/`}
              // icon='/media/icons/duotune/general/gen066.svg'
              hasBullet={true}
              title='Company Timesheet'
            />
          )}
          {HAS_UserManagementViewDepartmentsMenu(currentUser) && (
            <AsideMenuItem
              to={`/${company}/employee/roles`}
              // icon='/media/icons/duotune/general/gen057.svg'
              hasBullet={true}
              title='Manage Permissions'
              fontIcon='bi-layers'
            />
          )}
          {HAS_InvitedEmployeeMenu(currentUser) && (
            <AsideMenuItem
              to={`/${company}/employee/invitation`}
              // icon='/media/icons/duotune/general/gen057.svg'
              hasBullet={true}
              title='Invited Employees'
              fontIcon='bi-layers'
            />
          )}
          {HAS_WhoClockedIn(currentUser) && (
            <AsideMenuItem
              to={`/${company}/employee/whoclockedIn`}
              title={`Who's Clocked In`}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}
      {HAS_SettingMenu(currentUser) &&
      (HAS_DashboardRemainderAdd(currentUser) ||
        HAS_DashboardQuoteAdd(currentUser) ||
        HAS_AddTasks(currentUser) ||
        HAS_Timezone(currentUser) ||
        HAS_StatusOption(currentUser) ||
        HAS_Files(currentUser) ||
        HAS_MasqueradeView(currentUser) ||
        HAS_ClosingTime(currentUser)) ? (
        <AsideMenuItemWithSub
          to={`/${company}/settings`}
          title='Settings'
          icon='/media/icons/duotune/general/gen071.svg'
          fontIcon='bi-layers'
        >
          {HAS_DashboardMyCompany(currentUser) && (
            <AsideMenuItem
              to={`/${company}/settings/company/`}
              title='My Company'
              hasBullet={true}
            />
          )}
          {HAS_DashboardQuoteAdd(currentUser) && (
            <AsideMenuItem
              to={`/${company}/settings/add-quotes`}
              title='Add Quotes'
              hasBullet={true}
            />
          )}
          {HAS_AddTasks(currentUser) && (
            <AsideMenuItem to={`/${company}/settings/tasks`} title='Add Tasks' hasBullet={true} />
          )}
          {HAS_DashboardRemainderAdd(currentUser) && (
            <AsideMenuItem
              to={`/${company}/settings/add-reminder`}
              title='Add Reminder'
              hasBullet={true}
            />
          )}
          {HAS_Files(currentUser) && (
            <AsideMenuItem to={`/${company}/settings/files`} title='Files' hasBullet={true} />
          )}
          {HAS_MasqueradeView(currentUser) && (
            <AsideMenuItem
              to={`/${company}/settings/masquerade`}
              title='Masquerade'
              hasBullet={true}
            />
          )}
          {HAS_ClosingTime(currentUser) && (
            <AsideMenuItem
              to={`/${company}/settings/clock_out_time`}
              title='Closing Time'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}
    </>
  )
}
