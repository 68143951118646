/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  companyDetails,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  profileImg,
  updateCompany,
} from '../auth/core/_requests'
import * as Yup from 'yup'

import { GlobalForm, GlobalLable } from './GlobalForm'
import { ProfileContext } from '../../../_metronic/layout/MasterLayout'
import produce from 'immer'
import { useAuth } from '../auth'
import { LinearProgress } from '@mui/material'
const CompanyForm: React.FC = () => {
  const { currentUser } = useAuth()
  const [inputValue, setInputValue] = useState<any>(null)
  const profileContext: any = useContext(ProfileContext)
  const [data, setData] = useState<any>([])
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [loading, setLoading] = useState(false)
  const getList = () => {
    companyDetails()
      .then((res) => {
        setData(res?.data?.company_data || [])
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    getList()
  }, [])

  const companyValidation = Yup.object().shape({
    name: Yup.string().required('Company Name is required'),
    company_email: Yup.string().email('Wrong email format').required('Company Email is required'),
    // company_phone_number: Yup.mixed().required('Company Phone Number is required'),
    // company_address_line1: Yup.string().required('Company Address is required'),
    // company_address_line2: Yup.string().required('Company Address is required'),
    // company_city: Yup.string().required('Company City is required'),
    // company_state: Yup.string().required('Company State is required'),
    // company_zip_code: Yup.mixed().required('Company Zip Code is required'),
  })

  const initialValues = {
    logo: '',
    name: '',
    company_email: '',
    company_phone_number: '',
    company_address_line1: '',
    company_address_line2: '',
    company_city: '',
    company_state: '',
    company_zip_code: '',
  }
  const formik: any = useFormik({
    initialValues,
    validationSchema: companyValidation,
    onSubmit: (values) => {
      setLoading(true)
      let customer = {
        companyLogo: values.logo,
        companyName: values.name,
        company_email: values.company_email,
        company_phone_number: values.company_phone_number,
        company_address_line1: values.company_address_line1,
        company_address_line2: values.company_address_line2,
        company_city: values.company_city,
        company_state: values.company_state,
        company_zip_code: values.company_zip_code,
      }
      updateCompany(customer)
        .then(({ data }) => {
          profileContext?.state?.setProfilePic(
            produce((draft: any) => {
              draft.company = values?.name
              draft.company_logo = values?.logo
            })
          )
          setLoading(false)
          darkMessage('Company Details updated Successfully')
          formik.resetForm()
          window.location.href = `/${currnetCompanyName(data?.data?.company_name)}/dashboard`
        })
        .catch((e) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            setLoading(false)
          }
        })
    },
  })

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    formik.setFieldValue('company_phone_number', formattedPhoneNumber)
  }

  const setFieldValues = useCallback(() => {
    formik.setFieldValue('logo', data?.logo)
    formik.setFieldValue('name', data?.company_name)
    formik.setFieldValue('company_email', data?.company_email)
    formik.setFieldValue('company_phone_number', data?.company_phone_number)
    formik.setFieldValue('company_address_line1', data?.company_address_line1)
    formik.setFieldValue('company_address_line2', data?.company_address_line2)
    formik.setFieldValue('company_city', data?.company_city)
    formik.setFieldValue('company_state', data?.company_state)
    formik.setFieldValue('company_zip_code', data?.company_zip_file)
    setInputValue(data?.company_phone_number)
  }, [data])

  useEffect(() => {
    setFieldValues()
  }, [setFieldValues])

  const updateCompanyLogo = (event: any) => {
    const user_img = event.target.files
    setLoading(true)
    try {
      let filesToUpload: any = []
      let lengthOfFiles = Object.keys(user_img).length
      if (user_img != null || lengthOfFiles > 0) {
        let img_kb = user_img[0].size / 1024
        if (img_kb > 1024 * 2) {
          darkErrorMessage(`
          Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
              user_img[0]?.size /
              1024 /
              1024
            ).toFixed(2)}MB
         `)
          setLoading(false)
          return
        }
        Object.values(user_img).map((value: any) => {
          return filesToUpload.push(value)
        })
      } else {
        console.error('file is not selected')
        setLoading(false)
      }
      profileImg(filesToUpload, prefixCompany)
        .then((res) => {
          formik.setFieldValue('logo', res?.data?.user_pic)
          setLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }
  return (
    <>
      <div>
        <h1 className='p-7'>Company Details</h1>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap row col-sm-12 mb-3'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='row col-sm-12 m-5'>
                  <GlobalLable
                    classname={'col-lg-4 col-form-label  fw-bold fs-6'}
                    label={'Company Logo'}
                  />
                  <div className='col-lg-8 fv-row'>
                    {loading && <LinearProgress />}
                    <GlobalForm
                      classname={clsx('form-control form-control-lg form-control-solid')}
                      placeholder={'Enter Company Name here'}
                      inputType={'file'}
                      onChange={updateCompanyLogo}
                      customfield={undefined}
                    />
                  </div>
                </div>
                <div className='row col-sm-12 m-5'>
                  <GlobalLable
                    classname={'col-lg-4 col-form-label  fw-bold fs-6 required'}
                    label={'Company Name'}
                  />
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      required
                      placeholder='Company Name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className=' required col-lg-4 col-form-label fw-bold fs-6'>
                    Company Email
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      required
                      placeholder='Company email'
                      {...formik.getFieldProps('company_email')}
                    />
                    {formik.touched.company_email && formik.errors.company_email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_email}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='   col-lg-4 col-form-label fw-bold fs-6'>
                    Company Phone Number
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company phone number'
                      onChange={(e) => handleInput(e)}
                      value={inputValue}
                    />
                    {formik.touched.company_phone_number && formik.errors.company_phone_number && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_phone_number}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='   col-lg-4 col-form-label fw-bold fs-6'>
                    Company Address Line 1
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company Address 1'
                      {...formik.getFieldProps('company_address_line1')}
                    />
                    {formik.touched.company_address_line1 && formik.errors.company_address_line1 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_address_line1}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Company Address Line 2
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company Address 2'
                      {...formik.getFieldProps('company_address_line2')}
                    />
                    {formik.touched.company_address_line2 && formik.errors.company_address_line2 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_address_line2}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='   col-lg-4 col-form-label fw-bold fs-6'>Company City</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company City'
                      {...formik.getFieldProps('company_city')}
                    />
                    {formik.touched.company_city && formik.errors.company_city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_city}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='   col-lg-4 col-form-label fw-bold fs-6'>Company State</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company State'
                      {...formik.getFieldProps('company_state')}
                    />
                    {formik.touched.company_state && formik.errors.company_state && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_state}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row  col-sm-12 m-5'>
                  <label className='   col-lg-4 col-form-label fw-bold fs-6'>
                    Company Zip Code
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid '
                      placeholder='Company Zip Code'
                      {...formik.getFieldProps('company_zip_code')}
                    />
                    {formik.touched.company_zip_code && formik.errors.company_zip_code && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_zip_code}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-10 '></div>
                  <div className='col-sm-2 '>
                    <button
                      type='submit'
                      className='btn btn-primary m-5 w-90 '
                      disabled={loading || !formik.isValid}
                    >
                      {!loading && 'Save Changes'}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CompanyForm }
