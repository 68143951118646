/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  darkErrorMessage,
  darkMessage,
  deleteNotes,
  notes,
  viewQuote,
} from '../../../modules/auth/core/_requests'
import {QuotesModel} from '../../../modules/model/quotes'
import {useFormik} from 'formik'
import {
  Estimated_Notes,
  Estimated_Notes_Initial as initialValues,
} from '../../../modules/accounts/components/settings/SettingsModel'
import {EstimatedNotes} from '../../../modules/model/notes'
import ErrorAlert, {ErrorType} from '../../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../../modules/errors/SuccessAlert'
import {useAuth} from '../../../modules/auth'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import {EditNotes} from '../EditNotes'
import {HAS_QuoteAddNotes, HAS_WorkOrderAddNotes} from '../PermissionFunction'
export function Notes() {
  const [data, setData] = useState<any>([])
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [modal, setModalShow] = React.useState<any>(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const {type, id} = useParams<any>()
  var permissions = null
  const notesvalidation = Yup.object().shape({
    notes: Yup.string().required('Notes is required'),
  })

  const getList = useCallback(async (work_id: any) => {
    setLoading(true)
    await viewQuote(work_id)
      .then((res) => {
        let fields = res.data.notes as QuotesModel[]
        setData(fields)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getList(id)
  }, [getList, id])

  const formik = useFormik<Estimated_Notes>({
    initialValues,
    validationSchema: notesvalidation,
    onSubmit: (values) => {
      setLoading(true)
      let note_list: EstimatedNotes = {
        work_order: id,
        additional_information: values.notes,
        message: '',
        type: '',
      }

      notes(note_list)
        .then(({data}) => {
          // setSuccessBody(data as any)
          darkMessage('Notes Created Successfully')
          formik.resetForm()
          getList(id)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            // setErrorBody(e.response.data)
            setLoading(false)
          }
        })
    },
  })

  const edit_notes = useCallback((e: any) => {
    setModalShow({show: true, quote_id: id, notes_id: e.target.value})
    getList(id)
  }, [])

  const onModalClose = useCallback(() => {
    setModalShow({show: false, quote_id: '', notes_id: ''})
    getList(id)
  }, [])

  const delete_notes = (e: any) => {
    deleteNotes(id, e.target.value)
      .then((res) => {
        // setSuccessBody(res?.data as any)
        darkErrorMessage('Notes Deleted Successfully')
        formik.resetForm()
        getList(id)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          // setErrorBody(e.response.data)
          darkErrorMessage(e.response.data)
          setLoading(false)
        }
      })
  }
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onHide={() => setModalShow(false)}
        show={modal?.show}
        quote_id={modal?.quote_id}
        notes_id={modal?.notes_id}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <EditNotes
          onModalClose={onModalClose}
          show={modal?.show}
          quote_id={modal?.quote_id}
          notes_id={modal?.notes_id}
        />
      </Modal>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        {errorBody && <ErrorAlert error_response={errorBody} />}
        {successBody && <SuccessAlert success_response={successBody} />}
      </div>
      {type === 'quotes'
        ? (permissions = HAS_QuoteAddNotes(currentUser))
        : (permissions = HAS_WorkOrderAddNotes(currentUser))}
      <div className='card'>
        {permissions ? (
          <div className='card-body'>
            <h3 className='fw-bolder pb-5 border-bottom'>Estimated Notes</h3>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='row g-6 g-xl-9 mt-1'>
                <textarea
                  className='border-0  p-3 mb-5 bg-white'
                  placeholder='Enter your notes here'
                  rows={5}
                  cols={5}
                  readOnly={loading}
                  {...formik.getFieldProps('notes')}
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.notes}</div>
                  </div>
                )}
                <button
                  type='submit'
                  className='btn btn-primary col-lg-2'
                  disabled={loading || !formik.isValid}
                >
                  {!loading && 'Create Notes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='row g-6 g-xl-12 mt-3'>
        {data.map((value: any) => {
          return (
            <>
              <div
                className='shadow p-5 mb-5 '
                style={{borderLeft: '7px solid black', backgroundColor: '#', fontSize: '15px'}}
              >
                <p>
                  <strong>{moment(value.date_created).format('llll')}</strong>
                  <br />
                  <br />
                  {value?.message}
                </p>
                <hr
                  style={{borderTop: '1px solid grey', width: '97%', margin: 'auto'}}
                  className='m-5'
                />
                <div
                  className='row col-sm-12 text-center'
                  style={{justifyContent: 'space-between'}}
                >
                  <div className='col-sm-10'></div>
                  <div className='col-sm-1'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={edit_notes}
                      value={value?.id}
                    >
                      Edit
                    </button>
                  </div>
                  <div className='col-sm-1'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={delete_notes}
                      value={value?.id}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </>
  )
}
