import React, {useState, useCallback, useEffect, useContext} from 'react'
// import {BOMDataContext} from './BOMDataContext'
import {TextField, Button} from '@mui/material'
import {useParams, useLocation} from 'react-router-dom'
import {
  subQuote,
  darkMessage,
  darkErrorMessage,
  GetSubQuotes,
  currentlyClockIn,
  viewQuote,
  updateDescription,
} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'

const  SubQuoteDesc = ({getList, descModal, setDescModal, rowData}) => {
  const [quoteId, setQuoteId] = useState<any>([])
  const [subButton, setSubButton] = useState(false)
  const {id}: any = useParams<any>()
  const [formData, setFormData] = useState({
    label: '',
    description: rowData.description,
  })
  const [formErrors, setFormErrors] = useState({})

  const handleInputChange = (event) => {
    const {name, value} = event.target
    setFormData({...formData, [name]: value})
    setFormErrors({...formErrors, [name]: ''})
  }

  const getLists = useCallback(async () => {
    await viewQuote(id).then((res) => {
      let quo_id = res.data.customer_details as QuotesModel
      setQuoteId(quo_id.id)
    })
  }, [id])

  const handleSubmit = (event) => {
    event.preventDefault()
    updateDescription(rowData.id, formData.description)
      .then(({data}) => {
        darkMessage(data.message)
        setDescModal(false)
        getList()
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
      })
  }
  useEffect(() => {
    getLists()
  }, [getLists])
  return (
    <form>
      {/* <TextField
        name='label'
        label='Label'
        placeholder='Enter sub-quote label'
        value={formData.label}
        onChange={handleInputChange}
        fullWidth
        margin='normal'
      /> */}

      {subButton === false && (
        <div>
          <TextField
            name='description'
            label='Description'
            placeholder='Enter sub-quote description'
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            disabled
          />
          <button
            type='button'
            className='btn btn-secondary'
            style={{marginLeft: '60%', marginTop: '2%'}}
            onClick={() => setSubButton(true)}
          >
            Edit Description
          </button>
        </div>
      )}
      {subButton === true && (
        <div>
          <TextField
            name='description'
            label='Description'
            placeholder='Enter sub-quote description'
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
          />
          <button
            onClick={handleSubmit}
            className='btn btn-primary'
            style={{marginLeft: '60%', marginTop: '2%'}}
          >
            Update Description
          </button>
        </div>
      )}
    </form>
  )
}

export {SubQuoteDesc}
