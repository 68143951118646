/* eslint-disable react-hooks/exhaustive-deps */
import {Autocomplete, TextField} from '@mui/material'
import {useCallback, useEffect, useState} from 'react'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  assignNewTaskToEmp,
  darkErrorMessage,
  darkMessage,
  employeeList,
  currnetCompanyName,
} from '../../modules/auth/core/_requests'
import DatePicker from 'react-datepicker'
import EmpInterface from '../../modules/emp/EmpInterface'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {
  Task_To_Emp,
  Task_To_Emp_Initial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import moment from 'moment'
import {MomentDateFormat} from '../../context/TimeSheetConstant'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'

const taskValidationSchema = Yup.object().shape({
  task_title: Yup.string().required('Title Field is Required'),
  task_description: Yup.string().required('Task Description Field is Required'),
  task_end_date: Yup.mixed().required('End Date Field is Required'),
  task_selected_emp: Yup.mixed().required('Selection of Employee is Required'),
})

function AddTasks() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />
  const [empList, setEmpList] = useState<EmpInterface[]>([])
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const getList = useCallback(async () => {
    setLoading(true)
    await employeeList(1)
      .then((res) => {
        const employes = res.data.employees as EmpInterface[]
        setEmpList(employes)
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  const handleDateChange = useCallback((date: Date) => {
    setStartDate(date)
    const formattedDate = moment(date).format(MomentDateFormat)
    formik.setFieldValue('task_end_date', formattedDate)
  }, [])

  const handleEmpSelection = useCallback((idx: any) => {
    formik.setFieldValue('task_selected_emp', idx)
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const formik = useFormik<Task_To_Emp>({
    initialValues,
    validationSchema: taskValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      const createNewTask = {
        task_title: values?.task_title,
        task_description: values?.task_description,
        task_end_date: values?.task_end_date,
        task_selected_emp: JSON.stringify(values?.task_selected_emp),
      }
      assignNewTaskToEmp(createNewTask)
        .then(({data}) => {
          setLoading(false)
          formik.resetForm()
          setStartDate(new Date())
          darkMessage('Task assigned Successfully')
          navigate(`/${prefixCompany}/settings/tasks`)
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data.error_list[0])
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card mb-5 mb-xl-10 col-sm-12'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0 text-dark'>Create New Task</h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-sm-3'>
                <label className='col-form-label required fw-bold fs-6'>Employees</label>
              </div>
              <div className='col-sm-9'>
                <Autocomplete
                  multiple
                  id='checkboxes-tags-demo'
                  disableCloseOnSelect
                  options={empList}
                  onChange={(e, value) => {
                    return handleEmpSelection(value)
                  }}
                  getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
                  renderOption={(props, option, {selected}) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.first_name + ' ' + option?.last_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Please Select Employee'
                      placeholder='Employee List'
                    />
                  )}
                />
                {formik.touched.task_selected_emp && formik.errors.task_selected_emp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.task_selected_emp}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-sm-3'>
                <label className='col-form-label required fw-bold fs-6'>Title</label>
              </div>
              <div className='col-sm-9'>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  placeholder='Enter Title Here'
                  {...formik.getFieldProps('task_title')}
                />
                {formik.touched.task_title && formik.errors.task_title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.task_title}</div>
                  </div>
                )}
              </div>
            </div>{' '}
            <div className='row mb-6'>
              <div className='col-sm-3'>
                <label className='col-form-label required fw-bold fs-6'>Description</label>
              </div>
              <div className='col-sm-9'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  rows={7}
                  placeholder='Please Specify Description'
                  {...formik.getFieldProps('task_description')}
                />
                {formik.touched.task_description && formik.errors.task_description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.task_description}</div>
                  </div>
                )}
              </div>
            </div>{' '}
            <div className='row mb-6'>
              <div className='col-sm-3'>
                <label className='col-form-label required fw-bold fs-6'>Due Date</label>
              </div>
              <div className='col-sm-9'>
                <DatePicker
                  selected={startDate}
                  className='form-control form-control-lg form-control-solid'
                  onChange={(date: Date) => handleDateChange(date)}
                />
                {formik.touched.task_end_date && formik.errors.task_end_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.task_end_date}</div>
                  </div>
                )}
              </div>
            </div>{' '}
            <div className='my-2 text-end'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
export default AddTasks
