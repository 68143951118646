/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {
  Estimated_Notes,
  Estimated_Notes_Initial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import {
  darkErrorMessage,
  darkMessage,
  updateNotes,
} from '../../modules/auth/core/_requests'
import { EstimatedNotes } from '../../modules/model/notes'
export function EditNotes(props:any) {
  const [loading, setLoading] = useState(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const notes_validation = Yup.object().shape({
    notes: Yup.string().required(`Notes are required`),
  })
  const formik = useFormik<Estimated_Notes>({
    initialValues,
    validationSchema: notes_validation,
    onSubmit: (values) => {
      setLoading(true)
      let note_list: EstimatedNotes = {
        work_order: props?.quote_id,
        additional_information: values.notes,
        message: '',
        type: '',
        id: props?.notes_id,
      }
      updateNotes(note_list )
        .then(({data}) => {
          // setSuccessBody(data as any)
          darkMessage('Notes Updated Successfully')
          setLoading(false)
          formik.resetForm()
          props?.onModalClose()
        })
        .catch((e) => {
          if (e.response.status === 409) {
            // setErrorBody(e.response.data)
            darkErrorMessage(e.response.data)
            setLoading(false)
          }
        })
      
    },
  })

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2 className='fw-bolder m-2'>Update Your Notes</h2>
          <form onSubmit={formik.handleSubmit} noValidate className='form m-10'>
            <div className='row g-6 g-xl-9'>
              <input
                className='w-100  border  p-5 mb-2 bg-white rounded'
                type='text'
                placeholder='Enter Your Notes here'
                id=''
                {...formik.getFieldProps('notes')}
              />
              {formik.touched.notes && formik.errors.notes && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.notes}</div>
                </div>
              )}
              <button
                type='submit'
                className='btn btn-primary col-lg-2'
                disabled={loading || !formik.isValid}
              >
                {!loading && `Update notes`}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
          {errorBody && <ErrorAlert error_response={errorBody} />}
          {successBody && <SuccessAlert success_response={successBody} />}
        </div>
      </div>
    </>
  )
}
