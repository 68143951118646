/* eslint-disable react-hooks/rules-of-hooks */
import {toast} from 'react-toastify'
import axios from 'axios'
import {CustomerModel} from '../../model/customer'
import {InventoryModel} from '../../model/Inventory'
import {EstimatedNotes, VendorNotesInterface} from '../../model/notes'
import {PurchaseOrderModel} from '../../model/purchaseOrder'
import {getLocalUser, useAuth} from './Auth'
import {AuthModel, UserModel} from './_models'
import {useCallback, useEffect, useState} from 'react'
import {VendorInterface, Vendor_Notes} from '../../accounts/components/settings/SettingsModel'

export const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/user`
export const LOGIN_URL = `${API_URL}/api/login`
export const CREATE_COMPANY_URL = `${API_URL}/api/create-company`
export const REGISTER_URL = `${API_URL}/api/register`
export const MAIN_CLOCK_URL = `${API_URL}/api/timesheet`
export const MAIN_TIMESHEET_URL = `${API_URL}/api/timesheet/info`
export const TIMESHEET_MANAGER_LIST_URL = `${API_URL}/api/timesheetManager`
export const TIMESHEET_MANAGER_V2_LIST_URL = `${API_URL}/api/timesheetManager-v2`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/forgot_password`
export const UPDATE_USER_PASSWORD_URL = `${API_URL}/api/user/updatePassword`
export const UPDATE_USER_URL = `${API_URL}/api/user/update`
export const UPDATE_COMPANY_URL = `${API_URL}/api/company/update`
export const COMPANY_LIST_URL = `${API_URL}/api/company`
export const CHANGE_PASSWORD_URL = `${API_URL}/api/forgot-password/change-password`
export const INVITE_EMP_URL = `${API_URL}/api/invitations/send`
export const INVITE_CUSTOMER_URL = `${API_URL}/api/customer/create`
export const CREATE_FULL_VENDOR_DETAILS = `${API_URL}/inventory/api/vendor/create/new`
export const FETCH_SINGLE_VENDOR_DETAIL = `${API_URL}/inventory/api/inventory/fetch/vendor/single`
export const DELETE_SINGLE_VENDOR_URL = `${API_URL}/inventory/api/inventory/vendor/delete`
export const UPDATE_SINGLE_VENDOR_URL = `${API_URL}/inventory/api/inventory/vendor/update`
export const INVITE_FIELD_URL = `${API_URL}/api/fieldservice/create`
export const UPDATE_QUOTE_DUE_DATE_URL = `${API_URL}/api/quote/update/due_date`
export const GET_HOURS_USED_BY_DEPARTMENT_URL = `${API_URL}/api/hours_used/department`

export const VIEW_CUSTOMER_URL = `${API_URL}/api/customer/view`
export const UPDATE_CUSTOMER_URL = `${API_URL}/api/customer/edit`
export const CREATE_QUOTE_URL = `${API_URL}/api/create-quote`
export const CREATE_SUB_QUOTE_URL = `${API_URL}/api/create-subquote`
export const UPDATE_SUB_DESC_URL = `${API_URL}/api/update-description`
export const CREATE_ITEM_SER_URL = `${API_URL}/api/createItemService`
export const GET_SUB_QUOTE_URL = `${API_URL}/api/get-subquote`
export const GET_SUB_ITEM_SER_URL = `${API_URL}/api/getItemServices`
export const GET_DISCLAIMER = `${API_URL}/api/getDisclaimer`
export const GET_ITEM_TOGGLE = `${API_URL}/api/getItemToggle`
export const GET_SERVICE_TOGGLE = `${API_URL}/api/getServiceToggle`
export const GET_EDITED_DISCLAIMER = `${API_URL}/api/getEditedDisclaimer`
export const DUPLICATE_QUOTE_URL = `${API_URL}/api/duplicate-quote`
export const UPDATE_QUOTE_URL = `${API_URL}/api/quote/update`
export const UPDATE_SUBQUOTE_URL = `${API_URL}/api/quote/updateSubQuote`
export const UPDATE_FS_SUBQUOTE_URL = `${API_URL}/api/quote/updateSubQuoteFs`
export const UPDATE_SUBQUOTE_PROJECT_URL = `${API_URL}/api/quote/updateSubQuoteProject`
export const UPDATE_FIELD_SERVICE_URL = `${API_URL}/api/quote-field-service/update`
export const CUSTOMER_LIST_URL = `${API_URL}/api/customers`
export const CUSTOMER_QUOTE_LIST_URL = `${API_URL}/api/quotes/customers`
export const QUOTES_LIST_URL = `${API_URL}/api/quotes`
export const INVITE_NEW_FIELD_URL = `${API_URL}/api/fieldservice/createnew`

export const WORKORDER_LIST_URL = `${API_URL}/api/workorders`
export const FIELD_SERVICE_LIST_URL = `${API_URL}/api/fieldservice`
export const FIELD_SERVICE_URL = `${API_URL}/api/field`

export const SORT_LIST_URL = `${API_URL}/api/sort`

export const ESTIMATED_NOTES_URL = `${API_URL}/api/notes`
export const DELETE_NOTES_URL = `${API_URL}/api/notes/delete`
export const UPDATE_NOTES_URL = `${API_URL}/api/notes/update`
export const FILE_UPLOAD_URL = `${API_URL}/api/files-upload`
export const PROFILE_IMAGE_URL = `${API_URL}/api/register/profile_pic`
export const VIEW_QUOTE_URL = `${API_URL}/api/quote-view`
export const CUSTOMER_VIEW_QUOTE_URL = `${API_URL}/api/` //add url here---
export const CLOCK_IN_OUT_URL = `${API_URL}/api/clock_in_out`
export const ACCEPT_INVITE_EMP = `${API_URL}/api/invitations/accept`
export const VENDOR_LIST_URL = `${API_URL}/inventory/ajax/vendor-autocomplete`
export const SHIPPINGTYPE_LIST_URL = `${API_URL}/inventory/ajax/shipping-type-autocomplete`
export const INTRANSISIT_PURCHASE_LIST = `${API_URL}/inventory/api/purchase_order/intransit_json`
export const RECEIVED_PURCHASE_LIST = `${API_URL}/inventory/api/purchase_order/received_json`
export const EMP_LIST_URL = `${API_URL}/api/employees`
export const MANU_LIST_URL = `${API_URL}/api/manufacturer/employee`

export const CREATE_VENDOR_URL = `${API_URL}/inventory/api/vendor/create`
export const CREATE_BRAND_URL = `${API_URL}/inventory/api/brand/create`
export const CREATE_CATEGORY_URL = `${API_URL}/inventory/api/category/create`
export const CREATE_SUBCATEGORY_URL = `${API_URL}/inventory/api/subcategory/create`
export const CREATE_SHIPPING_URL = `${API_URL}/inventory/api/shipping/create`
export const CREATE_INVENTORY_URL = `${API_URL}/inventory/api/inventory/create`
export const PREFERRED_VENDOR_INVENTORY_URL = `${API_URL}/inventory/api/inventory/update/preferred_vendor`
export const INVENTORY_LIST_URL = `${API_URL}/inventory/api/inventory/view`
export const INVENTORY_LIST_FOR_PO = `${API_URL}/inventory/api/inventory/po/view-list`
export const INVENTORY_SEARCH_LIST_FOR_PO = `${API_URL}/inventory/api/inventory/po/search/view-list`

export const INVENTORY_LIST_DATA_URL = `${API_URL}/inventory/api/inventory/view/data`
export const INVENTORY_LIST_BRAND_URL = `${API_URL}/inventory/api/inventory/fetch/brands`
export const INVENTORY_LIST_CATEGORY_URL = `${API_URL}/inventory/api/inventory/fetch/categories`
export const INVENTORY_LIST_VENDOR_URL = `${API_URL}/inventory/api/inventory/fetch/vendors`
export const INVENTORY_LIST_SHIPPING_METHODS_URL = `${API_URL}/inventory/api/inventory/fetch/shippingMethods`

export const UPDATE_INVENTORY_URL = `${API_URL}/inventory/api/inventory/update`
export const DELETE_INVENTORY_ITEM = `${API_URL}/inventory/api/inventory/delete`
export const INVENTORY_ITEM_VIEW_URL = `${API_URL}/inventory/api/inventory-item/view`

export const CREATE_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/create`
export const UPDATE_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/update/po`

export const CREATE_RMA_ORDER_URL = `${API_URL}/inventory/api/return-order/create`
export const PURCHASE_ORDER_LIST_URL = `${API_URL}/inventory/api/purchase-order/view`
export const RECEIVED_PURCHASE_ORDER_LIST_URL = `${API_URL}/inventory/api/purchase-order/received/view`
export const RETURN_ORDER_LIST_URL = `${API_URL}/inventory/api/return-order/view`
export const UPLOAD_FILES_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/files/upload`
export const FETCH_FILES_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/files/view`
export const UPDATE_FILES_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/files/update`
export const DELETE_FILES_PURCHASE_ORDER_URL = `${API_URL}/inventory/api/purchase-order/files/delete`

export const PURCHASE_ORDER_ITEM_VIEW_URL = `${API_URL}/inventory/api/purchase-order/order/view`
export const CURRENTLY_CLOCK_IN = `${API_URL}/api/current_clock_in`
export const CREATE_REMAINDER_URL = `${API_URL}/api/remainderNotification`
export const UPDATE_ROLE_URL = `${API_URL}/api/update-role`
export const GET_PERMISSIONS_OF_ROLES_URL_LIST = `${API_URL}/api/get_roles_permissions`
export const GET_REMAINDER_LIST_URL = `${API_URL}/api/get/remainderNotification`
export const DELETE_QUOTE_URL = `${API_URL}/api/quote/delete`
export const DELETE_SUBQUOTE_URL = `${API_URL}/api/subquote/delete`
export const DELETE_ITEM_URL = `${API_URL}/api/deleteItem`
export const DELETE_SERVICE_URL = `${API_URL}/api/deleteServices`
export const UPDATE_ITEM_URL = `${API_URL}/api/subquote-item/update`
export const UPDATE_SERVICE_URL = `${API_URL}/api/subquote-service/update`
export const UPDATE_CUSTOMER_CONTACT_URL = `${API_URL}/api/customer/primary/update`

export const DELETE_CUSTOMER_URL = `${API_URL}/api/customer/delete`
export const DELETE_PO_URL = `${API_URL}/inventory/api/purchase-order/delete`
export const UPDATE_PERMISSIONS_URL = `${API_URL}/api/update-permission`
export const PO_UPDATE_URL = `${API_URL}/inventory/api/purchase-order/update`
export const UPDATE_QUOTE_LABEL_URL = `${API_URL}/api/quote/label/update`
export const PERTICULAR_QUOTE_USER_TIMESHEET_URL = `${API_URL}/api/timesheet/data`
export const DELETE_PERTICULAR_TIMESHEET_URL = `${API_URL}/api/timesheet/data/delete`
export const UPDATE_TIMESHEET_URL = `${API_URL}/api/timesheet/update`
export const ADD_TIMESTAMP_URL = `${API_URL}/api/timesheet/create`
export const QUOTE_NOTES_URL = `${API_URL}/api/quote/notes`
export const QUOTE_DISCLAIMER_URL = `${API_URL}/api/quote/disclaimer`
export const DELETE_EMPLOYEE_URL = `${API_URL}/api/employee/delete`
export const INACTIVE_EMPLOYEE_LIST_URL = `${API_URL}/api/employees/inactive`
export const DELETE_INACTIVE_EMPLOYEE_URL = `${API_URL}/api/employees/inactive/delete`
export const ALL_CLOCKED_IN_URL = `${API_URL}/api/current_clock_in_all`
export const UPDATE_TIMEZONE_URL = `${API_URL}/api/company/timezone`
export const CUSTOMER_ACTIVITIES_LIST_URL = `${API_URL}/api/customer/activity`
export const QUOTE_ACTIVITY_UPDATE_URL = `${API_URL}/api/activity/update`
export const UPDATE_CUSTOMER_STATUS_URL = `${API_URL}/api/customer/update/status`
export const COMPANY_FILES_LIST_URL = `${API_URL}/api/files/fetch`
export const UPDATE_QUOTE_DATA_URL = `${API_URL}/api/quote/update/data`
export const PO_UPDATE__RECEIVED_URL = `${API_URL}/inventory/api/purchase-order/update/received/po`
export const ITEMS_ASSIGNED_WRT_ITEM_URL = `${API_URL}/inventory/api/inventory/fetch/item/item_assigned`
export const ITEMS_ACTIVITY_WRT_ITEM_URL = `${API_URL}/inventory/api/inventory/fetch/item/item_activity`
export const CREATE_ITEM_ACTIVITY_WRT_ITEM_URL = `${API_URL}/inventory/api/inventory/create/item/item_activity`
export const UPDATE_ITEM_ACTIVITY_WRT_ITEM_URL = `${API_URL}/inventory/api/inventory/update/item/item_activity`

// order status
export const QUOTE_STATUS_ADD_URL = `${API_URL}/api/status/add`
export const QUOTE_STATUS_DELETE_URL = `${API_URL}/api/status/delete`
export const QUOTE_STATUS_FETCH_URL = `${API_URL}/api/status/fetch`
export const FETCH_ALL_STATUSES_URL = `${API_URL}/api/status/all/fetch`
export const QUOTE_STATUS_VIEW_URL = `${API_URL}/api/status/view`
export const QUOTE_STATUS_UPDATE_URL = `${API_URL}/api/status/edit`
export const GET_FILTERED_STATUS_URL = `${API_URL}/api/status/filter`
export const UPDATE_STATUS_SORTING = `${API_URL}/api/status/update/sorting`
export const LIFETIME_LIST_URL = `${API_URL}/api/company/customer_lifespan`
export const LIFETIME_ITEMLIST_URL = `${API_URL}/api/company/item_lifespan`
export const SET_COMPANY_CLOCK_OUT_URL = `${API_URL}/api/company/automatic_clock_out`
export const FETCH_ALL_WORK_ORDERS = `${API_URL}/api/all-work-order/fetch`
export const ASSIGNED_TO_URL_QUOTES = `${API_URL}/api/quote/update/assigned-to`
export const FETCH_ASSIGNED_TO_URL_QUOTES = `${API_URL}/api/quote/fetch/assigned-to`
export const PURCHASE_ORDER_WORKORDER_CUSTOMERS_URL = `${API_URL}/api/purchase-order/work_order/customers`
// quote files
export const QUOTE_FILE_DELETE_URL = `${API_URL}/api/file/delete`
export const QUOTE_FILE_UPDATE_URL = `${API_URL}/api/file/edit`
export const CAMPAIGNS_LIST_URL = `${API_URL}/api/campaigns`

// departments (Roles And Permissions)
export const ADD_DEPARTMENT_URL = `${API_URL}/api/role/add`
export const DELETE_DEPARTMENT_URL = `${API_URL}/api/role/delete`
export const UPDATE_DEPARTMENT_URL = `${API_URL}/api/role/update`
export const VIEW_DEPARTMENT_URL = `${API_URL}/api/role/view`

// Services (Name and Rates for Quotes)
export const CREATE_NEW_SERVICE_URL = `${API_URL}/api/services/create`
export const FETCH_ALL_SERVICES_URL = `${API_URL}/api/services/fetch`
export const DELETE_SPECIFIC_SERVICE_URL = `${API_URL}/api/services/delete`
export const UPDATE_SPECIFIC_SERVICE_URL = `${API_URL}/api/services/update`

// Update Dashboard Quote of the Month
export const UPDATE_DASHBOARD_MONTH_QUOTE_URL = `${API_URL}/api/setting/update/quote_of_the_month`

// vendor login credentials
export const ADD_CREDENTIALS_VENDOR_URL = `${API_URL}/inventory/api/vendor/credentials/add`
export const FETCH_CREDENTIALS_VENDOR_URL = `${API_URL}/inventory/api/vendor/credentials/fetch`
export const UPDATE_CREDENTIALS_VENDOR_URL = `${API_URL}/inventory/api/vendor/credentials/update`
export const DELETE_CREDENTIALS_VENDOR_URL = `${API_URL}/inventory/api/vendor/credentials/delete`
export const DELETE_PURCHASE_ORDER_FOR_ASSIGNEE_URL = `${API_URL}/inventory/api/purchase-order/for/delete`

// vendor login credentials
export const ADD_ASSIGNED_TO_ITEMS_URL = `${API_URL}/inventory/api/assigned-to/create`
export const FETCH_ASSIGNED_TO_ITEMS_URL = `${API_URL}/inventory/api/assigned-to/fetch`
export const FETCH_SUBQUOTE_ASSIGNED_TO_ITEMS_URL = `${API_URL}/api/get-subquoteItems`
export const FETCH_DUMMY_ASSIGNED_TO_ITEMS_URL = `${API_URL}/inventory/api/assigned-to/dummy/fetch`
export const DELETE_ASSIGNED_TO_ITEMS_URL = `${API_URL}/inventory/api/assigned-to/delete`
export const UPDATE_ASSIGNED_TO_ITEMS_URL = `${API_URL}/inventory/api/assigned-to/update`

// vendor login credentials
export const FETCH_PURCHASE_ORDER_NOTES_URL = `${API_URL}/inventory/api/purchase-order/notes/fetch`
export const CREATE_PURCHASE_ORDER_NOTES_URL = `${API_URL}/inventory/api/purchase-order/notes/create`
export const UPDATE_PURCHASE_ORDER_NOTES_URL = `${API_URL}/inventory/api/purchase-order/notes/update`
export const DELETE_PURCHASE_ORDER_NOTES_URL = `${API_URL}/inventory/api/purchase-order/notes/delete`
export const UPDATE_PURCHASE_ORDER_FOR_FIELD_URL = `${API_URL}/inventory/api/purchase-order/for/update`
export const FETCH_PURCHASE_ORDER_HISTORY_INVENTORY_URL = `${API_URL}/inventory/api/inventory/fetch/purchase_history`
export const FETCH_PURCHASE_ORDER_VENDOR_URL = `${API_URL}/inventory/api/vendor/fetch/po`
export const VENDOR_LIST_NAMES_ONLY_URL = `${API_URL}/inventory/api/inventory/fetch/vendors_list`
export const WORK_ORDER_PURCHASE_ORDER_URL = `${API_URL}/api/work-order/purchase-order-tab`
// vendor notes credentials
export const ADD_NOTES_VENDOR_URL = `${API_URL}/inventory/api/vendor/notes/add`
export const FETCH_NOTES_VENDOR_URL = `${API_URL}/inventory/api/vendor/notes/fetch`
export const UPDATE_NOTES_VENDOR_URL = `${API_URL}/inventory/api/vendor/notes/update`
export const DELETE_NOTES_VENDOR_URL = `${API_URL}/inventory/api/vendor/notes/delete`

// Work Order Tag Functionality
export const CREATE_WORK_ORDER_TAGS_URL = `${API_URL}/api/quote/tags/create`
export const DELETE_WORK_ORDER_TAGS_URL = `${API_URL}/api/quote/tags/delete`
export const UPDATE_WORK_ORDER_TAGS_URL = `${API_URL}/api/quote/tags/update`
export const FETCH_WORK_ORDER_TAGS_URL = `${API_URL}/api/quote/tags/fetch`
export const ALL_FETCH_WORK_ORDER_TAGS_URL = `${API_URL}/api/quote/tags/fetch/all`
// TAGS for Quotes/Project and Field Service
export const FETCH_TAGS_FOR_WORK_ORDERS = `${API_URL}/api/quote/fetch/tags`
export const UPDATE_TAGS_FOR_WORK_ORDERS = `${API_URL}/api/quote/update/tags`

// Shipping method ( Inventory )
export const CREATE_SHIPPING_METHOD_URL = `${API_URL}/inventory/api/shipping/create`
export const FETCH_ALL_SHIPPING_METHODS_URL = `${API_URL}/inventory/api/shipping/fetch`
export const UPDATE_SPECIFIC_SHIPPING_METHOD_URL = `${API_URL}/inventory/api/shipping/update`
export const DELETE_SPECIFIC_SHIPPING_MEHTOD_URL = `${API_URL}/inventory/api/shipping/delete`
export const NEED_TO_ORDER_INVENTORY_URL = `${API_URL}/inventory/api/inventory/need-to-order`

// assigns tasks to employess api
export const CREATE_NEW_TASK_URL = `${API_URL}/api/task/create`
export const FETCH_ALL_TASKS_URL = `${API_URL}/api/task/fetch`

// For masquerade
export const MASQUERADE_LIST_URL = `${API_URL}/api/database`
export const USER_MASQUERADE_URL = `${API_URL}/api/console/database`

// For Inventory Category Update and Delete
export const UPDATE_INVENTORY_CATEGORY_URL = `${API_URL}/inventory/api/category/update`
export const DELETE_INVENTORY_CATEGORY_URL = `${API_URL}/inventory/api/category/delete`
export const UPDATE_INVENTORY_BRAND_URL = `${API_URL}/inventory/api/brand/update`
export const DELETE_INVENTORY_BRAND_URL = `${API_URL}/inventory/api/brand/delete`
export const INVENTORY_ASSOCIATED_ITEMS_URL = `${API_URL}/inventory/api/inventory/assign/associated_items`
export const INVENTORY_SUBSTITUTES_URL = `${API_URL}/inventory/api/inventory/assign/substitutes`
export const FETCH_CUSTOMER_FILES = `${API_URL}/api/customer/file/fetch`
export const SUB_QUOTES_WORK_ORDER_URL = `${API_URL}/api/quote/sub_quotes`

//For Dashboard card data
export const DASHBOARD_DATA_URL = `${API_URL}/api/dashboard/card-data`
export const FETCH_NEXT_QUOTE = `${API_URL}/api/fetch/next_quote`

// temp files APIs
export const SAVE_TEMP_PDF_FILES = `${API_URL}/inventory/api/purchase-order/download/temp_files`
export const SAVE_COMPANY_LOGO_PDF_FILES = `${API_URL}/inventory/api/company_logo/download/temp_files`
export const DELETE_TEMP_PDF_FILES = `${API_URL}/inventory/api/purchase-order/delete/temp_files`
export const LOGIN_USER_BY_EMAIL_URL = `${API_URL}/api/database/login`
export const DUMMY_REST_API = `https://jsonplaceholder.typicode.com/todos/1`

export const SUB_QUOTES_PDF_GENERATION = `${API_URL}/api/quote/subquote/generate_pdf`
export const SERVICES_CSV_GENERATOR_URL = `${API_URL}/api/services/generate/csv`
const getBearerHeader = (token: string) => {
  return {
    headers: {Authorization: `Bearer ${token}`},
  }
}

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export async function createCompany(companyName: string, access: string) {
  let formData = new FormData()
  formData.append('company_name', companyName)
  return axios.post<AuthModel>(CREATE_COMPANY_URL, formData, getBearerHeader(access))
}

// Server should return AuthModel
export function register(
  company_name: string,
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  img_url: any
) {
  return axios.post(REGISTER_URL, {
    company_name,
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
    img_url,
  })
}

// Server should return AuthModel
export function registerInvite(
  file: any,
  token: string,
  id: number,
  password: string,
  password_confirmation: string
) {
  let formData = new FormData()
  formData.append('profile_pic', file)
  formData.append('token', token)
  formData.append('id', id.toString())
  formData.append('password', password)
  return axios.post(ACCEPT_INVITE_EMP, formData)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  let formData = new FormData()
  formData.append('email', email)
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, formData)
}

export async function updatePassword(password: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('new_password', password)
  return axios.post<{result: boolean}>(
    UPDATE_USER_PASSWORD_URL,
    formData,
    getBearerHeader(user.access)
  )
}

// Server should return object => { result: boolean } (Is Email in DB)
export function changePassword(password: string, token: any, id: any) {
  let formData = new FormData()
  formData.append('password', password)
  formData.append('token', token)
  formData.append('id', id)
  return axios.post<{result: boolean}>(CHANGE_PASSWORD_URL, formData)
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {}, getBearerHeader(token))
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function inviteUser(
  fullname: string,
  pin: string,
  email: string,
  role: string,
  phone: number | any,
  title: string | any,
  birthday: any,
  anniversary: any,
  hometown: string
) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('emp_name', fullname)
  formData.append('emp_email', email)
  formData.append('emp_pin', pin)
  formData.append('emp_role', role)
  formData.append('emp_phone', phone)
  formData.append('emp_title', title)
  formData.append('emp_birthday', birthday)
  formData.append('emp_anniversary', anniversary)
  formData.append('emp_hometown', hometown)
  return axios.post<{result: boolean}>(INVITE_EMP_URL, formData, getBearerHeader(user.access))
}

export async function createBrand(inventory_info: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('brand_name', inventory_info)
  return axios.post<{result: boolean}>(CREATE_BRAND_URL, formData, getBearerHeader(user.access))
}

export async function createRemainder(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('from_date', data.from_date)
  formData.append('to_date', data.to_date)
  formData.append('title', data.title)
  formData.append('status', data.status)
  return axios.post<{result: boolean}>(CREATE_REMAINDER_URL, formData, getBearerHeader(user.access))
}

export async function createCategory(inventory_info: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('category_name', inventory_info)
  return axios.post<{result: boolean}>(CREATE_CATEGORY_URL, formData, getBearerHeader(user.access))
}

export async function createSubcategory(inventory_info: string, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('category_id', id)
  formData.append('subcateory_name', inventory_info)
  return axios.post<{result: boolean}>(
    CREATE_SUBCATEGORY_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function createShippingType(inventory_info: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('shipping_type', inventory_info)
  return axios.post<{result: boolean}>(CREATE_SHIPPING_URL, formData, getBearerHeader(user.access))
}

export async function createVendor(inventory_info: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_name', inventory_info)
  return axios.post<{result: boolean}>(CREATE_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function createInventoryItem(item: InventoryModel) {
  let user = await getLocalUser()
  let formData = new FormData()
  let data = [item.uploadImage || []]
  data.forEach((form: any) => {
    formData.append('ImageFiles', form[0] as Blob)
  })
  formData.append('manufaturar_part_no', item.sku)
  formData.append('item_name', item.name)
  formData.append('manufacturar', item.brand_id)
  formData.append('item_description', item.item_description)
  formData.append('category', item.category_id)
  formData.append('vendor', item.vendor_id)
  formData.append('order_link', item.order_link)
  formData.append('item_cost', item.item_cost)
  formData.append('retail_price', item.retail_price)
  formData.append('available_stock', item.available_stock)
  formData.append('qty_in_store', item.qty_in_store || 0)
  formData.append('maintain_stock', item.maintain_stock)
  formData.append('reorder_level', item.reorder_level)
  formData.append('inventory_location', item.inventory_location)
  return axios.post<{result: boolean}>(CREATE_INVENTORY_URL, formData, getBearerHeader(user.access))
}

export async function preferred_vendor_inventory_item(item: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('inventory_id', item.inventory_id)
  formData.append('vendor_id', item.vendor_id)
  return axios.post<{result: boolean}>(
    PREFERRED_VENDOR_INVENTORY_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function createPurchaseOrder(po: PurchaseOrderModel) {
  let user = await getLocalUser()
  let formData: any = new FormData()
  formData.append('items_info', po.items_info)
  formData.append('vendor_order', po?.vendor_id)
  formData.append('for_assignee', po?.for_assignee)
  formData.append('date_ordered', po.date_ordered)
  formData.append('shipping_method_id', po.shipping_method_id)
  formData.append('expected_delivery', po?.expected_delivery)
  formData.append('tracking_number', po.tracking_number)
  formData.append('order_subtotal', po.order_subtotal)
  formData.append('shipping_cost', po?.shipping_cost)
  formData.append('tax', po?.tax)
  formData.append('total_cost', po?.total_cost)
  formData.append('order_status', po?.order_status)
  formData.append('overall_quantity', po.overall_quantity)
  Object.keys(po?.attached_files).forEach((form: any) => {
    formData.append('attached_files', po?.attached_files[form] as Blob)
  })
  formData.append('attached_notes', po?.attached_notes)
  formData.append('vendor_ordernumber', po?.vendor_ordernumber)
  return axios.post<{result: boolean}>(
    CREATE_PURCHASE_ORDER_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function updatePurchaseOrder(po: PurchaseOrderModel) {
  let user = await getLocalUser()
  let formData: any = new FormData()
  formData.append('po_id', po.po_id)
  formData.append('items_info', po.items_info)
  formData.append('vendor_order', po?.vendor_id)
  formData.append('for_assignee', po?.for_assignee)
  formData.append('date_ordered', po.date_ordered)
  formData.append('shipping_method_id', po.shipping_method_id)
  formData.append('expected_delivery', po?.expected_delivery)
  formData.append('tracking_number', po.tracking_number)
  formData.append('order_subtotal', po.order_subtotal)
  formData.append('shipping_cost', po?.shipping_cost)
  formData.append('tax', po?.tax)
  formData.append('total_cost', po?.total_cost)
  formData.append('order_status', po?.order_status)
  formData.append('overall_quantity', po.overall_quantity)
  formData.append('vendor_ordernumber', po?.vendor_ordernumber)
  return axios.post<{result: boolean}>(
    UPDATE_PURCHASE_ORDER_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function createReturnOrder(rma: PurchaseOrderModel) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('items_info', rma.items_info)
  formData.append('vendor', rma.vendor_id)
  formData.append('shipping_method', rma?.shipping_method_id)
  formData.append('total_cost', rma.total_cost)
  formData.append('tracking_number', rma.tracking_number)
  formData.append('date_ordered', rma.date_ordered)
  return axios.post<{result: boolean}>(CREATE_RMA_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function updateInventoryItem(item: InventoryModel, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  let data = [item.uploadImage]
  data.forEach((form: any) => {
    formData.append('ImageFiles', form[0] as Blob)
  })
  formData.append('manufacturar', item.brand_id)
  formData.append('manufaturar_part_no', item.sku)
  formData.append('item_name', item.name)
  formData.append('item_description', item.item_description)
  formData.append('category', item.category_id)
  formData.append('vendor', item.vendor_id)
  formData.append('order_link', item.order_link)
  formData.append('item_cost', item.item_cost)
  formData.append('retail_price', item.retail_price)
  formData.append('available_stock', item.available_stock)
  formData.append('qty_in_store', item.qty_in_store)
  formData.append('maintain_stock', item.maintain_stock)
  formData.append('reorder_level', item.reorder_level)
  formData.append('inventory_location', item.inventory_location)
  return axios.post<{result: boolean}>(UPDATE_INVENTORY_URL, formData, getBearerHeader(user.access))
}

export async function deleteInventoryItem(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<{result: boolean}>(
    DELETE_INVENTORY_ITEM,
    formData,
    getBearerHeader(user.access)
  )
}

// Creating new user from create-customer page
export async function createCustomer(customer: CustomerModel) {
  let user = await getLocalUser()
  let formData = new FormData()
  let pic = customer.profile_pic
  formData.append('cstmr_Fname', customer.first_name)
  formData.append('cstmr_Lname', customer.last_name)
  formData.append('cstmr_Cname', customer.company)
  formData.append('cstmr_Cphone', customer.company_phone)
  formData.append('cstmr_Email', customer.email)
  formData.append('cstmr_Phone', customer.phone)
  formData.append('cstmr_Address', customer.address)
  formData.append('cstmr_City', customer.city)
  formData.append('cstmr_State', customer.state)
  formData.append('cstmr_Zipcode', customer.zip_code)
  formData.append('cstmr_Cmethod', customer.contact_method)
  formData.append('cstmr_Dtype', customer.discount_type)
  formData.append('cstmr_Rtype', customer.referral_type)
  formData.append('notes', customer.notes)
  formData.append('fieldArr', JSON.stringify(customer.fieldArr))
  formData.append('address_line_2', customer.address_line_2)
  formData.append('billing_address_1', customer.billing_address_1)
  formData.append('billing_address_2', customer.billing_address_2)
  formData.append('billing_city', customer.billing_city)
  formData.append('billing_state', customer.billing_state)
  formData.append('billing_zip_code', customer.billing_zip_code)
  formData.append('website', customer.website)
  formData.append('cus_pros', customer.cus_pros)
  formData.append('profile_pic', pic)
  // company companyphone email phone address city state contactmethod discount refer
  return axios.post<{result: boolean}>(INVITE_CUSTOMER_URL, formData, getBearerHeader(user.access))
}

export async function createFullVendor(vendor: VendorInterface) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_name', vendor.vendor_name)
  formData.append('contacts_field', JSON.stringify(vendor.contacts_field))
  formData.append('address_line_1', vendor.address_line_1)
  formData.append('address_line_2', vendor.address_line_2)
  formData.append('city', vendor.city)
  formData.append('state', vendor.state)
  formData.append('zip', vendor.zip)
  formData.append('website', vendor.website)
  formData.append('terms', vendor.terms)
  formData.append('notes', vendor.notes)
  formData.append('account_id', vendor?.['vendor_account#'])
  if (vendor.image) {
    formData.append('profile_pic', vendor.image)
  }
  return axios.post<{result: boolean}>(
    CREATE_FULL_VENDOR_DETAILS,
    formData,
    getBearerHeader(user.access)
  )
}

export async function updateInventoryVendor(id: string, vendor: VendorInterface) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('account_id', vendor?.['vendor_account#'])
  formData.append('vendor_name', vendor.vendor_name)
  formData.append('contacts_field', JSON.stringify(vendor.contacts_field))
  formData.append('address_line_1', vendor.address_line_1)
  formData.append('address_line_2', vendor.address_line_2)
  formData.append('city', vendor.city)
  formData.append('state', vendor.state)
  formData.append('zip', vendor.zip)
  formData.append('website', vendor.website)
  formData.append('terms', vendor.terms)
  formData.append('notes', vendor.notes)
  if (vendor.image) {
    formData.append('profile_pic', vendor.image)
  }
  return axios.post<{result: boolean}>(
    UPDATE_SINGLE_VENDOR_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function fetchSingleVendor(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<{result: boolean}>(
    FETCH_SINGLE_VENDOR_DETAIL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function deleteSingleInventoryVendor(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<{result: boolean}>(
    DELETE_SINGLE_VENDOR_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function CreateFieldService(field: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('Customer', field.customer_name)
  formData.append('Call', field.emergency_call)
  formData.append('Service', field.service_requested)
  formData.append('Need', field.need)
  formData.append('Frame', field.frame)
  formData.append('Additional', field.additional_details)
  // company companyphone email phone address city state contactmethod discount refer
  return axios.post<{result: boolean}>(INVITE_FIELD_URL, formData, getBearerHeader(user.access))
}

export async function updateCustomer(customer: CustomerModel, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_id', id)
  const pic = customer.profile_pic
  if (pic) {
    formData.append('profile_pic', pic as Blob)
  }
  formData.append('cstmr_Fname', customer.first_name)
  formData.append('cstmr_Lname', customer.last_name)
  formData.append('cstmr_Cname', customer.company)
  formData.append('cstmr_Cphone', customer.company_phone)
  formData.append('cstmr_Email', customer.email)
  formData.append('cstmr_Phone', customer.phone)
  formData.append('cstmr_Address', customer.address)
  formData.append('cstmr_City', customer.city)
  formData.append('cstmr_State', customer.state)
  formData.append('cstmr_Zipcode', customer.zip_code)
  formData.append('cstmr_Cmethod', customer.contact_method)
  formData.append('cstmr_Dtype', customer.discount_type)
  formData.append('cstmr_Rtype', customer.referral_type)
  formData.append('notes', customer.notes)
  formData.append('fieldArr', JSON.stringify(customer.fieldArr))
  formData.append('address_line_2', customer.address_line_2)
  formData.append('billing_address_1', customer.billing_address_1)
  formData.append('billing_address_2', customer.billing_address_2)
  formData.append('billing_city', customer.billing_city)
  formData.append('billing_state', customer.billing_state)
  formData.append('billing_zip_code', customer.billing_zip_code)
  formData.append('website', customer.website)
  formData.append('cus_pros', customer.cus_pros)
  return axios.post<{result: boolean}>(UPDATE_CUSTOMER_URL, formData, getBearerHeader(user.access))
}

export async function updateCustomerContactUrl(customer: CustomerModel, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_id', id)
  formData.append('fieldArr', JSON.stringify(customer))
  return axios.post<{result: boolean}>(
    UPDATE_CUSTOMER_CONTACT_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function CreateNewFieldServiceForm(field: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('Customer Name', field.customer_name)
  formData.append('Emergency Call', field.emergency_call)
  formData.append('Service Requested', field.service_requested)
  formData.append(' How soon does this need to be done?', field.need_soon)
  formData.append(' What time frame does our tech need to arrive?', field.time_frame)
  formData.append('Additional Details', field.additional_details)
  // company companyphone email phone address city state contactmethod discount refer
  return axios.post<{result: boolean}>(INVITE_NEW_FIELD_URL, formData, getBearerHeader(user.access))
}

export async function updateUser(data: any = []) {
  let user = await getLocalUser()
  let formData = new FormData()
  let pic = data?.avatar
  if (pic != null) {
    formData.append('profile_pic', pic)
  }
  formData.append('first_name', data?.first_name)
  formData.append('last_name', data?.last_name)
  formData.append('user_type', data?.user_type)
  formData.append('email', data?.email)
  formData.append('phone_number', data?.phone_number)
  return axios.post<{result: boolean}>(UPDATE_USER_URL, formData, getBearerHeader(user.access))
}

export async function clockInOut(data: any, type: any, additional_notes: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('quote_id', data)
  formData.append('type', type)
  formData.append('additional_notes', additional_notes)
  return axios.post<any>(CLOCK_IN_OUT_URL, formData, getBearerHeader(user.access))
}

export async function ShopTimeClockIN(type: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('type', type)
  return axios.post<any>(CLOCK_IN_OUT_URL, formData, getBearerHeader(user.access))
}
export async function timesheetManager(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('permission', data.permission)
  formData.append('from_date', data.from_date)
  formData.append('to_date', data.to_date)
  formData.append('search', data.search)
  return axios.post<any>(TIMESHEET_MANAGER_LIST_URL, formData, getBearerHeader(user.access))
}

export async function timesheetManagerV2(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('permission', data.permission)
  formData.append('from_date', data.from_date)
  formData.append('to_date', data.to_date)
  formData.append('search', data.search)
  return axios.post<any>(TIMESHEET_MANAGER_V2_LIST_URL, formData, getBearerHeader(user.access))
}

export async function mainClock() {
  let user = await getLocalUser()
  return axios.post<any>(MAIN_CLOCK_URL, {}, getBearerHeader(user.access))
}

export async function mainTimesheet() {
  let user = await getLocalUser()
  return axios.post<any>(MAIN_TIMESHEET_URL, {}, getBearerHeader(user.access))
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function employeeList(page: number, str: string = '', employee_type: string = 'all') {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${EMP_LIST_URL}?page=${page}&q=${str}&employee_type=${employee_type}`
  return axios.get<any>(url, getBearerHeader(user.access))
}

export async function manufacturerList(
  page: number = 1,
  str: string = '',
  manufacturer_type: string = ''
) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${MANU_LIST_URL}?page=${page}&q=${str}&manufacturer_type=${manufacturer_type}`
  return axios.get<any>(url, getBearerHeader(user.access))
}

export async function vendorList(page: number) {
  let user = await getLocalUser()
  return axios.get<any>(VENDOR_LIST_URL + '?page=' + page, getBearerHeader(user.access))
}

export async function shippingList(page: number) {
  let user = await getLocalUser()
  return axios.get<any>(SHIPPINGTYPE_LIST_URL + '?page=' + page, getBearerHeader(user.access))
}

export async function customerList(
  page: number,
  str: string = '',
  customer_type: string = 'all',
  customer_status: string = 'all',
  pagePerEntries: number
) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${CUSTOMER_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}&customer_type=${customer_type}&customer_status=${customer_status}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function customerQuoteList(str: string | null) {
  let user = await getLocalUser()
  if (str === null || str === undefined) {
    str = ''
  }
  const url = `${CUSTOMER_QUOTE_LIST_URL}?str=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function inventoryListForPO() {
  let user = await getLocalUser()
  const url = `${INVENTORY_LIST_FOR_PO}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

//  inventory list for create and update purchase orders
export async function inventorySearchListForPO(searchText: any) {
  let user = await getLocalUser()
  const url = `${INVENTORY_SEARCH_LIST_FOR_PO}?search=${searchText}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function inventoryData(
  page: number,
  str: string = '',
  pagePerEntries: number = 100,
  item_type: string = 'all',
  low_inventory: number = 0,
  non_active_inventory: number = 0
) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${INVENTORY_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&item_type=${item_type}&q=${str}&low_inventory=${low_inventory}&non_active_inventory=${non_active_inventory}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function getInventoryData() {
  let user = await getLocalUser()
  const url = `${INVENTORY_LIST_DATA_URL}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function getInventoryCategories(
  page: number,
  str: string = '',
  pagePerEntries: number
) {
  let user = await getLocalUser()
  const url = `${INVENTORY_LIST_CATEGORY_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function getInventoryVendors(page: number, str: string = '', pagePerEntries: number) {
  let user = await getLocalUser()
  const url = `${INVENTORY_LIST_VENDOR_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function getInventoryManufacturers(
  page: number,
  str: string = '',
  pagePerEntries: number
) {
  let user = await getLocalUser()
  const url = `${INVENTORY_LIST_BRAND_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function purchaseOrderData(
  page: number,
  pagePerEntries: number,
  id: any,
  vendor_type: string = '',
  string: string = ''
) {
  let user = await getLocalUser()
  let formData = new FormData()

  if (string === null) {
    string = ''
  }

  formData.append('id', id)

  return axios.post<any>(
    PURCHASE_ORDER_LIST_URL +
      '?page=' +
      page +
      '&pagePerEntries=' +
      pagePerEntries +
      '&vendor_type=' +
      vendor_type +
      '&q=' +
      string,
    formData,
    getBearerHeader(user.access)
  )
}

export async function receivedPurchaseOrderData(
  page: number,
  pagePerEntries: number = 100,
  id: any,
  vendor_type: string = '',
  string: string = ''
) {
  let user = await getLocalUser()
  let formData = new FormData()

  if (string === null) {
    string = ''
  }

  formData.append('id', id)

  return axios.post<any>(
    RECEIVED_PURCHASE_ORDER_LIST_URL +
      '?page=' +
      page +
      '&pagePerEntries=' +
      pagePerEntries +
      '&vendor_type=' +
      vendor_type +
      '&q=' +
      string,
    formData,
    getBearerHeader(user.access)
  )
}

export async function rmaOrderData(page: number) {
  let user = await getLocalUser()
  return axios.post<any>(RETURN_ORDER_LIST_URL + '?page=' + page, {}, getBearerHeader(user.access))
}

export async function inventoryItemData(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(INVENTORY_ITEM_VIEW_URL, formData, getBearerHeader(user.access))
}

export async function purchaseOrderItemData(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(PURCHASE_ORDER_ITEM_VIEW_URL, formData, getBearerHeader(user.access))
}

export async function quotesList(
  page: number,
  str: string | null,
  quotes_type: string = '',
  tag_type: string[] | string = 'all',
  pagePerEntries: number,
  id: any
) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  let formData = new FormData()
  formData.append('id', id)
  formData.append('quotes', JSON.stringify(quotes_type))
  formData.append('tags', JSON.stringify(tag_type))
  const url = `${QUOTES_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&str=${str}&quotes_type=${quotes_type}&tag_type=${tag_type}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function lifetimeList(lifetime_type: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_lifespan', lifetime_type)
  const url = `${LIFETIME_LIST_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}
export async function lifetimeitemList(lifetime_type: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('item_lifespan', lifetime_type)
  const url = `${LIFETIME_ITEMLIST_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function getCustomerLifespan() {
  let user = await getLocalUser()
  let formData = new FormData()
  const url = `${LIFETIME_LIST_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function setCompanyClockOutTime(clockOut_Time: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('clock_out_time', clockOut_Time)
  const url = `${SET_COMPANY_CLOCK_OUT_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function getCompanyClockOutTime() {
  let user = await getLocalUser()
  let formData = new FormData()
  const url = `${SET_COMPANY_CLOCK_OUT_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function quoteAssignedToUrl(work_order_id: any, assigned_to: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('assigned_to', assigned_to)
  const url = `${ASSIGNED_TO_URL_QUOTES}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function quoteFetchAssignedToUrl(work_order_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  const url = `${FETCH_ASSIGNED_TO_URL_QUOTES}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function fetchCustomersWorkOrderUrl(str: string = '') {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_name', str)
  const url = `${PURCHASE_ORDER_WORKORDER_CUSTOMERS_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function campaignsList(campaigns_type: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('campaigns_search', campaigns_type)
  const url = `${CAMPAIGNS_LIST_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function workorderList(
  page: number,
  str: string,
  workorder_type: string = '',
  tag_type: string[] | string = 'all',
  sort_type: string = 'id asc',
  pagePerEntries: number = 100,
  id?: any
) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('status', JSON.stringify(workorder_type))
  formData.append('tags', JSON.stringify(tag_type))
  formData.append('sort', JSON.stringify(sort_type))
  console.log('values coming right way', page, str, workorder_type, tag_type, pagePerEntries, id)
  if (str === null) {
    str = ''
  }
  const url = `${WORKORDER_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}&sort_type=${sort_type}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function fieldServiceList(
  page: number,
  str: string,
  fieldservice_type: string = '',
  tag_type: any = 'all',
  pagePerEntries: number = 100,
  id?: any
) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('srvtype', JSON.stringify(fieldservice_type))
  formData.append('tag', JSON.stringify(tag_type))
  console.log('values coming right way', page, str, fieldservice_type, tag_type, pagePerEntries, id)
  if (str === null) {
    str = ''
  }

  const url = `${FIELD_SERVICE_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}&fieldservice_type=${fieldservice_type}&tag_type=${tag_type}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function notes(note_list: EstimatedNotes) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order', note_list.work_order)
  formData.append('message', note_list.message)
  formData.append('notes', note_list.additional_information)
  formData.append('type', note_list.type)
  return axios.post<{result: boolean}>(ESTIMATED_NOTES_URL, formData, getBearerHeader(user.access))
}
export async function deleteNotes(quote_id: any, notes_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('notes_id', notes_id)
  formData.append('quote_id', quote_id)
  return axios.post<{result: boolean}>(DELETE_NOTES_URL, formData, getBearerHeader(user.access))
}
export async function updateNotes(note_list: EstimatedNotes) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('quote_id', note_list.work_order)
  formData.append('message', note_list.message)
  formData.append('notes', note_list.additional_information)
  formData.append('notes_id', note_list.id)
  formData.append('type', note_list.type)
  return axios.post<{result: boolean}>(UPDATE_NOTES_URL, formData, getBearerHeader(user.access))
}
export async function fileUpload(data: any = [], id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', id)
  data.forEach((form: any) => {
    formData.append('files', form as Blob)
  })
  return axios.post<any>(FILE_UPLOAD_URL, formData, getBearerHeader(user.access))
}

export async function profileImg(data: any, folder: any) {
  let formData = new FormData()
  data.forEach((form: any) => {
    formData.append('profile_pic', form as Blob)
  })
  formData.append('folder', folder)
  return axios.post<any>(PROFILE_IMAGE_URL, formData)
}

export async function companyDetails() {
  let user = await getLocalUser()
  return axios.post<any>(COMPANY_LIST_URL, {}, getBearerHeader(user.access))
}

export async function getDepartments() {
  let user = await getLocalUser()
  return axios.post<any>(VIEW_DEPARTMENT_URL, {}, getBearerHeader(user.access))
}

export async function allCurrentlyClockedIn() {
  let user = await getLocalUser()
  return axios.post<any>(ALL_CLOCKED_IN_URL, {}, getBearerHeader(user.access))
}

export async function updateCompany(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  if (data?.companyLogo) {
    formData.append('company_logo', data.companyLogo)
  }
  formData.append('company_name', data.companyName)
  formData.append('company_email', data.company_email)
  formData.append('company_phone_number', data.company_phone_number)
  formData.append('company_address_line1', data.company_address_line1)
  formData.append('company_address_line2', data.company_address_line2)
  formData.append('company_city', data.company_city)
  formData.append('company_state', data.company_state)
  formData.append('company_zip_code', data.company_zip_code)
  return axios.post<any>(UPDATE_COMPANY_URL, formData, getBearerHeader(user.access))
}

export async function viewQuote(data: any, string = '', not_to_include: null | any = null) {
  let formData = new FormData()
  formData.append('work_order_id', data)
  let user = await getLocalUser()
  return axios.post<any>(
    `${VIEW_QUOTE_URL}?str=${string}&not_included=${not_to_include}`,
    formData,
    getBearerHeader(user.access)
  )
}

export async function getCustomerActivities(id: any, string = '') {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(CUSTOMER_ACTIVITIES_LIST_URL, formData, getBearerHeader(user.access))
}

export async function viewCustomer(data: any) {
  let formData = new FormData()
  formData.append('customer_id', data)
  let user = await getLocalUser()
  return axios.post<any>(VIEW_CUSTOMER_URL, formData, getBearerHeader(user.access))
}

export async function addQuote(data: any = [], quote_number: any) {
  let formData = new FormData()
  data.forEach((form: any) => {
    for (let [key, value] of Object.entries(form)) {
      formData.append(key, value as string)
    }
  })
  formData.append('label', quote_number)
  let user = await getLocalUser()
  return axios.post<any>(CREATE_QUOTE_URL, formData, getBearerHeader(user.access))
}

export async function subQuote(data: any = []) {
  let formData = new FormData()
  data.forEach((form: any) => {
    for (let [key, value] of Object.entries(form)) {
      formData.append(key, value as string)
    }
  })
  let user = await getLocalUser()
  return axios.post<any>(CREATE_SUB_QUOTE_URL, formData, getBearerHeader(user.access))
}

export async function updateDescription(quote_id: any, description: any) {
  let formData = new FormData()
  formData.append('id', quote_id)
  formData.append('description', description)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_SUB_DESC_URL, formData, getBearerHeader(user.access))
}

export async function itemService(
  form1: any = {},
  form2: any = {},
  tax: any,
  quote_id: any,
  grandTotal: any,
  editedSubQuoteDisclaimer: any,
  row_id: any,
  subquote_description: any,
  disUnit: any,
  discount: any
) {
  let formData = new FormData()
  formData.append('itemData', form1)
  formData.append('serviceData', form2)
  formData.append('tax', tax)
  formData.append('quote_id', quote_id)
  formData.append('grandTotal', grandTotal)
  formData.append('editedSubQuoteDisclaimer', editedSubQuoteDisclaimer)
  formData.append('row_id', row_id)
  formData.append('subquote_description', subquote_description)
  formData.append('discount', discount)
  formData.append('disUnit', disUnit)

  let user = await getLocalUser()
  return axios.post<any>(CREATE_ITEM_SER_URL, formData, getBearerHeader(user.access))
}
export async function updateQuoteData(data: any = []) {
  let formData = new FormData()
  data.forEach((form: any) => {
    for (let [key, value] of Object.entries(form)) {
      formData.append(key, value as string)
    }
  })
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_QUOTE_DATA_URL, formData, getBearerHeader(user.access))
}

//update quote with field service
export async function updateQuote(
  work_order_id: any,
  data: any,
  item_name: any,
  additional_notes: any
) {
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('additional_notes', additional_notes)
  formData.append(item_name, data)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_QUOTE_URL, formData, getBearerHeader(user.access))
}
export async function updateSubQuote(
  work_order_id: any,
  data: any,
  label: any,
  description: any,
  projectName: any,
  projectDescription: any,
  checkedValue: any,
  projectFiles: any,
  item_name: any,
  additional_notes: any
) {
  console.log(description)
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('label', label)
  formData.append('descriptions', description)
  formData.append('project_field', projectName)
  formData.append('description', projectDescription)
  formData.append('type', checkedValue)
  formData.append('projectFiles', projectFiles)
  formData.append('additional_notes', additional_notes)
  formData.append(item_name, data)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_SUBQUOTE_URL, formData, getBearerHeader(user.access))
}
export async function updateSubQuoteFs(
  work_order_id: any,
  data: any,
  label: any,
  description: any,
  projectName: any,
  projectDescription: any,
  checkedValue: any,
  projectFiles: any,
  item_name: any,
  additional_notes: any
) {
  console.log(description)
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('label', label)
  formData.append('descriptions', description)
  formData.append('project_field', projectName)
  formData.append('description', projectDescription)
  formData.append('type', checkedValue)
  formData.append('projectFiles', projectFiles)
  formData.append('additional_notes', additional_notes)
  formData.append(item_name, data)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_FS_SUBQUOTE_URL, formData, getBearerHeader(user.access))
}
export async function updateSubQuoteProject(
  work_order_id: any,
  // data: any,
  // label: any,
  // description:any,
  projectName: any,
  projectDescription: any
  // item_name: any,
  // additional_notes: any
) {
  console.log(projectName, projectDescription)
  let formData = new FormData()
  formData.append('id', work_order_id)
  // formData.append('label', label)
  // formData.append('description', description)
  formData.append('project_field', projectName)
  formData.append('description', projectDescription)
  // formData.append('additional_notes', additional_notes)
  // formData.append(item_name, data)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_SUBQUOTE_PROJECT_URL, formData, getBearerHeader(user.access))
}

export async function updateFieldService(
  field_service_id: any,
  data: any,
  item_name: any,
  additional_notes: any
) {
  let formData = new FormData()
  formData.append('work_order_id', field_service_id)
  formData.append('additional_notes', additional_notes)
  formData.append(item_name, data)
  let user = await getLocalUser()
  return axios.post<any>(UPDATE_FIELD_SERVICE_URL, formData, getBearerHeader(user.access))
}

export async function updateQuoteLabel(work_order_id: any, updated_label: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('new_label', updated_label)
  return axios.post<any>(UPDATE_QUOTE_LABEL_URL, formData, getBearerHeader(user.access))
}

export async function fetchAllWorkOrders(quoteType: any) {
  let user = await getLocalUser()
  let formdata = new FormData()
  quoteType && formdata.append('quote_type', quoteType)
  return axios.post<any>(FETCH_ALL_WORK_ORDERS, formdata, getBearerHeader(user.access))
}

export async function userQuoteTimesheet(start_time: any, emp_userId: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  // formData.append('work_order_id', work_order_id)
  formData.append('from_date', start_time)
  formData.append('emp_user_id', emp_userId)
  return axios.post<any>(
    PERTICULAR_QUOTE_USER_TIMESHEET_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function deleteTimesheetData(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(DELETE_PERTICULAR_TIMESHEET_URL, formData, getBearerHeader(user.access))
}

export async function deleteEmployee(id: any, password: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('emp_id', id)
  formData.append('password', password)
  return axios.post<any>(DELETE_EMPLOYEE_URL, formData, getBearerHeader(user.access))
}

export async function viewDepartment() {
  let user = await getLocalUser()
  return axios.post<any>(VIEW_DEPARTMENT_URL, {}, getBearerHeader(user.access))
}
export async function updateDepartment(id: any, label: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('label', label)
  return axios.post<any>(UPDATE_DEPARTMENT_URL, formData, getBearerHeader(user.access))
}

export async function updateInventoryCategory(category_id: any, category_name: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('category_id', category_id)
  formData.append('category_name', category_name)
  return axios.post<any>(UPDATE_INVENTORY_CATEGORY_URL, formData, getBearerHeader(user.access))
}

export async function updateInventoryBrand(brand_id: any, brand_name: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('brand_id', brand_id)
  formData.append('brand_name', brand_name)
  return axios.post<any>(UPDATE_INVENTORY_BRAND_URL, formData, getBearerHeader(user.access))
}
export async function savePdfTempFiles(vendor_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  return axios.post<any>(SAVE_TEMP_PDF_FILES, formData, getBearerHeader(user.access))
}

export async function sub_quotes_generate_pdf(quotes_id: string, sub_quotes_id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('quote_id', quotes_id)
  formData.append('subquote_id', sub_quotes_id)
  return axios.post<any>(SUB_QUOTES_PDF_GENERATION, formData, getBearerHeader(user.access))
}

export async function services_csv_generator() {
  let user = await getLocalUser()
  return axios.post<any>(SERVICES_CSV_GENERATOR_URL, {}, getBearerHeader(user.access))
}

export async function saveCompanyLogo() {
  let user = await getLocalUser()
  let formData = new FormData()
  // formData.append('vendor_id', vendor_id)
  return axios.post<any>(SAVE_COMPANY_LOGO_PDF_FILES, formData, getBearerHeader(user.access))
}
export async function deletePdfTempFiles(vendor_file_name: any, company_file_name: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_file_name', vendor_file_name)
  formData.append('company_file_name', company_file_name)
  return axios.post<any>(DELETE_TEMP_PDF_FILES, formData, getBearerHeader(user.access))
}

export async function fetchCustomerFiles(str: string = '', customer_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('str', str)
  formData.append('customer_id', customer_id)
  return axios.post<any>(FETCH_CUSTOMER_FILES, formData, getBearerHeader(user.access))
}

export async function fetchSubQuoteUrl(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(SUB_QUOTES_WORK_ORDER_URL, formData, getBearerHeader(user.access))
}
export async function GetSubQuotes(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(GET_SUB_QUOTE_URL, formData, getBearerHeader(user.access))
}
export async function GetItemServices(itemId: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('itemId', itemId)
  return axios.post<any>(GET_SUB_ITEM_SER_URL, formData, getBearerHeader(user.access))
}
export async function GetDisclaimer() {
  let user = await getLocalUser()
  return axios.post<any>(GET_DISCLAIMER, {}, getBearerHeader(user.access))
}

export async function GetItemToggle(itemId: string, quote_id: string, item_data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('subQuoteId', itemId)
  formData.append('quote_id', quote_id)
  formData.append('items_data', item_data)
  return axios.post<any>(GET_ITEM_TOGGLE, formData, getBearerHeader(user.access))
}

export async function GetServiceToggle(itemId: string, quote_id: string, service_data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('subQuoteId', itemId)
  formData.append('quote_id', quote_id)
  formData.append('services_data', service_data)
  return axios.post<any>(GET_SERVICE_TOGGLE, formData, getBearerHeader(user.access))
}

export async function GetEditedDisclaimer(rowDataId: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('rowDataId', rowDataId)
  return axios.post<any>(GET_EDITED_DISCLAIMER, formData, getBearerHeader(user.access))
}
export async function deleteInventoryCategory(category_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('category_id', category_id)
  return axios.post<any>(DELETE_INVENTORY_CATEGORY_URL, formData, getBearerHeader(user.access))
}

export async function deleteInventoryBrand(brand_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('brand_id', brand_id)
  return axios.post<any>(DELETE_INVENTORY_BRAND_URL, formData, getBearerHeader(user.access))
}

export async function inventoryAssignedItemsUrl(item_id: any, items_list: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('item_id', item_id)
  formData.append('items_list', items_list)
  return axios.post<any>(INVENTORY_ASSOCIATED_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function inventorySubstitutesItemsUrl(item_id: any, items_list: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('item_id', item_id)
  formData.append('items_list', items_list)
  return axios.post<any>(INVENTORY_SUBSTITUTES_URL, formData, getBearerHeader(user.access))
}

export async function addDepartment(department: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('department', department)
  return axios.post<any>(ADD_DEPARTMENT_URL, formData, getBearerHeader(user.access))
}
export async function deleteDepartment(id: any, password: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('password', password)
  return axios.post<any>(DELETE_DEPARTMENT_URL, formData, getBearerHeader(user.access))
}

export async function getInactiveEmployee(str: string | null) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  return axios.post<any>(
    `${INACTIVE_EMPLOYEE_LIST_URL}?str=${str}`,
    {},
    getBearerHeader(user.access)
  )
}

export async function deleteInactiveEmployee(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(DELETE_INACTIVE_EMPLOYEE_URL, formData, getBearerHeader(user.access))
}

export async function updateCompanyTimezone(timezone: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('timezone', timezone)
  return axios.post<any>(UPDATE_TIMEZONE_URL, formData, getBearerHeader(user.access))
}

export async function filteredStatus() {
  let user = await getLocalUser()
  return axios.post<any>(GET_FILTERED_STATUS_URL, {}, getBearerHeader(user.access))
}

export async function quoteStatusAdd(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('title', data.title)
  formData.append('bg_color', data.bgcolor)
  formData.append('text_color', data.textcolor)
  formData.append('type', data.type)
  return axios.post<any>(QUOTE_STATUS_ADD_URL, formData, getBearerHeader(user.access))
}

export async function quoteStatusDelete(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(QUOTE_STATUS_DELETE_URL, formData, getBearerHeader(user.access))
}

export async function addNotesQuoteActivity(id: any, additional_notes: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('additional_notes', additional_notes)
  return axios.post<any>(QUOTE_ACTIVITY_UPDATE_URL, formData, getBearerHeader(user.access))
}

export async function updateCustomerStatus(id: any, customer_status: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_id', id)
  formData.append('customer_status', customer_status)
  return axios.post<any>(UPDATE_CUSTOMER_STATUS_URL, formData, getBearerHeader(user.access))
}

export async function quoteFileDelete(id: any, workorder_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('workorder_id', workorder_id)
  return axios.post<any>(QUOTE_FILE_DELETE_URL, formData, getBearerHeader(user.access))
}

export async function quoteStatusfetch() {
  let user = await getLocalUser()
  return axios.post<any>(QUOTE_STATUS_FETCH_URL, {}, getBearerHeader(user.access))
}

export async function fetchAllStatuses() {
  let user = await getLocalUser()
  return axios.post<any>(FETCH_ALL_STATUSES_URL, {}, getBearerHeader(user.access))
}

export async function companyFilesList(
  page: number,
  str: string = '',
  pagePerEntries: number = 100
) {
  let user = await getLocalUser()

  const url = `${COMPANY_FILES_LIST_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function quoteStatusView(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(QUOTE_STATUS_VIEW_URL, formData, getBearerHeader(user.access))
}

export async function quoteStatusUpdate(data: any, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('title', data.title)
  formData.append('bg_color', data.bgcolor)
  formData.append('text_color', data.textcolor)
  formData.append('type', data.type)
  return axios.post<any>(QUOTE_STATUS_UPDATE_URL, formData, getBearerHeader(user.access))
}

export async function updateStatusSorting(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('sortedStatus', data)
  return axios.post<any>(UPDATE_STATUS_SORTING, formData, getBearerHeader(user.access))
}

export async function quoteFileUpdate(id: any, file_name: any, workorder_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('file_name', file_name)
  formData.append('workorder_id', workorder_id)
  return axios.post<any>(QUOTE_FILE_UPDATE_URL, formData, getBearerHeader(user.access))
}

export async function updateTimesheetData(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('data', data)
  return axios.post<any>(UPDATE_TIMESHEET_URL, formData, getBearerHeader(user.access))
}

export async function addTimesheetData(data: any, Emp_idx: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  data?.quote !== null && formData.append('quote', data?.quote)
  formData.append('start_time', data?.start_time)
  formData.append('end_time', data?.end_time)
  formData.append('timesheet_type', data?.timesheet_type)
  formData.append('emp_id', Emp_idx)
  return axios.post<any>(ADD_TIMESTAMP_URL, formData, getBearerHeader(user.access))
}

export async function quoteAdditionalNotes(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('additonal_notes', data?.additonal_notes)
  formData.append('workorder_id', data?.workorder_id)
  formData.append('type', data?.type)
  return axios.post<any>(QUOTE_NOTES_URL, formData, getBearerHeader(user.access))
}

export async function quoteDisclaimer(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('additonal_notes', data?.additonal_notes)
  formData.append('workorder_id', data?.workorder_id)
  formData.append('type', data?.type)
  formData.append('defaultTax', data?.defaultTax)
  formData.append('defaultMarkupValueUnit', data?.defaultMarkupValueUnit)
  formData.append('defaultMarkupValue', data?.defaultMarkupValue)
  formData.append('expireDays', data?.expireDays)

  return axios.post<any>(QUOTE_DISCLAIMER_URL, formData, getBearerHeader(user.access))
}

export async function masqueradeUrl() {
  let user = await getLocalUser()
  return axios.post<any>(MASQUERADE_LIST_URL, {}, getBearerHeader(user.access))
}

export async function userMasquerade() {
  let user = await getLocalUser()
  return axios.post<any>(USER_MASQUERADE_URL, {}, getBearerHeader(user.access))
}

export async function login_user_by_email(email: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('email', email)
  return axios.post<any>(LOGIN_USER_BY_EMAIL_URL, formData, getBearerHeader(user.access))
}

export async function currentlyClockIn() {
  let user = await getLocalUser()
  return axios.post<any>(CURRENTLY_CLOCK_IN, {}, getBearerHeader(user.access))
}

export async function updateEmp(data: any, user_id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('first_name', data.first_name)
  formData.append('last_name', data.last_name)
  formData.append('emp_role', data.emp_role)
  formData.append('emp_email', data.emp_email)
  formData.append('emp_phone', data.emp_phone)
  formData.append('emp_title', data.emp_title)
  formData.append('emp_birthday', data.emp_birthday)
  formData.append('emp_anniversary', data.emp_anniversary)
  formData.append('emp_hometown', data.emp_hometown)
  formData.append('user_id', user_id)
  return axios.post<{result: boolean}>(UPDATE_ROLE_URL, formData, getBearerHeader(user.access))
}

export async function getPermissions(role: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('role', role)
  return axios.post<any>(GET_PERMISSIONS_OF_ROLES_URL_LIST, formData, getBearerHeader(user.access))
}

export async function getRemainders() {
  let user = await getLocalUser()
  return axios.post<any>(GET_REMAINDER_LIST_URL, {}, getBearerHeader(user.access))
}

export async function quoteDelete(quote_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('quote_id', quote_id)
  return axios.post<any>(DELETE_QUOTE_URL, formData, getBearerHeader(user.access))
}

export async function subquoteDelete(id: any, quo_id: any, subQuoteLabel: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('quote_id', quo_id)
  formData.append('subQuoteLabel', subQuoteLabel)
  return axios.post<any>(DELETE_SUBQUOTE_URL, formData, getBearerHeader(user.access))
}
export async function subquote_item_delete(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(DELETE_ITEM_URL, formData, getBearerHeader(user.access))
}
export async function subquote_service_delete(id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(DELETE_SERVICE_URL, formData, getBearerHeader(user.access))
}
export async function customerDelete(customer_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('customer_id', customer_id)
  return axios.post<any>(DELETE_CUSTOMER_URL, formData, getBearerHeader(user.access))
}

export async function deletePO(po_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', po_id)
  return axios.post<any>(DELETE_PO_URL, formData, getBearerHeader(user.access))
}

export async function updatePermissions(data: any, role_type: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('jsonData', data)
  formData.append('role', role_type)
  return axios.post<any>(UPDATE_PERMISSIONS_URL, formData, getBearerHeader(user.access))
}
export async function modelClockInHandle() {
  return axios.get(DUMMY_REST_API)
}

export async function modelClockOutHandle() {
  return axios.get(DUMMY_REST_API)
}

export async function updatePO(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_data', data)
  return axios.post<any>(PO_UPDATE_URL, formData, getBearerHeader(user.access))
}

export async function updatePOReceived(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_data', data)
  return axios.post<any>(PO_UPDATE__RECEIVED_URL, formData, getBearerHeader(user.access))
}

export async function fetchAssignedItems_Item(inventory_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', inventory_id)
  return axios.post<any>(ITEMS_ASSIGNED_WRT_ITEM_URL, formData, getBearerHeader(user.access))
}

export async function fetchItemActivity_Item(inventory_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', inventory_id)
  return axios.post<any>(ITEMS_ACTIVITY_WRT_ITEM_URL, formData, getBearerHeader(user.access))
}

export async function createItemActivity_Item(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', data?.inventory_id)
  formData.append('message', '')
  formData.append('additional_notes', data?.additional_notes)
  return axios.post<any>(CREATE_ITEM_ACTIVITY_WRT_ITEM_URL, formData, getBearerHeader(user.access))
}

export async function updateItemActivity_Item(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', data?.id)
  formData.append('notes_id', data?.notes_id)
  formData.append('additional_notes', data?.additional_notes)
  return axios.post<any>(UPDATE_ITEM_ACTIVITY_WRT_ITEM_URL, formData, getBearerHeader(user.access))
}
export async function updateItem(itemUpdatedata: any) {
  console.log({itemUpdatedata})
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', itemUpdatedata?.id)
  formData.append('markup', itemUpdatedata?.markup)
  formData.append('quantity', itemUpdatedata?.quantity)
  formData.append('priceUnit', itemUpdatedata?.priceUnit)
  formData.append('itemCost', itemUpdatedata?.itemCost)
  return axios.post<any>(UPDATE_ITEM_URL, formData, getBearerHeader(user.access))
}
export async function updateService(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', data?.id)
  formData.append('serviceId', data?.serviceId)
  formData.append('service_description', data?.service_description)
  formData.append('quantity', data?.quantity)
  formData.append('serviceName', data?.serviceName)
  formData.append('price', data?.price)

  return axios.post<any>(UPDATE_SERVICE_URL, formData, getBearerHeader(user.access))
}
export const darkMessage = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  })
}

export const darkErrorMessage = (message: string) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  })
}

const myPromise = new Promise((resolve) => {
  for (let i = 0; i <= 99999999; i++) {}
  setTimeout(() => {
    resolve('success')
    return () => {
      resolve(null)
    }
  }, 10000)
})

export const customLoading = () => {
  toast.promise(myPromise, {
    pending: 'Generating Shareable Link ',
    success: 'Link Copied to Clipboard',
    error: 'error',
  })
}

export const darkWarningMessage = (message: string) => {
  toast.warning(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  })
}

export const currnetCompanyName = (companyName: any) => {
  return companyName?.toLowerCase().replaceAll(' ', '_')
}

export async function dulpicateQuotes(data: any, id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('label', JSON.stringify(data))
  // formData.append('checklist', JSON.stringify(checklist))
  formData.append('id', id)
  return axios.post<any>(DUPLICATE_QUOTE_URL, formData, getBearerHeader(user.access))
}

export async function assignNewTaskToEmp(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('title', data?.task_title)
  formData.append('description', data?.task_description)
  formData.append('end_date', data?.task_end_date)
  formData.append('assigned_for', data?.task_selected_emp)
  return axios.post<any>(CREATE_NEW_TASK_URL, formData, getBearerHeader(user.access))
}

export async function fetchAllTasks_ADMIN() {
  let user = await getLocalUser()
  return axios.post<any>(FETCH_ALL_TASKS_URL, {}, getBearerHeader(user.access))
}

export async function addVendorCredentials(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', data.vendor_id)
  formData.append('vendor_account', data.vendor_account)
  formData.append('username', data.username)
  formData.append('password', data.password)
  return axios.post<any>(ADD_CREDENTIALS_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function fetchVendorCredentials(vendor_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  return axios.post<any>(FETCH_CREDENTIALS_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function updateVendorCredentials(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', data.vendor_id)
  formData.append('vendor_account', data.vendor_account)
  formData.append('credentials_tab_id', data.id)
  formData.append('username', data.username)
  formData.append('password', data.password)
  return axios.post<any>(UPDATE_CREDENTIALS_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function deleteVendorCredentials(vendor_id: any, credentials_tab_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  formData.append('credentials_tab_id', credentials_tab_id)
  return axios.post<any>(DELETE_CREDENTIALS_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function createPurchaseOrderNotes(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', data.po_id)
  formData.append('notes', data.notes)
  formData.append('message', data.message)
  formData.append('type', data.type)
  return axios.post<any>(CREATE_PURCHASE_ORDER_NOTES_URL, formData, getBearerHeader(user.access))
}

export async function fetchPurchaseHistoryInventoryUrl(inventory_id: any, string: string | any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('inventory_id', inventory_id)
  const url = `${FETCH_PURCHASE_ORDER_HISTORY_INVENTORY_URL}?vendor_list=${string}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function fetchPOforVendors(vendor_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  const url = `${FETCH_PURCHASE_ORDER_VENDOR_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function fetchVendorListNamesOnly() {
  let user = await getLocalUser()
  return axios.post<any>(VENDOR_LIST_NAMES_ONLY_URL, {}, getBearerHeader(user.access))
}

export async function updatePurchaseOrderForField(po_id: any, json_pack: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', po_id)
  formData.append('json_data', json_pack)
  return axios.post<any>(
    UPDATE_PURCHASE_ORDER_FOR_FIELD_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function deletePurchaseOrderForField(po_id: any, for_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', po_id)
  formData.append('for_id', for_id)
  return axios.post<any>(
    DELETE_PURCHASE_ORDER_FOR_ASSIGNEE_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function fetchWorkOrderPurchaseOrders(wo_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('wo_id', wo_id)
  return axios.post<any>(WORK_ORDER_PURCHASE_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function fetchPurchaseOrderNotes(po_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', po_id)
  return axios.post<any>(FETCH_PURCHASE_ORDER_NOTES_URL, formData, getBearerHeader(user.access))
}

export async function fetchPurchaseOrderFiles(po_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('purchase_order_id', po_id)
  return axios.post<any>(FETCH_FILES_PURCHASE_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function uploadPurchaseOrderFiles(po_id: any, files: any = []) {
  let user = await getLocalUser()
  let formData = new FormData()
  console.log(po_id, files)
  formData.append('purchase_order_id', po_id)
  files.forEach((form: any) => {
    formData.append('files', form as Blob)
  })
  return axios.post<any>(UPLOAD_FILES_PURCHASE_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function deletePurchaseOrderFiles(po_id: any, file_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('purchase_order_id', po_id)
  formData.append('file_id', file_id)
  return axios.post<any>(DELETE_FILES_PURCHASE_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function updatePurchaseOrderFiles(po_id: any, data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('purchase_order_id', po_id)
  formData.append('file_id', data.file_id)
  formData.append('file_label', data.file_label)
  return axios.post<any>(UPDATE_FILES_PURCHASE_ORDER_URL, formData, getBearerHeader(user.access))
}

export async function updatePurchaseOrderNotes(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', data.po_id)
  formData.append('notes_id', data.notes_id)
  formData.append('notes', data.notes)
  formData.append('additional_information', data.message)
  formData.append('type', data.type)
  return axios.post<any>(UPDATE_PURCHASE_ORDER_NOTES_URL, formData, getBearerHeader(user.access))
}

export async function updateDueDateQuoteUrl(work_order_id: any, date: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('workorder_id', work_order_id)
  formData.append('due_date', date)
  const url = `${UPDATE_QUOTE_DUE_DATE_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function getHoursUsedUrl(project_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', project_id)
  const url = `${GET_HOURS_USED_BY_DEPARTMENT_URL}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function deletePurchaseOrderNotes(po_id: any, notes_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('po_id', po_id)
  formData.append('notes_id', notes_id)
  return axios.post<any>(DELETE_PURCHASE_ORDER_NOTES_URL, formData, getBearerHeader(user.access))
}

export async function addVendorNotes(data: VendorNotesInterface) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', data.vendor_id)
  formData.append('notes', data.message)
  return axios.post<any>(ADD_NOTES_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function fetchVendorNotes(vendor_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  return axios.post<any>(FETCH_NOTES_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function need_to_order_inventory_url() {
  let user = await getLocalUser()
  return axios.post<any>(NEED_TO_ORDER_INVENTORY_URL, {}, getBearerHeader(user.access))
}

export async function updateVendorNotesAPI(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', data.vendor_id)
  formData.append('notes_tab_id', data.id)
  formData.append('notes', data.message)
  return axios.post<any>(UPDATE_NOTES_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function deleteVendorNotesAPI(vendor_id: any, notes_tab_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('vendor_id', vendor_id)
  formData.append('notes_tab_id', notes_tab_id)
  return axios.post<any>(DELETE_NOTES_VENDOR_URL, formData, getBearerHeader(user.access))
}

export async function createWorkOrderTag(tag_name: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('tag_name', tag_name)
  return axios.post<any>(CREATE_WORK_ORDER_TAGS_URL, formData, getBearerHeader(user.access))
}
export async function deleteWorkOrderTag(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(DELETE_WORK_ORDER_TAGS_URL, formData, getBearerHeader(user.access))
}
export async function updateWorkOrderTag(id: string, tag_name: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('tag_name', tag_name)
  return axios.post<any>(UPDATE_WORK_ORDER_TAGS_URL, formData, getBearerHeader(user.access))
}
export async function fetchWorkOrderTag(page: number, str: string | null, pagePerEntries: number) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${FETCH_WORK_ORDER_TAGS_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function ALL_fetchWorkOrderTag() {
  let user = await getLocalUser()
  const url = `${ALL_FETCH_WORK_ORDER_TAGS_URL}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}
// TAGS
export async function FETCH_tagsForWorkOrder(work_order_id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  const url = `${FETCH_TAGS_FOR_WORK_ORDERS}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function UPDATE_tagsForWorkOrder(work_order_id: string, tags_id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('work_order_id', work_order_id)
  formData.append('tags_id', tags_id)
  const url = `${UPDATE_TAGS_FOR_WORK_ORDERS}`
  return axios.post<any>(url, formData, getBearerHeader(user.access))
}

export async function fetchAllShippingMethods(
  page: number,
  str: string | null,
  pagePerEntries: number
) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${FETCH_ALL_SHIPPING_METHODS_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function createShippingMethod(shipping_type: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('shipping_type', shipping_type)
  return axios.post<any>(CREATE_SHIPPING_METHOD_URL, formData, getBearerHeader(user.access))
}

export async function updateShippingMethod(id: string, shipping_type: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  formData.append('shipping_type', shipping_type)
  return axios.post<any>(
    UPDATE_SPECIFIC_SHIPPING_METHOD_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function deleteShippingMethod(id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('id', id)
  return axios.post<any>(
    DELETE_SPECIFIC_SHIPPING_MEHTOD_URL,
    formData,
    getBearerHeader(user.access)
  )
}

export async function addAssignedItems(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('all_items', data)
  return axios.post<any>(ADD_ASSIGNED_TO_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function fetchAssignedItems(project_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', project_id)
  return axios.post<any>(FETCH_ASSIGNED_TO_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function fetchSubquoteAssignedItems(project_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', project_id)
  return axios.post<any>(
    FETCH_SUBQUOTE_ASSIGNED_TO_ITEMS_URL,
    formData,
    getBearerHeader(user.access)
  )
}
export async function fetchDummyAssignedItems(project_id: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', project_id)
  return axios.post<any>(FETCH_DUMMY_ASSIGNED_TO_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function deleteAssignedItems(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', data.project_id)
  formData.append('inventory_id', data.inventory_id)
  formData.append('assigned_item_id', data.assigned_item_id)
  return axios.post<any>(DELETE_ASSIGNED_TO_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function updateAssignedItems(data: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('project_id', data.project_id)
  formData.append('assigned_item_id', data.assigned_item_id)
  formData.append('inventory_id', data.inventory_id)
  formData.append('assigned_items', data.assigned_items)
  return axios.post<any>(UPDATE_ASSIGNED_TO_ITEMS_URL, formData, getBearerHeader(user.access))
}

export async function dashBoardCardApi() {
  let user = await getLocalUser()
  return axios.post<any>(DASHBOARD_DATA_URL, '', getBearerHeader(user.access))
}

export async function fetchNextQuote() {
  let user = await getLocalUser()

  return axios.post<any>(FETCH_NEXT_QUOTE, '', getBearerHeader(user.access))
}

export async function create_new_service(
  service_name: string,
  service_description: string,
  service_rate: number
) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('service_name', service_name)
  formData.append('service_description', service_description)
  formData.append('service_rate', service_rate.toString())
  return axios.post<any>(CREATE_NEW_SERVICE_URL, formData, getBearerHeader(user.access))
}

export async function fetch_all_services(page: number, str: string | null, pagePerEntries: number) {
  let user = await getLocalUser()
  if (str === null) {
    str = ''
  }
  const url = `${FETCH_ALL_SERVICES_URL}?page=${page}&pagePerEntries=${pagePerEntries}&q=${str}`
  return axios.post<any>(url, {}, getBearerHeader(user.access))
}

export async function delete_specific_service(service_id: string) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('service_id', service_id)
  return axios.post<any>(DELETE_SPECIFIC_SERVICE_URL, formData, getBearerHeader(user.access))
}

export async function update_specific_service(service_model: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('service_id', service_model?.service_id)
  formData.append('service_name', service_model?.service_name)
  formData.append('service_description', service_model?.service_description)
  formData.append('service_rate', service_model?.service_rate)
  return axios.post<any>(UPDATE_SPECIFIC_SERVICE_URL, formData, getBearerHeader(user.access))
}

export async function update_dashboard_quote_month(new_quote: any) {
  let user = await getLocalUser()
  let formData = new FormData()
  formData.append('new_quote', new_quote)
  return axios.post<any>(UPDATE_DASHBOARD_MONTH_QUOTE_URL, formData, getBearerHeader(user.access))
}
