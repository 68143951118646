export const iconsMaping: any = {
  pdf: 'pdf.svg',
  png: 'blank-image.svg',
  jpg: 'blank-image.svg',
  jpeg: 'blank-image.svg',
  doc: 'doc.svg',
  cda: 'blank-image.svg',
  aif: 'ai.svg',
  docx: 'doc.svg',
  csv: 'csv.svg',
  ai: 'ai.svg',
  xlsx: 'xlsx.svg',
}
