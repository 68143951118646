/* eslint-disable react-hooks/exhaustive-deps */
import {
  currnetCompanyName,
  darkErrorMessage,
  fetchAllTasks_ADMIN,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {LinearProgress} from '@mui/material'
import {useNavigate} from 'react-router'
import {MaterialDataTable} from './MatrialDataTable'
import moment from 'moment'
import {
  MomentDateFormat,
  TASK_AWAITING_WORK,
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_NEEDS_UPDATES,
} from '../../context/TimeSheetConstant'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

const TASK_STATUSES = [TASK_AWAITING_WORK, TASK_IN_PROGRESS, TASK_COMPLETED, TASK_NEEDS_UPDATES]

function TasksToEmp() {
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    Awaiting_Modal: [],
    Completed_Modal: [],
    In_progress_Modal: [],
    Needs_update_Modal: [],
  })
  const [totalRows, setTotalRows] = useState({
    Awaiting_Modal_Count: 0,
    Completed_Modal_Count: 0,
    In_progress_Modal_Count: 0,
    Needs_update_Modal_Count: 0,
  })

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row.title,
        header: 'Task Title', //uses the default width from defaultColumn prop
      },
      {
        accessorFn: (row: any) => moment(row.end_date).format(MomentDateFormat),
        header: 'End Date',
      },
      // {
      //   header: 'Task Status',
      //   accessorFn: (row: any) => row.status,
      //   size: 200, //increase the width of this column
      // },
      {
        accessorFn: (row: any) => row.assigned_for.first_name + ' ' + row.assigned_for.last_name,
        header: 'Assigned To',
        size: 120, //decrease the width of this column
      },
      {
        accessorFn: (row: any) => row.assigned_by.first_name + ' ' + row.assigned_by.last_name,
        header: 'Assigned By',
        size: 100, //decrease the width of this column
      },
    ],
    []
  )

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Tasks',
        subtitle: ` `,
        btn_text: 'None',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: true,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    getList()
  }, [])

  const getList = useCallback(async () => {
    setLoading(true)
    await fetchAllTasks_ADMIN()
      .then((res) => {
        const getApiData = res?.data?.data as any
        setData({
          Awaiting_Modal: getApiData?.Awaiting_Modal,
          Completed_Modal: getApiData?.Completed_Modal,
          In_progress_Modal: getApiData?.In_progress_Modal,
          Needs_update_Modal: getApiData?.Needs_update_Modal,
        })
        setTotalRows({
          Awaiting_Modal_Count: getApiData?.awaiting_task_filter_count,
          Completed_Modal_Count: getApiData?.completed_task_filter_count,
          In_progress_Modal_Count: getApiData?.in_progress_task_filter_count,
          Needs_update_Modal_Count: getApiData?.needs_update_task_filter_count,
        })
        setLoading(false)
      })
      .catch((e) => {
        if (e?.response.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
          console.error(e)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  // const PaginationCallback = useCallback(
  //   (getPagination: any) => {
  //     getList()
  //   },
  //   [getList]
  // )

  return (
    <>
      <div className='card'>
        <div className='card-body border-bottom p-7'>
          <div className='d-flex justify-content-between'>
            <h1 className='fw-bolder m-2'>Tasks</h1>
            <Button
              className='btn-primary'
              onClick={() => navigate(`/${prefixCompany}/settings/tasks/create`)}
            >
              Assign New Tasks
            </Button>
          </div>
        </div>
        {loading ? (
          <LinearProgress />
        ) : (
          <div
            className='border-0 task-wrap-tables'
            style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 50%)'}}
          >
            {TASK_STATUSES.map((eachTask: any) => {
              return (
                <div className='card-body'>
                  <div>{eachTask}</div>
                  <MaterialDataTable
                    MaterialColumns={MaterialColumns}
                    MaterialColumnsApiData={data?.Awaiting_Modal}
                    totalRows={totalRows?.Awaiting_Modal_Count}
                    loadingBar={loading}
                    tableHeight={600}
                    navigationLink={'customers/view'}
                    serverPagination={pagination}
                    setServerPagination={(cb_data: any) => {
                      return console.log(cb_data)
                      // return PaginationCallback(cb_data)
                    }}
                    optionalTableHeader={
                      <div className='card-title m-0 d-flex d-inline align-items-center'>
                        <h4>
                          <KTSVG
                            path='/media/icons/duotune/general/gen071.svg'
                            className='svg-icon-4 me-3'
                          />
                          Task
                        </h4>
                      </div>
                    }
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
export default TasksToEmp
