import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

type PropsType = {
  loading: boolean
  handleCreateModal: boolean
  handleCloseCreateModal: () => void
  callbackFnx: (input_data: string) => void
  default_input_value?: string
}
const CreateTag = ({
  loading,
  handleCreateModal,
  handleCloseCreateModal,
  callbackFnx,
  default_input_value = '',
}: PropsType) => {
  const [inputFieldData, setInputFieldData] = useState('')

  useEffect(() => {
    setInputFieldData(default_input_value)
  }, [default_input_value])

  const handleSubmit = useCallback(
    (e: any) => {
      e?.preventDefault()
      callbackFnx(inputFieldData)
      setInputFieldData('')
      handleCloseCreateModal()
    },
    [callbackFnx, handleCloseCreateModal, inputFieldData]
  )

  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      show={handleCreateModal}
      centered
      className='fade'
      size='lg'
      onHide={handleCloseCreateModal}
      onModalClose={handleCloseCreateModal}
    >
      <Modal.Header>
        <h2>Create New Tag</h2>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className='form-group row'>
            <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
              <label htmlFor='enter_tag_name' className='required'>
                Enter Tag Name
              </label>
            </div>
            <div className='col-sm-9'>
              <input
                type='text'
                required
                className='form-control'
                id='enter_tag_name'
                onChange={(e) => setInputFieldData(e?.target?.value)}
                value={inputFieldData}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='dark'
            type='button'
            onClick={() => {
              handleCloseCreateModal()
              setInputFieldData('')
            }}
          >
            Discard
          </Button>
          <Button variant='primary' type='submit'>
            {!loading && 'Submit'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CreateTag
