import MaterialReactTable from 'material-react-table'
import React from 'react'
type PropType = {
  MaterialColumns: any
  MaterialColumnsApiData: any[]
  stopRedirection?: boolean
  handleClick?: (callback_data: any) => void
  tableHeight?: number
  loadingBar?: boolean
}
const SimpleDataTable = ({
  MaterialColumns,
  MaterialColumnsApiData,
  stopRedirection = false,
  handleClick,
  tableHeight = 750,
  loadingBar = false,
}: PropType) => {
  return (
    <>
      <MaterialReactTable
        columns={MaterialColumns}
        data={MaterialColumnsApiData}
        enableSorting={false}
        enableStickyHeader
        enableStickyFooter
        state={{isLoading: loadingBar}}
        enableGlobalFilter={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableBottomToolbar={false}
        muiTableContainerProps={{sx: {maxHeight: `${tableHeight}px`}}}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
          },
        }}
        muiTableBodyCellProps={{
          style: {fontSize: '15px', cursor: 'pointer'},
        }}
        muiTableBodyRowProps={({row}: any) => ({
          onClick: () => {
            stopRedirection && handleClick && handleClick(row?.original)
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </>
  )
}

export default SimpleDataTable
