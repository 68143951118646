/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, registerInvite} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {storeLocalUser, useAuth} from '../core/Auth'
import ErrorAlert, {ErrorType} from '../../errors/ErrorAlert'

const initialValues = {
  profile_pic: null,
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  // profile_pic: Yup.mixed().required(),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function RegistrationInvite() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const {token, id} = useParams<any>()
  const [errorBody, setErrorBody] = useState<ErrorType>()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setErrorBody({error: false, error_list: []})
      if (values?.profile_pic != null) {
        let pic = values?.profile_pic as File
        let img_kb = pic?.size / 1024
        if (img_kb > 1024 * 2) {
          setErrorBody({
            error: true,
            error_list: [
              `
           Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
                pic?.size /
                1024 /
                1024
              ).toFixed(2)}MB
          `,
            ],
          })
          return
        }
      }
      setLoading(true)
      try {
        const {data} = await registerInvite(
          values.profile_pic,
          token as string,
          Number(id),
          values.password,
          values.changepassword
        )
        const user_auth = data.user
        await saveAuth(user_auth)
        const {data: user} = await getUserByToken(user_auth.access)
        await storeLocalUser({...user_auth, ...user})
        await setCurrentUser(user)
      } catch (error: any) {
        saveAuth(undefined)
        if (error.response.status == 409) {
          setErrorBody(error.response.data)
          setLoading(false)
        }
        setStatus(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Accept Invite</h1>
      </div>

      {errorBody && <ErrorAlert error_response={errorBody} />}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        {/* <label className='form-label fw-bolder text-dark fs-6'>Email</label> */}
        <input
          type='file'
          autoComplete='off'
          accept='image/*'
          onChange={(e) => {
            try {
              if (e.currentTarget.files != null) {
                let file = e.currentTarget.files[0]
                formik.setFieldValue('profile_pic', file)
              }
            } catch (e) {}
          }}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.profile_pic && formik.errors.profile_pic},
            {
              'is-valid': formik.touched.profile_pic && !formik.errors.profile_pic,
            }
          )}
        />
        {formik.touched.profile_pic && formik.errors.profile_pic && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.profile_pic}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          {/* <label className='form-label fw-bolder text-dark fs-6'>Password</label> */}
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        {/* <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label> */}
        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?{' '}
          <Link to='/auth' className='link-primary fw-bolder'>
            Sign in
          </Link>
        </div>
        {/* <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link> */}
      </div>
      {/* end::Form group */}
    </form>
  )
}
