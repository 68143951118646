/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect, useState, useRef, useMemo} from 'react'
import {FC} from 'react'

import {useIntl} from 'react-intl'
import {Link, useParams, useSearchParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {QuotesModel} from '../../modules/model/quotes'
import {currnetCompanyName, quotesList} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {Menu, Tooltip} from '@mui/material'
import {HAS_QuoteCreate, HAS_QuotePageView, HAS_QuoteTable} from './PermissionFunction'
import {useLocalStorage} from './GlobalComponents'
import CloseIcon from '@mui/icons-material/Close'
import blank from './../../../_metronic/layout/components/header/blank.png'
import moment from 'moment'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import {MaterialDataTable} from './MatrialDataTable'
import {KTSVG} from '../../../_metronic/helpers'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {ExportToCsv} from 'export-to-csv'
import {Modal} from 'react-bootstrap'
import CustomStatus from './CustomStatus'
import FilterByTag from './FilterByTag'

import {AddNote} from './AddNote'
import {Quote} from './Quote'

const Quotes: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [totalRows, seTotalRows] = useState(0)
  const [loading, setLoading] = useState<any>(true)
  const [filtersT, setfiltersT] = useState('')
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [searchParams, setSearchParams] = useSearchParams()
  const SELECTED_ALL = 'all'
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const headerContext = useContext<any>(HeaderContext)
  const {id} = useParams()
  const [option, setOption] = useState<any>([])
  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])
  const [tableStorage, setTableStorage] = useLocalStorage('quoteColsVisibility', '')
  const [modal, setModalShow] = useState<any>(false)
  const [tag, setTag] = useState<any>('all')
  const _selectStatus = useRef<any>()
  const _selectTag = useRef<any>()
  const [createModal, setCreateModal] = useState(false)
  const [newModal, setNewModal] = useState<any>(false)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [modalHeading, setModalHeading] = useState<string>('') // Initialize with an empty string
  const [modalTitle, setModalTitle] = useState<string>('') // Initialize with an empty string

  const onModalClose = useCallback(() => {
    setNewModal(false)
  }, [])

  const HandleCloseModal = useCallback(() => {
    setModalShow(false)
  }, [])

  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: QuotesModel) => 'Q-' + row.label,
        size: 70,
        header: 'Quote #', //uses the default width from defaultColumn prop
        accessorKey: 'quote_id',
      },
      {
        accessorFn: (row: QuotesModel) => row.customer.company,
        header: 'Customer',
        accessorKey: 'customer_name',
      },
      {
        header: 'Project Name',
        accessorFn: (row: QuotesModel) => row.project_name,
        size: 200, //increase the width of this column
        accessorKey: 'project_name',
      },
      {
        header: 'Assigned To',
        accessorFn: (row: QuotesModel) =>
          row?.assigned_to ? (
            row?.assigned_to.map((eachUser) => {
              return (
                <Tooltip
                  title={
                    <span style={{fontSize: '15px'}}>
                      {eachUser?.first_name + '  ' + eachUser?.last_name || 'N/A'}
                    </span>
                  }
                  className='p-2 '
                >
                  <span>
                    <img
                      alt='emp logo'
                      src={`${eachUser?.profile_image || '/media/avatars/blank.png'}`}
                      onError={(evt: any) => {
                        evt.target.src = blank
                      }}
                      width='40px'
                      height={'40px'}
                      className='mx-1'
                      style={{objectFit: 'contain', borderRadius: '50%'}}
                    />
                  </span>
                </Tooltip>
              )
            })
          ) : (
            <></>
          ),
        size: 200, //increase the width of this column
        accessorKey: 'assigned_to',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
        header: 'Date Created',
        size: 100, //decrease the width of this column
        accessorKey: 'date_created',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.date_updated, 'DD-MM-YYYY').format('ll'),
        header: 'Last Modified',
        size: 100, //decrease the width of this column
        accessorKey: 'last_modified',
      },
      {
        accessorFn: (row: QuotesModel) => (
          <Link to={`/${prefixCompany}/project/${row?.id}/overview`} state={{data: row}}>
            <span
              style={{
                backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
                color: `${row.status_type.color ?? '#054701'}`,
                fontSize: '11px',
                textAlign: 'center',
                padding: '5px 15px',
                display: 'block',
                maxWidth: 'fit-content',
              }}
              title={row.status_type.title}
            >
              {row.type}
            </span>
          </Link>
        ),
        header: 'Status',
        size: 170, //decrease the width of this column
        accessorKey: 'quote_status',
      },
    ],
    []
  )
  // useEffect(() => {
  //   _selectStatus.current =
  //
  // }, [selected,]);
  const [data, setData] = useState<QuotesModel[]>([])
  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(getPagination?.pageIndex, '', SELECTED_ALL, SELECTED_ALL, getPagination?.pageSize)
  }, [])

  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      quote_type = SELECTED_ALL,
      tag_type: string[] | string = SELECTED_ALL,
      pagePerEntries: number = 100
      // pagePerEntries: number = rowsPerPage
    ) => {
      await quotesList(page, str, quote_type, tag_type, pagePerEntries, id)
        .then((res) => {
          let employes = res.data.quotes as QuotesModel[]
          setData(employes)
          seTotalRows(res.data.totalRows)
          setOption(res?.data?.status)
          const stateHeader = {
            payload: {subtitle: `${res.data.totalRows} Total Records`},
            type: HeaderAction.Update,
          }
          setLoading(false)
          headerContext.dispatch(stateHeader)
        })
        .catch((e) => {
          setLoading(false)
        })
    },
    []
  )

  const handleTagFunctionality = useCallback((tag_data: string[] | string) => {
    _selectStatus.current = _selectStatus.current.length > 0 ? _selectStatus.current : 'all'
    setTag(tag_data)
    setLoading(true)
    getList(0, '', _selectStatus.current, tag_data, 100)
    return
  }, [])
  useEffect(() => {
    setLoading(true)
    getList(0)
  }, [])

  useEffect(() => {
    const search = searchParams.get('str') as string
    setLoading(true)
    getList(0, search, _selectStatus.current, _selectTag.current)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openModal = () => {
    setModalShow(1)
    setAnchorEl(null)
    setModalHeading('Edit Quote Status')
    setModalTitle('Add New Quote Status')
  }

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Open Quotes',
        subtitle: `Total Records`,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/quotes/create`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: true,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  const [selected, setSelected] = useState<any>([])
  const isAllSelected = option.length > 0 && selected.length === option.length

  const handleChange = useCallback((event: any) => {
    setLoading(true)
    const value = event.target.value
    if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
      setSelected([])
      getList(0, '', SELECTED_ALL, _selectTag.current)
      return
    } else {
      setSelected(value)
      getList(0, '', value, _selectTag.current)
    }
  }, [])
  useEffect(() => {
    _selectStatus.current = selected.length > 0 ? selected : 'all'
    _selectTag.current = tag
  }, [selected, tag])

  const CSV_HEADER = useMemo(
    () => [
      'Quote Id',
      'Customer Name',
      'Project Name',
      'Assigned To',
      'Date Created',
      'Last Modified',
      'Status',
    ],
    []
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx) => {
        return [
          'Q-' + eachIdx?.label,
          eachIdx?.customer?.company,
          eachIdx?.project_name,
          eachIdx?.assigned_to[0]?.first_name + ',' + eachIdx?.assigned_to[0]?.email,
          eachIdx?.date_created,
          eachIdx?.date_updated,
          eachIdx?.status_type?.title,
        ]
      }),
    [data]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card mb-2'></div>
          </div>
        </div>
      </div>
      {HAS_QuoteTable(currentUser) ? (
        <>
          <div style={containerStyle}>
            <div style={gridStyle} className='ag-theme-alpine'>
              <MaterialDataTable
                MaterialColumns={MaterialColumns}
                stopRedirection={HAS_QuotePageView(currentUser)}
                MaterialColumnsApiData={data}
                totalRows={totalRows}
                loadingBar={loading}
                tableHeight={700}
                navigationLink={'quote'}
                additionalNavigationLink={'overview'}
                serverPagination={pagination}
                setServerPagination={(cb_data: any) => {
                  return PaginationCallback(cb_data)
                }}
                columnVisibility={tableStorage}
                cbColumnVisibility={(callbackData) => {
                  cbFunction(callbackData)
                }}
                // muiTablePaginationProps={{
                //   rowsPerPageOptions: [100, 200, 500],
                //   rowsPerPage,
                //   onRowsPerPageChange: (newRowsPerPage: any) => {
                //     console.log(newRowsPerPage)
                //     setRowsPerPage(newRowsPerPage)
                //   },
                // }}
                optionalTableHeader={
                  <div className='mui-filter-wrap card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'>
                    <h4 style={{marginLeft: '10px'}} className='fw-bolder my-5 w-115px '>
                      <KTSVG
                        path='/media/icons/duotune/general/gen083.svg'
                        className='svg-icon-4 me-3'
                      />
                      Quotes
                    </h4>
                    <div className='mui-filter-fields god'>
                      <div className='sub-god'>
                        <UsersListSearchComponent />{' '}
                      </div>
                      <FormControl className='sub-god h-35 c-width100'>
                        <InputLabel id='mutiple-select-label' style={{marginTop: '-6px'}}>
                          Filter By Status
                        </InputLabel>
                        <Select
                          labelId='mutiple-select-label'
                          style={{height: '35px'}}
                          multiple
                          value={selected}
                          label='Filter By Status'
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(',')}
                        >
                          <MenuItem value={SELECTED_ALL}>
                            <CloseIcon>
                              <Checkbox
                                checked={isAllSelected}
                                indeterminate={
                                  selected.length > 0 && selected.length < option.length
                                }
                              />
                            </CloseIcon>
                            <ListItemText primary='Clear All' />
                          </MenuItem>
                          {option.map((item: any) => (
                            <MenuItem value={item.title}>
                              <ListItemIcon>
                                <Checkbox checked={selected.indexOf(item.title) > -1} />
                              </ListItemIcon>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className='sub-god'>
                        <FilterByTag
                          callback_function={(cb_Data: string[] | string) => {
                            handleTagFunctionality(cb_Data)
                          }}
                        />
                      </div>
                    </div>
                    <div className='mui-icons'>
                      <div
                        className='flex-wrap fw-bold fs-6 m-1'
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <button
                          style={{backgroundColor: 'lightgrey'}}
                          className='btn-lightgrey btn h-35px w-35px'
                          id='basic-button'
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                            <KTSVG
                              className='svg-icon-4 m-0'
                              path='/media/icons/duotune/general/setting.svg'
                            />
                          </div>
                        </button>
                        <Menu
                          id='basic-menu'
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={handleExportData}>
                            <span>Export All Data</span>
                          </MenuItem>
                          <MenuItem onClick={openModal}>
                            <span>Edit Quote Statuses</span>
                          </MenuItem>
                          <MenuItem onClick={() => navigate(`/${prefixCompany}/quote/tags`)}>
                            <span> Edit Tags </span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setNewModal(true)
                              setAnchorEl(null)
                            }}
                          >
                            <span> Default Quote Settings </span>
                          </MenuItem>
                        </Menu>
                      </div>

                      <div className='m-1'>
                        {HAS_QuoteCreate(currentUser) ? (
                          <button
                            className='btn btn-primary h-35px w-35px fw-bold'
                            onClick={() => setCreateModal(true)}
                          >
                            <div style={{marginTop: '-10px', marginLeft: '-6px', fontSize: '20px'}}>
                              +
                            </div>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <Modal
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
            show={modal}
            onModalClose={HandleCloseModal}
            onHide={HandleCloseModal}
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <CustomStatus
              getList={getList}
              type='quotes'
              closeModal={HandleCloseModal}
              heading={modalHeading}
              title={modalTitle}
            />
          </Modal>
          <Modal
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
            show={newModal}
            onHide={() => setNewModal(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <AddNote
              subQuoteDisclaimer={false}
              disclaimer={true}
              onModalClose={onModalClose}
              onHide={() => setNewModal(false)}
            />
          </Modal>
          <Quote
            modal={createModal}
            setModal={(cb_value: boolean) => {
              setCreateModal(cb_value)
            }}
            getWorkOrderData={getList}
            projectType={''}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export {Quotes}
