/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
// import {darkErrorMessage} from '../../modules/auth/core/_requests'
export const PURCHASE_ORDER_PARTIALLY_RECEIVED = 'Partially Received'
export const PURCHASE_ORDER_RECEIVED = 'Received'
export const PURCHASE_ORDER_IN_TRANSIT = 'In-Transit'

export function CheckExistance(data: any) {
  return data !== 'undefined' ? data : 'N/A'
}

function getSavedValue(key: string, initialValue: any) {
  const localData = localStorage.getItem(key)
  if (localData) {
    const savedValue = JSON.parse(localData)
    return savedValue
  }
  if (initialValue instanceof Function) return initialValue()
  return initialValue
}

export function useLocalStorage(key: string, initialValue: string) {
  const result = getSavedValue(key, initialValue)
  const [value, setValue] = useState(result)

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])
  return [value, setValue]
}

export function onKeyDown(keyEvent: {charCode: any; keyCode: any; preventDefault: () => void}) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export const NumberFormatter = (number: any) => {
  if (isNaN(number)) {
    return '$0.00'
  }
  const plainNumber = parseFloat(number)
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(plainNumber)
  return formattedNumber
}

export const CommaNumberFormatter = (number: any): number => {
  const plainNumber = parseFloat(number)
  const formattedNumber = new Intl.NumberFormat('en-US').format(plainNumber)
  return parseInt(formattedNumber)
}

export const SubTotal = (...numbers: (number | bigint)[]) => {
  if (numbers.length === 1) {
    return formatNumber(numbers[0])
  }
  const total: any = numbers?.reduce((acc, curr) => acc * parseFloat(curr), 1)
  if (isNaN(total)) {
    return formatNumber(0)
  } else return formatNumber(total)
}

const formatNumber = (number: any) => {
  const plainNumber = parseFloat(number)
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(plainNumber)
  return formattedNumber
}

export const SumTotal = (...numbers: (number | bigint)[]) => {
  if (numbers.length === 1) {
    return formattedNumber(numbers[0])
  }
  const total = numbers.reduce((acc, curr) => acc + parseFloat(curr), 1)
  return formattedNumber(total)
}

const formattedNumber = (number: any) => {
  const plainNumber = parseFloat(number)
  const newFormattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(plainNumber)
  return newFormattedNumber
}
