import {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx'
import {ExcelModel} from './TableCompo/ExcelModel'

const Excel: React.FC = () => {
  const [data, setData] = useState<any>([])
  const [edit, setEdit] = useState(false)
  let store_id: any = []
  const cols: any = [
    {
      name: 'Name',
      selector: (row: ExcelModel) => <div>{row.Name}</div>,
    },
    {
      name: 'Company Name',
      selector: (data: ExcelModel) => <div>{data.CompanyName}</div>,
    },
    {
      name: 'Email',
      selector: (data: ExcelModel) => <div>{data.Email}</div>,
    },
    {
      name: 'Address',
      selector: (data: ExcelModel) => <div>{data.Address}</div>,
    },
    {
      name: 'Phone Number',
      selector: (data: ExcelModel) => <div>{data.Phone}</div>,
    },
  ]
  const handleUpload = (e: any) => {
    e.preventDefault()
    let files = e.target.files,
      f = files[0],
      reader = new FileReader()

    reader.onload = function (e: any) {
      var data = e.target.result
      let readedData = XLSX.read(data, {type: 'binary'})
      console.log(readedData)
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const dataParse: any = XLSX.utils.sheet_to_json(ws, {header: 0})
      setData(dataParse)
    }
    reader.readAsBinaryString(f)
  }
  const handleDownload = () => {
    var Data = [
      [
        {
          Name: 'nombre',
          CompanyName: 'carflos',
          Email: 'Somde@mail.com',
          Phone: '5484844848',
          Address: 'some street with other details....',
        },
      ],
    ]
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    Data.forEach((arr, idx) => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(arr)
      XLSX.utils.book_append_sheet(workbook, ws, `Data${idx + 1}`)
      XLSX.writeFile(workbook, 'Template.xls')
    })
  }

  const handleChange = (e: any) => {
    setEdit(true)
    store_id = e?.selectedRows
    store_id.length == 0 ? setEdit(false) : setEdit(true)
  }
  const handleDelete = () => {
    store_id.map((val: any) => {
      delete data[val.ID - 1]
    })
    setData(data.filter((e: any) => e !== undefined))
    setEdit(false)
  }

  return (
    <>
      <div className='card col-12 mb-4 p-5 d-flex'>
        <span className='col-5'>
          <p onClick={handleDownload} style={{fontSize:15}}>
            Download Template for SpreadSheet -
            <strong>
              <u style={{cursor: 'pointer'}}>Download </u>
            </strong>
          </p>
        </span>
      </div>

      <div className='card col-12 mb-4 p-5 d-flex'>
        <form>
         <Button> <input type='file' onChange={handleUpload} /></Button>
        </form>
      </div>

      <div className='card'>
        <DataTable columns={cols} data={data} selectableRows onSelectedRowsChange={handleChange} />
        {edit ? (
          <div className='d-flex flex-row-reverse'>
            <Button onClick={handleDelete} style={{marginRight: 40}}>
              Delete
            </Button>
          </div>
        ) : data.length == 0 ? null : (
          <div className='d-flex flex-row-reverse'>
            <Button onClick={handleDelete} style={{marginRight: 40}}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export {Excel}
