import React, {useState, useCallback, useEffect, useContext} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {itemService, darkMessage} from '../../modules/auth/core/_requests'
import {TextField, Button} from '@mui/material'
const SubQuoteDisclaimer = ({customerView, getDisc, disclaimer, handleDisclaimerChange}: any) => {
  // console.log({tax})
  const discVal = getDisc === '' ? disclaimer : getDisc
  return (
    <div style={{justifyContent: 'right'}}>
      <div>
        {customerView ? (
          <p dangerouslySetInnerHTML={{__html: discVal}}></p>
        ) : (
          <TextField
        style={{
              width: '100%',
              backgroundColor: 'rgb(245,248,250)',
            }}
        value={discVal}
        onChange={handleDisclaimerChange}
        multiline
        maxRows={10}
        fullWidth
      />
          // <textarea
          //   style={{
          //     width: '100%',
          //     backgroundColor: 'rgb(192, 192, 192, 0.5)',
          //   }}
          //   value={discVal}
          //   onChange={handleDisclaimerChange}
          // />
        )}
      </div>
    </div>
  )
}
export default SubQuoteDisclaimer
