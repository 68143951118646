/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {createSearchParams, useNavigate} from 'react-router-dom'

import {FormControl, InputLabel, LinearProgress, MenuItem, Select} from '@mui/material'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../../_metronic/layout/MasterLayout'
import {ListsWidget10} from '../../../../_metronic/partials/widgets/lists/ListsWidget10'
import {useAuth} from '../../../modules/auth'
import {
  viewQuote,
  getCustomerActivities,
  currnetCompanyName,
  campaignsList,
} from '../../../modules/auth/core/_requests'

export function Campaigns() {
  const {id} = useParams<any>()
  const [listLoading, setListLoading] = useState(false)

  const [data, setData] = useState<any>([])
  const location = useLocation()
  const profileContext: any = useContext(ProfileContext)
  const {currentUser} = useAuth()
  const companyName = currnetCompanyName(currentUser?.company)
  const [selectedClient, setSelectedClient] = useState('all')

  const [searchTerm, setSearchTerm] = useState<string>('')
  const navigate = useNavigate()

  const debouncedSearchTerm = useDebounce(searchTerm, 1000)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length > 0) {
      navigate({
        search: createSearchParams({
          str: searchTerm,
        }).toString(),
      })
    } else if (searchTerm.length === 0) {
      navigate({
        search: createSearchParams({
          str: '',
        }).toString(),
      })
    }
    return () => {}
  }, [debouncedSearchTerm])

  const SEARCH_QUOTE = `/${companyName}/customers/view/${id}`

  const getList = useCallback(
    async (activity_type = 'all') => {
      setListLoading(true)
      await viewQuote(id, activity_type, null)
        .then((res) => {
          let data = res.data.activities_grouping as any[]
          setData(data || [])
          setListLoading(false)
        })
        .catch((e) => {
          setListLoading(false)
        })
    },
    [id]
  )

  const getCustomerList = useCallback(
    async (activity_type = 'all') => {
      setListLoading(true)
      await getCustomerActivities(id, activity_type)
        .then((res) => {
          let data = res.data.data as any[]
          setData(data || [])
          setListLoading(false)
          setSelectedClient('all')
        })
        .catch((e) => {
          setListLoading(false)
        })
    },
    [id]
  )

  const handleActive = useCallback((event: any) => {
    console.log(event.target.value)
    // if (event.target.value === 'all') {
    //   setSelectedClient('all')
    // }
    setSelectedClient(event.target.value)
    SEARCH_QUOTE === location.pathname
      ? getCustomerList(event?.target?.value)
      : getList(event?.target?.value)
  }, [])

  useEffect(() => {
    SEARCH_QUOTE === location.pathname ? getCustomerList() : getList()
    profileContext?.state?.setStatus(false)
    setSelectedClient('all')
  }, [profileContext?.state?.clockIn, profileContext?.state?.status])

  const options = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Quote',
      value: 'quote',
    },
    {
      label: 'Files',
      value: 'file',
    },
    {
      label: 'Notes',
      value: 'note',
    },
    {
      label: 'Order Status',
      value: 'status',
    },
    {
      label: 'Clocked',
      value: 'clocked',
    },
    // {
    //   label: 'General Note',
    //   value: 'general note',
    // },
    // {
    //   label: 'Customer Communication',
    //   value: 'customer communication',
    // },
  ]
  const [option, setOption] = useState(options)

  const handleSearch = useCallback((event: any) => {
    setSearchTerm(event.target.value)
    SEARCH_QUOTE === location.pathname
      ? getCustomerList(event?.target?.value)
      : getList(event?.target?.value)
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col-md-10 my-5 d-inline-flex align-items-center serach-wrapper'>
          <h3 className='fw-bolder me-5'>
            Project Activity
            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>30 Days</span> */}
          </h3>
          {/* begin::Search */}
          <div
            className='d-flex align-items-center position-relative ml-5 border-1 '
            style={{border: '1px solid lightgray', borderRadius: '50px'}}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search'
              style={{borderRadius: '50px'}}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='col-md-2 my-5  '>
          <FormControl fullWidth>
            <InputLabel id='all-customer'>Activity</InputLabel>
            <Select
              labelId='all-customer'
              id='demo-simple-select'
              value={selectedClient}
              label='Activity'
              onChange={handleActive}
            >
              {option.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {/* <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Project Activity */}
      {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>30 Days</span> */}
      {/* </h3> */}

      {/* <div className='d-flex align-items-center my-2'>
          <div className='w-120px me-5'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm w-120'
              defaultValue='1'
            >
              <option value='today'>Today</option>
              <option value='week'>This Week</option>
              <option value='month'>This Month</option>
              <option value='year'>This Year</option>
            </select>
          </div>
        </div> */}
      {/* </div> */}

      <div className='row mt-9 activity-content'>
        {listLoading ? (
          <LinearProgress />
        ) : Object.keys(data).length === 0 ? (
          <>
            <div className='text-center bg-white' style={{padding: '20px'}}>
              There is no data to display
            </div>
          </>
        ) : (
          Object.keys(data).map((idx) => {
            let item = data[idx]
            return (
              <ListsWidget10
                className='rounded p-2 pt-5 pb-5 m-0'
                list={item || []}
                title={idx}
                getList={getList}
                getCustomerList={getCustomerList}
              />
            )
          })
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap pt-10'>
        {/* <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div> */}

        {/* <ul className='pagination'>
          <li className='page-item previous'>
            <a href='#' className='page-link'>
              <i className='previous'></i>
            </a>
          </li>

          <li className='page-item active'>
            <a href='#' className='page-link'>
              1
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              2
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              3
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              4
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              5
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              6
            </a>
          </li>

          <li className='page-item next'>
            <a href='#' className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </ul> */}
      </div>
    </>
  )
}
