import {createContext, useEffect, useReducer, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {dispatchHeader, HeaderContext, headerInitalState} from '../../app/context/HeaderContext'
import {useAuth} from '../../app/modules/auth'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export const ProfileContext = createContext({})

const MasterLayout = () => {
  const {currentUser} = useAuth()
  const [state, dispatch] = useReducer<any>(dispatchHeader, headerInitalState)
  const [clockIn, setClockIn] = useState<boolean>(false)
  const [profilepic, setProfilePic] = useState(currentUser)
  const [status, setStatus] = useState<Boolean>(false)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <ToastContainer />
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <ProfileContext.Provider
            value={{state: {clockIn, setClockIn, profilepic, setProfilePic, status, setStatus}}}
          >
            <HeaderContext.Provider value={{state: state, dispatch: dispatch}}>
              <div
                className='wrapper main-content-block d-flex flex-column flex-row-fluid'
                id='kt_wrapper'
              >
                <HeaderWrapper />
                <div id='kt_content' className='content  d-flex flex-column flex-column-fluid p-0'>
                  <div className='post datatable-cont d-flex flex-column-fluid' id='kt_post'>
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                </div>
                <Footer />
              </div>
            </HeaderContext.Provider>
          </ProfileContext.Provider>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
