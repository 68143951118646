/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  addAssignedItems,
  darkErrorMessage,
  darkMessage,
  deleteAssignedItems,
  fetchAssignedItems,
  fetchSubquoteAssignedItems,
  inventoryData,
  inventoryListForPO,
  updateAssignedItems,
} from '../../../modules/auth/core/_requests'
import moment from 'moment'
import {
  Autocomplete,
  Box,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import MaterialReactTable from 'material-react-table'
import {Button, Modal} from 'react-bootstrap'
import {ErrorMessage, Field, FieldArray, Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import blank from '../../../../_metronic/layout/components/header/blank.png'
import {CommaNumberFormatter, useLocalStorage} from '../GlobalComponents'
import {ApiContext} from '../ProfilePage'
import {TimeSheetInitialConstant, TimeSheetTextConstant} from '../../../context/TimeSheetConstant'
import ClearIcon from '@mui/icons-material/Clear'
import {OptionsFactory} from 'ag-grid-community/dist/lib/filter/provided/optionsFactory'
import {ExportToCsv} from 'export-to-csv'
import {setAriaColCount} from 'ag-grid-community/dist/lib/utils/aria'
const initialValues = {
  assigned_item: [{inventory_id: '', assigned_items: ''}],
}
const assignedItemsValidations = Yup.object().shape({
  assigned_item: Yup.array()
    .of(
      Yup.object().shape({
        inventory_id: Yup.string().required('Inventory Item is required'),
        assigned_items: Yup.string().required('No of Assigned items is required'),
      })
    )
    .required('Must have Item'),
})

export function ItemsAssigned() {
  const [count, setCount] = useState(0)
  const value = useContext<any>(ApiContext)
  const [data, setData] = useState<any>([])
  const [subQuoteData, setSubQuoteData] = useState<any>([])
  const {id} = useParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null)
  const open1 = Boolean(anchorEl1)
  const [createModal, setCreateModal] = useState(false)
  const new_open = Boolean(anchorEl)
  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState<any>(null)
  const [deleteModal, setDeleteModal] = useState<any>({
    show: false,
  })
  const [updateModal, setUpdateModal] = useState<any>({
    show: false,
  })
  const [itemsAssignedStorage, setItemsAssignedStorage] = useLocalStorage(
    'projectItemsAssignedColsVisibility',
    ''
  )
  const TIMESHEET_INITIAL =
    value.title === TimeSheetTextConstant?.QUOTE
      ? TimeSheetInitialConstant.QUOTE
      : value.title === TimeSheetTextConstant?.WORKORDER
      ? TimeSheetInitialConstant.WORKORDER
      : TimeSheetInitialConstant.FIELDSERVICE
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [open, setOpen] = useState(false)
  const [existingItems, setExistingItems] = useState('0')
  const [options, setOptions] = useState([])
  const [updateDataTable, setUpdateDataTable] = useState(null)
  const [selectedValue2, setSelectedValue2] = useState([])
  const autoCompleteLoading = open && options.length === 0

  const cbAssignedItems = useCallback((columnScope: {}) => {
    setItemsAssignedStorage(columnScope)
  }, [])
  const mappedDataState1 = data.map(
    (
      row: {
        id: any
        item_name: any
        item_mfg: {name: any}
        inventory: {sku: any; name: any; item_cost: any; retail_price: any}
        assigned_items: any
        item_mfg_part: any
        quantity: number
        item_cost: number
        markup: number
        priceUnit: any
      },
      index: number
    ) => {
      const prices = row?.item_cost + Number(row?.markup)

      return {
        id: row.id,
        item_name: row.inventory?.name,
        item_mfg: row.inventory?.name,
        item_mfg_part: row.inventory?.sku,
        quantity: row?.assigned_items,
        item_cost: row.inventory?.item_cost,
        // markup: row?.markup,
        // priceUnit: row?.priceUnit,
        Total: prices * row?.quantity,
      }
    }
  )

  const mappedDataState2 = subQuoteData.map(
    (
      row: {
        id: any
        item_name: any
        item_mfg: {name: any}
        item_mfg_part: any
        quantity: number
        item_cost: number
        markup: number
        priceUnit: any
      },
      index: number
    ) => {
      const prices = row?.item_cost + Number(row?.markup)
      return {
        id: row.id,
        item_name: row?.item_name,
        item_mfg: row?.item_mfg.name,
        item_mfg_part: row?.item_mfg_part,
        quantity: row?.quantity,
        item_cost: row?.item_cost,
        markup: row?.markup,
        priceUnit: row?.priceUnit,
        Total: prices * row?.quantity,
      }
    }
  )

  const mergedData = [...mappedDataState1, ...mappedDataState2]

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.item_mfg_part,
        header: 'MFG. PART. #',
        accessorKey: 'assigned_item_sku',
      },
      {
        accessorFn: (row: any) => row?.item_mfg,
        header: 'Manufacturer',
        accessorKey: 'assigned_item_manufacturer',
      },
      {
        accessorFn: (row: any) => row?.item_name,
        header: 'Item Name',
        accessorKey: 'assigned_item_name',
      },
      {
        header: 'QTY on Order',
        accessorFn: (row: any) => row?.quantity,
        size: 150, //increase the width of this column
        accessorKey: 'assigned_items_qty_on_order',
      },
      {
        accessorFn: (row: any) => `$${row?.item_cost}`,
        header: 'Item Rate',
        accessorKey: 'assigned_item_rate',
      },
      {
        accessorFn: (row: any) => `$${row?.item_cost * row?.quantity}`,
        header: 'Total',
        accessorKey: 'assigned_rate_total',
      },
    ],
    []
  )
  useEffect(() => {
    if (!createModal) {
      setOptions([])
    }
  }, [createModal])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (row_Data: any, event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setRowData(row_Data)
  }

  const handleChange1 = (index: any, newValue: any, selectedValue: any) => {
    const updatedSelectedValue: any = [...selectedValue]
    if (newValue) {
      const optionIndex = options.findIndex((option) => option === newValue)
      updatedSelectedValue[index] = optionIndex >= 0 ? options[optionIndex] : null
    } else {
      updatedSelectedValue[index] = null
    }
    setSelectedValue2(updatedSelectedValue)
  }

  const handleChange2 = (index: any, newValue: any, selectedValue: any) => {
    const updatedSelectedValue: any = [...selectedValue]
    if (newValue) {
      const optionIndex = options.findIndex((option) => option === newValue)
      updatedSelectedValue[index] = optionIndex >= 0 ? options[optionIndex] : null
    } else {
      updatedSelectedValue[index] = null
    }
    setSelectedValue2(updatedSelectedValue)
  }

  const getItemsList = useCallback(async () => {
    setLoading(true)
    await inventoryListForPO()
      .then((res) => {
        const inventoryItems = res?.data?.items
        setOptions(inventoryItems)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (createModal) {
      getItemsList()
    }
  }, [createModal])

  const handleDelete = useCallback(() => {
    const deleteData = {
      project_id: id,
      inventory_id: rowData?.inventory?.id,
      assigned_item_id: rowData?.id,
    }
    deleteAssignedItems(deleteData)
      .then(({data}) => {
        setLoading(false)
        setDeleteModal({
          show: false,
        })
        getList()
        getLists()
        darkMessage(data?.message)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
          console.error(e?.response?.data)
        }
        console.error(e)
        setLoading(false)
      })
  }, [id, rowData])

  const handleUpdate = useCallback(() => {
    const deleteData = {
      project_id: id,
      inventory_id: rowData?.inventory?.id,
      assigned_item_id: rowData?.id,
      assigned_items: existingItems,
    }
    updateAssignedItems(deleteData)
      .then(({data}) => {
        setLoading(false)
        setUpdateModal({
          show: false,
        })
        getList()
        getLists()
        darkMessage(data?.message)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
          console.error(e?.response?.data)
        }
        console.error(e)
        setLoading(false)
      })
  }, [existingItems, id, rowData])
  const getList = useCallback(async () => {
    try {
      setLoading(true)
      const res = await fetchAssignedItems(id)
      console.log(res.data)
      setData(res?.data?.result)
    } catch (error) {
      console.log(error)
      setData([])
    } finally {
      setLoading(false)
    }
  }, [id])

  const getLists = useCallback(async () => {
    try {
      setLoading(true)
      console.log('id')
      const res = await fetchSubquoteAssignedItems(id)
      console.log(res.data)
      setSubQuoteData(res?.data?.data)
    } catch (error) {
      console.log(error)
      setSubQuoteData([])
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    getList()
    getLists()
  }, [getList, getLists])

  // const getList = useCallback(async () => {
  //   setLoading(true)
  //   await fetchAssignedItems(id)
  //     .then((res) => {
  //       console.log(res.data)
  //       setData(res?.data?.result)
  //       setLoading(false)
  //     })
  //     .catch((e) => {
  //       setLoading(false)
  //       setData([])
  //       console.log(e)
  //     })
  // }, [id])

  // const getLists = useCallback(async () => {
  //   setLoading(true)
  //   console.log('id')
  //   await fetchSubquoteAssignedItems(id)
  //     .then((res) => {
  //       console.log(res.data)
  //       setSubQuoteData(res?.data?.data)
  //       setLoading(false)
  //     })
  //     .catch((e) => {
  //       setLoading(false)
  //       setData([])
  //       console.log(e)
  //     })
  // }, [id])
  // useEffect(() => {
  //   getList()
  //   getLists()
  // }, [getList, getLists])

  const PaginationCallback = useCallback((getPagination: any) => {
    getList()
  }, [])

  const CSV_HEADER = useMemo(
    () => ['MFG. PART. #', 'Manufacturer', 'Item Name', 'QTY on Order', 'Item Rate', 'Total'],
    [data]
  )

  const CSV_DATA = useMemo(
    () =>
      mergedData.map((eachIdx: any) => {
        return [
          eachIdx?.item_mfg_part,
          eachIdx?.item_mfg,
          eachIdx?.item_name,
          eachIdx?.quantity,
          `$${eachIdx?.item_cost}`, // Add "$" sign before item_cost
          `$${eachIdx?.quantity * eachIdx?.item_cost}`, // Show total cost as "$totalCost"
        ]
      }),
    // data.map((eachIdx: any) => {
    //   console.log(data)
    //   return [
    //     eachIdx.inventory.sku,
    //     eachIdx.inventory.item_name,
    //     // eachIdx.assigned_items,
    //     // eachIdx.inventory.quantity_in_store,
    //     // eachIdx.reserved_quantity,
    //     // eachIdx.inventory.quantity_on_order,
    //     // Math.abs(eachIdx.inventory.need_to_order),
    //     // eachIdx.user.first_name + ' ' + eachIdx.user.last_name,
    //     // moment(eachIdx.created_at).format('ll'),
    //     // eachIdx.item_image,
    //   ]
    // }),
    [mergedData]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  return (
    <>
      {loading && <LinearProgress />}
      <MaterialReactTable
        columns={MaterialColumns}
        data={mergedData}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
          },
        }}
        muiTableBodyCellProps={{
          style: {fontSize: '15px', cursor: 'pointer'},
        }}
        renderTopToolbarCustomActions={({table}) => (
          <div className='card-title m-0 d-flex d-inline align-items-center'>
            <div className='m-2'>
              <Button
                className='btn btn-secondary'
                onClick={() => setCreateModal(true)}
                variant='contained'
                type='button'
              >
                Assign New Item
              </Button>
            </div>
            <div className='m-2'>
              <Button
                className='btn btn-secondary'
                onClick={() => handleExportData()}
                variant='contained'
                type='button'
              >
                Export All Data
              </Button>
            </div>
          </div>
        )}
      />
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setCreateModal(false)
        }}
        show={createModal}
        className='shadow p-3'
        onHide={() => setCreateModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Add Item to {TIMESHEET_INITIAL + value?.data?.label}</h1>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, {resetForm}) => {
            let modalData: any[] = []
            const Items = values?.assigned_item
            Items.map(({inventory_id, assigned_items}) => {
              return modalData.push({
                project_id: id,
                inventory_id: inventory_id,
                assigned_items: assigned_items,
              })
            })

            setLoading(true)
            addAssignedItems(JSON.stringify(modalData))
              .then(({data}) => {
                setLoading(false)
                setSelectedValue2([])
                resetForm()
                setCreateModal(false)
                darkMessage('New Items assigned successfully')
                getList()
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  darkErrorMessage(e?.response?.data.error_list[0])
                  console.error(e?.response?.data)
                }
                console.error(e)
                setLoading(false)
              })
          }}
        >
          {({values, errors, getFieldProps, setFieldValue, isValid}) => (
            <Form>
              <Modal.Body style={{height: '700px', overflow: 'scroll'}}>
                <FieldArray name='assigned_item'>
                  {({insert, remove, push}) => (
                    <div>
                      {values.assigned_item.length > 0 &&
                        values.assigned_item.map((assigned_item: any, index) => (
                          <div
                            className='row p-6 '
                            style={{border: '1px solid darkgrey'}}
                            key={index}
                          >
                            <div className='row mb-6'>
                              <div className='col-lg-5 fv-row'>
                                <Autocomplete
                                  id={`assigned_item.${index}.inventory_id`}
                                  sx={{width: 400}}
                                  onOpen={() => {
                                    setOpen(true)
                                  }}
                                  onClose={() => {
                                    setOpen(false)
                                  }}
                                  clearIcon={
                                    <ClearIcon
                                      onClick={() =>
                                        setFieldValue(`assigned_item.${index}.item`, '')
                                      }
                                    />
                                  }
                                  onChange={(event, newValue, idx) => {
                                    handleChange1(index, newValue, selectedValue2)
                                    setFieldValue(
                                      `assigned_item.${index}.inventory_id`,
                                      newValue?.id
                                    )
                                    setFieldValue(`assigned_item.${index}.item`, newValue)
                                  }}
                                  getOptionLabel={(option: any) => option.sku}
                                  options={options}
                                  value={selectedValue2[index] || null}
                                  loading={autoCompleteLoading}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='MFG. PART. #'
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {autoCompleteLoading ? (
                                              <CircularProgress color='inherit' size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className='col-lg-6 fv-row'>
                                <Autocomplete
                                  id={`assigned_item.${index}.item_name`}
                                  sx={{width: 500}}
                                  onOpen={() => {
                                    setOpen(true)
                                  }}
                                  onClose={() => {
                                    setOpen(false)
                                  }}
                                  clearIcon={
                                    <ClearIcon
                                      onClick={() =>
                                        setFieldValue(`assigned_item.${index}.item`, '')
                                      }
                                    />
                                  }
                                  getOptionLabel={(option: any) => option.name}
                                  options={options}
                                  onChange={(event, newValue, idx) => {
                                    handleChange2(index, newValue, selectedValue2)
                                    setFieldValue(
                                      `assigned_item.${index}.inventory_id`,
                                      newValue?.id
                                    )
                                    setFieldValue(`assigned_item.${index}.item`, newValue)
                                  }}
                                  value={selectedValue2[index] || null}
                                  loading={autoCompleteLoading}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Item name'
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {autoCompleteLoading ? (
                                              <CircularProgress color='inherit' size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              {index > 0 && (
                                <div className='col-lg-1 d-flex justify-content-center'>
                                  <button
                                    className='btn btn-secondary'
                                    onClick={() => remove(index)}
                                    type='button'
                                    style={{float: 'right'}}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                              <div className='row mb-6 mt-5'>
                                <div className='col-lg-4 fv-row'>
                                  <label>MFG.</label>
                                  <input
                                    type='text'
                                    readOnly
                                    placeholder='MFG.'
                                    value={
                                      assigned_item && assigned_item.item
                                        ? assigned_item?.item?.brand?.name
                                        : ''
                                    }
                                    style={{
                                      borderColor: 'darkgrey',
                                      padding: '13px',
                                    }}
                                    className='form-control form-control-lg form-control-solid bg-white border-1 rounded'
                                  />
                                </div>
                                <div className='col-lg-4 fv-row'>
                                  <label>Available Stock</label>
                                  <input
                                    type='text'
                                    readOnly
                                    id={`assigned_item.${index}.available_stock`}
                                    placeholder='Available Stock'
                                    value={
                                      assigned_item && assigned_item.item
                                        ? assigned_item.item.available_stock
                                        : ''
                                    }
                                    style={{
                                      borderColor: 'darkgrey',
                                      padding: '13px',
                                    }}
                                    className='form-control form-control-lg form-control-solid bg-white border-1 rounded'
                                  />
                                </div>
                                <div className='col-lg-4 fv-row'>
                                  <br />
                                  <Field
                                    as={TextField}
                                    variant='outlined'
                                    label='Quantity'
                                    type='number'
                                    name={`assigned_item.${index}.assigned_items`}
                                    min={0}
                                    onChange={(e: {target: {value: any}}) =>
                                      setFieldValue(
                                        `assigned_item.${index}.assigned_items`,
                                        e?.target?.value
                                      )
                                    }
                                    className='form-control form-control-lg form-control-solid bg-white border-1 rounded'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className='row mb-6 mt-5'>
                        <div className='col-md-12'>
                          <button
                            className='btn btn-primary me-lg-5'
                            style={{float: 'right'}}
                            type={'button'}
                            onClick={() => push({inventory_id: '', assigned_items: ''})}
                          >
                            Add Another Item
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </Modal.Body>
              {/* <button type='submit'>Invite</button> */}
              <Modal.Footer>
                <div className='d-flex flex-row-reverse mx-2 '>
                  <span className='mx-2'>
                    <button className='btn btn-primary' disabled={loading || !isValid}>
                      {!loading && 'Assign Items'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </span>
                  <span className='mx-1'>
                    <button
                      onClick={() => {
                        setCreateModal(false)
                        setSelectedValue2([])
                      }}
                      className='btn btn-dark'
                    >
                      Discard
                    </button>
                  </span>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setUpdateModal({
            show: false,
          })
        }}
        show={updateModal?.show}
        className='fade'
        onHide={() =>
          setUpdateModal({
            show: false,
          })
        }
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Update Quantity</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Update number of Items
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  required
                  min={0}
                  defaultValue={updateModal?.key?.assigned_items ?? existingItems}
                  onChange={(e) => setExistingItems(e?.target?.value)}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2 '>
            <span className='mx-2'>
              <button className='btn btn-primary' onClick={() => handleUpdate()}>
                Update Qty
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  setUpdateModal({
                    show: false,
                  })
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setDeleteModal({
            show: false,
          })
        }}
        show={deleteModal?.show}
        className='fade'
        onHide={() =>
          setDeleteModal({
            show: false,
          })
        }
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Confirmation Message</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Did you really want to delete this Item ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2 '>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  handleDelete()
                }}
              >
                Remove Item
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  setDeleteModal({
                    show: false,
                  })
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Menu
        id='basic-menu-1'
        anchorEl={anchorEl}
        open={new_open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setUpdateModal({
              show: true,
            })
            setAnchorEl(null)
          }}
        >
          Update Qty
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteModal({
              show: true,
            })
            setAnchorEl(null)
          }}
        >
          Remove Item
        </MenuItem>
      </Menu>
    </>
  )
}
