import { useState } from "react"
import React from "react"

export interface SuccessType {
    message: string,
    error: boolean,
}

interface PropsType {
    success_response:SuccessType
}

const SuccessAlert = ({success_response}: PropsType)  => {

  const [visible, setVisible] = useState<boolean>(true)
  setTimeout(() => {
    // setVisible(false)
  }, 5000)
  return (
    <>
        { visible &&
        (
            <div className="container">
                <div className='mb-lg-15 alert alert-info'>
                    <div className='alert-text font-weight-bold'>
                    <b>{success_response.message}</b>
                    </div>
                </div>
            </div> 
        )}
    </>
  )
}

export default SuccessAlert