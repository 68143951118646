import React from 'react'
import {Modal} from 'react-bootstrap'

type PropsType = {
  modalShow: boolean
  title: string
  body: string
  handleClose: () => void
  confirmFunction: () => void
}

export default function DeleteModal({
  modalShow,
  title,
  body,
  handleClose,
  confirmFunction,
}: PropsType) {
  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      onModalClose={() => {
        handleClose()
      }}
      show={modalShow}
      className=''
      onHide={() => handleClose()}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='xl'
    >
      <Modal.Header>
        <Modal.Title>
          <h1>{title}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{body}</h3>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-row-reverse mx-2'>
          <span className='mx-2'>
            <button
              className='btn btn-primary'
              onClick={() => {
                confirmFunction()
              }}
            >
              Confirm
            </button>
          </span>
          <span className='mx-1'>
            <button
              onClick={() => {
                handleClose()
              }}
              className='btn btn-dark'
            >
              Discard
            </button>
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
