/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {
  VendorInterface,
  VendorInterface_Initial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {Formik, FieldArray, Field, Form, ErrorMessage} from 'formik'
import {
  createFullVendor,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  profileImg,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {LinearProgress} from '@mui/material'
import {Box} from '@mui/system'
import {onKeyDown} from './GlobalComponents'

const profileDetailsSchema = Yup.object().shape({
  vendor_name: Yup.string().required('Vendor Name is required'),
  website: Yup.string().url('Please enter a valid URL starting with http:// or https://'),
})

const VendorForm: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [inputValue, setInputValue] = useState<null | ''>('')
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [uploadLoading, setUploadLoading] = useState(false)

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Vendor',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/vendors`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any, index: any, setFieldValue: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    setFieldValue(`contacts_field.${index}.phone`, formattedPhoneNumber)
  }

  const uploadProfileImg = (event: any, setFieldValue: any) => {
    const user_img = event.target.files
    try {
      let filesToUpload: any = []
      let lengthOfFiles = Object.keys(user_img).length
      if (user_img != null || lengthOfFiles > 0) {
        let img_kb = user_img[0].size / 1024
        if (img_kb > 1024 * 2) {
          darkErrorMessage(`
          Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
            user_img[0]?.size /
            1024 /
            1024
          ).toFixed(2)}MB
         `)
          return
        }
        Object.values(user_img).map((value: any) => {
          return filesToUpload.push(value)
        })
      } else {
        console.error('file is not selected')
      }
      setUploadLoading(true)
      profileImg(filesToUpload, prefixCompany)
        .then((res) => {
          setFieldValue('image', res?.data?.user_pic)
          setUploadLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setUploadLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div id='kt_account_profile_details' className='collapse show'>
          <Formik
            initialValues={initialValues}
            validationSchema={profileDetailsSchema}
            onSubmit={(values, {resetForm}) => {
              setLoading(true)
              // *** values.contacts_field ***   under in all commented value get in array  ***
              let vendor: VendorInterface = {
                vendor_name: values?.vendor_name,
                contacts_field: values?.contacts_field,
                address_line_1: values?.address_line_1,
                address_line_2: values?.address_line_2,
                city: values?.city,
                state: values?.state,
                zip: values?.zip,
                website: values?.website,
                terms: values?.terms,
                notes: values?.notes,
                image: values.image,
                po_count: 0,
                'vendor_account#': values?.['vendor_account#'],
              }
              createFullVendor(vendor)
                .then(({data}) => {
                  setLoading(false)
                  resetForm()
                  darkMessage('Vendor created Successfully')
                  navigate(`/${prefixCompany}/inventory/vendors`)
                  setInputValue(null)
                })
                .catch((e) => {
                  if (e?.response?.status === 409) {
                    darkErrorMessage(e?.response?.data)
                    setLoading(false)
                  } else {
                    console.log(e)
                  }
                  setInputValue(null)
                })
            }}
          >
            {({values, setFieldValue}) => (
              <Form onKeyDown={onKeyDown}>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label
                      htmlFor='company'
                      className='col-lg-4 col-form-label required fw-bold fs-6'
                    >
                      Vendor Name
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Vendor Name'
                        name='vendor_name'
                      />
                      <ErrorMessage name='vendor_name' component='div' className='field-error' />
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                    <label
                      htmlFor='vendor_account#'
                      className='col-lg-4 col-form-label fw-bold fs-6'
                    >
                      Vendor Account #
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Vendor Account #'
                        name='vendor_account#'
                      />
                      <ErrorMessage
                        name='vendor_account#'
                        component='div'
                        className='field-error'
                      />
                    </div>
                  </div> */}
                  <div className='row mb-6'>
                    <label htmlFor={`cupro`} className='col-lg-4 col-form-label fw-bold fs-6'>
                      Contact Details
                    </label>
                  </div>
                  <FieldArray name='contacts_field'>
                    {({insert, remove, push}) => (
                      <div style={{border: '1px solid lightgrey'}} className='rounded'>
                        {values.contacts_field.length > 0 &&
                          values.contacts_field.map((friend, index) => (
                            <div
                              key={index}
                              className='p-9'
                              style={{border: '1px solid lightgrey'}}
                            >
                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  Contact {index + 1}
                                </label>
                                <div className='col-lg-8 fv-row align-items-right'>
                                  {index > 0 && (
                                    <div className='col'>
                                      <h4
                                        className='btn btn-secondary'
                                        onClick={() => remove(index)}
                                        style={{float: 'right'}}
                                      >
                                        X
                                      </h4>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`contacts_field.${index}.title`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Contact Title
                                </label>
                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Enter Contact Title'
                                    name={`contacts_field.${index}.title`}
                                  />
                                  <ErrorMessage
                                    name={`contacts_field.${index}.title`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`contacts_field.${index}.fName`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Contact Name
                                </label>
                                <div className='col-lg-8'>
                                  <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='First name'
                                        name={`contacts_field.${index}.fName`}
                                      />
                                      <ErrorMessage
                                        name={`contacts_field.${index}.fName`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Last name'
                                        name={`contacts_field.${index}.lName`}
                                      />
                                      <ErrorMessage
                                        name={`contacts_field.${index}.lName`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <label
                                  htmlFor={`contacts_field.${index}.email`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Email
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Email'
                                    name={`contacts_field.${index}.email`}
                                  />
                                  <ErrorMessage
                                    name={`contacts_field.${index}.email`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`contacts_field.${index}.phone`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Phone Number
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Phone Number'
                                    name={`contacts_field.${index}.phone`}
                                    onChange={(e: any) => handleInput(e, index, setFieldValue)}
                                  />
                                  <ErrorMessage
                                    name={`contacts_field.${index}.phone`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <label
                                  htmlFor={`contacts_field.${index}.alternative_contact`}
                                  className='col-lg-4 col-form-label  fw-bold fs-6'
                                >
                                  Alternative Contact
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Alternative contact'
                                    name={`contacts_field.${index}.alternative_contact`}
                                  />
                                  <ErrorMessage
                                    name={`contacts_field.${index}.alternative_contact`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className='row mb-6 mt-5'>
                          <div className='col-md-12'>
                            <button
                              className='btn btn-primary me-lg-5'
                              style={{float: 'right'}}
                              type={'button'}
                              onClick={() =>
                                push({
                                  fName: '',
                                  lName: '',
                                  email: '',
                                  phone: '',
                                  alternative_contact: '',
                                  title: '',
                                })
                              }
                            >
                              Add Another Contact
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className='row mb-6 mt-5'>
                    <label htmlFor='address' className='col-lg-4 col-form-label  fw-bold fs-6'>
                      Address
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 1'
                        name='address_line_1'
                      />
                      <ErrorMessage name='address_line_1' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label
                      htmlFor='address_line_2'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    ></label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 2'
                        name='address_line_2'
                      />
                      <ErrorMessage name='address_line_2' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='City'
                            name='city'
                          />
                          <ErrorMessage name='city' component='div' className='field-error' />
                        </div>

                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='State'
                            name='state'
                          />
                          <ErrorMessage name='state' component='div' className='field-error' />
                        </div>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Zip Code'
                            name='zip'
                          />
                          <ErrorMessage name='zip' component='div' className='field-error' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='website' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Website
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Website'
                        name='website'
                      />
                      <ErrorMessage name='website' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label htmlFor='terms' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Terms
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Terms'
                        name='terms'
                      />
                      <ErrorMessage name='terms' component='div' className='field-error' />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'>Notes</label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        component='textarea'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Vendor Notes'
                        name='notes'
                        rows={7}
                      />
                      <ErrorMessage name='notes' component='div' className='field-error' />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='image' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Vendor Logo
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='file'
                        autoComplete='off'
                        accept='image/*'
                        onChange={(e) => uploadProfileImg(e, setFieldValue)}
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage name='image' component='div' className='field-error' />
                      {uploadLoading && (
                        <Box sx={{width: '100%'}}>
                          <LinearProgress color='primary' />
                        </Box>
                      )}
                    </div>
                  </div>

                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
export {VendorForm}
