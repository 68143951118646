/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import MaterialReactTable from 'material-react-table'
import {
  currnetCompanyName,
  getInventoryVendors,
  purchaseOrderData,
  receivedPurchaseOrderData,
} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {PurchaseOrderModel} from '../../modules/model/purchaseOrder'
import {
  HAS_PurchaseOrderCreatePO,
  HAS_PurchaseOrderViewPO,
  HAS_PurchaseOrderViewTable,
} from './PermissionFunction'
import {KTSVG} from '../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import {PurchaseOrderView} from './PurchaseOrderView'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@mui/material'
import {UsersListSearchComponent_v2} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent_v2'
import {ExportToCsv} from 'export-to-csv'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {CommaNumberFormatter, NumberFormatter, useLocalStorage} from './GlobalComponents'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import {InventorySettings} from './InventorySettings'
import {VendorInterface} from '../../modules/accounts/components/settings/SettingsModel'
const SELECTED_ALL = 'all'
const SELECTED_ALL_REC_VENDOR = 'all'

const PurchaseOrders: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const [receivedOrdersCount, setReceivedOrdersCount] = useState(0)
  const [purchaseOrdersCount, setPurchaseOrdersCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [receivedLoading, setReceivedLoading] = useState(true)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState([])
  const [receivedData, setReceivedData] = useState([])
  const headerContext = useContext<any>(HeaderContext)
  const [open, setOpen] = useState(false)
  const [pdata, setPData] = useState<any>(false)
  const [index, setId] = useState()
  const navigate = useNavigate()

  const [option, setOption] = useState<any>([])
  const [selected, setSelected] = useState<any>([])
  const [selectedRecVendor, setSelectedRecVendor] = useState<any>([])
  const isAllSelected = option.length > 0 && selected.length === option.length
  const isAllSelectedRecVendor = option.length > 0 && selectedRecVendor.length === option.length

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [purchaseOrderTableStorage, setPurchaseOrderTableStorage] = useLocalStorage(
    'purchaseOrdersColsVisibility',
    ''
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openMenu = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null)
  const openMenuReceived = Boolean(anchorEl1)
  const handleClickReceived = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget)
  }
  const handleCloseReceived = () => {
    setAnchorEl1(null)
  }
  const [receivedPurchaseOrderTableStorage, setReceivedPurchaseOrderTableStorage] = useLocalStorage(
    'receivedPurchaseOrdersColsVisibility',
    ''
  )

  const cbPOFunction = useCallback((columnScope: {}) => {
    setPurchaseOrderTableStorage(columnScope)
  }, [])

  const cbReceivedPOFunction = useCallback((columnScope: {}) => {
    setReceivedPurchaseOrderTableStorage(columnScope)
  }, [])

  const setFunRec = (v: any) => {
    setId(v)
    setOpen(true)
    setPData(true)
  }
  const setFunRma = (v: any) => {
    setId(v)
    setOpen(true)
    setPData(false)
  }

  const recretM: any = (ev: boolean) => {
    setOpen(ev)
  }

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Purchase Orders',
        subtitle: '',
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/inventory/purchase-order/create`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setLoading(false)
    setReceivedLoading(false)
    let search = searchParams.get('str')
    let label = searchParams.get('label')

    if (search === null) {
      search = ''
    }

    if (label === 'In Transit Purchase Order') {
      getList(0, 100, SELECTED_ALL, search)
    } else if (label === 'Received Purchase Orders') {
      getReceivedList(0, 100, SELECTED_ALL, search)
    } else {
      getList()
      getReceivedList()
    }

    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {row.id}
              </div>
            )
          } else {
            return row.id
          }
        },
        size: 70,
        header: 'P.O.#',
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {row?.vendor?.name}
              </div>
            )
          } else {
            return row?.vendor?.name
          }
        },
        header: 'Vendor',
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {CommaNumberFormatter(row?.item_count)}
              </div>
            )
          } else {
            return CommaNumberFormatter(row?.item_count)
          }
        },
        header: 'Item Ordered',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {CommaNumberFormatter(row?.received_total)}
              </div>
            )
          } else {
            return CommaNumberFormatter(row?.received_total)
          }
        },
        header: 'Item Received',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {moment(row.date_ordered).format('ll')}
              </div>
            )
          } else {
            return moment(row.date_ordered).format('ll')
          }
        },
        header: 'Ordered Date',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {NumberFormatter(row.total_cost)}
              </div>
            )
          } else {
            return NumberFormatter(row.total_cost)
          }
        },
        header: 'PO Total',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => (
          <div
            style={{
              color: 'white',
              overflow: 'visible',
              padding: '5px 10px',
              fontSize: '13px',
              fontWeight: '600',
              borderRadius: '50px',
              display: 'table-row',
            }}
            className='bg-primary'
          >
            {row.order_status}
          </div>
        ),
        header: 'Status',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => (
          <button
            onClick={() => {
              setFunRec(row?.id)
            }}
            style={{border: 'none'}}
          >
            <KTSVG path={'/media/icons/duotune/ecommerce/ecm001.svg'} className={'svg-icon-1'} />
          </button>
        ),
        enableClick: false,
        header: 'Actions',
        size: 100, //decrease the width of this column
      },
    ],
    []
  )

  const MaterialColumnsFields: any = useMemo(
    () => [
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {row.id}
              </div>
            )
          } else {
            return row.id
          }
        },
        size: 70,
        header: 'P.O.#', //uses the default width from defaultColumn prop
      },
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {row?.vendor?.name}
              </div>
            )
          } else {
            return row?.vendor?.name
          }
        },
        header: 'Vendor',
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {CommaNumberFormatter(row?.item_count)}
              </div>
            )
          } else {
            return CommaNumberFormatter(row?.item_count)
          }
        },
        header: 'Item Ordered',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {CommaNumberFormatter(row?.received_total)}
              </div>
            )
          } else {
            return CommaNumberFormatter(row?.received_total)
          }
        },
        header: 'Item Received',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {moment(row.date_ordered).format('ll')}
              </div>
            )
          } else {
            return moment(row.date_ordered).format('ll')
          }
        },
        header: 'Ordered Date',
        size: 100, //decrease the width of this column
      },

      {
        accessorFn: (row: PurchaseOrderModel) => {
          if (HAS_PurchaseOrderViewPO(currentUser)) {
            return (
              <div
                onClick={() =>
                  navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
                }
              >
                {NumberFormatter(row.total_cost)}
              </div>
            )
          } else {
            return NumberFormatter(row.total_cost)
          }
        },
        header: 'PO Total',
        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => (
          <div
            style={{
              color: 'white',
              overflow: 'visible',
              padding: '5px 10px',
              fontSize: '13px',
              fontWeight: '600',
              borderRadius: '50px',
              display: 'table-row',
            }}
            className='bg-success'
          >
            {row.order_status}
          </div>
        ),
        header: 'Status',
        onClick: (row: {id: any}) => {
          navigate(`/${prefixCompany}/inventory/purchase-order/${row?.id}/view`)
        },

        size: 100, //decrease the width of this column
      },
      {
        accessorFn: (row: PurchaseOrderModel) => (
          <div style={{justifyContent: 'space-between'}}>
            <button
              style={{border: 'none'}}
              onClick={() => {
                setFunRma(row?.id)
              }}
            >
              <KTSVG path={'/media/icons/duotune/arrows/arr054.svg'} className={'svg-icon-1'} />
            </button>
          </div>
        ),
        header: 'Actions',
        size: 100, //decrease the width of this column
      },
    ],
    []
  )

  useEffect(() => {
    setLoading(true)
    setReceivedLoading(true)
    getList(pagination.pageIndex, pagination.pageSize)
  }, [pagination])

  const getList = useCallback(
    async (
      page: number = 1,
      pagePerEntries: number = 100,
      vendor_type = SELECTED_ALL,
      str = ''
    ) => {
      await purchaseOrderData(page, pagePerEntries, id, vendor_type, str)
        .then((res) => {
          setData(res.data.purchase_order || [])
          setPurchaseOrdersCount(res.data.total_purchase_order_rows)
          setLoading(false)
          // setLoading(true)
          // getVendorList()
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )

  const getVendorList = useCallback(
    async (page: number = 1, str = '', pagePerEntries: number = 100) => {
      setLoading(true)
      await getInventoryVendors(page, str, pagePerEntries)
        .then((res) => {
          let vendorList = res.data.vendors as VendorInterface[]
          setOption(vendorList)
          setLoading(false)
          setReceivedLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          setReceivedLoading(false)
        })
    },
    []
  )

  useEffect(() => {
    getReceivedList(pagination.pageIndex, pagination.pageSize)
  }, [pagination])

  const getReceivedList = useCallback(
    async (
      page: number = 1,
      pagePerEntries: number = 100,
      vendor_type = SELECTED_ALL,
      str = ''
    ) => {
      setReceivedLoading(true)
      await receivedPurchaseOrderData(page, pagePerEntries, id, vendor_type, str)
        .then((res) => {
          setReceivedData(res.data.received_purchase_order || [])
          setReceivedOrdersCount(res.data.total_purchase_order_rows)
          setReceivedLoading(false)
        })
        .catch(() => {
          setReceivedLoading(false)
        })
    },
    []
  )

  const updateTable = useCallback(() => {
    setLoading(true)
    setReceivedLoading(true)
    getList(1)
    getReceivedList(1)
    getVendorList()
  }, [])

  useEffect(() => {
    updateTable()
  }, [updateTable])

  const ORDER_DATA = useMemo(
    () => [
      {
        label: 'In Transit Purchase Order',
        tableCols: MaterialColumns,
        tableData: data,
        rowCount: purchaseOrdersCount,
        colsVisibility: purchaseOrderTableStorage,
        colsVisibilityFnc: (cbData: {}) => cbPOFunction(cbData),
      },
      {
        label: 'Received Purchase Orders',
        tableCols: MaterialColumnsFields,
        tableData: receivedData,
        rowCount: receivedOrdersCount,
        colsVisibility: receivedPurchaseOrderTableStorage,
        colsVisibilityFnc: (cbData: {}) => cbReceivedPOFunction(cbData),
      },
    ],
    [
      data,
      purchaseOrdersCount,
      receivedData,
      receivedOrdersCount,
      purchaseOrderTableStorage,
      receivedPurchaseOrderTableStorage,
    ]
  )
  console.log(data)
  console.log(receivedData)
  console.log(ORDER_DATA)

  // const CSV_HEADER = useMemo(
  //   () => [
  //     'Purchase Order Id',
  //     'Vendor',
  //     'Shipping Method',
  //     'Item Ordered',
  //     'Tracking Number',
  //     'Vendor Order Number',
  //     'Item Received',
  //     'Order Link',
  //     'Date Ordered',
  //     'Expected Delivery',
  //     'Shipping Cost',
  //     'PO Total',
  //     'Status',
  //     'Attachment',
  //   ],
  //   []
  // )

  // const csvOptions = useMemo(
  //   () => [
  //     {
  //       fieldSeparator: ',',
  //       quoteStrings: '"',
  //       decimalSeparator: '.',
  //       showLabels: true,
  //       useBom: true,
  //       useKeysAsHeaders: false,
  //       headers: CSV_HEADER,
  //     },
  //   ],
  //   [CSV_HEADER]
  // )

  // const CSV_DATA = useMemo(() => {
  //   data.map((eachIdx: any) => {
  //     return [
  //       eachIdx?.sku,
  //       eachIdx?.name,
  //       eachIdx?.item_description,
  //       eachIdx?.brand?.name,
  //       eachIdx?.category?.name,
  //       eachIdx?.vendor?.name,
  //       eachIdx?.inventory_location,
  //       eachIdx?.order_link,
  //       eachIdx?.reorder_level,
  //       eachIdx?.available_stock,
  //       eachIdx?.item_cost,
  //       eachIdx?.retail_price,
  //       eachIdx?.image_field,
  //     ]
  //   })
  // }, [data])

  const CSV_HEADER_TRANSIT = useMemo(
    () => [
      'PO #',
      'Vendor ',
      'Item Ordered',
      'Item Received',
      'Ordered Date',
      'PO TOtal',
      'Status',
    ],
    []
  )
  const CSV_DATA_TRANSIT = useMemo(
    () =>
      data.map((eachIdx: any) => {
        const inputDate = eachIdx?.date_ordered
        let formattedDate = ''

        if (inputDate) {
          const dateObj = new Date(inputDate)
          const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]

          formattedDate = `${
            monthNames[dateObj.getMonth()]
          } ${dateObj.getDate()}, ${dateObj.getFullYear()}`
        } else {
          console.log('Invalid input date.')
        }
        return [
          eachIdx?.id,
          eachIdx?.vendor?.name,
          eachIdx?.item_count,
          eachIdx?.received_total,
          formattedDate,
          '$' + eachIdx?.total_cost + '.00',
          eachIdx?.order_status,
        ]
      }),
    [data, CSV_HEADER_TRANSIT]
  )

  const csvOptionsTransit = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER_TRANSIT,
      },
    ],
    [CSV_HEADER_TRANSIT]
  )

  const csvExporterTransit = new ExportToCsv(csvOptionsTransit[0])

  const handleExportDataTransit = useCallback(() => {
    csvExporterTransit.generateCsv(CSV_DATA_TRANSIT)
  }, [CSV_DATA_TRANSIT])

  const CSV_HEADER_RECEIVED = useMemo(
    () => [
      'PO #',
      'Vendor ',
      'Item Ordered',
      'Item Received',
      'Ordered Date',
      'PO TOtal',
      'Status',
    ],
    []
  )
  const CSV_DATA_RECEIVED = useMemo(
    () =>
      receivedData.map((eachIdx: any) => {
        const inputDate = eachIdx?.date_ordered
        let formattedDate = ''

        if (inputDate) {
          const dateObj = new Date(inputDate)
          const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]

          formattedDate = `${
            monthNames[dateObj.getMonth()]
          } ${dateObj.getDate()}, ${dateObj.getFullYear()}`
        } else {
          console.log('Invalid input date.')
        }
        return [
          eachIdx?.id,
          eachIdx?.vendor?.name,
          eachIdx?.item_count,
          eachIdx?.received_total,
          formattedDate,
          '$' + eachIdx?.total_cost + '.00',
          eachIdx?.order_status,
        ]
      }),
    [receivedData, CSV_HEADER_RECEIVED]
  )

  const csvOptionsReceived = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER_RECEIVED,
      },
    ],
    [CSV_HEADER_RECEIVED]
  )

  const csvExporterReceived = new ExportToCsv(csvOptionsReceived[0])

  const handleExportDataReceived = useCallback(() => {
    csvExporterReceived.generateCsv(CSV_DATA_RECEIVED)
  }, [CSV_DATA_RECEIVED])

  // const CSV_RECEIVED_DATA = useMemo(() => {
  //   receivedData.map((eachIdx: any) => {
  //     return [
  //       eachIdx?.sku,
  //       eachIdx?.name,
  //       eachIdx?.item_description,
  //       eachIdx?.brand?.name,
  //       eachIdx?.category?.name,
  //       eachIdx?.vendor?.name,
  //       eachIdx?.inventory_location,
  //       eachIdx?.order_link,
  //       eachIdx?.reorder_level,
  //       eachIdx?.available_stock,
  //       eachIdx?.item_cost,
  //       eachIdx?.retail_price,
  //       eachIdx?.image_field,
  //     ]
  //   })
  // }, [receivedData])

  // const csvExporter = new ExportToCsv(csvOptions[0])

  // const handleExportData = useCallback(
  //   (dataCSV: []) => {
  //     console.log(dataCSV.length, data.length, receivedData.length)
  //     dataCSV.length === data.length ? (
  //       csvExporter.generateCsv(CSV_DATA)
  //     ) : dataCSV.length === receivedData.length ? (
  //       csvExporter.generateCsv(CSV_RECEIVED_DATA)
  //     ) : (
  //       <>{console.log('error')}</>
  //     )
  //   },
  //   [data, receivedData]
  // )

  const handleChange = useCallback((event: any) => {
    setLoading(true)
    const value = event.target.value
    if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
      setSelected(
        selected.length === option.length
          ? []
          : option.map((item: {title: any}) => {
              return item.title
            })
      )
      getList(0, 100, SELECTED_ALL)
      return
    } else {
      setSelected(value)
      getList(0, 100, value)
    }
  }, [])
  const handleChanged = useCallback((event: any) => {
    setReceivedLoading(true)
    const value = event.target.value
    if (value[value.length - 1] === SELECTED_ALL_REC_VENDOR || value.length === 0) {
      setSelectedRecVendor(
        selectedRecVendor.length === option.length
          ? []
          : option.map((item: {title: any}) => {
              return item.title
            })
      )
      getReceivedList(0, 100, SELECTED_ALL_REC_VENDOR)
      return
    } else {
      setSelectedRecVendor(value)
      getReceivedList(0, 100, value)
    }
  }, [])
  // const handleChange = useCallback((event: any, label: string) => {
  //   setLoading(true)
  //   const value = event.target.value
  //   setSelected(value)
  //   setSelectedRecVendor(value)
  //   console.log({label})
  //   if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
  //     if (label === 'In Transit Purchase Order') {
  //       getList(0, 100, SELECTED_ALL)
  //     } else if (label === 'Received Purchase Orders') {
  //       getReceivedList(0, 100, SELECTED_ALL_REC_VENDOR)
  //     }
  //   } else {
  //     if (label === 'In Transit Purchase Order') {
  //       getList(0, 100, value)
  //     } else if (label === 'Received Purchase Orders') {
  //       getReceivedList(0, 100, value)
  //     }
  //   }
  // }, [])

  return HAS_PurchaseOrderViewTable(currentUser) ? (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      {ORDER_DATA.map(
        ({label, tableCols, tableData, rowCount, colsVisibility, colsVisibilityFnc}: any) => {
          return (
            <div className='card mb-5 mb-xl-10 p-0'>
              <div>
                <div className='sticky_header_data_table'>
                  <MaterialReactTable
                    columns={tableCols}
                    muiTableHeadCellProps={{
                      sx: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                      },
                    }}
                    muiTableBodyCellProps={{
                      style: {fontSize: '14px'},
                    }}
                    muiTableBodyRowProps={({row}: any) => ({
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                    data={tableData}
                    defaultColumn={{
                      maxSize: 400,
                      minSize: 80,
                      size: 150,
                    }}
                    enableColumnResizing
                    manualFiltering
                    manualPagination
                    manualSorting
                    state={{
                      pagination,
                      showProgressBars:
                        label === 'In Transit Purchase Order' ? loading : receivedLoading,
                      columnVisibility: colsVisibility,
                      isLoading: loading,
                    }}
                    onPaginationChange={setPagination}
                    enableStickyHeader
                    enableStickyFooter
                    columnResizeMode='onChange'
                    rowCount={rowCount}
                    onColumnVisibilityChange={(e) => {
                      colsVisibilityFnc(e)
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [100, 200, 500],
                    }}
                    enableGlobalFilter={false}
                    enableColumnFilters={false}
                    muiTableContainerProps={{sx: {maxHeight: '600px'}}}
                    renderTopToolbarCustomActions={({table}) => (
                      <div
                        style={{width: '100%', gap: '10px'}}
                        className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'
                      >
                        <h4
                          className='fw-bolder m-5 w-115px'
                          onClick={() =>
                            navigate(`/${prefixCompany}/inventory/purchase-order/create`)
                          }
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen067.svg'
                            className='svg-icon-4 me-3'
                          />
                          {label}
                        </h4>
                        {/* <div className='god-father_2'> */}
                        <div
                          className={
                            label === 'In Transit Purchase Order' ? 'god-father_2' : 'god-father_v2'
                          }
                        >
                          <div
                            className={
                              label === 'In Transit Purchase Order' ? 'sub-god_2' : 'sub-god_v2'
                            }
                          >
                            <UsersListSearchComponent_v2 label={label} />{' '}
                          </div>
                          {label === 'In Transit Purchase Order' && (
                            <FormControl className='sub-god_2 h-35'>
                              <InputLabel id='multiple-select-label' style={{marginTop: '-6px'}}>
                                Filter by Vendor
                              </InputLabel>
                              <Select
                                labelId='multiple-select-label'
                                style={{height: '35px'}}
                                multiple
                                value={selected}
                                label='Filter by Vendor'
                                onChange={handleChange}
                                // onChange={(event) => handleChange(event, label)}
                                renderValue={(selected) => selected.join(',')}
                              >
                                <MenuItem value={SELECTED_ALL}>
                                  <CloseIcon>
                                    <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={
                                        selected.length > 0 && selected.length < option.length
                                      }
                                    />
                                  </CloseIcon>
                                  <ListItemText primary='Clear All' />
                                </MenuItem>
                                {option.map((item: any) => (
                                  <MenuItem value={item.name}>
                                    <ListItemIcon>
                                      <Checkbox checked={selected.indexOf(item.name) > -1} />
                                    </ListItemIcon>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {label === 'Received Purchase Orders' && (
                            <>
                              <FormControl className='sub-god_2 h-35'>
                                <InputLabel id='multiple-select' style={{marginTop: '-6px'}}>
                                  Filter by Vendor
                                </InputLabel>
                                <Select
                                  labelId='multiple-select'
                                  style={{height: '35px'}}
                                  multiple
                                  value={selectedRecVendor}
                                  label='Filter by Vendor'
                                  onChange={handleChanged}
                                  // onChange={(event) => handleChange(event, label)}
                                  renderValue={(selectedRecVendor) => selectedRecVendor.join(',')}
                                >
                                  <MenuItem value={SELECTED_ALL_REC_VENDOR}>
                                    <CloseIcon>
                                      <Checkbox
                                        checked={isAllSelectedRecVendor}
                                        indeterminate={
                                          selectedRecVendor.length > 0 &&
                                          selectedRecVendor.length < option.length
                                        }
                                      />
                                    </CloseIcon>
                                    <ListItemText primary='Clear All' />
                                  </MenuItem>
                                  {option.map((item: any) => (
                                    <MenuItem value={item.name}>
                                      <ListItemIcon>
                                        <Checkbox
                                          checked={selectedRecVendor.indexOf(item.name) > -1}
                                        />
                                      </ListItemIcon>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div
                                className='flex-wrap fw-bold fs-6 pe-2'
                                style={{
                                  alignItems: 'center',
                                }}
                              >
                                <button
                                  style={{backgroundColor: 'lightgrey'}}
                                  className='btn-lightgrey btn h-35px w-35px'
                                  id='basic-button'
                                  aria-controls={open ? 'basic-menu' : undefined}
                                  aria-haspopup='true'
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClickReceived}
                                >
                                  <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                                    <KTSVG
                                      className='svg-icon-4 m-0'
                                      path='/media/icons/duotune/general/setting.svg'
                                    />
                                  </div>
                                </button>
                                <Menu
                                  id='basic-menu'
                                  anchorEl={anchorEl1}
                                  open={openMenuReceived}
                                  onClose={handleCloseReceived}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem onClick={handleExportDataReceived}>
                                    Export All Data
                                  </MenuItem>
                                </Menu>
                              </div>{' '}
                            </>
                          )}
                        </div>

                        {/* <div className='m-2'></div> */}
                        <div className='mui-icons'>
                          {label === 'In Transit Purchase Order' && (
                            <>
                              <div
                                className='flex-wrap fw-bold fs-6 pe-2'
                                style={{
                                  alignItems: 'center',
                                }}
                              >
                                <button
                                  style={{backgroundColor: 'lightgrey'}}
                                  className='btn-lightgrey btn h-35px w-35px'
                                  id='basic-button'
                                  aria-controls={open ? 'basic-menu' : undefined}
                                  aria-haspopup='true'
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClick}
                                >
                                  <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                                    <KTSVG
                                      className='svg-icon-4 m-0'
                                      path='/media/icons/duotune/general/setting.svg'
                                    />
                                  </div>
                                </button>
                                <Menu
                                  id='basic-menu'
                                  anchorEl={anchorEl}
                                  open={openMenu}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem onClick={handleExportDataTransit}>
                                    Export All Data
                                  </MenuItem>
                                </Menu>
                              </div>
                              <div>
                                {HAS_PurchaseOrderCreatePO(currentUser) ? (
                                  <button
                                    className='btn btn-primary h-35px w-35px fw-bold'
                                    // style={{fontSize: '20px', padding: '6px 16px'}}
                                    onClick={() =>
                                      navigate(`/${prefixCompany}/inventory/purchase-order/create`)
                                    }
                                  >
                                    <div
                                      style={{
                                        marginTop: '-10px',
                                        marginLeft: '-6px',
                                        fontSize: '20px',
                                      }}
                                    >
                                      +
                                    </div>
                                  </button>
                                ) : null}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          )
        }
      )}

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={open}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size={'xl'}
        onHide={() => {
          setOpen(false)
        }}
      >
        <div className='p-5'>
          {pdata !== false ? <h2>Receive PO-{index}</h2> : <h2>RMA Order #{index}</h2>}
        </div>
        <PurchaseOrderView
          recretM={recretM}
          pdata={pdata}
          jio={1}
          jid={index}
          updateTable={updateTable}
        />
      </Modal>
    </>
  ) : (
    <></>
  )
}

export {PurchaseOrders}
function setAnchorEl(arg0: null) {
  throw new Error('Function not implemented.')
}
