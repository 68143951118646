/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState, useRef, useCallback} from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  Inventory_Items,
  Inventory_Items_Intial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  createInventoryItem,
  currnetCompanyName,
  darkMessage,
  getInventoryData,
  inventoryData,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {InventoryModel} from '../../modules/model/Inventory'
import {InventoryManagement} from './InventoryManagement'
import {Autocomplete, Checkbox, CircularProgress, TextField} from '@mui/material'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {onKeyDown} from './GlobalComponents'
import CheckBox from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const profileDetailsSchema = Yup.object().shape({
  sku: Yup.mixed().required('Manufacturer part no is required'),
  item_cost: Yup.number().min(0),
  mark_up: Yup.number().min(0),
  retail_price: Yup.number().min(0),
  qty_in_store: Yup.number().min(0),
  reorder_level: Yup.number().min(0),
  order_link: Yup.string().url('Please enter a valid URL starting with http:// or https://'),
})

type PropsType = {
  checkIsInventoryModal?: boolean
  set_create_inventory_modal?: void
  closeInventoryModal?: () => void
}
const InventoryForm = ({
  checkIsInventoryModal = false,
  set_create_inventory_modal,
  closeInventoryModal,
}: PropsType) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<any>({
    manufacturer: false,
    vendor: false,
    category: false,
  })
  const [modal, setModalShow] = React.useState<any>(false)
  const {currentUser} = useAuth()
  const inputFile = useRef<any>()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const headerContext = useContext<any>(HeaderContext)
  const [options, setOptions] = useState<any>([])
  const [dropdown, setDropdown] = useState<any>([])
  const [body, setBody] = useState<any>([])
  const [img, setImg] = React.useState<any>('/media/avatars/blank.png')
  // ========================== header here =============================

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Inventory Item',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/store`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    formik.setFieldValue('mark_up', 0)
  }, [])

  const getList = useCallback(async () => {
    setLoading(true)
    let brand: any = []
    let vendor: any = []
    let category: any = []
    await getInventoryData()
      .then((res) => {
        let brands = res?.data?.brands
        let vendors = res?.data?.vendors
        let categories = res?.data?.category
        brands.forEach((e: any) => {
          brand.push({label: e.name, id: e.id})
        })
        vendors.forEach((e: any) => {
          vendor.push({label: e.name, id: e.id})
        })
        categories.forEach((e: any) => {
          category.push({label: e.name, id: e.id})
        })
        setDropdown(vendor)
        setOptions(brand)
        setBody(category)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  // =========================formik here =========================
  const formik = useFormik<Inventory_Items>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      let stock = ''
      if (values.maintain_stock === 'yes') {
        stock = 'True'
      } else {
        stock = 'False'
      }
      let pic = values.uploadedImage
      setLoading(true)
      let inventoryItems: InventoryModel = {
        name: values.name,
        maintain_stock: stock,
        mark_up: values.mark_up || 0,
        reorder_level: values.reorder_level || 0,
        item_cost: values.item_cost || 0,
        retail_price: parseFloat(formik.values.item_cost) + parseFloat(formik.values.mark_up) || 0,
        available_stock: values.available_stock || 0,
        qty_in_store: values.qty_in_store || 0,
        order_link: values.order_link,
        category_id: values.category_id,
        item_status: values.item_status,
        brand_id: values.brand_id,
        vendor_id: JSON.stringify(values.vendor_id),
        sku: values.sku,
        in_transit_stock: values.in_transit_stock,
        reserved_stock: values.reserved_stock,
        item_description: values.item_description,
        uploadImage: pic,
        inventory_location: values.inventory_location,
        image_field: undefined,
      }
      createInventoryItem(inventoryItems)
        .then(({data}) => {
          setSuccessBody(data as any)
          setLoading(false)
          formik.resetForm()
          darkMessage('Item added successfully')
          checkIsInventoryModal
            ? closeInventoryModal()
            : navigate(`/${prefixCompany}/inventory/store`)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setErrorBody(e.response.data)
          }
          setLoading(false)
        })
    },
  })

  const onModalClose = useCallback(() => {
    setModalShow({show: false, label: ''})
    getList()
  }, [])
  const onButtonClick = () => {
    inputFile.current.click()
  }

  const changeImg = (e: any) => {
    setImg(URL.createObjectURL(e.target.files[0]))
    try {
      let filesToUpload: any = []
      if (e?.target?.files != null) {
        let lengthOfFiles = Object.keys(e.target.files).length
        Object.values(e.target.files).map((value) => {
          return filesToUpload.push(value)
        })
        formik.setFieldValue('uploadedImage', e.target.files)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        label={modal?.label}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryManagement
          onModalClose={onModalClose}
          label={modal?.label}
          show={modal?.show}
          customerData={modal?.data}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <form
        // onKeyDown={onKeyDown}
        onSubmit={formik.handleSubmit}
        noValidate
        encType='multipart/formData'
        className='form'
      >
        <div className='card mb-5 mb-xl-10 col-sm-12'>
          <div
            className='card-header border-bottom cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <div className='d-flex p-lg-5 align-items-center m-0'>
              <h3 className='fw-bolder m-0 '>Create New Item</h3>
            </div>
          </div>
          {errorBody && <ErrorAlert error_response={errorBody} />}
          {successBody && <SuccessAlert success_response={successBody} />}
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='row card-body p-7'>
              <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'>
                <table>
                  <tbody>
                    <tr className=''>
                      <td colSpan={4} className='pb-5 px-4 w-75 text-center'>
                        <input
                          type='file'
                          ref={inputFile}
                          style={{display: 'none'}}
                          onChange={changeImg}
                          accept='image/*'
                        />
                        <img
                          src={img}
                          onClick={onButtonClick}
                          alt=''
                          style={{
                            width: '170px',
                            height: '170px',
                            margin: 'auto',
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={1} className='py-2  px-4 font-size-15'>
                        Manufacturer
                      </td>
                      <td colSpan={2} style={{minWidth: '180px'}} className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'manufacturer',
                            })
                            e.preventDefault()
                          }}
                          className='btn btn-sm btn-primary btn-xs cursor-pointerm'
                          style={{cursor: 'pointer'}}
                        >
                          + Create New Manufacturer
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} colSpan={4} className='pb-4 font-size-15'>
                        <Autocomplete
                          disablePortal
                          className=' form-control-lg '
                          id='combo-box-demo'
                          open={open?.manufacturer}
                          onOpen={() => {
                            setOpen({...open, manufacturer: true})
                          }}
                          onClose={() => {
                            setOpen({...open, manufacturer: false})
                          }}
                          loading={loading}
                          style={{padding: '7px'}}
                          options={options}
                          onChange={(e, value: any) => {
                            if (value?.id) {
                              formik.setFieldValue('brand_id', value?.id)
                            }
                          }}
                          fullWidth={true}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label='Please select Manufacturer'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='pb-4  px-4 font-size-15'>Vendor</td>
                      <td className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'vendor',
                            })
                            e.preventDefault()
                          }}
                          style={{cursor: 'pointer'}}
                          className='btn btn-sm btn-primary btn-xs cursor-pointer'
                        >
                          + Create New Vendor
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td
                        style={{width: '100%', padding: '7px'}}
                        colSpan={4}
                        className='pb-4 px-4 font-size-15'
                      >
                        <Autocomplete
                          multiple
                          id='checkboxes-tags-demo'
                          disableCloseOnSelect
                          options={dropdown || []}
                          getOptionLabel={(option: any) => option?.label}
                          renderOption={(props, option: any, {selected}) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          onChange={(e, value: any) => {
                            formik.setFieldValue('vendor_id', value)
                          }}
                          fullWidth={true}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Please Select Vendor'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr className='table-grid-inventory'>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        {formik.touched.vendor_id && formik.errors.vendor_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.vendor_id}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='pb-4  px-4 font-size-15'>Inventory Category</td>
                      <td className='text-right'>
                        <button
                          type='button'
                          onClick={(e) => {
                            setModalShow({
                              show: 1,
                              label: 'category',
                            })
                            e.preventDefault()
                          }}
                          className='btn btn-sm btn-primary btn-xs cursor-pointer'
                          style={{cursor: 'pointer'}}
                        >
                          + Create New Category
                        </button>
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '50%'}} colSpan={4} className='pb-4 font-size-15'>
                        <Autocomplete
                          disablePortal
                          className=' form-control-lg '
                          id='combo-box-demo'
                          style={{padding: '7px', width: '100%'}}
                          options={body}
                          open={open?.category}
                          onOpen={() => {
                            setOpen({...open, category: true})
                          }}
                          onClose={() => {
                            setOpen({...open, category: false})
                          }}
                          loading={loading}
                          onChange={(e, value: any) => {
                            if (value?.id) {
                              formik.setFieldValue('category_id', value?.id)
                            }
                          }}
                          fullWidth={true}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label='Please select Category'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 font-size-15 '>
                        Item Cost
                      </td>
                      <td style={{width: '100%'}} className='pb-4 font-size-15 '>
                        Markup
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text'>$</span>
                          </div>
                          <input
                            type='number'
                            className='form-control'
                            style={{padding: '7px', width: '50%', minWidth: '50%'}}
                            {...formik.getFieldProps('item_cost')}
                          />
                        </div>
                      </td>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        <div className='input-group mb-3'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text'>$</span>
                          </div>
                          <input
                            type='number'
                            className='form-control'
                            style={{padding: '7px', width: '50%', maxWidth: '50%'}}
                            {...formik.getFieldProps('mark_up')}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        {formik.touched.item_cost && formik.errors.item_cost && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.item_cost}</div>
                          </div>
                        )}
                      </td>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'></td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'>
                        {formik.touched.mark_up && formik.errors.mark_up && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.mark_up}</div>
                          </div>
                        )}
                      </td>
                      <td style={{width: '100%'}} className='pb-4 px-4 font-size-15'></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
                <table>
                  <tbody className='block-display'>
                    <tr className=''>
                      <td
                        style={{width: '100%'}}
                        colSpan={4}
                        className='pb-4 font-size-15 required'
                      >
                        Manufacturer Part #
                      </td>
                    </tr>
                    <tr className=''>
                      <td
                        style={{width: '100%', padding: '0'}}
                        colSpan={4}
                        className='pb-4 font-size-15'
                      >
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('sku')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 font-size-15' colSpan={4}>
                        {formik.touched.sku && formik.errors.sku && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.sku}</div>
                          </div>
                        )}
                      </td>
                    </tr>

                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 font-size-15' colSpan={4}>
                        Item Name
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 font-size-15' colSpan={4}>
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('name')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{width: '100%'}} className='pb-4 font-size-15' colSpan={4}>
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </td>
                    </tr>

                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 font-size-15 ' colSpan={4}>
                        Item description
                      </td>
                    </tr>
                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 font-size-15' colSpan={4}>
                        <textarea
                          id=''
                          rows={4}
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('item_description')}
                        />
                      </td>
                    </tr>

                    <tr className=''>
                      <td style={{width: '100%'}} className='pb-4 font-size-15 ' colSpan={4}>
                        Order Link (Must include http:// or https://)
                      </td>
                    </tr>
                    <tr className=''>
                      <td
                        style={{width: '100%', padding: '0'}}
                        className='pb-4 font-size-15'
                        colSpan={4}
                      >
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('order_link')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{width: '100%', padding: '0'}}
                        className='pb-4 font-size-15'
                        colSpan={4}
                      >
                        {formik.touched.order_link && formik.errors.order_link && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.order_link}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className=''>
                      <td
                        style={{width: '100%', padding: '0'}}
                        className='pb-4  font-size-15 '
                        colSpan={4}
                      >
                        Inventory Location
                      </td>
                    </tr>
                    <tr className=''>
                      <td
                        style={{width: '100%', padding: '0'}}
                        className='pb-4 font-size-15'
                        colSpan={4}
                      >
                        <input
                          type='text'
                          id=''
                          style={{padding: '7px', width: '100%'}}
                          {...formik.getFieldProps('inventory_location')}
                        />
                      </td>
                    </tr>
                    <div className='table-row-inventory'>
                      <tr className='table-col-inventory col-gap'>
                        <td className='pb-4 px-4 font-size-15'>Retail Price</td>
                        <td className='pb-4 px-4 font-size-15'>QTY in Store</td>
                        <td className='pb-4 px-4 font-size-15'>Maintain Stock</td>
                        <td className='pb-4 px-4 font-size-15'>Reorder Level</td>
                      </tr>
                      <tr className='table-col-inventory'>
                        <td className='pb-4 p-lr-16'>
                          <div className='input-group mb-3'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text'>$</span>
                            </div>
                            <input
                              type='number'
                              className='form-control'
                              style={{padding: '7px', width: '50%'}}
                              readOnly={true}
                              value={
                                parseFloat(formik.values.item_cost) +
                                  parseFloat(formik.values.mark_up) || 0
                              }
                            />
                          </div>
                        </td>
                        <td className='pb-4 p-lr-16 font-size-15'>
                          <input
                            type='text'
                            id=''
                            style={{padding: '7px', width: '100%'}}
                            {...formik.getFieldProps('qty_in_store')}
                          />
                        </td>
                        <td className='pb-4 p-lr-16 font-size-15'>
                          <select
                            id=''
                            style={{padding: '7px', width: '100px'}}
                            {...formik.getFieldProps('maintain_stock')}
                          >
                            <option value={'no'}>N</option>
                            <option value={'yes'}>Y</option>
                          </select>
                        </td>
                        <td className='pb-4 p-lr-16 font-size-15'>
                          {formik.values.maintain_stock === 'yes' ? (
                            <input
                              type='text'
                              id=''
                              style={{padding: '7px', width: '100%'}}
                              {...formik.getFieldProps('reorder_level')}
                            />
                          ) : (
                            <input
                              type='text'
                              id=''
                              style={{padding: '7px', width: '100%'}}
                              disabled
                              {...formik.getFieldProps('reorder_level')}
                            />
                          )}
                        </td>
                      </tr>
                    </div>
                    <tr className=''>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.retail_price && formik.errors.retail_price && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.retail_price}</div>
                          </div>
                        )}
                      </td>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.qty_in_store && formik.errors.qty_in_store && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.qty_in_store}</div>
                          </div>
                        )}
                      </td>
                      <td className='pb-4 px-4 font-size-15'></td>
                      <td className='pb-4 px-4 font-size-15'>
                        {formik.touched.reorder_level && formik.errors.reorder_level && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.reorder_level}</div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='row '>
                <div className='col-sm-6 my-2'></div>

                <div className='col-sm-6 my-2 justify-content-end d-flex'>
                  <button
                    className='btn btn-primary'
                    onClick={() => set_create_inventory_modal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary ms-4'
                    disabled={loading || !formik.isValid}
                  >
                    {!loading && 'Create Item'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export {InventoryForm}
