/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  useAuth,
  getLocalUser,
  storeLocalUser,
  UserModel,
  UserModel_Intial as initialValues,
} from '../../../../auth'
import {User} from '../../../../apps/user-management/users-list/core/_models'
import moment from 'moment'
import {HeaderAction, HeaderContext} from '../../../../../context/HeaderContext'
import {
  currnetCompanyName,
  darkMessage,
  getUserByToken,
  profileImg,
  updateUser,
} from '../../../../auth/core/_requests'
import ErrorAlert, {ErrorType} from '../../../../errors/ErrorAlert'
import {ProfileContext} from '../../../../../../_metronic/layout/MasterLayout'
import produce from 'immer'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'

const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().required('Work Email Address is required'),
  phone_number: Yup.string().required('Work Phone Number is required'),
})

const ProfileDetails: React.FC = () => {
  const profilecontext: any = useContext(ProfileContext)
  // const inputFile = useRef<any>()
  const {currentUser} = useAuth()
  const [img, setImg] = React.useState<any>(currentUser?.pic)
  const navigate = useNavigate()
  const headerContext = useContext<any>(HeaderContext)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState<any>(null)
  const formik = useFormik<UserModel>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      let profileData: User = {
        avatar: values?.pic,
        first_name: values?.first_name,
        last_name: values?.last_name || '',
        phone_number: values?.phone_number || inputValue,
        email: values?.email,
      }
      updateUser(profileData)
        .then(async () => {
          profilecontext?.state?.setProfilePic(
            produce((draft: any) => {
              draft.pic = img
              draft.first_name = values?.first_name
              draft.last_name = values?.last_name
            })
          )
          setLoading(false)
          getList()
          darkMessage('Profile Update Successfully')
          navigate(`/${prefixCompany}/dashboard`)
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
          getList()
        })
    },
  })

  const getList = useCallback(async () => {
    const auth = await getLocalUser()
    const {data: user} = await getUserByToken(auth.access)
    const item = user
    formik.setFieldValue('first_name', item?.first_name)
    formik.setFieldValue('last_name', item?.last_name)
    formik.setFieldValue('company', item?.company)
    formik.setFieldValue('email', item?.email)
    formik.setFieldValue('phone_number', item?.phone)
    setInputValue(item?.phone)
  }, [])

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    getList()
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Profile',
        subtitle: ` `,
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const changeImg = (e: any) => {
    try {
      setLoading(true)
      let filesToUpload: any = []
      if (e?.target?.files != null) {
        Object.values(e.target.files).map((value) => {
          return filesToUpload.push(value)
        })
        profileImg(filesToUpload, prefixCompany)
          .then((res) => {
            formik.setFieldValue('pic', res?.data?.user_pic)
            setLoading(false)
            setImg(URL.createObjectURL(e.target.files[0]))
          })
          .catch((e) => {
            if (e.response.status === 409) {
              setErrorBody(e.response.data)
              setLoading(false)
            }
          })
      }
    } catch (e) {
      console.error(e)
    }
  }

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    formik.setFieldValue('phone_number', formattedPhoneNumber)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {errorBody && <ErrorAlert error_response={errorBody} />}
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-2'>
          <h3 className='fw-bolder m-2'>Update Your Profile ....</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                {/* {loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border text-primary spinner-border-lg align-middle ms-2'></span>
                  </span>
                ) : ( */}
                <>
                  <input
                    type={'file'}
                    onChange={changeImg}
                    autoComplete='off'
                    accept='image/*'
                    className={clsx('form-control form-control-lg form-control-solid')}
                  />

                  {/* <img
                      src={img}
                      alt='Logo'
                      onError={() => {
                        setImg(blank)
                      }}
                      onClick={onButtonClick}
                      style={{width: '150px', height: '150px', margin: 'auto', cursor: 'pointer'}}
                    /> */}
                </>
                {/* )} */}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      defaultValue={currentUser?.first_name}
                      {...formik.getFieldProps('first_name')}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      defaultValue={currentUser?.last_name}
                      placeholder='Last name'
                      {...formik.getFieldProps('last_name')}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Work Phone Number
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone Number'
                  onChange={(e) => handleInput(e)}
                  value={inputValue}
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone_number}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Work Email Address</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  defaultValue={currentUser?.email}
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Date Joined</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  readOnly
                  defaultValue={moment(currentUser?.date_joined).format('LLLL')}
                  {...formik.getFieldProps('date_joined')}
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export {ProfileDetails}
