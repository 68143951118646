import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {
  API_URL,
  create_new_service,
  darkErrorMessage,
  darkMessage,
  delete_specific_service,
  fetch_all_services,
  services_csv_generator,
  update_specific_service,
} from '../../modules/auth/core/_requests'
import DeleteModal from './DeleteModal'
import {MaterialDataTable} from './MatrialDataTable'
import {useSearchParams} from 'react-router-dom'
import {useLocalStorage} from './GlobalComponents'
import {Menu, MenuItem} from '@mui/material'
import GeneratePDF from './GeneratePDF'
import {useAuth} from '../../modules/auth'
import {
  HAS_ServiceCreateTable,
  HAS_ServiceDeleteService,
  HAS_ServiceUpdateService,
  HAS_ServiceViewTable,
} from './PermissionFunction'

//  Typescript Interface for Callback Data
interface CallBack_DataType {
  input_data: string
  service_des: string
  number_data: number
}

//  Typescript Interface for Api Data
interface ServiceData {
  id: string
  service_name: string
  service_description: string
  service_rate: number
  user_name: string
  user_image: string
}

//  Typescript type for Props (Parent to Child and Child to Parent)
type PropsType = {
  title: string
  loading: boolean
  handleCreateModal: boolean
  handleCloseCreateModal: () => void
  callbackFnx: (input_data: CallBack_DataType) => void
  default_input_value?: string
  default_service_value?: string
  default_number_value?: number
}

// Child Component (For Create and Update Purpose : Input Fields are here)
const EditService = ({
  title,
  loading,
  handleCreateModal,
  handleCloseCreateModal,
  callbackFnx,
  default_input_value = '',
  default_service_value = '',
  default_number_value = 0,
}: PropsType) => {
  const [inputFieldData, setInputFieldData] = useState('')
  const [serviceDescriptionField, setServiceDescriptionField] = useState('')
  const [numberFieldData, setNumberFieldData] = useState(0.0)
  // Load previous data if it is here (for update functionality)
  useEffect(() => {
    setInputFieldData(default_input_value)
    setServiceDescriptionField(default_service_value)
    setNumberFieldData(default_number_value)
  }, [default_input_value, default_service_value, default_number_value])

  // Submit Function (Sends data from Child to Parent)
  const handleSubmit = useCallback(
    (e: any) => {
      e?.preventDefault()
      const input_data = {
        input_data: inputFieldData,
        service_des: serviceDescriptionField,
        number_data: numberFieldData,
      }
      callbackFnx(input_data)
      setInputFieldData('')
      setServiceDescriptionField('')
      setNumberFieldData(0)
      handleCloseCreateModal()
    },
    [callbackFnx, handleCloseCreateModal, inputFieldData, serviceDescriptionField, numberFieldData]
  )

  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
      show={handleCreateModal}
      centered
      className='fade'
      size='lg'
      onHide={handleCloseCreateModal}
      onModalClose={handleCloseCreateModal}
    >
      <Modal.Header>
        <h2>{title}</h2>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className='form-group'>
            <div className='row my-2'>
              <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                <label htmlFor='enter_service_name' className='required'>
                  Service Name
                </label>
              </div>
              <div className='col-sm-9'>
                <input
                  type='text'
                  required
                  className='form-control'
                  id='enter_service_name'
                  onChange={(e) => setInputFieldData(e?.target?.value)}
                  value={inputFieldData}
                />
              </div>
            </div>
            <div className='row my-2'>
              <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                <label htmlFor='enter_service_description'>Service Description</label>
              </div>
              <div className='col-sm-9'>
                <textarea
                  className='form-control'
                  rows={4}
                  id='enter_service_description'
                  onChange={(e) => setServiceDescriptionField(e?.target?.value)}
                  value={serviceDescriptionField}
                />
              </div>
            </div>
            <div className='row my-2'>
              <div className='col-sm-3 fw-bold justify-content-start align-items-center d-flex fs-4'>
                <label htmlFor='enter_service_rate' className='required'>
                  Service Rate
                </label>
              </div>
              <div className='col-sm-9'>
                <input
                  type='number'
                  required
                  className='form-control'
                  id='enter_service_rate'
                  onChange={(e) => setNumberFieldData(parseFloat(e?.target?.value))}
                  value={numberFieldData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='dark'
            type='button'
            onClick={() => {
              handleCloseCreateModal()
              setInputFieldData('')
            }}
          >
            Discard
          </Button>
          <Button variant='primary' type='submit'>
            {!loading && 'Submit'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

// Parent component is Here Now
const Services = () => {
  // State Management Data is here
  const [handleCreateModal, setHandleCreateModal] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const [data, setData] = useState<string[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [tableStorage, setTableStorage] = useLocalStorage('servicesColsVisibility', '')
  const [handleDeleteModal, setHandleDeleteModal] = useState<{
    show: boolean
    data: ServiceData | null
  }>({
    show: false,
    data: null,
  })
  const [handleUpdateModal, setHandleUpdateModal] = useState<{
    show: boolean
    data: ServiceData | null
  }>({
    show: false,
    data: null,
  })
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()

  // Get Initial data of Data table (Service List will be get here)
  const getServiceList = useCallback((page: number = 0, str = '', pagePerEntries: number = 100) => {
    fetch_all_services(page, str, pagePerEntries)
      .then(({data}) => {
        const SERVICES_DATA = data?.fetch_services
        const TOTAL_ROWS = data?.row_count
        setData(SERVICES_DATA || [])
        setRowCount(TOTAL_ROWS || 0)
        setLoading(false)
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  const handleExportData = useCallback(async () => {
    setPdfOpen(true)
    await services_csv_generator()
      .then((res: any) => {
        const fileUrl = API_URL + res?.data?.file_path
        if (fileUrl) {
          window.open(fileUrl, '_blank')
        }
      })
      .catch((e: any) => {
        darkErrorMessage('ERROR Found! CSV does not generated.')
      })
    setPdfOpen(false)
  }, [])
  // Create New Service (Function will be come when submit button is clicked in Modal)
  const handleCreateService = useCallback(
    (cb_Data: CallBack_DataType) => {
      setLoading(true)
      const service_name = cb_Data?.input_data
      const service_description = cb_Data?.service_des
      const service_rate = cb_Data?.number_data
      create_new_service(service_name, service_description, service_rate)
        .then(({data}) => {
          const message = data?.message
          darkMessage(message)
          setLoading(false)
          getServiceList()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [getServiceList]
  )

  // Delete Existing Service (Function will be come when confirmation button is clicked in Modal or throws error)
  const handleDeleteService = useCallback(async () => {
    setLoading(true)
    const SERVICE_ID: string = handleDeleteModal?.data?.id || ''
    setLoading(true)
    await delete_specific_service(SERVICE_ID)
      .then(({data}) => {
        darkMessage('Service Deleted Successfully')
        setHandleDeleteModal({
          show: false,
          data: null,
        })
        setLoading(false)
        getServiceList()
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [getServiceList, handleDeleteModal])

  // Update Existing Service (Function will be come when Submit button is clicked in Modal or throws error)
  const handleUpdateTag = useCallback(
    async (input_data: CallBack_DataType) => {
      setLoading(true)
      const SERVICE_ID: string = handleUpdateModal?.data?.id || ''
      let service_data = {
        service_id: SERVICE_ID,
        service_name: input_data?.input_data,
        service_description: input_data?.service_des,
        service_rate: input_data?.number_data,
      }
      await update_specific_service(service_data)
        .then(({data}) => {
          darkMessage('Service Updated Successfully')
          setHandleUpdateModal({
            show: false,
            data: null,
          })
          setLoading(false)
          getServiceList()
        })
        .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [getServiceList, handleUpdateModal]
  )

  // Call Intial function on page loads and when some changes are made in function (helps to load datatable data)
  useEffect(() => {
    setLoading(true)
    getServiceList()
  }, [getServiceList])

  // Handle Pagination on Data Table
  const PaginationCallback = useCallback(
    (getPagination: any) => {
      setLoading(true)
      getServiceList(getPagination?.pageIndex, '', getPagination?.pageSize)
    },
    [getServiceList]
  )

  // Handle search through UserSearchList Component
  useEffect(() => {
    setLoading(true)
    const search: any = searchParams.get('str')
    getServiceList(0, search)
    return () => {
      searchParams.set('str', '')
    }
  }, [getServiceList, searchParams])

  // Update Data Table when Pagination Updated
  useEffect(() => {
    setLoading(true)
    getServiceList(pagination.pageIndex, '', pagination.pageSize)
  }, [getServiceList, pagination])

  // Material Columns are given here (Table Columns)
  const MaterialColumns: any = useMemo(
    () => [
      {
        header: 'Service Name',
        accessorFn: (row: any) => row?.service_name,
      },
      {
        header: 'Service Description',
        accessorFn: (row: any) => row?.service_description,
      },
      {
        header: 'Service Rate',
        accessorFn: (row: any) => {
          const serviceRate = parseFloat(row?.service_rate) || 0
          return `$${serviceRate.toFixed(2)}`
        },
      },
      {
        header: 'Created By',
        accessorFn: (row: any) => (
          <a href={row?.user_image || null} target='blank' title={row?.user_name}>
            <img
              alt={row?.user_name}
              src={`${row?.user_image || '/media/avatars/blank.png'}`}
              width='40px'
              height={'40px'}
              style={{objectFit: 'contain', borderRadius: '50%'}}
            />
          </a>
        ),
      },
      {
        accessorFn: (row: any) => (
          <>
            <div className='service-action-btn'>
              {HAS_ServiceUpdateService(currentUser) ? (
                <button
                  className='btn btn-light'
                  onClick={() => {
                    setHandleUpdateModal({
                      show: true,
                      data: row,
                    })
                  }}
                >
                  Update Service
                </button>
              ) : null}
              {HAS_ServiceDeleteService(currentUser) ? (
                <button
                  className='btn btn-light'
                  onClick={() => {
                    setHandleDeleteModal({
                      show: true,
                      data: row,
                    })
                  }}
                >
                  Delete Service
                </button>
              ) : null}
            </div>
          </>
        ),
        header: 'Actions',
      },
    ],
    []
  )

  return HAS_ServiceViewTable(currentUser) ? (
    <div className='card-body'>
      <MaterialDataTable
        MaterialColumns={MaterialColumns}
        MaterialColumnsApiData={data}
        totalRows={rowCount}
        loadingBar={loading}
        tableHeight={700}
        serverPagination={pagination}
        setServerPagination={(cb_data: any) => {
          return PaginationCallback(cb_data)
        }}
        columnVisibility={tableStorage}
        cbColumnVisibility={(callbackData) => {
          cbFunction(callbackData)
        }}
        navigationLink={''}
        stopRedirection={false}
        optionalTableHeader={
          <div className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'>
            <h4 className='fw-bolder m-5 w-115px'>
              <KTSVG path='/media/custom/services-icon.svg' className='svg-icon-1 me-3' />
              Services
            </h4>
            <div className='mx-lg-3'>
              <UsersListSearchComponent />{' '}
            </div>
            <div className='mui-icons'>
              <div
                className='flex-wrap fw-bold fs-6 m-1'
                style={{
                  alignItems: 'center',
                }}
              >
                <button
                  style={{backgroundColor: 'lightgrey'}}
                  className='btn-lightgrey btn h-35px w-35px'
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    setAnchorEl(event.currentTarget)
                  }
                >
                  <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                    <KTSVG
                      className='svg-icon-4 m-0'
                      path='/media/icons/duotune/general/setting.svg'
                    />
                  </div>
                </button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleExportData()
                      setAnchorEl(null)
                    }}
                  >
                    <span>Export All Data</span>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            {HAS_ServiceCreateTable(currentUser) ? (
              <Button
                className='btn btn-primary h-35px w-35px fw-bold'
                onClick={() => setHandleCreateModal(true)}
              >
                <div style={{marginTop: '-9px', marginLeft: '-7px', fontSize: '20px'}}>+</div>
              </Button>
            ) : null}
          </div>
        }
      />
      {/* 
        Modal for Create New Service
      */}
      <EditService
        title='Add New Service'
        loading={loading}
        handleCreateModal={handleCreateModal}
        handleCloseCreateModal={() => setHandleCreateModal(false)}
        callbackFnx={(input_data: CallBack_DataType) => handleCreateService(input_data)}
      />
      {/* 
        Modal for Delete Existing Service
      */}
      <DeleteModal
        modalShow={handleDeleteModal?.show}
        title={'Confirmation Message'}
        body={'Are you sure you want to delete this Service?'}
        handleClose={() => {
          setHandleDeleteModal({
            show: false,
            data: null,
          })
        }}
        confirmFunction={() => {
          handleDeleteService()
        }}
      />
      {/* 
        Modal for Update Existing Service
      */}
      <EditService
        loading={loading}
        title='Update Service'
        handleCreateModal={handleUpdateModal?.show}
        handleCloseCreateModal={() =>
          setHandleUpdateModal({
            show: false,
            data: null,
          })
        }
        callbackFnx={(input_data: CallBack_DataType) => handleUpdateTag(input_data)}
        default_input_value={handleUpdateModal?.data?.service_name}
        default_service_value={handleUpdateModal?.data?.service_description}
        default_number_value={handleUpdateModal?.data?.service_rate}
      />
      {/* <GeneratePDF pdfOpen={pdfOpen} pdfText='Downloading Data' /> */}
    </div>
  ) : (
    <></>
  )
}

export default Services
