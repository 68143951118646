/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {currnetCompanyName, inventoryData} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {InventoryModel, Vendor} from '../../modules/model/Inventory'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {ExportToCsv} from 'export-to-csv'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Tooltip,
  Select,
  CircularProgress,
} from '@mui/material'
import {HAS_InventoryCreateItem, HAS_InventoryViewTable,HAS_InventoryViewItem} from './PermissionFunction'
import {MaterialDataTable} from './MatrialDataTable'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {CommaNumberFormatter, useLocalStorage} from './GlobalComponents'
import {InventorySettings} from './InventorySettings'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {ClassNames} from '@emotion/react'

const INVENTORY_HEADER = [
  {
    item_Icon: '/media/icons/duotune/ecommerce/ecm006.svg',
    quantity: 'incomingItems',
    name: 'Incoming Items',
    click_Link: 'inventory/purchase-order',
  },
  {
    item_Icon: '/media/icons/duotune/abstract/abs049.svg',
    quantity: 'lowInventory',
    name: 'Low Inventory',
    lowInventoryFnc: true,
  },
  {
    item_Icon: '/media/icons/duotune/arrows/arr014.svg',
    quantity: 'inactiveItems',
    name: 'Non Active Inventory',
    nonActiveInventoryFnc: true,
  },
  // {
  //   item_Icon: '/media/icons/duotune/arrows/arr054.svg',
  //   quantity: '0',
  //   name: 'RMA Orders',
  //   click_Link: 'inventory/return-order',
  // },
  {
    item_Icon: '/media/icons/duotune/finance/fin010.svg',
    quantity: 'totalStockValue',
    name: 'Stock Value',
  },
]

const Inventory: FC = () => {
  const intl = useIntl()
  const [totalRows, seTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [filtersT, setfiltersT] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser} = useAuth()
  const company = currnetCompanyName(currentUser?.company)
  const headerContext = useContext<any>(HeaderContext)
  const [data, setData] = useState<any>([])
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [selectedItem, setSelectedItem] = useState('All Items')
  const [header, setHeader] = useState<any>({
    totalStockValue: 0,
    incomingItems: 0,
    inactiveItems: 0,
    lowInventory: 0,
  })
  const ActiveItemType = {
    all: 'All Items',
    active: 'Active Items',
    inactive: 'Inactive Items',
  }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [tableStorage, setTableStorage] = useLocalStorage('inventoryColsVisibility', '')

  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  const handleActive = useCallback(
    (event: any) => {
      console.log(event.target.value)
      const search: any = searchParams.get('str')
      setSelectedItem(event.target.value)
      getList(0, search, 100, event?.target?.value)
      return () => {
        searchParams.set('str', '')
      }
    },
    [selectedItem, searchParams]
  )

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Store Inventory',
        subtitle: ' ',
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/inventory/store/create`,
        additional_btn_text: '',
        additional_navigation_url: `/${prefixCompany}/inventory/store/categories`,
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: InventoryModel) => (
          <a href={row?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${row?.image_field ? row?.image_field : '/media/custom/empty_inventory.svg'}`}
              width='50px'
              height={'50px'}
              style={{objectFit: 'contain', maxWidth: '100%'}}
            />
          </a>
        ),
        header: 'Item Logo',
        size: 120, //decrease the width of this column
        accessorKey: 'item_logo',
      },
      {
        accessorFn: (row: InventoryModel) => row.sku,
        size: 150,
        header: 'MFG Part #', //uses the default width from defaultColumn prop
        accessorKey: 'mfg_part_no',
      },
      {
        accessorFn: (row: InventoryModel) => row.name,
        header: 'Item Name',
        accessorKey: 'item_name',
      },
      {
        header: 'Manufacturer',
        accessorFn: (row: InventoryModel) => row?.brand?.name,
        size: 100, //increase the width of this column
        accessorKey: 'manufacturer',
      },
      {
        accessorFn: (row: InventoryModel) => row?.category?.name,
        header: 'Category',
        size: 100, //decrease the width of this column
        accessorKey: 'category',
      },
      {
        accessorFn: (row: InventoryModel) =>
          row?.vendor ? (
            row?.vendor.map((eachVendor: Vendor) => {
              return (
                <Tooltip
                  title={<span style={{fontSize: '15px'}}>{eachVendor?.name || 'N/A'}</span>}
                  className='p-0.5'
                >
                  <span>
                    <img
                      alt='emp logo'
                      src={`${eachVendor?.image || '/media/avatars/blank.png'}`}
                      onError={(evt: any) => {
                        evt.target.src = blank
                      }}
                      // width='40px'
                      // // max-width='40px'
                      // // height='40px'
                      // className='m-1'
                      // // max-width='70%'
                      // style={{objectFit: 'contain', borderRadius: '50%'}}
                      width='40px'
                      height={'40px'}
                      style={{objectFit: 'contain', maxWidth: '100%', borderRadius: '50%'}}
                    />
                  </span>
                </Tooltip>
              )
            })
          ) : (
            <></>
          ),
        header: 'Vendor',
        size: 200, //decrease the width of this column
        accessorKey: 'vendor',
      },
      {
        accessorFn: (row: InventoryModel) =>
          row.quantity_in_store < 0 ? (
            <span
              title='This item is not available in Inventory, added to Need to Order List.'
              className='text-danger'
            >
              {CommaNumberFormatter(row.quantity_in_store)} !{' '}
            </span>
          ) : (
            CommaNumberFormatter(row.quantity_in_store)
          ),
        header: 'QTY in Store',
        size: 100, //decrease the width of this column
        accessorKey: 'quantity_in_store',
      },
      {
        accessorFn: (row: InventoryModel) =>
          row.available_stock < 0 ? (
            <span
              title='This item is not available in Inventory, added to Need to Order List.'
              className='text-danger'
            >
              {CommaNumberFormatter(row.available_stock)} !{' '}
            </span>
          ) : (
            CommaNumberFormatter(row.available_stock)
          ),
        header: 'Available Stock',
        size: 100, //decrease the width of this column
        accessorKey: 'available_stock',
      },
      {
        accessorFn: (row: InventoryModel) => row.reserved_quantity || 0,
        header: 'Qty. Reserved',
        size: 100, //decrease the width of this column
        accessorKey: 'quantity_reserved',
      },
      {
        accessorFn: (row: InventoryModel) => CommaNumberFormatter(row.quantity_on_order) || 0,
        header: 'Qty. on Order',
        size: 100, //decrease the width of this column
        accessorKey: 'quantity_ordered',
      },
      {
        accessorFn: (row: InventoryModel) => row.inventory_location,
        header: 'Inventory Location',
        size: 100, //decrease the width of this column
        accessorKey: 'inventory_location',
      },
    ],
    []
  )

  useEffect(() => {
    setLoading(true)
    getList(pagination.pageIndex, '', pagination.pageSize)
  }, [pagination])

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(getPagination?.pageIndex, '', getPagination?.pageSize)
  }, [])

  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      pagePerEntries: number = 100,
      // item_type = selectedItem === 'All Items'
      //   ? ActiveItemType.all
      //   : selectedItem === 'Active Items'
      //   ? ActiveItemType.active
      //   : selectedItem === 'InActive Items'
      //   ? ActiveItemType.inactive
      //   : ActiveItemType.all,
      item_type = selectedItem,
      low_inventory: number = 0,
      non_active_inventory: number = 0
    ) => {
      console.log(item_type)
      await inventoryData(page, str, pagePerEntries, item_type, low_inventory, non_active_inventory)
        .then((res) => {
          const inventoryItems = res?.data?.items
          setData(inventoryItems)
          setHeader({
            ...header,
            incomingItems: res?.data?.purchase_orders_count,
            totalStockValue: res?.data?.total_stock_value,
            inactiveItems: res?.data?.inactiveItemsCount,
            lowInventory: res?.data?.low_inventory_count,
          })

          seTotalRows(res?.data?.total_item_rows)
          const stateHeader = {
            payload: {subtitle: `${res?.data?.total_item_rows} Total Records`},
            type: HeaderAction.Update,
          }
          headerContext.dispatch(stateHeader)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )
  // {selectedItem}
  useEffect(() => {
    // console.log(selectedItem)
    setLoading(true)
    const search: any = searchParams.get('str')
    getList(0, search, 10, selectedItem)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const CSV_HEADER = useMemo(
    () => [
      'Mfg. Part No.',
      'Item Name',
      'Item Description',
      'Manufacturer',
      'Category',
      'Vendor',
      'Inventory Location',
      'Order Link',
      'Reorder Level',
      'Available Stock',
      'Item Cost',
      'Retail Price',
      'Item Image',
    ],
    []
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx: any) => {
        return [
          eachIdx?.sku,
          eachIdx?.name,
          eachIdx?.item_description,
          eachIdx?.brand?.name,
          eachIdx?.category?.name,
          eachIdx?.vendor?.name,
          eachIdx?.inventory_location,
          eachIdx?.order_link,
          eachIdx?.reorder_level,
          eachIdx?.available_stock,
          eachIdx?.item_cost,
          eachIdx?.retail_price,
          eachIdx?.image_field,
        ]
      }),
    [data]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  return HAS_InventoryViewTable(currentUser) ? (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      <div className='card mb-2 mb-xl-10 p-0'>
        <div className='container' style={{background: '#F5F8FA'}}>
          <div className='row'>
            {INVENTORY_HEADER.map(
              ({
                item_Icon,
                name,
                quantity,
                click_Link,
                lowInventoryFnc,
                nonActiveInventoryFnc,
              }: any) => {
                return (
                  <>
                    {loading ? (
                      <div
                        className='col m-1  bg-white shadow'
                        style={{padding: '20px', textAlign: 'center'}}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div
                        className='col m-1  bg-white shadow'
                        style={{padding: '20px'}}
                        // {...click_Link && onclick = ''}
                        onClick={
                          click_Link
                            ? () => navigate(`/${company}/${click_Link}`)
                            : lowInventoryFnc
                            ? () => getList(0, '', 100, 1, 0)
                            : nonActiveInventoryFnc
                            ? () => getList(0, '', 100, 0, 1)
                            : (e) => e.preventDefault()
                        }
                      >
                        <div className='row cursor-pointer align-items-center'>
                          <div
                            className='col-4'
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <KTSVG path={`${item_Icon}`} svgClassName='c-size-svg' />
                          </div>
                          <div className='col-8'>
                            <p className='m-0' style={{fontSize: '13px'}}>
                              {header[quantity] || 0}
                              <br />
                              <br />
                              <p>{name}</p>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }
            )}
          </div>

          <div className='card  mt-5 p-0 overflow-scroll custom-data-table'>
            <MaterialDataTable
              MaterialColumns={MaterialColumns}
              stopRedirection={HAS_InventoryViewItem(currentUser)}
              MaterialColumnsApiData={data}
              totalRows={totalRows}
              loadingBar={loading}
              tableHeight={650}
              navigationLink={'inventory/store'}
              additionalNavigationLink={'view'}
              serverPagination={pagination}
              setServerPagination={(cb_data: any) => {
                return PaginationCallback(cb_data)
              }}
              columnVisibility={tableStorage}
              cbColumnVisibility={(callbackData) => {
                cbFunction(callbackData)
              }}
              optionalTableHeader={
                <div
                  style={{gap: '10px'}}
                  className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'
                >
                  <h4
                    style={{cursor: 'pointer'}}
                    className='fw-bolder m-5 w-115px'
                    onClick={() => {
                      navigate(`/${prefixCompany}/inventory/store/create`)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen067.svg'
                      className='svg-icon-4 me-3'
                    />
                    Store Inventory
                  </h4>
                  {/* <KTSVG
                    path={'/media/icons/duotune/general/gen055.svg'}
                    className={'svg-icon-1'}
                  />

                  <div
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`/${prefixCompany}/inventory/store/categories`)
                    }}
                  >
                    Edit Inventory Categories
                  </div> */}
                  <div className='god-father_2'>
                    <div className='sub-god_2'>
                      <UsersListSearchComponent />{' '}
                    </div>
                    <FormControl className='sub-god_2 h-35'>
                      <InputLabel id='all-customer'>Item Filter</InputLabel>
                      <Select
                        style={{height: '35px'}}
                        labelId='all-customer'
                        id='demo-simple-select'
                        label='Item Filter'
                        value={selectedItem}
                        onChange={handleActive}
                      >
                        <MenuItem value='All Items'>All</MenuItem>
                        <MenuItem value='Active Items'>Active</MenuItem>
                        <MenuItem value='Inactive Items'>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='mui-icons'>
                    <InventorySettings />
                    {HAS_InventoryCreateItem(currentUser) ? (
                      <button
                        className='btn btn-primary h-35px w-35px fw-bold'
                        onClick={() => navigate(`/${prefixCompany}/inventory/store/create`)}
                      >
                        <div style={{marginTop: '-8px', marginLeft: '-6px', fontSize: '20px'}}>
                          +
                        </div>
                      </button>
                    ) : null}
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

export {Inventory}
