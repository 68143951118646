/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react'
import {getDepartments} from '../../../../app/modules/auth/core/_requests'
import Roles from '../../../../app/pages/dashboard/Roles'
export const RolesDropDown = ({defaultValue, onChange}: any) => {
  const [data, setData] = useState([])
  const getList = useCallback(() => {
    getDepartments()
      .then((res) => {
        setData(res?.data?.response)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [])

  return (
    <>
      <select
        className='form-select form-select-solid'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        onChange={onChange}
      >
        <option selected hidden disabled>
          {defaultValue ? defaultValue : 'Please Select Role'}
        </option>

        {data.length !== 0 ? (
          data?.map((item: any) => <option value={item?.title}>{item?.title}</option>)
        ) : (
          <>
            <option value={Roles.Executive.value}>{Roles.Executive.label}</option>
            <option value={Roles.Admin.value}>{Roles.Admin.label}</option>
            <option value={Roles.Sales.value}>{Roles.Sales.label}</option>
            <option value={Roles.Engineering.value}>{Roles.Engineering.label}</option>
            <option value={Roles.Production.value}>{Roles.Production.label}</option>
          </>
        )}
      </select>
    </>
  )
}

export default RolesDropDown
