/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {ProfileContext} from '../../../_metronic/layout/MasterLayout'
import {ProfileDetails} from '../accounts/components/settings/cards/ProfileDetails'
import {useAuth} from '../auth'

const ProfileHeader: React.FC = () => {
  const user = useAuth()
  const profilecontext: any = useContext(ProfileContext)

  return (
    <>
      <div className='card mb-5 mb-xl-10 '>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={profilecontext?.state?.profilepic.pic}
                  alt={user?.currentUser?.first_name}
                  
                />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <div
                      className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                      style={{cursor: 'pointer'}}
                    >
                      {profilecontext?.state?.profilepic?.first_name +
                        ' ' +
                        profilecontext?.state?.profilepic?.last_name}
                    </div>
                    <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {user?.currentUser?.company}
                    </a>
                    {/* <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1' />
                    SF, Bay Area
                  </a> */}
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {user?.currentUser?.email}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <div className='me-0'>
                    {/* <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button> */}
                    {/* <Dropdown1 /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{<ProfileDetails />}</div>
    </>
  )
}

export {ProfileHeader}
