/* eslint-disable react-hooks/exhaustive-deps */
// / eslint-disable jsx-a11y/anchor-is-valid /
import {useCallback, useContext, useEffect} from 'react'
import {FC} from 'react'
import {useAuth} from '../../modules/auth'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {QuoteWidget} from '../../../_metronic/partials/widgets/feeds/QuoteWidget'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  update_dashboard_quote_month,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'

type PropsType = {
  handleCloseModal?: () => void
}

const AddQuotes: FC = ({handleCloseModal}: PropsType) => {
  const {currentUser} = useAuth()
  const headerContext = useContext<any>(HeaderContext)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const navigate = useNavigate()

  const handleQuote = useCallback(async (e: any) => {
    await update_dashboard_quote_month(e)
      .then(({data}) => {
        darkMessage(data?.message)
        handleCloseModal ? handleCloseModal() : navigate(`/${prefixCompany}/dashboard`)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
      })
  }, [])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Add Quotes',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <QuoteWidget
      Heading='Update Quote Of The Month'
      className='mb-5 mb-xl-8'
      getQuote={handleQuote}
    />
  )
}

export default AddQuotes
