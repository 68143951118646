// Top Bar
import {LinearProgress, Menu, MenuItem} from '@mui/material'
import clsx from 'clsx'
import produce from 'immer'
import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {TimeSheetConstant, TimeSheetTextConstant} from '../../../../app/context/TimeSheetConstant'
import {useAuth} from '../../../../app/modules/auth'
import {
  currentlyClockIn,
  currnetCompanyName,
  darkErrorMessage,
  ShopTimeClockIN,
  quotesList,
} from '../../../../app/modules/auth/core/_requests'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {ProfileContext} from '../../MasterLayout'
import blank from './blank.png'
import {ClockInModal} from './ClockInModal'
import {Modal} from 'react-bootstrap'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {Quote} from '../../../../app/pages/dashboard/Quote'
import {
  HAS_CreateFieldService,
  HAS_CreateProject,
  HAS_CustomerCreate,
  HAS_InvitedEmployeeCreateInvitedEmployee,
  HAS_QuoteCreate,
} from '../../../../app/pages/dashboard/PermissionFunction'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
const Topbar: FC = () => {
  const TIMESHEET_TYPE = TimeSheetConstant.JOB
  // const {config} = useLayout()
  const {config} = useLayout()
  const {header, aside} = config

  const {currentUser}: any = useAuth()
  const userCompany = currentUser?.company
  const userCompanyLogo = currentUser?.company_logo.includes('None')
    ? blank
    : currentUser?.company_logo
  const companyName: any = currnetCompanyName(currentUser?.company)
  const [titleLastTimer, setTitleLastTimer] = useState<any>()
  const [clockInLink, setClockInLink] = useState('')
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const profileContext: any = useContext(ProfileContext)
  // Modal realated  state and function
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState<any>()
  // const company = currnetCompanyName(currentUser?.company)
  const [modal, setModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [projectType, setProjectType] = useState('')

  // End Modal realated state and function
  const navigate = useNavigate()
  // Effect for API call

  const lastLoginedTime = useCallback(() => {
    currentlyClockIn()
      .then((res) => {
        const title = res?.data?.clocked_in?.final_title
        const id = res?.data?.clocked_in?.quote?.id
        const shopTime = res?.data?.clocked_in_timesheet
        let clock_in_data = Object.keys(shopTime).length
        const type =
          res?.data?.clocked_in?.timesheet_type === TimeSheetConstant.QUOTE
            ? TimeSheetTextConstant.QUOTE
            : res?.data?.clocked_in?.timesheet_type === TimeSheetConstant.WORKORDER
            ? TimeSheetTextConstant.WORKORDER
            : TimeSheetTextConstant.FIELDSERVICE
        const link = `/${companyName}/${type}/${id}/overview`
        setTitleLastTimer(title)
        setValue(clock_in_data)
        if (id) {
          setClockInLink(link)
        } else {
          setClockInLink('')
        }
      })
      .catch(() => {
        setTitleLastTimer('')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosed = () => {
    setAnchorEl(null)
  }
  const open3 = Boolean(anchorEl3)

  const handleSubmit = useCallback(() => {
    try {
      async function getData() {
        setLoading(true)
        await ShopTimeClockIN(TIMESHEET_TYPE)
          .then((res: any) => {
            setValue(res?.data?.data)
            setTimeout(() => {
              setShowModal(false)
              setLoading(false)
              profileContext?.state?.setClockIn((prev: any) => !prev)
            }, 0)
          })
          .catch((e: any) => {
            console.error(e)
            darkErrorMessage('Error in Clocked in')
          })
      }
      getData()
    } catch (error: any) {
      console.error(error)
    }
  }, [TIMESHEET_TYPE, profileContext?.state])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  useEffect(() => {
    lastLoginedTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext?.state?.clockIn])

  useEffect(() => {
    setTimeout(() => {
      lastLoginedTime()
    }, 1000 * 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    lastLoginedTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext?.state?.clockIn])

  const checkClockIn = useCallback(
    (event: any) => {
      event.stopPropagation()
      clockInLink ? navigate(clockInLink) : setShowModal(true)
    },
    [clockInLink, navigate]
  )
  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      quote_type = 'all',
      tag_type: string[] | string = 'all',
      pagePerEntries: number = 100
      // pagePerEntries: number = rowsPerPage
    ) => {
      await quotesList(page, str, quote_type, tag_type, pagePerEntries, undefined)
      // .then((res) => {
      //   let employes = res.data.quotes as QuotesModel[]
      //   setData(employes)
      //   seTotalRows(res.data.totalRows)
      //   setOption(res?.data?.status)
      //   const stateHeader = {
      //     payload: {subtitle: `${res.data.totalRows} Total Records`},
      //     type: HeaderAction.Update,
      //   }
      //   setLoading(false)
      //   headerContext.dispatch(stateHeader)
      // })
      // .catch((e) => {
      //   setLoading(false)
      // })
    },
    []
  )
  const handleTask = useCallback(() => {
    navigate(`/${prefixCompany}/settings/tasks/create`)
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  const handleCustomer = useCallback(() => {
    navigate(`/${prefixCompany}/customers/create-customer`)
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  const handleEmployee = useCallback(() => {
    navigate(`/${prefixCompany}/employees/invitations`)
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  const handleServicefield = useCallback(() => {
    navigate(`/${prefixCompany}/fieldservice`)
    setCreateModal(true)
    setProjectType('Field Service')
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  const handleQuote = useCallback(() => {
    navigate(`/${prefixCompany}/quote`)
    setCreateModal(true)
    setProjectType('Project Quote')
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  const handleWorkorder = useCallback(() => {
    navigate(`/${prefixCompany}/project`)
    setCreateModal(true)
    setProjectType('Project')
    setAnchorEl(null)
  }, [navigate, prefixCompany])

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
      <Search />
    </div> */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      <div
        className={clsx(
          'btn btn-icon btn-active-light-primary btn-custom',
          toolbarButtonHeightClass
        )}
        id='kt_activities_toggle'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen032.svg'
          className={toolbarButtonIconSizeClass}
        />
      </div>
    </div> */}

        {/* NOTIFICATIONS */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      <div
        className={clsx(
          'btn btn-icon btn-active-light-primary btn-custom',
          toolbarButtonHeightClass
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen022.svg'
          className={toolbarButtonIconSizeClass}
        />
      </div>
      <HeaderNotificationsMenu />
    </div> */}

        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      <div
        className={clsx(
          'btn btn-icon btn-active-light-primary btn-custom position-relative',
          toolbarButtonHeightClass
        )}
        id='kt_drawer_chat_toggle'
      >
        <KTSVG
          path='/media/icons/duotune/communication/com012.svg'
          className={toolbarButtonIconSizeClass}
        />
        <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
      </div>
    </div> */}

        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      <div
        className={clsx(
          'btn btn-icon btn-active-light-primary btn-custom',
          toolbarButtonHeightClass
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen025.svg'
          className={toolbarButtonIconSizeClass}
        />
      </div>
      <QuickLinks />
    </div> */}

        {/* begin::Theme mode */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      <ThemeModeSwitcher
        toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
      />
    </div> */}
        {/* end::Theme mode */}

        {/* begin::User */}
        {/* <div  style={{width: '350px'}}>
          <Button onClick={handleOpen}>Open modal</Button>
          <Modal
            open={modal}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div>
                <input style={{borderRadius: '7px', width: '150px', height: '50px'}}></input>
              </div>
            </Box>
          </Modal>
        </div> */}

        <div
          className='m-auto pe-4 d-flex align-items-center gap-5 fw-bold nav-mobile-icons'
          style={{color: '#4b525c'}}
        >
          <a href={userCompanyLogo} target='_blank' rel='noopener noreferrer'>
            <img
              src={userCompanyLogo}
              alt={userCompany}
              style={{objectFit: 'contain', height: '40px', width: '40px'}}
            />
          </a>
          {userCompany.charAt(0).toUpperCase() + userCompany.slice(1)}
          <style>
            {`
        @media (max-width: 767px) {
          .desktop-view {
            display: none;
          }
        }
        `}
          </style>
        </div>
        {/* <Menu
          id='basic-menu'
          anchorEl={anchorEl2}
          open={open}
          onClose={handleClosed}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <>
            <MenuItem onClick={handleTask}>Add New Task</MenuItem>
            <MenuItem onClick={handleCustomer}>Create New Customer</MenuItem>
            <MenuItem onClick={handleQuote}>Create New Quote</MenuItem>
            <MenuItem onClick={handleWorkorder}>Create New Work Order</MenuItem>
            <MenuItem onClick={handleServicefield}>Create New Field Service</MenuItem>
            <MenuItem onClick={handleEmployee}>Invite New Employee</MenuItem>
          </>
        </Menu> */}
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          show={modal}
          onHide={() => {
            setModal(false)
          }}
          className='bg-opacity-1 text-dark'
        >
          <div className='' style={{width: '100%'}}>
            <UsersListSearchComponent />
          </div>
        </Modal>
        <Menu
          className='btn btn-sm btn-icon btn-light btn-active-light-primary my-4'
          style={{background: '#f5f8fa', padding: '10px', borderRadius: '10px'}}
          onClick={(event) => {
            setAnchorEl2(event.currentTarget)
          }}
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          open={false}
        >
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2' />
        </Menu>
        {/* {toggle && (
          <div className='m-auto '>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-90px '
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            /> */}

        {/* )} */}

        <div className='m-auto mx-1 cursor-pointer nav-mobile-icons'>
          <button
            className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2 '
            onClick={(event) => {
              setAnchorEl3(event.currentTarget)
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 ' />
          </button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl3}
            open={open3}
            onClose={() => {
              setAnchorEl3(null)
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <>
              <UsersListSearchComponent />{' '}
            </>
          </Menu>
        </div>

        <div className='m-auto mx-1 cursor-pointer '>
          <button
            className='btn btn-sm btn-icon btn-light btn-active-light-primary '
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <KTSVG path='/media/icons/duotune/general/gen080.svg' className='svg-icon-1 ' />
          </button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClosed}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <>
              <MenuItem onClick={handleTask}>Add New Task</MenuItem>
              {HAS_CustomerCreate(currentUser) ? (
                <MenuItem onClick={handleCustomer}>Create New Customer</MenuItem>
              ) : (
                <></>
              )}
              {HAS_QuoteCreate(currentUser) ? (
                <MenuItem onClick={handleQuote}>Create New Quote</MenuItem>
              ) : (
                <></>
              )}
              {HAS_CreateProject(currentUser) && (
                <MenuItem onClick={handleWorkorder}>Create New Project</MenuItem>
              )}
              {HAS_CreateFieldService(currentUser) && (
                <MenuItem onClick={handleServicefield}>Create New Field Service</MenuItem>
              )}
              {HAS_InvitedEmployeeCreateInvitedEmployee(currentUser) ? (
                <MenuItem onClick={handleEmployee}>Invite New Employee</MenuItem>
              ) : (
                <></>
              )}
            </>
          </Menu>
        </div>
        <Quote
          modal={createModal}
          setModal={(cb_value: boolean) => {
            setCreateModal(cb_value)
          }}
          getWorkOrderData={getList}
          projectType={projectType}
        />
        <div className='m-auto mx-1 cursor-pointer nav-mobile-icons'>
          <button className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'>
            <KTSVG path='/media/icons/duotune/general/bell.svg' className='svg-icon-1 ' />
          </button>
        </div>

        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <ClockInModal
              show={showModal}
              onModalClose={onModalClose}
              description={`Do you want to Clock ${value ? 'Out from' : 'In into'} Shop Time  ?`}
              handleSubmit={handleSubmit}
              value={value}
            />
          )}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={profileContext?.state?.profilepic.pic}
              alt={currentUser?.first_name}
              style={{objectFit: 'contain'}}
              onError={() => {
                profileContext?.state?.setProfilePic(
                  produce((draft: any) => {
                    draft.pic = blank
                  })
                )
              }}
            />
            <span style={{color: '#a1a1a1'}} className='ms-2'>
              <span
                style={{
                  // paddingLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <span className='text-dark' style={{fontWeight: '600', fontSize: '15px'}}>
                  {currentUser?.first_name.charAt(0).toUpperCase() +
                    currentUser?.first_name.slice(1) +
                    ' ' +
                    currentUser?.last_name.charAt(0).toUpperCase() +
                    currentUser?.last_name.slice(1)}
                </span>
                <span style={{fontWeight: '600'}}>
                  <div className='menu-item me-lg-1 ' onClick={checkClockIn}>
                    {' '}
                    <span
                      // className='badge badge-primary'
                      className='badge'
                      style={{cursor: 'pointer'}}
                    >
                      <strong>
                        {/* <KTSVG path='/media/custom/clock.svg' svgClassName='w-25px h-25px' /> */}
                        {clockInLink ? (
                          <span
                            className='menu-title text-success'
                            style={{color: '#a1a1a1', fontSize: '13px'}}
                          >
                            {titleLastTimer}
                          </span>
                        ) : (
                          <span
                            className={`menu-title ${value ? 'text-warning  ' : 'text-danger'}`}
                            style={{fontSize: '13px'}}
                          >
                            {value ? 'Shop Time' : 'Not Clocked In'}
                          </span>
                        )}
                      </strong>
                    </span>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {Topbar}
function useRef() {
  throw new Error('Function not implemented.')
}
