/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ErrorType} from '../../modules/errors/ErrorAlert'
import {SuccessType} from '../../modules/errors/SuccessAlert'
import {
  Inventory_Management,
  Inventory_Management_Initials as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import {
  createBrand,
  createCategory,
  createShippingType,
  createSubcategory,
  createVendor,
  darkErrorMessage,
  darkMessage,
} from '../../modules/auth/core/_requests'
export function InventoryManagement(props: any) {
  const [loading, setLoading] = useState(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  let method = props.label
  let customer_id = props.customerData
  let dynamic_title: string
  dynamic_title = ''
  if (method === 'manufacturer') {
    dynamic_title = 'Manufacturer'
  } else {
    if (method === 'vendor') {
      dynamic_title = 'Vendor'
    } else {
      if (method === 'shipping') {
        dynamic_title = 'Shipping Method'
      } else {
        if (method === 'category') {
          dynamic_title = 'Category'
        } else {
          if (method === 'subcategory') {
            dynamic_title = 'Subcategory'
          }
        }
      }
    }
  }
  const Inventory_validation = Yup.object().shape({
    inventory_info: Yup.string().required(`${dynamic_title} is required`),
  })
  // useEffect(() => {
  //   const stateHeader = {
  //     payload: {
  //       custom: true,
  //       title: `Create New ${dynamic_title}`,
  //       subtitle: ` `,
  //       btn_text: 'Back To Inventory',
  //       navigation_url: `/${prefixCompany}/store`,
  //       additional_btn_text: ' ',
  //     },
  //     type: HeaderAction.Update,
  //   }
  //   headerContext.dispatch(stateHeader)
  // }, [])
  const formik = useFormik<Inventory_Management>({
    initialValues,
    validationSchema: Inventory_validation,
    onSubmit: (values) => {
      setLoading(true)
      if (method == 'manufacturer') {
        createBrand(values.inventory_info)
          .then(({data}) => {
            setSuccessBody(data as any)
            setLoading(false)
            props?.onModalClose()
            formik.resetForm()
            darkMessage('Manufacturer added successfully')
            props?.callbackData()
          })
          .catch((e) => {
            if (e.response.status === 409) {
              darkErrorMessage('Manufacturer already exists')
              setErrorBody(e.response.data)
              setLoading(false)
            }
          })
      } else {
        if (method === 'vendor') {
          createVendor(values.inventory_info)
            .then(({data}) => {
              setLoading(false)
              props?.onModalClose()
              formik.resetForm()
              darkMessage('Vendor added successfully')
              props?.callbackData()
            })
            .catch((e) => {
              if (e.response.status === 409) {
                darkErrorMessage('Vendor already exists')
                setErrorBody(e.response.data)
                setLoading(false)
              }
            })
        } else {
          if (method === 'shipping') {
            createShippingType(values.inventory_info)
              .then(({data}) => {
                setLoading(false)
                props?.onModalClose()
                formik.resetForm()
                darkMessage('Shipping method added successfully')
                props?.callbackData()
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setErrorBody(e.response.data)
                  darkErrorMessage('Shipping method already exists')
                  setLoading(false)
                }
              })
          } else {
            if (method === 'category') {
              createCategory(values.inventory_info)
                .then(({data}) => {
                  setLoading(false)
                  props?.onModalClose()
                  formik.resetForm()
                  darkMessage('Category added successfully')
                  props?.callbackData()
                })
                .catch((e) => {
                  setLoading(false)
                  if (e.response.status === 409) {
                    darkErrorMessage(e?.response?.data?.error_list[0])
                    setLoading(false)
                    setErrorBody(e.response.data)
                    props?.getList()
                  }
                })
            } else {
              if (method == 'subcategory') {
                createSubcategory(values.inventory_info, customer_id)
                  .then(({data}) => {
                    setSuccessBody(data as any)
                    formik.resetForm()
                    setLoading(false)
                    props?.onModalClose()
                  })
                  .catch((e) => {
                    if (e.response.status === 409) {
                      setErrorBody(e.response.data)
                      setLoading(false)
                    }
                  })
              }
            }
          }
        }
      }
    },
  })

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2 className='fw-bolder m-2'>Enter {dynamic_title} Name</h2>
          <form onSubmit={formik.handleSubmit} noValidate className='form m-10'>
            <div className='row g-6 g-xl-9'>
              <input
                className='form-control form-control-lg '
                type='text'
                id=''
                {...formik.getFieldProps('inventory_info')}
              />
              {formik.touched.inventory_info && formik.errors.inventory_info && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.inventory_info}</div>
                </div>
              )}
              <button
                type='submit'
                className='btn btn-primary '
                style={{width: '230px'}}
                disabled={loading || !formik.isValid}
              >
                {!loading && `Create ${dynamic_title}`}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
