/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {currnetCompanyName} from '../../modules/auth/core/_requests'
import MaterialReactTable from 'material-react-table'
import {ClassNames} from '@emotion/react'
interface PropsType {
  MaterialColumns: any
  MaterialColumnsApiData: any[]
  totalRows: number
  tableHeight: number
  loadingBar: boolean
  stopRedirection?: boolean
  navigationLink?: string
  additionalNavigationLink?: string
  serverPagination?: any
  setServerPagination?: (data: any) => void
  optionalTableHeader?: any
  initialState?: any
  columnVisibility?: any
  cbColumnVisibility?: (columns: {}) => void
  DragAbleRowsData?: ([]) => void
  allowDragAbleRows?: boolean
  sorting?: boolean
  tableInstanceRef?: any
}

const MaterialDataTable = ({
  MaterialColumns,
  MaterialColumnsApiData,
  totalRows,
  loadingBar,
  tableHeight,
  navigationLink,
  serverPagination,
  additionalNavigationLink,
  setServerPagination,
  stopRedirection = true,
  optionalTableHeader = false,
  initialState = {},
  columnVisibility = {},
  cbColumnVisibility,
  allowDragAbleRows = false,
  sorting = false,
  DragAbleRowsData,
  tableInstanceRef = false,
}: PropsType) => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [pagination, setPagination] = useState({...serverPagination})

  useEffect(() => {
    setServerPagination && setServerPagination(pagination)
  }, [pagination])

  const handleNavigate = useCallback(
    (e: any) => {
      additionalNavigationLink
        ? navigate(`/${prefixCompany}/${navigationLink}/${e?.id}/${additionalNavigationLink}`)
        : navigate(`/${prefixCompany}/${navigationLink}/${e?.id}`)
    },
    [currentUser, navigationLink, prefixCompany]
  )

  return (
    <div>
      <div className='sticky_header_data_table'>
        <MaterialReactTable
          columns={MaterialColumns}
          muiTableHeadCellProps={{
            sx: {
              fontWeight: 'bold',
              fontSize: '14px',
            },
          }}
          muiTableBodyCellProps={{
            style: {fontSize: '14px'},
          }}
          muiTableBodyRowProps={({row}: any) => ({
            onClick: () => {
              stopRedirection ? handleNavigate(row?.original) : <></>
              localStorage.setItem('showAddButton', 'false')
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          data={MaterialColumnsApiData}
          defaultColumn={{
            maxSize: 400,
            minSize: 20,
            // size: 150,
          }}
          enableColumnResizing
          manualFiltering
          manualPagination
          manualSorting
          state={{pagination, isLoading: loadingBar, columnVisibility: columnVisibility}}
          onPaginationChange={setPagination}
          enableStickyHeader
          enableStickyFooter
          columnResizeMode='onChange'
          rowCount={totalRows}
          enableRowOrdering={allowDragAbleRows}
          muiTableBodyRowDragHandleProps={({table}) => ({
            onDragEnd: () => {
              const {draggingRow, hoveredRow}: any = table.getState()
              if (hoveredRow && draggingRow) {
                const newArray = [...MaterialColumnsApiData]
                ;[newArray[hoveredRow.index], newArray[draggingRow.index]] = [
                  newArray[draggingRow.index],
                  newArray[hoveredRow.index],
                ]
                MaterialColumnsApiData = newArray

                DragAbleRowsData && DragAbleRowsData(MaterialColumnsApiData)
              }
            },
          })}
          enableSorting={sorting}
          muiTablePaginationProps={{
            rowsPerPageOptions: [100, 200, 500],
          }}
          onColumnVisibilityChange={(e) => {
            cbColumnVisibility && cbColumnVisibility(e)
          }}
          enableGlobalFilter={false}
          enableColumnFilters={false}
          enableColumnActions={false}
          enableDensityToggle={true}
          enableFullScreenToggle={true}
          
          muiTableContainerProps={{sx: {maxHeight: `${tableHeight}px`}}}
          renderTopToolbarCustomActions={({table}) =>
            optionalTableHeader && (
              <div className='sticky-wrap check'>
                <div className='mui-sticky-header'>{optionalTableHeader}</div>
              </div>
            )
          }
          tableInstanceRef={tableInstanceRef && tableInstanceRef}
          initialState={initialState && initialState} //hide firstName column by default
          // enableEditing
        />
      </div>
    </div>
  )
}

export {MaterialDataTable}
