import {useState, useEffect} from 'react'
import {KTSVG, useDebounce} from '../../../../../../../_metronic/helpers'
import {createSearchParams, useNavigate} from 'react-router-dom'

const UsersListSearchComponent_v2 = (props: any) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const navigate = useNavigate()

  const debouncedSearchTerm = useDebounce(searchTerm, 100)

  useEffect(() => {
    const hasSearchTerm = searchTerm !== undefined && searchTerm.length > 0

    if (props.label && debouncedSearchTerm !== undefined) {
      navigate({
        search: createSearchParams({label: props.label, str: searchTerm}).toString(),
      })
    } else if (!searchTerm) {
      navigate({
        search: '',
      })
    }

    return () => {}
  }, [debouncedSearchTerm, navigate, searchTerm])

  return (
    <div className='d-flex' style={{}}>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-4 position-absolute ms-6'
        />
        <input
          style={{fontSize: '14px', padding: '7px 10px'}}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid ps-14'
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent_v2}
