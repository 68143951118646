/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {
  currnetCompanyName,
  customerDelete,
  darkErrorMessage,
  darkMessage,
  updateCustomer,
  updateCustomerContactUrl,
  updateCustomerStatus,
  viewCustomer,
} from '../../modules/auth/core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import moment from 'moment'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {Link} from 'react-router-dom'
import {HAS_CustomerDelete, HAS_CustomerUpdate, HAS_CustomerView} from './PermissionFunction'
import blank from '../../../_metronic/layout/components/header/upload.png'
import {Button, Modal} from 'react-bootstrap'
import {Quotes} from './Quotes'
import WorkOrder from './WorkOrder'
import {Campaigns} from '../dashboard/components/Campaigns'
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {CustomerModel} from '../../modules/model/customer'
import {CustomerFiles} from './components/CustomerFiles'

const options = ['Active', 'In Active']
const CustomerCustomView: React.FC = () => {
  const [data, setData] = useState<any>([])
  const getStatus: any = useMemo(() => {
    const statusidx =
      data && data?.status === '0' ? 'Default' : data?.status === '1' ? 'Active' : 'In Active'
    return statusidx
  }, [data])

  const getColor: any = useMemo(() => {
    const statusidx =
      data && data?.status === '0' ? 'warning' : data?.status === '1' ? 'success' : 'danger'
    return statusidx
  }, [data])

  const [modal, setModal] = useState(false)
  // const [modalStatus, setModalStatus] = useState(false)
  // const [selectedClient, setSelectedClient] = useState('Active')

  const [customerStatus, setCustomerStatus] = useState('')
  const [showModal, setShowModal] = useState<any>(false)
  const inputFile = useRef<any>()
  const {currentUser} = useAuth()
  const {id} = useParams<any>()
  const navigate = useNavigate()
  const [buffer, setBuffer] = useState(false)

  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [compo, setCompo] = useState('contact')
  const [img, setImg] = useState<any>('/media/avatars/blank.png')

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Customer View',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/customers`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getList = useCallback(() => {
    viewCustomer(id)
      .then((res) => {
        let employes = res?.data?.customer_details
        const array = [
          {
            fName: employes.first_name,
            lName: employes.last_name,
            email: employes.email,
            cupro: employes?.type,
            phone: employes.phone,
            companyphone: employes.company_phone,
            contactTitle: '',
          },
        ]
        if (employes.fieldArr == null) {
          employes.fieldArr = JSON.stringify(array)
        }
        setData(employes || [])
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const deleteButton = (id: any) => {
    customerDelete(id)
      .then((res) => {
        darkMessage('Customer Deleted Successfully')
        navigate(`/${prefixCompany}/customers`)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  // updateCustomerContactUrl
  async function updateCustomerPic(customer_info: any, customer_id: any) {
    await updateCustomer(customer_info, customer_id)
      .then(() => {
        setBuffer(true)
        setTimeout(() => {
          setBuffer(false)
        }, 0)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
  const changeImg = (e: any) => {
    setImg(URL.createObjectURL(e.target.files[0]))
    try {
      let filesToUpload: any = []
      if (e?.target?.files != null) {
        Object.values(e.target.files).map((value) => {
          return filesToUpload.push(value)
        })
        let customer: CustomerModel = {
          id: id,
          profile_pic: filesToUpload,
          first_name: data?.first_name,
          last_name: data?.last_name,
          company: data?.company,
          company_phone: data?.company_phone,
          email: data?.email,
          phone: data?.phone,
          address: data?.address,
          city: data?.city,
          state: data?.state,
          zip_code: data?.zip_code,
          contact_method: data?.contact_mehtod,
          discount_type: data?.discount_type,
          referral_type: data?.referral_type,
          notes: data?.notes,
          fieldArr: JSON.parse(data?.fieldArr),
          address_line_2: data?.address_line_2,
          billing_address_1: data?.billing_address_1,
          billing_address_2: data?.billing_address_address_2,
          billing_city: data?.address_city,
          billing_state: data?.address_state,
          billing_zip_code: data?.zip_code,
          website: data?.website,
          cus_pros: data?.cus_pros || 'customer',
        }
        updateCustomerPic(customer, id)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handlePrimary = useCallback(
    (idx: number) => {
      const result = JSON.parse(data?.fieldArr).map((item: any, index: any) => {
        if (idx === index) {
          item.primary = !item.primary
        } else {
          item.primary = false
        }
        return item
      })

      let hasPrimary = result.some((contact: {primary: any}) => contact.primary)
      if (!hasPrimary) {
        result[0].primary = true
      }

      updateCustomerContactUrl(result, id)
        .then(({data}) => {
          getList()
          if (!hasPrimary) {
            darkErrorMessage('At least one contact must be marked as primary')
          } else {
            darkMessage('Contact Updated Successfully')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [data, getList, id, updateCustomerContactUrl]
  )

  useEffect(() => {
    if (data?.profile_pic) {
      setImg(data?.profile_pic)
    }
  }, [data])

  const updateStatus = useCallback(
    (customerStatus: any) => {
      updateCustomerStatus(id, customerStatus)
        .then(({data}) => {
          darkMessage('Status updated Successfully')
          setCustomerStatus('')
          navigate(`/${prefixCompany}/customers`)
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            darkErrorMessage(e?.response?.data)
          } else {
            console.log(e)
          }
          setCustomerStatus('')
        })
    },
    [customerStatus]
  )

  const onModalClose = useCallback(() => {
    setShowModal({show: false, data: null})
  }, [])
  return HAS_CustomerView(currentUser) ? (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={showModal?.show}
        onHide={() => {
          onModalClose()
        }}
        size='xl'
        centered
        // className='bg-opacity-1 bg-black'
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>Did you really want to change Customer Status?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              onModalClose()
            }}
          >
            Discard
          </button>

          <button
            className='btn btn-primary'
            onClick={() => {
              updateStatus(showModal?.idx)
            }}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      <div className='card mb-5 mb-xl-10'>
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          onModalClose={() => {
            setModal(false)
          }}
          label={'somelable'}
          show={modal}
          onHide={() => setModal(false)}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          className='fade'
          centered
        >
          <div className='card'>
            <div className='card-body' style={{padding: '25px'}}>
              <h1 className='fw-bolder m-5'>
                {' '}
                {data?.type
                  ? `Delete ${data?.type.charAt(0).toUpperCase() + data?.type.slice(1)}`
                  : 'Delete Customer'}
              </h1>
              <h3 className='lh-base m-5'>
                Are you sure want to{' '}
                {data?.type
                  ? `Delete ${data?.type.charAt(0).toUpperCase() + data?.type.slice(1)}`
                  : 'Delete Customer'}{' '}
                {data?.first_name + '  ' + data?.last_name?.charAt(0).toUpperCase() + '..'} ?
              </h3>
              <div className='d-flex flex-row-reverse mx-2'>
                <span className='mx-2'>
                  <button
                    onClick={() => {
                      deleteButton(id)
                    }}
                    className='btn btn-primary float-right m-5'
                  >
                    Delete
                  </button>
                </span>
                <span className='mx-1'>
                  <button
                    onClick={() => {
                      setModal(false)
                    }}
                    className='btn btn-dark float-right m-5'
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* side bar form start  */}
      <div className='col-md-12'>
        <div className='row'>
          <div className='card col-md-3 border'>
            <div className='card-body border-top' style={{padding: '10px'}}>
              <div
                className='d-flex justify-content-center rounded-circle'
                style={{overflow: 'hidden'}}
              >
                {buffer ? (
                  <CircularProgress style={{margin: 'auto'}} />
                ) : (
                  <>
                    <input
                      type='file'
                      ref={inputFile}
                      style={{display: 'none'}}
                      onChange={changeImg}
                      accept='image/*'
                    />
                    <img
                      src={img}
                      alt='Customer Image'
                      // onClick={onButtonClick}
                      onError={() => {
                        setImg(blank)
                      }}
                      style={{
                        width: '190px',
                        height: 'auto',
                        margin: 'auto',
                        cursor: 'pointer',
                        objectFit: 'contain',
                        borderRadius: '50%',
                        maxWidth: '100%',
                      }}
                    />
                  </>
                )}
              </div>

              <div className='mt-5 custom-row' style={{padding: '5px 10px'}}>
                <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Business Name</h6>
                  <span>{data?.company || 'N/A'}</span>
                </div>
                <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Date Created</h6>
                  <span>{moment(data?.date_created).format('MMM. Do,  YYYY') || 'N/A'}</span>
                </div>
                <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Website</h6>
                  <span>{data?.website || 'N/A'}</span>
                </div>
                <div className=' mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Referral Source</h6>
                  <span>{data?.referral_type || 'N/A'}</span>
                </div>
                <div className=' mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Customer Notes</h6>
                  <span>{data?.notes || 'N/A'}</span>
                </div>
                <div className='mt-4' style={{width: '100%'}}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h6 className='mb-0 me-2'>Status</h6>
                    <select
                      id='demo-simple-select'
                      onChange={(e) => setShowModal({show: true, idx: e?.target?.value})}
                      className='border-1 text-left rounded'
                      style={{
                        height: '25px',
                        borderColor: 'lightgrey',
                        background: '#fff',
                        minWidth: '80px',
                        paddingLeft: '6px',
                      }}
                    >
                      <option disabled selected value=''>
                        {getStatus}
                      </option>
                      {options.map((item, key) => (
                        <option value={key + 1} key={key + 1}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='fw-600 mt-3 d-flex align-items-center'>
                    <span
                      className={`bg-${getColor} me-2`}
                      style={{display: 'block', width: '10px', height: '10px', borderRadius: '50%'}}
                    ></span>
                    {getStatus}
                  </div>
                </div>
              </div>
              <div className='mt-5 custom-row d-flex justify-content-between customer-view-btn'>
                <div className='col-6'>
                  {HAS_CustomerUpdate(currentUser) ? (
                    <button
                      type='submit'
                      className='btn btn-light btn-block'
                      onClick={() => navigate(`/${prefixCompany}/customers/edit/${id}`)}
                    >
                      {data?.type
                        ? `Edit ${data?.type.charAt(0).toUpperCase() + data?.type.slice(1)}`
                        : 'Edit Customer'}
                    </button>
                  ) : null}
                </div>
                <div className='col-6'>
                  {HAS_CustomerDelete(currentUser) ? (
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      onClick={() => {
                        setModal(true)
                      }}
                    >
                      {data?.type
                        ? `Delete ${data?.type.charAt(0).toUpperCase() + data?.type.slice(1)}`
                        : 'Delete Customer'}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* right section */}
          <div className='col-md-9'>
            <div className='row' style={{marginLeft: '0px'}}>
              {/*right side form start with heading tag */}
              <div className='card col-md-12 border p-0'>
                <div className='card-body border-top p-lg-7 p-sm-3 tabs-titles'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'contact' ? 'text-danger' : ''}
                          onClick={() => setCompo('contact')}
                          style={{cursor: 'pointer'}}
                        >
                          Contact Info
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'address' ? 'text-danger' : ''}
                          onClick={() => setCompo('address')}
                          style={{cursor: 'pointer'}}
                        >
                          Addresses
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'project' ? 'text-danger' : ''}
                          onClick={() => setCompo('project')}
                          style={{cursor: 'pointer'}}
                        >
                          Projects
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'quotes' ? 'text-danger' : ''}
                          onClick={() => setCompo('quotes')}
                          style={{cursor: 'pointer'}}
                        >
                          Quotes
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'activity' ? 'text-danger' : ''}
                          onClick={() => setCompo('activity')}
                          style={{cursor: 'pointer'}}
                        >
                          Activity
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'files' ? 'text-danger' : ''}
                          onClick={() => setCompo('files')}
                          style={{cursor: 'pointer'}}
                        >
                          Files
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*right side form heading end */}

              {compo && compo === 'contact' ? (
                <div
                  className='mt-5 tab-grid-content p-0'
                  style={{display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '20px'}}
                >
                  {data?.fieldArr !== 'null' &&
                    data?.fieldArr?.length > 0 &&
                    JSON.parse(data.fieldArr).map((val: any, key: number) => {
                      return (
                        <>
                          <div className=' card'>
                            <div className=' card-body border custom-row'>
                              <div className='mt-5'>
                                <span
                                  style={{cursor: 'pointer', float: 'right'}}
                                  onClick={() => {
                                    handlePrimary(key)
                                  }}
                                >
                                  <KTSVG
                                    // width='50'
                                    // height='25'
                                    svgClassName='w-30px h-30px'
                                    path={
                                      val?.primary
                                        ? '/media/custom/pin-red.svg'
                                        : '/media/custom/pin-white.svg'
                                    }
                                  />
                                </span>
                                <div className=''>
                                  <h6 className='mb-1'>Title</h6>
                                  <span>{val?.contactTitle || 'N/A'}</span>
                                </div>
                              </div>
                              <div className='mt-5'>
                                <div className=''>
                                  <h6 className='mb-1'>Name</h6>
                                  <span>{val?.fName + '  ' + val?.lName || 'N/A'}</span>
                                </div>
                              </div>
                              <div className=' mt-5'>
                                <div className=''>
                                  <h6 className='mb-1'>Phone Number</h6>
                                  <span>{val?.phone || 'N/A'}</span>
                                </div>
                              </div>
                              <div className=' mt-5'>
                                <div className=''>
                                  <h6 className='mb-1'>Email</h6>
                                  <span>{val?.email || 'N/A'}</span>
                                </div>
                              </div>
                              <div className='mt-5'>
                                <div className=''>
                                  <h6 className='mb-1'>Other</h6>
                                  <span>{val?.companyphone || 'N/A'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              ) : compo && compo === 'address' ? (
                <div className='col-md-12  mt-5 p-0'>
                  <div className='row custom-row'>
                    <div className='col-md-6'>
                      <div className='border-top card card-body border p-9'>
                        <div className='col-md-12 mt-5'>
                          <div className=''>
                            <h6 className='mb-3'>Mailing Address 1</h6>
                            <span className='mt-5'>{data.address || 'N/A'}</span>
                          </div>
                        </div>
                        <div className='col-md-12 mt-5'>
                          <div className=''>
                            <h6 className='mb-3'>Mailing Address 2</h6>
                            <span className='mt-5'>{data.address_line_2 || 'N/A'}</span>
                          </div>
                        </div>

                        <div className='row mt-5'>
                          <div className='col-lg-6  fv-row'>
                            <div className=''>
                              <h6 className='mb-3'>city</h6>
                              <span className='mt-5'>{data.city || 'N/A'}</span>
                            </div>
                          </div>
                          <div className='col-lg-6 fv-row '>
                            <div className=''>
                              <h6 className='mb-3'>State</h6>
                              <span className='mt-5 '>{data.state || 'N/A'}</span>
                            </div>
                          </div>
                          <div className='col-lg-6 fv-row '>
                            <div className='mt-4'>
                              <h6 className='mb-3'>Zip Code</h6>
                              <span className='mt-5 '>{data?.zip_code || 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='border-top card card-body border p-9'>
                        <div className='mt-5'>
                          <h6 className='mb-3'>Billing Address</h6>
                          <span className='mt-5'>{data.billing_address_1 || 'N/A'}</span>
                        </div>
                        <div className='col-md-12 mt-5'>
                          <h6 className='mb-3'>Billing Address2</h6>
                          <span className='mt-5'>{data.billing_address_2 || 'N/A'}</span>
                        </div>

                        <div className='row mt-5'>
                          <div className='col-lg-6  fv-row'>
                            <h6 className='mb-3'> City</h6>
                            <span className='mt-5'>{data.billing_city || 'N/A'}</span>
                          </div>
                          <div className='col-lg-6 fv-row'>
                            <h6 className='mb-3'>State</h6>
                            <span className='mt-5'>{data.billing_state || 'N/A'}</span>
                          </div>
                          <div className='col-lg-6 fv-row'>
                            <div className='mt-4'>
                              <h6 className='mb-3'>Zip Code</h6>
                              <span className='mt-5 '>{data?.billing_zip_code || 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {compo && compo === 'project' ? (
                <>
                  <div className='mt-5 p-1'>
                    <WorkOrder />
                  </div>
                </>
              ) : compo && compo === 'quotes' ? (
                <>
                  <div className='mt-5 p-1'>
                    <Quotes />
                  </div>
                </>
              ) : compo && compo === 'activity' ? (
                <>
                  <div className='mt-5 p-1'>
                    <Campaigns />
                  </div>
                </>
              ) : compo && compo === 'files' ? (
                <>
                  <div className='mt-5 p-1'>
                    <CustomerFiles />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}
export {CustomerCustomView}
