export interface AuthModel {
  api_token?: string
  refreshToken?: string
  access: string
  refresh: string
  role : string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  profile_image?: string
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  user_type: string
  last_name: string
  fullname?: string
  phone_number?:number
  occupation?: string
  companyName?: string
  company_email?:string
  company_phone_number?:number
  company_address_line1?:string
  company_address_line2?:string
  company?: string
  phone?: string
  permissions?: any
  date_joined?: string
  is_staff?: string
  roles?: Array<number>
  pic?: string
  // user_type:string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  access?: string
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
}

export const UserModel_Intial: UserModel = {
  company: '',
  email: '',
  phone: '',
  user_type:'',
  id: 0,
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  company_email: '',
  company_phone_number: '',
  company_address_line1: '',
  company_address_line2: ''
  // user_type:''
}
