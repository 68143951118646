import {CircularProgress} from '@mui/material'
import React from 'react'
import {Modal, ModalBody} from 'react-bootstrap'

type generate_pdf = {
  pdfOpen: boolean
  pdfText: string
}
const GeneratePDF = ({pdfOpen = false, pdfText}: generate_pdf) => {
  return (
    <Modal
      style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', backdropFilter: 'blur(2px)'}}
      centered
      show={pdfOpen}
      className='w-100 h-100 bg-transparent'
    >
      <ModalBody className='text-center bg-transparent'>
        <CircularProgress color='info' />
        <h1 className='mt-5'>{pdfText}</h1>
      </ModalBody>
    </Modal>
  )
}

export default GeneratePDF
