import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Create_Customer_Form} from '../../modules/accounts/components/settings/SettingsModel'
import {PageTitle} from '../../../_metronic/layout/core'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {viewQuote} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'

const QuoteView: React.FC = () => {
  const [data, setData] = useState<QuotesModel[]>([])

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const location: any = useLocation()
  const {currentUser} = useAuth()
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()

  useEffect(() => {
    let subscribe = true
    async function getList() {
      await viewQuote(1)
        .then((res) => {
          if (subscribe) {
            let employes = res.data.quotes as QuotesModel[]
            setData(employes)
          }
        })
        .catch(() => {})
    }
    getList()
  }, [])
  return (
    <>
      {/* <Toolbar1 btnText={"Back to Quotes"} btnAction={() => {
        navigate(`/${prefixCompany}/quotes`)
      }} /> */}

      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Quotes</h3>
            {/* dynamic content to give quote id is pending here  */}
          </div>
        </div>

        {errorBody && <ErrorAlert error_response={errorBody} />}
        {successBody && <SuccessAlert success_response={successBody} />}

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quote</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.work_order_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Customer</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.customer_name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Project Name
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.project_name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Project Location
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.project_location}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Replacing Existing Panel or New Build?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.panel_type}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  How many panels are needed?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.number_of_panels}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Project Description
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Power in Voltage
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.power}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Control Voltage
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.control}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phase</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.phase}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  How many motors will this control panel be running?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.number_of_motors}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  HP of the motors?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.hp_of_motor}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Voltage of the motors?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.voltage_of_motor}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Do you have pictures, drawings, layouts, motor nameplate or any other reference
                  info?{' '}
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        // value = {location.state.data.first_name }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Do you have your own electrician on-site that will install the panel?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.electrician}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  When are you expecting to have the completed project?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.expectations}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  What is the desired NEMA environmental rating of this Control Panel?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.nema_rating}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  What is the available short-circuit current that the Control Panel will be
                  designed for?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.short_circuit_current}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Does the control panel need to be designed to meet any arc flash Category?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.arc_category}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Where will be the Control panel be installed?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.location}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Do you have manufacturar requirements for PLCs, HMIs, VFDs, Starters?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.manu_req}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  What are the current manufacturers used for PLCs, HMIs, VFDs, Starters?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.curr_req}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Does this panel require any special components that we did not already discuss?
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.data.spe_component}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Files Uploaded are Here
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        readOnly={true}
                        value={location.state.file_uploaded}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export {QuoteView}
