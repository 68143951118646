import {useCallback, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  addVendorNotes,
  darkErrorMessage,
  darkMessage,
  deleteVendorNotesAPI,
  fetchVendorNotes,
  updateVendorNotesAPI,
} from '../../../modules/auth/core/_requests'
import {useFormik} from 'formik'
import {
  Vendor_Notes,
  Vendor_Notes_Initial as initialValues,
} from '../../../modules/accounts/components/settings/SettingsModel'
import {VendorNotesInterface} from '../../../modules/model/notes'
import {useAuth} from '../../../modules/auth'
import moment from 'moment'
import {Modal, Stack} from 'react-bootstrap'
import blank from '../../../../_metronic/layout/components/header/blank.png'
import {HAS_VENDOR_NOTES_EDIT} from '../PermissionFunction'
import {LinearProgress} from '@mui/material'
import {Editor} from '@tinymce/tinymce-react'
import {onKeyDown} from '../GlobalComponents'

export function VendorNotes() {
  const editorRef: any = useRef(null)
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [notesModal, setNotesModal] = useState(false)
  const [deleteVendorNotes, setDeleteVendorNotes] = useState({
    show: false,
    idx: null,
  })

  const [updateVendorNotes, setUpdateVendorNotes] = useState<any>({
    show: false,
    idx: null,
  })

  const HandleDeleteModal = useCallback(() => {
    setDeleteVendorNotes({
      show: false,
      idx: null,
    })
  }, [])

  const HandleUpdateModal = useCallback(() => {
    setUpdateVendorNotes({
      show: false,
      idx: null,
    })
  }, [])

  const NotesValidation = Yup.object().shape({
    message: Yup.string().required('Notes is required'),
  })

  const getList = useCallback(async (vendor_id: any) => {
    setLoading(true)
    await fetchVendorNotes(vendor_id)
      .then((res) => {
        setLoading(false)
        setData(res?.data?.result || [])
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          setData([])
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getList(id)
  }, [getList, id])

  const formik = useFormik<Vendor_Notes>({
    initialValues,
    validationSchema: NotesValidation,
    onSubmit: (values) => {
      setLoading(true)
      let note_list: VendorNotesInterface = {
        vendor_id: id,
        message: values.message,
      }
      addVendorNotes(note_list)
        .then(({data}) => {
          getList(id)
          formik.resetForm()
          darkMessage('Notes Added Successfully')
          setNotesModal(false)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })

  const updateFormik = useFormik<Vendor_Notes>({
    initialValues,
    validationSchema: NotesValidation,
    onSubmit: (values) => {
      setLoading(true)
      let note_list: VendorNotesInterface = {
        vendor_id: id,
        message: values.message,
        id: values.id,
      }
      updateVendorNotesAPI(note_list)
        .then(({data}) => {
          getList(id)
          updateFormik.resetForm()
          darkMessage('Notes Updated Successfully')
          HandleUpdateModal()
          setLoading(false)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })

  const handleDeleteVendorNotes = (e: any) => {
    setLoading(true)
    const vendorId = e?.id
    deleteVendorNotesAPI(id, vendorId)
      .then((res) => {
        darkMessage('Notes Deleted Successfully')
        getList(id)
        HandleDeleteModal()
      })
      .catch((e) => {
        if (e.response.status === 409) {
          darkErrorMessage(e.response.data)
        }
        setLoading(false)
      })
  }

  return (
    <>
      <Stack className='card my-5'>
        <div className='m-5 text-right'>
          <button
            className='btn btn-secondary'
            onClick={() => {
              setNotesModal(true)
            }}
          >
            Add New Notes
          </button>
        </div>
        {loading && <LinearProgress />}
        <Stack className='card-body'>
          <Stack className='overflow-scroll' style={{height: '600px'}}>
            {data?.length === 0 && <h2 className='text-center'>There are no records to Display</h2>}
            {data.map((value: any) => {
              return (
                <>
                  <div
                    className='shadow p-5 mb-5 '
                    style={{borderLeft: '4px solid black', backgroundColor: '#', fontSize: '15px'}}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='row'>
                        <div className='col-3 d-flex align-items-center'>
                          <a
                            href={`${
                              value?.profile_pic ? value?.profile_pic : '/media/avatars/blank.png'
                            }`}
                            target='blank'
                            style={{padding: '5px'}}
                          >
                            <img
                              alt='emp img'
                              src={`${
                                value?.profile_pic ? value?.profile_pic : '/media/avatars/blank.png'
                              }`}
                              onError={(evt: any) => {
                                evt.target.src = blank
                              }}
                              width='40px'
                              height={'40px'}
                              style={{borderRadius: '50%'}}
                            />
                          </a>
                        </div>
                        <div className='col-9 d-grid align-items-center'>
                          <strong>{value?.user?.first_name + ' ' + value?.user?.last_name}</strong>
                          <div style={{fontSize: '12px'}}>
                            {moment(value.created_at).format('llll')}
                          </div>
                        </div>
                      </div>
                      {HAS_VENDOR_NOTES_EDIT(currentUser, value?.user) && (
                        <div className='float-end'>
                          <button
                            type='button'
                            onClick={() =>
                              setUpdateVendorNotes({
                                show: true,
                                idx: value,
                              })
                            }
                            className='btn btn-secondary mx-2 '
                          >
                            Edit
                          </button>
                          <button
                            type='button'
                            onClick={() =>
                              setDeleteVendorNotes({
                                show: true,
                                idx: value,
                              })
                            }
                            className='btn btn-secondary mx-2 '
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                    <hr
                      style={{borderTop: '1px solid grey', width: '97%', margin: 'auto'}}
                      className='m-5'
                    />
                    <div dangerouslySetInnerHTML={{__html: value?.message}} />
                  </div>
                </>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setNotesModal(false)
        }}
        show={notesModal}
        onHide={() => setNotesModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='fade'
        centered
      >
        <div className='card'>
          <div className='card-body' style={{padding: '25px'}}>
            <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
              <h1 className='fw-bolder m-5'> {'Add Notes to Vendor'}</h1>
              <div className='lh-base m-5 my-lg-9'>
                <div className='col-lg-12 fv-row'>
                  <Editor
                    apiKey='qdz5vil5mb6qz1e23p3tytmo6w25luiwdwjo51vrjoo5tt88'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(stringifiedHtmlValue) => {
                      formik.setFieldValue('message', stringifiedHtmlValue)
                    }}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </div>
              </div>
              <div className='d-flex flex-row-reverse mx-2'>
                <span className='mx-2'>
                  <button type='submit' className='btn btn-primary float-right m-5'>
                    {!loading && 'Confirm'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </span>
                <span className='mx-1'>
                  <button
                    onClick={() => {
                      setNotesModal(false)
                    }}
                    type='reset'
                    className='btn btn-dark float-right m-5'
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          HandleUpdateModal()
        }}
        show={updateVendorNotes?.show}
        onHide={() => HandleUpdateModal()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='fade'
        centered
      >
        <div className='card'>
          <div className='card-body' style={{padding: '25px'}}>
            <form onSubmit={updateFormik.handleSubmit} onKeyDown={onKeyDown}>
              <h1 className='fw-bolder m-5'> {'Update Notes'}</h1>
              <div className='lh-base m-5 my-lg-9'>
                <div className='col-lg-12 fv-row'>
                  <Editor
                    initialValue={updateVendorNotes.idx?.message || ''}
                    apiKey='qdz5vil5mb6qz1e23p3tytmo6w25luiwdwjo51vrjoo5tt88'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(stringifiedHtmlValue) => {
                      updateFormik.setFieldValue('message', stringifiedHtmlValue)
                      updateFormik.setFieldValue('id', updateVendorNotes.idx?.id)
                    }}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </div>
              </div>
              <div className='d-flex flex-row-reverse mx-2'>
                <span className='mx-2'>
                  <button type='submit' className='btn btn-primary float-right m-5'>
                    {!loading && 'Confirm'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </span>
                <span className='mx-1'>
                  <button
                    onClick={() => {
                      HandleUpdateModal()
                    }}
                    type='reset'
                    className='btn btn-dark float-right m-5'
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          HandleDeleteModal()
        }}
        show={deleteVendorNotes?.show}
        onHide={() => HandleDeleteModal()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='fade'
        centered
      >
        <div className='card'>
          <div className='card-body' style={{padding: '25px'}}>
            <h1 className='fw-bolder m-5'> {'Confirmation Message'}</h1>
            <div className='lh-base m-5 my-lg-9'>
              <div className='col-lg-12 fv-row'>
                <h3>Did you really want to delete Notes ?</h3>
              </div>
            </div>
            <div className='d-flex flex-row-reverse mx-2'>
              <span className='mx-2'>
                <button
                  type='submit'
                  onClick={() => handleDeleteVendorNotes(deleteVendorNotes?.idx)}
                  className='btn btn-primary float-right m-5'
                >
                  {!loading && 'Confirm'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </span>
              <span className='mx-1'>
                <button
                  onClick={() => {
                    HandleDeleteModal()
                  }}
                  type='reset'
                  className='btn btn-dark float-right m-5'
                >
                  Discard
                </button>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
