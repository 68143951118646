import React, {useCallback, useEffect, useRef, useState} from 'react'
import jsPDF from 'jspdf'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useParams} from 'react-router-dom'
import {
  saveCompanyLogo,
  API_URL,
  companyDetails,
  currentlyClockIn,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  quoteDelete,
  quoteFetchAssignedToUrl,
  updateCustomer,
  updateDueDateQuoteUrl,
  updateQuote,
  viewQuote,
  sub_quotes_generate_pdf,
} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'
import TextField from '@mui/material/TextField'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  TimeSheetTextConstant,
  CONST_FILED_SERVICE_CREATED,
  CONST_WORK_ORDER_CREATED,
} from '../../context/TimeSheetConstant'
import ProgressBar from 'react-bootstrap/esm/ProgressBar'
import {Button, Modal, Row, ModalBody} from 'react-bootstrap'
import {ErrorType} from '../../modules/errors/ErrorAlert'
import {SuccessType} from '../../modules/errors/SuccessAlert'
import {QuoteModal} from './QuoteModal'
import {FormControl, Select} from '@mui/material'
import EmpInterface from '../../modules/emp/EmpInterface'
import {UpdateQuoteModal} from './UpdateQuoteModal'
import moment from 'moment'
import GeneratePDF from './GeneratePDF'

const SubquotesHeader: React.FC = ({
  setOnClickView,
  getDisc,
  defaultTax,
  pdfOpen,
  setPdfOpen,
  onClickView,
  customerView,
  setCustomerView,
  formData,
  setFormData,
  taxValue,
  rowData,
  viewItem,
  viewService,
  itemTotal,
  serviceTotal,
  disclaimer,
}: any) => {
  const {currentUser} = useAuth()
  const [updateDueDate, setUpdateDueDate] = useState(false)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const {type, id}: any = useParams<any>()
  const [dueDate, setDueDate] = useState('')
  const TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : type === TimeSheetTextConstant.WORKORDER
      ? TimeSheetTextConstant.WORKORDER
      : TimeSheetTextConstant.FIELDSERVICE
  const inputFile = useRef<any>()
  const [data, setData] = useState<any>([])
  const [assignedTo, setAssignedTo] = useState([])
  const [compLogo, setCompLogo] = useState()
  const [info, setInfo] = useState<any>([])
  const [value, setValue] = useState<any>(0)
  const [loading, setLoading] = useState(false)
  const [buffer, setBuffer] = useState(false)
  const [updateQuoteModal, setUpdateQuoteModal] = useState({
    show: false,
    data: '',
  })
  const [topping, setTopping] = useState<any>('CONST_WORK_ORDER_CREATED')

  const [acceptedModal, setAcceptedModal] = useState({show: false, data: null})
  const MAX_CORRECT = 100
  const [selectedClient, setSelectedClient] = useState('Editing View')
  const [selectedOnRow, setSelectedOnRow] = useState('Customer View')
  const [img, setImg] = useState<any>('/media/custom/upload.png')
  const [hour, setHour] = useState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [maxScore] = useState(MAX_CORRECT)
  const [score, setScore] = useState<any>(0)
  const [progressBarValue, setProgressBarValue] = useState(0)
  const navigate = useNavigate()
  const [popupModal, setPopupModal] = useState<any>(false)
  const [showModal, setShow] = useState(false)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [notestatus, setNoteStatus] = useState<any>('')
  const [updation, setUpdation] = useState('')
  const [showOutModal, setOutModal] = useState(false)
  const [emplist, setEmplist] = useState<EmpInterface[]>([])
  const [descriptor, setDescriptor] = useState<boolean>(false)
  const [clocker, setClocker] = useState()
  const [clockr, setClockr] = useState()
  const [tooltip, setTooltip] = React.useState(false)
  const [customerDetails, setCustomerDetails] = useState<object | any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [companyData, setCompanyData] = useState<any>([])
  const [visibleAddButton, setVisibleAddButton] = useState(
    localStorage.getItem('visibleAddButton') === 'true'
  )

  useEffect(() => {
    setFormData({description: rowData?.description})
    localStorage.setItem('visibleAddButton', String(visibleAddButton))
  }, [visibleAddButton, customerView])

  const onCloseUpdateQuoteModal = useCallback(() => {
    setUpdateQuoteModal({
      show: false,
      data: '',
    })
    callbackFnc()
  }, [])

  const propTypes = {
    percent: PropTypes.number.isRequired,
  }

  const PercentBar = ({percent, ...props}: any) => (
    <ProgressBar {...props} now={percent} min={0} max={100} />
  )

  PercentBar.propTypes = propTypes
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getList = useCallback(async () => {
    await viewQuote(id)
      .then((res) => {
        let fields = res.data.customer_details as QuotesModel
        let notes_data = res.data.notes as QuotesModel[]
        let profile_pic = res?.data?.customer_details?.profile_pic
        if (profile_pic != null) {
          setImg(profile_pic)
        }

        setCustomerDetails(res.data.customer_details.customer)
        const sum = res?.data?.total_hours?.sum
        if (sum != null) {
          setHour(sum)
        }
        setData(fields)
        setInfo(notes_data)
        setValue(res.data.user_status?.status)
        res?.data?.clocked_in?.data ? setValue(1) : setValue(0)
      })
      .catch(() => {})
  }, [])


  useEffect(() => {
    currentlyClockIn().then((va) => {
      const id = va?.data?.clocked_in?.quote?.id
      const isd = va?.data?.clocked_in?.final_title
      const isdr = va?.data?.clocked_in_timesheet?.final_title
      setClocker(isd)
      setClockr(isdr)
      id ? setDescriptor(true) : setDescriptor(false)
      let x: any = data?.id ? data.id : id
      if (data?.id === x) {
        id ? setValue(1) : setValue(0)
      }
    })
  }, [value])

  useEffect(() => {
    setScore((prevScore: any) => Math.max(0, Math.min(prevScore, maxScore)))
  }, [maxScore])

  useEffect(() => {
    setInterval(function () {
      let val = progressBarValue
      val += Math.floor(Math.random() * 10) + 5

      if (val >= 100) {
        val = 100
      }

      setProgressBarValue(val)
    }, 1000)
  }, [])

  const handleActive = useCallback((event: any) => {
    setSelectedClient(event.target.value)
  }, [])
  const handleOnRow = useCallback((event: any) => {
    setSelectedOnRow(event.target.value)
  }, [])
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const fetchAssignedTo = useCallback(() => {
    setLoading(true)
    quoteFetchAssignedToUrl(id)
      .then((res) => {
        setLoading(false)
        setAssignedTo(res?.data?.result[0]?.assigned_to)
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }, [])

  const callbackFnc = useCallback(() => {
    getList()
    fetchAssignedTo()
  }, [getList, fetchAssignedTo])

  useEffect(() => {
    callbackFnc()
  }, [callbackFnc])

  const getData = useCallback(
    async (e: any) => {
      await updateQuote(id, e, 'status', notestatus)
        .then((result) => {
          let fields: any = result.data.customer_details as QuotesModel[]
          setAcceptedModal({
            show: false,
            data: null,
          })
          setData(fields)
          setNoteStatus('')
          setOpenModal(false)
          getList()
          navigate(`/${prefixCompany}/${TIMESHEET_TEXT}`)
        })
        .catch((e) => {
          console.error(e)
        })
    },
    [TIMESHEET_TEXT, getList, id, navigate, notestatus, prefixCompany]
  )

  const onModalClose = useCallback(() => {
    setShow(false)
    setModalShow(false)
    setOutModal(false)
  }, [])
  const navigateDetails = () => {
    navigate(`/${prefixCompany}/customers/view/${customerDetails.id}`)
  }

  const companyList = () => {
    companyDetails()
      .then((res) => {
        setCompanyData(res?.data?.company_data)
        setCompLogo(res?.data?.company_data?.company_logo)
      })
      .catch((e) => {
        console.error(e)
      })
  }
  useEffect(() => {
    companyList()
  }, [])

  const onOptionChange = (e: {target: {value: any}}) => {
    setTopping(e.target.value)
  }

  async function updateCustomerPic(customer_info: any, customer_id: any) {
    await updateCustomer(customer_info, customer_id)
      .then(() => {
        setBuffer(true)
        setTimeout(() => {
          setBuffer(false)
        }, 0)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  function calculateTotalCost(viewService: any[], viewItem: any[]) {
    const serviceTotal = viewService.reduce(
      (acc: number, service: {quantity: number; service_cost: number}) => {
        return acc + service.quantity * service.service_cost
      },
      0
    )

    const itemTotal = viewItem.reduce(
      (acc: number, item: {quantity: number; item_cost: number}) => {
        return acc + item.quantity * item.item_cost
      },
      0
    )

    return (serviceTotal + itemTotal).toFixed(2)
  }

  const pdfGenerate = useCallback(
    (c_image: any) => {
      const pdf = new jsPDF('portrait', 'pt', 'a4')

      const header = `<header style="width: 595px; margin-left: 15px; font-size: 10px; margin-right: 15px">
      <table width="100%">
          <td
              style="
              font-size: 9px;
              vertical-align: top;
              color: #000;
              line-height: 11px;
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              "
              >
              <p>
                  <b
                      ><span
                      style="
                      color: #a4a4a4;
                      font-family: 'Roboto', sans-serif;
                      font-weight: 700;
                      font-size: 10px;
                      "
                      ><span style="color: black">QUOTE:</span>
                  ${rowData?.label}</span
                      ></b
                      ><br />
                  <span style="margin-top: 50px">${rowData?.description}</span>
              </p>
          </td>
          <td
              style="
              font-size: 9px;
              vertical-align: top;
              color: #000;
              line-height: 11px;
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              "
              >
              <p>
                  <b
                      ><span
                      style="
                      color: #a4a4a4;
                      font-family: 'Roboto', sans-serif;
                      font-weight: 700;
                      font-size: 8px;
                      "
                      >CUSTOMER</span
                      ></b
                      ><br />
                  ${data?.customer?.company}
              </p>
          </td>
          <td
              style="
              font-size: 9px;
              vertical-align: top;
              color: #000;
              line-height: 11px;
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              "
              >
              <p>
                  <b
                      ><span
                      style="
                      color: #a4a4a4;
                      font-family: 'Roboto', sans-serif;
                      font-weight: 700;
                      font-size: 8px;
                      "
                      >PROJECT NAME</span
                      ></b
                      ><br />
                  ${data?.fields?.project_field}
              </p>
          </td>
          <tr>
              <td style="vertical-align: top">
                  <div style="width: 50px; height: 50px"></div>
              </td>
              <td
                  style="
                  font-size: 9px;
                  vertical-align: top;
                  color: #000;
                  font-family: 'Roboto', sans-serif;
                  font-weight: 500;
                  "
                  >
                  <p
                      style="
                      font-size: 9px;
                      line-height: 11px;
                      font-family: 'Roboto', sans-serif;
                      font-weight: 500;
                      "
                      >
                      <b>${companyData?.company_name}</b><br />
                      ${companyData?.company_email}<br />
                      ${
                        companyData?.company_phone_number &&
                        companyData?.company_phone_number + '<br />'
                      }
                      ${
                        companyData?.company_address_line1 &&
                        companyData?.company_address_line1 + '<br />'
                      }
                      ${
                        companyData?.company_address_line2 &&
                        companyData?.company_address_line2 + '<br />'
                      }
                      ${companyData?.company_city && companyData?.company_city + ','} ${
        companyData?.company_state && companyData?.company_state + '<br />'
      }
                      ${companyData?.company_zip_file && companyData?.company_zip_file + '<br />'}
                  </p>
              </td>
          </tr>
      </table>
  </header>
  `
      const body = `
      <div  class="body-content ">
    <div style="width:595px; margin-left: 15px; font-size: 10px; margin-right: 15px;margin-top: 40px;">
        <table width="100%">
            ${
              viewItem.length > 0
                ? `
            <thead>
                <tr style="border-bottom:1px solid #5b5b5b; margin-bottom:20px;height:7px;">
                    <span>ITEMS</span>
                </tr>
                <tr style="border-bottom:1px solid #DCDCDC; margin-bottom:20px; height:27px;">
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">ITEMS</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;margin-left:10px;">MANUFACTURER</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">MFG.PART#</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">QUANTITY</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">PRICE</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">TOTAL</th>
                </tr>
            </thead>
            `
                : ''
            }
            <tbody style="margin-top:20px; border-bottom:1px solid #DCDCDC; margin-bottom:20px;">
                ${
                  viewItem.length > 0
                    ? viewItem.map(
                        (item: {
                          item_name: any
                          item_mfg_part: any
                          item_mfg: {name: any}
                          quantity: any
                          item_cost: any
                          markup: any
                          priceUnit: any
                          finalCost: any
                        }) => {
                          const finalCosts =
                            item.priceUnit === '%'
                              ? item.finalCost + item.finalCost * (item.markup / 100)
                              : item.finalCost + item.markup
                          const Totals = item.quantity * finalCosts
                          return `
                <tr style="font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;height:23px;border-bottom:1px solid #DCDCDC; margin-bottom:20px;">
                    <td style="white-space: normal;max-width:45px;">${item.item_name}</td>
                    <td style="margin-left:40px;">${item.item_mfg.name}</td>
                    <td style="white-space: normal;max-width:45px;">${item.item_mfg_part}</td>
                    <td style="white-space: normal;max-width:45px;">${item.quantity}</td>
                    <td style="white-space: normal;max-width:45px;">$${
                      finalCosts
                        ? finalCosts.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    </td>
                    <td style="white-space: normal;max-width:45px;">$${
                      Totals
                        ? Totals.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    </td>
                </tr>
                `
                        }
                      )
                    : ''
                }
            </tbody>
        </table>
    </div>
    <div style="width:595px;margin-top:30px; margin-left: 15px; font-size: 10px; margin-right: 15px;">
        <table width="100%">
            <thead>
                ${
                  viewService.length > 0
                    ? `
                <tr style="border-bottom:1px solid #5b5b5b; margin-bottom:20px;height:7px;">
                    <span>SERVICES</span>
                </tr>
                `
                    : ''
                }
                ${
                  viewService.length > 0
                    ? `
                <tr style="border-bottom:1px solid #DCDCDC; margin-bottom:20px;height:27px;">
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">SERVICES</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">QUANTITY</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">PRICE</th>
                    <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">TOTAL</th>
                </tr>
                `
                    : ''
                }
            </thead>
            <tbody style="margin-top:20px; border-bottom:1px solid #DCDCDC; margin-bottom:20px;">
                ${
                  viewService.length > 0
                    ? viewService.map(
                        (service: {
                          service_name: any
                          service_description: any
                          quantity: any
                          service_cost: any
                        }) => `
                <tr style="font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;height:23px;border-bottom:1px solid #DCDCDC; margin-bottom:20px;">
                    <td style="max-width:90px;">${
                      service.service_name
                    }<br/><span style="color:#a4a4a4;">${service.service_description || ''}<span>
                    </td>
                    <td style="margin-left:40px;">${service.quantity} </td>
                    <td>$${service.service_cost}</td>
                    <td>$${service.quantity * service.service_cost}</td>
                </tr>
                `
                      )
                    : ''
                }
                ${
                  viewService.length > 0
                    ? ''
                    : ` 
                <tr style="border-bottom:1px solid #DCDCDC; margin-bottom:20px;">
                </tr>
                `
                }
            </tbody>
        </table>
    </div>
</div>
  `
      const footer = `
      <footer class="footer">
      <div style="width:595px; margin-top:10px;position:fixed; margin-left: 450px; font-size: 10px; margin-right: 15px;">
          <table style="page-break-inside: avoid;">
              <tfoot>
                  <tr>
                      <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;"></th>
                      <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;"></th>
                      <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;"></th>
                      <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;"></th>
                  </tr>
              </tfoot>
              <tbody style="margin-top:4px; margin-bottom:2px; page-break-inside: avoid;">
                  <tr style="font-weight:500; margin-top:4px; margin-bottom:2px; font-family: 'Roboto', sans-serif; font-size:8px;height:23px">
                      <td style="margin-left:200px;font-weight:bold;">ITEM TOTAL: <span style="font-weight:normal;">$${
                        itemTotal
                          ? itemTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })
                          : 0
                      }
                      </td>
                      </span></td>
                  </tr>
                  <tr style="font-weight:500; margin-top:4px; margin-bottom:2px; font-family: 'Roboto', sans-serif; font-size:8px;height:23px">
                      <td style="margin-left:200px;font-weight:bold;">SERVICE TOTAL: <span style="font-weight:normal;">$${serviceTotal}</span></td>
                  </tr>
                  <tr style="font-weight:500; margin-top:4px; margin-bottom:2px; font-family: 'Roboto', sans-serif; font-size:8px;height:23px">
                      <td style="margin-left:200px;font-weight:bold;">SUB TOTAL: <span style="font-weight:normal;">$${calculateTotalCost(
                        viewService,
                        viewItem
                      )}</span>
                      </td>
                  </tr>
                  <tr style="font-weight:500; margin-top:4px; margin-bottom:2px; font-family: 'Roboto', sans-serif; font-size:8px;height:23px">
                      <td style="margin-left:200px;font-weight:bold;">${
                        rowData?.tax ? rowData?.tax : defaultTax
                      } % TAX <span style="font-weight:normal;">$${
        taxValue
          ? taxValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : 0
      }</span>
                      </td>
                  </tr>
                  <tr style="font-weight:500; margin-top:4px; margin-bottom:2px;font-family: 'Roboto', sans-serif; font-size:8px;height:23px ; page-break-before: avoid;">
                      <td style="margin-left:200px;font-weight:bold; page-break-inside: avoid;">
                          GRAND TOTAL:
                          <span style="font-weight:normal;">
                          ${(
                            parseFloat(calculateTotalCost(viewService, viewItem)) +
                            parseFloat(taxValue)
                          ).toLocaleString(undefined, {maximumFractionDigits: 2})}
                          </span>
                      </td>
                  </tr>
                  </tfoot>
          </table>
      </div>
      <div style="width:595px;margin-top:140px;margin-left:15px;font-size:10px;margin-right:15px;position:fixed; margin-bottom:5px">
          <table style="page-break-inside: avoid;" >
              <tfoot style="margin-top:4px; margin-bottom:2px; page-break-before: avoid;">
                  <tr>
                      <th>
                          <h5>Disclaimer</h5>
                      </th>
                  </tr>
                  <td style="color: rgb(132,132,132); font-weight: normal;">${getDisc}</td>
                  </tr>
              </tfoot>
          </table>
      </div>
    </footer>    
  `
      const html = `
<!doctype html>
<html lang="en">
<head>
<meta charset="UTF-8">
<style>
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  th, td {
    border: 1px solid #DCDCDC;
    padding: 5px;
    font-size: 8px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  th {
    color: #a4a4a4;
  }
  .no-page-break {
    page-break-inside: avoid;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    color: #a4a4a4;
  }
  .body-content {
    margin-bottom: 60px; /* Adjust this value to leave space for the footer */
  }
</style>
</head>
<body>
      ${header}
      ${body}
      ${footer}
</body>
</html>
`

      var img_1 = new Image()

      img_1.onload = function () {
        var x = 20 // x-coordinate
        var y = 80 // y-coordinate
        var width = 60 // image width
        var height = 60 // image height
        let autoPaging = 'text'

        pdf.addImage(img_1, 'PNG', x, y, width, height, autoPaging) //base64 image, format, x-coordinate, y-coordinate, width, height
      }

      img_1.crossOrigin = ''
      img_1.src = API_URL + c_image
      pdf.html(html).then(() => {
        pdf.save(`SubQuote ${rowData?.label}.pdf`)
        setPdfOpen(false)
      })
    },
    [
      rowData?.label,
      rowData?.description,
      rowData?.tax,
      data?.customer?.company,
      data?.fields?.project_field,
      companyData?.company_name,
      companyData?.company_email,
      companyData?.company_phone_number,
      companyData?.company_address_line1,
      companyData?.company_address_line2,
      companyData?.company_city,
      companyData?.company_state,
      companyData?.company_zip_file,
      viewItem,
      viewService,
      itemTotal,
      serviceTotal,
      defaultTax,
      taxValue,
      getDisc,
      setPdfOpen,
    ]
  )
  const handleBackendPdf = useCallback(async () => {
    setPdfOpen(true)

    await sub_quotes_generate_pdf(id, rowData?.id)
      .then((res: any) => {
        const fileUrl = API_URL + res?.data?.file_path
        if (fileUrl) {
          window.open(fileUrl, '_blank')
        }
      })
      .catch((e: any) => {
        darkErrorMessage('ERROR! PDF does not generated')
      })
    setPdfOpen(false)
  }, [id, rowData, setPdfOpen])

  const handlePdfGenerate = useCallback(async () => {
    setPdfOpen(true)
    await saveCompanyLogo()
      .then((res) => {
        const company_logo = res?.data?.company_file
        pdfGenerate(company_logo)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
        setPdfOpen(false)
      })
  }, [pdfGenerate])
  const deleteQuote = (id: any) => {
    quoteDelete(id)
      .then((res) => {
        let result = res?.data?.error
        setSuccessBody(result as any)
        darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} deleted Successfully`)
        navigate(`/${prefixCompany}/${type}`)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
        }
      })
  }
  const handleDueDate = useCallback(() => {
    updateDueDateQuoteUrl(id, dueDate)
      .then((res) => {
        const message = res?.data?.message
        darkMessage(message)
        setUpdateDueDate(false)
        callbackFnc()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [callbackFnc, dueDate, id])

  const handleModal = useCallback(
    (index: any) => {
      updateQuote(id, index, 'status', null)
        .then(handleClose)
        .then(() => {
          navigate(`/${prefixCompany}/${type}`)
          darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} updated Successfully.`)
        })
      setPopupModal(false)
    },
    [id, navigate, prefixCompany, type]
  )

  const handleToppings = useCallback(() => {
    const index = topping
    topping === 'None'
      ? getData('Accepted')
      : updateQuote(id, index, 'status', null)
          .then(handleClose)
          .then(() => {
            navigate(`/${prefixCompany}/${type}`)
            darkMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} updated Successfully.`)
          })
    setAcceptedModal({
      show: false,
      data: null,
    })
  }, [id, navigate, prefixCompany, topping, type])

  const [modal, setModalShow] = React.useState<any>({
    show: false,
    id: null,
    check: false,
  })

  const handleClosed = () => {
    setTooltip(false)
  }

  const handleOpened = () => {
    setTooltip(true)
  }

  return (
    <div className='card mb-5 mb-xl-10 p-5 pt-0 pb-0'>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <QuoteModal
          onModalClose={onModalClose}
          callbackFnc={callbackFnc}
          show={modal?.show}
          id={modal?.id}
          checked={modal?.check}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setOpenModal(false)
        }}
        label={'somelable'}
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      ></Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={updateDueDate}
        onHide={() => {
          setUpdateDueDate(false)
        }}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Update Due Date</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Please Enter Due Date
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  value={dueDate}
                  className='form-control form-control-lg form-control-solid'
                  onChange={(e) => setDueDate(e?.target?.value)}
                />
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-end' style={{paddingRight: '10px'}}>
            <button onClick={() => setUpdateDueDate(false)} className='mx-5 btn btn-dark'>
              Discard
            </button>
            <button
              type='button'
              onClick={() => handleDueDate()}
              className=' mx-5 text-center btn btn-primary'
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={openDeleteModal}
        onHide={() => setDeleteModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to delete #{data?.id} and all files attached?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={'btn-dark'}
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={'btn-primary'}
            onClick={() => {
              deleteQuote(data?.id)
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={acceptedModal?.show}
        onHide={() =>
          setAcceptedModal({
            ...acceptedModal,
            show: false,
          })
        }
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>Do you want to create a Project or Field Service?</h3>
            <div className='d-flex justify-content-around mt-lg-9'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='flexRadioDefault'
                  value={CONST_WORK_ORDER_CREATED}
                  id='project'
                  onChange={onOptionChange}
                  checked={topping === CONST_WORK_ORDER_CREATED}
                />
                <label className='form-check-label' htmlFor='project'>
                  Project
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  value={CONST_FILED_SERVICE_CREATED}
                  name='flexRadioDefault'
                  id='field_service'
                  onChange={onOptionChange}
                  checked={topping === CONST_FILED_SERVICE_CREATED}
                />
                <label className='form-check-label' htmlFor='field_service'>
                  Field Service
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  value={'None'}
                  name='flexRadioDefault'
                  id='None'
                  onChange={onOptionChange}
                  checked={topping === 'None'}
                />
                <label className='form-check-label' htmlFor='None'>
                  Mark Accepted Only
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setAcceptedModal({
                ...acceptedModal,
                show: false,
              })
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleToppings()
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <GeneratePDF pdfOpen={pdfOpen} pdfText='Generating PDF......' />
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={popupModal?.show}
        onHide={() => setPopupModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={'fade'}
      >
        <Modal.Header>
          <h1>Confirmation Message</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>{popupModal?.body}</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={() => {
              setPopupModal(false)
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              handleModal(popupModal?.params, popupModal?.addData)
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={updateQuoteModal?.show}
        onHide={() => {
          onCloseUpdateQuoteModal()
        }}
        size='lg'
        centered
      >
        <Modal.Header>
          <h1>Update {TIMESHEET_TEXT}</h1>
        </Modal.Header>
        <UpdateQuoteModal onCloseUpdateQuoteModal={onCloseUpdateQuoteModal} />
      </Modal>

      {/* <div className='d-flex overflow-auto h-55px' style={{justifyContent: 'space-between'}}>
<ul className=' text-left nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap '>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/overview` && 'active')
}
to={`/${prefixCompany}/${type}/${id}/overview`}
>
Overview
</Link>
</li>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/files` && 'active')
}
to={`/${prefixCompany}/${type}/${id}/files`}
>
Files
</Link>
</li>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/activity` && 'active')
}
to={`/${prefixCompany}/${type}/${id}/activity`}
>
Activity
</Link>
</li>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/timesheet` && 'active')
}
to={`/${prefixCompany}/${type}/${id}/timesheet`}
>
Hours used
</Link>
</li>

{HAS_CONTRA_THREE_TAB_PERMISSION(type) && (
<>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/purchase-order` &&
'active')
}
to={`/${prefixCompany}/${type}/${id}/purchase-order`}
>
Purchase Orders
</Link>
</li>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(false)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/items-assigned` &&
'active')
}
to={`/${prefixCompany}/${type}/${id}/items-assigned`}
>
Items Assigned
</Link>
</li>
</>
)}
{HAS_THREE_TAB_PERMISSION(type) && (
<>
<li className='nav-item'>
<Link
onClick={() => setVisibleAddButton(true)}
className={
`nav-link text-active-primary me-6 ` +
(location.pathname === `/${prefixCompany}/${type}/${id}/bom` && 'active')
}
to={`/${prefixCompany}/${type}/${id}/bom`}
>
Quotes
</Link>
</li>
</>
)}
</ul>
</div> */}
      <div className='row d-flex align-items-center my-lg-5'>
        {/* <div className='col-md-1 d-flex justify-content-center pb-2 p-0'>
<div className=''>
<div className='symbol symbol-100px symbol-fixed position-relative'>
<input
type='file'
ref={inputFile}
style={{display: 'none'}}
onChange={changeImg}
accept='image/*'
/>

{loading ? (
<div
style={{
width: '100px',
height: '100px',
margin: 'auto',
cursor: 'pointer',
objectFit: 'contain',
}}
>
<LinearProgress />
</div>
) : (
<img
src={img}
alt='Customer Logo'
onClick={onButtonClick}
onError={() => {
setImg(blank)
}}
style={{
maxWidth: '100px',
width: '100%',
height: '100px',
margin: 'auto',
cursor: 'pointer',
objectFit: 'contain',
}}
/>
)}
</div>
</div>
</div> */}
        <div className='col-md-4'>
          <div>
            <span
              className=' col-form-label fw-bold text-left p-0 cursor-pointer'
              style={{fontSize: '16px'}}
            >
              {type === TimeSheetTextConstant?.QUOTE ? (
                'QUOTE'
              ) : type === TimeSheetTextConstant?.WORKORDER ? (
                'PROJECT'
              ) : type === TimeSheetTextConstant?.FIELDSERVICE ? (
                'FIELD SERVICE'
              ) : (
                <></>
              )}
              :
              <span style={{marginLeft: '7px', color: '#a1a5b7'}}>
                {/* {TIMESHEET_TEXT === TimeSheetTextConstant.QUOTE
? TimeSheetInitialConstant.QUOTE
: TIMESHEET_TEXT === TimeSheetTextConstant.WORKORDER
? TimeSheetInitialConstant.WORKORDER
: TIMESHEET_TEXT === TimeSheetTextConstant.FIELDSERVICE
? TimeSheetInitialConstant.FIELDSERVICE
: TIMESHEET_TEXT} */}
                {/* {data?.label ?? data?.id} */}
                {rowData?.label}
              </span>
            </span>
          </div>
        </div>

        <div className='col-md-3'>
          <div className=' col-form-label fs-6 text-left customer' p-3 style={{color: '#a1a5b7'}}>
            <span>Customer</span>
            <div className=' col-form-label fw-bold fs-6 text-left p-0 cursor-pointer'>
              <Tooltip
                open={tooltip}
                onClose={handleClosed}
                onOpen={handleOpened}
                title={
                  <>
                    <span style={{fontSize: '15px'}}>
                      Business Name :{customerDetails?.company}
                      <br />
                      Customer Name :
                      {customerDetails?.first_name + ' ' + customerDetails?.last_name || 'N/A'}
                      <br />
                      Email: {customerDetails?.email || 'N/A'}
                      <br />
                      Phone Number : {customerDetails?.phone || 'N/A'}
                      <br />
                      Address :{' '}
                      {customerDetails?.address +
                        ', ' +
                        customerDetails?.city +
                        ', ' +
                        customerDetails?.state +
                        ', ' +
                        customerDetails?.zip_code || 'N/A'}
                    </span>
                  </>
                }
              >
                <span style={{fontSize: '16px'}} onClick={navigateDetails}>
                  {data?.customer?.company}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className=' col-form-label fs-6 text-left' p-3 style={{color: '#a1a5b7'}}>
            Project Name{' '}
            {customerView === false && (
              <i
                className='bi bi-pencil-square mx-2 cursor-pointer'
                style={{color: 'black'}}
                onClick={() =>
                  setUpdateQuoteModal({
                    show: true,
                    data: data,
                  })
                }
              ></i>
            )}
            <div className=' col-form-label fw-bold fs-6 text-left p-0'>
              <p className='m-0' style={{fontSize: '16px'}}>
                {data?.fields?.project_field}
              </p>
            </div>
          </div>
        </div>
        <div className='col-md-1'>
          {' '}
          <button
            className='btn-white btn'
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
          >
            <b style={{letterSpacing: '3px', color: 'black'}}>...</b>
          </button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <>
              <MenuItem
                onClick={() => {
                  handleBackendPdf()
                  handleClose()
                }}
              >
                Generate PDF
              </MenuItem>
            </>
          </Menu>
          {/* {customerView===false && ( */}
          <FormControl style={{width: '210%'}} className='customer-filter h-35 c-width100'>
            {onClickView === false && (
              <select
                style={{
                  padding: '0px',
                  border: 'none',
                  textAlign: 'center',
                  width: '160px',
                  height: '20px',
                  backgroundColor:
                    selectedClient === 'Editing View' ? 'rgb(236,223,119)' : 'rgb(140,187,241)',
                  backgroundImage:
                    selectedClient === 'Editing View'
                      ? 'url(/media/icons/duotune/eye/editIcon.svg)'
                      : 'url(/media/icons/duotune/eye/eyeIcon.svg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'left center',
                  backgroundSize: '15px',
                }}
                id='demo-simple-select'
                className='customer-filter h-35 c-width100 f'
                value={selectedClient}
                onChange={handleActive}
                onClick={() => {
                  if (selectedClient === 'Customer View') {
                    setCustomerView(true)
                  } else if (selectedClient === 'Editing View') {
                    setCustomerView(false)
                  }
                }}
              >
                <option
                  value='Editing View'
                  style={{height: '25px', backgroundColor: 'rgb(236,223,119)'}}
                  selected={!onClickView}
                >
                  <i
                    className='bi bi-pencil-square mx-1 cursor-pointer'
                    style={{color: 'black'}}
                    aria-hidden='true'
                  ></i>
                  Editing View
                </option>
                <option
                  value='Customer View'
                  style={{
                    height: '25px',
                    backgroundColor: 'rgb(140,187,241)',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  selected={onClickView}
                >
                  {/* <i
                    className='bi bi-eye mx-4 cursor-pointer'
                    style={{color: 'black'}}
                    aria-hidden='true'
                  ></i> */}
                  Customer View
                </option>
              </select>
            )}
            {
              onClickView === true && (
                <select
                  style={{
                    padding: '4px',
                    border: 'none',
                    textAlign: 'center',
                    width: '160px',
                    backgroundColor:
                      selectedOnRow === 'Editing View' ? 'rgb(236,223,119)' : 'rgb(140,187,241)',
                    backgroundImage:
                      selectedOnRow === 'Editing View'
                        ? 'url(/media/icons/duotune/eye/editIcon.svg)'
                        : 'url(/media/icons/duotune/eye/eyeIcon.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left center',
                    backgroundSize: '15px',
                  }}
                  id='demo-simple-select'
                  className='customer-filter h-35 c-width100'
                  value={selectedOnRow}
                  onChange={handleOnRow}
                  onClick={() => {
                    if (selectedOnRow === 'Customer View') {
                      setCustomerView(true)
                    } else if (selectedOnRow === 'Editing View') {
                      setCustomerView(false)
                    }
                  }}
                >
                  <span>
                    <img
                      src='/media/icons/duotune/eye/show.svg'
                      alt='Hide Icon'
                      style={{width: '20px', height: '20px'}}
                    />
                  </span>
                  <option
                    value='Editing View'
                    style={{height: '25px', backgroundColor: 'rgb(236,223,119)'}}
                  >
                    <span>
                      <i className='bi bi-pencil-square mx-1' style={{color: 'black'}}></i>
                    </span>
                    Editing View
                  </option>
                  <option
                    value='Customer View'
                    style={{
                      height: '25px',
                      backgroundColor: 'rgb(140,187,241)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <i className='bi bi-eye mx-1 cursor-pointer' style={{color: 'black'}}></i>
                    Customer View
                  </option>
                </select>
              )
            }
          </FormControl>
        </div>

        <div className='col-md-1'>
          {TIMESHEET_TEXT === TimeSheetTextConstant?.WORKORDER && (
            <div className=' col-form-label fs-6 text-left' p-3 style={{color: '#a1a5b7'}}>
              Due Date
              <div className=' col-form-label fw-bold fs-6 text-left p-0'>
                {data?.due_date ? moment(data?.due_date).format('ll') : 'N/A'}
                <i
                  className='fa-solid fa-angle-down mx-2 cursor-pointer'
                  style={{color: 'black'}}
                  onClick={() => setUpdateDueDate(true)}
                ></i>
              </div>
            </div>
          )}
        </div>
        {customerView === true && (
          <div className='col-md-4' style={{marginTop: '-15px'}}>
            {formData?.description}
          </div>
        )}
        {customerView === false && (
          <div style={{marginTop: '-15px'}} className='col-md-4'>
            <TextField
              label='Quote Description'
              style={{backgroundColor: 'rgb(245,248,250)'}}
              value={formData?.description}
              onChange={(e) => setFormData({description: e.target.value})}
              inputProps={{
                style: {
                  height: '0.05px',
                },
              }}
            />
          </div>
        )}
         </div>
    </div>
  )
}

export {SubquotesHeader}
