// import React, {useState, useCallback, useEffect, useContext} from 'react'
import {MenuItem, Select} from '@mui/material'
const SubQuoteTotal = ({
  discountValue,
  handleDiscountChange,
  handleDisUnitChange,
  discount,
  disUnit,
  customerView,
  tax,
  taxValue,
  handletaxChange,
  grandTotal,
  subTotal,
  serviceTotal,
  itemTotal,
  defaultTax,
}: any) => {
  console.log('defaultTax', defaultTax)
  const taxval = tax === 0 ? (defaultTax ? defaultTax : 0) : tax
  console.log(taxval,'taxval')
  return (
    <div style={{justifyContent: 'right'}}>
      <div className='row' style={{justifyContent: 'right', marginLeft: '70%'}}>
        <div className='col'>
          {' '}
          <h6> Item Total:</h6>
        </div>
        <div className='col'>
          <p>
            $
            {itemTotal
              ? itemTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </p>
        </div>
        <div className='w-10'></div>
        <div className='col'>
          {' '}
          <h6> Service Total:</h6>
        </div>
        <div className='col'>
          <p>
            ${' '}
            {serviceTotal
              ? serviceTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </p>
        </div>
        <div className='w-10'></div>
        <div className='col'>
          {' '}
          {customerView === false ? (
            <h6>
              {' '}
              Discount:{' '}
              <input
                type='number'
                style={{
                  marginLeft: '4px',
                  height: '32px',
                  width: '25%',
                  backgroundColor: 'rgb(245,248,250)',
                }}
                value={discount}
                onChange={(e) => {
                  handleDiscountChange(e)
                }}
              />
              <Select
                className='priceUnit-p-btn'
                labelId='demo-simple-select-label'
                id='demo-simple-select-label'
                style={{height: '32px', width: '36px', borderRadius: '0px'}}
                value={disUnit ? disUnit : '$'}
                onChange={(e) => {
                  handleDisUnitChange(e)
                }}
              >
                <MenuItem
                  value='$'
                  style={{
                    height: '25px',
                    width: '50px',
                    padding: '5px',
                  }}
                >
                  $
                  <span style={{marginLeft: '6px'}}>
                    {disUnit === '$' && (
                      <i
                        className='bi-check2'
                        style={{color: 'rgb(25,118,210)', marginLeft: '5px'}}
                      ></i>
                    )}
                  </span>
                </MenuItem>
                <MenuItem
                  value='%'
                  style={{
                    height: '25px',
                    width: '50px',
                    padding: '1px',
                  }}
                >
                  %
                  <span style={{marginLeft: '6px'}}>
                    {disUnit === '%' && (
                      <i className='bi-check2' style={{color: 'rgb(25,118,210)'}}></i>
                    )}
                  </span>
                </MenuItem>
              </Select>
            </h6>
          ) : (
            <h6>
              {' '}
              Discount: {discount} {disUnit ? disUnit : '$'}
            </h6>
          )}
        </div>
        <div className='col'>
          <p>
            ${' '}
            {discountValue
              ? discountValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </p>
        </div>
        <div className='w-10'></div>
        <div className='col'>
          {' '}
          <h6> Subtotal:</h6>
        </div>
        <div className='col'>
          <p>
            ${' '}
            {subTotal
              ? subTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </p>
        </div>
        <div className='w-10'></div>
        <div className='col'>
          <h6>
            {customerView ? (
              <p>
                Tax : <span style={{marginLeft: '4px'}}>{taxval}%</span>
              </p>
            ) : (
              <p>
                Tax:{' '}
                <input
                  type='number'
                  style={{
                    marginLeft: '4px',
                    width: '25%',
                    backgroundColor: 'rgb(245,248,250)',
                  }}
                  value={taxval}
                  onChange={handletaxChange}
                />{' '}
                %
              </p>
            )}
          </h6>
        </div>
        <div
          className='col'
          style={{
            marginLeft: '4px',
          }}
        >
          $
          {taxValue
            ? taxValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}
        </div>
        <div className='w-10'></div>
        <div className='col'>
          <h6>Grand Total</h6>
        </div>
        <div className='col'>
          <p>
            {' '}
            $
            {grandTotal
              ? grandTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </p>
        </div>
      </div>
    </div>
  )
}
export default SubQuoteTotal
