/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {useCallback, useContext, useEffect, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useParams, useSearchParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {QuotesModel} from '../../modules/model/quotes'
import {
  currnetCompanyName,
  getInventoryVendors,
  quotesList,
} from '../../modules/auth/core/_requests'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {ExportToCsv} from 'export-to-csv'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {
  HAS_QuotePageView,
  HAS_VendorCreateVendor,
  HAS_VendorViewTable,
  HAS_VendorViewVendor,
} from './PermissionFunction'
import {MaterialDataTable} from './MatrialDataTable'
import {KTSVG} from '../../../_metronic/helpers'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {VendorInterface} from '../../modules/accounts/components/settings/SettingsModel'
import {Button, Menu, MenuItem} from '@mui/material'
import {useLocalStorage} from './GlobalComponents'
import blank from './../../../_metronic/layout/components/header/blank.png'

function Vendors() {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [totalRows, seTotalRows] = useState(6)
  const [loading, setLoading] = useState<any>(true)
  const [filtersT, setfiltersT] = useState('')
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [searchParams, setSearchParams] = useSearchParams()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const headerContext = useContext<any>(HeaderContext)
  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])
  const [tableStorage, setTableStorage] = useLocalStorage('vendorColsVisibility', '')

  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: VendorInterface) => (
          <a href={row?.image || null} target='blank'>
            <img
              alt='vendor logo'
              src={`${row?.image || '/media/avatars/blank.png'}`}
              width='70px'
              onError={(evt: any) => {
                evt.target.src = blank
              }}
              height={'70px'}
              style={{objectFit: 'contain', maxWidth: '100%'}}
            />
          </a>
        ),
        header: 'Vendor Logo',
        size: 70, //decrease the width of this column
        accessorKey: 'vendor_logo',
      },
      {
        header: 'Vendor Name',
        accessorFn: (row: VendorInterface) => row.name,
        accessorKey: 'vendor_name',
      },
      {
        header: 'Address',
        accessorFn: (row: VendorInterface) =>
          row.address_line_1
            ? row.address_line_1 +
              ', ' +
              row.address_line_2 +
              ', ' +
              row.city +
              ', ' +
              row.state +
              ', ' +
              row.zip
            : ' ',
        size: 150, //increase the width of this column
        accessorKey: 'address',
      },
      {
        header: 'Website',
        accessorFn: (row: VendorInterface) => row.website || ' ',
        size: 120, //decrease the width of this column
        accessorKey: 'website',
      },
      {
        header: 'Terms',
        accessorFn: (row: VendorInterface) => row.terms || ' ',
        size: 150, //increase the width of this column
        accessorKey: 'terms',
      },
      {
        header: `PO Count`,
        accessorFn: (row: VendorInterface) => row.po_count,
        size: 100, //decrease the width of this column
        accessorKey: 'po_count',
      },
      {
        header: 'Created By',
        accessorFn: (row: VendorInterface) => row.user?.first_name + ' ' + row.user?.last_name,
        size: 100, //decrease the width of this column
        accessorKey: 'created_by',
      },
    ],
    []
  )

  const getList = useCallback(async (page: number = 1, str = '', pagePerEntries: number = 100) => {
    await getInventoryVendors(page, str, pagePerEntries)
      .then((res) => {
        let vendorList = res.data.vendors as VendorInterface[]
        setData(vendorList)
        seTotalRows(res.data.totalRows)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])
  const [data, setData] = useState<VendorInterface[]>([])

  const PaginationCallback = useCallback(
    (getPagination: any) => {
      setLoading(true)
      getList(getPagination?.pageIndex, '', getPagination?.pageSize)
    },
    [getList]
  )

  useEffect(() => {
    setLoading(true)
    getList(1)
  }, [getList])

  // useEffect(() => {
  //   const search = searchParams.get('str') as string
  //   search !== null ? getList(1, search) : getList(1)
  //   return () => {
  //     searchParams.set('str', '')
  //   }
  // }, [getList, searchParams])
  useEffect(() => {
    setLoading(true)
    const search: any = searchParams.get('str') as string
    if (search === null) {
      getList()
    } else {
      getList(1, search)
    }
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Vendors',
        subtitle: `Total Records`,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/vendors/create`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: true,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  const CSV_HEADER = useMemo(
    () => [
      'Vendor Name',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip Code',
      'Website',
      'Vendor Notes',
      'Terms',
      'PO Count',
      'Created by',
    ],
    []
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx) => {
        return [
          eachIdx.name,
          eachIdx.address_line_1 || ' ',
          eachIdx.address_line_2 || ' ',
          eachIdx.city || ' ',
          eachIdx.state || ' ',
          eachIdx.zip || ' ',
          eachIdx.website || ' ',
          eachIdx.notes || ' ',
          eachIdx.terms || ' ',
          eachIdx?.po_count || '0',
          eachIdx.user?.first_name + ' ' + eachIdx.user?.last_name,
        ]
      }),
    [data]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  return HAS_VendorViewTable(currentUser) ? (
    <>
      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-alpine'>
          <MaterialDataTable
            MaterialColumns={MaterialColumns}
            stopRedirection={HAS_VendorViewVendor(currentUser)}
            MaterialColumnsApiData={data}
            totalRows={totalRows}
            loadingBar={loading}
            tableHeight={700}
            navigationLink={'inventory/vendors/view'}
            serverPagination={pagination}
            columnVisibility={tableStorage}
            cbColumnVisibility={(callbackData) => {
              cbFunction(callbackData)
            }}
            initialState={{columnVisibility: {created_by: false}}} //hide firstName column by default
            setServerPagination={(cb_data: any) => {
              return PaginationCallback(cb_data)
            }}
            optionalTableHeader={
              <div className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'>
                <h4 className='fw-bolder m-5 w-115px'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen088.svg'
                    className='svg-icon-4 me-3'
                  />
                  Vendors
                </h4>
                <div className='mx-lg-3'>
                  <UsersListSearchComponent />{' '}
                </div>

                <div className='mui-icons'>
                  <div
                    className='flex-wrap fw-bold fs-6 pe-2 m-1'
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <button
                      style={{backgroundColor: 'lightgrey'}}
                      className='btn-lightgrey btn h-35px w-35px'
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                        <KTSVG
                          className='svg-icon-4 m-0'
                          path='/media/icons/duotune/general/setting.svg'
                        />
                      </div>
                    </button>
                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleExportData}>
                        <span>Export All Data</span>
                      </MenuItem>
                    </Menu>
                  </div>
                  <div className='m-2'>
                    {HAS_VendorCreateVendor(currentUser) ? (
                      <button
                        className='btn btn-primary h-35px w-35px fw-bold'
                        onClick={() => navigate(`/${prefixCompany}/inventory/vendors/create`)}
                      >
                        <div style={{marginTop: '-10px', marginLeft: '-6px', fontSize: '20px'}}>
                          +
                        </div>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

export default Vendors
