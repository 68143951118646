/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useRef, useState} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import {
  darkMessage,
  darkErrorMessage,
  addNotesQuoteActivity,
} from '../../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {Editor} from '@tinymce/tinymce-react'
import {HAS_Activity_Edit} from '../../../../app/pages/dashboard/PermissionFunction'
import {KTSVG} from '../../../helpers'
import reactStringReplace from 'react-string-replace'
import {ListsWidget13} from './ListsWidget13'
import blank from './../../../../_metronic/layout/components/header/blank.png'

type Props = {
  list: any[]
  className: string
  title: string
  getList: any
  getCustomerList: any
}

var parse = require('html-react-parser')

const ListsWidget12: React.FC<Props> = ({title, list, className, getList, getCustomerList}) => {
  const [show, setShow] = useState(false)
  const [noteVal, setNoteVal] = useState<any>({})
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const handleClose = useCallback(() => {
    setShow(false)
    getList()
    getCustomerList()
    setLoading(false)
  }, [])
  const editorRef: any = useRef(null)

  //set data when click on the pencil icon nd open modal
  const setNoteModal = useCallback((item: any) => {
    setNoteVal(item)
    setShow(true)
  }, [])

  const editNoteHandler = useCallback(() => {
    setLoading(true)
    addNotesQuoteActivity(noteVal?.id, editorRef.current.getContent())
      .then(({data}) => {
        setLoading(false)
        darkMessage('Notes updated Successfully')
        handleClose()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          handleClose()
          darkErrorMessage('You are not Allowed to Edit Other Employee Notes')
          console.error(e?.response?.data)
        } else {
          handleClose()
          console.error(e)
        }
        setLoading(false)
      })
    setNoteVal(null)
  }, [editorRef, noteVal])

  return (
    <>
      <div className={`card ${className}`}>
        <div className='timeline-label d-flex flex-column gap-2'>
          {list.map((item) => {
            return (
              <div
                className='timeline-item align-items-start  py-2'
                style={{margin: '0px 20px', borderRadius: '15px', background: '#F6F6F7'}}
              >
                <div className='fw-normal timeline-content text-muted d-flex align-items-start timesheet notes-card mt-n2 mb-n2'>
                  <a
                    href={`${item?.icon ? item?.icon : '/media/avatars/blank.png'}`}
                    target='blank'
                    style={{padding: '5px'}}
                  >
                    <img
                      alt='emp img'
                      onError={(evt: any) => {
                        evt.target.src = blank
                      }}
                      src={`${item?.icon ? item?.icon : '/media/avatars/blank.png'}`}
                      width='40px'
                      height='40px'
                      style={{borderRadius: '50%'}}
                    />
                  </a>
                  <div
                    className='d-flex flex-column gap-1'
                    style={{
                      width: '100%',
                      marginLeft: '10px',
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontSize: '14px',
                        marginLeft: '5px',
                      }}
                      className=''
                    >
                      <p className='d-flex fw-bold mb-0 justify-content-between file-data-info'>
                        <span className='fs-6'>
                          {item.user.first_name + ' ' + item.user.last_name}
                        </span>
                        <span className='text-muted fs-7 pe-lg-2'>
                          {item.message}
                          <strong className='cursor-pointer float-end'>
                            {HAS_Activity_Edit(currentUser, item) && (
                              <i
                                className='bi bi-pencil-square mx-2 cursor-pointer'
                                style={{color: 'black'}}
                                onClick={() => setNoteModal(item)}
                              ></i>
                            )}
                          </strong>
                        </span>
                      </p>
                      <span className='fs-8 lh-1' style={{color: 'darkgrey'}}>
                        {moment(item.original_date).format('hh:mm A, MMM. Do YYYY')}
                      </span>
                    </div>
                    {item.title && (
                      <div
                        style={{fontWeight: '500'}}
                        className='d-flex gap-3 m-1 text-dark align-items-center quote-activity-wrapper fs-6 mb-n2 mt-n1'
                      >
                        {item.message === 'Status Updated' ? (
                          <>{item.title && <ListsWidget13 itemData={item} />}</>
                        ) : (
                          reactStringReplace(item.title, '==>', (match, i) => (
                            <span key={i}>
                              <KTSVG path={'/media/custom/extra_yo.svg'} className='svg-icon-2' />
                            </span>
                          ))
                        )}
                      </div>
                    )}
                    {item?.additional_notes && (
                      <div
                        style={{
                          fontSize: '14px',
                          marginLeft: '4px',
                          textAlign: 'justify',
                          whiteSpace: 'pre-line',
                        }}
                        className='text-black mt-2 notes-desc'
                      >
                        {parse(item?.additional_notes)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/**************************** Edit  Modal start ****************************  */}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={show}
        size='lg'
        centered
        onHide={handleClose}
        className='fade'
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className='p-2 lh-base'>Additional Notes</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='kt_account_profile_details' className='collapse show'>
            {/* <label className='required fw-bold fs-6 mb-5'>Enter Notes Here</label> */}
            {/* <textarea
              defaultValue={noteVal?.additional_notes}
              onChange={(e) => setNoteVal({...noteVal, additional_notes: e?.target?.value})}
              rows={7}
              className='form-control form-control-lg form-control-solid border-0  p-3 mb-5'
            /> */}
            <Editor
              apiKey='qdz5vil5mb6qz1e23p3tytmo6w25luiwdwjo51vrjoo5tt88'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={noteVal?.additional_notes || ''}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className='btn btn-dark'>
            Discard
          </button>
          <button
            type='submit'
            disabled={loading}
            className='btn btn-primary'
            onClick={() => editNoteHandler()}
          >
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/***************************** Edit  Modal End ****************************/}
    </>
  )
}

export {ListsWidget12}
