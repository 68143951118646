import {createContext} from 'react'
import produce from 'immer'

export interface QuoteStateType {
  current: number
  step_1: []
  step_2: []
}

export const initalQuoteState = {
  current: 1,
  step_1: [],
  step_2: [],
}
export const reducerQuote = (state: QuoteStateType, action: any) => {
  switch (action.type) {
    case 'NEXT':
      return produce(state, (draft: QuoteStateType) => {
        console.log({draft})
        draft.current++
      })
    case 'BACK':
      return produce(state, (draft: QuoteStateType) => {
        draft.current--
      })
    case 'UPDATE_STEP_1':
      return produce(state, (draft: QuoteStateType) => {
        draft.step_1 = action.payload
        draft.current++
      })
    case 'UPDATE_STEP_2':
      return produce(state, (draft: QuoteStateType) => {
        draft.step_2 = action.payload
      })
    case 'RESET':
      return initalQuoteState // Reset the state to its initial value
    default:
      return state
  }
}

export const QuoteStepper = createContext({})
