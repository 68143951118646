/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {
  addVendorCredentials,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  deleteSingleInventoryVendor,
  deleteVendorCredentials,
  fetchSingleVendor,
  fetchVendorCredentials,
  updateVendorCredentials,
} from '../../modules/auth/core/_requests'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {
  HAS_CustomerUpdate,
  HAS_CustomerView,
  HAS_POTab,
  HAS_VendorDeleteVendor,
  HAS_VendorEditVendor,
  HAS_VendorLoginCredentialsTab,
  HAS_VendorPOTab,
  HAS_VendorUpdateVendor,
  HAS_VendorViewVendor,
} from './PermissionFunction'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {Modal, Stack} from 'react-bootstrap'
import {
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import {
  VendorInterface,
  VendorCredentials,
  VendorCredentials_Initial as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import {VendorNotes} from './components/VendorNotes'
import VendorPO from './VendorPO'

const profileDetailsSchema = Yup.object().shape({
  vendor_account: Yup.string().required('Vendor Account # is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const VendorsView: React.FC = () => {
  const [data, setData] = useState<VendorInterface>([] as any)
  const [vendorPo, setVendorPo] = useState(() => {
    return []
  })
  const [poCount, setPoCount] = useState(() => 0)
  const [contactData, setContactData] = useState([])
  const [credentialsList, setCredentialsList] = useState([])
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [credentialModal, setCredentialModal] = useState(false)
  const inputFile = useRef<any>()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [compo, setCompo] = useState('contact')
  const [img, setImg] = useState<any>('/media/avatars/blank.png')
  const headerContext = useContext<any>(HeaderContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [getCred, setCred] = useState({})
  const [passwordType, setPasswordType] = useState('password')
  const open = Boolean(anchorEl)
  const [deleteModal, setDeleteModal] = useState<any>({
    show: false,
    idx: null,
  })
  const [updateModal, setUpdateModal] = useState<any>({
    show: false,
    idx: null,
  })
  const handleClick = (event: React.MouseEvent<HTMLElement>, data: VendorInterface) => {
    setAnchorEl(event.currentTarget)
    setCred(data)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setCred({})
  }
  const handleCloseDeleteModal = () => {
    setDeleteModal({
      show: false,
      idx: null,
    })
  }

  const handleCloseUpdateModal = () => {
    setUpdateModal({
      show: false,
      idx: null,
    })
  }

  const handleDeleteModal = useCallback(
    (datax: any) => {
      console.log()
      deleteVendorCredentials(id, datax?.id)
        .then(({data}) => {
          getCredentials()
          darkMessage('Credentials deleted Successfully')
          handleCloseDeleteModal()
          setLoading(false)
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
    [deleteModal]
  )
  // const updateCredentials = useCallback(
  //   () =>{

  //   }
  //   [anchorEl, getCred],
  // )

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Vendor View',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/vendors`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const toggleViewPassword = (index: number) => {
    setCredentialsList((prevList) => {
      const newList: any = [...prevList]
      newList[index] = {
        ...newList[index],
        viewPasswordType: !newList[index]?.viewPasswordType || false,
      }
      return newList
    })
  }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const getList = useCallback(() => {
    setLoading(true)
    fetchSingleVendor(id!)
      .then((res: any) => {
        setLoading(false)
        setData(res?.data?.vendors[0] || [])
        setVendorPo(res?.data?.po_vendor_model || [])
        setPoCount(res?.data?.po_vendor_count || 0)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [])

  const getCredentials = useCallback(() => {
    setLoading(true)
    fetchVendorCredentials(id!)
      .then((res: any) => {
        setLoading(false)
        setCredentialsList(res?.data?.result || [])
      })
      .catch((e) => {
        console.error(e)
        setCredentialsList([])
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    setLoading(true)
    // const CONTACT_DETAILS = data?.contacts_field && JSON.parse(data?.contacts_field===undefined? data?.contacts_field)
    // const CONTACT_DETAILS = data?.contacts_field && JSON.parse( data?.contacts_field)
    // const CONTACT_DETAILS = data?.contacts_field==undefined? &&JSON.parse(data?.contacts_field)

    setContactData(data?.contacts_field || [])
    setImg(data?.image)
    setTimeout(() => {
      setLoading(false)
    }, 0)
  }, [data])

  useEffect(() => {
    getList()
    getCredentials()
  }, [getList, getCredentials])

  const deleteButton = (id: string) => {
    deleteSingleInventoryVendor(id)
      .then((res) => {
        darkMessage('Vendor Deleted Successfully')
        navigate(`/${prefixCompany}/inventory/vendors`)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
      })
  }

  const credentialFormik = useFormik<VendorCredentials>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      let requiredData: VendorCredentials = {
        vendor_id: id,
        vendor_account: values.vendor_account,
        username: values.username,
        password: values.password,
      }
      addVendorCredentials(requiredData)
        .then(({data}) => {
          setLoading(false)
          setCredentialModal(false)
          credentialFormik.resetForm()
          getCredentials()
          darkMessage('Credentials added Successfully')
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })

  const updateCredentialsFormik = useFormik<VendorCredentials>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      // setLoading(true)
      let requiredData: VendorCredentials = {
        id: values?.id,
        vendor_id: id,
        vendor_account: values.vendor_account,
        username: values.username,
        password: values.password,
      }
      updateVendorCredentials(requiredData)
        .then(({data}) => {
          setLoading(false)
          handleCloseUpdateModal()
          updateCredentialsFormik.resetForm()
          getCredentials()
          darkMessage('Credentials updated Successfully')
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e.response.data)
          } else {
            console.error(e)
          }
          setLoading(false)
        })
    },
  })
  useEffect(() => {
    updateCredentialsFormik.setFieldValue('id', updateModal?.idx?.id)
    updateCredentialsFormik.setFieldValue('vendor_account', updateModal?.idx?.vendor_account)
    updateCredentialsFormik.setFieldValue('username', updateModal?.idx?.username)
    updateCredentialsFormik.setFieldValue('password', updateModal?.idx?.password)
  }, [updateModal])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          onModalClose={() => {
            setModal(false)
          }}
          label={'somelable'}
          show={modal}
          onHide={() => setModal(false)}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          className='fade'
          centered
        >
          <div className='card'>
            <div className='card-body' style={{padding: '25px'}}>
              <h1 className='fw-bolder m-5'> {'Delete Vendor'}</h1>
              <h3 className='lh-base m-5'>
                Are you sure want to {'Delete Vendor '}
                {data?.name} ?
              </h3>
              <div className='d-flex flex-row-reverse mx-2'>
                <span className='mx-2'>
                  <button
                    onClick={() => {
                      deleteButton(id!)
                    }}
                    className='btn btn-primary float-right m-5'
                  >
                    Delete
                  </button>
                </span>
                <span className='mx-1'>
                  <button
                    onClick={() => {
                      setModal(false)
                    }}
                    className='btn btn-dark float-right m-5'
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          onModalClose={() => {
            setCredentialModal(false)
          }}
          label={'somelable'}
          show={credentialModal}
          onHide={() => setCredentialModal(false)}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          className='fade'
          centered
        >
          <div className='card'>
            <div className='card-body' style={{padding: '25px'}}>
              <form onSubmit={credentialFormik.handleSubmit}>
                <h1 className='fw-bolder m-5'> {'Add Vendor Credentials'}</h1>
                <div className='lh-base m-5 my-lg-9'>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_username'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Vendor Account # :
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Vendor Account #'
                        {...credentialFormik.getFieldProps('vendor_account')}
                      />
                      {credentialFormik.touched.vendor_account &&
                        credentialFormik.errors.vendor_account && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {credentialFormik.errors.vendor_account}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_username'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Username :
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        autoComplete='new-username'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Username'
                        {...credentialFormik.getFieldProps('username')}
                      />
                      {credentialFormik.touched.username && credentialFormik.errors.username && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{credentialFormik.errors.username}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_password'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Password :
                    </label>
                    <div className='col-lg-8 fv-row position-relative'>
                      <input
                        type={passwordType}
                        autoComplete='new-password'
                        className='form-control form-control-lg form-control-solid '
                        placeholder='Enter Password'
                        {...credentialFormik.getFieldProps('password')}
                      />
                      <span className='input-group-btn position-absolute end-0 top-0'>
                        <button
                          className='btn btn-outline-dark'
                          type='button'
                          onClick={togglePassword}
                        >
                          {passwordType === 'password' ? (
                            <i className='fa-sharp fa-solid fa-eye-slash'></i>
                          ) : (
                            <i className='fa-solid fa-eye'></i>
                          )}
                        </button>
                      </span>
                      {credentialFormik.touched.password && credentialFormik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{credentialFormik.errors.password}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row-reverse mx-2'>
                  <span className='mx-2'>
                    <button type='submit' className='btn btn-primary float-right m-5'>
                      {!loading && 'Confirm'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </span>
                  <span className='mx-1'>
                    <button
                      onClick={() => {
                        setCredentialModal(false)
                      }}
                      type='reset'
                      className='btn btn-dark float-right m-5'
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          show={deleteModal?.show}
          size='lg'
          centered
          onModalClose={handleCloseDeleteModal}
          onHide={handleCloseDeleteModal}
          className='fade'
        >
          <Modal.Header>
            <Modal.Title>
              <h1 className='p-2 lh-base'>Confirmation Message</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='kt_account_profile_details' className='collapse show'>
              {/* <img src={delObj?.url} alt='ai.svg' width='180px' height='150px' /> */}
              <h2 className='m-5'>Did You really want to delete credentials ? </h2>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='fw-bold fs-6'>
              <button onClick={handleCloseDeleteModal} className='btn btn-dark'>
                Discard
              </button>
            </div>
            <div className='fw-bold fs-6'>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={() => handleDeleteModal(deleteModal.idx)}
                style={{marginLeft: '12px'}}
              >
                Confirm
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          onModalClose={handleCloseUpdateModal}
          show={updateModal?.show}
          onHide={handleCloseUpdateModal}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          className='fade'
          centered
        >
          <div className='card'>
            <div className='card-body' style={{padding: '25px'}}>
              <form onSubmit={updateCredentialsFormik.handleSubmit}>
                <h1 className='fw-bolder m-5'> {'Update Vendor Credentials'}</h1>
                <div className='lh-base m-5 my-lg-9'>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_username'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Vendor Account # :
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Vendor Account #'
                        {...updateCredentialsFormik.getFieldProps('vendor_account')}
                      />
                      {updateCredentialsFormik.touched.vendor_account &&
                        updateCredentialsFormik.errors.vendor_account && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {updateCredentialsFormik.errors.vendor_account}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_username'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Username :
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Username'
                        {...updateCredentialsFormik.getFieldProps('username')}
                      />
                      {updateCredentialsFormik.touched.username &&
                        updateCredentialsFormik.errors.username && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {updateCredentialsFormik.errors.username}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='vendor_password'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Password :
                    </label>
                    <div className='col-lg-8 fv-row position-relative'>
                      <input
                        type={passwordType}
                        autoComplete='new-password'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Password'
                        {...updateCredentialsFormik.getFieldProps('password')}
                      />
                      <span className='input-group-btn position-absolute end-0 top-0'>
                        <button
                          className='btn btn-outline-dark'
                          type='button'
                          onClick={togglePassword}
                        >
                          {passwordType === 'password' ? (
                            <i className='fa-sharp fa-solid fa-eye-slash'></i>
                          ) : (
                            <i className='fa-solid fa-eye'></i>
                          )}
                        </button>
                      </span>

                      {updateCredentialsFormik.touched.password &&
                        updateCredentialsFormik.errors.password && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {updateCredentialsFormik.errors.password}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row-reverse mx-2'>
                  <span className='mx-2'>
                    <button type='submit' className='btn btn-primary float-right m-5'>
                      {!loading && 'Confirm'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </span>
                  <span className='mx-1'>
                    <button
                      onClick={handleCloseUpdateModal}
                      type='reset'
                      className='btn btn-dark float-right m-5'
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          onClick={() => {
            setUpdateModal({
              show: true,
              idx: getCred,
            })
          }}
        >
          Update Credentials
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setDeleteModal({
              show: true,
              idx: getCred,
            })
          }}
        >
          Delete Credentials
        </MenuItem>
      </Menu>

      {/* side bar form start  */}
      <div className='col-md-12'>
        <div className='row'>
          <div className='card col-md-3 border' style={{maxHeight: '600px'}}>
            <div className='card-body border-top' style={{padding: '10px'}}>
              <div
                className='d-flex justify-content-center rounded-circle'
                style={{overflow: 'hidden'}}
              >
                {loading ? (
                  <CircularProgress style={{margin: 'auto'}} />
                ) : (
                  <>
                    <input
                      type='file'
                      ref={inputFile}
                      style={{display: 'none'}}
                      // onChange={changeImg}
                      accept='image/*'
                    />
                    <img
                      src={img}
                      alt='Customer Image'
                      // onClick={onButtonClick}
                      onError={() => {
                        setImg(blank)
                      }}
                      style={{
                        width: '190px',
                        height: 'auto',
                        margin: 'auto',
                        cursor: 'pointer',
                        objectFit: 'contain',
                        maxWidth: '100%',
                        borderRadius: '50%',
                      }}
                    />
                  </>
                )}
              </div>

              <div className='mt-5 custom-row' style={{padding: '5px 10px'}}>
                <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Vendor Name</h6>
                  <span>{data?.name || ' '}</span>
                </div>
                {/* <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Vendor Account #</h6>
                  <span>{data?.account_id || ' '}</span>
                </div> */}
                <div className='mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Website</h6>
                  <span>{data?.website || ' '}</span>
                </div>
                <div className=' mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Address</h6>
                  <span>
                    {data?.address_line_1 && data?.address_line_1 + ' ,'}
                    {data?.address_line_2 && data?.address_line_2 + ' ,'}
                    {data?.city && data?.city + ' ,'}
                    {data?.state && data?.state + ' ,'}
                    {data?.zip && data?.zip}
                  </span>
                </div>
                <div className=' mt-4' style={{width: '100%'}}>
                  <h6 className='mb-1'>Terms</h6>
                  <span>{data?.terms || ' '}</span>
                </div>
                {/* <div className='mt-4' style={{width: '100%'}}>
                  <div className='d-flex align-items-end justify-content-between'>
                    <h6 className='mb-1'>Status</h6>
                    <select
                      id='demo-simple-select'
                      onChange={(e) => setShowModal({show: true, idx: e?.target?.value})}
                      className='border-1 text-left rounded ms-2'
                      style={{
                        height: '25px',
                        borderColor: 'lightgrey',
                        background: '#fff',
                        width: '80px',
                      }}
                    >
                      <option selected hidden disabled>
                        {getStatus}
                      </option>
                      {options.map((item, key) => {
                        return (
                          <>
                            <option value={key + 1}>{item}</option>
                          </>
                        )
                      })}
                    </select>
                  </div>
                  <div className='fw-600 mt-3 d-flex align-items-center'>
                    <span
                      className={`bg-${getColor} me-3`}
                      style={{display: 'block', width: '10px', height: '10px', borderRadius: '50%'}}
                    ></span>
                    {getStatus}
                  </div>
                </div> */}
              </div>
              <div className='mt-5 custom-row customer-view-btn d-flex justify-content-between'>
                {HAS_VendorUpdateVendor(currentUser) ? (
                  <button
                    type='submit'
                    className='btn btn-light'
                    onClick={() => navigate(`/${prefixCompany}/inventory/vendors/edit/${id}`)}
                  >
                    {'Edit Vendor'}
                  </button>
                ) : null}
                {HAS_VendorDeleteVendor(currentUser) ? (
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      setModal(true)
                    }}
                  >
                    {'Delete Vendor'}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* right section */}
          <div className='col-md-9'>
            <div className='row' style={{marginLeft: '1px'}}>
              {/*right side form start with heading tag */}
              <div className='card col-md-12 border overflow-scroll'>
                <div className='card-body border-top p-7 tabs-titles'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'contact' ? 'text-danger' : ''}
                          onClick={() => setCompo('contact')}
                          style={{cursor: 'pointer', margin: '0'}}
                        >
                          Contact Info
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'vendor_notes' ? 'text-danger' : ''}
                          onClick={() => setCompo('vendor_notes')}
                          style={{cursor: 'pointer', margin: '0'}}
                        >
                          Vendor Notes
                        </h5>
                      </div>
                      {HAS_VendorPOTab(currentUser) && (
                        <div className='col'>
                          <h5
                            className={compo && compo === 'purchase_order' ? 'text-danger' : ''}
                            onClick={() => setCompo('purchase_order')}
                            style={{cursor: 'pointer', margin: '0'}}
                          >
                            Purchase Orders
                          </h5>
                        </div>
                      )}

                      {HAS_VendorLoginCredentialsTab(currentUser) && (
                        <div className='col'>
                          <h5
                            className={compo && compo === 'login_credentials' ? 'text-danger' : ''}
                            onClick={() => setCompo('login_credentials')}
                            style={{cursor: 'pointer', margin: '0'}}
                          >
                            Login Credentials
                          </h5>
                        </div>
                      )}
                      {/* <div className='col'>
                        <h5
                          className={compo && compo === 'address' ? 'text-danger' : ''}
                          onClick={() => setCompo('address')}
                          style={{cursor: 'pointer'}}
                        >
                          Addresses
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'customer' ? 'text-danger' : ''}
                          onClick={() => setCompo('workorder')}
                          style={{cursor: 'pointer'}}
                        >
                          Projects
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'quotes' ? 'text-danger' : ''}
                          onClick={() => setCompo('quotes')}
                          style={{cursor: 'pointer'}}
                        >
                          Quotes
                        </h5>
                      </div>
                      <div className='col'>
                        <h5
                          className={compo && compo === 'activity' ? 'text-danger' : ''}
                          onClick={() => setCompo('activity')}
                          style={{cursor: 'pointer'}}
                        >
                          Activity
                        </h5>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/*right side form heading end */}

              {compo && compo === 'contact' ? (
                <div
                  className='mt-5 tab-grid-content'
                  style={{display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '20px'}}
                >
                  {contactData?.length > 0 &&
                    contactData.map(({fName, lName, email, phone, title, alternative_contact}) => {
                      return (
                        <div className=' card'>
                          <div className=' card-body border custom-row'>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Title</h6>
                                <span>{title || ' '}</span>
                              </div>
                            </div>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Name</h6>
                                <span>{fName && lName ? fName + ' ' + lName : ' '}</span>
                              </div>
                            </div>
                            <div className=' mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Phone Number</h6>
                                <span>{phone || ' '}</span>
                              </div>
                            </div>
                            <div className=' mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Email</h6>
                                <span>{email || ' '}</span>
                              </div>
                            </div>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Other</h6>
                                <span>{alternative_contact || ' '}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              ) : compo && compo === 'purchase_order' ? (
                <Stack className='mt-5'>
                  <VendorPO />
                </Stack>
              ) : compo && compo === 'login_credentials' ? (
                <Stack>
                  <div className='my-5 text-right'>
                    <button
                      className='btn btn-secondary'
                      onClick={() => {
                        setCredentialModal(true)
                      }}
                    >
                      Add New Credentials
                    </button>
                  </div>
                  {loading && <LinearProgress />}
                  <div
                    className='mt-5 '
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto auto',
                      gap: '20px',
                      overflow: 'scroll',
                    }}
                  >
                    {credentialsList?.map((eachData: VendorCredentials, index: number) => {
                      return (
                        <div className=' card'>
                          <div className=' card-body border custom-row'>
                            <div className='float-end'>
                              <Tooltip title='Manage Credentials' className='bg-secondary'>
                                <IconButton
                                  onClick={(e) => handleClick(e, eachData)}
                                  size='small'
                                  sx={{ml: 2}}
                                  aria-controls={open ? 'account-menu' : undefined}
                                  aria-haspopup='true'
                                  aria-expanded={open ? 'true' : undefined}
                                >
                                  <KTSVG path='/media/icons/duotune/arrows/arr082.svg' />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Vendor Account #</h6>
                                <span>{eachData?.vendor_account || 'N/A'}</span>
                              </div>
                            </div>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>Username</h6>
                                <span>{eachData?.username}</span>
                              </div>
                            </div>
                            <div className='mt-5'>
                              <div className=''>
                                <h6 className='mb-1'>
                                  Password
                                  <span className='input-group-btn'>
                                    <button
                                      className='btn btn-outline-dark p-0 mx-4'
                                      type='button'
                                      onClick={() => toggleViewPassword(index)}
                                    >
                                      {eachData?.viewPasswordType === true ? (
                                        <i className='fa-solid fa-eye'></i>
                                      ) : (
                                        <i className='fa-sharp fa-solid fa-eye-slash'></i>
                                      )}
                                    </button>
                                  </span>
                                </h6>
                                <input
                                  type={eachData?.viewPasswordType || 'password'}
                                  value={eachData?.password}
                                  className='e-disabled bg-white border-0 text-black'
                                  readOnly={true}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Stack>
              ) : compo && compo === 'vendor_notes' ? (
                <Stack>
                  <VendorNotes />
                </Stack>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      {/* side bar form end  */}

      {/************************ Modal Customer status start *******************************/}
      {/* <Modal
        onModalClose={() => {
          setModalStatus(false)
        }}
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        // size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
      
        <Modal.Body>
          <div className='row mb-6'>
        
            <div className='col-lg-8 fv-row'>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter Department'
                onChange={(e) => setCustomerStatus(e.target.value)}
              >
                <option selected hidden disabled>
                  {data && data?.status === '0'
                    ? 'Default'
                    : data?.status === '1'
                    ? 'Active'
                    : 'In Active'}
                </option>
                <option value='1'>Active</option>
                <option value='2'>In Active</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
   
          <Button
            onClick={() => {
              customerStatusUpdate()
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/************************ Modal Customer status end   *******************************/}

      {/* <div id='kt_account_profile_details' className='collapse show'>
                <form noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Business Name
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data.company}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Contact Name
                            </label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            readOnly={true}
                                            value={data?.first_name + '  ' + data?.last_name}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data?.email}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Phone Number
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data?.phone}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Alternative Contact</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data?.company_phone}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required  fw-bold fs-6'>
                                Project Address
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data?.address}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            readOnly={true}
                                            value={data?.city}
                                        />
                                    </div>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            readOnly={true}
                                            value={data?.state}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Mailing Address</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={data?.zip_code}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Referral Source</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    name='refer'
                                    value={data?.referral_type}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Date Created</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    value={moment(data?.date_created).format('llll')}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Customer Notes</label>
                            <div className='col-lg-8 fv-row'>
                                <textarea
                                    className='form-control form-control-lg form-control-solid'
                                    readOnly={true}
                                    rows={5}
                                    value={data?.notes}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div> */}
      {/* </div> */}
    </>
  )
}
export default VendorsView
