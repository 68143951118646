/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {FC} from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction} from '../../context/HeaderContext'
import {HeaderContext} from '../../context/HeaderContext'
import {
  companyFilesList,
  currnetCompanyName,
  customLoading,
} from '../../modules/auth/core/_requests'
import {KTSVG} from '../../../_metronic/helpers'
import SimpleDataTable from './SimpleDataTable'
import {MaterialDataTable} from './MatrialDataTable'
import {useLocalStorage} from './GlobalComponents'
import {Menu, MenuItem} from '@mui/material'
import {ExportToCsv} from 'export-to-csv'
import {useAuth} from '../../modules/auth'

const CompanyFiles: FC = () => {
  const headerContext = useContext<any>(HeaderContext)
  const [formArray, setFormArray] = useState<any>([])
  const [totalRows, seTotalRows] = useState(0)
  const [tableStorage, setTableStorage] = useLocalStorage('companyFilesColsVisibility', '')
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Company Files',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: -1,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const ClipBoard = useCallback((idx: any) => {
    navigator.clipboard.writeText(idx?.file_path)
    customLoading()
  }, [])
  const columns: any = useMemo(
    () => [
      {
        size: 300,

        header: 'File Name',
        accessorFn: (row: any) => <div>{row.file_name}</div>,
        accessorKey: 'file_name',
      },
      {
        header: 'Project',
        accessorFn: (row: any) => <div>{row.workorder}</div>,
        accessorKey: 'project',
      },
      {
        header: 'Upload By',
        accessorFn: (row: any) => <div>{row?.user?.first_name + ' ' + row?.user?.last_name}</div>,
        size: 200, //increase the width of this column
        accessorKey: 'upload_by',
      },
      {
        header: 'Link',
        id: 'button',
        accessorFn: (row: any, id: any) => (
          <>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-light btn-active-light-primary'
              onClick={(e) => {
                ClipBoard(row)
              }}
            >
              <span className='svg-icon svg-icon-5 m-0'>
                <KTSVG path='/media/custom/link.svg' className='svg-icon-1' />
              </span>
            </button>
          </>
        ),
        accessorKey: 'Link',
      },
    ],
    []
  )
  useEffect(() => {
    setLoading(true)
    fetchQuoteStatus(pagination.pageIndex, '', pagination.pageSize)
  }, [pagination])

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    fetchQuoteStatus(getPagination?.pageIndex, '', getPagination?.pageSize)
  }, [])
  const CSV_HEADER = useMemo(() => ['File Name', 'Project', 'Upload By', 'Link'], [])
  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const CSV_DATA = useMemo(
    () =>
      formArray.map((eachIdx: any) => {
        return [
          eachIdx?.file_name,
          eachIdx?.workorder,
          eachIdx.user?.first_name + ' ' + eachIdx.user?.last_name,
          eachIdx?.file_path,
        ]
      }),
    [formArray]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])
  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])
  const fetchQuoteStatus = useCallback(
    async (page: number = 0, str = '', pagePerEntries: number = 100) => {
      await companyFilesList(page, str, pagePerEntries)
        .then((res: any) => {
          const companyFiles = res?.data?.response
          setFormArray(companyFiles)
          seTotalRows(res?.data?.totalRows)
          setLoading(false)
        })
        .catch((e: any) => {
          setLoading(false)
        })
    },
    []
  )
  return (
    <>
      <div className='card  mt-5 p-0 overflow-scroll custom-data-table'>
        <MaterialDataTable
          MaterialColumns={columns}
          stopRedirection={false}
          MaterialColumnsApiData={formArray}
          totalRows={totalRows}
          tableHeight={650}
          loadingBar={loading}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          columnVisibility={tableStorage}
          cbColumnVisibility={(callbackData) => {
            cbFunction(callbackData)
          }}
          optionalTableHeader={
            <div
              className='flex-wrap fw-bold fs-6 pe-2'
              style={{
                alignItems: 'center',
                marginLeft: '96%',
              }}
            >
              <button
                style={{backgroundColor: 'lightgrey'}}
                className='btn-lightgrey btn h-35px w-35px'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                  <KTSVG
                    className='svg-icon-4 m-0'
                    path='/media/icons/duotune/general/setting.svg'
                  />
                </div>
              </button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <>
                  <MenuItem onClick={handleExportData}>Export All Data</MenuItem>
                </>
              </Menu>
            </div>
          }
        />
      </div>
    </>
  )
}

export default CompanyFiles
