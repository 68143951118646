/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useFormik} from 'formik'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  deleteEmployee,
  updateEmp,
} from '../../../../app/modules/auth/core/_requests'
import * as Yup from 'yup'
import ErrorAlert, {ErrorType} from '../../../../app/modules/errors/ErrorAlert'
import SuccessAlert from '../../../../app/modules/errors/SuccessAlert'
import RolesDropDown from './RolesDropDown'
import Roles from '../../../../app/pages/dashboard/Roles'
import {UserEditModal} from '../../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModal'
import {LinearProgress} from '@mui/material'
import {
  HAS_EmployeesDeleteEmployeeMenu,
  HAS_EmployeesUpdateEmployeeMenu,
} from '../../../../app/pages/dashboard/PermissionFunction'
export function Dropdown1({defaultValue, onChange, user_id, emp, changed, menuSwitch}: any) {
  const resetRef: any = useRef(null)
  const {currentUser} = useAuth()
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [modal, setModal] = useState(false)
  const [show, setShow] = useState(false) //Edit Modal state
  const [inputValue, setInputValue] = useState<any>(null)
  const [selectVal, setSelectVal] = useState<any>(null)
  const navigate = useNavigate()
  const [warn, setWarn] = useState('')

  const regex = /\d\d\d\d/g
  const profileDetailsSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    fName: Yup.string().required('First name is required'),
    lName: Yup.string().required('Last name is required'),
    title: Yup.string().required('Title is required'),
    phone: Yup.string().min(14).required('Work Phone Number is required'),
    email: Yup.string().email().required('Work Email Address is required'),
  })
  const initialValues = {
    type: defaultValue,
    user_id: user_id,
    fName: '',
    lName: '',
    email: '',
    role: '',
    phone: '',
    title: '',
    hometown: '',
    birthday: '',
    anniversary: '',
  }
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const handleDmodal = () => {
    setModal(true)
  }
  const setfal = () => {
    setLoading(false)
    setModal(false)
  }
  const checkDelete = () => {
    deleteEmployee(emp?.id, warn)
      .then((res) => {
        darkMessage('Employee Deleted Successfully')
        setfal()
        setWarn('')
        changed()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
        setfal()
        setWarn('')
        changed()
      })
  }

  useEffect(() => {
    setClicked(menuSwitch)
  }, [menuSwitch])

  //**** Edit Form Function start ***
  const editModal = () => {
    setShow(true)
    console.log('getEmp', show, modal, clicked)
  }
  console.log('getEmp', show, modal, clicked)
  const handleClose = () => {
    setShow(false)
  }

  const getEmpData = useCallback(() => {
    if (emp) {
      editformik.setFieldValue('fName', emp.first_name)
      editformik.setFieldValue('lName', emp.last_name)
      editformik.setFieldValue('email', emp.email)
      editformik.setFieldValue('role', emp.user_type)
      editformik.setFieldValue('pin', emp.pin)
      if (emp.phone) {
        editformik.setFieldValue('phone', emp.phone)
      }
      editformik.setFieldValue('title', emp.emp_title)
      editformik.setFieldValue('hometown', emp.hometown)
      editformik.setFieldValue('birthday', emp.birthday)
      editformik.setFieldValue('anniversary', emp.anniversary)
      setInputValue(emp.phone)
    }
  }, [])

  useEffect(() => {
    getEmpData()
  }, [getEmpData])

  const editformik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      let emp = {
        first_name: values?.fName,
        last_name: values?.lName,
        emp_role: values?.role,
        emp_email: values?.email,
        emp_phone: values?.phone,
        user_type: values?.type,
        emp_title: values?.title,
        emp_birthday: values?.birthday,
        emp_anniversary: values?.anniversary,
        emp_hometown: values?.hometown,
      }
      const user_id = values?.user_id
      updateEmp(emp, user_id)
        .then(({data: any}) => {
          setLoading(false)
          editformik.resetForm()
          resetRef?.current?.click()
          onChange(emp?.emp_role, user_id)
          darkMessage('Employee Update Successfully')
          navigate(`/${prefixCompany}/employee/view`)
          handleClose()
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setErrorBody(e.response.data)
            setLoading(false)
            handleClose()
          }
        })
    },
  })

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    {
      editformik.setFieldValue('phone', formattedPhoneNumber)
    }
  }

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <>
        <div
          className={
            clicked
              ? 'menu menu-sub menu-sub-dropdown w-250px w-md-300px false show positionRelative'
              : 'menu menu-sub menu-sub-dropdown w-250px w-md-300px true '
          }
          data-kt-menu='true'
          data-popper-placement='bottom-end'
          style={{
            zIndex: 105,
            position: 'absolute',
            inset: '0px 0px auto auto',
            margin: '0px',
            top: '90px',
          }}
        >
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Customizations</div>
          </div>
          <div className='separator border-gray-200'></div>
          {HAS_EmployeesUpdateEmployeeMenu(currentUser) && (
            <div className='m-5'>
              <label className='form-label fw-bold'>Update Employee Details</label>
              <div className='fw-bold p-2' style={{color: '#b5b5c3'}}>
                Click Below to Update Employee Details...
              </div>
              <div className='text-right mt-3'>
                <button
                  // style={{width: '100%'}}
                  className='btn btn-sm btn-primary'
                  // disabled={loading}
                  onClick={() => editModal()}
                >
                  {!loading && 'Update'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          )}
          {HAS_EmployeesDeleteEmployeeMenu(currentUser) && (
            <div className='m-5'>
              <label className='form-label fw-bold'>Manage Employee</label>
              <div className='fw-bold p-2' style={{color: '#b5b5c3'}}>
                Click Below to delete Employee...
              </div>
              <div className='text-right mt-3'>
                <button
                  onClick={handleDmodal}
                  // style={{width: '100%'}}
                  className='btn btn-sm btn-primary'
                  disabled={loading}
                >
                  {!loading && 'Delete'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <Modal
          className='fade'
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          onModalClose={() => {
            setfal()
          }}
          show={modal}
          onHide={setfal}
          size='lg'
          centered
        >
          <div className='card'>
            <div className='card-body' style={{overflow: 'scroll'}}>
              <div className='row g-6 g-xl-9'>
                <div className='col-12'>
                  <h1 className='fw-bolder m-2'>Did You really want to delete this Employee ?</h1>
                </div>
                <div className='col-12'>
                  <h3 className='p-5 text-justify fw-normal'>
                    To Confirm Changes Please Type <strong>Your Account Password</strong>
                  </h3>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid '
                    placeholder='Enter Password'
                    onChange={(e: any) => {
                      setWarn(e.target.value)
                    }}
                  />
                </div>
                <div className='col-12'>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-dark  my-2 me-2' onClick={setfal}>
                      {`Discard `}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary my-2 me-2'
                      onClick={checkDelete}
                    >
                      {`Delete Employee`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/**************************** Edit Employee Form start ****************************  */}
        <Modal
          className='fade'
          show={show}
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          size='lg'
          centered
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              <h1 className='p-2 lh-base'>Update Employee</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={editformik.handleSubmit} noValidate className='form'>
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Full Name
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='First name'
                            {...editformik.getFieldProps('fName')}
                          />
                          {editformik.touched.fName && editformik.errors.fName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{editformik.errors.fName}</div>
                            </div>
                          )}
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Last name'
                            {...editformik.getFieldProps('lName')}
                          />
                          {editformik.touched.lName && editformik.errors.lName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{editformik.errors.lName}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
                    <div className='col-lg-8 fv-row'>
                      <RolesDropDown
                        defaultValue={editformik.values['role']}
                        onChange={(evt: any) => {
                          editformik.setFieldValue('role', evt?.target?.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Work Email Address
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Email'
                        {...editformik.getFieldProps('email')}
                      />
                      {editformik.touched.email && editformik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.email}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Work Phone Number
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Phone Number'
                        onChange={(e) => handleInput(e)}
                        value={inputValue}
                      />
                      {editformik.touched.phone && editformik.errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.phone}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Title</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Title'
                        {...editformik.getFieldProps('title')}
                      />
                      {editformik.touched.title && editformik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.title}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'>Hometown</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='HomeTown'
                        {...editformik.getFieldProps('hometown')}
                      />
                      {editformik.touched.hometown && editformik.errors.hometown && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.hometown}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'>Birthday</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Title'
                        {...editformik.getFieldProps('birthday')}
                      />
                      {editformik.touched.birthday && editformik.errors.birthday && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.birthday}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'>Anniversary</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Title'
                        {...editformik.getFieldProps('anniversary')}
                      />
                      {editformik.touched.anniversary && editformik.errors.anniversary && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{editformik.errors.anniversary}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row justify-content-end'>
                    <div className='col-lg-2 col-form-label  fw-bold fs-6'>
                      <button onClick={handleClose} type='button' className='btn btn-dark'>
                        Discard
                      </button>
                    </div>
                    <div className='col-lg-3 col-form-label  fw-bold fs-6'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        style={{marginLeft: '12px'}}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        {/***************************** Edit Form End ****************************/}
      </>
    </>
  )
}
