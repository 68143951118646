import React, {useState, useCallback, useEffect, useContext} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {
  itemService,
  darkMessage,
  updateDescription,
  updateItem,
  darkErrorMessage,
} from '../../modules/auth/core/_requests'
import {Modal} from 'react-bootstrap'
const SubQuoteFooter = ({
  discount,
  setDiscountValue,
  disUnit,
  setGetServiceName,
  setGetServiceDescription,
  setGetPriceServices,
  getServiceName,
  getServiceDescription,
  setCostItems,
  getCostItems,
  finalCost,
  selectedOnRow,
  getMarkUp,
  itemRowData,
  markUp,
  setViewItem,
  setRowData,
  viewItem,
  viewService,
  defaultTax,
  customerView,
  formData,
  setDisable,
  getList,
  disable,
  tax,
  setTax,
  taxValue,
  grandTotal,
  subTotal,
  setSubTotal,
  itemTotal,
  serviceTotal,
  item,
  service,
  getItem,
  getService,
  rowData,
  setUpdModalOpen,
  setService,
  setItem,
  fieldArrayData,
  setFieldArrayData,
  fieldServiceData,
  setFieldServiceData,
  editedSubQuoteDisclaimer,
  getQuantityItems,
  setQuantityItems,
  getQuantityServices,
  setQuantityServices,
  getPriceServices,
}: any) => {
  const {id} = useParams()


  const [cancelModal, setCancelModal] = useState(false)



  const handleButtonClick = () => {

  
    const quote_id = id

    const fieldDataItems = []

    const indexs = viewItem.length

    for (let i = 0; i < fieldArrayData.length; i++) {

      const modalItemData = {
        subquote_id: rowData?.label,
        item_id: fieldArrayData[i].id,
        finalCost:
          getCostItems.length === 0
            ? fieldArrayData[i].item_cost
            : getCostItems[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],

        quantity:
          getQuantityItems[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
        markup: getMarkUp[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
        priceUnit:
          selectedOnRow[i === 0 ? indexs : i === 1 ? indexs + 1 : i === 2 ? indexs + 2 : i],
      }
      fieldDataItems.push(modalItemData)
    }
    const fieldDataServices = []
    const sindex = viewService.length
    for (let i = 0; i < fieldServiceData.length; i++) {
      const modalServiceData = {
        subquote_id: rowData?.label,
        service_name:
          getServiceName.length === 0
            ? fieldServiceData[i].service_name
            : getServiceName[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
        serviceDescription:
          getServiceDescription.length === 0
            ? fieldServiceData[i].service_description
            : getServiceDescription[
                i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i
              ],
        servicequantity:
          getQuantityServices[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
        servicePrice:
          getPriceServices.length === 0
            ? fieldServiceData[i].service_rate
            : getPriceServices[i === 0 ? sindex : i === 1 ? sindex + 1 : i === 2 ? sindex + 2 : i],
      }
      fieldDataServices.push(modalServiceData)
    }
    const taxSet = tax !== '' ? tax : defaultTax
    itemService(
      JSON.stringify(fieldDataItems),
      JSON.stringify(fieldDataServices),
      taxSet,
      quote_id,
      grandTotal,
      editedSubQuoteDisclaimer,
      rowData?.id,
      rowData?.description,
      disUnit,
      discount,
    ).then(({data}) => {
      darkMessage(data.message)
      setUpdModalOpen(false)
      getItem()
      getService()
      setViewItem([])
      setItem([])
      setService([])
      setFieldArrayData([])
      setGetServiceName([])
      setGetServiceDescription([])
      setGetPriceServices([])
      setFieldServiceData([])
      setCostItems([])
      setDiscountValue('')
      getList()
      setDisable(false)
    })
    updateDescription(rowData.id, formData.description).then(({data}) => {
      darkMessage(data.message)
      getList()
    })

 

   
    setViewItem([])
 
  }

  const handleCancel = () => {
    setUpdModalOpen(false)
    setCancelModal(false)
    setItem([])
    setService([])
    setGetServiceName([])
    setGetServiceDescription([])
    setGetPriceServices([])
    setFieldArrayData([])
    setFieldServiceData([])
    getList()
  }
  return (
    <div>
      
      {customerView === false && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button
            style={{marginRight: '20%'}}
            className='btn btn-secondary'
            onClick={() => setCancelModal(true)}
          >
            Cancel
          </button>
          <Modal
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', backdropFilter: 'blur(2px)'}}
            className='border-2 border-secondary'
            show={cancelModal}
            onHide={() => setCancelModal(false)}
           
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure you do not want to save your edits ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div>
                  <button
                    style={{marginRight: '10%', marginLeft: '30%'}}
                    className='btn btn-secondary'
                    onClick={handleCancel}
                  >
                    Discard Edits
                  </button>
                  <button
                    style={{marginRight: '4%'}}
                    className='btn btn-primary'
                    onClick={handleButtonClick}
                  >
                    Save Edits
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <button
            style={{marginRight: '30%'}}
            className='btn btn-primary'
            onClick={handleButtonClick}
          >
            Submit
          </button>
        </div>
      )}
     
    </div>
  )
}
export default SubQuoteFooter
