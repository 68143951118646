// / eslint-disable jsx-a11y/anchor-is-valid /
import {useContext, useEffect, useState} from 'react'
import {FC} from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import * as Yup from 'yup'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useFormik} from 'formik'
import {createRemainder, currnetCompanyName, darkMessage} from '../../modules/auth/core/_requests'
import { HAS_DashboardRemainderAdd } from './PermissionFunction'
import { useAuth } from '../../modules/auth'
import { useNavigate } from 'react-router-dom'
const yesterday = new Date(Date.now() - 86400000)
const profileDetailsSchema = Yup.object().shape({
  from_date: Yup.date()
    .required('From Date is required')
    .min(yesterday, 'Date cannot be in the past'),
  to_date: Yup.date().required('To date is required').min(yesterday, 'Date cannot be in the past'),
  title: Yup.string().required('Title is required'),
})
const AddReminder: FC = () => {
  const headerContext = useContext<any>(HeaderContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  let description =
    "You don't always need a plan. Sometimes you just need to breathe, trust, let go and see what happens."

  useEffect(() => {
    // console.log("ues")
  }, [description])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Reminder',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = {
    from_date: '',
    to_date: '',
    title: '',
    status: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      let remainder = {
        from_date: values.from_date,
        to_date: values.to_date,
        title: values.title,
        status: values.status,
      }
      createRemainder(remainder)
        .then(() => {
          setLoading(false)
          formik.resetForm()
          darkMessage('Remainder added Successfully')
          navigate(`/${prefixCompany}/dashboard`)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            // setErrorBody(e.response.data)
            setLoading(false)
          }
        })
    },
  })
  return (
    HAS_DashboardRemainderAdd(currentUser) &&
    <>
      <div className='container card'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row p-5'>
            <div className='col-lg-12 p-5'>
              <h3>Add Reminder To Calender</h3>
            </div>
            <hr></hr>
            <div className='col-lg-12 add-reminder-event'>
              <div className='row mb-2'>
                <div className='col-lg-4'>
                  <b>Enter Title</b>
                  <br></br>
                  <input
                    type='text'
                    placeholder='Add Title'
                    style={{width: '75%', marginRight: '10px'}}
                    {...formik.getFieldProps('title')}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-4'>
                  <b>Start Date</b>
                  <input
                    type='date'
                    style={{width: '75%', marginRight: '10px'}}
                    {...formik.getFieldProps('from_date')}
                  />
                  {formik.touched.from_date && formik.errors.from_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.from_date}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-4'>
                  <b>End Date</b>
                  <input
                    type='date'
                    style={{width: '75%', marginRight: '10px'}}
                    {...formik.getFieldProps('to_date')}
                  />
                  {formik.touched.to_date && formik.errors.to_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.to_date}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-12 p-5'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || !formik.isValid}
              >
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddReminder
