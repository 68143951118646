/* eslint-disable react-hooks/exhaustive-deps */
import {Button, LinearProgress, Menu, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useState, useMemo, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'

import {
  darkMessage,
  deleteInactiveEmployee,
  getInactiveEmployee,
  inviteUser,
} from '../../modules/auth/core/_requests'
import {MaterialDataTable} from './MatrialDataTable'
import {KTSVG} from '../../../_metronic/helpers'
import {ExportToCsv} from 'export-to-csv'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {useLocalStorage} from './GlobalComponents'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {currnetCompanyName} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {
  HAS_InvitedEmployeeCreateInvitedEmployee,
  HAS_InvitedEmployeeDeleteInvitation,
  HAS_InvitedEmployeeResendInvitation,
  HAS_InvitedEmployeeViewTable,
} from './PermissionFunction'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'

function Invitation() {
  const {currentUser} = useAuth()
  const headerContext = useContext<any>(HeaderContext)
  const [totalRows, seTotalRows] = useState(0)
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [tableStorage, setTableStorage] = useLocalStorage('invitationColsVisibility', '')
  const [searchParams, setSearchParams] = useSearchParams()
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        subtitle: ` `,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])
  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => row.first_name + ' ' + row.last_name,
        size: 150,
        header: 'Full Name', //uses the default width from defaultColumn prop
        accessorKey: 'emp_name',
      },
      {
        accessorFn: (row: any) => row.email,
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Phone Number',
        accessorFn: (row: any) => row.phone,
        accessorKey: 'phone_number',
      },
      {
        accessorFn: (row: any) => row.role,
        header: 'User Role',
        accessorKey: 'user_role',
      },
      {
        accessorFn: (row: any) => row.pin,
        header: 'Employee Pin',
        accessorKey: 'emp_pin',
      },
      {
        header: 'Resend Invitation',
        accessorKey: 'resend_invitation',
        accessorFn: (row: any) =>
          HAS_InvitedEmployeeResendInvitation(currentUser) ? (
            <button
              style={{padding: '0px', minHeight: '44px'}}
              onClick={() => {
                resendInvi(row)
              }}
              disabled={loading}
              className='btn btn-secondary px-5'
            >
              {'Resend Invitation'}
            </button>
          ) : null,
      },
      {
        header: 'Delete Invitation',
        accessorKey: 'delete_invitation',

        accessorFn: (row: any) =>
          HAS_InvitedEmployeeDeleteInvitation(currentUser) ? (
            <button
              style={{width: '100px'}}
              onClick={() => {
                deleteInvi(row)
              }}
              className='btn btn-secondary '
            >
              {`Delete`}
            </button>
          ) : null,
      },
    ],
    []
  )

  const [data, setData] = useState([])
  const [modal, setModalShow] = useState<any>({show: false, data: null})
  const [loading, setLoading] = useState(false)

  const getList = useCallback((string = '') => {
    setLoading(true)
    getInactiveEmployee(string)
      .then((res) => {
        let result = res?.data?.members
        setData(result || [])
        seTotalRows(res?.data?.team_member_count)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
    setLoading(false)
  }, [])

  useEffect(() => {
    const search: any = searchParams.get('str')
    getList(search)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  useEffect(() => {
    getList()
  }, [getList])

  const resendInvi = (data: any) => {
    setLoading(true)
    let fullName: string = `${data.first_name} ${data.last_name}`
    inviteUser(
      fullName,
      data?.pin,
      data?.email,
      data?.role,
      data?.phone,
      data?.title,
      data?.birthday,
      data?.anniversary,
      data?.hometown
    )
      .then((res: any) => {
        console.log(res?.data?.message)
        darkMessage(res?.data?.message)
        setLoading(false)
      })
      .catch((e: any) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setLoading(false)
      })
  }

  const deleteInvi = (data: any) => {
    setModalShow({show: true, data: data})
  }
  const discardButton = () => {
    setModalShow(false)
  }
  const confirmButton = (idx: any) => {
    deleteInactiveEmployee(idx?.id)
      .then((res: any) => {
        getList()
        setModalShow(false)
        darkMessage(res?.data?.message)
      })
      .catch((e: any) => {
        getList()
        if (e?.response?.status === 409) {
          console.error(e?.response?.error)
        } else {
          console.error(e)
        }
      })
  }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })

  const PaginationCallback = useCallback(
    (getPagination: any) => {
      getList()
    },
    [pagination]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: Object.keys(data[0] || []),
      },
    ],
    [data]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(data)
  }, [data])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={modal?.show}
        className={'fade'}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='card'>
          <div className='card-body'>
            <h1 className='fw-bolder m-5'>Confirm Message</h1>
            <div className='row g-6 g-xl-9'>
              <div>
                <h3 className='lh-base m-5'>Did You really want to Delete this Invitation ?</h3>
              </div>
              <div className='text-end' style={{padding: '0px 10px'}}>
                <button onClick={discardButton} className='btn btn-dark float-right m-5'>
                  {`Discard `}
                </button>
                <button
                  onClick={() => {
                    confirmButton(modal?.data)
                  }}
                  className='btn btn-primary float-right m-5'
                >
                  {`Confirm `}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {HAS_InvitedEmployeeViewTable(currentUser) ? (
        <MaterialDataTable
          MaterialColumns={MaterialColumns}
          MaterialColumnsApiData={data}
          totalRows={totalRows}
          loadingBar={loading}
          tableHeight={600}
          navigationLink={'employee/invitation'}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          columnVisibility={tableStorage}
          cbColumnVisibility={(callbackData) => {
            cbFunction(callbackData)
          }}
          stopRedirection={false}
          optionalTableHeader={
            <>
              <div className='card-title m-0 d-flex d-inline align-items-center'>
                <h4 className='fw-bolder m-5 w-115px'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen065.svg'
                    className='svg-icon-4 me-3'
                  />
                  Invited Employees
                </h4>

                <button
                  style={{backgroundColor: 'lightgrey'}}
                  className='btn-lightgrey btn h-35px w-35px'
                  id='basic-button'
                  onClick={handleClick}
                >
                  <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                    <KTSVG
                      className='svg-icon-4 m-0'
                      path='/media/icons/duotune/general/setting.svg'
                    />
                  </div>
                </button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <>
                    <MenuItem onClick={handleExportData}>
                      <span>Export All Data</span>
                    </MenuItem>
                  </>
                </Menu>
                {HAS_InvitedEmployeeCreateInvitedEmployee(currentUser) ? (
                  <div style={{marginLeft: '8px'}}>
                    <button
                      className='btn btn-primary h-35px w-35px fw-bold'
                      onClick={() => navigate(`/${prefixCompany}/employees/invitations`)}
                    >
                      <div style={{marginTop: '-7px', marginLeft: '-6px', fontSize: '20px'}}>+</div>
                    </button>
                  </div>
                ) : null}
              </div>
            </>
          }
        />
      ) : null}
    </>
  )
}
export default Invitation
