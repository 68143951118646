import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {FC} from 'react'
import {useAuth} from '../../modules/auth'
import {currnetCompanyName} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {Menu, MenuItem} from '@mui/material'
import {ExportToCsv} from 'export-to-csv'
import {inventoryData} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import Modal from 'react-bootstrap'
import Lifetime2 from './Lifetime2'
import {HAS_InventoryEditCategory} from './PermissionFunction'

const InventorySettings: FC = () => {
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [totalRows, seTotalRows] = useState(0)
  const headerContext = useContext<any>(HeaderContext)
  const [data, setData] = useState<any>([])
  const [item, setItemSettings] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [header, setHeader] = useState<any>({
    totalStockValue: 0,
    incomingItems: 0,
    inactiveItems: 0,
    lowInventory: 0,
  })
  const ActiveItemType = {
    all: 'All Items',
    active: 'Active Items',
    inactive: 'Inactive Items',
  }
  const CSV_HEADER = useMemo(
    () => [
      'Mfg. Part No.',
      'Item Name',
      'Manufacturer',
      'Category',
      'Vendor',
      'Inventory Location',
      'Quantity In Store',
      'Available Stock',
      'Quantity Reserved',
      'Quantity On Order',
      'Item Image',
    ],
    []
  )
  const handleSubmitModal = () => {
    setItemSettings(false)
    getList()
  }
  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx: any) => {
        return [
          eachIdx?.sku,
          eachIdx?.name,
          eachIdx?.brand?.name,
          eachIdx?.category?.name,
          eachIdx.vendor && eachIdx.vendor[0]?.name,
          eachIdx?.inventory_location,
          eachIdx?.quantity_in_store,
          eachIdx?.available_stock,
          eachIdx?.reserved_quantity,
          eachIdx?.quantity_on_order,
          eachIdx?.image_field,
        ]
      }),
    [data]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      pagePerEntries: number = 100,
      item_type = ActiveItemType.all,
      low_inventory: number = 0,
      non_active_inventory: number = 0
    ) => {
      await inventoryData(page, str, pagePerEntries, item_type, low_inventory, non_active_inventory)
        .then((res) => {
          const inventoryItems = res?.data?.items
          setData(inventoryItems)
          setHeader({
            ...header,
            incomingItems: res?.data?.purchase_orders_count,
            totalStockValue: res?.data?.total_stock_value,
            inactiveItems: res?.data?.inactiveItemsCount,
            lowInventory: res?.data?.low_inventory_count,
          })

          seTotalRows(res?.data?.total_item_rows)
          const stateHeader = {
            payload: {subtitle: `${res?.data?.total_item_rows} Total Records`},
            type: HeaderAction.Update,
          }
          headerContext.dispatch(stateHeader)
        })
        .catch(() => {})
    },
    []
  )
  useEffect(() => {
    getList()
  }, [getList])
  return (
    <>
      <div
        className='flex-wrap fw-bold fs-6 pe-2'
        style={{
          alignItems: 'center',
        }}
      >
        <button
          style={{backgroundColor: 'lightgrey'}}
          className='btn-lightgrey btn h-35px w-35px'
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
            <KTSVG className='svg-icon-4 m-0' path='/media/icons/duotune/general/setting.svg' />
          </div>
        </button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <>
            <MenuItem onClick={handleExportData}>Export All Data</MenuItem>
            {HAS_InventoryEditCategory(currentUser) ? (
              <MenuItem
                onClick={() => {
                  navigate(`/${prefixCompany}/inventory/store/categories`)
                }}
              >
                Edit Inventory Categories
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => {
                navigate(`/${prefixCompany}/inventory/store/manufacturer`)
              }}
            >
              Edit Manufacturers
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/${prefixCompany}/inventory/store/shipping_methods`)
              }}
            >
              Edit Shipping Methods
            </MenuItem>
            <MenuItem
              onClick={() => {
                setItemSettings(true)
                handleClose()
              }}
            >
              Active/Inactive Settings
            </MenuItem>
          </>
        </Menu>
      </div>
      <Lifetime2 modalHandler={item} handleCloseModal={() => handleSubmitModal()} />
    </>
  )
}

export {InventorySettings}
