/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useCallback, useContext, useEffect, useRef, useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {CustomerModel} from '../../modules/model/customer'
import {currnetCompanyName, customerList} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {
  HAS_CustomerCreate,
  HAS_CustomerTable,
  HAS_CustomerView,
} from '../../pages/dashboard/PermissionFunction'
import {FormControl, InputLabel, Menu, MenuItem, Select} from '@mui/material'
import React from 'react'
import {MaterialDataTable} from './MatrialDataTable'
import {KTSVG} from '../../../_metronic/helpers'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {ExportToCsv} from 'export-to-csv'
import {useLocalStorage} from './GlobalComponents'
import blank from './../../../_metronic/layout/components/header/blank.png'
import Lifetime from './Lifetime'
// If you want to change the below data, you also have to change in backend also
const ActiveCustomerType = {
  all: 'All Customers',
  active: 'Active Customers',
  inactive: 'Inactive Customers',
}

const Customers = (props: any) => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [data, setData] = useState<CustomerModel[]>([])
  const [selectedClient, setSelectedClient] = useState('All Customers')
  const headerContext = useContext<any>(HeaderContext)
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalRows, seTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectClient, setSelectClient] = useState<string>('All Customers')
  const [tableStorage, setTableStorage] = useLocalStorage('customerColsVisibility', '')
  const [customerSettings, setCustomerSettings] = useState(false)
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])
  const ActiveCustomerType = {
    all: 'All',
    customers: 'All Customers',
    prospects: 'All Prospects',
    active: 'Active',
    inactive: 'Inactive',
  }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })

  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      customer_type = ActiveCustomerType.all,
      customer_status = ActiveCustomerType.all,
      pagePerEntries: number = 100
    ) => {
      setLoading(true)
      await customerList(page, str, customer_type, customer_status, pagePerEntries)
        .then((res) => {
          let employees = res.data.customers as CustomerModel[]
          if (employees.length === 0) {
            if (
              str !== '' ||
              customer_type !== 'All Customers' ||
              customer_status !== 'All Customers'
            )
              console.log('clear inside')
          }
          setData(employees)
          seTotalRows(res.data.totalRows)
          setLoading(false)
          const stateHeader = {
            payload: {subtitle: `${res.data.totalRows} Total Records`},
            type: HeaderAction.Update,
          }
          headerContext.dispatch(stateHeader)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )

  useEffect(() => {
    setLoading(true)
    getList(
      pagination.pageIndex,
      '',
      ActiveCustomerType.all,
      ActiveCustomerType.all,
      pagination.pageSize
    )
  }, [pagination])

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(
      getPagination?.pageIndex,
      '',
      ActiveCustomerType.all,
      ActiveCustomerType.all,
      getPagination?.pageSize
    )
  }, [])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Customers',
        subtitle: `Total Records`,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/customers/create-customer`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: true,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    getList()
    headerContext.dispatch(stateHeader)
  }, [getList])

  useEffect(() => {
    const search: any = searchParams.get('str')
    getList(0, search, _selectClient?.current, _selectedClient?.current)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: CustomerModel) => (
          <a href={row?.profile_pic !== null && row?.profile_pic} target='blank'>
            <img
              alt='customer logo'
              src={`${row?.profile_pic || '/media/avatars/blank.png'}`}
              onError={(evt: any) => {
                evt.target.src = blank
              }}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain', maxWidth: '100%'}}
              // style={{objectFit: 'contain'}}
            />
          </a>
        ),
        header: 'Customer Logo',
        size: 70, //decrease the width of this column
        accessorKey: 'customer_logo',
      },
      {
        accessorFn: (row: CustomerModel) => row.company,
        header: 'Business Name', //uses the default width from defaultColumn prop
        accessorKey: 'business_name',
      },
      {
        accessorFn: (row: CustomerModel) =>
          row.fields
            ? row.fields.map((item) => {
                return item?.primary === true ? item?.fName + ' ' + item?.lName : ''
              })
            : '',
        header: 'Contact Name',
        accessorKey: 'contact_name',
      },
      {
        header: 'Email Address',
        accessorFn: (row: CustomerModel) =>
          row.fields
            ? row.fields.map((item) => {
                return item?.primary === true ? item?.email : ''
              })
            : '',
        size: 200, //increase the width of this column
        accessorKey: 'email_address',
      },
      {
        accessorFn: (row: CustomerModel) =>
          row.fields
            ? row.fields.map((item) => {
                return item?.primary === true ? item?.phone : ''
              })
            : '',
        header: 'Phone Number',
        size: 120, //decrease the width of this column
        accessorKey: 'phone_number',
      },
      {
        accessorFn: (row: CustomerModel) => row.city,
        header: 'City',
        size: 100, //decrease the width of this column
        accessorKey: 'city',
      },
      {
        accessorFn: (row: CustomerModel) => row.state,
        header: 'State',
        size: 100, //decrease the width of this column
        accessorKey: 'state',
      },
    ],
    []
  )

  const CSV_HEADER = useMemo(
    () => [
      // 'Database Id',
      // 'Created By',
      'First Name',
      'Last Name',
      'Business Name',
      'Alternative Contact',
      'Email',
      'Contact Number',
      'Mailing Address Line 1',
      'Mailing Address Line 2',
      'Mailing City',
      'Mailing State',
      'Zip Code',
      'Billing Address Line 1',
      'Billing Address Line 2',
      'Billing City',
      'Billing State',
      'Billing Zip Code',
      'Customer Notes',
      // 'Contact Details',
      'Customer Image',
      'Website',
      // 'Type',
      // 'Contact Method',
      // 'Discount Type',
      'Referral Type',
      'Date Created',
      'Contact 1',
      'Contact 2',
      'Contact 3',
      'Contact 4',
      'Contact 5',
    ],
    [data]
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx) => {
        let contactDetails = JSON.parse(eachIdx?.fieldArr)
        return [
          // eachIdx.id,
          // eachIdx.user?.first_name + ' ' + eachIdx.user?.last_name,
          eachIdx.first_name,
          eachIdx.last_name,
          eachIdx.company,
          eachIdx.company_phone,
          eachIdx.email,
          eachIdx.phone,
          eachIdx.address,
          eachIdx.address_line_2,
          eachIdx.city,
          eachIdx.state,
          eachIdx.zip_code,
          eachIdx.billing_address_1,
          eachIdx.billing_address_2,
          eachIdx.billing_city,
          eachIdx.billing_state,
          eachIdx.billing_zip_code,
          eachIdx.notes,
          eachIdx.profile_pic,
          eachIdx.website,
          // eachIdx.cus_pros,
          // eachIdx.contact_method,
          // eachIdx.discount_type,
          eachIdx.referral_type,
          eachIdx.date_created,
          contactDetails?.length > 0
            ? contactDetails.map(
                (eachContact: any) =>
                  `Title: ${eachContact?.contactTitle || 'N/A'}- Name: ${
                    eachContact.fName || 'N/A'
                  } ${eachContact?.lName || 'N/A'}- Phone: ${eachContact.phone || 'N/A'}-Email: ${
                    eachContact.email || 'N/A'
                  }-Other: ${eachContact.companyphone || 'N/A'}`
              )
            : `Title: N/A- Name: ${eachIdx.first_name || 'N/A'} ${
                eachIdx?.last_name || 'N/A'
              }- Phone: ${eachIdx.phone || 'N/A'}-Email: ${eachIdx.email || 'N/A'}-Other: ${
                eachIdx.company_phone || 'N/A'
              }`,
        ]
      }),
    [data]
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  const _selectClient = useRef('All Customer')
  const _selectedClient = useRef('All Customer')
  const handleActives = useCallback((event: any) => {
    setSelectClient(event.target.value)
    getList(0, '', event.target.value, _selectedClient?.current)
  }, [])

  const handleActive = useCallback((event: any) => {
    setSelectedClient(event.target.value)
    getList(0, '', _selectClient?.current, event.target.value)
  }, [])

  useEffect(() => {
    _selectedClient.current = selectedClient
    _selectClient.current = selectClient
  }, [selectClient, selectedClient])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmitModal = () => {
    setCustomerSettings(false)
    getList()
  }
  return HAS_CustomerTable(currentUser) ? (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card mb-2'></div>
        </div>
      </div>
      <div className='sticky_header_data_table'>
        <MaterialDataTable
          MaterialColumns={MaterialColumns}
          stopRedirection={HAS_CustomerView(currentUser)}
          MaterialColumnsApiData={data}
          totalRows={totalRows}
          loadingBar={loading}
          tableHeight={700}
          navigationLink={'customers/view'}
          serverPagination={pagination}
          setServerPagination={(cb_data: any) => {
            return PaginationCallback(cb_data)
          }}
          columnVisibility={tableStorage}
          cbColumnVisibility={(callbackData) => {
            cbFunction(callbackData)
          }}
          optionalTableHeader={
            <div
              style={{gap: '10px'}}
              className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'
            >
              <h4 style={{marginLeft: '10px'}} className='fw-bolder my-5 w-115px'>
                <KTSVG path='/media/icons/duotune/general/gen063.svg' className='svg-icon-4 me-3' />
                Customers
              </h4>

              <div className='god-father_1'>
                <div style={{width: '33%'}} className='c-width100'>
                  <UsersListSearchComponent />{' '}
                </div>
                <FormControl style={{width: '33%'}} className='customer-filter h-35 c-width100'>
                  <InputLabel id='all-customers'>Customer Filter</InputLabel>
                  <Select
                    style={{height: '35px'}}
                    labelId='all-customers'
                    value={selectClient}
                    label='Customer Filter'
                    onChange={handleActives}
                  >
                    <MenuItem value='All Customers'>All</MenuItem>
                    <MenuItem value='Customer'>Customers</MenuItem>
                    <MenuItem value='Prospect'>Prospects</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{width: '33%'}} className='customer-filter h-35 c-width100'>
                  <InputLabel id='all-customer'>Customer Filter</InputLabel>
                  <Select
                    style={{height: '35px'}}
                    labelId='all-customer'
                    id='demo-simple-select'
                    value={selectedClient}
                    label='Customer Filter'
                    onChange={handleActive}
                  >
                    <MenuItem value='All Customers'>All</MenuItem>
                    <MenuItem value='Active Customers'>Active</MenuItem>
                    <MenuItem value='Inactive Customers'>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className='mui-icons'>
                <div
                  className='flex-wrap fw-bold fs-6 m-1'
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <button
                    style={{backgroundColor: 'lightgrey'}}
                    className='btn-lightgrey btn h-35px w-35px'
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                      <KTSVG
                        className='svg-icon-4 m-0'
                        path='/media/icons/duotune/general/setting.svg'
                      />
                    </div>
                  </button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <>
                      <MenuItem
                        onClick={() => {
                          handleExportData()
                          handleClose()
                        }}
                      >
                        <span>Export All Data</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCustomerSettings(true)
                          handleClose()
                        }}
                      >
                        <span>Active / Inactive Settings</span>
                      </MenuItem>
                    </>
                  </Menu>
                </div>

                <div className='m-1'>
                  {HAS_CustomerCreate(currentUser) ? (
                    <button
                      className='btn btn-primary h-35px w-35px fw-bold'
                      onClick={() => navigate(`/${prefixCompany}/customers/create-customer`)}
                    >
                      <div style={{marginTop: '-10px', marginLeft: '-6px', fontSize: '20px'}}>
                        +
                      </div>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
      <Lifetime modalHandler={customerSettings} handleCloseModal={() => handleSubmitModal()} />
    </div>
  ) : (
    <></>
  )
}

export {Customers}
