/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string,
  title: string,
  subtitle: string,
  description: string
  pic: string
}

const FeedsWidget2: React.FC<Props> = ({className, title, subtitle, description, pic}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={pic} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                { title }
              </a>

              <span className='text-gray-400 fw-semibold'>{subtitle}</span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Text */}
          <p className='text-gray-800 fw-normal mb-5'>
            {description}
          </p>
          {/* end::Text */}

       
        </div>
        {/* end::Post */}
      
        {/* edit::Reply input */}
      </div>
      {/* end::Body */}
    </div>
  )
}
FeedsWidget2.defaultProps = {
  title: "Title",
  subtitle:"Subtitle",
  description:"Description",
  pic:toAbsoluteUrl('/media/logos/prosper_portal_favicon.png'),
  // currentUser?.pic
}
export {FeedsWidget2}
