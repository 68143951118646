/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
// / eslint-disable jsx-a11y/anchor-is-valid /
import {useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react'
import {FC} from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import {HeaderAction} from '../../context/HeaderContext'
import {HeaderContext} from '../../context/HeaderContext'
import {Button} from '@mui/material'
import Modal from 'react-bootstrap/Modal'
import {ColorPicker} from 'material-ui-color'
import {useFormik} from 'formik'
import {
  darkErrorMessage,
  quoteStatusAdd,
  quoteStatusDelete,
  quoteStatusfetch,
  quoteStatusUpdate,
  updateStatusSorting,
} from '../../modules/auth/core/_requests'
import {LinearProgress} from '@mui/material'
import {MaterialDataTable} from './MatrialDataTable'
import {ExportToCsv} from 'export-to-csv'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {useLocalStorage} from './GlobalComponents'
import {DEFAULT_STATUS} from '../../context/TimeSheetConstant'
import DeleteModal from './DeleteModal'

type Props = {
  getList: () => void
  type: string
  closeModal: () => void
  heading: string
  title: string
}

const CustomStatus = ({getList, type, closeModal, heading, title}: Props) => {
  const headerContext = useContext<any>(HeaderContext)
  const [show, setShow] = useState(false)
  const [editShow, setEditShow] = useState(false)
  const [roweditFormData, setEditFormData] = useState<any>({})
  const [colors, setColors] = useState(null)
  const [bgColors, setBgColors] = useState(null)
  const [addShow, setAddShow] = useState(false)
  const [statusformData, setStatusFormData] = useState<any>({})
  const [addColors, setaddColors] = useState(null)
  const [addBgColors, setAddBgColors] = useState(null)
  const [loading, setLoading] = useState(false)
  const [delId, setDelId] = useState<any>(null)
  const [viewStyle, setViewStyle] = useState<object | any>({title: '', bgcolor: '', colors: ''})
  const [quotetableStorage, setQuoteTableStorage] = useLocalStorage('quoteStatusColsVisibility', '')
  const [workordertableStorage, setWorkOrderTableStorage] = useLocalStorage(
    'workOrderStatusColsVisibility',
    ''
  )
  const [selectedQuotesFilter, setSelectedQuotesFilter] = useState([])

  const [fieldServicetableStorage, setFieldServiceTableStorage] = useLocalStorage(
    'fieldServiceStatusColsVisibility',
    ''
  )
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [data, setData] = useState({
    quoteStatus: [],
    workorderStatus: [],
    fieldServiceStatus: [],
    quoteStatusCount: 0,
    workorderStatusCount: 0,
    fieldServiceStatusCount: 0,
  })

  const cbQuoteFunction = useCallback((columnScope: {}) => {
    setQuoteTableStorage(columnScope)
  }, [])
  const cbWorkOrderFunction = useCallback((columnScope: {}) => {
    setWorkOrderTableStorage(columnScope)
  }, [])
  const cbFieldServiceFunction = useCallback((columnScope: {}) => {
    setFieldServiceTableStorage(columnScope)
  }, [])

  const cbDragAbleQuote = useCallback((draggable: any) => {
    const option_length = draggable.length
    let newArray: any[] = []
    option_length > 0 &&
      draggable.map((eachOrder: {id: number}, idx: number) => {
        return newArray.push({id: idx, status: eachOrder?.id})
      })

    updateStatusSorting(JSON.stringify(newArray))
      .then((res) => {
        fetchQuoteStatus()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [])

  const cbDragAbleWorkOrder = useCallback((draggable: any) => {
    const option_length = draggable.length
    let newArray: any[] = []
    option_length > 0 &&
      draggable.map((eachOrder: {id: number}, idx: number) => {
        return newArray.push({id: idx, status: eachOrder?.id})
      })

    updateStatusSorting(JSON.stringify(newArray))
      .then((res) => {
        fetchQuoteStatus()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [])
  const cbDragAbleFieldService = useCallback((draggable: any) => {
    const option_length = draggable.length
    let newArray: any[] = []
    option_length > 0 &&
      draggable.map((eachOrder: {id: number}, idx: number) => {
        return newArray.push({id: idx, status: eachOrder?.id})
      })

    updateStatusSorting(JSON.stringify(newArray))
      .then((res) => {
        fetchQuoteStatus()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [])

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: false,
        title: 'Masquerade',
        subtitle: ' ',
        navigation_url: ``,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
    fetchQuoteStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //select_value for dropdown in Type formik
  const select_value = [
    {value: 'Quote', label: 'Quote'},
    {value: 'Project', label: 'Project'},
    {value: 'Field Service', label: 'Field Service'},
  ]

  function findArrayElementByTitle(title: any) {
    if (DEFAULT_STATUS.includes(title)) {
      return true
    } else {
      return false
    }
  }

  const columns: any = [
    {
      header: 'Title',
      accessorFn: (row: any) => (
        <div
          style={{
            backgroundColor: row.title ? row.bgcolor : '',
            color: row.title ? row.color : '',
            padding: '10px',
            overflow: 'visible',
          }}
        >
          {row.title}
        </div>
      ),
      accessorKey: 'title',

      sortable: true,
    },
    {
      header: 'Background Color',
      accessorKey: 'bg_Color',

      accessorFn: (row: any) => <div>{row.bgcolor}</div>,
    },
    {
      header: 'Text Color',
      accessorKey: 'text_Color',

      accessorFn: (row: any) => <div>{row.color}</div>,
    },
    {
      header: 'Update Status',
      accessorKey: 'update_Status',

      accessorFn: (row: any, id: any) => (
        <>
          <Button
            style={{padding: 'calc(0.7rem + 1px) calc(0.7rem + 1px)'}}
            variant='contained'
            className='btn btn-secondary px-5'
            onClick={() => EditFormhandler(row, id)}
          >
            Update Status
          </Button>
        </>
      ),
    },
    {
      header: 'Delete Status',
      accessorKey: 'delete_Status',
      accessorFn: (row: any, id: any) => (
        <>
          {!findArrayElementByTitle(row.title) && (
            <Button
              style={{padding: 'calc(0.7rem + 1px) calc(0.7rem + 1px)', marginLeft: '2px'}}
              onClick={() => handleShow(row, id)}
              variant='contained'
              className='btn btn-secondary px-5'
            >
              Delete Status
            </Button>
          )}
        </>
      ),
    },
  ]

  const handleClose = () => setShow(false)

  //Show Delete Modal
  const handleShow = (row: any, id: any) => {
    setShow(true)
    setDelId(row?.id)
    setEditFormData(row)
  }
  // When User click on the Yes button record Delete from state
  const deleteHandler = useCallback(() => {
    setLoading(true)
    quoteStatusDelete(delId)
      .then((res) => {
        if (res.data.data) {
          setShow(false)
          fetchQuoteStatus()
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
      })
  }, [delId])
  // updateStatusSorting
  //Edit status Form Modal show and set data into the state
  const EditFormhandler = (row: any, id: any) => {
    setEditShow(true)
    {
      formik.setFieldValue('title', row.title)
      formik.setFieldValue('bgcolor', row.bgcolor)
      formik.setFieldValue('textcolor', row.color)
      formik.setFieldValue('type', row.status_type)
    }
    if (row.bgcolor) {
      setBgColors(row.bgcolor)
    } else {
      setBgColors(null)
    } //color save in bgColors state
    if (row.color) {
      setColors(row.color)
    } else {
      setColors(null)
    } //color save in colors state
    setEditFormData(row)
    setViewStyle({title: row.title, bgcolor: row.bgcolor, colors: row.color})
    setDelId(row.id)
  }

  const editFormCloseHandler = () => setEditShow(false)

  // Formik Edit Form Start and all function
  const initialValues = {
    title: '',
    bgcolor: '',
    textcolor: '',
    type: '',
  }
  //Edit form submit functionality
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setLoading(true)
      setEditFormData(values)
      quoteStatusUpdate(values, delId)
        .then((res) => {
          if (res.data.data) {
            setEditShow(false)
            fetchQuoteStatus()
            setLoading(false)
          }
        })
        .catch((e) => {
          console.log('e', e)
          setLoading(false)
        })
      setViewStyle({})
    },
  })

  //color store in TextColor formik
  const handleChangeColor = (color: any) => {
    setColors(color.css.backgroundColor)
    setViewStyle({...viewStyle, colors: color.css.backgroundColor})
    {
      formik.setFieldValue('textcolor', color.css.backgroundColor)
    }
  }
  //color store in BgColor formik
  const handleBgColor = (color: any) => {
    setBgColors(color.css.backgroundColor)
    setViewStyle({...viewStyle, bgcolor: color.css.backgroundColor})
    {
      formik.setFieldValue('bgcolor', color.css.backgroundColor)
    }
  }
  // Formik Edit Form end:-

  // fetch status data store into FormArray
  const fetchQuoteStatus = useCallback(() => {
    setLoading(true)
    quoteStatusfetch()
      .then((res) => {
        setData({
          quoteStatus: res.data.quote_status_modal,
          workorderStatus: res.data.workorder_status_modal,
          fieldServiceStatus: res.data.fieldService_status_modal,
          quoteStatusCount: res?.data?.quote_status_filter_count,
          workorderStatusCount: res?.data?.workorder_status_filter_count,
          fieldServiceStatusCount: res?.data?.fieldService_status_filter_count,
        })
        getList()
        setLoading(false)
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
      })
  }, [])

  //Add status Formik form start
  const addformik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setLoading(true)
      setStatusFormData(values)
      setStatusFormData({})
      setAddShow(false)
      quoteStatusAdd(values)
        .then((res) => {
          if (res.data.data) {
            fetchQuoteStatus()
            setLoading(false)
          }
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data
            darkErrorMessage(message?.error_list[0])
          }
          console.log('e', e)
          setLoading(false)
        })
    },
  })

  //color store in TextColor addformik
  const addChangeColor = (color: any) => {
    setaddColors(color.css.backgroundColor)
    setViewStyle({...viewStyle, colors: color.css.backgroundColor})
    {
      addformik.setFieldValue('textcolor', color.css.backgroundColor)
    }
  }
  //color store in BgColor addformik
  const addBgColor = (color: any) => {
    setAddBgColors(color.css.backgroundColor)
    setViewStyle({...viewStyle, bgcolor: color.css.backgroundColor})
    {
      addformik.setFieldValue('bgcolor', color.css.backgroundColor)
    }
  }

  // Add status form function:-
  const handleAddStatus = () => {
    setAddShow(true)
    setStatusFormData({})
    {
      addformik.setFieldValue('title', '')
    }
    {
      addformik.setFieldValue('bgcolor', '')
    }
    {
      addformik.setFieldValue('textcolor', '')
    }
    {
      addformik.setFieldValue('type', '')
    }
    setaddColors(null)
    setAddBgColors(null)
    setViewStyle({})
  }

  const PaginationCallback = useCallback((getPagination: any) => {
    quoteStatusfetch()
  }, [])

  const addStatusClose = () => setAddShow(false)

  const setTitle = (e: any) => {
    setViewStyle({...viewStyle, title: e.target.value})
    {
      addformik.setFieldValue('title', e.target.value)
    }
    {
      formik.setFieldValue('title', e.target.value)
    }
  }

  const CSV_HEADER = useMemo(() => ['Title', 'Text Color', 'Background Color', 'Created By'], [])

  const CSV_QUOTE_DATA = useMemo(
    () =>
      data?.quoteStatus.map((eachIdx: any) => {
        return [
          eachIdx?.title,
          eachIdx?.color,
          eachIdx?.bgcolor,
          eachIdx?.user?.first_name + ' ' + eachIdx?.user?.last_name,
        ]
      }),
    [data]
  )

  const CSV_WORK_ORDER_DATA = useMemo(
    () =>
      data?.workorderStatus.map((eachIdx: any) => {
        return [
          eachIdx?.title,
          eachIdx?.color,
          eachIdx?.bgcolor,
          eachIdx?.user?.first_name + ' ' + eachIdx?.user?.last_name,
        ]
      }),
    [data]
  )

  const CSV_FIELD_SERVICE_DATA = useMemo(
    () =>
      data?.fieldServiceStatus.map((eachIdx: any) => {
        return [
          eachIdx?.title,
          eachIdx?.color,
          eachIdx?.bgcolor,
          eachIdx?.user?.first_name + ' ' + eachIdx?.user?.last_name,
        ]
      }),
    [data]
  )
  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporterQuote = new ExportToCsv(csvOptions[0])
  const csvExporterWorkOrder = new ExportToCsv(csvOptions[0])
  const csvExporterFieldService = new ExportToCsv(csvOptions[0])

  const handleExportQuoteData = useCallback(() => {
    csvExporterQuote.generateCsv(CSV_QUOTE_DATA)
  }, [CSV_QUOTE_DATA])

  const handleExportWorkOrderData = useCallback(() => {
    csvExporterWorkOrder.generateCsv(CSV_WORK_ORDER_DATA)
  }, [CSV_WORK_ORDER_DATA])

  const handleExportFieldServiceData = useCallback(() => {
    csvExporterFieldService.generateCsv(CSV_FIELD_SERVICE_DATA)
  }, [CSV_FIELD_SERVICE_DATA])

  const newLocal = (
    <div className='m-2'>
      <button className='btn btn-primary' onClick={handleAddStatus} style={{padding: '7px 20px'}}>
        {title}{' '}
      </button>
    </div>
  )
  return (
    <>
      {loading && <LinearProgress />}
      <div className='card'>
        <div className='card-body border-bottom p-5'>
          <div className='d-flex justify-content-between'>
            <h1 className='fw-bolder m-auto mx-2'>{heading}</h1>
            <button className='btn btn-dark' onClick={closeModal} style={{padding: '7px 20px'}}>
              X
            </button>
          </div>
        </div>
        <div className='col-md-12' style={{height: '700px', overflow: 'scroll'}}>
          {type === 'quotes' ? (
            <div className=''>
              <MaterialDataTable
                MaterialColumns={columns}
                MaterialColumnsApiData={data?.quoteStatus}
                totalRows={data?.quoteStatusCount}
                loadingBar={loading}
                navigationLink={'settings/custom-status'}
                allowDragAbleRows={true}
                serverPagination={pagination}
                setServerPagination={(cb_data: any) => {
                  return PaginationCallback(cb_data)
                }}
                DragAbleRowsData={(cbDragData) => {
                  cbDragAbleQuote(cbDragData)
                }}
                columnVisibility={quotetableStorage}
                cbColumnVisibility={(callbackData) => {
                  cbQuoteFunction(callbackData)
                }}
                stopRedirection={false}
                optionalTableHeader={
                  <div className='card-title m-0 d-flex d-inline align-items-center'>
                    {newLocal}
                    <div className='m-2'>
                      <Button
                        className='btn btn-secondary h-35px'
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportQuoteData}
                        startIcon={<FileDownloadIcon />}
                        variant='contained'
                      >
                        Export All Data
                      </Button>
                    </div>
                  </div>
                }
                tableHeight={1000}
              />
            </div>
          ) : type === 'projects' ? (
            <div className=''>
              <MaterialDataTable
                MaterialColumns={columns}
                MaterialColumnsApiData={data?.workorderStatus}
                totalRows={data?.workorderStatusCount}
                loadingBar={loading}
                navigationLink={'settings/custom-status'}
                serverPagination={pagination}
                allowDragAbleRows={true}
                DragAbleRowsData={(cbDragData) => {
                  cbDragAbleWorkOrder(cbDragData)
                }}
                setServerPagination={(cb_data: any) => {
                  return PaginationCallback(cb_data)
                }}
                columnVisibility={workordertableStorage}
                cbColumnVisibility={(callbackData) => {
                  cbWorkOrderFunction(callbackData)
                }}
                stopRedirection={false}
                optionalTableHeader={
                  <div className='card-title m-1000 d-flex d-inline align-items-center'>
                    {newLocal}
                    <div className='m-2'>
                      <Button
                        className='btn btn-secondary h-35px'
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportWorkOrderData}
                        startIcon={<FileDownloadIcon />}
                        variant='contained'
                      >
                        Export All Data
                      </Button>
                    </div>
                  </div>
                }
                tableHeight={1000}
              />
            </div>
          ) : type === 'field service' ? (
            <div className=''>
              <MaterialDataTable
                MaterialColumns={columns}
                MaterialColumnsApiData={data?.fieldServiceStatus}
                totalRows={data?.fieldServiceStatusCount}
                loadingBar={loading}
                allowDragAbleRows={true}
                navigationLink={'settings/custom-status'}
                serverPagination={pagination}
                setServerPagination={(cb_data: any) => {
                  return PaginationCallback(cb_data)
                }}
                columnVisibility={fieldServicetableStorage}
                cbColumnVisibility={(callbackData) => {
                  cbFieldServiceFunction(callbackData)
                }}
                stopRedirection={false}
                DragAbleRowsData={(cbDragData) => {
                  cbDragAbleFieldService(cbDragData)
                }}
                optionalTableHeader={
                  <div className='card-title m-0 d-flex d-inline align-items-center'>
                    {newLocal}
                    <div className='m-2'>
                      <Button
                        className='btn btn-secondary h-35px'
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportFieldServiceData}
                        startIcon={<FileDownloadIcon />}
                        variant='contained'
                      >
                        Export All Data
                      </Button>
                    </div>
                  </div>
                }
                tableHeight={1000}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <DeleteModal
        modalShow={show}
        title={'Confirmation Message'}
        body={
          'Did you really want to delete this Order Status? (This may effect your Quote , Project and Field Service Data)'
        }
        handleClose={() => handleClose()}
        confirmFunction={() => deleteHandler()}
      />

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={editShow}
        size='lg'
        centered
        onHide={editFormCloseHandler}
        className='fade'
      >
        <div
          className='p-4 lh-base m-0 text-center py-7'
          style={{fontSize: '28px', fontWeight: 'bold', textAlign: 'center'}}
        >
          Update Order Status
        </div>
        <div className='card mb-5 mb-xl-10'>
          <form onSubmit={formik.handleSubmit}>
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>
                <div className='row mb-10'>
                  <div
                    className='col fv-row text-center p-3'
                    style={{
                      backgroundColor: viewStyle ? viewStyle.bgcolor : '',
                      color: viewStyle ? viewStyle.colors : '',
                      fontSize: '20px',
                    }}
                  >
                    {viewStyle.title}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Enter Title
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      readOnly={findArrayElementByTitle(viewStyle.title)}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Enter Title'
                      value={viewStyle.title}
                      onChange={(e) => setTitle(e)}
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Colors</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row fs-6'>
                        <label>BackGround Color</label>
                        <ColorPicker value={bgColors ? bgColors : ''} onChange={handleBgColor} />
                      </div>
                      <div className='col-lg-6 fv-row fs-6'>
                        <label>Text Color</label>
                        <ColorPicker value={colors ? colors : ''} onChange={handleChangeColor} />
                      </div>
                    </div>
                  </div>
                </div>
                {!findArrayElementByTitle(viewStyle.title) && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Status Type
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps('refer')}
                        name='refer'
                        id=''
                        value={roweditFormData ? roweditFormData.status_type : ''}
                        onChange={(e) => {
                          formik.setFieldValue('type', e.target.value)
                          roweditFormData.status_type = e.target.value
                        }}
                      >
                        <option disabled hidden selected>
                          {'-------------'}
                        </option>
                        {select_value.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex flex-end' style={{paddingRight: '10px'}}>
                <button type='button' onClick={editFormCloseHandler} className='mx-5 btn btn-dark'>
                  Discard
                </button>
                <button type='submit' className=' mx-5 text-center btn btn-primary'>
                  Update Status
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={addShow}
        size='lg'
        centered
        onHide={addStatusClose}
        className='fade'
      >
        <p className='py-6 lh-base m-0 text-center' style={{fontSize: '30px', fontWeight: 'bold'}}>
          Add Custom Status
        </p>
        <div className='card mb-5 mb-xl-10'>
          <form onSubmit={addformik.handleSubmit}>
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>
                <div className='row mb-10'>
                  <div
                    className='col fv-row text-center p-3'
                    style={{
                      backgroundColor: viewStyle ? viewStyle.bgcolor : '',
                      color: viewStyle ? viewStyle.colors : '',
                      fontSize: '20px',
                    }}
                  >
                    {/* // className='form-control form-control-lg form-control-solid' */}
                    {viewStyle.title}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Enter Title
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Enter Title'
                      onChange={(e) => setTitle(e)}
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Colors</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row fs-6'>
                        <label style={{paddingLeft: '5px'}}>Background Color</label>
                        <ColorPicker value={addBgColors ? addBgColors : ''} onChange={addBgColor} />
                      </div>

                      <div className='col-lg-6 fv-row fs-6'>
                        <label style={{paddingLeft: '5px'}}>Text Color</label>
                        <ColorPicker value={addColors ? addColors : ''} onChange={addChangeColor} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Quote/Projects/Field Service
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <select
                      className='form-control form-control-lg form-control-solid'
                      {...formik.getFieldProps('refer')}
                      name='refer'
                      id=''
                      value={statusformData ? statusformData.type : ''}
                      onChange={(e) => {
                        addformik.setFieldValue('type', e.target.value)
                        statusformData.type = e.target.value
                      }}
                    >
                      {' '}
                      <option disabled hidden selected>
                        Choose One (Quote/Project/Field Service)
                      </option>
                      {select_value.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-end' style={{marginRight: '13px'}}>
                <button type='reset' onClick={addStatusClose} className='btn btn-dark mx-5'>
                  Discard
                </button>
                <button type='submit' className='btn btn-primary mx-5 text-center'>
                  Add Status
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default CustomStatus
