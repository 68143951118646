/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {viewQuote} from '../../../modules/auth/core/_requests'
import {QuotesModel} from '../../../modules/model/quotes'
import {useParams} from 'react-router-dom'
import {UpdateQuoteModal} from '../UpdateQuoteModal'
import {Modal} from 'react-bootstrap'
import {TimeSheetTextConstant} from '../../../context/TimeSheetConstant'

export function Projects() {
  const [data, setData] = useState<any>([])
  const {type, id} = useParams<any>()
  const [updateQuoteModal, setUpdateQuoteModal] = useState({
    show: false,
    data: '',
    type: 'text',
  })
  const TIMESHEET_TEXT =
    type === TimeSheetTextConstant.QUOTE
      ? TimeSheetTextConstant.QUOTE
      : TimeSheetTextConstant.WORKORDER

  const getList = useCallback(async () => {
    await viewQuote(id)
      .then((res) => {
        let fields = res.data.customer_details as QuotesModel[]
        setData(fields)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [id])

  useEffect(() => {
    getList()
  }, [getList])

  const onCloseUpdateQuoteModal = useCallback(() => {
    getList()
    setUpdateQuoteModal({
      show: false,
      data: '',
      type: 'text',
    })
  }, [])

  return (
    <>
      <div className='row g-6 g-xl-9'>
        <div className='col-xl-12'>
          <div className='card mb-5 pe-5 ps-5 pt-2 pb-2'>
            <Modal
              style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
              show={updateQuoteModal?.show}
              onHide={() => {
                onCloseUpdateQuoteModal()
              }}
              size='lg'
              centered
              // className='bg-opacity-1 bg-black'
            >
              <Modal.Header>
                <h1>Update {TIMESHEET_TEXT}</h1>
              </Modal.Header>
              <UpdateQuoteModal
                type={updateQuoteModal.type}
                onCloseUpdateQuoteModal={onCloseUpdateQuoteModal}
              />
            </Modal>
            <div
              className='card-header border-0 cursor-pointer m-0 project-desc-head'
              style={{height: '40px'}}
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='d-flex align-items-lg-center '>
                <h3 className='fw-bolder m-0'>Project Description</h3>{' '}
                <i
                  className='bi bi-pencil-square mx-2 cursor-pointer'
                  style={{color: 'black'}}
                  onClick={() =>
                    setUpdateQuoteModal({
                      show: true,
                      data: data,
                      type: 'text',
                    })
                  }
                ></i>
                {/* dynamic content to give quote id is pending here  */}
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              {/* maxHeight: '450px', */}
              <form noValidate className='form'>
                <div
                  className='card-body border-top pb-0 pe-10 ps-10 pt-0 projects-dec'
                  style={{
                    maxHeight: '400px',
                    minHeight: '30px',
                    overflow: 'scroll',
                    marginLeft: '-30px',
                  }}
                  dangerouslySetInnerHTML={{__html:data?.fields === ''
                  ? data?.description
                  : data?.fields?.description || data?.description,}}
                ></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
