/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {currnetCompanyName} from '../../modules/auth/core/_requests'
import {WeeklyTimeCard} from './WeeklyTimeCard'
import { WeeklyTimeCardV2 } from './WeeklyTimeCardV2'
const TimeCardPage = (props: any) => {
  const headerContext = useContext<any>(HeaderContext)
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const timesheet_type = 0

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'My Timesheet',
        subtitle: `-`,
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  return (
    <>
      <WeeklyTimeCardV2 timesheet_type={timesheet_type} />
    </>
  )
}

export {TimeCardPage}
