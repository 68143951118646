/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useCallback, useEffect, useContext} from 'react'
import {TextField, Button} from '@mui/material'
import {useParams, useLocation} from 'react-router-dom'
import {
  subQuote,
  darkMessage,
  darkErrorMessage,
  GetSubQuotes,
  viewQuote,
} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'

const SubQuote = ({
  setModalOpen,
  getList,
  newSub,
  setNewSub,
  updateQuantity,
  setRowData,
  defaultMarkupUnit,
  defaultMarkup,
}: any) => {
  const [quoteId, setQuoteId] = useState<any>([])
  const {type, id}: any = useParams<any>()
  const [formData, setFormData] = useState({
    label: '',
    description: '',
  })

  const [formErrors, setFormErrors] = useState({})

  const handleInputChange = (event: {target: {name: any; value: any}}) => {
    const {name, value} = event.target
    setFormData({...formData, [name]: value})
    setFormErrors({...formErrors, [name]: ''})
  }

  let quo_id: any
  let newlabel
  // const getLists = useCallback(async () => {
  //   await viewQuote(id).then((res) => {
  //     quo_id = res.data.customer_details as QuotesModel

  //     setQuoteId(quo_id.id)

  //   })

  //   await GetSubQuotes(quo_id.id || '')
  //     .then(({data}) => {
  //       const labelPrefix = `${quo_id.id}-`
  //       const existingQuotesCount = data?.data.length
  //       const lastDigit =
  //         existingQuotesCount > 0 ? Number(existingQuotesCount.toString().slice(-1)) : 1

  //       let newlabel = `${labelPrefix}${lastDigit}`
  //       let increment = 0
  //       while (data.data.some((item) => item.label === newlabel)) {
  //         increment++
  //         newlabel = `${labelPrefix}${lastDigit + increment}`
  //       }
  //       setNewSub(newlabel)
  //     })

  //     .catch(() => {})
  // }, [id])

  const getLists = useCallback(async () => {
    await viewQuote(id).then((res) => {
      quo_id = res.data.customer_details as QuotesModel
      console.log(quo_id)
      setQuoteId(quo_id.id)
    })

    await GetSubQuotes(quo_id.id || '')
      .then(({data}) => {
        const taxVal = data?.data.map((item: any) => item.tax)
        const SUB_QUOTE_DATA = data?.data
        console.log(data?.data)

        const labelPrefix = `${quo_id.label}-`
        let existingQuotesCount = data?.data.length

        // Check if the new label matches any existing labels in the data.data array
        let newlabel = `${labelPrefix}${existingQuotesCount + 1}`
        while (data.data.some((item) => item.label === newlabel)) {
          existingQuotesCount++
          newlabel = `${labelPrefix}${existingQuotesCount + 1}`
        }

        setNewSub(newlabel)
        console.log({existingQuotesCount, newlabel})
      })
      .catch(() => {})
  }, [id])
  useEffect(() => {
    const slist = getList().length
  }, [])
  const handleSubmit = (event: {preventDefault: () => void}) => {
    event.preventDefault()
    const params = []
    const modalData = {
      label: newSub,
      quote_id: quoteId,
      description: formData?.description,
    }
    params.push(modalData)
    subQuote(params)
      .then(({data}) => {
        darkMessage(data.message)
        setModalOpen(false)
        getList()
        setRowData(data.result)
        updateQuantity(data.result)
        defaultMarkupUnit(data?.result)
        defaultMarkup(data.result)
      })
      .catch((e: {response: {status: number; data: {error_list: any[]}}}) => {
        if (e?.response?.status === 409) {
          const message = e?.response?.data?.error_list[0]
          darkErrorMessage(message)
        } else {
          console.error(e)
        }
      })
  }
  useEffect(() => {
    getLists()
  }, [getLists])
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name='description'
        label='Quote Description'
        placeholder='Enter Quote description'
        value={formData.description}
        onChange={handleInputChange}
        multiline
        maxRows={1}
        fullWidth
      />

      <button
        type='submit'
        className='btn btn-primary'
        style={{marginLeft: '80%', marginTop: '2%'}}
      >
        Submit
      </button>
    </form>
  )
}

export {SubQuote}
