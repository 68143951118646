export const TimeSheetConstant = {
  QUOTE: '0',
  JOB: '1',
  WORKORDER: '2',
  FIELDSERVICE: '3',
}
export const TimeSheetTextConstant: any = {
  QUOTE: 'quote',
  JOB: 'job',
  WORKORDER: 'project',
  FIELDSERVICE: 'fieldservice',
}

export const TimeSheetInitialConstant: any = {
  QUOTE: 'Q-',
  WORKORDER: 'P-',
  FIELDSERVICE: 'FS-',
}

export const CONST_WORK_ORDER_CREATED = 'Project Created'
export const CONST_FILED_SERVICE_CREATED = 'Field Service Created'
export const CONST_COMPLETE_WORK_ORDER = 'Project Completed'
export const CONST_COMPLETE_FIELD_SERVICE = 'Field Service Completed'
export const MomentTimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const MomentDateFormat = 'YYYY-MM-DD'
export const CustomMomentDateFormat = 'HH:mm:ss'

export const NEW_QUOTE_STATUS = 'New'
export const TASK_AWAITING_WORK = 'Awaiting Work'
export const TASK_IN_PROGRESS = 'In Progress'
export const TASK_COMPLETED = 'Completed'
export const TASK_ACCEPTED = 'Accepted'
export const TASK_REJECTED = 'Rejected'
export const TASK_NEEDS_UPDATES = 'Need Updates'
export const TEMP_TimeSheetConstant = {
  QUOTE_INTIAL: '0',
  WORKORDER_INITIAL: '1',
  FIELD_SERVICE_INITIAL: '2',
  SHOP_TIME_INITIAL: '3',
}

export const DEFAULT_STATUS = [
  NEW_QUOTE_STATUS,
  TASK_ACCEPTED,
  TASK_REJECTED,
  CONST_WORK_ORDER_CREATED,
  CONST_FILED_SERVICE_CREATED,
  CONST_COMPLETE_WORK_ORDER,
  CONST_COMPLETE_FIELD_SERVICE,
]
