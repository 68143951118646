import React, {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {Create_Customer_Form_Initial as initialValues} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {Formik, FieldArray, Field, Form, ErrorMessage} from 'formik'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  createCustomer,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  profileImg,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {CustomerModel} from '../../modules/model/customer'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {HAS_CustomerCreate, HAS_CustomerView} from './PermissionFunction'
import {Box, LinearProgress} from '@mui/material'
import {onKeyDown} from './GlobalComponents'

const profileDetailsSchema = Yup.object().shape({
  company: Yup.string().required('Business name is required'),
  website: Yup.string().url('Please enter a valid URL starting with http:// or https://'),
  fieldArrays: Yup.array().of(
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^(\(\d{3}\)\s*|\d{3}-)\d{3}-\d{4}$/, 'Phone number must be exactly 10 digits')

        .required('Phone number is required'),
      companyphone: Yup.string().matches(
        /^(\(\d{3}\)\s*|\d{3}-)\d{3}-\d{4}$/,
        'Alternative contact must be exactly 10 digits'
      ),
    })
  ),
})

const CustomerForm: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [uploadLoading, setUploadLoading] = useState(false)
  const [checker, setChecker] = useState(false)

  const headerContext = useContext<any>(HeaderContext)
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Customer',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/customers`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [headerContext, prefixCompany])

  const uploadProfileImg = (event: any, setFieldValue: any) => {
    const user_img = event.target.files
    try {
      let filesToUpload: any = []
      let lengthOfFiles = Object.keys(user_img).length
      if (user_img != null || lengthOfFiles > 0) {
        let img_kb = user_img[0].size / 1024
        if (img_kb > 1024 * 2) {
          setErrorBody({
            error: true,
            error_list: [
              `
             Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
                user_img[0]?.size /
                1024 /
                1024
              ).toFixed(2)}MB
            `,
            ],
          })
          darkErrorMessage(`
          Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
            user_img[0]?.size /
            1024 /
            1024
          ).toFixed(2)}MB
         `)
          return
        }
        Object.values(user_img).map((value: any) => {
          return filesToUpload.push(value)
        })
      } else {
        console.error('file is not selected')
      }
      setUploadLoading(true)
      profileImg(filesToUpload, prefixCompany)
        .then((res) => {
          setFieldValue('profile_pic', res?.data?.user_pic)
          setUploadLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setUploadLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any, index: any, setFieldValue: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setFieldValue(`fieldArrays.${index}.phone`, formattedPhoneNumber)
  }
  const handleAlternativeInput = (e: any, index: any, setFieldValue: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setFieldValue(`fieldArrays.${index}.companyphone`, formattedPhoneNumber)
  }
  return HAS_CustomerView(currentUser) ? (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CREATE_CUSTOMER'})} </PageTitle>
      <div className='card mb-5 mb-xl-10 my-4'>
        {errorBody && <ErrorAlert error_response={errorBody} />}
        {successBody && <SuccessAlert success_response={successBody} />}
        <div id='kt_account_profile_details' className='collapse show'>
          <Formik
            initialValues={initialValues}
            validationSchema={profileDetailsSchema}
            onSubmit={(values) => {
              setLoading(true)
              // *** values.fieldArrays ***   under in all commented value get in array  ***
              const fieldArrays = values.fieldArrays.map((item, index) => ({
                ...item,
                primary: index === 0 ? true : false,
              }))
              let customer: CustomerModel = {
                first_name: values.fieldArrays[0].fName,
                last_name: values.fieldArrays[0].lName,
                company: values.company,
                company_phone: values.fieldArrays[0].companyphone,
                email: values.fieldArrays[0].email,
                phone: values.fieldArrays[0].phone,
                address: values.address,
                city: values.city,
                state: values.state,
                zip_code: values.zip_code,
                fieldArr: fieldArrays,
                contact_method: values.contactmethod,
                discount_type: values.discount,
                referral_type: values.refer,
                notes: values.notes,
                address_line_2: values.address_line_2,
                billing_address_1: values.billing_address_1,
                billing_address_2: values.billing_address_2,
                billing_city: values.billing_city,
                billing_state: values.billing_state,
                billing_zip_code: values.billing_zip_code,
                website: values.website,
                cus_pros: values.cupro,
                profile_pic: values.profile_pic,
              }
              createCustomer(customer)
                .then(({data}) => {
                  setLoading(false)
                  setSuccessBody(data as any)
                  darkMessage('Customer created Successfully')
                  navigate(`/${prefixCompany}/customers`)
                })
                .catch((e) => {
                  if (e?.response?.status === 409) {
                    let error: any = 'Phone number Already Exists'
                    darkErrorMessage(error)
                    setLoading(false)
                  } else {
                    console.log(e)
                  }
                })
            }}
          >
            {({values, setFieldValue}) => (
              <Form onKeyDown={onKeyDown}>
                <div
                  className='card-body border-top p-9'
                  // style={{height: '800px', overflow: 'scroll'}}
                >
                  <div className='row mb-6'>
                    <label
                      htmlFor='company'
                      className='col-lg-4 col-form-label required fw-bold fs-6'
                    >
                      Business Name
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Business Name'
                        name='company'
                      />
                      <ErrorMessage name='company' component='div' className='field-error' />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor={`cupro`}
                      className='col-lg-4 col-form-label required fw-bold fs-6'
                    >
                      Type
                    </label>
                    <div className='col-lg-8 fv-row d-flex align-items-center'>
                      <div style={{display: 'table', height: '100%'}}>
                        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                          <span className='col-lg-4 float-left' style={{marginRight: '50px'}}>
                            <Field
                              type='radio'
                              id='customer'
                              name={`cupro`}
                              className='cursor-pointer'
                              value={'customer'}
                              onClick={() => `cupro`}
                            />
                            <label htmlFor='customer' style={{marginLeft: '6px'}}>
                              Customer
                            </label>
                          </span>
                        </div>
                      </div>
                      <div style={{display: 'table', height: '100%'}}>
                        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                          <span className='col-lg-4 float-right' style={{marginRight: '50px'}}>
                            <Field
                              type='radio'
                              id='prospect'
                              name={`cupro`}
                              className='cursor-pointer'
                              value={'prospect'}
                              onClick={() => `cupro`}
                            />
                            <label htmlFor={`cupro`} style={{marginLeft: '6px'}}>
                              Prospect
                            </label>
                          </span>
                        </div>
                      </div>

                      <ErrorMessage name={`cupro`} component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label htmlFor={`cupro`} className='col-lg-4 col-form-label fw-bold fs-6'>
                      Contact Details
                    </label>
                  </div>
                  <FieldArray name='fieldArrays'>
                    {({insert, remove, push}) => (
                      <div style={{border: '1px solid lightgrey'}} className='rounded'>
                        {values.fieldArrays.length > 0 &&
                          values.fieldArrays.map((friend, index) => (
                            <div
                              key={index}
                              className='p-9'
                              style={{borderTop: '1px solid lightgrey'}}
                            >
                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  Contact {index + 1}
                                </label>
                                <div className='col-lg-8 fv-row align-items-right'>
                                  {index > 0 && (
                                    <div className='col'>
                                      <h4
                                        className='btn btn-secondary'
                                        onClick={() => remove(index)}
                                        style={{float: 'right'}}
                                      >
                                        X
                                      </h4>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`fieldArrays.${index}.contactTitle`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Contact Title
                                </label>
                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Enter Contact Title'
                                    name={`fieldArrays.${index}.contactTitle`}
                                  />
                                  <ErrorMessage
                                    name={`fieldArrays.${index}.contactTitle`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`fieldArrays.${index}.fName`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Contact Name
                                </label>
                                <div className='col-lg-8'>
                                  <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='First name'
                                        name={`fieldArrays.${index}.fName`}
                                      />
                                      <ErrorMessage
                                        name={`fieldArrays.${index}.fName`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Last name'
                                        name={`fieldArrays.${index}.lName`}
                                      />
                                      <ErrorMessage
                                        name={`fieldArrays.${index}.lName`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <label
                                  htmlFor={`fieldArrays.${index}.email`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Email
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Email'
                                    name={`fieldArrays.${index}.email`}
                                  />
                                  <ErrorMessage
                                    name={`fieldArrays.${index}.email`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label
                                  htmlFor={`fieldArrays.${index}.phone`}
                                  className='col-lg-4 col-form-label fw-bold fs-6'
                                >
                                  Phone Number
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Phone Number'
                                    name={`fieldArrays.${index}.phone`}
                                    onChange={(e: any) => handleInput(e, index, setFieldValue)}
                                  />
                                  <ErrorMessage
                                    name={`fieldArrays.${index}.phone`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <label
                                  htmlFor={`fieldArrays.${index}.companyphone`}
                                  className='col-lg-4 col-form-label  fw-bold fs-6'
                                >
                                  Alternative Contact
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Alternative contact'
                                    name={`fieldArrays.${index}.companyphone`}
                                    onChange={(e: any) =>
                                      handleAlternativeInput(e, index, setFieldValue)
                                    }
                                  />
                                  <ErrorMessage
                                    name={`fieldArrays.${index}.companyphone`}
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className='row mb-6 mt-5'>
                          <div className='col-md-12'>
                            <button
                              className='btn btn-primary me-lg-5'
                              style={{float: 'right'}}
                              type={'button'}
                              onClick={() =>
                                push({
                                  fName: '',
                                  lName: '',
                                  email: '',
                                  cupro: '',
                                  phone: '',
                                  companyphone: '',
                                  contactTitle: '',
                                  primary: '',
                                })
                              }
                            >
                              Add Another Contact
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className='row mb-6 mt-5'>
                    <label htmlFor='address' className='col-lg-4 col-form-label  fw-bold fs-6'>
                      Mailing Address
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 1'
                        name='address'
                      />
                      <ErrorMessage name='address' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label
                      htmlFor='address_line_2'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    ></label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 2'
                        name='address_line_2'
                      />
                      <ErrorMessage name='address_line_2' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='City'
                            name='city'
                          />
                          <ErrorMessage name='city' component='div' className='field-error' />
                        </div>

                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='State'
                            name='state'
                          />
                          <ErrorMessage name='state' component='div' className='field-error' />
                        </div>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Zip Code'
                            name='zip_code'
                          />
                          <ErrorMessage name='zip_code' component='div' className='field-error' />
                        </div>
                      </div>

                      <>
                        {' '}
                        <Field
                          type='checkbox'
                          name='mailing_address'
                          id='flexCheckIndeterminate'
                          className='form-check-input m-4'
                          checked={checker}
                          onChange={() => {
                            if (checker === false) {
                              setChecker(true)
                              setFieldValue('billing_address_1', values.address)
                              setFieldValue('billing_address_2', values.address_line_2)
                              setFieldValue('billing_city', values.city)
                              setFieldValue('billing_state', values.state)
                              setFieldValue('billing_zip_code', values.zip_code)
                            } else {
                              setChecker(false)
                            }
                          }}
                        />
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                          Same as Mailing Adderss
                        </label>
                      </>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='billing_address_1'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    >
                      Billing Address
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 1'
                        name='billing_address_1'
                      />
                      <ErrorMessage
                        name='billing_address_1'
                        component='div'
                        className='field-error'
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label
                      htmlFor='billing_address_2'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    ></label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address Line 2'
                        name='billing_address_2'
                      />
                      <ErrorMessage
                        name='billing_address_2'
                        component='div'
                        className='field-error'
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label
                      htmlFor='billing_city'
                      className='col-lg-4 col-form-label  fw-bold fs-6'
                    ></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='City'
                            name='billing_city'
                          />
                          <ErrorMessage
                            name='billing_city'
                            component='div'
                            className='field-error'
                          />
                        </div>

                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='State'
                            name='billing_state'
                          />
                          <ErrorMessage
                            name='billing_state'
                            component='div'
                            className='field-error'
                          />
                        </div>
                        <div className='col-lg-4 fv-row'>
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Zip Code'
                            name='billing_zip_code'
                          />
                          <ErrorMessage
                            name='billing_zip_code'
                            component='div'
                            className='field-error'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='website' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Website
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Website'
                        name='website'
                      />
                      <ErrorMessage name='website' component='div' className='field-error' />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label htmlFor='refer' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Referral Source
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        className='form-control form-control-lg form-control-solid'
                        name='refer'
                        id=''
                        as='select'
                      >
                        <option value='option' hidden selected>
                          Not Selected
                        </option>
                        <option value='Drive By '>Drive By</option>
                        <option value='Facebook'>Facebook</option>
                        <option value='Friend'>Friend</option>
                        <option value='Search Engine'>Search Engine</option>
                        <option value='Google Maps'>Google Maps</option>
                        <option value='Yelp'>Yelp</option>
                        <option value='Other'>Other</option>
                      </Field>

                      <ErrorMessage name='refer' component='div' className='field-error' />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='profile_pic' className='col-lg-4 col-form-label fw-bold fs-6'>
                      Customer Logo
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='file'
                        autoComplete='off'
                        accept='image/*'
                        onChange={(e) => uploadProfileImg(e, setFieldValue)}
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage name='profile_pic' component='div' className='field-error' />
                      {uploadLoading && (
                        <Box sx={{width: '100%'}}>
                          <LinearProgress color='primary' />
                        </Box>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label  fw-bold fs-6'>Customer Notes</label>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        type='textarea'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Notes for Internal Use'
                        name='notes'
                        rows={5}
                      />
                      <ErrorMessage name='notes' component='div' className='field-error' />
                    </div>
                  </div>

                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  ) : null
}
export {CustomerForm}
