/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import DataTable from 'react-data-table-component'
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {useAuth} from '../../modules/auth'
import {
  workorderList,
  currnetCompanyName,
  fieldServiceList,
} from '../../modules/auth/core/_requests'
import {QuotesModel} from '../../modules/model/quotes'
import {
  statusWorkOptions_bgColorInitial as bgColorInitials,
  statusWorkOptions_textColorInitial as textColorInitial,
} from '../../modules/accounts/components/settings/SettingsModel'
import {ExportToCsv} from 'export-to-csv'
import CloseIcon from '@mui/icons-material/Close'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import MaterialReactTable from 'material-react-table'
import {
  HAS_CreateFieldService,
  HAS_FieldPageView,
  HAS_FieldTable,
  HAS_QuoteCreate,
  HAS_ViewWorkOrder,
} from './PermissionFunction'
import {CheckExistance, useLocalStorage} from './GlobalComponents'
import moment from 'moment'
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import {UsersListSearchComponent} from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../_metronic/helpers'
import {AgGridReact} from 'ag-grid-react'
import {MaterialDataTable} from './MatrialDataTable'
import blank from './../../../_metronic/layout/components/header/blank.png'
import {Modal} from 'react-bootstrap'
import CustomStatus from './CustomStatus'
import FilterByTag from './FilterByTag'
import {Quote} from './Quote'

const ServiceField = () => {
  const {currentUser} = useAuth()
  const [totalRows, seTotalRows] = useState(0)
  const navigate = useNavigate()
  const [data, setData] = useState<QuotesModel[]>([])
  const headerContext = useContext<any>(HeaderContext)
  const [loading, setLoading] = useState(true)
  const company = currnetCompanyName(currentUser?.company)
  const [selectClient, setSelectClient] = useState<string>()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const [filtersT, setfiltersT] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const {id} = useParams()
  const [option, setOption] = useState<any>([])
  const SELECTED_ALL = 'all'
  const [selected, setSelected] = useState<any>([])
  const isAllSelected = option.length > 0 && selected.length === option.length
  const containerStyle = useMemo(() => ({width: '100%', height: '95%'}), [])
  const gridStyle = useMemo(() => ({height: '100%', width: '100%'}), [])
  const [tableStorage, setTableStorage] = useLocalStorage('fieldServiceColsVisibility', '')
  const [modal, setModalShow] = useState<any>(false)
  const [createModal, setCreateModal] = useState(false)
  const [tag, setTag] = useState<any>('all')
  const _selectStatus = useRef<any>()
  const _selectTag = useRef<any>()
  const [modalHeading, setModalHeading] = useState<string>('') // Initialize with an empty string
  const [modalTitle, setModalTitle] = useState<string>('') // Initialize with an empty string

  const HandleCloseModal = useCallback(() => {
    setModalShow(false)
  }, [])
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  const MaterialColumns: any = useMemo(
    () => [
      {
        accessorFn: (row: QuotesModel) => 'FS-' + row.label,
        size: 70,
        header: 'FS#', //uses the default width from defaultColumn prop
        accessorKey: 'fieldService_id',
      },
      {
        accessorFn: (row: QuotesModel) => row.customer.company,
        header: 'Customer',
        accessorKey: 'customer_name',
      },
      {
        header: 'Project Name',
        accessorFn: (row: QuotesModel) => row.project_name,
        size: 200, //increase the width of this column
        accessorKey: 'project_name',
      },
      {
        header: 'Assigned To',
        accessorFn: (row: QuotesModel) =>
          row?.assigned_to ? (
            row?.assigned_to.map((eachUser) => {
              return (
                <Tooltip
                  title={
                    <span style={{fontSize: '15px'}}>
                      {eachUser?.first_name + '  ' + eachUser?.last_name || 'N/A'}
                    </span>
                  }
                  className='p-2 '
                >
                  <span>
                    <img
                      alt='emp logo'
                      src={`${eachUser?.profile_image || '/media/avatars/blank.png'}`}
                      onError={(evt: any) => {
                        evt.target.src = blank
                      }}
                      width='40px'
                      height={'40px'}
                      className='mx-1'
                      style={{objectFit: 'contain', borderRadius: '50%'}}
                    />
                  </span>
                </Tooltip>
              )
            })
          ) : (
            <></>
          ),
        size: 200, //increase the width of this column
        accessorKey: 'assigned_to',
      },
      {
        accessorFn: (row: any) => {
          if (row?.tags && Array.isArray(row.tags)) {
            if (row.tags.length > 1) {
              return row.tags.join(', ')
            } else if (row.tags.length === 1) {
              return row.tags[0]
            }
          }
          return ''
        },
        header: 'Tags',
        size: 100, //decrease the width of this column
        accessorKey: 'tags',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
        header: 'Date Created',
        size: 100, //decrease the width of this column
        accessorKey: 'date_created',
      },
      {
        accessorFn: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
        header: 'Last Modified',
        size: 100, //decrease the width of this column
        accessorKey: 'last_modified',
      },
      {
        accessorFn: (row: QuotesModel) => (
          <Link to={`/${company}/project/${row?.id}/overview`} state={{data: row}}>
            <span
              style={{
                backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
                color: `${row.status_type.color ?? '#054701'}`,
                fontSize: '11px',
                textAlign: 'center',
                padding: '5px 15px',
                display: 'block',
                maxWidth: 'fit-content',
              }}
              // title={row.status_type.title}
            >
              {row?.status_type?.title}
            </span>
          </Link>
        ),
        header: 'Status',
        size: 170, //decrease the width of this column
        accessorKey: 'fieldService_status',
      },
    ],
    []
  )
  const columnDefs: any = [
    {
      headerName: 'FS#',
      field: 'label',
      selector: (row: QuotesModel) => 'WO-' + row.label,
      sortable: true,
      cellStyle: {color: 'red', cursor: 'pointer'},
      width: 210,
      suppressSizeToFit: true,

      style: {
        color: 'red',
      },
    },
    {
      headerName: 'Customer',
      field: 'customer.company',
      cellStyle: {cursor: 'pointer'},

      width: 220,

      selector: (row: QuotesModel) => row.customer.company,
    },
    {
      headerName: 'Project Name',
      field: 'project_name',
      cellStyle: {cursor: 'pointer'},

      width: 220,
      selector: (row: QuotesModel) => CheckExistance(row.project_name),
    },

    {
      headerName: 'Date Created',

      field: 'date_created',
      cellStyle: {cursor: 'pointer'},

      width: 220,

      selector: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
    },
    {
      headerName: 'Last Modified',

      field: 'last_modified',
      cellStyle: {cursor: 'pointer'},

      width: 230,

      selector: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
    },
    {
      headerName: 'Status',
      field: 'type',
      cellStyle: {cursor: 'pointer', color: 'rgb(7,71,1)'},

      width: '275px',
      selector: (row: QuotesModel) => (
        <Link to={`/${company}/projects/${row?.id}/overview`} state={{data: row}}>
          <span
            style={{
              backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
              color: `${row.status_type.color ?? '#054701'}`,
              fontSize: '11px',
              textAlign: 'center',
              padding: '5px 15px',
              display: 'block',
              maxWidth: 'fit-content',
            }}
            title={row.status_type.title}
          >
            {row.type}
          </span>
        </Link>
      ),
    },
  ]
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    }
  }, [])
  const agHandleClicked = (e: any) => {
    if (HAS_FieldPageView(currentUser)) {
      navigate(`/${company}/fieldservice/${e?.data.id}/overview`)
    }
  }

  const columns: any = [
    {
      name: 'FS#',
      selector: (row: QuotesModel) => 'FS-' + row.label,
      sortable: true,
      style: {
        color: 'red',
      },
    },
    {
      name: 'Customer',
      selector: (row: QuotesModel) => row.customer.company,
    },
    {
      name: 'Project Name',
      selector: (row: QuotesModel) => CheckExistance(row.project_name),
    },
    {
      name: 'Date Created',
      selector: (row: QuotesModel) => moment(row.date_created, 'DD-MM-YYYY').format('ll'),
    },
    {
      name: 'Last Modified',
      selector: (row: QuotesModel) => moment(row.last_modified, 'DD-MM-YYYY').format('ll'),
    },
    {
      name: 'Status',
      width: '275px',
      selector: (row: QuotesModel) => (
        <Link to={`/${company}/project/${row?.id}/overview`} state={{data: row}}>
          <span
            style={{
              backgroundColor: `${row.status_type.bgcolor ?? '#D0E0CF'}`,
              color: `${row.status_type.color ?? '#054701'}`,
              fontSize: '11px',
              textAlign: 'center',
              padding: '5px 15px',
              display: 'block',
              maxWidth: 'fit-content',
            }}
            title={row.status_type.title}
          >
            {row.type}
          </span>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    setLoading(true)
    getList(
      pagination.pageIndex,
      '',
      _selectStatus.current,
      _selectTag.current,
      pagination.pageSize
    )
  }, [pagination])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const PaginationCallback = useCallback((getPagination: any) => {
    setLoading(true)
    getList(getPagination?.pageIndex, '', SELECTED_ALL, SELECTED_ALL, getPagination?.pageSize)
  }, [])

  const getList = useCallback(
    async (
      page: number = 0,
      str = '',
      field_type = SELECTED_ALL,
      tag_type: string[] | string = SELECTED_ALL,
      pagePerEntries: number = 100
    ) => {
      await fieldServiceList(page, str, field_type, tag_type, pagePerEntries, id)
        .then((res) => {
          let workorders = res.data.quotes as QuotesModel[]
          setData(workorders)
          seTotalRows(res.data.totalRows)
          setOption(res?.data?.status || [])
          setLoading(false)
          const stateHeader = {
            payload: {subtitle: `${res.data.totalRows} Total Records`},
            type: HeaderAction.Update,
          }
          headerContext.dispatch(stateHeader)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    []
  )

  const handleChange = useCallback((event: any) => {
    setLoading(true)
    const value = event.target.value
    if (value[value.length - 1] === SELECTED_ALL || value.length === 0) {
      setSelected([])
      getList(0, '', SELECTED_ALL, _selectTag.current)
      return
    } else {
      setSelected(value)
      getList(0, '', value, _selectTag.current)
    }
  }, [])

  useEffect(() => {
    _selectStatus.current = selected.length > 0 ? selected : 'all'
    _selectTag.current = tag
  }, [selected, tag])

  useEffect(() => {
    setLoading(true)
    const search = searchParams.get('str') as string
    getList(0, search, _selectStatus.current, _selectTag.current)
    return () => {
      searchParams.set('str', '')
    }
  }, [searchParams])

  useEffect(() => {
    function fetchList() {
      setLoading(true)
      getList(0)
    }
    fetchList()
  }, [])
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const handleNavigate = (e: any) => {
    if (HAS_ViewWorkOrder(currentUser)) {
      navigate(`/${company}/fieldservice/${e?.id}/overview`)
    }
  }

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'None',
        subtitle: ' ',
        btn_text: 'None',
        navigation_url: `/${prefixCompany}/fieldservice/create`,
        additional_btn_text: ' ',
      },
      searchBar: {
        search: false,
        search_text: '',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    setfiltersT(headerContext?.state?.searchBar?.search_text)
  }, [headerContext?.state?.searchBar?.search_text])

  const filteredItems = data.filter((item) => {
    if (filtersT.length >= 2) {
      return item?.customer.company.toLowerCase().includes(filtersT.toLowerCase())
    } else {
      return data
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const getListt = useCallback(async (page: number = 0, str = '', sort_type = '') => {
    setLoading(true)
    await fieldServiceList(page, str, id, sort_type)
  }, [])

  const handleTagFunctionality = useCallback((tag_data: string[] | string) => {
    setLoading(true)
    _selectStatus.current = _selectStatus.current.length > 0 ? _selectStatus.current : 'all'
    setTag(tag_data)
    getList(0, '', _selectStatus.current, tag_data, 100)
  }, [])

  function handleActives(event: any) {
    setLoading(true)
    setSelectClient(event.target.value)
    console.log('sort', event.target.value)
    getListt(1, '', event?.target?.value)
  }
  const CSV_HEADER = useMemo(
    () => [
      'Field Service Id',
      'Customer Name',
      'Project Name',
      'Assigned To',
      'Tags',
      'Date Created',
      'Last Modified',
      'Status Type',
    ],
    []
  )
  const CSV_DATA = useMemo(
    () =>
      data.map((eachIdx) => {
        console.log(data)
        return [
          'FS-' + eachIdx?.label,
          eachIdx?.customer?.company,
          eachIdx?.project_name,
          eachIdx?.assigned_to[0]?.first_name + ',' + eachIdx?.assigned_to[0]?.email,
          eachIdx?.tags?.join(','),
          eachIdx?.date_created,
          eachIdx?.last_modified,
          eachIdx?.status_type?.title,
        ]
      }),
    [data]
  )

  const csvOptions = useMemo(
    () => [
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: CSV_HEADER,
      },
    ],
    [CSV_HEADER]
  )

  const csvExporter = new ExportToCsv(csvOptions[0])

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(CSV_DATA)
  }, [CSV_DATA])

  const openModal = () => {
    setModalShow(1)
    setAnchorEl(null)
    setModalHeading('Edit Field Service Status')
    setModalTitle('Add New Field Service Status')
  }

  return (
    <>
      {HAS_FieldTable(currentUser) && (
        <>
          <div>
            <div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card mb-2'></div>
                </div>
              </div>
            </div>
          </div>

          <div style={containerStyle}>
            <div style={gridStyle} className='ag-theme-alpine'>
              <MaterialDataTable
                stopRedirection={HAS_FieldPageView(currentUser)}
                MaterialColumns={MaterialColumns}
                MaterialColumnsApiData={data}
                totalRows={totalRows}
                loadingBar={loading}
                tableHeight={700}
                navigationLink={'fieldservice'}
                additionalNavigationLink={'overview'}
                serverPagination={pagination}
                setServerPagination={(cb_data: any) => {
                  return PaginationCallback(cb_data)
                }}
                columnVisibility={tableStorage}
                cbColumnVisibility={(callbackData) => {
                  cbFunction(callbackData)
                }}
                optionalTableHeader={
                  <div
                    style={{gap: '10px'}}
                    className='card-title m-0 d-flex d-inline align-items-center mui-table-header-custom'
                  >
                    <h4
                      style={{marginLeft: '10px'}}
                      className='fw-bolder my-5 w-115px'
                      // onClick={() => navigate(`/${prefixCompany}/fieldservice/createnew`)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen084.svg'
                        className='svg-icon-3 me-3'
                      />
                      Field Service
                    </h4>

                    <div className='god-father_1'>
                      <div className='sub-god_1'>
                        <UsersListSearchComponent />{' '}
                      </div>
                      {/* <div className='service-p-btn'> */}
                      <FormControl className='sub-god_1 h-35'>
                        <InputLabel id='mutiple-select-label' style={{marginTop: '-6px'}}>
                          Filter By Status
                        </InputLabel>
                        <Select
                          labelId='mutiple-select-label'
                          multiple
                          value={selected}
                          label='Filter By Status'
                          style={{height: '35px'}}
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(',')}
                        >
                          <MenuItem value={SELECTED_ALL}>
                            <CloseIcon>
                              <Checkbox
                                checked={isAllSelected}
                                indeterminate={
                                  selected.length > 0 && selected.length < option.length
                                }
                              />
                            </CloseIcon>
                            <ListItemText primary='Clear All' />
                          </MenuItem>
                          {option.map((item: any) => (
                            <MenuItem value={item.title}>
                              <ListItemIcon>
                                <Checkbox checked={selected.indexOf(item.title) > -1} />
                              </ListItemIcon>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* </div> */}
                      <div className='sub-god_1'>
                        <FilterByTag
                          callback_function={(cb_Data: string[] | string) => {
                            handleTagFunctionality(cb_Data)
                          }}
                        />
                      </div>
                    </div>
                    <div className='mui-icons'>
                      <div
                        className='flex-wrap fw-bold fs-6 m-1'
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <button
                          style={{backgroundColor: 'lightgrey'}}
                          className='btn-lightgrey btn h-35px w-35px'
                          id='basic-button'
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <div style={{marginTop: '-6px', marginLeft: '-8px'}}>
                            <KTSVG
                              className='svg-icon-4 m-0'
                              path='/media/icons/duotune/general/setting.svg'
                            />
                          </div>
                        </button>
                        <Menu
                          id='basic-menu'
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={handleExportData}>
                            <span>Export All Data</span>
                          </MenuItem>
                          <MenuItem onClick={openModal}>
                            <span>Edit Field Service Statuses</span>
                          </MenuItem>
                          <MenuItem onClick={() => navigate(`/${prefixCompany}/fieldservice/tags`)}>
                            <span> Edit Tags </span>
                          </MenuItem>
                        </Menu>
                      </div>
                      <div className='m-1'>
                        {HAS_CreateFieldService(currentUser) && (
                          <button
                            className='btn btn-primary h-35px w-35px fw-bold'
                            // style={{fontSize: '20px', padding: '6px 16px'}}
                            onClick={() => setCreateModal(true)}
                          >
                            <div style={{marginTop: '-10px', marginLeft: '-6px', fontSize: '20px'}}>
                              +
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <Modal
            style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
            show={modal}
            onModalClose={HandleCloseModal}
            onHide={HandleCloseModal}
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <CustomStatus
              getList={getList}
              type='field service'
              closeModal={HandleCloseModal}
              heading={modalHeading}
              title={modalTitle}
            />
          </Modal>
          <Quote
            modal={createModal}
            setModal={(cb_value: boolean) => {
              setCreateModal(cb_value)
            }}
            getWorkOrderData={getList}
          />
        </>
      )}
    </>
  )
}

export {ServiceField}
