import {useContext, useState} from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {HeaderAction, HeaderContext} from '../../../../app/context/HeaderContext'
import {Else, If, Then} from 'react-if'
import {useLocation, useNavigate} from 'react-router-dom'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {useAuth} from '../../../../app/modules/auth'
import {currnetCompanyName} from '../../../../app/modules/auth/core/_requests'

export function MenuInner() {
  const intl = useIntl()
  const navigate = useNavigate()
  const headerContext = useContext<any>(HeaderContext)
  const location = useLocation()
  const {currentUser} = useAuth()
  const companyName = currnetCompanyName(currentUser?.company)
  const search_routes = [
    // `/${companyName}/customers`,
    // `/${companyName}/quote`,
    //  `/${companyName}/workorder`,
    // `/${companyName}/inventory/store`,
    // `/${companyName}/inventory/purchase-order`,
    // `/${companyName}/inventory/return-order`,
    `/${companyName}/employee/view`,
  ]

  return (
    <>
      <If condition={headerContext?.state?.custom}>
        <Then>
          {headerContext?.state?.action?.btn_text === 'None' ? null : (
            <button
              className='btn btn-sm btn-primary cursor-pointer'
              style={{margin: '10px auto'}}
              id='kt_toolbar_primary_button'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() => navigate(headerContext?.state?.action?.navigation_url || -1)}
            >
              {headerContext?.state?.action?.btn_text}
            </button>
          )}
          <MenuItem
            title={headerContext?.state?.title === 'None' ? <></> : headerContext?.state?.title}
            to='#'
          />
          {/* {headerContext?.state?.subtitle === ' ' ? <></> : (
            <MenuItem title={headerContext?.state?.subtitle} to='#' />
          )} */}
          {headerContext?.state?.additionalAction?.additional_btn_text === ' ' ? null : (
            <button
              className='btn btn-sm btn-light cursor-pointer'
              style={{margin: '10px'}}
              id='kt_toolbar_primary_button'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() =>
                navigate(headerContext?.state?.additionalAction?.additional_navigation_url)
              }
            >
              {headerContext?.state?.additionalAction?.additional_btn_text}
            </button>
          )}
          {search_routes.find((w) => w === location.pathname) && <UsersListSearchComponent />}
        </Then>
        <Else>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
        </Else>
      </If>
    </>
  )
}
