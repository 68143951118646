import React, {useCallback, useState} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../../app/modules/auth'
import {HAS_Activity_Edit} from '../../../../app/pages/dashboard/PermissionFunction'
import {InventoryNotes} from '../../../../app/pages/dashboard/InventoryNotes'
import {
  darkErrorMessage,
  darkMessage,
  updateItemActivity_Item,
} from '../../../../app/modules/auth/core/_requests'

type Props = {
  list: any
  className: string
  getList: any
}

var parse = require('html-react-parser')

const ListsWidget14: React.FC<Props> = ({list, className, getList}) => {
  const {currentUser} = useAuth()
  const [show, setShow] = useState<any>({
    show: false,
    data: null,
  })
  const handleCloseModal = useCallback(() => {
    setShow({
      show: false,
      data: null,
    })
  }, [])

  const handleUpdateModal = useCallback(
    async (callback_data: any) => {
      const assigned_items_data = {
        id: callback_data?.inventory_id,
        notes_id: show?.data?.id,
        additional_notes: callback_data?.additional_notes,
      }
      console.log(assigned_items_data)
      console.log(show?.data)
      await updateItemActivity_Item(assigned_items_data)
        .then(({data}) => {
          setShow(null)
          darkMessage('Notes updated Successfully')
          getList()
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            const message = e?.response?.data?.error_list[0]
            darkErrorMessage(message)
          } else {
            console.error(e)
          }
        })
    },
    [getList, show]
  )

  return (
    <>
      <div
        className='timeline-item align-items-start  py-2'
        style={{margin: '0px 20px', borderRadius: '15px', background: '#F6F6F7'}}
      >
        <div className='fw-normal timeline-content text-muted ps-3 d-flex align-items-start timesheet notes-card'>
          <a
            href={`${list?.user_image ? list?.user_image : '/media/avatars/blank.png'}`}
            target='blank'
            style={{padding: '5px'}}
          >
            <img
              alt='emp img'
              src={`${list?.user_image ? list?.user_image : '/media/avatars/blank.png'}`}
              width='40px'
              height='40px'
              style={{borderRadius: '50%'}}
            />
          </a>
          <div
            className='d-flex flex-column gap-1'
            style={{
              width: '100%',
              marginLeft: '10px',
            }}
          >
            <div
              style={{
                color: 'black',
                fontSize: '14px',
                marginLeft: '5px',
              }}
              className=''
            >
              <p className='d-flex fw-bold mb-0 justify-content-between'>
                <span className='fs-2 '>{list?.user_name}</span>
                <span className='text-muted pe-lg-5'>
                  {list.title}
                  <strong className='cursor-pointer float-end'>
                    {HAS_Activity_Edit(currentUser, list) && (
                      <i
                        className='bi bi-pencil-square mx-2 cursor-pointer'
                        style={{color: 'black'}}
                        onClick={() =>
                          setShow({
                            show: true,
                            data: list,
                          })
                        }
                      ></i>
                    )}
                  </strong>
                </span>
              </p>
              <span className='fs-6 lh-1' style={{color: 'darkgrey'}}>
                {moment(list.date_created).format('hh:mm A, MMM. Do YYYY')}
              </span>
            </div>
            <div className='d-flex gap-3 m-2 text-dark fw-bold align-items-center'>
              {list.message}
            </div>
            {list?.additional_notes && (
              <div style={{fontSize: '13px', marginLeft: '5px'}} className='text-black mt-2'>
                {parse(list?.additional_notes)}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={show?.show}
        onHide={() => handleCloseModal()}
        onModalClose={() => handleCloseModal()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryNotes
          cb_fnc={(cb_data: any) => handleUpdateModal(cb_data)}
          previous_data={show?.data?.additional_notes}
        />
      </Modal>
    </>
  )
}

export {ListsWidget14}
