import React, {useState, useRef, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {
  currnetCompanyName,
  deleteDepartment,
  darkMessage,
  darkErrorMessage,
  updateDepartment,
} from '../../../modules/auth/core/_requests'
import {RoleObjectInterface} from '../Roles'
import {useNavigate} from 'react-router-dom'
import {
  HAS_UserManagementDeleteDepartments,
  HAS_UserManagementEditDepartments,
  HAS_UserManagementUpdatePermissions,
} from '../PermissionFunction'
const RolesType: any = ['Admin Department', 'Engineering Department']

export const RoleCard = (props: any) => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [modal, setModal] = useState(false)
  const [val, setval] = useState(false) //val state used for showing drop modal
  const [show, setShow] = useState<any>(false) //show state used for open modal
  const [editShow, setEditShow] = useState<any>(false) //editShow state used for open modal
  const [department, setDepartment] = useState<any>('') //department state used for set value in Edit Modal
  const setfal = () => setModal(false)
  const handleClose = () => setShow(false) //this function is used for close delete modal
  const handleEditClose = () => setEditShow(false) //this function is used for cloase edit modal
  const [warn, setWarn] = useState('')

  //Delete Modal Submit handler function
  const deleteHandler = () => {
    deleteDepartment(props?.role?.id, warn)
      .then((res) => {
        darkMessage('Employee Deleted Successfully')
        props?.getList()
        setfal()
        setWarn('')
        setShow(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
        setfal()
        setWarn('')
      })
  }

  //Open delete Modal function
  const delOpenModal = (role: any) => {
    setShow(true)
  }

  //Open Edit Modal function
  const editOpenModal = (role: any) => {
    setEditShow(true)
    setDepartment(role?.title)
  }

  //Edit Modal Submit handler function
  const editPermissionHandler = () => {
    updateDepartment(props?.role?.id, department)
      .then((res) => {
        setEditShow(false)
        props?.getList()
      })
      .catch((e) => {
        setEditShow(false)
        console.log('e', e)
      })
  }

  const ref = useRef<HTMLDivElement>(null)
  // In this function is used for close edit& delete modal with ref,callback
  useEffect(() => {
    let handler = (e: any) => {
      if (!ref?.current?.contains(e.target)) {
        setval(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div className='card card-flush h-md-100'>
      <div className='card-header roles-cards-wrapper'>
        <div className='d-flex m-lg-5'>
          <h2>
            {props?.role.title
              ? props?.role.title.charAt(0).toUpperCase() + props?.role.title.slice(1)
              : props?.role?.type === 'Owner'
              ? 'Admin Department'
              : props?.role?.type === 'Team Member'
              ? 'Engineering Department'
              : props?.role.type.charAt(0).toUpperCase() + props?.role.type.slice(1)}
          </h2>
        </div>
        <div className='col-md-1  mt-4 float-end' ref={ref} style={{marginRight: '20px'}}>
          <button
            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              setval(!val)
            }}
          >
            <i className='bi bi-three-dots fs-3'></i>
          </button>
          {val && (
            <>
              <div
                className='c-menu1 menu menu-sub menu-sub-dropdown w-190px w-md-200px'
                data-kt-menu='true'
                style={{
                  display: `${val === true ? 'block' : 'none'}`,
                  position: 'absolute',
                  right: '9px',
                  top: '60px',
                }}
              >
                {HAS_UserManagementEditDepartments(currentUser) ? (
                  <h3
                    className='cursor-pointer text-align-center py-2'
                    onClick={() => editOpenModal(props?.role)}
                  >
                    Edit
                  </h3>
                ) : (
                  <></>
                )}
                {HAS_UserManagementDeleteDepartments(currentUser) ? (
                  <div>
                    {RolesType.includes(props?.role?.title) ? null : (
                      <h3
                        className='cursor-pointer text-align-center py-2'
                        onClick={() => delOpenModal(props?.role)}
                      >
                        Delete
                      </h3>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className='card-footer flex-wrap pt-0'
        //  style={{justifyContent:'space-around'}}
      >
        <Link
          to={
            HAS_UserManagementUpdatePermissions(currentUser) &&
            `/${prefixCompany}/employee/roles/${props?.role.title ?? props?.role.type}/permission`
          }
          className='btn btn-light btn-active-primary '
        >
          {' '}
          <i className='fa fa-lock'></i> Edit Permission{' '}
        </Link>
        {/* <button className='btn btn-primary' onClick={()=>{setModal(true)}}>Manage</button> */}
      </div>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setfal()
        }}
        show={modal}
        onHide={setfal}
        size='lg'
        centered
        className='fade bg-black modal show'
      >
        <div className='card'>
          <div className='card-body' style={{overflow: 'scroll'}}>
            <div className='row g-6 g-xl-9'>
              <div className='col-7'>
                <h1 className='fw-bolder m-2'>Manage {props?.role.label} ?</h1>
              </div>
              <div className='col-5 d-flex flex-end'>
                <button type='submit' className='btn btn-primary my-2 me-2'>
                  Delete {props?.role.label}
                </button>
              </div>
              <div className='col-12'>
                <h3 className='p-5 text-justify fw-normal'>Change Department Name</h3>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Enter Department Name'
                />
              </div>
              <div className='col-12'>
                <div className='text-end'>
                  <button type='submit' className='btn btn-dark  my-2 me-2' onClick={setfal}>
                    {`Discard `}
                  </button>
                  <button type='submit' className='btn btn-primary my-2 me-2'>
                    {`Save Changes`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/******************* delete modal start **********************/}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={show}
        size='lg'
        centered
        onHide={handleClose}
        className='fade'
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className='p-2 lh-base'>Are You Sure For Delete Record...</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='col-12'>
            <h3 className='p-5 text-justify fw-normal'>
              To Confirm Changes Please Type <strong>Your Account Password</strong>
            </h3>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Enter Password'
              onChange={(e: any) => {
                setWarn(e.target.value)
              }}
            />
          </div>
        </Modal.Body>
        {/* <Modal.Body>Do you want to Clock In?</Modal.Body> */}
        <Modal.Footer>
          <button onClick={handleClose} className='btn btn-dark'>
            Cancel
          </button>
          <button onClick={deleteHandler} className='btn btn-primary'>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/******************** delete modal end ***********************/}

      {/********************* Edit Modal start  *************************/}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={editShow}
        size='lg'
        centered
        onHide={handleEditClose}
        className='fade'
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className='p-2 lh-base'>Update Department Name</h1>
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Do you want to Clock In?</Modal.Body> */}
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Department</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter Department'
                value={department.charAt(0).toUpperCase() + department.slice(1)}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Modal.Footer>
          <button onClick={handleEditClose} className='btn btn-dark'>
            Cancel
          </button>
          <button onClick={editPermissionHandler} className='btn btn-primary'>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      {/********************* Edit Modal start end **********************/}
    </div>
  )
}
export default RoleCard
