/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-lone-blocks */
import React, {useContext, useEffect, useState, useRef} from 'react'
import {useIntl} from 'react-intl'
import {Update_Customer_Form} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik, Formik, FieldArray, Field, Form, ErrorMessage} from 'formik'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  profileImg,
  updateCustomer,
  viewCustomer,
} from '../../modules/auth/core/_requests'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {CustomerModel} from '../../modules/model/customer'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {HAS_CustomerUpdate} from './PermissionFunction'
import {Box, LinearProgress} from '@mui/material'
import {onKeyDown} from './GlobalComponents'
const regex =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/g

const profileDetailsSchema = Yup.object().shape({
  // first_name: Yup.string().required('First name is required'),
  // last_name: Yup.string().required('Last name is required'),
  company: Yup.string().required('Business name is required'),
  // email: Yup.string().email('Must be a valid email').required('Email is required'),
  // address: Yup.string().required('Address is required'),
  // city: Yup.string().required('City name is required'),
  // state: Yup.string().required('State name is required'),
  // phone: Yup.string()
  // .matches(regex, 'Enter Valid Phone Number ')
  // .required('Phone number is required'),
  // billing_address_1: Yup.string().required('Billing Address is required'),
  // billing_address_2: Yup.string().required('Billing Address is required'),
  // billing_city: Yup.string().required('City name is required'),
  // billing_state: Yup.string().required('State name is required'),
  // billing_zip_code: Yup.number().required('Zip code is required'),
})

const EditCustomer = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [checker, setChecker] = useState(false)
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState<null | ''>('')
  const {currentUser} = useAuth()
  const {id} = useParams()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  // const location: any = useLocation()
  const headerContext = useContext<any>(HeaderContext)
  const [data, setData] = useState<any>({})
  const [uploadLoading, setUploadLoading] = useState(false)
  const refData = useRef<any>(null)

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Update Customer Details',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/customers/view/${id}`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  useEffect(() => {
    let subscribe = true
    async function getList() {
      await viewCustomer(id)
        .then((res) => {
          if (subscribe) {
            let employes = res?.data?.customer_details
            refData?.current?.setFieldValue('first_name', employes.first_name)
            refData?.current?.setFieldValue('last_name', employes.last_name)
            refData?.current?.setFieldValue('customer_id', employes.customer_id)
            refData?.current?.setFieldValue('company', employes.company)
            refData.current.setFieldValue('company_phone', employes.company_phone)
            refData.current.setFieldValue('email', employes.email)
            refData.current.setFieldValue('phone', employes.phone)
            refData.current.setFieldValue('address', employes.address)
            refData.current.setFieldValue('address_line_2', employes.address_line_2)
            refData.current.setFieldValue('city', employes.city)
            refData.current.setFieldValue('state', employes.state)
            refData.current.setFieldValue('zip_code', employes.zip_code)
            refData.current.setFieldValue('discount_type', employes.discount_type)
            refData.current.setFieldValue('contactmethod', employes.contact_method)
            refData.current.setFieldValue('referral_type', employes.referral_type)
            refData.current.setFieldValue('notes', employes.notes)
            if (JSON.parse(employes.fieldArr)) {
              refData.current.setFieldValue('fieldArr', JSON.parse(employes.fieldArr))
            } else {
              refData.current.setFieldValue('fieldArr', [
                {
                  fName: employes.first_name,
                  lName: employes.last_name,
                  email: employes.email,
                  cupro: employes?.type,
                  phone: employes.phone,
                  companyphone: employes.company_phone,
                  contactTitle: '',
                },
              ])
            }
            refData.current.setFieldValue('billing_address_1', employes.billing_address_1)
            refData.current.setFieldValue('billing_address_2', employes.billing_address_2)
            refData.current.setFieldValue('billing_city', employes.billing_city)
            refData.current.setFieldValue('billing_state', employes.billing_state)
            refData.current.setFieldValue('billing_zip_code', employes.billing_zip_code)
            refData.current.setFieldValue('website', employes.website)
            refData.current.setFieldValue('cupro', employes?.type)
            setInputValue(employes.phone)
          }
        })
        .catch(() => {})
    }
    getList()
  }, [])

  const initialValues = {
    fieldArr: [
      {
        fName: data?.first_name,
        lName: data?.last_name,
        email: data?.email,
        cupro: data?.type,
        phone: data?.phone,
        companyphone: data?.company_phone,
        contactTitle: data?.contactTitle,
      },
    ],
    first_name: data?.first_name,
    last_name: data?.last_name,
    company: data?.company,
    company_phone: data?.company_phone,
    email: data?.email,
    phone: data?.phone,
    address: data?.address,
    city: data?.city,
    state: data?.state,
    zip_code: data?.zip_code,
    contact_method: data?.contactmethod,
    discount_type: data?.discount,
    referral_type: data?.referral_type,
    notes: data?.notes,
    address_line_2: data?.address_line_2,
    billing_address_1: data?.billing_address_1,
    billing_address_2: data?.billing_address_2,
    billing_city: data?.billing_city,
    billing_state: data?.billing_state,
    billing_zip_code: data?.billing_zip_code,
    website: data?.website,
    cupro: data?.type,
    profile_pic: data?.profile_pic,
  }

  const uploadProfileImg = (event: any, setFieldValue: any) => {
    const user_img = event.target.files
    try {
      let filesToUpload: any = []
      let lengthOfFiles = Object.keys(user_img).length
      if (user_img != null || lengthOfFiles > 0) {
        let img_kb = user_img[0].size / 1024
        if (img_kb > 1024 * 2) {
          setErrorBody({
            error: true,
            error_list: [
              `
             Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
                user_img[0]?.size /
                1024 /
                1024
              ).toFixed(2)}MB
            `,
            ],
          })
          darkErrorMessage(`
          Oops! Image size should be less than ${(1024 * 2) / 1024}MB and your image size ${(
            user_img[0]?.size /
            1024 /
            1024
          ).toFixed(2)}MB
         `)
          return
        }
        Object.values(user_img).map((value: any) => {
          return filesToUpload.push(value)
        })
      } else {
        console.error('file is not selected')
      }
      setUploadLoading(true)
      profileImg(filesToUpload, prefixCompany)
        .then((res) => {
          setFieldValue('profile_pic', res?.data?.user_pic)
          // formik.setFieldValue('profile_pic', res?.data?.user_pic)
          setUploadLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setUploadLoading(false)
        })
    } catch (e) {
      console.error(e)
    }
  }
  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e: any, index: any, setFieldValue: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setInputValue(formattedPhoneNumber)
    setFieldValue(`fieldArr.${index}.phone`, formattedPhoneNumber)
  }
  return (
    <>
      {/* <Toolbar1 btnText={"Back to Customers"} btnAction={() => {
        navigate(`/${prefixCompany}/customers`)
      }} /> */}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EDIT_CUSTOMER'})} </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        {errorBody && <ErrorAlert error_response={errorBody} />}
        {successBody && <SuccessAlert success_response={successBody} />}
        {HAS_CustomerUpdate(currentUser) ? (
          <div id='kt_account_profile_details' className='collapse show'>
            <Formik
              innerRef={refData}
              initialValues={initialValues}
              validationSchema={profileDetailsSchema}
              onSubmit={(values) => {
                setLoading(true)
                let customer: CustomerModel = {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  company: values.company,
                  company_phone: values.company_phone,
                  email: values.email,
                  phone: values.phone,
                  address: values.address,
                  city: values.city,
                  state: values.state,
                  zip_code: values.zip_code,
                  contact_method: values.contact_method,
                  discount_type: values.discount_type,
                  referral_type: values.referral_type,
                  notes: values.notes,
                  fieldArr: values.fieldArr,
                  billing_address_1: values.billing_address_1,
                  billing_address_2: values.billing_address_2,
                  billing_city: values.billing_city,
                  billing_state: values.billing_state,
                  billing_zip_code: values.billing_zip_code,
                  address_line_2: values.address_line_2,
                  website: values.website,
                  cus_pros: values.cupro,
                  profile_pic: values.profile_pic,
                }
                updateCustomer(customer, id)
                  .then(({data}) => {
                    setLoading(false)
                    setSuccessBody(data as any)
                    // formik.resetForm()
                    darkMessage('Customer updated Successfully')
                    navigate(`/${prefixCompany}/customers/view/${id}`)
                    setInputValue(null)
                  })
                  .catch((e) => {
                    if (e.response.status === 409) {
                      setErrorBody(e.response.data)
                      setLoading(false)
                    }

                    setInputValue(null)
                  })
              }}
            >
              {({values, setFieldValue, errors, touched, isSubmitting}) => (
                <Form onKeyDown={onKeyDown}>
                  <div
                    className='card-body border-top p-9'
                    style={{height: '800px', overflow: 'scroll'}}
                  >
                    <div className='row mb-6'>
                      <label
                        htmlFor='company'
                        className='col-lg-4 col-form-label required fw-bold fs-6'
                      >
                        Business Name
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Enter Business Name'
                          name='company'
                        />
                        <ErrorMessage name='company' component='div' className='field-error' />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        htmlFor={`cupro`}
                        className='col-lg-4 col-form-label required fw-bold fs-6'
                      >
                        Type
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <span className='col-lg-4 float-left' style={{marginRight: '50px'}}>
                          <Field
                            type='radio'
                            id='customer'
                            name={`cupro`}
                            className='cursor-pointer'
                            value={'customer'}
                            onClick={() => `cupro`}
                          />{' '}
                          <label htmlFor='customer'>Customer</label>
                        </span>
                        <span className='col-lg-4 float-right' style={{marginRight: '50px'}}>
                          <Field
                            type='radio'
                            id='prospect'
                            name={`cupro`}
                            className='cursor-pointer'
                            value={'prospect'}
                            onClick={() => `cupro`}
                          />{' '}
                          <label htmlFor={`cupro`}>Prospect</label>
                        </span>

                        <ErrorMessage name={`cupro`} component='div' className='field-error' />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label htmlFor={`cupro`} className='col-lg-4 col-form-label fw-bold fs-6'>
                        Contact Details
                      </label>
                    </div>

                    <FieldArray name='fieldArr'>
                      {({insert, remove, push}) => (
                        <div style={{border: '1px solid lightgrey'}} className='rounded'>
                          {values.fieldArr.length > 0 && values.fieldArr[0].fName !== ' '
                            ? values.fieldArr.map((friend, index) => (
                                <div
                                  className='p-9'
                                  style={{border: '1px solid lightgrey'}}
                                  key={index}
                                >
                                  <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                      Contact {index + 1}
                                    </label>
                                    <div className='col-lg-8 fv-row align-items-right'>
                                      {index > 0 && (
                                        <div className='col'>
                                          <h4
                                            className='btn btn-secondary'
                                            onClick={() => remove(index)}
                                            style={{float: 'right'}}
                                          >
                                            X
                                          </h4>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className='row mb-6'>
                                    <label
                                      htmlFor={`fieldArr.${index}.contactTitle`}
                                      className='col-lg-4 col-form-label fw-bold fs-6'
                                    >
                                      Contact Title
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Enter Contact Title'
                                        name={`fieldArr.${index}.contactTitle`}
                                      />
                                      <ErrorMessage
                                        name={`fieldArr.${index}.contactTitle`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>

                                  <div className='row mb-6'>
                                    <label
                                      htmlFor={`fieldArr.${index}.fName`}
                                      className='col-lg-4 col-form-label required fw-bold fs-6'
                                    >
                                      Contact Name
                                    </label>
                                    <div className='col-lg-8'>
                                      <div className='row'>
                                        <div className='col-lg-6 fv-row'>
                                          <Field
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='First name'
                                            name={`fieldArr.${index}.fName`}
                                          />
                                          <ErrorMessage
                                            name={`fieldArr.${index}.fName`}
                                            component='div'
                                            className='field-error'
                                          />
                                        </div>

                                        <div className='col-lg-6 fv-row'>
                                          <Field
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last name'
                                            name={`fieldArr.${index}.lName`}
                                          />
                                          <ErrorMessage
                                            name={`fieldArr.${index}.lName`}
                                            component='div'
                                            className='field-error'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row mb-6'>
                                    <label
                                      htmlFor={`fieldArr.${index}.email`}
                                      className='col-lg-4 col-form-label required fw-bold fs-6'
                                    >
                                      Email
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Email'
                                        name={`fieldArr.${index}.email`}
                                      />
                                      <ErrorMessage
                                        name={`fieldArr.${index}.email`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>

                                  <div className='row mb-6'>
                                    <label
                                      htmlFor={`fieldArr.${index}.phone`}
                                      className='col-lg-4 col-form-label required fw-bold fs-6'
                                    >
                                      Phone Number
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Phone Number'
                                        name={`fieldArr.${index}.phone`}
                                        onChange={(e: any) => handleInput(e, index, setFieldValue)}
                                      />
                                      <ErrorMessage
                                        name={`fieldArr.${index}.phone`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-6'>
                                    <label
                                      htmlFor={`fieldArr.${index}.companyphone`}
                                      className='col-lg-4 col-form-label  fw-bold fs-6'
                                    >
                                      Alternative Contact
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Alternative contact'
                                        name={`fieldArr.${index}.companyphone`}
                                      />
                                      <ErrorMessage
                                        name={`fieldArr.${index}.companyphone`}
                                        component='div'
                                        className='field-error'
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))
                            : 'null'}
                          <div className='row mb-6 mt-5'>
                            <div className='col-md-12'>
                              <button
                                className='btn btn-primary me-lg-5'
                                style={{float: 'right'}}
                                type={'button'}
                                onClick={() =>
                                  push({
                                    fName: '',
                                    lName: '',
                                    email: '',
                                    cupro: '',
                                    phone: '',
                                    companyphone: '',
                                    contactTitle: '',
                                  })
                                }
                              >
                                {' '}
                                Add Another Contact
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>

                    <div className='row mb-6 mt-5'>
                      <label
                        htmlFor='address'
                        className='col-lg-4 col-form-label required  fw-bold fs-6'
                      >
                        Mailing Address
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Address Line 1'
                          name='address'
                        />
                        <ErrorMessage name='address' component='div' className='field-error' />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label
                        htmlFor='address_line_2'
                        className='col-lg-4 col-form-label  fw-bold fs-6'
                      ></label>

                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Address Line 2'
                          name='address_line_2'
                        />
                        <ErrorMessage
                          name='address_line_2'
                          component='div'
                          className='field-error'
                        />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='City'
                              name='city'
                            />
                            <ErrorMessage name='city' component='div' className='field-error' />
                          </div>

                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='State'
                              name='state'
                            />
                            <ErrorMessage name='state' component='div' className='field-error' />
                          </div>
                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Zip Code'
                              name='zip_code'
                            />
                            <ErrorMessage name='zip_code' component='div' className='field-error' />
                          </div>
                        </div>

                        <>
                          {' '}
                          <Field
                            type='checkbox'
                            name='mailing_address'
                            id='flexCheckIndeterminate'
                            className='form-check-input m-4'
                            checked={checker}
                            onChange={() => {
                              if (checker === false) {
                                setChecker(true)
                                setFieldValue('billing_address_1', values.address)
                                setFieldValue('billing_address_2', values.address_line_2)
                                setFieldValue('billing_city', values.city)
                                setFieldValue('billing_state', values.state)
                                setFieldValue('billing_zip_code', values.zip_code)
                              } else {
                                setChecker(false)
                              }
                            }}
                          />
                          <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                            Same as Mailing Adderss
                          </label>
                        </>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        htmlFor='billing_address_1'
                        className='col-lg-4 col-form-label required  fw-bold fs-6'
                      >
                        Billing Address
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Address Line 1'
                          name='billing_address_1'
                        />
                        <ErrorMessage
                          name='billing_address_1'
                          component='div'
                          className='field-error'
                        />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label
                        htmlFor='billing_address_2'
                        className='col-lg-4 col-form-label  fw-bold fs-6'
                      ></label>

                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Address Line 2'
                          name='billing_address_2'
                        />
                        <ErrorMessage
                          name='billing_address_2'
                          component='div'
                          className='field-error'
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        htmlFor='billing_city'
                        className='col-lg-4 col-form-label  fw-bold fs-6'
                      ></label>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='City'
                              name='billing_city'
                            />
                            <ErrorMessage
                              name='billing_city'
                              component='div'
                              className='field-error'
                            />
                          </div>

                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='State'
                              name='billing_state'
                            />
                            <ErrorMessage
                              name='billing_state'
                              component='div'
                              className='field-error'
                            />
                          </div>
                          <div className='col-lg-4 fv-row'>
                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Zip Code'
                              name='billing_zip_code'
                            />
                            <ErrorMessage
                              name='billing_zip_code'
                              component='div'
                              className='field-error'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        htmlFor='website'
                        className='col-lg-4 col-form-label required fw-bold fs-6'
                      >
                        Website
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Website'
                          name='website'
                        />
                        <ErrorMessage name='website' component='div' className='field-error' />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label
                        htmlFor='referral_type'
                        className='col-lg-4 col-form-label fw-bold fs-6'
                      >
                        Referral Source
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='referral_type'
                          id=''
                          as='select'
                        >
                          <option value='option'>-------</option>
                          <option value='Drive By '>Drive By</option>
                          <option value='Facebook'>Facebook</option>
                          <option value='Friend'>Friend</option>
                          <option value='Search Engine'>Search Engine</option>
                          <option value='Google Maps'>Google Maps</option>
                          <option value='Yelp'>Yelp</option>
                          <option value='Other'>Other</option>
                        </Field>

                        <ErrorMessage
                          name='referral_type'
                          component='div'
                          className='field-error'
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label htmlFor='profile_pic' className='col-lg-4 col-form-label fw-bold fs-6'>
                        Customer Logo
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='file'
                          autoComplete='off'
                          accept='image/*'
                          onChange={(e) => uploadProfileImg(e, setFieldValue)}
                          className='form-control form-control-lg form-control-solid'
                        />
                        <ErrorMessage name='profile_pic' component='div' className='field-error' />
                        {uploadLoading && (
                          <Box sx={{width: '100%'}}>
                            <LinearProgress color='primary' />
                          </Box>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                        Customer Notes
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Field
                          type='textarea'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Notes for Internal Use'
                          name='notes'
                          rows={5}
                        />
                        <ErrorMessage name='notes' component='div' className='field-error' />
                      </div>
                    </div>

                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && 'Save Changes'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export {EditCustomer}
