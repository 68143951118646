import {useCallback, useEffect, useRef, useState} from 'react'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {useParams, useSearchParams} from 'react-router-dom'
import {
  darkErrorMessage,
  darkMessage,
  fileUpload,
  quoteFileDelete,
  quoteFileUpdate,
  viewQuote,
} from '../../../modules/auth/core/_requests'
import {useFormik} from 'formik'
import {useAuth} from '../../../modules/auth'
import * as Yup from 'yup'
import moment from 'moment'
import {iconsMaping} from './icons'
import {CardFilePreview} from '../../../../_metronic/partials/content/cards/CardFilePreview'
import {
  HAS_QuoteUploadFile,
  HAS_WorkOrderUploadFiles,
  HAS_FieldServiceUploadFiles,
} from '../PermissionFunction'
import {UsersListSearchComponent} from '../../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {Modal} from 'react-bootstrap'
import {LinearProgress} from '@mui/material'
const fileuploadschema = Yup.object().shape({
  selected_files: Yup.mixed().required(),
})
const initialValues = {
  selected_files: null,
}

interface PropsTypes {
  cb_func?: () => void
}
export function Documents(props: PropsTypes) {
  const [filesInfo, setFilesInfo] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [files, setFiles] = useState<any>(0)
  const [filedata, setFileData] = useState<any>([])
  const state = useState<any>({
    photo: '',
  })
  const [loading, setLoading] = useState(false)
  const [listLoading, setListLoading] = useState(false)
  const [iconShow, setIconShow] = useState(false)
  const [delModal, setDelModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [hoverKey, setHoverKey] = useState(false)
  const [delObj, setDelObj] = useState({
    extension: '',
    filedate: '',
    filename: '',
    url: '',
    user: '',
    id: 0,
  })
  const {currentUser} = useAuth()
  const [isDragOver, setIsDragOver] = useState(false)

  const handleDragOver = (e: {preventDefault: () => void}) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleDragEnter = (e: {preventDefault: () => void}) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = (e: {preventDefault: () => void}) => {
    e.preventDefault()
    setIsDragOver(false)
  }

  const handleDrop = (e: any) => {
    // Get the dropped files from the DataTransfer object
    const files = e?.dataTransfer?.files
    handleFileChange(files)
    e.preventDefault()
    setIsDragOver(false)
  }

  const handleFileChange = (files: null) => {
    try {
      let filesToUpload: any = []
      if (files != null) {
        Object.values(files).map((value) => {
          return filesToUpload.push(value)
        })
        formik.setFieldValue('selected_files', filesToUpload)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const {photo} = state
  const {type, id} = useParams<any>()
  const inputFile = useRef<any>()
  var permissions = null

  const getList = useCallback(
    async (idx: any = id, str = '') => {
      setListLoading(true)
      setLoading(true)
      await viewQuote(idx, str)
        .then((res) => {
          let noOfFiles = res.data.file_counts
          let fileInfos = res.data.files_info
          setFileData(fileInfos)
          setFiles(noOfFiles)
          setListLoading(false)
          setLoading(false)
        })
        .catch((e) => {
          setListLoading(false)
          setLoading(false)
        })
    },
    [id]
  )

  useEffect(() => {
    const search: any = searchParams.get('str')
    search !== null ? getList(id, search) : getList(id)
    return () => {
      searchParams.set('str', '')
    }
  }, [getList, id, searchParams])
  useEffect(() => {
    let temp_arr: any = []
    for (let key in filedata) {
      let id = filedata[key]?.id
      let value = filedata[key]?.file_path
      let date_uploaded = filedata[key]?.date_created
      let url = filedata[key]?.path
      let fileName = filedata[key]?.file_name
      let fname = filedata[key]?.user?.first_name
      let lname = filedata[key]?.user?.last_name
      let myarr = value.split('.')
      let fileNames = value.split('/')
      let count = myarr.length
      let length = fileNames.length
      let ext = myarr[count - 1]
      let file_name = fileNames[length - 1]
      // eslint-disable-next-line no-self-assign
      fileName ? (file_name = fileName) : (file_name = file_name)
      temp_arr.push({
        filename: file_name,
        extension: ext,
        filedate: date_uploaded,
        url: url,
        user: fname + ' ' + lname,
        id: id,
      })
    }
    setFilesInfo(temp_arr)
  }, [filedata])

  const formik = useFormik({
    initialValues,
    validationSchema: fileuploadschema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let pic = values.selected_files
        async function getData(file: any, id: any) {
          setLoading(true)
          await fileUpload(file, id)
            .then((res) => {
              getList()
              formik.resetForm()
              props?.cb_func && props?.cb_func()
              setTimeout(() => {
                setLoading(false)
                darkMessage('Files Uploaded Successfully')
              }, 1000)
            })
            .catch((e) => {
              setLoading(false)
              console.error(e)
            })
        }
        getData(pic, id)
      } catch (error: any) {
        console.error(error)
      }
    },
  })
  const onButtonClick = () => {
    inputFile.current.click()
  }

  // Delete Modal show
  const deleteModal = (value: any) => {
    setDelModal(true)
    setDelObj(value)
  }

  // Edit Modal
  const editModals = (value: any) => {
    setEditModal(true)
    setDelObj(value)
    // .split('.')[0]
  }

  const deleteHandler = (idx: any) => {
    quoteFileDelete(idx, id)
      .then((res: any) => {
        handleClose()
        darkMessage('File Deleted Successfully')
        props?.cb_func && props?.cb_func()
      })
      .catch((e: any) => {
        handleClose()
        darkErrorMessage(e?.error_list[0])
        console.error(e)
      })
  }

  const editModalHandler = (data: any) => {
    quoteFileUpdate(data?.id, data?.filename, id)
      .then((res: any) => {
        handleClose()
        darkMessage('File Updated Successfully')
        props?.cb_func && props?.cb_func()
      })
      .catch((e: any) => {
        handleClose()
        darkErrorMessage(e?.error_list[0])
        console.error(e)
      })
  }

  const editModalValue = (e: any) => {
    setDelObj({...delObj, filename: e})
  }

  const handleClose = () => {
    getList(id, '')
    setDelModal(false)
    setEditModal(false)
  }
  const hoverHandler = (key: any) => {
    setIconShow(true)
    setHoverKey(key)
  }
  useEffect(() => {
    getList(id, '')
  }, [getList, id])
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder m-2'>Project Files + {files || 0}</h3>
          <div className='d-flex m-2'>
            {console.log(type)}
            {type === 'quote'
              ? (permissions = HAS_QuoteUploadFile(currentUser))
              : type === 'project'
              ? (permissions = HAS_WorkOrderUploadFiles(currentUser))
              : (permissions = HAS_FieldServiceUploadFiles(currentUser))}
            {permissions && (
              <>
                {/*
              ============================================================
              ============================================================
              Button is currently not displayed using d-none class name
              (Modified according to clients requirements. )
              ============================================================
              ============================================================
              */}
                <button
                  type={'button'}
                  onClick={onButtonClick}
                  className='btn btn-primary btn-sm  m-2 d-none'
                >
                  Upload Files
                </button>
              </>
            )}
            <div className='parent-global-search'>
              <UsersListSearchComponent />
            </div>
          </div>
        </div>

        <div>
          <CardFilePreview
            list={formik?.values?.selected_files}
            onConfirm={() => {
              formik.submitForm()
            }}
            onDiscard={() => {
              formik.resetForm()
            }}
            loading={loading}
          />
        </div>

        <div
          className='row g-6 g-xl-9 mb-6 mb-xl-9'
          style={{
            overflow: 'scroll',
            maxHeight: '500px',
          }}
        >
          {type === 'quote'
            ? (permissions = HAS_QuoteUploadFile(currentUser))
            : type === 'project'
            ? (permissions = HAS_WorkOrderUploadFiles(currentUser))
            : (permissions = HAS_FieldServiceUploadFiles(currentUser))}
          {permissions && (
            <>
              {loading && listLoading && <LinearProgress />}
              <div
                className={`custom-file-container ${isDragOver ? 'drag-over' : ''}`}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  width: '100%',
                  textAlign: 'center',
                  color: 'black',
                  background: '#dbdbdb',
                  border: '2px dashed lightgray',
                  borderRadius: '10px',
                  fontSize: '15px',
                  padding: '100px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <label htmlFor='fileInput' className='cursor-pointer'>
                  Click me or drag and drop file here
                </label>
                <input
                  type='file'
                  onChange={(e) => {
                    try {
                      let filesToUpload: any = []
                      if (e?.target?.files != null) {
                        Object.values(e.target.files).map((value) => {
                          return filesToUpload.push(value)
                        })
                        formik.setFieldValue('selected_files', filesToUpload)
                      }
                    } catch (e) {
                      console.error(e)
                    }
                  }}
                  value={photo}
                  name='file'
                  id='fileInput'
                  style={{
                    display: 'none',
                  }}
                  ref={inputFile}
                  multiple
                />
              </div>
            </>
          )}
          {filesInfo.length === 0 ? (
            <div className='text-center'>No files found.</div>
          ) : (
            filesInfo.map((value: any, key: any) => {
              return (
                <div
                  className='col-lg-3 col-md-4 col-sm-6 col-xsm-12 col-sm'
                  style={{cursor: 'pointer'}}
                  onMouseOver={() => hoverHandler(key)}
                  onMouseOut={() => setIconShow(false)}
                >
                  <div
                    className={`position-relative ${
                      iconShow === true && hoverKey === key ? 'd-block' : 'd-none'
                    }`}
                    style={{marginTop: '25px'}}
                  >
                    <i
                      className='bi bi-pencil-square position-absolute top-2 start-100 translate-middle'
                      style={{marginLeft: '-26px', top: '-6px', color: 'black'}}
                      onClick={() => editModals(value)}
                    ></i>
                    <i
                      className='bi bi-x-circle position-absolute top-2 start-100 translate-middle'
                      style={{top: '-6px', color: 'red', marginLeft: '-8px'}}
                      onClick={() => deleteModal(value)}
                    ></i>
                  </div>
                  <Card4
                    icon={`/media/svg/files/${iconsMaping[value?.extension] || 'ai.svg'}`}
                    title={value.filename}
                    url={value?.url}
                    description={
                      `Uploaded by ${value?.user} on` +
                      ' ' +
                      moment(value.filedate).format('YYYY-MM-DD')
                    }
                    outerClassname={
                      'card-body d-flex justify-content-center text-center flex-column p-8'
                    }
                    innerClassname={'fs-5 fw-bolder mb-2'}
                    outerId={''}
                  />
                </div>
              )
            })
          )}
        </div>
      </form>

      {/**************************** Delete Modal start ****************************  */}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={delModal}
        size='lg'
        centered
        onHide={() => handleClose()}
        className='fade'
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className='p-2 lh-base'>Confirmation Message</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='kt_account_profile_details' className='collapse show'>
            {/* <img src={delObj?.url} alt='ai.svg' width='180px' height='150px' /> */}
            <h2 className='m-5'>Did You really want to delete File ({delObj?.filename})</h2>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='fw-bold fs-6'>
            <button onClick={() => handleClose()} className='btn btn-dark'>
              Discard
            </button>
          </div>
          <div className='fw-bold fs-6'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => deleteHandler(delObj.id)}
              style={{marginLeft: '12px'}}
            >
              Delete File
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/***************************** Delete Modal End ****************************/}

      {/**************************** Edit  Modal start ****************************  */}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={editModal}
        size='lg'
        centered
        onHide={() => handleClose()}
        className='fade'
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className='p-2 lh-base'>Update File Label</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                Enter File Name
              </label>
              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  required
                  value={delObj?.filename.split('.')[0]}
                  onChange={(e) => editModalValue(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='fw-bold fs-6'>
            <button onClick={() => handleClose()} className='btn btn-dark'>
              Discard
            </button>
          </div>
          <div className='fw-bold fs-6'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => editModalHandler(delObj)}
              style={{marginLeft: '12px'}}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/***************************** Edit  Modal End ****************************/}
    </>
  )
}
