import {Box, LinearProgress, Typography} from '@mui/material'
import MaterialReactTable from 'material-react-table'
import moment from 'moment'
import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {fetchCustomerFiles} from '../../../modules/auth/core/_requests'

enum stateType {
  QUOTES = 'Quotes',
  PROJECTS = 'Projects',
  FIELD_SERVICES = 'Field Service',
  ALL = 'All Files',
}

export function CustomerFiles() {
  const {id} = useParams<any>()
  const [loading, setLoading] = useState(false)
  const [allFiles, setAllFiles] = useState([])
  const [quoteFiles, setQuoteFiles] = useState([])
  const [projectFiles, setProjectFiles] = useState([])
  const [serviceFiles, setServiceFiles] = useState([])
  const [currentState, setCurrentState] = useState<stateType>(stateType.QUOTES)
  const [filteredFileName, setFilteredFileName] = useState('')

  const MaterialColumns: any = [
    {
      accessorFn: (row: any) => row.final_title,
      header: `${currentState}`, //uses the default width from defaultColumn prop
      accessorKey: 'customer_files',
    },
    {
      accessorFn: (row: any) => row.project_name,
      header: 'Project Name', //uses the default width from defaultColumn prop
      accessorKey: 'project_name',
    },
  ]

  const getFilesList = useCallback(
    (str: string = '') => {
      setLoading(true)
      fetchCustomerFiles(str, id)
        .then((res) => {
          const api_data = res?.data
          setAllFiles(api_data?.all_files)
          setQuoteFiles(api_data?.quote_files)
          setProjectFiles(api_data?.work_order_files)
          setServiceFiles(api_data?.field_service_files)
          setLoading(false)
        })
        .catch((e) => {
          if (e.response.status === 409) {
            // darkErrorMessage(e?.response?.data?.error_list[0])
            console.error(e?.response?.data)
          }
          console.error(e)
          setLoading(false)
        })
    },
    [id]
  )

  useEffect(() => {
    getFilesList(filteredFileName)
  }, [filteredFileName, getFilesList])

  useEffect(() => {
    getFilesList()
  }, [getFilesList])

  return (
    <>
      {loading && <LinearProgress />}
      <div className='row mt-9 bg-white p-0 file-tabs-title'>
        <div className='col-lg-2 col-sm-12 control-section control-section-title cursor-pointer d-flex flex-column'>
          <div
            className={`p-2 my-5 btn  ${
              currentState === stateType.QUOTES ? 'btn-outline-primary' : 'btn-outline-dark'
            }`}
            style={{fontSize: '15px'}}
            onClick={() => setCurrentState(stateType.QUOTES)}
          >
            {stateType.QUOTES}
          </div>
          <div
            className={`p-2 my-5 btn  ${
              currentState === stateType.PROJECTS ? 'btn-outline-primary' : 'btn-outline-dark'
            }`}
            style={{fontSize: '15px'}}
            onClick={() => setCurrentState(stateType.PROJECTS)}
          >
            {stateType.PROJECTS}
          </div>
          <div
            className={`p-2 my-5 btn  ${
              currentState === stateType.FIELD_SERVICES ? 'btn-outline-primary' : 'btn-outline-dark'
            }`}
            style={{fontSize: '15px'}}
            onClick={() => setCurrentState(stateType.FIELD_SERVICES)}
          >
            {stateType.FIELD_SERVICES}
          </div>
          <div
            className={`p-2 my-5 btn  ${
              currentState === stateType.ALL ? 'btn-outline-primary' : 'btn-outline-dark'
            }`}
            style={{fontSize: '15px'}}
            onClick={() => setCurrentState(stateType.ALL)}
          >
            {stateType.ALL}
          </div>
        </div>

        <div className='col-lg-10 col-sm-12 border overflow-scroll border-secondary control-section'>
          {currentState === stateType.QUOTES ? (
            <DataTable
              columns={MaterialColumns}
              data={quoteFiles}
              callbackFunction={(file_to_found: string = '') => {
                setFilteredFileName(file_to_found)
              }}
            />
          ) : currentState === stateType.PROJECTS ? (
            <DataTable
              columns={MaterialColumns}
              data={projectFiles}
              callbackFunction={(file_to_found: string = '') => {
                setFilteredFileName(file_to_found)
              }}
            />
          ) : currentState === stateType.FIELD_SERVICES ? (
            <DataTable
              columns={MaterialColumns}
              data={serviceFiles}
              callbackFunction={(file_to_found: string = '') => {
                setFilteredFileName(file_to_found)
              }}
            />
          ) : currentState === stateType.ALL ? (
            <div
              className='mt-5'
              style={{display: 'grid', gap: '20px', gridTemplateColumns: 'auto auto auto'}}
            >
              {allFiles.map((each_File: any) => {
                return each_File?.uploaded_files.map((file_data: any) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: '',
                      }}
                    >
                      <div className=' row mb-4'>
                        <div className='col-md-4'>
                          <a
                            href={file_data.file_path}
                            title='Click on this icon to open file'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <KTSVG
                              path='/media/icons/duotune/files/fil003.svg'
                              svgClassName='h-70px w-70px'
                            />
                          </a>
                        </div>
                        <div className='col-md-8'>
                          <Typography title='Click on this icon to open file' className='fw-bold'>
                            {file_data.file_name}
                          </Typography>
                          <Typography>
                            {moment(file_data.uploaded_date).format('ddd, MMM Do YYYY')}
                          </Typography>
                          <Typography>
                            By:{' '}
                            <strong>
                              {file_data.user.first_name + ' ' + file_data.user.last_name}
                            </strong>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  )
                })
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

const DataTable = ({columns, data, callbackFunction}: any) => {
  const [globalFilter, setGlobalFilter] = useState('')

  useEffect(() => {
    callbackFunction(globalFilter)
  }, [callbackFunction, globalFilter])

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableExpanding
      enableSorting={false}
      enableStickyHeader
      enableStickyFooter
      manualFiltering
      onGlobalFilterChange={setGlobalFilter}
      enableGlobalFilter={true}
      state={{globalFilter}}
      enableColumnActions={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enablePagination={false}
      enableColumnFilters={false}
      enableBottomToolbar={false}
      muiTableContainerProps={{sx: {maxHeight: `700px`}}}
      muiTableHeadCellProps={{sx: {fontWeight: 'bold', fontSize: '17px'}}}
      muiTableBodyCellProps={{sx: {fontSize: '15px'}}}
      renderDetailPanel={({row}: any) => (
        <div className='' style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
          {row.original.uploaded_files.length > 0 ? (
            row.original.uploaded_files.map((item: any) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    margin: 'auto',
                    flexWrap: '',
                  }}
                >
                  <div className=' row mb-4'>
                    <div className='col-md-4'>
                      <a
                        href={item.file_path}
                        title='Click on this icon to open file'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil003.svg'
                          svgClassName='h-70px w-70px'
                        />
                      </a>
                    </div>
                    <div className='col-md-8'>
                      <Typography title='Click on this icon to open file' className='fw-bold'>
                        {item.file_name}
                      </Typography>
                      <Typography>
                        {moment(item.uploaded_date).format('ddd, MMM Do YYYY')}
                      </Typography>
                      <Typography>
                        By: <strong>{item.user.first_name + ' ' + item.user.last_name}</strong>
                      </Typography>
                    </div>
                  </div>
                </Box>
              )
            })
          ) : (
            <h4 className=''>There is no data to display</h4>
          )}
        </div>
      )}
    />
  )
}
