/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {FC} from 'react'
import {useAuth} from '../../modules/auth'
import {currnetCompanyName, mainClock, mainTimesheet} from '../../modules/auth/core/_requests'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import moment from 'moment'
import {WeeklyTimeCard} from './WeeklyTimeCard'

const Timesheet: FC = () => {
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<any>(0)
  const headerContext = useContext<any>(HeaderContext)
  const [data, setData] = useState<any>([])
  const [result, setResult] = useState<any>(null)

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Time Sheet',
        subtitle: `Total Records`,
        btn_text: 'Add Time Stamp',
        navigation_url: `/${prefixCompany}/create-customer`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  async function getTimesheet() {
    await mainTimesheet()
      .then((res) => {
        const timesheet_data = res?.data?.timesheet
        setValue(res?.data?.data)
        setData(timesheet_data?.data || [])
        setResult(timesheet_data?.sum)
        const stateHeader = {
          payload: {subtitle: `${timesheet_data?.data?.length} Total Records`},
          type: HeaderAction.Update,
        }
        headerContext.dispatch(stateHeader)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  useEffect(() => {
    getTimesheet()
  }, [])

  async function getData() {
    setLoading(true)
    await mainClock()
      .then((res: any) => {
        setValue(res?.data?.data)
        setTimeout(() => {
          setLoading(false)
          getTimesheet()
        }, 0)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
  function handleSubmit() {
    try {
      getData()
    } catch (error: any) {
      console.error(error)
    }
  }

  function dateDifference(timeDiff: any) {
    const h = Math.floor(timeDiff / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((timeDiff % 3600) / 60)
        .toString()
        .padStart(2, '0')
    return h + ':' + m
  }

  return (
    <>
      <table
        style={{
          fontFamily: 'Poppins, Helvetica, "sans-serif"',
          borderCollapse: 'collapse',
          width: '100%',
          background: 'white',
          fontSize: '14px',
        }}
        className='shadow'
      >
        <tbody>
          <tr style={{borderBottom: '1px dotted lightgrey'}}>
            <td colSpan={3} style={{textAlign: 'left'}}>
              <h3 className='m-5'>Timesheet</h3>
            </td>
            <td colSpan={1} className='' style={{textAlign: 'right'}}>
              <button
                onClick={handleSubmit}
                type='button'
                className='btn btn-primary m-5'
                disabled={loading}
              >
                {value == '0' ? !loading && 'Clock In' : !loading && 'Clock Out'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </td>
          </tr>
          <tr>
            <td
              style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
              className='text-dark fw-bold'
            >
              In
            </td>
            <td
              style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
              className='text-dark fw-bold'
            >
              Out
            </td>
            <td
              style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
              className='text-dark fw-bold'
            >
              Hours Used
            </td>
            <td
              style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
              className='text-dark fw-bold'
            >
              Actions
            </td>
          </tr>
          {data.map((value: any) => {
            return (
              <>
                <tr>
                  <td
                    style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
                  >
                    {value?.user?.first_name + ' ' + value?.user?.last_name || ''}{' '}
                  </td>
                  <td
                    style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
                  >
                    {' '}
                    {moment(value.start_time).format('llll') || ''}{' '}
                  </td>
                  <td
                    style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
                  >
                    {moment(value.end_time).format('llll') || ''}{' '}
                  </td>
                  <td
                    style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
                  >
                    {dateDifference(value?.timeDiff) || ''}{' '}
                  </td>
                </tr>
              </>
            )
          })}
          <tr>
            <td
              style={{
                borderBottom: '1px solid #dddddd',
                textAlign: 'left',
                padding: '8px',
                color: '#ef003c',
              }}
              colSpan={3}
            >
              Grand Total
            </td>
            <td
              style={{borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px'}}
              className='text-dark'
            >
              {dateDifference(result) || '00:00:00'}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {Timesheet}
